<template>
    <div>
        <vue-spinner
        size="40px"
        />
    </div>
</template>

<script>
    import VueSpinner from 'vue-spinner/src/ClipLoader'

    export default {
        name: "Loader",
        components: {
            VueSpinner
        }
    }
</script>

<style scoped>

</style>