<template>
    <div>

        <vue-google-autocomplete
                v-if="showSearch"
                id="google-autocomplete"
                classname="p-3 w-full bg-gray-700 text-gray-200 rounded-none"
                :enable-geolocation="true"
                placeholder="Start typing a location"
                :country="['uk']"
                types="geocode"
                v-on:placechanged="onPlaceChange"
        >
        </vue-google-autocomplete>
        <div
                v-loading="loading"
                :class="'el-block--application-map-ods ' + osClass"
        >
            <div class="el-block--ods-info is-small" v-if="$store.getters['ods/getSelectedPropertyInfo'] !== null">
                <div
                        @click="()=>{ $store.state.ods.property = null }"
                        class="w-5 h-5 cursor-pointer rounded-full bg-gray-400 right-0 top-0 absolute m-2 flex items-center justify-center">
                    <i class="el-icon-close text-xs text-gray-700"></i>
                </div>
                <dl class="el-block--dl">
                    <div class="item"
                         :key="index"
                         v-for="(item, index) in $store.getters['ods/getSelectedPropertyInfo']"
                    >
                        <dt>
                            {{ item.key }}
                        </dt>
                        <dd>
                            {{ item.value }}
                        </dd>
                    </div>
                </dl>
            </div>

            <div class="el-block--select-ods">
                <div class="flex">
                    <div>
                        <el-select
                                size="mini"
                                :loading="loading"
                                :disabled="loading"
                                v-model="$store.state.ods.type" placeholder="Select">
                            <el-option-group
                                    v-for="group in $store.state.ods.types"
                                    :key="group.label"
                                    :label="group.label">
                                <el-option
                                        v-for="item in group.options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-option-group>
                        </el-select>
                    </div>
                    <div>
                        <el-button
                                size="mini"
                                icon="el-icon-refresh"
                                   @click="fetchNearby"
                                   type="info"
                        >Refresh</el-button>
                    </div>
                    <div>
                        <el-button
                                size="mini"
                                :disabled="!$store.state.ods.geo_objects.length"
                                icon="el-icon-delete"
                                   @click="clear"
                                   type="danger"
                        >Clear</el-button>
                    </div>
                    <!--
                    <div>
                        <el-button
                                size="mini"
                                icon="el-icon-document-add"
                                @click="save"
                                type="success"
                        >Save</el-button>
                    </div>
                    -->
                </div>


            </div>


            <l-map
                    @click="getOdsProperty"
                    ref="odsMap"
                    :max-bounds="$store.getters['auth/getMaxBounds']"
                    :center="{lat: opts.lat,
                                    lng: opts.lon}"
                    :zoom="opts.zoom">

                <l-marker
                        :lat-lng="{lat: opts.lat,
                                    lng: opts.lon}">
                    <l-icon>
                        <div class="h-8 w-8 flex items-center justify-center rounded-full bg-blue-800">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 width="15"
                                 class="fill-current text-white"
                                 height="15" viewBox="0 0 24 24">
                                <path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/>
                            </svg>
                        </div>
                    </l-icon>
                </l-marker>


                <l-geo-json
                        v-if="$store.getters['ods/getSelectedPropertyGeoJson'] !== null"
                        :options-style="()=>{ return {
                                                    color: '#009c64',
                                                    weight: 1,
                                                    fillColor: '#009c64'
                                                    } }"
                        :geojson="$store.getters['ods/getSelectedPropertyGeoJson']" />

                <l-geo-json
                        :key="k"
                        :options-style="()=>{ return {
                                                    color: '#1270b5',
                                                    weight: 1,
                                                    fillColor: '#1270b5'
                                                    } }"
                        v-for="(json, k) in $store.getters['ods/getGeoObjects']"
                        :geojson="json" />

                <l-tile-layer
                        url="https://api.os.uk/maps/raster/v1/zxy/Outdoor_3857/{z}/{x}/{y}.png?key=wC6Him1A448aAquBYlhEJq2IS4GAK51Z"
                />

            </l-map>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OrdinanceSurveyComponent",
        props: {
          lat: {
              type: Number,
              default(){
                  return 0;
              }
          },
            lon: {
                type: Number,
                default(){
                    return 0;
                }
            },
            zoom: {
                type: Number,
                default(){
                    return 18;
                }
            },
            showSearch: {
              type: Boolean,
                default(){
                  return true;
                }
            },
            osClass: {
              type: String,
                default(){
                  return '';
                }
            }
        },
        data(){
          return {
              loading: false,
              opts: {
                  lat: this.lat,
                  lon: this.lon,
                  zoom: this.zoom,
                  last_name: ''
              }
          }
        },
        mounted(){
            console.log(this.$refs.odsMap);
        },
        methods: {
            onPlaceChange(opts){
                const el = document.getElementById('google-autocomplete');
              this.opts.lat = opts.latitude;
                this.opts.lon = opts.longitude;
                this.opts.last_name = el.value;

                el.value = '';
            },
            clear(){
                this.$store.state.ods.geo_objects = []
            },
            save(){
               // this.$store.state.ods.geo_objects = []
            },
            fetchNearby(){
                let vm = this,
                    bounds = this.$refs.odsMap.mapObject.getBounds();

                vm.loading = true;
                this.$store.dispatch('ods/getOdsNearby', {
                    sw_lat: bounds._southWest.lat,
                    sw_lng: bounds._southWest.lng,
                    ne_lat: bounds._northEast.lat,
                    ne_lng: bounds._northEast.lng
                }).then(()=>{
                    vm.$notify({
                        type: vm.$store.getters['ods/getGeoObjects'].length > 0 ? 'success' : 'warning',
                        message: (vm.$store.getters['ods/getGeoObjects'].length > 0 ? vm.$store.getters['ods/getGeoObjects'].length.toLocaleString() : 'No') + ' objects found',
                        title:  vm.$store.getters['ods/getGeoObjects'].length > 0 ? 'Objects found' : 'No objects found',
                        position: 'top-right',
                        offset: 140,
                    });
                    vm.loading = false;
                }).catch(()=>{
                    vm.loading = false;
                })
            },
            getOdsProperty(el){
                let items = Object.values(el.latlng);
                this.$store.dispatch('ods/getOdsProperty', {
                    lat: items[0],
                    lon: items[1]
                })
            },
        }
    }
</script>

<style scoped>

</style>