import { render, staticRenderFns } from "./MarketTrackerBuyTable.vue?vue&type=template&id=33ab8fb9&scoped=true&"
import script from "./MarketTrackerBuyTable.vue?vue&type=script&lang=js&"
export * from "./MarketTrackerBuyTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33ab8fb9",
  null
  
)

export default component.exports