<template>
    <div>
        <div
                v-if="loading"
                class="flex h-full mt-12 pt-12 items-center justify-center">
            <div class="p-3 text-center">
                <property-loader />
                <h3 class="font-semibold text-blue-800">Fetching Planning Applications...</h3>
            </div>
        </div>


        <div  v-if="!loading" class="columns">
            <div class="column w-full">
                <dashboard-widget
                        padding-class="''"
                        title="Planning Applications">
                    <slot>

                        <div class="p-3" v-if="!getApplications.length">
                            <empty-state />
                        </div>

                        <div
                                class="el-collapse--planning-applications"
                                v-if="getApplications.length > 0">

                            <el-collapse v-model="openApplications">
                                <el-collapse-item
                                        :key="item.id"
                                        v-for="(item) of value"
                                        :name="item.id">

                                    <template slot="title">
                                        <div class="w-5/6 px-2 pt-1">
                                            {{ item.site_address ? item.site_address.toUpperCase() : 'Application' }}<br />
                                            <small class="block w-full truncate">{{ item.proposal }}</small>

                                            <div class="my-2 -mx-2 pt-0 flex w-full flex-wrap" v-if="findTags(item).length > 0">
                                                <div class="p-1"
                                                     :key="k"
                                                     v-for="(v, k) of findTags(item)">
                                                    <el-tag type="info">{{ v }}</el-tag>
                                                </div>
                                            </div>

                                        </div>
                                    </template>

                                    <div class="p-3">
                                        <div class="columns flex-no-wrap"
                                             :key="i"
                                             v-for="(d, i) of getDetails(item)">
                                            <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                                                {{ d.label }}
                                            </div>
                                            <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                                {{ d.value }}
                                            </div>
                                        </div>

                                        <div class="columns flex-no-wrap" v-if="item.date_received">
                                            <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                                                Date Received
                                            </div>
                                            <div class="column ml-auto text-xs text-right text-gray-900">
                                                <date-human-view size="text-xs"  v-if="item.date_received" v-model="item.date_received" />
                                            </div>
                                        </div>
                                        <div class="columns flex-no-wrap" v-if="item.date_validated">
                                            <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                                                Date Validated
                                            </div>
                                            <div class="column ml-auto text-xs text-right text-gray-900">
                                                <date-human-view size="text-xs"  v-if="item.date_validated" v-model="item.date_validated" />
                                            </div>
                                        </div>
                                        <div class="columns flex-no-wrap" v-if="item.date_decided">
                                            <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                                                Date Decided
                                            </div>
                                            <div class="column ml-auto text-xs text-right text-gray-900">
                                                <date-human-view size="text-xs" v-if="item.date_decided" v-model="item.date_decided" />
                                            </div>
                                        </div>

                                        <div v-if="item.url" class="mt-6">
                                            <el-button
                                                    @click="openApplication(item.url)"
                                                    class="w-full" type="success" icon="el-icon-link">
                                                View Application
                                            </el-button>
                                        </div>


                                    </div>



                                </el-collapse-item>
                            </el-collapse>


                        </div>
                    </slot>
                </dashboard-widget>
            </div>
        </div>

    </div>


</template>

<script>
    import EmptyState from "../../EmptyState";
    import PropertyLoader from "../../incs/PropertyLoader";
    import {getPlanningTypeTags} from "../../../utils/ownership";
    export default {
        name: "OwnershipPlanningApplications",
        components: {PropertyLoader, EmptyState},
        props: {
            value: {
                type: Array,
                default(){
                    return []
                }
            },
            activeSiteId: {
                type: [Number, String],
                default(){
                    return null
                }
            }
        },
        computed: {
            getApplications(){
                if(this.activeSiteId){
                    return this.value.filter((i)=>{
                        return i.site_id === this.activeSiteId;
                    })
                }
                return this.value
            },
        },
        methods: {
            findTags(item){
                return getPlanningTypeTags(item);
            },
            openApplication(url){
             window.open(url);
            },
          getDetails(item){
              return [
                  {label: 'Proposal', value: item.proposal},
                  {label: 'Status', value: item.status},
                  {label: 'Decision', value: item.decision},
                  {label: 'Reference', value: item.reference},
                  {label: 'Alternative Reference', value: item.alternative_reference},
                  {label: 'Address', value: item.site_address},
                  {label: 'Applicant', value: item.applicant_name},
                  {label: 'Applicant Address', value: item.applicant_address},
                  {label: 'Applicant Company', value: item.applicant_company},
                  {label: 'Application Type', value: item.type},
                  {label: 'Appeal Decision', value: item.appeal_decision},
                  {label: 'Ward', value: item.ward},
                  {label: 'Parish', value: item.parish},
                  {label: 'District', value: item.district},
                  {label: 'Case Officer', value: item.case_officer},
              ].filter((item) => item.value)
          }
        },
        data(){
            return {
                loading: false,
                openApplications: [],
            }
        }
    }
</script>

<style lang="scss">
    .el-collapse--planning-applications  .el-collapse-item__header {
        line-height: 15px !important;
        height: auto !important;
        padding: 10px !important;
    }

    .el-collapse--planning-applications .el-collapse-item__content {
        padding-bottom: 0;
    }
</style>