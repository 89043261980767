<template>

        <page v-loading="loading">

            <template slot="header">
                <page-header
                        :title="team.name ? team.name + ' Seats' : 'Manage Seats'">
                    <template v-slot:tabs>
                        <active-team-nav />
                    </template>
                </page-header>

            </template>

            <template slot="content">
                <div class="columns">
                    <div class="column w-full">

                        <dashboard-widget
                                padding-class=""
                                title="Team Seats">

                            <template slot="cta">
                                <el-button type="success"
                                           @click="$store.dispatch('auth/openSupport')"
                                           icon="el-icon-plus">Buy Seats</el-button>
                            </template>

                            <empty-state class="p-3"
                                         title="No seats found"
                                         message="You currently have no seats"
                                         :support="false"
                                         v-if="!table.loading && !table.results.length"/>


                            <div class="p-3 py-12 text-center" v-if="table.loading">
                                <property-loader />
                            </div>


                            <div v-if="table.results.length > 0 && !table.loading">


                                <el-table :data="table.results">
                                    <el-table-column label="Assigned to">
                                        <template slot-scope="scope">

                                            <div v-if="scope.row.name" class="flex items-start justify-start">
                                                <div class="flex-shrink-0 h-10 w-10">
                                                    <avatar-manager
                                                            :name="scope.row.name"
                                                            v-model="scope.row.avatar" />
                                                </div>
                                                <div class="ml-4">
                                                    <div class="text-sm font-medium text-gray-900">
                                                        {{ scope.row.name }}
                                                    </div>
                                                    <div class="text-xs text-gray-500">
                                                        {{ scope.row.email }}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="tag is-warning" v-else>
                                                Unassigned
                                            </div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column width="140" label="From">
                                        <template slot-scope="scope">
                                            <date-human-view v-model="scope.row.subscription_from" />
                                        </template>
                                    </el-table-column>

                                    <el-table-column width="140" label="To">
                                        <template slot-scope="scope">
                                            <date-human-view v-model="scope.row.subscription_to" />
                                        </template>
                                    </el-table-column>

                                </el-table>
                            </div>

                        </dashboard-widget>
                    </div>
                </div>
            </template>


        </page>

</template>
<script>

    import EmptyState from "../../components/EmptyState";
    import PropertyLoader from "../../components/incs/PropertyLoader";
    import ActiveTeamNav from "./ActiveTeamNav";
    import AvatarManager from "../../components/AvatarManager";

    export default {
        name: 'TeamSeats',
        components: {AvatarManager, ActiveTeamNav, PropertyLoader, EmptyState},


        methods: {

            revoke(id){

                let vm = this;

                vm.$confirm(
                    'Are you sure you want to revoke this invite? This cannot be undone.',
                    'Revoke Invite?',
                    {
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'Cancel'
                    })
                    .then(() => {
                        //vm.loading = true;


                        this.$store.dispatch('teamInvite/remove', {
                            teamId: vm.$route.params.teamId,
                            inviteId: id
                        }).then(()=>{
                            vm.$notify({
                                type: 'success',
                                title: 'Invite Revoked',
                                message: 'Invite was revoked successfully',
                                position: 'top-right',
                                offset: 140,
                            });

                            setTimeout(()=>{
                                vm.fetch();
                            }, 100)
                        }).catch((e)=>{
                            console.log('error', e);
                            vm.$store.dispatch('auth/handleServerError', {
                                vm: vm,
                                error: e
                            });
                        })


                    }).catch((e) => {
                    console.log(e);
                });
            },

            resend(id){

                let vm = this;
                vm.table.loading = true;

                vm.$confirm(
                    'Are you sure you want to resend this invite? This cannot be undone.',
                    'Resend Invite?',
                    {
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'Cancel'
                    })
                    .then(() => {
                        //vm.loading = true;
                        vm.table.loading = true;
                        this.$store.dispatch('teamInvite/resend', {
                            teamId: vm.$route.params.teamId,
                            inviteId: id
                        }).then(()=>{

                            vm.$notify({
                                type: 'success',
                                title: 'Invite Resent',
                                message: 'Invite was resent successfully',
                                position: 'top-right',
                                offset: 140,
                            });

                            setTimeout(()=>{
                                vm.fetch();
                            }, 100)
                        }).catch((e)=>{
                            vm.table.loading = false;
                            console.log('error', e);
                            vm.$store.dispatch('auth/handleServerError', {
                                vm: vm,
                                error: e
                            });
                        })


                    }).catch((e) => {
                    vm.table.loading = false;
                    console.log(e);
                });
            },

            fetch() {
                const vm = this;
                vm.table.loading = true;
                return vm.$store.dispatch('teamSeat/index', {
                    teamId: vm.$route.params.teamId
                }).then((response) => {
                    vm.table.loading = false;
                    vm.table.results = response.data.data;
                }).catch(() => {
                    vm.table.loading = false;
                    vm.table.results = [];
                })

            }
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.team = {};
                vm.loading = true;
                vm.$http.get('/api/v1/team/' + vm.$route.params.teamId)
                    .then((response) => {

                        vm.team = response.data.data;

                        vm.$store.commit('auth/setBreadcrumbs', [
                            {
                                title: 'My Teams',
                                path: '/teams'
                            },
                            {
                                title: response.data.data.name,
                                path: '/team/' + response.data.data.id
                            },
                            {
                                title: 'Seats',
                            }
                        ]);



                        vm.fetch();

                        vm.loading = false;

                    }).catch(() => {

                })
            })
        },
        data(){
            return {
                loading: true,
                invite: {
                    updating: false,
                    show: false,
                    model: {
                       email: ''
                    }
                },
                rules: {
                    email: [
                        {required: true, message: 'An email address is required', trigger: 'blur'},
                    ]
                },
                team: {},
                table: {
                    loading: true,
                    results: [

                    ],
                    meta: {}
                }
            }
        }
    }
</script>
<style>
    .el-option-select--seat-data {
        height: auto !important;
    }
</style>
