<template>
    <header class="relative h-16  bg-white flex items-center shadow-md top-nav">

        <div class="h-16 flex items-center justify-center w-20 md:bg-gray-900" @click="$store.state.auth.showMenu = true">

            <svg viewBox="0 0 100 133" class="w-8 hidden md:block"

                 version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" class="fill-current text-green-600"  fill-rule="evenodd">
                    <g>
                        <g>
                            <path d="M99.469,49.958 C99.469,66.251 84.687,89.43 71.25,107.167 L63.289,97.727 C76.121,80.427 87.375,61.413 87.375,49.958 C87.375,29.198 70.512,12.301 49.718,12.301 C28.957,12.301 12.094,29.198 12.094,49.958 C12.094,65.411 32.552,94.57 49.718,114.792 L57.578,124.131 C52.908,129.641 49.751,132.999 49.751,132.999 C49.751,132.999 0,79.823 0,49.958 C0,22.479 22.272,0.207 49.751,0.207 C77.196,0.207 99.469,22.479 99.469,49.958" id="Fill-13"></path>
                            <path d="M60.568,101.288 L68.529,110.728 C65.674,114.423 62.919,117.782 60.467,120.739 L52.573,111.4 L49.718,108.007 C32.652,87.246 16.46,62.22 16.46,49.958 C16.46,31.583 31.376,16.668 49.718,16.668 C68.093,16.668 83.008,31.583 83.008,49.958 C83.008,59.633 72.964,77.168 60.366,94.301 L52.338,84.76 C65.069,67.091 70.914,56.214 70.914,49.958 C70.914,38.268 61.408,28.761 49.718,28.761 C38.027,28.761 28.554,38.268 28.554,49.958 C28.554,56.214 35.205,68.804 49.718,88.388 L57.679,97.862 L60.568,101.288" id="Fill-15"></path>
                        </g>
                    </g>
                </g>
            </svg>



            <button type="button" class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden" @click="open = true">
                <span class="sr-only">Open sidebar</span>

                <svg class="w-8" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z"/></svg>

            </button>

        </div>


        <div class="px-4 flex items-center w-full">

            <div class="w-auto mr-2 overflow-hidden leading-loose h-4 truncate">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item v-for="(crumb, index) of $store.getters['auth/getBreadcrumbs']"
                                        :key="index"
                                        :to="{ path: crumb.path }">
                        {{ crumb.title }}
                    </el-breadcrumb-item>
                </el-breadcrumb>
            </div>

            <div class="flex ml-auto items-center space-x-8">
                <div class="relative inline-block text-left text-xs uppercase">

                    <el-tooltip content="Switch Teams" :disabled="$store.state.auth.user.total_teams <= 1">
                        <div
                                @click="$store.state.auth.showSwitchTeam = true"
                                class="flex items-center justify-center cursor-pointer">
                            <avatar-manager
                                    :name="$store.state.auth.user.team.name"
                                    v-model="$store.state.auth.user.team.avatar" />
                            <span class="ml-2">{{ $store.state.auth.user.team.name }}</span>
                        </div>
                    </el-tooltip>

                </div>
                <div class="relative inline-block text-left">
                    <el-dropdown class="h-full el--dropdown-profile flex items-center items-center"
                                 @command="handleAction"
                                 :hide-on-click="true">
                        <div>
                            <button
                                    class="max-w-xs flex items-center text-sm rounded-full text-white focus:outline-none focus:shadow-solid" id="user-menu" aria-label="User menu" aria-haspopup="true">
                                   <avatar-manager
                                           :name="$store.state.auth.user.name"
                                           v-model="$store.state.auth.user.avatar" />
                            </button>
                        </div>

                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                                    v-for="(link, k) in profile"
                                    :key="k"
                                    :command="link.action"
                            >
                                {{ link.label }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import AvatarManager from "../AvatarManager";
    export default {
        name: "Navigation",
        components: {AvatarManager},
        data(){
            return {
                show: false,
                profile: [
                    {label: 'Your Profile',  action: 'openProfile' },
                    {label: 'Your Teams (' + this.$store.getters['auth/totalTeams'] + ')', action: 'openTeams' },
                    //todo: invites
                 //   {label: 'Your Invites (' + this.$store.getters['auth/totalTeams'] + ')', action: 'openInvites' },
                    {label: 'Account Security', action: 'openSecurity' },
                    {label: 'Delete Account', action: 'openDeleteAccount' },
                    {label: 'Logout', action: 'runLogout' },
                ]
            }
        },
        methods: {
            handleAction(value){
                this[value]();
            },
            openSecurity(){
                this.$store.state.auth.account.activeTab = 'security';
                this.$store.state.auth.account.visible = true;
            },
            openDeleteAccount(){
                this.$store.state.auth.account.activeTab = 'delete';
                this.$store.state.auth.account.visible = true;
            },
            openTeams(){
                this.$store.state.team.visible = true;
            },
            openProfile(){
                this.$store.state.auth.account.activeTab = 'profile';
                this.$store.state.auth.account.visible = true;
            },
            runLogout(){
                this.$router.push('/login?mode=logout');
            }
        }
    }
</script>

<style>
.el--dropdown-profile {
    display: flex !important;
}
.top-nav {
    z-index: 8001;
}
</style>