<template>
    <floating-label-input label="Select Report Metrics">
        <el-select
                class="w-full"
                size="medium"
                autocomplete="off"
                filterable
                :popper-append-to-body="false"
                v-model="activeTab" placeholder="Select data option...">
            <el-option-group
                    v-for="group in options"
                    :key="group.label"
                    :label="group.label">
                <el-option
                        v-for="item in group.options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    <div class="flex justify items-center -mx-1">
                        <div class="p-1 mr-auto">
                            {{ item.label }}
                        </div>
                        <div class="p-1 text-gray-500">
                            <i v-if="item.loading" class="el-icon-loading"></i>
                            <span v-if="item.hasOwnProperty('count') && !item.loading" class="text-xs">
                                                {{ item.count }}
                                           </span>
                            <span v-if="item.hasOwnProperty('has_warning') && !item.loading" class="text-xs">
                                                <svg v-if="item.has_warning"
                                                     xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 fill-current text-orange-500" viewBox="0 0 24 24"><path d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"/></svg>
                                                <svg v-else
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     class="w-4 h-4 fill-current text-green-500"
                                                     viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>
                                            </span>
                        </div>
                    </div>
                </el-option>
            </el-option-group>
        </el-select>
    </floating-label-input>
</template>

<script>

    import {
        getKeyIntegerValue, getNearestSubstation,
        getTotalBuildings, getTotalEPCRatings, getTotalKnownListings,
        getTotalLeaseholders, getTotalListedBuildings, getTotalPlanningApplications,
        getTotalProperties, getTotalSales, getTotalUseClasses,
        isAONB,
        isFloodRisk,
        isGreenbelt
    } from "../../utils/ownership";


    export default {
        name: "OwnershipReportSelect",
        props: {
          isPoint: {
              type: Boolean,
              default(){
                  return false
              }
          },
            loading: {
                type: Boolean,
                default(){
                    return false
                }
            },
            value: {
              type: String,
                default(){
                  return 'ownership'
                }
            },
            topography: {
              type: Object,
                default(){
                  return {}
                }
            },
            substations: {
                type: Object,
                default(){
                    return {}
                }
            },
            environment: {
                type: Object,
                default(){
                    return {}
                }
            },
            saleHistory: {
              type: Array,
                default(){
                  return []
                }
            },
            knownListings: {
                type: Array,
                default(){
                    return []
                }
            },
            properties: {
                type: Array,
                default(){
                    return []
                }
            },
            listedBuildings: {
                type: Array,
                default(){
                    return []
                }
            },
            planning: {
                type: Array,
                default(){
                    return []
                }    
            },
            epcs: {
                type: Array,
                default(){
                    return []
                }
            },
            densityAverages: {
                type: Object,
                default(){
                    return {}
                }
            },
            localPricingAverages: {
                type: Object,
                default(){
                    return {}
                }
            }
        },
        watch: {
          activeTab(){
               this.$emit('input', this.activeTab);
          }
        },
        data(){
          return {
              activeTab: this.value
          }
        },
        computed: {
            options() {

                let items = [
                    {
                        label: 'Overview',
                        options: [
                            {
                                value: 'ownership',
                                label: 'Ownership & Downloads',
                                loading: false
                            },
                            {
                                value: 'administrative',
                                label: 'Administration & Demographics',
                                loading: false
                            },
                            {
                                value: 'street-view',
                                label: 'Street View',
                                loading: false
                            }
                        ]
                    }
                ];


                if (!this.isPoint) {

                    items.push({
                        label: 'Topography and Environment',
                        options: [
                            {
                                value: 'site-areas',
                                label: 'Site Areas',
                                loading: this.loading,
                                count: 1
                            },
                            {
                                value: 'buildings',
                                label: 'Buildings',
                                loading: this.loading,
                                count: getTotalBuildings(this.topography)
                            },
                            {
                                value: 'sub-stations',
                                label: 'Nearest Sub-stations',
                                loading: this.loading,
                                count: getNearestSubstation(this.substations) ? getNearestSubstation(this.substations) + 'm' : null
                            },
                            {
                                value: 'topography',
                                label: 'Topography Analysis',
                                loading: this.loading,
                                has_warning: false
                            },
                            {
                                value: 'environment',
                                label: 'Greenbelt/AONB Coverage',
                                loading: this.loading,
                                has_warning: isAONB(this.environment) || isGreenbelt(this.environment)
                            },
                            {
                                value: 'flood-risk',
                                label: 'Flood Risk',
                                loading: this.loading,
                                has_warning: isFloodRisk(this.environment)
                            },
                        ]
                    })

                }


                if (!this.isPoint) {
                    let properties = {
                        label: 'On-site dataset\'s',
                        options: []
                    };

                    properties.options.push({
                        value: 'properties',
                        label: 'Registered Addresses',
                        count: getTotalProperties(this.properties),
                        loading: false
                    })
                    properties.options.push({
                        value: 'leaseholders',
                        label: 'Leaseholders',
                        count: getTotalLeaseholders(this.properties),
                        loading: false
                    });
                    properties.options.push({
                        value: 'use-classes',
                        label: 'Use Classes',
                        count: getTotalUseClasses(this.properties),
                        loading: false
                    })

                    properties.options.push({
                        value: 'listed',
                        label: 'Listed Buildings',
                        count: getTotalListedBuildings(this.listedBuildings),
                        loading: false
                    })
                    properties.options.push({
                        value: 'sale-history',
                        label: 'Sale History',
                        count: getTotalSales(this.saleHistory),
                        loading: this.loading
                    });

                    properties.options.push({
                        value: 'known-listings',
                        label: 'Known Marketed Listings',
                        count: getTotalKnownListings(this.knownListings),
                        loading: false
                    });

                    properties.options.push({
                        value: 'planning-applications',
                        label: 'Planning History',
                        count: getTotalPlanningApplications(this.planning),
                        loading: this.loading
                    });
                    properties.options.push({
                        value: 'epc',
                        label: 'EPC Ratings',
                        count: getTotalEPCRatings(this.epcs),
                        loading: false
                    });


                    items.push(properties)
                }

                items.push({
                    label: 'Local Property Density',
                    options: [
                        {
                            value: 'density-residential',
                            label: 'Residential',
                            loading: this.loading,
                            count: getKeyIntegerValue(!this.loading && this.densityAverages ? this.densityAverages : {}, 'residential', false) + '%'
                        },
                        {
                            value: 'density-detached-semi',
                            label: 'Detached/Semi-detached',
                            loading: this.loading,
                            count: getKeyIntegerValue(!this.loading && this.densityAverages ? this.densityAverages : {}, 'detached_semi_detached', false) + '%'
                        },
                        {
                            value: 'density-terraced',
                            label: 'Terraced',
                            loading: this.loading,
                            count: getKeyIntegerValue(!this.loading && this.densityAverages ? this.densityAverages : {}, 'terraced_housing', false) + '%'
                        },
                        {
                            value: 'density-flats',
                            label: 'Flats/Maisonettes',
                            loading: this.loading,
                            count: getKeyIntegerValue(!this.loading && this.densityAverages ? this.densityAverages : {}, 'flats_maisonette', false) + '%'
                        },
                        {
                            value: 'density-hmos',
                            label: 'Houses of Multiple Occupation',
                            loading: this.loading,
                            count: getKeyIntegerValue(!this.loading && this.densityAverages ? this.densityAverages : {}, 'hmos', false) + '%'
                        },
                        {
                            value: 'density-offices',
                            label: 'Offices',
                            loading: this.loading,
                            count: getKeyIntegerValue(!this.loading && this.densityAverages ? this.densityAverages : {}, 'offices', false) + '%'
                        },
                        {
                            value: 'density-retail-leisure',
                            label: 'Retail & Leisure',
                            loading: this.loading,
                            count: getKeyIntegerValue(!this.loading && this.densityAverages ? this.densityAverages : {}, 'retail_leisure_dual_use', false) + '%'
                        },
                        {
                            value: 'density-owner-occupied',
                            label: 'Owner-occupied',
                            loading: this.loading,
                            count: getKeyIntegerValue(!this.loading && this.densityAverages ? this.densityAverages : {}, 'owner_occupied', false) + '%'
                        },
                        {
                            value: 'density-private-rentals',
                            label: 'Private Rentals',
                            loading: this.loading,
                            count: getKeyIntegerValue(!this.loading && this.densityAverages ? this.densityAverages : {}, 'private_rentals', false) + '%'
                        },
                        {
                            value: 'density-social-rentals',
                            label: 'Social Rentals',
                            loading: this.loading,
                            count: getKeyIntegerValue(!this.loading && this.densityAverages ? this.densityAverages : {}, 'social_rentals', false) + '%'
                        }
                    ]
                })

                items.push({
                    label: 'Local Property Pricing',
                    options: [
                        {
                            value: 'pricing-residential',
                            label: 'Residential',
                            loading: this.loading,
                            count: '£' + getKeyIntegerValue(!this.loading && this.localPricingAverages && this.localPricingAverages ? this.localPricingAverages : {}, 'residential', true)
                        },
                        {
                            value: 'pricing-detached',
                            label: 'Detached',
                            loading: this.loading,
                            count: '£' + getKeyIntegerValue(!this.loading && this.localPricingAverages && this.localPricingAverages ? this.localPricingAverages : {}, 'detached', true)
                        },
                        {
                            value: 'pricing-semi-detached',
                            label: 'Semi-detached',
                            loading: this.loading,
                            count: '£' + getKeyIntegerValue(!this.loading && this.localPricingAverages && this.localPricingAverages ? this.localPricingAverages : {}, 'semi_detached', true)
                        },
                        {
                            value: 'pricing-terraced',
                            label: 'Terraced',
                            loading: this.loading,
                            count: '£' + getKeyIntegerValue(!this.loading && this.localPricingAverages && this.localPricingAverages ? this.localPricingAverages : {}, 'terraced', true)
                        },
                        {
                            value: 'pricing-flats',
                            label: 'Flats/Maisonettes',
                            loading: this.loading,
                            count: '£' + getKeyIntegerValue(!this.loading && this.localPricingAverages && this.localPricingAverages ? this.localPricingAverages : {}, 'flats', true)
                        },
                        {
                            value: 'pricing-non-residential',
                            label: 'Non-residential',
                            loading: this.loading,
                            count: '£' + getKeyIntegerValue(!this.loading && this.localPricingAverages && this.localPricingAverages ? this.localPricingAverages : {}, 'non_residential', true)
                        },
                        {
                            value: 'pricing-offices',
                            label: 'Offices',
                            loading: this.loading,
                            count: '£' + getKeyIntegerValue(!this.loading && this.localPricingAverages && this.localPricingAverages ? this.localPricingAverages : {}, 'offices', true)
                        },
                        {
                            value: 'pricing-retail-leisure',
                            label: 'Retail & Leisure',
                            loading: this.loading,
                            count: '£' + getKeyIntegerValue(!this.loading && this.localPricingAverages && this.localPricingAverages ? this.localPricingAverages : {}, 'retail_leisure_dual_use', true)
                        }
                    ]
                })


                items.push({
                    label: 'Local Area',
                    options: [
                        {
                            value: 'transport',
                            label: 'Local Transport',
                            loading: this.loading
                        },
                        {
                            value: 'population',
                            label: 'Population & Dwellings',
                            loading: this.loading
                        },
                        {
                            value: 'sold-nearby',
                            label: 'Recently Sold Nearby',
                            loading: this.loading

                        },
                        {
                            value: 'nearby-listed-buildings',
                            label: 'Nearby Listed Buildings',
                            loading: this.loading
                        },
                        {
                            value: 'traffic',
                            label: 'Traffic Analysis'
                        },
                        {
                            value: 'local-schools',
                            label: 'Local Schools'
                        },
                        {
                            value: 'crime',
                            label: 'Crime'
                        }
                    ]
                })

                return items;


            },
        }
    }
</script>

<style scoped>

</style>