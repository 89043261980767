<template>
    <div class="h-full bg-gray-200 overflow-scroll">
        <div class="bg-blue-900 p-6">
            <h4 class="font-semibold overflow-hidden text-white">
                Company Ownership
            </h4>
            <p class="text-xs text-white">View which corporate entities own property within the local area or nationally.
            </p>
        </div>
        <div class="bg-gray-200 p-3">
            <div class="columns">
                <div class="column w-full">
                    <dashboard-widget title="Search Company Ownership">
                        <template v-slot:cta>
                            <export-button
                                    @export="(format) => exportData(format) "
                                    :exporting="$store.state.map.data_metrics.corporate_ownership.fetching"
                                    :disabled="$store.state.map.data_metrics.corporate_ownership.fetching"
                            />
                        </template>

                        <data-layer-filters
                                ref="dataLayerFilters"
                                :loading="$store.state.map.data_metrics.corporate_ownership.fetching"
                                :filters="filters"
                                @change="()=> { fetch() } "
                        />



                        <el-tabs v-model="active_tab">

                            <el-tab-pane label="Properties" name="properties">


                                <data-layer-table-wrapper
                                        ref="tableWrapper"
                                        :filters="filters"
                                        @change="(page)=> {  searchCorporateOwnership(page) }"
                                        v-model="$store.state.map.data_metrics.corporate_ownership">
                                    <div
                                            :key="k"
                                            v-for="(i, k) of $store.state.map.data_metrics.corporate_ownership.results"
                                            class="bg-white px-4 py-2 mb-6 border shadow border border-gray-200">
                                        <div class="flex -pr-1 items-center  text-xs">
                                            <div class="pr-1">
                                                <pretty-tenure :value="i.tenure"/>
                                            </div>
                                            <div class="pr-1 mx-auto">Purchased
                                                <date-human-view size="text-xs" v-model="i.date_proprietor_added"/>
                                                <span v-if="i.price_paid"> for £{{ i.price_paid.toLocaleString() }}</span></div>
                                            <div>
                                                <el-button
                                                        icon="el-icon-view"
                                                        @click="$emit('view', i.title_number)"
                                                        type="success" size="mini">View</el-button>
                                            </div>
                                        </div>
                                        <h3 class="font-bold mt-1 leading-snug my-2 mt-3">{{ i.property_address }}</h3>
                                        <h4 class="text-sm my-1 text-xs"><span class="font-semibold">
                                   <span v-if="i.company_registration_no">{{ i.company_registration_no }} -</span> {{ i.name }}</span>
                                            <small class="block my-2 flex items-center"><span
                                                    class="mr-1 w-3 h-3 inline-block rounded"
                                                    :style="{
                                   'background-color': getOwnershipColor(i.type)
                                   }"
                                            ></span> {{ i.category }}</small>
                                            <small class="block">{{ i.address_line_1 }}</small>
                                        </h4>


                                        <div class="columns mb-0 mt-3 -mx-1">
                                            <div class="w-1/3 p-1">
                                                <el-button class="w-full" type="success"
                                                           @click="$emit('company', i.id)"
                                                           icon="el-icon-search"
                                                           size="small"> Find Properties
                                                </el-button>
                                            </div>
                                            <div class="w-1/3 p-1">
                                                <el-button
                                                        @click="openCompany(i.name)"
                                                        icon="el-icon-coin"
                                                        class="w-full" type="info" size="small"> View Finances
                                                </el-button>
                                            </div>
                                            <div class="w-1/3 p-1">
                                                <el-button
                                                        @click="openContactSearch(i.name)"
                                                        icon="el-icon-postcard"
                                                        class="w-full" type="Find Contacts" size="small"> Find Contacts
                                                </el-button>
                                            </div>
                                        </div>

                                    </div>
                                </data-layer-table-wrapper>




                            </el-tab-pane>
                            <el-tab-pane label="Companies" name="companies">


                                <div v-if="companies.fetching"
                                     class="rounded-md p-4 w-full my-12 mx-auto">
                                    <div class="animate-pulse flex space-x-4">
                                        <div class="rounded-full bg-gray-400 h-12 w-12"></div>
                                        <div class="flex-1 space-y-4 py-1">
                                            <div class="h-4 bg-gray-400 rounded w-3/4"></div>
                                            <div class="space-y-2">
                                                <div class="h-4 bg-gray-400 rounded"></div>
                                                <div class="h-4 bg-gray-400 rounded w-5/6"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                    <div v-if="!companies.fetching && !companies.results.length">
                                        <empty-state title="Sorry, no results found" />
                                    </div>

                                    <div v-if="!companies.fetching && companies.meta.totalResult && companies.results.length > 0">

                                        <div class="columns my-3">
                                            <div class="column w-full text-xs flex items-center uppercase">
                                                {{ companies.meta.totalResult.toLocaleString() }} results
                                            </div>
                                            <div class="column w-full">
                                                <el-table
                                                        :default-sort="companies.sort"
                                                        @sort-change="(val)=>{ sortCompaniesChange(val) }"
                                                        :data="companies.results">
                                                    <el-table-column
                                                            prop="company_name"
                                                            sortable="custom"
                                                            label="Company">
                                                        <template slot-scope="scope">
                                                            <div class="text-xs leading-tight font-bold">
                                                                {{ scope.row.company_name }}

                                                                <h4 class="text-sm my-1 text-xs"><span class="font-semibold">
                                   <span v-if="scope.row.company_registration_no">{{ scope.row.company_registration_no }} -</span> {{ scope.row.name }}</span>
                                                                    <small class="block my-2 flex">
                                                                        <span
                                                                            class="mr-1 w-3 h-3 inline-block rounded flex-none"
                                                                            :style="{
                                   'background-color': getOwnershipColor(scope.row.category_type)
                                   }"
                                                                    ></span>
                                                                        <span>{{ scope.row.category }} - {{ scope.row.country }}</span>

                                                                    </small>
                                                                </h4>
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column
                                                            align="right"
                                                            prop="count"
                                                            sortable="custom"
                                                            label="Count"
                                                            width="90">
                                                        <template slot-scope="scope">
                                                            <div class="text-xs">
                                                                {{ scope.row.count ? scope.row.count.toLocaleString() : null }}
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column
                                                            align="right"
                                                            width="110">
                                                        <template slot-scope="scope">
                                                        <el-button
                                                                @click="$emit('company', scope.row.id)"
                                                                type="info" icon="el-icon-view" size="mini">View</el-button>
                                                        </template>
                                                    </el-table-column>
                                                </el-table>
                                            </div>
                                        </div>


                                        <div class="columns p-3" v-if="!companies.fetching && companies.meta  && companies.results.length > 0">
                                            <div class="column ml-auto">
                                                <el-pagination
                                                        background
                                                        layout="prev, next"
                                                        @current-change="(page)=>{ searchCorporateCompanies (page) }"
                                                        :page-size="companies.meta.rpp"
                                                        :current-page="companies.meta.current"
                                                        :total="companies.meta.totalResult">
                                                </el-pagination>
                                            </div>
                                        </div>

                                    </div>


                            </el-tab-pane>

                            <el-tab-pane label="Activity" name="activity">


                                <div v-if="activity.fetching"
                                     class="rounded-md p-4 w-full my-12 mx-auto">
                                    <div class="animate-pulse flex space-x-4">
                                        <div class="rounded-full bg-gray-400 h-12 w-12"></div>
                                        <div class="flex-1 space-y-4 py-1">
                                            <div class="h-4 bg-gray-400 rounded w-3/4"></div>
                                            <div class="space-y-2">
                                                <div class="h-4 bg-gray-400 rounded"></div>
                                                <div class="h-4 bg-gray-400 rounded w-5/6"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="!activity.fetching && !activity.results">
                                    <empty-state title="Sorry, no results found" />
                                </div>

                                <div v-if="!activity.fetching && activity.results">
                                    <ownership-activity-chart v-model="activity.results" />
                                </div>

                            </el-tab-pane>

                        </el-tabs>

                    </dashboard-widget>
                </div>
            </div>
        </div>
    </div>
</template>

<script>



    import PrettyTenure from "../../PrettyTenure";
    import {openCompanySearch, openContactSearch} from "../../../utils/external";
    import {getColor} from "../../../utils/ownership";
    import EmptyState from "../../EmptyState";
    import OwnershipActivityChart from "../OwnershipLayerComponents/OwnershipActivityChart";
    import DataLayerTableWrapper from "../../DataLayerTableWrapper";
    import DataLayerFilters from "../../DataLayerFilters";
    import ExportButton from "../../ExportButton";
    import {download} from "../../../utils/download";

    export default {
        name: "CorporateOwnershipDataLayer",
        components: {
            ExportButton,
            DataLayerFilters,
            DataLayerTableWrapper,
            OwnershipActivityChart,
             EmptyState, PrettyTenure},
        data() {
            return {
                active_tab: 'properties',
                sort: 'date_proprietor_added|desc',
                query: {},
                search_area: 'bounds',
                search_areas: [
                    {value: 'bounds', label: 'Current Bounds'},
                    {value: null, label: 'United Kingdom'},
                ],

                companies: {
                    sort: {prop: 'count', order: 'descending'},
                  fetching: false,
                    results: [],
                    meta: {}
                },

                activity: {
                    fetching: false,
                    results: null
                },

                filters: [
                    {
                        id: 'proprietor_name',
                        label: 'Company Name',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']},
                    {
                        id: 'proprietor_company_registration_no',
                        label: 'Company Number',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']},
                    {
                        id: 'overseas',
                        label: 'Company Overseas',
                        type: 'select',
                        choices: [{label: 'Yes', value: true}]
                    },
                    {
                        id: 'title_number',
                        label: 'Title Number',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'tenure',
                        value: 'tenure',
                        label: 'Tenure',
                        type: 'select',
                        choices: [
                            {label: "Freehold", value: "Freehold"},
                            {label: "Leasehold", value: "Leasehold"}
                        ]
                    },

                    {
                        id: 'price_paid',
                        label: 'Price Paid',
                        type: 'numeric',
                        operators: ["=", "<",  "<=", '>', ">="],
                    },

                    {
                        type: "text",
                        id: "date_proprietor_added",
                        label: "Date Purchased",
                        operators: ["=", "<",  "<=", '>', ">="],
                    },

                    {
                        id: 'property_address',
                        label: 'Property Address',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'postcode',
                        label: 'Property Postcode',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    }

                ]
            }
        },
        created() {
            this.fetch();
        },
        watch: {
            '$store.state.map.bounds'(){
                this.searchCorporateOwnership(1);
                this.searchCorporateCompanies(1);
                this.searchActivity();
            }
        },
        computed: {
            getQueryObject() {
                const match = this.query && this.query.logicalOperator ? this.query.logicalOperator : null;
                if (!match) {
                    return null;
                }
                return {
                    match: match,
                    rules: this.query && this.query.children ? this.query.children.map((i) => {
                        return i.query
                    }).filter((item) => {
                        return item && ((typeof item.value === 'string' && item.value.length > 0) || item.type === 'custom-component' || (typeof item.value === 'boolean'));
                    }) : []
                }
            },
        },
        methods: {
            exportData(type){
                const vm = this, object = this.buildFilters();
                vm.$store.state.map.data_metrics.corporate_ownership.fetching = true;
                object.format = type;
                vm.$store.dispatch('map/searchCorporateOwnership', object)
                    .then((response) => {
                        vm.$store.state.map.data_metrics.corporate_ownership.fetching = false;
                        download(response.data.data);
                    }).catch(() => {
                    vm.$store.state.map.data_metrics.corporate_ownership.fetching = false;
                })
            },
            sortCompaniesChange(val){
              console.log(val);
              if(val.prop && val.order){
                  this.companies.sort = {prop: val.prop, order: val.order};
              }
              this.searchCorporateCompanies(1);
            },
            fetch(){
                this.searchCorporateOwnership(1);
                this.searchCorporateCompanies(1);
                this.searchActivity();
            },
            getOwnershipColor(ownership) {
                return getColor(ownership)
            },
            openCompany(companyName) {
                openCompanySearch(companyName);
            },
            openContactSearch(companyName) {
                openContactSearch(companyName);
            },
            buildFilters(page) {

                let vm = this,
                    filters = {},
                    object = {
                        rpp: 10,
                        page: page || 1
                    },
                    queryObject = this.$refs.dataLayerFilters ? this.$refs.dataLayerFilters.getFilters() : {};

                if (queryObject && queryObject.match && queryObject.rules) {
                    filters.match = queryObject.match;
                    filters.rules = queryObject.rules;
                }

                if (this.$refs.tableWrapper && this.$refs.tableWrapper.getSortField().length > 0) {
                    let ex = this.$refs.tableWrapper.getSortField().split('|');
                    console.log(ex);
                    if (ex.length === 2) {
                        object.sort = ex[0];
                        object.order = ex[1];
                    }
                }

                const bounds = this.$store.state.map.search_area === 'bounds' ? vm.$store.getters['map/getMapBoundsAsObject'] : {};

                object.search_area = this.$store.state.map.search_area;

                return Object.assign(object, filters, bounds);

            },
            searchActivity() {
                const vm = this, object = this.buildFilters();
                object.view = 'activity';

                vm.activity.fetching = true;
                vm.$store.dispatch('map/searchCorporateOwnership', object).then((response) => {
                    vm.activity.fetching = false;
                    vm.activity.results = response.data.data;
                }).catch(() => {
                    vm.activity.fetching = false;
                    vm.activity.results = null;
                })
            },
            searchCorporateOwnership(page) {

                const vm = this, object = this.buildFilters(page);

                vm.$store.state.map.data_metrics.corporate_ownership.fetching = true;
                vm.$store.dispatch('map/searchCorporateOwnership', object).then((response) => {
                    vm.$store.state.map.data_metrics.corporate_ownership.fetching = false;
                    vm.$store.state.map.data_metrics.corporate_ownership.results = response.data.data.results;
                    vm.$store.state.map.data_metrics.corporate_ownership.meta = response.data.data.meta;
                }).catch(() => {
                    vm.$store.state.map.data_metrics.corporate_ownership.fetching = false;
                    vm.$store.state.map.data_metrics.corporate_ownership.results = [];
                    vm.$store.state.map.data_metrics.corporate_ownership.meta = {};
                })
            },
            searchCorporateCompanies(page) {


                const vm = this, object = this.buildFilters(page);
                object.view = 'companies';

                if(this.companies && this.companies.sort && this.companies.sort.prop && this.companies.sort.order){
                        object.sort = this.companies.sort.prop;
                        object.order = this.companies.sort.order === 'ascending' ? 'asc' : 'desc'
                }

                vm.companies.fetching = true;
                vm.$store.dispatch('map/searchCorporateOwnership', object).then((response) => {
                    vm.companies.fetching = false;
                    vm.companies.results = response.data.data.results;
                    vm.companies.meta = response.data.data.meta;
                }).catch((e) => {
                    console.warn(e);
                    vm.companies.fetching = false;
                    vm.companies.results = [];
                    vm.companies.meta = {};
                })
            },
        }
    }
</script>