import api from "./../../utils/api";
import {
    getAddressBaseCategories,
    getDefaultFilterParams,
    getEPCRatingColor,
    getOrdnanceSurveyStyles, getPlanningObjectColors
} from "../../utils/ownership";

const collect = require('collect.js');

let cancelTokenSource = null;

const initialState = {
    fetching: true,
    loading_polygons: false,
    show_freeholder_icons: false,
    polygon_filters: getDefaultFilterParams(),
    os_types: getOrdnanceSurveyStyles(),
    use_types: getAddressBaseCategories(),
    active_overlays: ['ownership'],
    visible_overlays: ['ownership'],
    ownership_types: [
        {value: 'private', label: 'Private'},
        {value: 'corporate', label: 'Corporate'},
        {value: 'company', label: 'Company'},
        {value: 'ha', label: 'Housing Association'},
        {value: 'council', label: 'Council/Local Authority'}
    ],
    map_views: [
        {label: 'Hybrid', value: 'hybrid', polygon_color: '#fff'},
        {label: 'Satellite', value: 'satellite', polygon_color: '#fff'},
        {label: 'Road', value: 'roadmap', polygon_color: '#4b4b4b'},
        {label: 'OS', value: 'os', polygon_color: '#4b4b4b'},
        {label: 'Street View', value: 'street', polygon_color: '#4b4b4b'},
    ],
    search_modes: [
        {label: 'Location', value: 'location'},
       // {label: 'Address', value: 'address'},
        {label: 'Title Number', value: 'title_number'},
        {label: 'Company', value: 'company'},
    ],
    tenure_types: [
        {value: 'any', label: 'Any'},
        {value: 'freehold', label: 'Freehold'},
        {value: 'leasehold', label: 'Leasehold'},
    ],
    active_data_metric: null,
    overlays: [
        {
            title: 'Ownership',
            closeable: false,
            value: 'ownership',
            group: null,
            img: null
        },
        {
            title: 'Planning',
            closeable: false,
            value: 'planning',
            group: null,
            img: null
        },
        {
            title: 'EPCs',
            closeable: false,
            value: 'epcs',
            group: null,
            img: null
        },
        {
            title: 'Current Occupiers',
            closeable: false,
            value: 'occupiers',
            group: null,
            img: null
        },
        {
            title: 'Known Marketed Sales',
            closeable: false,
            value: 'knownMarketedSales',
            group: null,
            img: null
        },
        {
            title: 'HMOs',
            closeable: false,
            value: 'hmos',
            group: null,
            img: null
        },
        {
            title: 'Listed Buildings',
            closeable: true,
            value: 'listedBuildings',
            group: 'planning',
            img: 'listed-buildings'
        },
        {
            title: 'Sale Prices',
            closeable: false,
            value: 'salePrices',
            group: null,
            img: null
        },
        {
            title: 'HMOs',
            closeable: false,
            value: 'hmos',
            group: null,
            img: null
        },
        {
            title: 'Leaseholders',
            closeable: false,
            value: 'leaseholders',
            group: null,
            img: null
        },
        {
            title: 'Local Planning Authorities',
            closeable: true,
            value: 'planningAuthorities',
            group: 'planning',
            img: 'local-authorities'
        },
        {
            title: 'Parishes',
            closeable: true,
            value: 'parishes',
            group: 'boundaries',
            img: 'parishes'
        },
        {
            title: 'Wards',
            closeable: true,
            value: 'wards',
            group: 'boundaries',
            img: 'wards'
        },
        {
            title: 'District Boroughs',
            closeable: true,
            value: 'districts',
            group: 'boundaries',
            img: 'districts'
        },
        {
            title: 'Counties',
            closeable: true,
            value: 'counties',
            group: 'boundaries',
            img: 'counties'
        },
        {
            title: 'Areas of Outstanding Natural Beauty',
            closeable: true,
            value: 'aonb',
            group: 'planning',
            img: 'aonb'
        },
        {title: 'Greenbelt',
            value: 'greenbelt',
            closeable: true,
            group: 'planning', img: 'greenbelt'},
        {
            title: 'Conservation Areas',
            value: 'conservation',
            closeable: true,
            group: 'planning',
            img: 'conservation-areas'
        },
        {
            title: 'Flood Map for Planning/Flood Zones',
            value: 'floodZones',
            closeable: true,
            group: 'planning',
            img: 'flood-map-planning'
        },

        {
            title: 'Residential (Price per sqft)',
            value: 'pricePerSqft',
            closeable: true,
            group: 'property-market',
            img: 'pp-sqft'
        },
        {
            title: 'Residential Yield (%)',
            value: 'rentalYield',
            closeable: true,
            group: 'property-market',
            img: 'yield'
        },
        {title: 'Income', value: 'income', closeable: true, group: 'property-market', img: 'income'},
        {
            title: 'Deprivation',
            value: 'deprivation',
            closeable: true,
            group: 'property-market',
            img: 'deprivation'
        },


        {
            title: 'Built up areas',
            value: 'builtUpAreas',
            closeable: true,
            group: 'areas',
            img: 'built-up-areas'
        },
        {
            title: 'Agricultural Land Classification (Eng)',
            value: 'agriculturalLandClassification',
            closeable: true,
            group: 'areas',
            img: 'agri'
        },
        {
            title: 'National Parks',
            value: 'nationalParks',
            closeable: true,
            group: 'areas',
            img: 'national-parks'
        },
        {
            title: 'Sites of Scientific Interest',
            value: 'sitesOfScientificInterest',
            closeable: true,
            group: 'areas',
            img: 'special-interest'
        },
        {
            title: 'Special Protection Order Areas',
            value: 'specialProtectionOrder',
            closeable: true,
            group: 'areas',
            img: 'special-protection'
        },
        {
            title: 'Ancient Woodland',
            value: 'ancientWoodland',
            closeable: true,
            group: 'areas',
            img: 'ancient-woodland'
        },
        {
            title: 'Flood Risk Communities',
            value: 'floodRiskCommunities',
            closeable: true,
            group: 'areas',
            img: 'flood-risk-communities'
        },
    ],
    map_mode: 'hybrid',
    search_mode: 'location',
    polygons: [],
    sale_prices: {},
    show_data_metric_polygons: true,
    fetching_sale_prices: false,
    ownership_layers_visible: true,

    /*  Draw mode   */
    custom_draw: {
        visible: false
    },

    data_metrics: {
        corporate_ownership: {
            fetching: true,
            results: []
        },
        leaseholders: {
            fetching: true,
            results: [],
            polygons: [],
            fetching_polygons: true
        },
        planning: {
            fetching: true,
            results: [],
            polygons: [],
            fetching_polygons: true
        },
        epcs: {
            fetching: true,
            results: [],
            polygons: [],
            fetching_polygons: true
        },
        current_occupiers: {
            fetching: true,
            results: [],
            polygons: [],
            fetching_polygons: true
        },
        hmos: {
            fetching: true,
            results: [],
            polygons: [],
            fetching_polygons: true
        },
        marketed_sales: {
            fetching: true,
            results: [],
            polygons: [],
            fetching_polygons: true
        },
        commercial_availability: {
            fetching: true,
            results: [],
            polygons: [],
            fetching_polygons: true
        },
        sale_prices: {
            search_area: 'bounds',
            table: {
                fetching: true,
                results: []
            },
            legend: null,
            stats: {},
            chart: {
                fetching: true,
                data: null
            }
        }
    },
    active_polygon_id: null,
    bounds: null,
    search_area: 'bounds',
    search_areas: [
        {
            label: 'Popular',
            options: [
                {value: 'bounds', label: 'Current Bounds'},
                {value: null, label: 'United Kingdom'},
            ]
        }
    ]
};
const saleLegendColors = [
    "#FED7D7",
    "#FEB2B2",
    "#FC8181",
    "#F56565",
    "#E53E3E"
];

const saleLegendIconColors = [
    "#E53E3E",
    "#F56565",
    "#FED7D7",
    "#FEB2B2",
    "#FED7D7",
];

const planningColors = [
    "#C1FFE8ff",
    "#9BD9BEff",
    "#74B494ff",
    "#4E8E6Bff",
    "#276941ff",
    "#014317ff"
];

export default {
    namespaced: true,
    state: initialState,
    mutations: {
        reset(state) {
            Object.keys(initialState).forEach(key => {
                state[key] = initialState[key]
            })
        },
        refreshLayers: (state) => {
            let find = state.overlays.findIndex((i) => {
                return i.value === 'ownership';
            });
            if (find > -1 && state.overlays[find]) {
                state.overlays[find].visible = false;
                setTimeout(() => {
                    state.overlays[find].visible = true;
                }, 100)
            }
        },
        setFilter: (state, opts) => {
            console.log(opts.params);
            state.polygon_filters = opts.params;
        },
        resetFilter: (state) => {
            state.polygon_filters = getDefaultFilterParams()
        },

        addLayerAvailability: (state, overlay) => {
            let visibleOverlayIndexes = state.active_overlays.reduce((a, e, i) => {
                if (e === overlay)
                    a.push(i);
                return a;
            }, []);
            if (!visibleOverlayIndexes.length) {
                state.active_overlays.unshift(overlay);
            }
        },
        addLayerVisibility: (state, overlay) => {
            let visibleOverlayIndexes = state.visible_overlays.reduce((a, e, i) => {
                if (e === overlay)
                    a.push(i);
                return a;
            }, []);
            if (!visibleOverlayIndexes.length) {
                state.visible_overlays.push(overlay);
            }
        },
        removeLayerVisibility: (state, overlay) => {
            let visibleOverlayIndexes = state.visible_overlays.reduce((a, e, i) => {
                if (e === overlay)
                    a.push(i);
                return a;
            }, []);
            for (let i of visibleOverlayIndexes) {
                state.visible_overlays.splice(i, 1);
            }
        },
        removeLayerAvailability: (state, overlay) => {
            let visibleOverlayIndexes = state.active_overlays.reduce((a, e, i) => {
                if (e === overlay)
                    a.push(i);
                return a;
            }, []);
            for (let i of visibleOverlayIndexes) {
                state.active_overlays.splice(i, 1);
            }
        },

        toggleLayerVisibility: (state, overlay) => {

            let visibleOverlayIndexes = state.visible_overlays.reduce((a, e, i) => {
                if (e === overlay)
                    a.push(i);
                return a;
            }, []);

            if (visibleOverlayIndexes.length > 0) {
                for (let i of visibleOverlayIndexes) {
                    state.visible_overlays.splice(i, 1);
                }
            } else {
                state.visible_overlays.push(overlay);
            }

        },
        toggleLayerAvailability: (state, overlay) => {
            let activeOverlayIndexes = state.active_overlays.reduce((a, e, i) => {
                if (e === overlay)
                    a.push(i);
                return a;
            }, []);

            let visibleOverlayIndexes = state.visible_overlays.reduce((a, e, i) => {
                if (e === overlay)
                    a.push(i);
                return a;
            }, []);

            if (activeOverlayIndexes.length > 0) {
                console.log('removin')
                for (let i of activeOverlayIndexes) {
                    state.active_overlays.splice(i, 1);
                }
                for (let i of visibleOverlayIndexes) {
                    state.visible_overlays.splice(i, 1);
                }
            } else {
                console.log('Adding')
                state.active_overlays.unshift(overlay);
                state.visible_overlays.unshift(overlay);
            }
        },


        toggleLayerOverlay: (state, overlay) => {
            let index = state.active_overlays.findIndex((i) => {
                return i === overlay;
            });
            if (index === -1) {
                state.active_overlays.unshift(overlay);
            } else {
                state.active_overlays.splice(index, 1);
            }
        }
    },
    getters: {

        getPricePerSqftLegend(){
            return [
                {color: "#84006C", label: "£1001+", min: 1001, max: 99999999999999},
                {color: "#7D147B", label: "£951 - £1000", min: 951, max: 1000},
                {color: "#77278A", label: "£901 - £950", min: 901, max: 950},
                {color: "#703B98", label: "£851 - £900", min: 851, max: 900},
                {color: "#6A4EA7", label: "£801 - £850", min: 801, max: 850},
                {color: "#6362B6", label: "£751 - £800", min: 751, max: 800},
                {color: "#5D75C5", label: "£701 - £750", min: 701, max: 750},
                {color: "#5689D3", label: "£651 - £700", min: 651, max: 700},
                {color: "#509CE2", label: "£601 - £650", min: 601, max: 650},
                {color: "#49B0F1", label: "£551 - £600", min: 551, max: 600},
                {color: "#68C5FF", label: "£501 - £550", min: 501, max: 550},
                {color: "#77C9FD", label: "£451 - £500", min: 451, max: 500},
                {color: "#85CEFB", label: "£401 - £450", min: 401, max: 450},
                {color: "#94D2F9", label: "£351 - £400", min: 351, max: 400},
                {color: "#A3D6F7", label: "£301 - £350", min: 301, max: 350},
                {color: "#B1DBF4", label: "£251 - £300", min: 251, max: 300},
                {color: "#C0DFF2", label: "£201 - £250", min: 201, max: 250},
                {color: "#CFE3F0", label: "£151 - £200", min: 151, max: 200},
                {color: "#DDE8EE", label: "£1 - £150", min: 1, max: 150},
                {color: "#ECECEC", label: "No Data", min: 0, max: 0},
            ]
        },

        getPlanningDataLegend() {
            return [
                {color: planningColors[0], layer: '<= 1 year'},
                {color: planningColors[1], layer: '2+ years'},
                {color: planningColors[2], layer: '3+ years'},
                {color: planningColors[3], layer: '5+ years'},
                {color: planningColors[4], layer: '10+ years'},
            ]
        },

        getEPCRatingDataLegend() {
            let items = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
            return items.map((i) => {
                return {color: getEPCRatingColor(i), label: i}
            })
        },

        getPlanningApplicationDataLegend() {
            return getPlanningObjectColors()
        },


        getSaleDataLegend: (state) => {
            if (state.data_metrics.sale_prices.chart.fetching) {
                return [];
            }
            try {
                return state.data_metrics.sale_prices.chart.data.legend.map((item) => {
                    item.color = saleLegendColors[item.price_group_index];
                    item.icon_color = saleLegendIconColors[item.price_group_index];
                    return item;
                });
            } catch (e) {
                return [];
            }
        },

        getEPCDataMarkers: (state) => {
            let polygons = state.data_metrics && state.data_metrics.epcs ? state.data_metrics.epcs.polygons : [];
            console.log('polygonds', polygons);
            console.log('fetching_polygons', state.data_metrics.epcs.fetching_polygons);

            if (state.data_metrics.epcs.fetching_polygons) {
                return [];
            }
            console.log('EHRE')
            return polygons;
        },
        getLeaseholderDataMarkers: (state) => {
            let polygons = state.data_metrics && state.data_metrics.leaseholders ? state.data_metrics.leaseholders.polygons : [];
            console.log('polygonds', polygons);
            console.log('fetching_polygons', state.data_metrics.leaseholders.fetching_polygons);

            if (state.data_metrics.leaseholders.fetching_polygons) {
                return [];
            }
            console.log('EHRE')
            return polygons;
        },
        getPlanningDataMarkers: (state) => {
            let polygons = state.data_metrics && state.data_metrics.planning ? state.data_metrics.planning.polygons : [];
            console.log('polygonds', polygons);
            console.log('fetching_polygons', state.data_metrics.planning.fetching_polygons);

            if (state.data_metrics.planning.fetching_polygons) {
                return [];
            }
            return polygons.map((item) => {
                if (item.date_received_years <= 1) {
                    item.icon_color = planningColors[0];
                }
                if (item.date_received_years === 2) {
                    item.icon_color = planningColors[1];
                }
                if (item.date_received_years === 3) {
                    item.icon_color = planningColors[2];
                }
                if (item.date_received_years >= 4 && item.date_received_years <= 5) {
                    item.icon_color = planningColors[3];
                }
                if (item.date_received_years >= 6 && item.date_received_years <= 9) {
                    item.icon_color = planningColors[4];
                }
                if (item.date_received_years >= 10) {
                    item.icon_color = planningColors[5];
                }
                return item;
            });
        },
        getSaleDataMarkers: (state) => {

            console.log('ddd', state.data_metrics.sale_prices.search_area );
            if(state.data_metrics.sale_prices.search_area === null){
                return [];
            }

            if (state.data_metrics.sale_prices.chart.fetching) {
                return [];
            }

            if (!state.visible_overlays.includes('salePrices')) {
                return [];
            }

            if(!state.data_metrics.sale_prices.chart.data){
                return [];
            }

            const data = state.data_metrics.sale_prices.chart.data;
            const polygons = data.polygons && Array.isArray(data.polygons) && data.polygons.length > 0 ? data.polygons : [];
            return polygons.map((polygon) => {
                polygon.properties.color = saleLegendColors[polygon.properties.price_group_index];
                polygon.properties.icon_color = saleLegendIconColors[polygon.properties.price_group_index];
                return polygon;
            })
        },
        getMapBoundsAsObject: (state) => {
            if (!state.bounds) {
                return null;
            }
            return {
                sw_lat: state.bounds._southWest.lat,
                sw_lng: state.bounds._southWest.lng,
                ne_lat: state.bounds._northEast.lat,
                ne_lng: state.bounds._northEast.lng
            }
        },
        getGroupedOverlays: (state) => {
            return [
                {
                    title: 'Planning',
                    items: state.overlays.filter((i) => i.group === 'planning')
                },
                {
                    title: 'Property Market',
                    items: state.overlays.filter((i) => i.group === 'property-market')
                },
                {
                    title: 'Areas',
                    items: state.overlays.filter((i) => i.group === 'areas')
                },
                {
                    title: 'Boundaries',
                    items: state.overlays.filter((i) => i.group === 'boundaries')
                }
            ]
        },

        isVisibleLayer: (state) => (layer) => {
            return state.visible_overlays.includes(layer)
        },

        getActiveMapLayers: (state) => {
            return state.active_overlays;
        },

        OwnershipPolygonsVisible: (state) => {
            let find = state.overlays.findIndex((i) => {
                return i.value === 'ownership';
            });
            return find > -1 && state.overlays[find] ? state.overlays[find].visible : false;
        },
        getUseTypes: (state) => {
            return collect(state.use_types).sortBy('label').toArray()
        },

        getUseClassTypes: () => {
            return [
                {value: 'B2', label: 'B2 - General industrial'},
                {value: 'B8', label: 'B8 - Storage or distribution'},
                {value: 'C1', label: 'C1 - Hotel/Motel/Boardings/Guest Houses'},
                {value: 'C2', label: 'C2 - Residential institutions'},
                {value: 'C3', label: 'C3 - Dwellinghouses'},
                {value: 'C4', label: 'C4 - Houses in multiple occupation'},
                {value: 'D1', label: 'D1 - Non-residential institutions'},
                {value: 'D2', label: 'D2 - Assembly and leisure'},
                {value: 'E', label: 'E - Commercial, business and service'},
                {value: 'F1', label: 'F1 - Local Community and Learning'}
            ]
        },


        getSoldPricesLegend: (state) => {
            const legend = state.sale_prices && state.sale_prices.legend && Array.isArray(state.sale_prices.legend) ? state.sale_prices.legend : [];

            return legend.map((item) => {
                return {
                    color: saleLegendColors[item.index].color,
                    label: '£' + item.min + ' - £' + item.max
                }
            })

        },

        getVisibleLayers: (state) => {
            return state.overlays.filter((item) => {
                return item.visible === true;
            });
        },

        isVisibleOverlay: (state) => (overlay) => {
            const active = state.active_overlays.indexOf(overlay) > -1;
            return active ? state.visible_overlays.indexOf(overlay) > -1 : false;
        },


        hasActiveOverlay: (state) => (overlay) => {
            return state.active_overlays.findIndex((i) => {
                return i === overlay
            }) > -1;
        },
        getActiveOverlayDetails: (state) => (overlay) => {
            console.log('getActiveOverlayDetails', overlay);
            let index = state.overlays.findIndex((i) => {
                return i.value === overlay
            });
            return index > -1 ? state.overlays[index] : null;
        },
        isOverlayVisible: (state) => (overlay) => {
            return state.visible_overlays.findIndex((i) => {
                return i === overlay
            }) > -1;
        },

        searchParams: (state) => {
            return {
                area_min: state.polygon_filters.area_min,
                area_max: state.polygon_filters.area_max,
                tenure: state.polygon_filters.tenure,
                ownership: state.polygon_filters.ownership,
                registered_properties_min: state.polygon_filters.registered_properties_min ? parseInt(state.polygon_filters.registered_properties_min) : null,
                registered_properties_max: state.polygon_filters.registered_properties_max ? parseInt(state.polygon_filters.registered_properties_max) : null,
                leaseholders_min: state.polygon_filters.leaseholders_min ? parseInt(state.polygon_filters.leaseholders_min) : null,
                leaseholders_max: state.polygon_filters.leaseholders_max ? parseInt(state.polygon_filters.leaseholders_max) : null,
                use_types: state.polygon_filters.use_types.length > 0 ? state.polygon_filters.use_types : [],
                use_classes: state.polygon_filters.use_classes.length > 0 ? state.polygon_filters.use_classes : [],
            };
        },
    },
    actions: {

        toggleOverlayAvailability({state}, overlay) {
            let activeOverlayIndexes = state.active_overlays.reduce((a, e, i) => {
                if (e === overlay)
                    a.push(i);
                return a;
            }, []);

            let visibleOverlayIndexes = state.visible_overlays.reduce((a, e, i) => {
                if (e === overlay)
                    a.push(i);
                return a;
            }, []);

            if (activeOverlayIndexes.length > 0) {
                console.log('removin')
                for (let i of activeOverlayIndexes) {
                    state.active_overlays.splice(i, 1);
                }
                for (let i of visibleOverlayIndexes) {
                    state.visible_overlays.splice(i, 1);
                }
            } else {
                console.log('Adding')
                state.active_overlays.unshift(overlay);
                state.visible_overlays.unshift(overlay);
            }


        },

        fetchTitleNumberPolygons: ({rootState, state}, titleNumber) => {
            state.loading_polygons = true;
            state.show_freeholder_icons = false;

            try {
                cancelTokenSource.cancel();
            } catch (e) {
                console.warn(e);
            }
            cancelTokenSource = api.CancelToken.source();
            return api.get('/api/v1/team/' + rootState.auth.user.team.id + '/title-number/' + titleNumber, {
                cancelToken: cancelTokenSource.token
            });
        },

        searchSalePrices: ({rootState}, object) => {
            return api.get('/api/v1/team/' + rootState.auth.user.team.id + '/sale-prices', {
                params: object
            });
        },

        searchLeaseholders: ({rootState}, object) => {
            return api.get('/api/v1/team/' + rootState.auth.user.team.id + '/leaseholders', {
                params: object
            });
        },

        searchCommercialAvailability: ({rootState}, object) => {
            return api.get('/api/v1/team/' + rootState.auth.user.team.id + '/commercial-availability', {
                params: object
            });
        },
        searchEPCs: ({rootState}, object) => {
            return api.get('/api/v1/team/' + rootState.auth.user.team.id + '/epcs', {
                params: object
            });
        },
        searchCurrentOccupiers: ({rootState}, object) => {
            return api.get('/api/v1/team/' + rootState.auth.user.team.id + '/current-occupiers', {
                params: object
            });
        },

        searchHMOs: ({rootState}, object) => {
            return api.get('/api/v1/team/' + rootState.auth.user.team.id + '/hmos', {
                params: object
            });
        },

        searchMarketedSales: ({rootState}, object) => {
            return api.get('/api/v1/team/' + rootState.auth.user.team.id + '/known-marketed-sales', {
                params: object
            });
        },

        searchPlanning: ({rootState}, object) => {
            return api.get('/api/v1/team/' + rootState.auth.user.team.id + '/planning-applications', {
                params: object
            });
        },

        fetchPolygonFromUPRN: ({rootState}, uprn) => {
            return api.get('/api/v1/team/' + rootState.auth.user.team.id + '/uprn/' + uprn);
        },
        searchCorporateOwnership: ({rootState}, object) => {
            return api.get('/api/v1/team/' + rootState.auth.user.team.id + '/corporate-ownership', {
                params: object
            });
        },


        searchPrices: ({rootState, state}, {lat, lng}) => {
            return api.get('/api/v1/team/' + rootState.auth.user.team.id + '/sale-prices', {
                cancelToken: cancelTokenSource.token,
                params: {lat, lng, radius: state.polygon_filters.radius, filters: []}
            }).then((response) => {
                state.fetching = false;
                return response.data.data;
            }).catch(() => {
                state.fetching = false;
                return {}
            });
        },

        fetch: ({rootState, state}, {inspireId}) => {

            state.fetching = true;
            console.log('Fetching... ' + inspireId);


            return api.get('/api/v1/team/' + rootState.auth.user.team.id + '/ownership/' + inspireId)
                .then((response) => {
                    state.fetching = false;
                    return response.data.data;
                }).catch(() => {
                    state.fetching = false;
                    return {}
                });

        },
        closeOverlay: ({state}, overlay) => {
            for (let arr of ['active_overlays', 'visible_overlays']) {
                let activeIndexes = [], i = -1;
                while ((i = state[arr].indexOf(overlay, i + 1)) != -1) {
                    activeIndexes.push(i);
                }
                console.log('activeIndexes', activeIndexes);
                for (let index of activeIndexes) {
                    state[arr].splice(index, 1);
                }
            }
        },
        toggleOverlayVisibility: ({state, getters}, {overlay}) => {
            const isVisibleOverlay = getters.isVisibleOverlay(overlay);

            console.log('isVisibleOverlay', isVisibleOverlay);
            if (isVisibleOverlay) {

                let activeIndexes = [], i = -1;
                while ((i = state.visible_overlays.indexOf(overlay, i + 1)) != -1) {
                    activeIndexes.push(i);
                }
                for (let index of activeIndexes) {
                    state.visible_overlays.splice(index, 1);
                }

            } else {
                state.visible_overlays.push(overlay);
            }
        }
    }
}