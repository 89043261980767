import collect from 'collect.js';

export function paginate({results, page, rpp, sort, order}) {
  console.log(sort, );
  if(sort && order){
    results = order === 'descending' ? collect(results).sortByDesc(sort) : collect(results).sortBy(sort);
    results = results.toArray();
  }
  return {
    results: results.slice((page - 1) * rpp, page * rpp),
    meta: {
      rpp: rpp,
      current: page,
      total: results.length
    }
  };
}