<template>

    <page v-loading="loading">

        <template slot="header">
            <page-header
                    :title="(invites.length > 1 ? 'You\'ve got some Invites!' : 'You\'ve got an Invite!') " />
        </template>

        <template slot="content">

            <dashboard-widget
                    title="Your Invites"
                    padding-class="p-6"
                    v-loading="loading"
            >
                <div class="text-center">
                    <div class="mx-auto flex items-center h-full justify-center">
                        <div class="columns w-full lg:w-auto">
                            <div class="column w-full">
                                <h2 class="leading-none text-2xl font-semibold mb-3">

                                    {{ (invites.length > 1 ? 'You have  been invited to join some teams.' : 'You have  been invited to join a team.') }}
                                </h2>

                                <div class="m-8 mx-auto">
                                    <lottie :options="{animationData: animationData}" :height="100"  />
                                </div>
                                <div class="leading-loose text-sm">
                                    <p class="mb-1">Select which team(s) you would like to join below or skip. You can always answer invites at a later date.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="rounded border mx-auto max-w-md border-gray-400 my-4 mb-2" v-if="invites.length > 0">
                    <el-checkbox-group
                            :disabled="running"
                            v-model="selected" class="el-block--invite-options">
                        <el-checkbox
                                :disabled="running"
                                :key="k"
                                v-for="(i, k) of invites"  :label="i.id">
                            {{ i.name }}
                            <div class="text-xs">

                                <date-human-view prefix="Invited " v-model="i.invited" />
                            </div>
                        </el-checkbox>
                    </el-checkbox-group>
                </div>

                <div class="py-3 text-center flex justify-center items-center">
                   <div class="p-2">
                       <el-button
                               @click="handleInvites"
                               :loading="running"
                               type="success" :disabled="running || !selected.length">Join Teams</el-button>
                   </div>
                    <div class="p-2">
                        <el-link
                                @click="handleInvites"
                        :disabled="running"
                        >Skip for now</el-link>
                    </div>
                </div>

            </dashboard-widget>


        </template>
    </page>

</template>

<script>
    import Lottie from "vue-lottie";
    import * as animationData from "./../components/incs/Animations/gift";

    export default {
        name: "OutstandingInvites",
        components: {
          Lottie
        },
        data(){
          return {
              loading: true,
              running: false,
              invites: [],
              selected: [],
              animationData: animationData.default
          }
        },
        computed: {
          getActionList(){
              return this.invites.map((i)=>{
                return {
                    id: i.id,
                    skip: !this.selected.includes(i.id)
                }
              })
          }
        },
        methods: {

          handleInvites(){
              const vm = this;
              vm.running = true;
              vm.$store.dispatch('invites/updateOutstanding', {
                  invites: vm.getActionList
              })
                  .then(()=>{

                      vm.$http.get('/api/v1/auth/user')
                          .then((response)=>{
                              vm.$store.commit('auth/setUser', response.data.data);
                              vm.$router.push('/')
                          })


              })
                  .catch(()=>{

                  })
          }
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {

                vm.loading = true;
                vm.$store.commit('auth/setBreadcrumbs', [
                    {
                        title: 'Invites',
                    }
                ]);


                if(!vm.$store.state.auth.user.has_outstanding_invites){
                    console.log('No invites outstanding...')
                    vm.$store.commit('auth/setUserObject', {
                        key: 'has_outstanding_invites',
                        value: false
                    });
                    vm.$router.push('/')
                }else{
                    console.log('Fetching invites...', vm.$store.state.auth.user.has_outstanding_invites)
                    vm.$store.dispatch('invites/outstanding')
                        .then((response) => {

                            vm.invites = response.data.data;

                            if(!vm.invites.length){
                                return vm.$http.get('/api/v1/auth/user')
                                    .then((response)=>{
                                        vm.$store.commit('auth/setUser', response.data.data);
                                        vm.$router.push('/')
                                    })
                            }


                            vm.loading = false;

                        }).catch(() => {
                        vm.loading = false;

                    })
                }

            })
        }
    }
</script>

<style lang="scss">
    .el-block--invite-options {
        .el-checkbox {
            display: flex !important;
            align-items: center;
            padding: 0.5rem;
            border-bottom: #dddddd 1px solid;
            margin-right: 0;
        }
    }
</style>