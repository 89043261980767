<template>
<div>
    <l-map
            ref="crime-map"
            style="height: 220px;"
            :options="{zoomControl: false}"
    >

        <l-marker
                :key="index"
                v-for="(prop, index) of value"
                :lat-lng="{
                                lat: prop.lat,
                                lng: prop.lon
                                }">
            <l-icon>

                <div class="w-4 h-4 flex text-white border border-white shadow items-center justify-center shadow-lg rounded-full bg-blue-600 text-xxs ">
                    {{ index + 1 }}
                </div>
            </l-icon>
        </l-marker>

        <l-google-tile-layer
                ref="googleLayer"
                :apikey="$store.state.auth.map.google.key"
                :options="{type: 'hybrid'} "/>

    </l-map>
</div>
</template>

<script>
    export default {
        name: "OwnershipUprnMap",
        props: {
            value: {
              type: Array,
              default(){
                  return [];
              }
            },
            bbox: {
                type: Array,
                default(){
                    return [];
                }
            }
        },
        mounted(){
            if (this.$refs && this.$refs['crime-map'] && this.bbox && this.bbox.length === 4) {
                this.$refs['crime-map'].mapObject.fitBounds([
                    [this.bbox[1], this.bbox[0]],
                    [this.bbox[3], this.bbox[2]],
                ]);

                //this.map.center = this.crime.center;
            }
        },
    }
</script>

<style scoped>

</style>