<template>

        <page>

            <template slot="header">
                <page-header title="Workflows">
                    <template v-slot:tabs>
                        <workflow-nav />
                    </template>
                </page-header>

            </template>

            <template slot="content">
                <div class="columns">
                    <div class="column w-full">
                        <dashboard-widget
                                title="Workflows"
                                padding-class="">

                            <empty-state class="p-3"
                                         title="No results found"
                                         message="Sorry, but we could not find any Workflows"
                                         :support="false"
                                         v-if="!table.loading && !table.results.length"/>


                            <div class="p-3 py-12 text-center" v-if="table.loading">
                                <property-loader />
                            </div>


                            <div v-if="table.results.length > 0 && !table.loading">
                                <div class="p-3">
                                    <el-alert effect="dark" type="info" :closable="false">
                                        {{ table.meta.totalResult.toLocaleString() }} workflows found. Displaying page {{ table.meta.current }} of {{ table.meta.pageCount }}
                                    </el-alert>
                                </div>
                                <div class="columns p-3">
                                    <div class="column ml-auto">
                                        <el-pagination
                                                background
                                                layout="prev, pager, next"
                                                @current-change="(page)=>{ fetch(page) }"
                                                :page-size="table.rpp"
                                                :current-page="table.meta.current"
                                                :total="table.meta.totalResult">
                                        </el-pagination>
                                    </div>
                                </div>
                                <el-table
                                        :default-sort="table.default_sort"
                                        @sort-change="onSortChange"
                                        :data="table.results">
                                    <el-table-column
                                            prop="name"
                                            sortable="custom"
                                            label="Name">
                                        <template slot-scope="scope">
                                          <span class="font-bold">  {{ scope.row.name }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            width="200"
                                            align="right"
                                            prop="created_at"
                                            sortable="custom"
                                            label="Date Created">
                                        <template slot-scope="scope">
                                            <date-human-view v-model="scope.row.created_at" />
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            width="110"
                                            align="right"
                                            prop="columns"
                                            sortable="custom"
                                            label="Columns">
                                        <template slot-scope="scope">
                                           {{ scope.row.columns.toLocaleString() }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            width="150"
                                            align="right"
                                            prop="title_numbers"
                                            sortable="custom"
                                            label="Title Numbers">
                                        <template slot-scope="scope">
                                            {{ scope.row.title_numbers.toLocaleString() }}
                                        </template>
                                    </el-table-column>


                                    <el-table-column width="140" align="right">
                                        <template slot-scope="scope">

                                            <el-dropdown @command="(cmd)=>{ handleCommand(cmd, scope.row.id)  }">
                                                <el-button size="medium" type="info">
                                                    Actions <i class="el-icon-arrow-down el-icon--right"></i>
                                                </el-button>
                                                <el-dropdown-menu slot="dropdown">
                                                    <el-dropdown-item command="view">
                                                        View
                                                    </el-dropdown-item>
                                                    <el-dropdown-item command="remove">
                                                        Delete Workflow
                                                    </el-dropdown-item>
                                                </el-dropdown-menu>
                                            </el-dropdown>

                                        </template>
                                    </el-table-column>
                                </el-table>
                                <div class="columns p-3">
                                    <div class="column ml-auto">
                                        <el-pagination
                                                background
                                                layout="prev, pager, next"
                                                @current-change="(page)=>{ fetch(page) }"
                                                :page-size="table.rpp"
                                                :current-page="table.meta.current"
                                                :total="table.meta.totalResult">
                                        </el-pagination>
                                    </div>
                                </div>
                            </div>


                        </dashboard-widget>
                    </div>
                </div>
            </template>

        </page>


</template>
<script>
    import PropertyLoader from "../components/incs/PropertyLoader";
    import EmptyState from "../components/EmptyState";
    import WorkflowNav from "./Workflows/WorkflowNav";
    export default {
        name: 'Workflows',
        components: {WorkflowNav, EmptyState, PropertyLoader},
        methods: {
            handleCommand(command, id){
                switch(command){
                    case "view":
                        this.$router.push('/workflow/' + id);
                        break;
                    case "remove":
                        return this.remove(id);
                }
            },
            formatRadius(radius){
                return radius < 1000 ? radius + 'm' : (radius / 1000) + 'km'
            },
            remove(workflowId){
                const vm = this;
                vm.$confirm(
                    'Are you sure you want to delete this workflow? This cannot be undone and will be removed permanently.',
                    'Delete Workflow?',
                    {
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'Cancel'
                    })
                    .then(() => {
                        vm.table.loading = true;
                        vm.$store.dispatch('workflow/delete', {
                            workflowId: workflowId
                        }).then(()=>{
                            vm.$notify({
                                position: 'top-right',
                                title: 'Workflow Deleted',
                                message: 'Workflow deleted successfully.',
                                type: 'success',
                                offset: 140
                            });
                            vm.fetch(1);
                        }).catch((e)=>{
                            vm.$store.dispatch('auth/handleServerError', {
                                vm: vm,
                                error: e
                            });
                            vm.table.loading = false;
                        })

                    })
                    .catch(() => {

                    })
            },
            onSortChange({column}) {
                this.table.default_sort.order = column.order;
                this.table.default_sort.prop = column.property;
                this.fetch(1);
            },
            fetch(page) {
                const vm = this;
                vm.table.loading = true;
                return vm.$store.dispatch('workflow/index', {
                    params: {
                        page: page || 1,
                        rpp: vm.table.rpp,
                        sort: vm.table.default_sort && vm.table.default_sort.prop ? vm.table.default_sort.prop : null,
                        order: vm.table.default_sort && vm.table.default_sort.order ? vm.table.default_sort.order === 'descending' ? 'desc' : 'asc' : null,
                    }
                }).then((response) => {
                    vm.table.loading = false;
                    vm.table.results = response.data.data.results;
                    vm.table.meta = response.data.data.meta;
                }).catch(() => {
                    vm.table.loading = false;
                    vm.table.results = [];
                    vm.table.meta = {};
                })

            }
        },
        mounted(){
          this.fetch(1);
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.$store.commit('auth/setBreadcrumbs', [
                    {
                        title: 'Workflows'
                    }
                ]);
            })
        },
        data(){
            return {
                table: {
                    loading: true,
                    results: [

                    ],
                    default_sort: {prop: 'created_at', order: 'descending'},
                    rpp: 10,
                    meta: {}
                }
            }
        }
    }
</script>

