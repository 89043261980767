<template>
     <div>
         <div class="columns">
             <div class="column w-full">

                 <dashboard-widget
                         padding-class=""
                         title="Known Marketed Listings">
                     <slot>
                         <div class="p-3" v-if="!propertiesList.length">
                             <empty-state />
                         </div>

                         <div v-if="propertiesList.length > 0">

                             <div class="p-2">
                                 <el-alert
                                         effect="dark" type="success" :closable="false">
                                     {{ propertiesList.length }} known marketed listings within this site
                                 </el-alert>
                             </div>

                             <el-collapse
                                     class="el-collapse--listed"
                                     v-model="selected">
                                 <el-collapse-item
                                         :key="i"
                                         v-for="(item, i) of propertiesList" :name="i">


                                     <template slot="title">
                                         <div class="flex flex-wrap w-full flex-row">
                                             <div class="w-full py-2 pb-3">
                                                 {{ item.single_line_address }}
                                             </div>
                                             <div class="text-xs w-full flex">
                                                 <div class="mr-3">
                                                     <span>{{ item.total_listings }} listings</span>
                                                 </div>
                                                 <div class="mr-3">
                                                     <span>Last listed </span> <date-human-view size="text-xs" v-model="item.last_listed" />
                                                 </div>
                                             </div>
                                         </div>
                                     </template>


                                     <el-table border stripe :data="item.listings">
                                         <el-table-column
                                                 prop="date"
                                                 width="115"
                                                 label="Date Listed">
                                             <template slot-scope="scope">
                                                 <div class="text-xs">
                                                     <date-human-view size="text-xs" v-model="scope.row.date_listed" />
                                                 </div>
                                             </template>
                                         </el-table-column>
                                         <el-table-column
                                                 prop="listing_type"
                                                 label="Listing Type">
                                         </el-table-column>
                                     </el-table>

                                 </el-collapse-item>
                             </el-collapse>


                         </div>



                     </slot>
                 </dashboard-widget>



             </div>
         </div>
     </div>
</template>

<script>
    import EmptyState from "../../EmptyState";
    export default {
        name: "OwnershipKnownListings",
        components: {EmptyState},
        props: {
            value: {
                type: Array,
                default(){
                    return []
                }
            },
            activeSiteId: {
                type: Number,
                default(){
                    return null;
                }
            }
        },
        computed: {
            propertiesList(){
                if(this.activeSiteId){
                    return this.value.filter((i)=>{
                        return i.site_id === this.activeSiteId;
                    })
                }
                return this.value
            }
        },
        data(){
            return {
                selected: null
            }
        }
    }
</script>

<style>
    .el-collapse--listed  .el-collapse-item__header {
        line-height: 15px !important;
        height: auto !important;
        padding: 10px !important;
    }

    .el-collapse-item__content {
        padding: 0 !important;
    }

</style>