<template>
    <div>
        <slot></slot>
        <div class="break-page"></div>
    </div>
</template>

<script>
    export default {
        name: "PdfPage"
    }
</script>

<style>
</style>