<template>
    <div class="columns">


        <div class="column w-full">
            <dashboard-widget
                    title="Overview">


                <template slot="cta">
                    <el-button
                            v-if="showSnapTo"
                            @click="$emit('snap', value.area.total.bbox)"
                            size="small" type="info" icon="el-icon-position">
                        Snap to
                    </el-button>
                </template>


                <ownership-overview-map
                        :coords="value.area.total.center"
                        :polygons="value.polygons"
                        :bbox="value.area.total.bbox"
                        class="mb-5"
                />


                <slot>
                    <div class="columns">
                        <div class="column mr-auto text-xs text-gray-500 font-medium flex items-center">
                            Tenure
                        </div>
                        <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                            <pretty-tenure v-if="value.tenure" v-model="value.tenure" />
                            <span v-if="!value.tenure">Unknown</span>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column mr-auto text-xs text-gray-500 font-medium flex items-center">
                            Title Number
                        </div>
                        <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                            {{ value.title_number ? value.title_number : 'Unknown' }}
                        </div>
                    </div>
                    <div class="columns flex-no-wrap">
                        <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                            Address
                        </div>
                        <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                            {{ value.address ? value.address : 'Unknown' }}
                        </div>
                    </div>


                    <div class="columns">
                        <div class="column mr-auto text-xs text-gray-500 font-medium flex items-center">
                            Ownership
                        </div>
                        <div class="column w-auto text-xs text-gray-900 flex items-center">
                            <i v-if="isPrivateOwnership" class="el-icon-lock mr-1"></i>
                            {{ isPrivateOwnership ? 'Privately Owned' : 'Company Owned' }}
                        </div>
                    </div>

                    <div class="columns flex-no-wrap" v-if="value.area && value.area.sqm">
                        <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                            Area (m²)
                        </div>
                        <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                            {{ Math.round(value.area.total.area.sqm).toLocaleString() }}m²
                        </div>
                    </div>
                    <div class="columns flex-no-wrap" v-if="value.area && value.area.acres">
                        <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                            Area (acres)
                        </div>
                        <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                            {{ value.area.total.area.acres.toLocaleString() }} acres
                        </div>
                    </div>

                    <div class="columns flex-no-wrap" v-if="value.area && value.area.hectares">
                        <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                            Area (hectares)
                        </div>
                        <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                            {{ value.area.total.area.hectares.toLocaleString() }} hectares
                        </div>
                    </div>

                    <div class="columns flex-no-wrap">
                        <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                            What 3 Words
                        </div>
                        <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                            {{ value.area.total.w3w ? value.area.total.w3w : '' }}
                        </div>
                    </div>
                    <div class="columns flex-no-wrap">
                        <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                            Latitude
                        </div>
                        <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                            {{ value.area.total.center.lat.toFixed(6) }}
                        </div>
                    </div>
                    <div class="columns flex-no-wrap">
                        <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                            Longitude
                        </div>
                        <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                            {{ value.area.total.center.lon.toFixed(6) }}
                        </div>
                    </div>


                    <div class="flex -mx-2 mt-4">
                        <div class="w-1/2 p-2">
                            <el-button
                                    class="w-full"
                                    size="medium"
                                    @click="openGoogle"
                                    icon="el-icon-location"
                                    type="success">
                                View on Google Maps
                            </el-button>
                        </div>
                        <div class="w-1/2 p-2">
                            <el-button
                                    class="w-full"
                                    size="medium"
                                    @click="openBirdseyeView"
                                    icon="el-icon-view"
                                    type="info">
                                Open Birdseye View
                            </el-button>
                        </div>
                    </div>

                </slot>
            </dashboard-widget>
        </div>
        <div class="column w-full">
            <dashboard-widget title="Land Monitor Report">
                <p class="text-xs leasing-leading-loose mb-1 font-semibold">Download a free comprehensive Land Monitor report by clicking the button below.</p>
                <p class="text-xs leasing-leading-loose mb-1">Once it has been complied it will be in your team documents along with a email to access straight away.</p>
                <div class="mt-4">
                    <el-button
                            class="w-full"
                            size="medium"
                            :disabled="!value.inspire_id || downloading"
                            :loading="downloading"
                            @click="downloadReport"
                            icon="el-icon-data-analysis"
                            type="success">
                       Download Land Monitor Report
                    </el-button>
                </div>
            </dashboard-widget>
        </div>

        <div class="column w-full" v-if="hasLocalPlan">
            <dashboard-widget title="Local Plan" padding-class="">
                <slot>
                    <el-table border stripe :data="value.local_plan">
                        <el-table-column
                                prop="name"
                                label="name">
                            <template slot-scope="scope">
                                <span class="font-semibold">{{ scope.row.plan_name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="plan"
                                width="85"
                                align="right"
                                label="Plan">
                            <template slot-scope="scope">
                                <el-button
                                        @click="openLink(scope.row.plan_link + '/index.html#organisation')"
                                        type="info" size="small">View</el-button>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="brown"
                                width="110"
                                align="right"
                                label="Brownfield">
                            <template slot-scope="scope">
                                <el-button
                                        @click="openLink(scope.row.authority_link + '#accordion-related-data-heading-1')"
                                        type="info" size="small">View</el-button>
                            </template>
                        </el-table-column>
                    </el-table>

                </slot>
            </dashboard-widget>
        </div>

        <div class="column w-full" v-if="isPrivateOwnership">
            <dashboard-widget
                    title="Owner Details">
                <slot>

                    <div class="el-block--private-ownership">
                        <p class="text-xs leasing-leading-loose mb-1">This property is privately owned.</p>
                        <p class="text-xs leasing-leading-loose mb-1">This information is currently held by HM Land Registry.</p>
                    </div>

                    <div class="mt-4">
                        <el-button
                                class="w-full"
                                size="medium"
                                :disabled="!value.title_number"
                                @click="openLandRegistryLink"
                                icon="el-icon-search"
                                type="info">
                            Find Owner
                        </el-button>
                    </div>


                </slot>
            </dashboard-widget>
        </div>


        <div class="column w-full">
            <dashboard-widget
                    title="HM Land Registry">
                <slot>

                    <div class="el-block--private-ownership">
                        <p class="text-xs leasing-leading-loose mb-1">In order to find the owner you will need to purchase the title number.</p>
                        <p class="text-xs leasing-leading-loose mb-1 font-semibold">Click below and we will copy the title number and open a new window.</p>

                        <div class="mt-4">
                            <el-button
                                    class="w-full"
                                    size="medium"
                                    :disabled="!value.title_number"
                                    @click="openLandRegistryLink"
                                    icon="el-icon-document"
                                    type="info">
                                Purchase Documents
                            </el-button>
                        </div>

                    </div>


                </slot>
            </dashboard-widget>
        </div>



        <div class="column w-full" v-if="!isPrivateOwnership">
            <dashboard-widget
                    title="Purchase Information">
                <slot>

                    <div class="columns flex-no-wrap" v-if="value.company_ownership.tenure">
                        <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                                Tenure
                        </div>
                        <div class="column ml-auto text-xs text-right text-gray-900 flex values-center">
                            {{ value.company_ownership.tenure }}
                        </div>
                    </div>

                    <div class="columns flex-no-wrap" v-if="value.company_ownership.property_address">
                        <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                            Property Address
                        </div>
                        <div class="column ml-auto text-xs text-right text-gray-900 flex values-center">
                            {{ value.company_ownership.property_address }}

                        </div>
                    </div>

                    <div class="columns flex-no-wrap" v-if="value.company_ownership.price_paid">
                        <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                            Price Paid
                        </div>
                        <div class="column ml-auto text-xs text-right text-gray-900 flex values-center">
                            £{{ value.company_ownership.price_paid.toLocaleString() }}
                        </div>
                    </div>


                    <div class="columns flex-no-wrap" v-if="value.company_ownership.date_proprietor_added">
                        <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                            Date Purchased
                        </div>
                        <div class="column ml-auto text-xs text-right text-gray-900 flex values-center">
                            <div class="break-normal h-full text-xs leading-tight">
                                <date-human-view size="text-xs" v-model="value.company_ownership.date_proprietor_added" />
                            </div>
                        </div>
                    </div>


                    <div class="columns flex-no-wrap" v-if="value.date_proprietor_added">
                        <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                            Date Purchased
                        </div>
                        <div class="column ml-auto text-xs text-right text-gray-900 flex values-center">
                            {{ value.date_proprietor_added }}
                        </div>
                    </div>


                </slot>
            </dashboard-widget>
        </div>

        <div class="column w-full m-0 p-0"  v-if="!isPrivateOwnership">
            <div class="columns w-full m-0 p-0">
                <div
                        :key="i"
                        v-for="(item, i) of value.company_ownership.proprietors"
                        class="column w-full">
                    <dashboard-widget
                            :title="'Proprietor (' + ( i + 1) + ')'">
                        <slot>


                            <h3 class="uppercase block text-sm font-semibold text-blue-900 mb-2">
                                Company Overview
                            </h3>

                            <corporate-ownership-details
                                    class="py-2"
                                    :value="item"
                            />

                            <h3
                                    v-if="item.cash_in_bank || item.turnover || item.net_assets"
                                    class="uppercase block text-sm font-semibold text-blue-900 mb-2">
                                Financials
                            </h3>

                            <corporate-ownership-financials
                                    class="py-2"
                                :value="item"
                            />

                            <h3
                                    v-if="item.directors && item.directors.length > 0"
                                    class="uppercase block text-sm font-semibold text-blue-900 mb-2">
                                Directors
                            </h3>

                            <corporate-ownership-directors
                                    :value="item"
                            />


                            <div class="columns" v-if="showFindProperties && item.hash">
                                <div class="column" :class="item.proprietor_company_registration_no ? 'w-1/2' : 'w-full'">
                                    <el-button
                                            class="w-full"
                                            size="small"
                                            @click="$emit('open-corporate-ownership', item.hash)"
                                            icon="el-icon-search"
                                            type="success">View Properties & Finances</el-button>
                                </div>
                            <div class="column w-1/2" v-if="item.proprietor_company_registration_no">
                                <el-button
                                        size="small"
                                        type="info"
                                        @click="openContactSearch(item.proprietor_name)"
                                        icon="el-icon-postcard"
                                        class="w-full">
                                    Find Contacts
                                </el-button>
                            </div>
                            </div>





                        </slot>
                    </dashboard-widget>
                </div>
            </div>
        </div>



    </div>
</template>

<script>
    import CorporateOwnershipFinancials from "./Corporate/CorporateOwnershipFinancials";
    import CorporateOwnershipDirectors from "./Corporate/CorporateOwnershipDirectors";
    import CorporateOwnershipDetails from "./Corporate/CorporateOwnershipDetails";
    import OwnershipOverviewMap from "./incs/OwnershipOverviewMap";

    import {openCompanySearch, openContactSearch, openBirdseyeView, openGoogleMaps} from "./../../../utils/external";
    import PrettyTenure from "../../PrettyTenure";

    export default {
        name: "OwnershipOverview",
        components: {
            PrettyTenure,
            OwnershipOverviewMap,
            CorporateOwnershipDetails, CorporateOwnershipDirectors, CorporateOwnershipFinancials},
        props: {
            value: {
                type: Object,
                default(){
                    return {}
                }
            },
            showFindProperties: {
                type: Boolean,
                default: true
            },
            showSnapTo: {
                type: Boolean,
                default: true
            }
        },
        data(){
          return {
              downloading: false,
              map: {
                  url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                  zoom: 3
              },
              data: this.value
          }
        },
        methods: {
            downloadReport(){
                const vm = this;
                vm.downloading = true;

                this.$http.post('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/ownership/' + this.value.inspire_id + '/report')
                    .then(()=>{
                        vm.$notify({
                            type: 'success',
                            title: 'Creating Land Monitor Report',
                            message: 'We are currently creating your report. We will notify you when its ready.',
                            position: 'top-right',
                            offset: 140,
                        });
                        vm.downloading = false;
                    })
                    .catch((e)=>{
                        vm.$store.dispatch('auth/handleServerError', {
                            vm: vm,
                            error: e
                        });
                        vm.downloading = false;
                    })
            },
            openLink(url){
                window.open(url);
            },
            openLandRegistryLink(){
                this.$clipboard(this.value.title_number);
                window.open('https://eservices.landregistry.gov.uk/eservices/FindAProperty/view/DetailedEnquiryInit.do#titleNo')
            },
            openGoogle(){
                openGoogleMaps({
                    lat: this.value.area.total.center.lat,
                    lng:  this.value.area.total.center.lon
                })
            },
            openBirdseyeView(){
                openBirdseyeView({
                    lat: this.value.area.total.center.lat,
                    lng:  this.value.area.total.center.lon
                })
            },
            openCompany(companyName){
                openCompanySearch(companyName);
            },
            openContactSearch(companyName){
                openContactSearch(companyName);
            }
        },
        computed: {
            isPrivateOwnership(){
                return this.value && this.value.is_corporate_ownership ? this.value.is_corporate_ownership !== true : true;
            },
            hasLocalPlan(){
                return this.value && this.value.local_plan && Array.isArray(this.value.local_plan) && this.value.local_plan.length > 0;
            }
        }
    }
</script>

<style scoped>

</style>