<template>
<div>
    <div
            v-if="loading"
            class="flex h-full mt-12 pt-12 items-center justify-center">
        <div class="p-3 text-center">
            <property-loader />
            <h3 class="font-semibold text-blue-800">Fetching Sales...</h3>
        </div>
    </div>

    <div class="columns"
         v-if="!loading"
    >
        <div class="column w-1/2" v-if="stats && stats.transactions">
        <stat-box
                custom-class="border border-gray-200 rounded" title="Transactions"
                padding-class="p-3"
                font-size="text-xl"
                label-size="text-xs"
                :loading="false"
                :value="stats.transactions.toLocaleString()"
        />
        </div>
        <div class="column w-1/2" v-if="stats && stats.volume">
            <stat-box
                    custom-class="border border-gray-200 rounded"
                    title="Total Volume"
                    padding-class="p-3"
                    font-size="text-xl"
                    label-size="text-xs"
                    :loading="false"
                    :prefix="'£'"
                    :value="stats.volume.toLocaleString()"
            />
        </div>
        <div class="column w-1/2" v-if="stats && stats.last_sale">
            <stat-box
                    custom-class="border border-gray-200 rounded" title="Last Sale"
                    padding-class="p-3"
                    font-size="text-xl"
                    label-size="text-xs"
                    :loading="false"
                    :value=" $moment(stats.last_sale).format('MMM Do YYYY')"
            />
        </div>
        <div class="column w-1/2" v-if="stats && stats.avg">
            <stat-box
                    custom-class="border border-gray-200 rounded"
                    title="Avg. Price"
                    padding-class="p-3"
                    font-size="text-xl"
                    label-size="text-xs"
                    :loading="false"
                    :prefix="'£'"
                    :value="stats.avg.toLocaleString()"
            />
        </div>
        <div class="column w-1/2" v-if="stats && stats.min">
            <stat-box
                    custom-class="border border-gray-200 rounded"
                    title="Min. Price"
                    padding-class="p-3"
                    font-size="text-xl"
                    label-size="text-xs"
                    :loading="false"
                    :prefix="'£'"
                    :value="stats.min.toLocaleString()"
            />
        </div>
        <div class="column w-1/2" v-if="stats && stats.max">
            <stat-box
                    custom-class="border border-gray-200 rounded"
                    title="Max. Price"
                    padding-class="p-3"
                    font-size="text-xl"
                    label-size="text-xs"
                    :loading="false"
                    :prefix="'£'"
                    :value="stats.max.toLocaleString()"
            />
        </div>

        <div class="column w-full">
            <dashboard-widget
                    padding-class="''"
                    title="Property Sale History">
                <slot>


                    <div class="p-3" v-if="!propertiesList || !propertiesList.length">
                        <empty-state />
                    </div>


                    <div  v-if="propertiesList && Array.isArray(propertiesList) && propertiesList.length > 0">
                        <div
                                class="el-collapse--sale-prices">

                            <el-collapse v-model="activeProperties">
                                <el-collapse-item
                                        :key="item.uprn"
                                        :name="item.uprn" v-for="(item) of propertiesList">


                                    <template slot="title">
                                        <div class="flex flex-wrap w-full flex-row">
                                            <div class="w-full py-2 pb-3">
                                                {{ item.address }}
                                            </div>
                                            <div class="text-xs w-full flex">


                                                <div class="mr-3">
                                                    <span> {{ item.total_sales }} Sales</span>
                                                </div>
                                                <div class="mr-3">
                                                    <span>Last Sold</span> <date-human-view size="text-xs" v-model="item.last_sold" /> for
                                                    £{{ item.last_sold_price.toLocaleString() }}
                                                </div>
                                            </div>
                                        </div>
                                    </template>

                                    <div class="p-3"
                                         v-if="activeProperties.length > 0 && activeProperties.indexOf(item.uprn) > -1"
                                    >

                                        <ownership-property-sales-chart
                                                :categories="item.chart.categories"
                                                :series="item.chart.series"
                                        />

                                    </div>

                                    <el-table
                                            row-class-name="text-xs"
                                            border stripe :data="item.sales">
                                        <el-table-column
                                                prop="date"
                                                label="Date">
                                            <template slot-scope="scope">
                                                <div class="text-xs">
                                                    <date-human-view size="text-xs" v-model="scope.row.date" /><br />
                                                    <span v-if="scope.row.new_build">
                                                    <i class="el-icon-check text-green-600"></i> New Build Property
                                              </span>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                                prop="tenure"
                                                width="100"
                                                label="Tenure">
                                        </el-table-column>
                                        <el-table-column
                                                prop="price_per_ft2"
                                                width="100"
                                                align="right"
                                                label="Price (ft²)">
                                            <template slot-scope="scope">
                                                {{ scope.row.price_per_sqft ? '£' + scope.row.price_per_sqft.toLocaleString() : null }}
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                                prop="price"
                                                align="right"
                                                class-name="leading-tight"
                                                label="Price">
                                            <template slot-scope="scope">
                                                {{ scope.row.price_paid ? '£' + scope.row.price_paid.toLocaleString() : null }}<br />
                                                <small v-if="scope.row.sale_price_paid_difference">
                                                <span :class="{
                                                'text-green-500': scope.row.sale_price_paid_difference > 0,
                                                'text-red-500': scope.row.sale_price_paid_difference < 0,
                                                'text-blue-500': scope.row.sale_price_paid_difference === 0
                                                }">
                                                    <span v-if="scope.row.sale_price_paid_difference > 0">+£{{ scope.row.sale_price_paid_difference.toLocaleString() }}</span>
                                                    <span v-else-if="scope.row.sale_price_paid_difference < 0">-£{{ Math.abs(scope.row.sale_price_paid_difference).toLocaleString() }}</span>
                                                    <span v-else>£0.00</span>
                                                    ({{ scope.row.sale_price_paid_difference_percentage }}%)
                                                </span>
                                                </small>
                                            </template>
                                        </el-table-column>
                                    </el-table>

                                </el-collapse-item>
                            </el-collapse>


                        </div>
                    </div>

                </slot>
            </dashboard-widget>
        </div>
    </div>

</div>


</template>

<script>
    import EmptyState from "../../EmptyState";
    import OwnershipPropertySalesChart from "./incs/OwnershipPropertySalesChart";
    import PropertyLoader from "../../incs/PropertyLoader";

    export default {
        name: "OwnershipSaleHistory",
        components: {PropertyLoader, OwnershipPropertySalesChart, EmptyState},
        props: {
            value: {
                type: Array,
                default(){
                    return []
                }
            },
            stats: {
                type: Object,
                default(){
                    return {}
                }
            },
            activeSiteId: {
                type: Number,
                default(){
                    return null;
                }
            },
            loading: {
                type: Boolean,
                default(){
                    return true
                }
            }
        },
        computed: {
            propertiesList(){
                if(this.activeSiteId){
                    return this.value.filter((i)=>{
                        return i.address.site_id === this.activeSiteId;
                    })
                }
                return this.value
            },
        },
        data(){
            return {
                activeProperties: [],
        }
        }
    }
</script>

<style lang="scss">
    .el-collapse--sale-prices  .el-collapse-item__header {
        line-height: 15px !important;
        height: auto !important;
        padding: 10px !important;
    }

    .el-collapse--sale-prices .el-collapse-item__content {
        padding-bottom: 0;
    }
</style>