<template>
<div>
    <div
            v-if="loading"
            class="flex h-full mt-12 pt-12 items-center justify-center">
        <div class="p-3 text-center">
            <property-loader />
            <h3 class="font-semibold text-blue-800">Fetching Sales...</h3>
        </div>
    </div>

    <div class="columns"
         v-if="!loading"
    >
        <div class="column w-full">
            <dashboard-widget
                    padding-class=""
                    title="Recently Sold Nearby">
                <slot>


                    <div class="p-3" v-if="value.length > 0">
                        <el-alert effect="dark" type="success" :closable="false">
                           There were currently {{ value.length.toLocaleString() }} nearby properties sold within the last 18 months.
                        </el-alert>
                    </div>

                    <div class="p-3" v-if="!value || !value.length">
                        <empty-state />
                    </div>


                    <div
                            v-if="value && Array.isArray(value) && value.length > 0"
                    >

                        <el-collapse
                                class="el-collapse--listed"
                                v-model="selectedNearby">
                            <el-collapse-item
                                    :key="item.id"
                                    v-for="(item) of value"
                                    :name="item.id">
                                <template slot="title">
                                    <div class="flex flex-wrap w-full flex-row">
                                        <div class="w-full py-2 pb-3 font-bold">
                                            {{ item.single_line_address }}
                                        </div>
                                        <div class="text-xs w-full flex items-center">

                                            <div v-if="item.distance" class="block flex items-center text-xs mr-3">
                                                <i class="el-icon-location mr-1"></i>
                                                {{ item.distance ? Math.round(item.distance) + 'm away' : '' }}
                                            </div>
                                            <div class="mr-3">
                                           {{ item.price ? '£' + item.price.toLocaleString() : null }}
                                            </div>
                                            <div class="mr-3">
                                                {{ item.property_type_string }}
                                            </div>
                                            <div class="mr-3">
                                                Sold <date-human-view size="text-xs" v-model="item.date_of_transfer" />
                                            </div>
                                        </div>
                                    </div>

                                </template>

                                <div class="p-4">
                                    <div class="columns flex-no-wrap">
                                        <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                                            Distance
                                        </div>
                                        <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                            <i class="el-icon-location mr-1"></i> {{ item.distance ? Math.round(item.distance) + 'm' : '' }}
                                        </div>
                                    </div>
                                    <div class="columns flex-no-wrap">
                                        <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                                            Type
                                        </div>
                                        <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                            {{ item.property_type_string }}
                                        </div>
                                    </div>
                                    <div class="columns flex-no-wrap">
                                        <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                                            Price Paid
                                        </div>
                                        <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                            {{ item.price ? '£' + item.price.toLocaleString() : null }}
                                        </div>
                                    </div>
                                    <div class="columns flex-no-wrap">
                                        <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                                            Date Sold
                                        </div>
                                        <div class="column ml-auto text-xs text-right text-gray-900 leading-tight items-center">
                                            <date-human-view size="text-xs" v-model="item.date_of_transfer" />
                                        </div>
                                    </div>
                                    <div class="mt-4" v-if="allowPropertySelect">
                                        <el-button
                                                @click="() => $emit('uprn', item.uprn)"
                                                class="w-full" type="success" icon="el-icon-map">
                                            View Property
                                        </el-button>
                                    </div>
                                </div>

                            </el-collapse-item>
                        </el-collapse>
                    </div>

                </slot>
            </dashboard-widget>
        </div>
    </div>

</div>


</template>

<script>
    import EmptyState from "../../EmptyState";
    import PropertyLoader from "../../incs/PropertyLoader";
    export default {
        name: "OwnershipSoldNearby",
        components: {PropertyLoader, EmptyState},
        props: {
            value: {
                type: Array,
                default(){
                    return []
                }
            },
            loading: {
                type: Boolean,
                default(){
                    return true
                }
            },
            allowPropertySelect: {
                type: Boolean,
                default(){
                    return true
                }
            }
        },
        data(){
            return {
                selectedNearby: []
            }
        }
    }
</script>

<style lang="scss">
    .el-collapse--sale-prices  .el-collapse-item__header {
        line-height: 15px !important;
        height: auto !important;
        padding: 10px !important;
    }

    .el-collapse--sale-prices .el-collapse-item__content {
        padding-bottom: 0;
    }
     .el-collapse--listed  .el-collapse-item__header {
         line-height: 15px !important;
         height: auto !important;
         padding: 10px !important;
     }
    </style>