<template>
<div>
    <div
            v-if="loading"
            class="flex h-full mt-12 pt-12 items-center justify-center">
        <div class="p-3 text-center">
            <property-loader />
            <h3 class="font-semibold text-blue-800">Fetching Nearby Listed Buildings...</h3>
        </div>
    </div>

    <div class="columns" v-if="!loading">
        <div class="column w-full">




            <dashboard-widget
                    padding-class=""
                    title="Nearby Listed Buildings">
                <slot>
                    <div class="p-3" v-if="!value.length">
                        <empty-state />
                    </div>

                    <div class="p-2" v-if="value.length > 0"
                    >

                        <ownership-uprn-map
                                :bbox="bbox"
                                v-model="uprns"
                        />

                    </div>

                    <div v-if="value.length > 0">

                        <el-collapse
                                class="el-collapse--listed"
                                v-model="selected">
                            <el-collapse-item
                                    :key="i"
                                    v-for="(item, i) of value"
                                 :name="i">
                                <template slot="title">



                                    <div class="flex flex-wrap w-full flex-row">
                                        <div class="w-full py-2 pb-3">
                                            <div class="w-full flex py-2 pb-3">
                                                <div class="w-5 mr-2 flex-none">
                                                    <div class="w-5 h-5 flex text-white items-center justify-center rounded-full bg-blue-600 text-xxs ">
                                                        {{ i + 1 }}
                                                    </div>
                                                </div>
                                                <div class="w-auto">
                                                    {{ item.name }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="text-xs w-full flex">
                                            <div class="mr-3">
                                                <span>Grade {{ item.grade }} Listed</span>
                                            </div>
                                            <div class="mr-3">
                                                <span>Listed </span> <date-human-view size="text-xs" v-model="item.date_listed" />
                                            </div>
                                            <div class="mr-3" v-if="item.distance">
                                                {{ Math.round(item.distance) + 'm' }} away
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <div class="p-3">
                                    <div class="columns flex-no-wrap" v-if="item.name">
                                        <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                                            Name
                                        </div>
                                        <div class="column uppercase ml-auto text-xs text-right text-gray-900 flex items-center">
                                            {{ item.name }}
                                        </div>
                                    </div>


                                    <div class="columns flex-no-wrap" v-if="item.distance">
                                        <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                                            Distance
                                        </div>
                                        <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                            <div class="block flex items-center text-xs my-1">
                                                <i class="el-icon-location mr-1"></i>
                                                {{ item.distance ? Math.round(item.distance) + 'm' : '' }}
                                            </div>
                                        </div>
                                    </div>



                                    <div class="columns flex-no-wrap" v-if="item.date_listed">
                                        <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                                            Date listed
                                        </div>
                                        <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                            <div class="break-normal h-full text-xs leading-tight">
                                                {{ item.date_listed | moment("MMM Do YYYY") }}
                                                <br/><small>{{ item.date_listed | moment("from", "now") }}</small>
                                            </div>
                                        </div>
                                    </div>

                                    <el-button class="w-full mt-4" type="success"
                                               icon="el-icon-view"
                                               @click="gotTo(item.id)">View Details</el-button>

                                </div>


                            </el-collapse-item>
                        </el-collapse>


                    </div>



                </slot>
            </dashboard-widget>



        </div>
    </div>

</div>


</template>

<script>
    import EmptyState from "../../EmptyState";
    import PropertyLoader from "../../incs/PropertyLoader";
    import OwnershipUprnMap from "./incs/OwnershipUprnMap";

    import { latLngBounds } from "leaflet"

    export default {
        name: "OwnershipListedBuildingsNearbyComponent",
        components: {OwnershipUprnMap, PropertyLoader, EmptyState},
        props: {
            value: {
                type: Array,
                default(){
                    return []
                }
            },
            loading: {
                type: Boolean,
                default(){
                    return true
                }
            }
        },
        data(){
          return {
              selected: []
          }
        },
        computed: {
            bbox(){
              let bounds =  latLngBounds(this.uprns.map((c) => {
                  return [c.lon, c.lat];
              }));

              return [ bounds._southWest.lat, bounds._southWest.lng, bounds._northEast.lat, bounds._northEast.lng];

            },
            uprns(){
                return this.value.map((i)=> { return { lat: i.lat, lon: i.lon }});
            }
        },
        methods: {
            gotTo(id){
                window.open('https://historicengland.org.uk/listing/the-list/list-entry/' + id);
            }
        }
    }
</script>

<style lang="scss">
    .el-collapse--sale-prices  .el-collapse-item__header {
        line-height: 15px !important;
        height: auto !important;
        padding: 10px !important;
    }

    .el-collapse--sale-prices .el-collapse-item__content {
        padding-bottom: 0;
    }
</style>