<template>
        <div :class="wrapperClass">
            <div class="p-2">
                <l-map
                        ref="preview-map"
                        :class="{
                    'h-40': !customHeight
                    }"
                        :style="customHeight ? 'height: ' + height + 'px !important' : ''"
                        @ready="onMapReady"
                        @update:zoom="(z)=>{ zoom = z }"
                        :max-zoom="18"
                        :max-bounds="maxBounds"
                        :options="{zoomControl: false}"
                        :center="[object.center.lat, object.center.lon]"
                >

                    <l-marker
                            :key="index"
                            v-for="(prop, index) of markers"
                            :lat-lng="{
                                lat: prop.lat,
                                lng: prop.lon
                                }">
                        <l-icon>

                            <div class="w-4 h-4 flex text-white border border-white items-center justify-center rounded-full bg-blue-600 text-xxs ">
                                {{ index + 1 }}
                            </div>
                        </l-icon>
                    </l-marker>

                    <l-geo-json
                            :key="'geojson-' + i"
                            v-for="(polygon, i) of getFeatures"
                            :options-style="getPolygonSettings(polygon)"
                            :geojson="polygon"/>


                    <l-tile-layer
                            url="https://api.os.uk/maps/raster/v1/zxy/Road_3857/{z}/{x}/{y}.png?key=wC6Him1A448aAquBYlhEJq2IS4GAK51Z"
                    />

                </l-map>
            </div>
            <div v-if="showLegend && getLegend.length > 0" class="flex flex-wrap p-4 text-xs -mx-4">
                <div class="flex items-center p-2"
                     :key="k"
                     v-for="(i, k) of getLegend">
                    <div class="mr-1 w-3 h-3 rounded-full border border-gray-500"
                    :style="{'background-color': i.style + ' !important' }"
                    ></div>
                    <span>{{ i.name }}</span>
                </div>
            </div>
        </div>
</template>

<script>
    import Styles from "../../../../data/OSStyleCodes";
    const collect = require('collect.js');

    export default {
        name: "OwnershipTopographyMap",
        props: {
            value: {
                type: Object,
                default(){
                    return {}
                }
            },
            wrapperClass: {
                type: String,
                default(){
                    return '';
                }
            },
            markers: {
                type: Array,
                default(){
                    return []
                }
            },
            fillColor: {
              type: String,
              default(){
                  return null;
              }
            },
            fillOpacity: {
                type: Number,
                default(){
                    return 1;
                }
            },
            opacity: {
                type: Number,
                default(){
                    return 1;
                }
            },
            showLegend: {
                type: Boolean,
                default() {
                    return true
                }
            },
            showPolygons: {
                type: Boolean,
                default() {
                    return true
                }
            },
            customHeight: {
                type: Boolean,
                default() {
                    return false
                }
            },
            height: {
                type: Number,
                default(){
                    return 400;
                }
            },
        },
        watch: {
          value: {
              deep: true,
              handler(val){
                  this.object = val;
                  this.onMapReady();
              }
          }
        },
        data(){
          return {
              zoom: 18,
              object: this.value
          }
        },
        computed: {
            getLegend(){
              return collect(this.getFeatures).transform((i)=> {
                  return {
                      name: i.properties.descriptive_group,
                      style: this.fetchStyle(i.properties.style_code)
                  }
              }).unique('style').filter((y) => y.name).toArray()
            },
            getFeatures(){
                return this.showPolygons && this.value && this.object.features && Array.isArray(this.object.features) && this.object.features.length > 0 ? this.object.features : [];
            },
            maxBounds(){
                const vm = this;
                return [
                    [vm.object.bbox[1], vm.object.bbox[0]],
                    [vm.object.bbox[3], vm.object.bbox[2]],
                ]
            }
        },
        methods: {
            getPolygonSettings(polygon){
                return {
                    weight: 1,
                    color: '#898989',
                    opacity: this.opacity,
                    fillColor: this.fillColor ? this.fillColor : this.fetchStyle(polygon.properties.style_code),
                    fillOpacity: this.fillOpacity
                }
            },
            onMapReady(){
                const vm = this;
                if (vm.$refs && vm.$refs['preview-map'] && vm.object.bbox) {
                    vm.$refs['preview-map'].mapObject.fitBounds(vm.maxBounds)
                }
            },
            fetchStyle(code) {
                let item = Styles.find((item) => {
                    return item.code === code;
                });
                return item ? item.color : this.fillColor;
            }
        }
    }
</script>

<style scoped>

</style>