<template>
    <div class="columns">
        <div class="column w-full">
            <dashboard-widget title="Commercial Availability - Search">
                <slot>

                    <div class="p-4 el-commercial--filter">
                        <div class="columns">
                            <div class="column w-full px-6 lg:w-1/2">

                                <h3 class="text-md uppercase mb-2 font-medium text-gray-500 uppercase">
                                    Filters
                                </h3>

                                <el-form label-position="left" label-width="220px" :model="$store.state.commercialAvailability.form">

                                    <el-form-item label="Channel">

                                        <el-select
                                                class="w-full"
                                                filterable
                                                placeholder="Select a channel..."
                                                v-model="$store.state.commercialAvailability.form.channel"
                                        >
                                            <el-option
                                                    :key="k"
                                                    :value="i.value"
                                                    :label="i.label"
                                                    v-for="(i, k) of options.channels"></el-option>
                                        </el-select>

                                    </el-form-item>

                                        <el-form-item label="Price">
                                            <el-slider
                                                    range
                                                    :marks="{
                                              0: 'Any',
                                              100000000: '£100m +'
                                              }"
                                                    v-model="$store.state.commercialAvailability.form.price"
                                                    :format-tooltip="formatPricePaidTooltip"
                                                    :min="0"
                                                    :max="100000000"
                                                    :step="10000">
                                            </el-slider>
                                        </el-form-item>

                                    <el-form-item label="Size (ft²)">

                                        <el-slider
                                                :format-tooltip="formatBuildingsFootprintTooltip"
                                                range
                                                v-model="$store.state.commercialAvailability.form.size"
                                                :min="0"
                                                :marks="{
                                              0: 'Any',
                                              20000: '20,000+ ft²'
                                              }"
                                                :max="20000"
                                                :step="100">
                                        </el-slider>

                                    </el-form-item>

                                    <el-form-item label="Property Type">

                                        <el-select
                                                class="w-full"
                                                filterable
                                                clearable
                                                multiple
                                                collapse-tags
                                                placeholder="Property Types..."
                                                v-model="$store.state.commercialAvailability.form.property_types"
                                        >
                                            <el-option
                                                    :key="k"
                                                    :value="i"
                                                    :label="i"
                                                    v-for="(i, k) of options.property_types"></el-option>
                                        </el-select>

                                    </el-form-item>

                                    <el-form-item label="New Build">
                                        <el-select
                                                class="w-full"
                                                filterable
                                                placeholder="New Build..."
                                                v-model="$store.state.commercialAvailability.form.new_build"
                                        >
                                            <el-option
                                                    :key="k"
                                                    :value="i.value"
                                                    :label="i.label"
                                                    v-for="(i, k) of options.bool"></el-option>
                                        </el-select>
                                    </el-form-item>

                                    <el-form-item label="Auction Only">
                                        <el-select
                                                class="w-full"
                                                filterable
                                                placeholder="Auction.."
                                                v-model="$store.state.commercialAvailability.form.auction_only"
                                        >
                                            <el-option
                                                    :key="k"
                                                    :value="i.value"
                                                    :label="i.label"
                                                    v-for="(i, k) of options.bool"></el-option>
                                        </el-select>
                                    </el-form-item>

                                    <el-form-item label="Business for Sale">
                                        <el-select
                                                class="w-full"
                                                filterable
                                                placeholder="Business for sale..."
                                                v-model="$store.state.commercialAvailability.form.is_business"
                                        >
                                            <el-option
                                                    :key="k"
                                                    :value="i.value"
                                                    :label="i.label"
                                                    v-for="(i, k) of options.bool"></el-option>
                                        </el-select>
                                    </el-form-item>

                                    <el-form-item label="Date First Listed">


                                        <el-date-picker
                                                class="w-full"
                                                v-model="$store.state.commercialAvailability.form.date_first_listed"
                                                :clearable="true"
                                                type="date"
                                                format="dd MMM yyyy"
                                                placeholder="Select a Date">
                                        </el-date-picker>
                                    </el-form-item>

                                </el-form>








                            </div>

                            <div class="column w-full px-6 lg:w-1/2">


                                <h3 class="text-md uppercase mb-2 font-medium text-gray-500 uppercase">
                                    Location
                                </h3>

                                <el-form label-position="left" label-width="220px" :model="$store.state.commercialAvailability.form">


                                    <el-form-item label="Radius (km)">
                                        <el-slider
                                                v-model="$store.state.commercialAvailability.form.radius"
                                                :min="1000"
                                                :max="50000"
                                                :format-tooltip="formatRadiusTooltip"
                                                :marks="{
                                              1000: '1km',
                                              10000: '10km',
                                              20000: '20km',
                                              30000: '30km',
                                              40000: '40km',
                                              50000: '50km'
                                              }"
                                                :step="500">
                                        </el-slider>
                                    </el-form-item>

                                    <el-form-item label="Postcode">
                                        <el-input
                                                clearable
                                                v-model="$store.state.commercialAvailability.form.postcode" />
                                    </el-form-item>

                                </el-form>

                            </div>

                            <div class="column w-full text-right">
                                <el-button type="success"
                                           @click="$emit('search', {})"
                                           icon="el-icon-search">
                                    Search
                                </el-button>
                            </div>
                        </div>

                    </div>


                </slot>
            </dashboard-widget>
        </div>
    </div>
</template>

<script>

    const humanNumber = require('human-number')

    import {getDefaultDateOptions} from './../utils/dates'

    export default {
        name: 'CommercialAvailabilitySearchForm',
        data() {
            return {
                pickerOptions: getDefaultDateOptions(),
                options: {
                    property_types: [ "Office", "Serviced Office", "Retail Property (high street)", "Light Industrial", "Commercial Property", "Shop", "Land", "Warehouse", "Distribution Warehouse", "Pub", "Restaurant", "Residential Development", "Hotel", "Commercial Development", "Storage", "Mixed Use", "Cafe", "Leisure Facility", "Guest House", "Retail Property (out of town)", "Convenience Store", "Takeaway", "Industrial Park", "Garage", "Hairdresser / Barber Shop", "Workshop", "Business Park", "Plot", "Healthcare Facility", "Trade Counter", "Industrial Development", "Farm", "Showroom", "Post Office", "Bar / Nightclub", "Heavy Industrial", "Hospitality", "Childcare Facility", "Farm Land", "Place of Worship", "Campsite & Holiday Village", "Smallholding", "Factory", "Petrol Station", "Spa", "Marine Property", "Science Park", "Mill", "Sports facilities", "Research & Development Facility", "House", "Off-Plan", "Bungalow", "Cottage", "Data Centre", "Detached", "Garages", "House Share", "Semi-Detached" ],
                    channels: [
                        {value: null, label: 'Any'},
                        {value: 'COMMERCIAL_BUY', label: 'Buy'},
                        {value: 'COMMERCIAL_RENT', label: 'Rent'},
                    ],
                    bool: [
                        {value: null, label: 'Any'},
                        {value: true, label: 'Yes'},
                        {value: false, label: 'No'},
                    ],
                }
            }
        },
        methods: {
            formatRadiusTooltip(val) {
                return val < 1000 ? val + 'm' : val / 1000 + 'km';
            },
            formatPricePaidTooltip(val) {
                if (val === 0) {
                    return 'Any';
                }
                if (val === 100000000) {
                    return '£100m+';
                }
                return '£' + humanNumber(val).toString().toLowerCase();
            },
            formatBuildingsFootprintTooltip(val) {
                if (val === 0) {
                    return 'Any';
                }
                if (val === 20000) {
                    return val.toLocaleString() + '+ sqft';
                }
                return (val ? val.toLocaleString() : 0) + ' sqft';
            },
        }
    }
</script>
<style>
    .el-commercial--filter .el-slider__marks-text {
        font-size: 11px !important;
        text-align: center !important;
        width: 100px !important;
        margin: 0;
        padding: 0;
    }
</style>