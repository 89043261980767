<template>
    <div>

        <navigation/>

        <page-header :title="getTitle" v-if="!loading">
        </page-header>
        <page v-if="!loading">

            <!--
            {{ getMapCoords }}
            {{ getLocationGeoJson }}

            {{ getPriceHeatMapsLatLng }}
            --->

            <div class="columns">
                <div class="column w-full md:w-1/2 lg:w-1/5">
                    <stat-box title="Avg. Price"
                              :loading="loading"
                              :prefix="'£'"
                              :value="stats.data.avg ? stats.data.avg : 0"
                    >
                    </stat-box>
                </div>
                <div class="column w-full md:w-1/2 lg:w-1/5">
                    <stat-box title="Avg. Detached Price"
                              :loading="loading"
                              :prefix="'£'"
                              :value="stats.data.detached ? stats.data.detached : 0"
                    >
                    </stat-box>
                </div>
                <div class="column w-full md:w-1/2 lg:w-1/5">
                    <stat-box title="Avg. Semi-detached Price"
                              :loading="loading"
                              :prefix="'£'"
                              :value="stats.data.semi_detached ? stats.data.semi_detached : 0"
                    >
                    </stat-box>
                </div>
                <div class="column w-full md:w-1/2 lg:w-1/5">
                    <stat-box title="Avg. Terraced Price"
                              :loading="loading"
                              prefix="£"
                              :value="stats.data.terraced ? stats.data.terraced : 0"
                    >
                    </stat-box>
                </div>
                <div class="column w-full md:w-1/2 lg:w-1/5">
                    <stat-box title="Avg. Flat/Maisonette Price"
                              :loading="loading"
                              :prefix="'£'"
                              :value="stats.data.flats ? stats.data.flats : 0"
                    >
                    </stat-box>
                </div>
                <div class="column w-full xl:w-1/2">
                    <dashboard-widget
                            title="Most Expensive Areas"
                            :padding-class="''">
                        <div class="h-32 w-full" v-loading="true"
                             v-if="loading"
                        >
                        </div>
                        <div
                                v-if="getMapCoords !== null"
                                class="el-block--map-lmap relative">

                            <l-map
                                :center="getMapCoords"
                                @update:zoom="(zoom)=>{map.prices.zoom = zoom }"
                                ref="mapPrices"
                                :min-zoom="$store.state.auth.map.min_zoom"
                                :max-zoom="$store.state.auth.map.max_zoom"
                                :max-bounds="$store.getters['auth/getMaxBounds']"
                                @ready="fitPriceMapBounds"
                                :zoom="map.prices.zoom">


                            <l-geo-json
                                    v-if="getLocationGeoJson !== null"
                                    :options-style="()=>{ return {
                                                color: '#0a456d',
                                                weight: 2,
                                                fillColor: '#fff'
                                                } }"
                                    :geojson="getLocationGeoJson" />


                                <l-google-tile-layer
                                        :apikey="$store.state.auth.map.google.key"
                                        :options="$store.state.auth.map.google.opts"/>

                            <l-heat-map
                                    v-if="getPriceHeatMapsLatLng.length > 0"
                                    :radius="8"
                                    :blur="10"
                                    :lat-lng="getPriceHeatMapsLatLng"
                                    :max="1.0"
                                    :max-zoom="14"></l-heat-map>

                        </l-map>

                        </div>


                    </dashboard-widget>
                </div>
                <div class="column w-full xl:w-1/2">
                    <dashboard-widget
                            title="Most Active Areas"
                            :padding-class="''">
                        <div class="h-32 w-full" v-loading="true"
                             v-if="loading"
                        >
                        </div>
                        <div
                                v-if="getMapCoords !== null"
                                class="el-block--map-lmap relative">

                            <l-map
                                    :center="getMapCoords"
                                    @update:zoom="(zoom)=>{map.sales.zoom = zoom }"
                                    ref="mapSales"
                                    :min-zoom="$store.state.auth.map.min_zoom"
                                    :max-zoom="$store.state.auth.map.max_zoom"
                                    :max-bounds="$store.getters['auth/getMaxBounds']"
                                    @ready="fitSalesMapBounds"
                                    :zoom="map.sales.zoom">


                                <l-geo-json
                                        v-if="getLocationGeoJson !== null"
                                        :options-style="()=>{ return {
                                                color: '#0a456d',
                                                weight: 2,
                                                fillColor: '#fff'
                                                } }"
                                        :geojson="getLocationGeoJson" />

                                <l-google-tile-layer
                                        :apikey="$store.state.auth.map.google.key"
                                        :options="$store.state.auth.map.google.opts"/>
                                <l-heat-map
                                        v-if="getSalesHeatMapsLatLng.length > 0"
                                        :radius="8"
                                        :blur="10"
                                        :max-zoom="14"
                                        :lat-lng="getSalesHeatMapsLatLng"
                                        :max="1.0"></l-heat-map>

                            </l-map>

                        </div>


                    </dashboard-widget>
                </div>
                <div class="column w-full xl:w-1/2">
                    <dashboard-widget
                            title="Sales">
                        <div class="h-32 w-full" v-loading="true"
                             v-if="loading"
                        >
                        </div>

                        <apexchart type="line"
                                   v-if="!loading && getSalesChart !== null"
                                   :options="getSalesChart.options"
                                   :series="getSalesChart.series"></apexchart>
                    </dashboard-widget>
                </div>
                <div class="column w-full xl:w-1/2">
                    <dashboard-widget
                            title="Avg Sale Price">

                        <div class="h-32 w-full" v-loading="true"
                             v-if="loading"
                        >
                        </div>

                        <apexchart type="line"
                                   v-if="!loading && getAvgPriceChart !== null"
                                   :options="getAvgPriceChart.options"
                                   :series="getAvgPriceChart.series"></apexchart>
                    </dashboard-widget>
                </div>

            </div>

        </page>
    </div>

</template>
<script>


    export default {
        name: "AreaPropertyPrices",
        data() {
            return {
                loading: true,
                title: null,
                heatmap_mode: 'price',
                map: {
                   prices: {
                       zoom: 12,
                   },
                    sales: {
                        zoom: 12,
                    },
                    loading: true,
                    data: {}
                },
                stats: {
                    data: {},
                    loading: true
                },
                charts: {},
                search: {
                    default_sort: {prop: 'avg_2020', order: 'descending'},
                    meta: {},
                    results: [],
                    loading: true,
                    rpp: 10
                }
            }
        },
        computed: {
            getSubtypeObject(){
              switch (this.$route.params.type) {
                  case "communities":
                      return {
                          title: 'Welsh Communities',
                          path: '/property-sales/communities',
                      }
                  case "unitary-authorities":
                      return {
                          title: 'Unitary Authorities',
                          path: '/property-sales/unitary-authorities',
                      }
                  case "london-boroughs":
                      return {
                          title: 'London Boroughs',
                          path: '/property-sales/london-boroughs',
                      }
                  case "districts":
                      return {
                          title: 'Districts',
                          path: '/property-sales/districts',
                      }
                  case "parishes":
                      return {
                          title: 'Civil Parishes',
                          path: '/property-sales/civil-parishes',
                      }
              }
              return null;
            },
            getTitle(){
                if(typeof this.title === 'string'){
                    return 'Property Prices in ' + this.title;
                }
              return 'Property Prices';
            },
            getMapCoords(){
              try {
                  return {
                      lat: this.map.data.center.geometry.coordinates[1],
                      lng: this.map.data.center.geometry.coordinates[0]
                  }
              }catch (e) {
                  return  null
              }
            },

            getPriceHeatMapsLatLng(){
                return this.map.data && this.map.data.heat_maps && this.map.data.heat_maps.prices ? this.map.data.heat_maps.prices : []
            },
            getSalesHeatMapsLatLng(){
                return this.map.data && this.map.data.heat_maps && this.map.data.heat_maps.sales ? this.map.data.heat_maps.sales : []
            },
            getLocationGeoJson(){
                return this.map.data && this.map.data.polygon ? this.map.data.polygon : null;
            },
            getSalesChart() {
                try {
                    return {
                        options: {
                            chart: {
                                zoom: {
                                    enabled: false
                                },
                            },
                            dataLabels: {
                                enabled: false
                            },
                            xaxis: {
                                categories: this.charts.sales && this.charts.sales.categories ? this.charts.sales.categories : []
                            },
                            yaxis: {
                                labels: {
                                    formatter: (val) => {
                                        return val ? val.toLocaleString() : 0
                                    }
                                }
                            }
                        },
                        series: this.charts.sales && this.charts.sales.data ? this.charts.sales.data : []
                    }
                } catch (e) {
                    return null;
                }
            },
            getAvgPriceChart() {
                try {
                    return {
                        options: {
                            chart: {
                                zoom: {
                                    enabled: false
                                },
                            },
                            dataLabels: {
                                enabled: false
                            },
                            xaxis: {
                                categories: this.charts.avg_sales && this.charts.avg_sales.categories ? this.charts.avg_sales.categories : []
                            },
                            yaxis: {
                                labels: {
                                    formatter: (val) => {
                                        return val ? '£' + val.toLocaleString() : 0
                                    }
                                }
                            }
                        },
                        series: this.charts.avg_sales && this.charts.avg_sales.data ? this.charts.avg_sales.data : []
                    }
                } catch (e) {
                    return null;
                }
            },
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.loading = true;
                vm.$http.all(
                    [
                        vm.$http.get('/api/v1/sale-history-stats', {
                    params: {
                        id: vm.$route.params.id,
                        mode: vm.$route.params.type
                    }
                }),
                        vm.$http.get('/api/v1/sale-history-location', {
                            params: {
                                id: vm.$route.params.id,
                                mode: vm.$route.params.type
                            }
                        })
                ]).then(vm.$http.spread((...responses) => {
                    vm.stats.data = responses[0].data.stats;
                    vm.title = responses[0].data.name;
                    vm.charts = responses[0].data.charts;
                    vm.map.data = responses[1].data;
                    vm.loading = false;

                    let item = vm.getSubtypeObject, obj = [
                        {
                            title: 'Property Prices',
                            path: '/property-sales'
                        }
                    ];

                    if(item !== null){
                        obj.push(item);
                    }

                    obj.push(  {
                        title: vm.getTitle
                    })

                    vm.$store.commit('auth/setBreadcrumbs', obj);

                }));

            })
        },
        methods: {
            fitPriceMapBounds(){
                console.log(this.map.data.bbox);
               this.$refs.mapPrices.mapObject.fitBounds([
                   [this.map.data.bbox[1], this.map.data.bbox[0]],
                   [this.map.data.bbox[3], this.map.data.bbox[2]],
               ])
            },
            fitSalesMapBounds(){
                console.log(this.map.data.bbox);
                this.$refs.mapSales.mapObject.fitBounds([
                    [this.map.data.bbox[1], this.map.data.bbox[0]],
                    [this.map.data.bbox[3], this.map.data.bbox[2]],
                ])
            },
        }
    }
</script>
<style>
    .el-block--map-lmap {
        height: 500px;
    }
</style>
