<template>

    <page>

        <template slot="header">
            <page-header title="EPC Ratings">
            </page-header>
        </template>

        <template slot="content">

            <dashboard-widget title="EPC Rating Search" padding-class="">
                <div class="p-4">
                    <div class="columns">
                        <div class="column flex-grow">
                            <el-input
                                    @keyup.enter.native="fetch(1)"
                                    placeholder="Search term e.g, AL5 or Downing Street"
                                    :disabled="loading" v-model="search.term" />
                        </div>
                        <div class="column flex flex-col items-center">
                            <el-switch
                                    :disabled="loading"
                                    v-model="search.include_expired"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949">
                            </el-switch>
                            <label class="block uppercase mt-1 text-xs text-gray-600">Include Expired</label>
                        </div>
                        <div class="column">
                            <el-button
                                    :disabled="loading"
                                    :loading="loading"
                                    @click="fetch(1)"
                                    icon="el-icon-search" type="success">Search</el-button>
                        </div>
                    </div>
                </div>


                <div class="columns p-4"
                     v-if="meta.totalResult && !loading"
                >
                    <div class="column flex items-center">
                                <span class="text-sm">
                                  {{ meta.totalResult.toLocaleString() }} results found. Displaying page {{ meta.current.toLocaleString() }} of {{ meta.pageCount.toLocaleString() }}
                                </span>
                    </div>
                    <div class="column ml-auto">
                        <el-pagination
                                background
                                layout="prev, pager, next"
                                @current-change="(page)=>{ fetch(page) }"
                                :page-size="10"
                                :current-page="meta.current"
                                :total="meta.totalResult">
                        </el-pagination>

                    </div>
                </div>


                <empty-state class="p-3"
                             title="Sorry, no results found"
                             message="We could not find any results for your search."
                             :support="false"
                             v-if="!loading && !results.length" />


                <div class="p-6" v-if="loading">
                    <property-loader />
                </div>


                <el-table
                        v-if="meta.totalResult && !loading"
                        :data="results"
                        :default-sort="default_sort"
                        @sort-change="onSortChange"
                        style="width: 100%">

                    <el-table-column
                            prop="address.keyword"
                            sortable="custom"
                            label="Address">
                        <template slot-scope="scope">
                           <span class="font-bold"> {{ scope.row.address }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column
                            prop="type.keyword"
                            sortable="custom"
                            width="170"
                            label="Type">
                        <template slot-scope="scope">
                            {{ scope.row.type }}
                        </template>
                    </el-table-column>

                    <el-table-column
                            prop="total_floor_area_sqm"
                            sortable="custom"
                            align="right"
                            width="130"
                            label="Area (m²)">
                        <template slot-scope="scope">
                            {{ scope.row.total_floor_area_sqm ? scope.row.total_floor_area_sqm + 'm²' : '' }}
                        </template>
                    </el-table-column>

                    <el-table-column
                            prop="total_floor_area_sqm"
                            sortable="custom"
                            align="right"
                            width="130"
                            label="Area (ft²)">
                        <template slot-scope="scope">
                            {{ scope.row.total_floor_area_sqft ? scope.row.total_floor_area_sqft + 'ft²' : '' }}
                        </template>
                    </el-table-column>


                    <el-table-column
                            prop="valid_until"
                            sortable="custom"
                            width="170"
                            label="Expires">
                        <template slot-scope="scope">
                            <date-human-view
                                    v-if="scope.row.valid_until"
                                    v-model="scope.row.valid_until" />
                        </template>
                    </el-table-column>

                    <el-table-column
                            prop="rating.keyword"
                            sortable="custom"
                            width="100"
                            label="Rating">
                        <template slot-scope="scope">
                            <span
                                    v-if="scope.row.rating"
                                    :class="{
                                    'bg-green-600 text-green-100': scope.row.rating === 'A',
                                    'bg-green-400 text-green-900': scope.row.rating === 'B',
                                    'bg-green-200 text-green-800': scope.row.rating === 'C',
                                    'bg-yellow-600 text-yellow-200': scope.row.rating === 'D',
                                    'bg-orange-300 text-orange-600': scope.row.rating === 'E',

                                    'bg-orange-500 text-orange-100': scope.row.rating === 'F',
                                    'bg-red-600 text-red-100': scope.row.rating === 'G',

                                    }"
                                    class="p-2 mx-auto inline-block w-6 h-6 flex items-center text-sm justify-center font-bold text-black rounded-full">
                                {{ scope.row.rating }}
                            </span>
                        </template>
                    </el-table-column>

                    <el-table-column
                            prop="potential_rating.keyword"
                            sortable="custom"
                            width="160"
                            label="Potential Rating">
                        <template slot-scope="scope">

                            <span
                                    v-if="scope.row.potential_rating"
                                    :class="{
                                    'bg-green-600 text-green-100': scope.row.potential_rating === 'A',
                                    'bg-green-400 text-green-900': scope.row.potential_rating === 'B',
                                    'bg-green-200 text-green-800': scope.row.potential_rating === 'C',
                                    'bg-yellow-600 text-yellow-200': scope.row.potential_rating === 'D',
                                    'bg-orange-300 text-orange-600': scope.row.potential_rating === 'E',

                                    'bg-orange-500 text-orange-100': scope.row.potential_rating === 'F',
                                    'bg-red-600 text-red-100': scope.row.potential_rating === 'G',

                                    }"
                                    class="p-2 mx-auto inline-block w-6 h-6 flex items-center text-sm justify-center font-bold rounded-full">
                                {{ scope.row.potential_rating }}
                            </span>
                        </template>
                    </el-table-column>

                    <el-table-column
                            align="right"
                            width="100"
                    >
                        <template slot-scope="scope">
                            <el-button type="info" @click="view(scope.row.id)">View</el-button>
                        </template>
                    </el-table-column>



                </el-table>

                <div class="columns p-4"
                     v-if="meta.totalResult && !loading"
                >
                    <div class="column flex items-center">
                                <span class="text-sm">
                                  {{ meta.totalResult.toLocaleString() }} results found. Displaying page {{ meta.current.toLocaleString() }} of {{ meta.pageCount.toLocaleString() }}
                                </span>
                    </div>
                    <div class="column ml-auto">
                        <el-pagination
                                background
                                layout="prev, pager, next"
                                @current-change="(page)=>{ fetch(page) }"
                                :page-size="10"
                                :current-page="meta.current"
                                :total="meta.totalResult">
                        </el-pagination>

                    </div>
                </div>


            </dashboard-widget>





        </template>


    </page>


</template>
<script>
    import PropertyLoader from "../components/incs/PropertyLoader";
    import EmptyState from "../components/EmptyState";

    export default {
        name: 'EPCRatings',
        components: {EmptyState, PropertyLoader},
        methods: {
            view(id){
                window.open('https://find-energy-certificate.digital.communities.gov.uk/energy-certificate/' + id )
            },
            onSortChange(object) {
                this.default_sort = object.prop && object.order ? object : null;
                this.fetch(1);
            },
            fetch(page) {
                let vm = this;
                vm.results = [];
                vm.loading = true;
                vm.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id  + '/epc-ratings', {
                    params: Object.assign(vm.search, {
                        page: page || 1,
                        rpp: vm.rpp,
                        sort: vm.default_sort && vm.default_sort.prop ? vm.default_sort.prop : null,
                        order: vm.default_sort && vm.default_sort.order ? vm.default_sort.order === 'descending' ? 'desc' : 'asc' : null,
                    })
                }).then((response) => {
                    vm.results = response.data.results;
                    vm.meta = response.data.meta;
                    vm.loading = false;
                }).catch(() => {
                    vm.loading = false;
                    vm.results = [];
                    vm.meta = {};
                })
            },
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.$store.commit('auth/setBreadcrumbs', [
                    {
                        title: 'EPC Ratings'
                    }
                ]);
                vm.fetch(1);
            })
        },
        data() {
            return {
                default_sort: {
                    prop: 'address.keyword',
                    order: 'desc'
                },
                search: {
                  term: '',
                  include_expired: false,

                },
                loading: false,
                results: [],
                rpp: 10,
                meta: {}
            }
        }
    }
</script>

