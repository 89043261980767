<template>

        <page>

            <template slot="header">
                <page-header title="Verify your Email">

                </page-header>
            </template>

            <template slot="content">
            <div class="columns" v-if="loaded">
                <div class="column w-full">
                    <dashboard-widget title="Please verify your email address"
                                      description="Sorry about this, but we get a lot of robots!">


                        <div class="columns">
                            <div class="column w-full">
                                <el-alert type="info" effect="dark" :closable="false">We have sent a code to the email address <span
                                        class="font-semibold">{{ profile.email }}</span>. Please enter the code below in
                                    order to verify your email address.
                                </el-alert>
                            </div>
                            <div class="column w-full">
                                <div class="pt-3">
                                    <el-input
                                            :disabled="isRunning"
                                            placeholder="Please enter your verification code" v-model="code"/>
                                </div>
                                <div class="py-3 text-right">
                                    <el-link
                                            @click="resend"
                                            :disabled="isRunning"
                                    >Resend verification code?</el-link>
                                </div>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column">
                                <el-link
                                        :disabled="isRunning"
                                        @click="openProfile"
                                >Change email address z?</el-link>
                            </div>
                            <div class="column ml-auto">
                                <el-button
                                        :disabled="isRunning"
                                        :loaing="verifying"
                                        @click="verify"
                                        icon="el-icon-refresh-right"
                                        type="success">
                                    Verify Email
                                </el-button>
                            </div>
                        </div>

                    </dashboard-widget>
                </div>
            </div>
            </template>

        </page>

</template>

<script>

    export default {
        name: 'VerifyEmail',
        data() {
            return {
                profile: {},
                code: null,
                loaded: false,
                verifying: false,
                resending: false
            }
        },
        computed: {
          isRunning(){
              return this.resending || this.verifying;
          }
        },
        methods: {
            openProfile(){
                this.$store.state.auth.account.activeTab = 'profile';
                this.$store.state.auth.account.visible = true;
            },
          verify(){
              let vm = this;
              vm.verifying = true;

              return vm.$http.post('/api/v1/auth/email-verification', {
                  code: vm.code
              })
                  .then(() => {
                      this.$notify({
                          type: 'success',
                          title: 'Email verified',
                          message: 'Thank you. Your email address has been verified!',
                          position: 'top-right',
                          offset: 140,
                      });
                      vm.$store.state.auth.user.email_verified = true;
                      this.$router.push('/')
                  }).catch(() => {
                      vm.verifying = false;
                      this.$notify({
                          type: 'warning',
                          title: 'Incorrect code',
                          message: 'The verification code entered is not correct.',
                          position: 'top-right',
                          offset: 140,
                      });
                  })

          },
            resend(){
                let vm = this;
               vm.resending = true;

                return vm.$http.put('/api/v1/auth/email-verification')
                    .then(() => {
                        vm.resending = false;
                        this.$notify({
                            type: 'success',
                            title: 'Verification code sent',
                            message: 'Please check your inbox (' + this.profile.email + '), we have emailed you a new verification code.',
                            position: 'top-right',
                            offset: 140,
                        });
                    }).catch((e) => {
                        vm.resending = false;
                        vm.$store.dispatch('auth/handleServerError', {
                            vm: vm,
                            error: e
                        });
                    })
            }
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.$http.get('/api/v1/auth/user')
                    .then((response) => {
                        vm.profile = response.data.data;
                        vm.$store.commit('auth/setUser', response.data.data);
                        if(vm.profile.email_verified){
                            vm.$router.push('/');
                            return;
                        }
                        vm.loaded = true;
                        vm.$store.commit('auth/setBreadcrumbs', [
                            {
                                title: 'Verify your Email'
                            }
                        ]);


                        next();
                    })
                    .catch(() => {
                        vm.$router.push('/login?failed=true&mode=token');
                    })
            })
        }
    }
</script>