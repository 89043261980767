import api from "./../../utils/api";
import moment from "moment";
import {getDefaultDateOptions} from './../../utils/dates'

console.log('api', api);

let cancelTokenSource = null;

const initialState = {
    newCenter: [51.81325798696447, -0.37155504271413],

    active: {
        center: [51.81325798696447, -0.37155504271413],
        term: '',
        term_match: 'must_contain'
    },
    bounds: null,

    map_bounds: {
        current: null,
        active: null
    },
    polygons: {
        total: 0,
      postcode_districts: [],
      postcode_areas: [],
      counties: []
    },
    zoom: 12,
    search: {
        term: '',
        term_match: 'must_contain',
        decision: null,
        date_group_by: null,
        dateRange: [moment().subtract(30, 'day').toISOString(), moment().toISOString()]
    },

    pickerOptions: getDefaultDateOptions(),
    decision: [
        {label: 'All', value: null},
        {label: 'Pending', value: 'pending'},
        {label: 'Refused', value: 'refused'},
        {label: 'Approved', value: 'approved'},
        {label: 'Split', value: 'split'},
        {label: 'Withdrawn', value: 'withdrawn'},
    ],
    date_ranges: [
        {label: 'All', value: null},
        {label: 'Date Received', value: 'date_received'},
        {label: 'Date Decided', value: 'date_decided'}
    ],
    term_matches: [
        {label: 'contains', value: 'must_contain'},
        {label: 'Not contain', value: 'not_contain'}
    ],
    properties: [],
    stats: {},
    type: [],
    application: {},
    info_map_view: 'map',

    draw: {
        visible: false,
        loading: false
    },

    details: {
        show: false,
        activeTab: 'details'
    },
    loading: {
        map: false,
        location: true,
        application: true
    },
    meta: {},
    setZoom: false,
    location_data: [],
    local_authorities: []
};

export default {
    namespaced: true,
    state: initialState,
    mutations: {
        reset(state) {
            console.log('reset')
            Object.keys(initialState).forEach(key => {
                state[key] = initialState[key]
            })
        },
    },
    actions: {


        setCurrentBoundsAndZoom: ({state}, bounds) => {
            state.map_bounds.current = bounds;
            state.zoom = 17
        },



        locationSearchChange: ({state}, opts) => {
            if (opts.latitude && opts.longitude) {
                state.setZoom = true;

                console.log('Change', opts);

                state.active.center = [opts.latitude, opts.longitude];
                state.newCenter = [opts.latitude, opts.longitude];
                state.zoom = 12;

                let el = document.getElementById('google-autocomplete');
                if (el) {
                    el.value = '';
                }

            }
        },
        updateCenter: ({state, dispatch}) => {
            state.active.center = state.newCenter;
            state.active.term = state.search.term;
            state.active.term_match = state.search.term_match;
            dispatch('getApplications')
        },

        fetchMapPolygonOverlays: () => {
            return api.get('/api/v1/overlays');
        },
        fetchPropertyPrices: ({getters}, opts) => {

            try {
                cancelTokenSource.cancel();
            } catch (e) {
                console.warn(e);
            }

            cancelTokenSource = api.CancelToken.source();
            return api.get('/api/v1/property-sales', {
                cancelToken: cancelTokenSource.token,
                params: Object.assign(getters.getBounds, opts)
            });
        },
        fetchProperties: ({getters}) => {

            try {
                cancelTokenSource.cancel();
            } catch (e) {
                console.warn(e);
            }

            cancelTokenSource = api.CancelToken.source();
            return api.get('/api/v1/properties', {
                cancelToken: cancelTokenSource.token,
                params: getters.getBounds
            });
        },
        fetchListedBuildings: ({getters}, opts) => {

            try {
                cancelTokenSource.cancel();
            } catch (e) {
                console.warn(e);
            }

            cancelTokenSource = api.CancelToken.source();
            return api.get('/api/v1/listed-buildings', {
                cancelToken: cancelTokenSource.token,
                params: Object.assign(getters.getBounds, opts)
            });
        },

        fetchOrdinance: ({getters}, opts) => {

            opts = opts || {};
            try {
                cancelTokenSource.cancel();
            } catch (e) {
                console.warn(e);
            }

            cancelTokenSource = api.CancelToken.source();
            return api.get('/api/v1/ordinance', {
                cancelToken: cancelTokenSource.token,
                params: Object.assign(getters.getBounds, opts)
            });
        },
        fetchApplications: ({getters}) => {

            try {
                cancelTokenSource.cancel();
            } catch (e) {
                console.warn(e);
            }

            cancelTokenSource = api.CancelToken.source();
            return api.get('/api/v1/map', {
                cancelToken: cancelTokenSource.token,
                params: getters.getBounds
            });
        },

        getApplications: ({state}) => {

            try {
                cancelTokenSource.cancel();
            } catch (e) {
                console.warn(e);
            }

            let params = {
                sw_lat: state.bounds._southWest.lat,
                sw_lng: state.bounds._southWest.lng,
                ne_lat: state.bounds._northEast.lat,
                ne_lng: state.bounds._northEast.lng
            };

            state.loading.map = true;
            cancelTokenSource = api.CancelToken.source();


            if (state.search.decision) {
                params.decision = state.search.decision;
            }

            if (state.search.term && state.search.term.length > 0) {
                params.search_term = state.search.term;
                params.term_match = state.search.term_match;
            }

            if (state.search.date_group_by) {
                params.date_group_by = state.search.date_group_by;
            }

            if (Array.isArray(state.search.dateRange) && state.search.dateRange.length === 2) {
                params.date_from = moment(state.search.dateRange[0]).format('YYYY-MM-DD');
                params.date_to = moment(state.search.dateRange[1]).format('YYYY-MM-DD');
            }

            api.get('/api/v1/map', {
                cancelToken: cancelTokenSource.token,
                params: params
            }).then((response) => {
                state.loading.map = false;
                state.properties = response.data.data;
                state.stats = response.data.stats;
                state.local_authorities = response.data.local_authorities;
                state.meta = response.data.meta;
            }).catch(() => {
                state.loading.map = false;
            })
        },

        getApplication: ({state}, opts) => {

            state.loading.application = true;

            api.get('/api/v1/application/' + opts.id).then((response) => {
                state.loading.application = false;
                state.application = response.data.data;
            }).catch(() => {
                state.loading.application = false;
            })
        },
    },
    getters: {
        fetchedStoredPolygonIds: (state)  => (type) => {
            return Object.keys(state.stored_boundary_layers[type]);
        },
        hasBoundsMismatch: (state) => {
            try {
                return JSON.stringify(state.map_bounds.active) !== JSON.stringify(state.map_bounds.current)
            } catch (e) {
                return false;
            }
        },
        getBounds: (state) => {
            if (!state.map_bounds.active) {
                return {};
            }


            return {
                sw_lat: state.map_bounds.active._southWest.lat,
                sw_lng: state.map_bounds.active._southWest.lng,
                ne_lat: state.map_bounds.active._northEast.lat,
                ne_lng: state.map_bounds.active._northEast.lng
            }
        },
        getCoords: (state) => {
            if (!state.map_bounds.active) {
                return {};
            }

            return {
                sw_lat: state.map_bounds.active._southWest.lat,
                sw_lng: state.map_bounds.active._southWest.lng,
                ne_lat: state.map_bounds.active._northEast.lat,
                ne_lng: state.map_bounds.active._northEast.lng
            }
        },
        getStat: (state) => (type) => {
            return state.stats[type] && Number.isInteger(state.stats[type]) ? state.stats[type].toLocaleString() : 0;
        },
        getLocalAuthorities: (state) => {
            if (state.local_authorities && Array.isArray(state.local_authorities)) {
                return state.local_authorities;
            }
            return [];
        },
        getLocationAddress: (state) => {
            return state.location_data.location ? state.location_data.location : null;
        },
        getLocationArea: (state) => {
            return state.location_data.square_miles ? state.location_data.square_miles.toLocaleString() + ' sq mi' : null;
        },
        getStats: (state) => {
            let meta = state.meta, results = [];

            results.push({
                label: 'Total',
                value: meta.total ? meta.total : 0,
                class: 'indigo'
            })

            results.push({
                label: 'Approved',
                value: meta.approved ? meta.approved : 0,
                class: 'green'
            })
            results.push({
                label: 'Pending',
                value: meta.pending ? meta.pending : 0,
                class: 'blue'
            })
            results.push({
                label: 'Refused',
                value: meta.refused ? meta.refused : 0,
                class: 'red'
            })

            return results;

        },
        centerHasChanged: (state) => {
            return state.active.center.join('|') !== state.newCenter.join('|');
        },
        searchHasChanged: (state) => {
            console.log(state.search.term, state.active.term)
            return state.active.term !== state.search.term;
        },
        termMatchHasChanged: (state) => {
            return state.active.term_match !== state.search.term_match;
        },
        isLoadingMap: (state) => {
            return state.loading.map;
        },
        getFormObject: (state) => {
            let objects = Object.assign(state.search.form.application, state.search.form.agent, state.search.form.dates);
            Object.keys(objects).forEach((key) => {
                if (objects[key] === null || (typeof objects[key] === 'string' && !objects[key].length)) {
                    delete objects[key]
                }
            })
            return objects;
        }
    }
}