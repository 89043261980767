<template>
    <div class="h-full bg-gray-200 overflow-scroll">
        <div class="bg-blue-900 p-6">
            <h4 class="font-semibold overflow-hidden text-white">
                Property Sale Prices
            </h4>
            <p class="text-xs text-white">
                View a full sale history of the local area or nationally in order see the value of current area.
            </p>
        </div>
        <div class="bg-gray-200 p-3">


            <div class="columns">
                <div class="column w-full">
                    <dashboard-widget title="Search Property Sales">
                        <template slot="cta">
                            <export-button
                                    @export="(format) => exportData(format) "
                                    :exporting="$store.state.map.data_metrics.sale_prices.table.fetching"
                                    :disabled="$store.state.map.data_metrics.sale_prices.table.fetching"
                            />
                        </template>

                        <data-layer-filters
                                ref="dataLayerFilters"
                                :loading="$store.state.map.data_metrics.sale_prices.table.fetching"
                                :filters="filters"
                                @change="()=> { onSearchAreaChange() } "
                        />


                        <el-tabs v-model="active_tab">
                            <el-tab-pane label="Sale History" name="table">


                                <data-layer-table-wrapper
                                        ref="tableWrapper"
                                        :filters="filters"
                                        @change="(page)=> {  searchSalesTable(page) }"
                                        v-model="$store.state.map.data_metrics.sale_prices.table">
                                    <div
                                            :key="k"
                                            v-for="(i, k) of $store.state.map.data_metrics.sale_prices.table.results"
                                            class="bg-white px-4 py-2 mb-6 border shadow border border-gray-200">


                                        <div class="columns">
                                            <div class="column w-2/5 my-4">
                                                <div class="border border-gray-300  p-3 h-full rounded">

                                                    <h4 class="text-lg font-semibold text-blue-900">£{{ i.price.toLocaleString() }}</h4>
                                                    <div class="text-xs" v-if="i.price_per_sqft">
                                                        £{{ i.price_per_sqft.toLocaleString() }} per ft²
                                                    </div>
                                                    <div class="my-2">
                                                        <pretty-tenure v-model="i.tenure" />
                                                    </div>
                                                    <div class="text-xs pt-1">
                                                        Sold <date-human-view size="text-xs" v-model="i.date_of_transfer"/>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="column w-3/5">
                                                <h3 class="font-bold mt-1 leading-tight my-2 mt-6">{{ i.address }}</h3>
                                                <div class="text-xs">
                                                    <div class="py-1"><span v-if="i.sqft">{{ i.sqft.toLocaleString() }} ft² </span><span v-if="i.built_form">{{ i.built_form }} </span>{{ i.type }}</div>
                                                    <div v-if="i.current_tenure">{{ i.current_tenure }}</div>
                                                    <div v-if="i.construction_age_band">Built {{ i.construction_age_band }}</div>
                                                    <div class="flex items-center">
                                                        <i class="mr-1" :class="{
                                                        'el-icon-close text-red-500': !i.new_build,
                                                        'el-icon-check text-green-500': i.new_build
                                                        }"></i>
                                                        <span>New Build</span>
                                                    </div>
                                                </div>
                                                <div class="flex justify-end">
                                                    <el-button size="mini"
                                                               :disabled="!i.uprn"
                                                               @click="$emit('uprn', i.uprn)"
                                                               type="success" icon="el-icon-view">View</el-button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </data-layer-table-wrapper>


                            </el-tab-pane>
                            <el-tab-pane label="Activity" name="chart">
                                <data-layer-loading v-if="$store.state.map.data_metrics.sale_prices.chart.fetching" />


                                <div v-if="!$store.state.map.data_metrics.sale_prices.chart.fetching">



                                    <div v-if="!$store.state.map.data_metrics.sale_prices.chart.data">
                                        <empty-state title="Sorry, no data was found" />
                                    </div>



                                    <div v-else>
                                        <div class="columns w-full mt-3">
                                            <div class="column w-1/2">
                                                <floating-label-input label="View as"  class="mb-2">
                                                    <el-select
                                                            class="w-full"
                                                            placeholder="View as..."
                                                            size="small"
                                                            v-model="group_by">
                                                        <el-option
                                                                v-for="item in chart_group_types"
                                                                :key="item.value"
                                                                :label="item.label"
                                                                :value="item.value">
                                                            {{ item.label }}
                                                        </el-option>
                                                    </el-select>
                                                </floating-label-input>
                                            </div>
                                            <div class="column w-1/2">
                                                <floating-label-input label="Plot by"  class="mb-2">
                                                    <el-select
                                                            class="w-full"
                                                            placeholder="Group by..."
                                                            size="small"
                                                            @change="()=> { searchSalesStats() }"
                                                            v-model="chart_type">
                                                        <el-option
                                                                v-for="item in chart_types"
                                                                :key="item.value"
                                                                :label="item.label"
                                                                :value="item.value">
                                                            {{ item.label }}
                                                        </el-option>
                                                    </el-select>
                                                </floating-label-input>
                                            </div>
                                            <div class="column w-1/2">
                                                <stat-box custom-class="border border-gray-200 shadow" title="Transactions"
                                                          padding-class="py-5 px-2"
                                                          font-size="text-xl"
                                                          label-size="text-xs"
                                                          :loading="false"
                                                          :value="$store.state.map.data_metrics.sale_prices.stats.transactions ? $store.state.map.data_metrics.sale_prices.stats.transactions : 0"
                                                >
                                                </stat-box>
                                            </div>
                                            <div class="column w-1/2">
                                                <stat-box custom-class="border border-gray-200 shadow" title="Total Volume"
                                                          padding-class="py-5 px-2"
                                                          font-size="text-xl"
                                                          label-size="text-xs"
                                                          :loading="false"
                                                          :prefix="'£'"
                                                          :value="$store.state.map.data_metrics.sale_prices.stats.volume ? $store.state.map.data_metrics.sale_prices.stats.volume : 0"
                                                >
                                                </stat-box>
                                            </div>
                                            <div class="column w-full">
                                                <apexchart type="line"
                                                           v-if="getSalesChart !== null"
                                                           :options="getSalesChart.options"
                                                           :series="getSalesChart.series"></apexchart>
                                            </div>
                                            <div class="column w-1/2">
                                                <stat-box custom-class="border border-gray-200 shadow" title="Last Sale"
                                                          padding-class="py-5 px-2"
                                                          font-size="text-xl"
                                                          label-size="text-xs"
                                                          :loading="false"
                                                          :value="$store.state.map.data_metrics.sale_prices.stats.last_sale ? $moment($store.state.map.data_metrics.sale_prices.stats.last_sale).format('MMM Do YYYY') : '-'"
                                                >
                                                </stat-box>
                                            </div>
                                            <div class="column w-1/2">
                                                <stat-box
                                                        v-if="group_by === 'avg_price'"
                                                        custom-class="border border-gray-200 shadow" title="Avg. Price"
                                                          padding-class="py-5 px-2"
                                                          font-size="text-xl"
                                                          label-size="text-xs"
                                                          :loading="false"
                                                          :prefix="'£'"
                                                          :value="$store.state.map.data_metrics.sale_prices.stats.avg ? $store.state.map.data_metrics.sale_prices.stats.avg : 0"
                                               />
                                                <stat-box
                                                        v-if="group_by === 'avg_price_per_ft2'"
                                                        custom-class="border border-gray-200 shadow" title="Avg. Price per ft²"
                                                        padding-class="py-5 px-2"
                                                        font-size="text-xl"
                                                        label-size="text-xs"
                                                        :loading="false"
                                                        :prefix="'£'"
                                                        :value="$store.state.map.data_metrics.sale_prices.stats.avg_price_per_sqft ? $store.state.map.data_metrics.sale_prices.stats.avg_price_per_sqft : 0"
                                                />
                                            </div>
                                            <div class="column w-1/2">
                                                <stat-box
                                                        v-if="group_by === 'avg_price'"
                                                        custom-class="border border-gray-200 shadow" title="Min. Price"
                                                          padding-class="py-5 px-2"
                                                          font-size="text-xl"
                                                          label-size="text-xs"
                                                          :loading="false"
                                                          :prefix="'£'"
                                                          :value="$store.state.map.data_metrics.sale_prices.stats.min ? $store.state.map.data_metrics.sale_prices.stats.min : 0"
                                                />
                                                <stat-box
                                                        v-if="group_by === 'avg_price_per_ft2'"
                                                        custom-class="border border-gray-200 shadow" title="Min. Price per ft²"
                                                        padding-class="py-5 px-2"
                                                        font-size="text-xl"
                                                        label-size="text-xs"
                                                        :loading="false"
                                                        :prefix="'£'"
                                                        :value="$store.state.map.data_metrics.sale_prices.stats.avg_price_per_sqft ? $store.state.map.data_metrics.sale_prices.stats.min_price_per_sqft : 0"
                                                />
                                            </div>
                                            <div class="column w-1/2">
                                                <stat-box
                                                        v-if="group_by === 'avg_price'"
                                                        custom-class="border border-gray-200 shadow" title="Max. Price"
                                                        padding-class="py-5 px-2"
                                                        font-size="text-xl"
                                                        label-size="text-xs"
                                                        :loading="false"
                                                        :prefix="'£'"
                                                        :value="$store.state.map.data_metrics.sale_prices.stats.max ? $store.state.map.data_metrics.sale_prices.stats.max : 0"
                                                />
                                                <stat-box
                                                        v-if="group_by === 'avg_price_per_ft2'"
                                                        custom-class="border border-gray-200 shadow" title="Max. Price per ft²"
                                                        padding-class="py-5 px-2"
                                                        font-size="text-xl"
                                                        label-size="text-xs"
                                                        :loading="false"
                                                        :prefix="'£'"
                                                        :value="$store.state.map.data_metrics.sale_prices.stats.max_price_per_sqft ? $store.state.map.data_metrics.sale_prices.stats.max_price_per_sqft : 0"
                                                />
                                            </div>

                                        </div>
                                    </div>

                                </div>



                            </el-tab-pane>
                        </el-tabs>



                    </dashboard-widget>
                </div>
            </div>
        </div>
    </div>
</template>

<script>



    import PrettyTenure from "../../PrettyTenure";
    import EmptyState from "../../EmptyState";
    import DataLayerLoading from "./DataLayerLoading";
    import DataLayerFilters from "../../DataLayerFilters";
    import DataLayerTableWrapper from "../../DataLayerTableWrapper";
    import {download} from "../../../utils/download";
    import ExportButton from "../../ExportButton";

    export default {
        name: "SalePricesDataLayer",
        components: {
            ExportButton,
            DataLayerTableWrapper,
            DataLayerFilters,
            DataLayerLoading, EmptyState, PrettyTenure},
        data() {
            return {
                active_tab: 'table',
                chart_type: 'yearly',
                group_by: 'avg_price',
                chart_types: [
                    {value: 'yearly', label: 'Yearly'},
                    {value: 'quarterly', label: 'Quarterly'},
                    {value: 'monthly', label: 'Monthly'},
                ],
                chart_group_types: [
                    {value: 'avg_price', label: 'Price'},
                    {value: 'avg_price_per_ft2', label: 'Price per ft²'}
                ],
                filters: [

                    {
                        id: 'single_line_address',
                        label: 'Single Line Address',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'paon',
                        label: 'PAON',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'saon',
                        label: 'SAON',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'street',
                        label: 'Street',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'locality',
                        label: 'Locality',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'town_city',
                        label: 'Town/City',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'district',
                        label: 'District',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },

                    {
                        id: 'county',
                        label: 'County',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'postcode',
                        label: 'Postcode',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },

                    {
                        id: 'tenure',
                        value: 'tenure',
                        label: 'Tenure',
                        type: 'select',
                        choices: [
                            {label: "Freehold", value: "F"},
                            {label: "Leasehold", value: "L"}
                        ]
                    },
                    {
                        id: 'property_type',
                        value: 'property_type',
                        label: 'Property Type',
                        type: 'select',
                        choices: [
                            {label: "Detached", value: "D"},
                            {label: "Semi-detached", value: "S"},
                            {label: "Terraced", value: "T"},
                            {label: "Flat/Maisonette", value: "F"},
                            {label: "Other", value: "O"}
                        ]
                    },
                    {
                        id: 'new_build',
                        value: 'new_build',
                        label: 'New Build',
                        type: 'select',
                        choices: [
                            {label: "Yes", value: true},
                            {label: "No", value: false}
                        ]
                    },
                    {
                        id: 'price_paid',
                        label: 'Price Paid',
                        type: 'numeric',
                        operators: ["=", "<",  "<=", '>', ">="],
                    },
                    {
                        type: "text",
                        id: "date_of_transfer",
                        label: "Date Purchased",
                        operators: ["=", "<",  "<=", '>', ">="],
                    },


                ]
            }
        },
        created() {
            this.searchSalesTable(1);
            this.searchSalesStats();
            this.$store.commit('map/addLayerAvailability', 'salePrices');
            this.$store.commit('map/addLayerVisibility', 'salePrices');
        },
        beforeDestroy(){
            this.$store.commit('map/removeLayerAvailability', 'salePrices')
            this.$store.commit('map/removeLayerVisibility', 'salePrices')
        },
        watch: {
            '$store.state.map.bounds'(){
                const vm = this;

                if(this.$store.state.map.data_metrics.sale_prices.search_area !== null){
                    if(!vm.$store.state.map.data_metrics.sale_prices.table.fetching){
                        this.searchSalesTable(1);
                    }
                    if(!vm.$store.state.map.data_metrics.sale_prices.chart.fetching){
                        this.searchSalesStats();
                    }
                }
            }
        },
        computed: {

            getSalesChart(){
                let vm = this,
                    data = this.$store.state.map.data_metrics.sale_prices.chart.data;

                let categories = data && data.categories ? data.categories : [],
                    series = data && data.series ? data.series : [];

                if(!categories.length){
                    return null;
                }

                categories = categories.map((x) => {
                    if(vm.chart_type === 'yearly'){
                        return vm.$moment(x).format('YYYY')
                    }
                    return vm.$moment(x).format('MMM YYYY')
                })

                let dataSeries = [];

                dataSeries.push(series.find((i)=>{
                    return i.id === 'sales';
                }));

                if(this.group_by === 'avg_price'){
                    dataSeries.push(series.find((i)=>{
                        return i.id === 'avg_price';
                    }));
                }
                if(this.group_by === 'avg_price_per_ft2'){
                    dataSeries.push(series.find((i)=>{
                        return i.id === 'avg_price_per_ft2';
                    }));
                }

                return {
                    options: {
                        chart: {
                            zoom: {
                                enabled: false
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        xaxis: {
                            categories: categories
                        },
                        yaxis: [
                            {
                                seriesName: 'Transactions',
                                opposite: true,
                                title: {
                                    text: "Sales",
                                },
                                labels: {
                                    formatter(y) {
                                        return y.toLocaleString();
                                    }
                                }
                            },
                            {
                                title: {
                                    text: this.group_by === 'avg_price' ? 'Avg. Price' : 'Avg. Price per ft²',
                                },
                                labels: {
                                    formatter(y) {
                                        return '£' + y.toLocaleString();
                                    }
                                }
                            },
                        ],
                    },
                    series: dataSeries
                }
            },
        },
        methods: {
            exportData(type){
                const vm = this, object = this.buildFilters();
                object.format = type;
                vm.$store.dispatch('map/searchSalePrices', object)
                    .then((response) => {
                        download(response.data.data);
                    }).catch(() => {
                })
            },
            buildFilters(page) {

                let vm = this,
                    filters = {},
                    object = {
                        rpp: 10,
                        page: page || 1
                    },
                    queryObject = this.$refs.dataLayerFilters ? this.$refs.dataLayerFilters.getFilters() : {};

                if (queryObject && queryObject.match && queryObject.rules) {
                    filters.match = queryObject.match;
                    filters.rules = queryObject.rules;
                }

                if (this.$refs.tableWrapper && this.$refs.tableWrapper.getSortField().length > 0) {
                    let ex = this.$refs.tableWrapper.getSortField().split('|');
                    console.log(ex);
                    if (ex.length === 2) {
                        object.sort = ex[0];
                        object.order = ex[1];
                    }
                }

                const bounds = this.$store.state.map.search_area === 'bounds' ? vm.$store.getters['map/getMapBoundsAsObject'] : {};

                object.search_area = this.$store.state.map.search_area;

                return Object.assign(object, filters, bounds);

            },
            onSearchAreaChange(){
              const vm = this;
                vm.searchSalesTable(1);
                vm.searchSalesStats();
            },
            searchSalesStats() {

                const vm = this, object = this.buildFilters();

                object.view = 'chart';
                object.mode = vm.chart_type;

                vm.$store.state.map.data_metrics.sale_prices.chart.fetching = true;
                vm.$store.state.map.data_metrics.sale_prices.chart.data = null;
                vm.$store.state.map.data_metrics.sale_prices.legend = null;
                vm.$store.state.map.data_metrics.sale_prices.overview = null;
                vm.$store.state.map.data_metrics.sale_prices.stats = {}

                vm.$store.dispatch('map/searchSalePrices', object).then((response) => {
                    vm.$store.state.map.data_metrics.sale_prices.chart.fetching = false;
                    vm.$store.state.map.data_metrics.sale_prices.legend = response.data.data.stats.legend;
                    vm.$store.state.map.data_metrics.sale_prices.stats = response.data.data.stats.overview;
                    vm.$store.state.map.data_metrics.sale_prices.chart.data =  response.data.data.stats.legend.length > 0 ? response.data.data.chart : null;
                }).catch(() => {
                    vm.$store.state.map.data_metrics.sale_prices.chart.fetching = false;
                    vm.$store.state.map.data_metrics.sale_prices.chart.data = null;
                    vm.$store.state.map.data_metrics.sale_prices.legend = null;
                    vm.$store.state.map.data_metrics.sale_prices.stats = {};
                })

            },
            searchSalesTable(page) {
                const vm = this, object = this.buildFilters(page);

                vm.$store.state.map.data_metrics.sale_prices.table.fetching = true;
                vm.$store.dispatch('map/searchSalePrices', object).then((response) => {
                    vm.$store.state.map.data_metrics.sale_prices.table.fetching = false;
                    vm.$store.state.map.data_metrics.sale_prices.table.results = response.data.data.results;
                    vm.$store.state.map.data_metrics.sale_prices.table.meta = response.data.data.meta;
                }).catch(() => {
                    vm.$store.state.map.data_metrics.sale_prices.table.fetching = false;
                    vm.$store.state.map.data_metrics.sale_prices.table.results = [];
                    vm.$store.state.map.data_metrics.sale_prices.table.meta = {};
                })
            },
        }
    }
</script>
