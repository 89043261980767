<template>
    <div class="columns">
        <div class="column"
             :key="tag"
             v-for="tag in tags"
        >
            <el-tag
                    closable
                    :type="type"
                    :disable-transitions="false"
                    @close="handleClose(tag)">
                {{tag}}
            </el-tag>
        </div>
        <div class="column">
            <el-input
                    v-if="inputVisible"
                    v-model="inputValue"
                    ref="saveTagInput"
                    size="mini"
                    @keyup.enter.native="handleInputConfirm"
                    @blur="handleInputConfirm"
            />
            <el-button
                    v-if="!inputVisible"
                    type="success" size="small"
                       icon="el-icon-plus"
                       @click="showInput">Add Keyword</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ProposalTags",
        props: {
            type: {
                type: String,
                default(){
                    return 'success';
                }
            },
            value: {
                type: Array,
                default(){
                    return []
                }
            }
        },
        watch: {
          tags(){
              this.$emit('input', this.value)
          }
        },
        methods: {
            handleClose(tag) {
                this.tags.splice(this.tags.indexOf(tag), 1);
            },

            showInput() {
                this.inputVisible = true;
                this.$nextTick(() => {
                    this.$refs.saveTagInput.$refs.input.focus();
                });
            },

            handleInputConfirm() {
                let inputValue = this.inputValue;
                if (inputValue) {
                    this.tags.push(inputValue);
                }
                this.inputVisible = false;
                this.inputValue = '';
            }
        },
        data(){
            return {
                tags: this.value,
                inputVisible: false,
                inputValue: ''
            }
        }
    }
</script>

<style scoped>

</style>