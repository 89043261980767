<template>

        <page v-loading="loading">

            <template slot="header">
                <page-header
                        :title="team.name ? team.name : 'Manage Team'">
                    <template v-slot:tabs>
                        <active-team-nav />
                    </template>
                </page-header>

            </template>

            <template slot="content">
                <div class="columns">
                    <div class="column w-full">

                        <dashboard-widget
                                padding-class=""
                                title="Team Members">

                            <empty-state class="p-3"
                                         title="No results found"
                                         message="You currently have no team members"
                                         :support="false"
                                         v-if="!table.loading && !table.results.length"/>


                            <div class="p-3 py-12 text-center" v-if="table.loading">
                                <property-loader />
                            </div>


                            <div v-if="table.results.length > 0 && !table.loading">
                                <div class="p-3">
                                    <el-alert effect="dark" type="info" :closable="false">
                                        {{ table.meta.totalResult.toLocaleString() }} team members found. Displaying page {{ table.meta.current }} of {{ table.meta.pageCount }}
                                    </el-alert>
                                </div>
                                <div class="columns p-3">
                                    <div class="column ml-auto">
                                        <el-pagination
                                                background
                                                layout="prev, pager, next"
                                                @current-change="(page)=>{ fetch(page) }"
                                                :page-size="table.rpp"
                                                :current-page="table.meta.current"
                                                :total="table.meta.totalResult">
                                        </el-pagination>
                                    </div>
                                </div>
                                <el-table :data="table.results">
                                    <el-table-column label="Name">
                                        <template slot-scope="scope">

                                            <div  class="flex items-start justify-start">
                                                <div class="flex-shrink-0 h-10 w-10">
                                                    <avatar-manager
                                                            :name="scope.row.name"
                                                            v-model="scope.row.avatar" />
                                                </div>
                                                <div class="ml-4">
                                                    <div class="text-sm font-medium text-gray-900">
                                                        {{ scope.row.name }}
                                                    </div>
                                                    <div class="text-xs text-gray-500">
                                                        {{ scope.row.email }}
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column width="130" label="Role">
                                        <template slot-scope="scope">
                                            <span
                                                    :class="{
                                           'is-success': scope.row.is_owner,
                                           'is-info': !scope.row.is_owner
                                           }"
                                                    class="tag">
                                       {{ scope.row.is_owner ? 'Owner' : 'Member' }}
                                   </span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column width="180" label="Seat">
                                        <template slot-scope="scope">
                                            <div
                                                    v-if="!scope.row.has_subscription" class="tag is-warning">
                                                {{ scope.row.has_subscription ? 'Assigned' : 'None' }}
                                            </div>

                                            <div   v-if="scope.row.has_subscription">
                                                <date-human-view
                                                        prefix="Expires in "
                                                        v-model="scope.row.subscription_to" />
                                            </div>


                                        </template>
                                    </el-table-column>
                                    <el-table-column  width="120" label="Status">
                                        <template slot-scope="scope">
                                            <pretty-status-label-component
                                                    v-model="scope.row.status"
                                            />
                                        </template>
                                    </el-table-column>


                                    <el-table-column width="140" align="right">


                                        <template slot-scope="scope">
                                            <el-dropdown @command="(cmd)=>{ handleCommand(cmd, scope.row.id)  }">
                                                <el-button size="medium" type="info">
                                                    Actions <i class="el-icon-arrow-down el-icon--right"></i>
                                                </el-button>
                                                <el-dropdown-menu slot="dropdown">
                                                    <el-dropdown-item
                                                            :disabled="scope.row.has_subscription"
                                                            command="assign">
                                                        Assign Seat
                                                    </el-dropdown-item>
                                                    <el-dropdown-item
                                                            :disabled="!scope.row.has_subscription"
                                                            command="revoke">
                                                        Revoke Seat
                                                    </el-dropdown-item>
                                                    <el-dropdown-item
                                                            :disabled="scope.row.is_owner"
                                                            command="manage">
                                                        Manage Team Member
                                                    </el-dropdown-item>
                                                    <el-dropdown-item
                                                            :disabled="scope.row.is_owner"
                                                            command="remove">
                                                        Remove
                                                    </el-dropdown-item>
                                                </el-dropdown-menu>
                                            </el-dropdown>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <div class="columns p-3">
                                    <div class="column ml-auto">
                                        <el-pagination
                                                background
                                                layout="prev, pager, next"
                                                @current-change="(page)=>{ fetch(page) }"
                                                :page-size="table.rpp"
                                                :current-page="table.meta.current"
                                                :total="table.meta.totalResult">
                                        </el-pagination>
                                    </div>
                                </div>
                            </div>

                        </dashboard-widget>
                    </div>
                </div>
            </template>


            <el-dialog
                    title="Assign Seat"
                    :append-to-body="true"
                    :close-on-press-escape="false"
                    :close-on-click-modal="false"
                    :show-close="!subscription.updating"
                    :visible.sync="subscription.show">

                <div class="p-3 text-center" v-if="seats.loading">
                    <property-loader />
                </div>

                <div class="text-center" v-if="!seats.loading && !seats.results.length">
                    <h3 class="font-semibold text-xl text-blue-800">You have no available seats.</h3>
                    <div class="my-3">
                        <missing :height="200" />
                    </div>
                    <div class="leading-loose text-sm">
                        <p class="mb-1">Sorry, but you dont seem to have any available seats.</p>
                        <p class="mb-1">If you would like to purchase a additional seats please speak to our team.</p>

                        <p class="mt-4">
                            <el-button
                                    @click="$store.dispatch('auth/openSupport')"
                                    type="info">Purchase Seats</el-button>
                        </p>
                    </div>
                </div>

                <div v-if="!seats.loading && seats.results.length > 0">

                    <el-form label-position="top" :model="seats.selected">
                        <el-form-item :label="'Available Seats (' + seats.results.length + ')'">
                            <el-select
                                    class="w-full"
                                    filterable
                                    placeholder="Select Local Authority..."
                                    v-model="seats.selected.team"
                            >
                                <el-option
                                        class="el-option-select--seat-data"
                                        :key="i.id"
                                        :value="i.id"
                                        :label="'Seat ' + (k + 1)"
                                        v-for="(i, k) of seats.results">

                                    <div class="leading-tight p-1">
                                        <h4 class="font-semibold">Seat {{ k + 1 }}</h4>
                                        <p class="text-xs my-2">
                                           Expires on {{ i.subscription_to | moment("MMM Do YYYY") }}
                                            <br /><small>{{ i.subscription_to | moment("from", "now") }}</small>
                                        </p>
                                    </div>

                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>

                    <div class="flex items-center w-full -px-2">
                       <div class="ml-auto flex items-center p-2">
                           <el-link
                           @click="subscription.show = false"
                           >Cancel</el-link>
                       </div>
                        <div class="p-2">
                            <el-button
                                    :disabled="seats.selected.team === null"
                                    @click="assignSeat"
                                    type="success">Assign Seat</el-button>
                        </div>
                    </div>

                </div>


            </el-dialog>

        </page>

</template>
<script>

    import EmptyState from "../../components/EmptyState";
    import PropertyLoader from "../../components/incs/PropertyLoader";
    import Missing from "../../components/Missing";
    import ActiveTeamNav from "./ActiveTeamNav";
    import AvatarManager from "../../components/AvatarManager";
    import PrettyStatusLabelComponent from "../../components/PrettyStatusLabelComponent";
    export default {
        name: 'ManageTeam',
        components: {PrettyStatusLabelComponent, AvatarManager, ActiveTeamNav, Missing, PropertyLoader, EmptyState},
        mounted(){
            this.fetch(1);
        },
        watch: {
            'subscription.show'(){
                if(this.subscription.show){
                    this.fetchSeats();
                }
            }
        },
        methods: {
            handleCommand(command, id){
                switch(command){
                    case "revoke":
                        return this.revoke(id);
                    case "assign":
                        return this.launchSeatModal(id);
                    case "remove":
                        return this.confirmRemoval(id);
                }
            },
            confirmRemoval(id){
                let vm = this;

                vm.$confirm(
                    'Are you sure you want to remove this team member? This cannot be undone.',
                    'Remove Team Member?',
                    {
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'Cancel'
                    })
                    .then(() => {
                        vm.seats.loading = true;
                        this.$store.dispatch('teamMember/remove', {
                            teamId: vm.$route.params.teamId,
                            memberId: id
                        }).then(()=>{
                            vm.$notify({
                                type: 'success',
                                title: 'Team Member Removed',
                                message: 'Team member was removed successfully',
                                position: 'top-right',
                                offset: 140,
                            });

                            setTimeout(()=>{
                                vm.fetch(1);
                            }, 100)
                        }).catch((e)=>{
                            console.log('error', e);
                            vm.seats.loading = false;
                            vm.$store.dispatch('auth/handleServerError', {
                                vm: vm,
                                error: e
                            });
                        })


                    }).catch((e) => {
                    console.log(e);
                });
            },
            revoke(id){
               // revokeSubscription

                let vm = this;

                vm.$confirm(
                    'Are you sure you want to revoke this seat? This cannot be undone.',
                    'Revoke Seat?',
                    {
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'Cancel'
                    })
                    .then(() => {
                        //vm.loading = true;

                        console.log({
                            teamId: vm.$route.params.teamId,
                            memberId: id
                        })

                        this.$store.dispatch('teamMember/revokeSubscription', {
                            teamId: vm.$route.params.teamId,
                            memberId: id
                        }).then(()=>{
                            vm.$notify({
                                type: 'success',
                                title: 'Seat Revoked',
                                message: 'Seat was revoked successfully',
                                position: 'top-right',
                                offset: 140,
                            });

                            setTimeout(()=>{
                                vm.fetch(1);
                            }, 100)
                        }).catch((e)=>{
                            console.log('error', e);
                            vm.$store.dispatch('auth/handleServerError', {
                                vm: vm,
                                error: e
                            });
                        })


                    }).catch((e) => {
                    console.log(e);
                });
            },
            launchSeatModal(memberId){
                const vm = this;
                vm.seats.activeMember = memberId;
                vm.subscription.show = true;
            },
            assignSeat(){
                const vm = this;
                vm.seats.loading = true;
                return vm.$store.dispatch('teamMember/addSubscription', {
                    seatId: vm.seats.selected.team,
                    teamId: vm.$route.params.teamId,
                    memberId: vm.seats.activeMember
                }).then(() => {
                    vm.seats.loading = false;
                    vm.subscription.show = false;
                    vm.fetch(1);
                    vm.$notify({
                        type: 'success',
                        title: 'Subscription Added',
                        message: 'Subscription was added  successfully',
                        position: 'top-right',
                        offset: 140,
                    });
                }).catch((e) => {
                    vm.seats.loading = false;
                    vm.$store.dispatch('auth/handleServerError', {
                        vm: vm,
                        error: e
                    });
                })
            },
            fetchSeats(){
                const vm = this;
                vm.seats.loading = true;
                return vm.$store.dispatch('teamSeat/index', {
                    teamId: vm.$route.params.teamId,
                    params: {
                        available: true
                    }
                }).then((response) => {
                    vm.seats.selected.team = null;
                    vm.seats.loading = false;
                    vm.seats.results = response.data.data;
                }).catch(() => {
                    vm.seats.selected.team = null;
                    vm.seats.loading = false;
                    vm.seats.results = [];
                })
            },
            fetch(page) {
                const vm = this;
                vm.table.loading = true;
                return vm.$store.dispatch('teamMember/index', {
                    teamId: vm.$route.params.teamId,
                    params: {
                        page: page || 1,
                        rpp: vm.table.rpp
                    }
                }).then((response) => {
                    vm.table.loading = false;
                    vm.table.results = response.data.data.results;
                    vm.table.meta = response.data.data.meta;
                }).catch(() => {
                    vm.table.loading = false;
                    vm.table.results = [];
                    vm.table.meta = {};
                })

            }
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.team = {};
                vm.loading = true;
                vm.$http.get('/api/v1/team/' + vm.$route.params.teamId)
                    .then((response) => {

                        vm.team = response.data.data;

                        vm.$store.commit('auth/setBreadcrumbs', [
                            {
                                title: 'My Teams',
                                path: '/teams'
                            },
                            {
                                title: response.data.data.name,
                            }
                        ]);



                        vm.fetch(1);

                        vm.loading = false;

                    }).catch(() => {

                })
            })
        },
        data(){
            return {
                loading: true,
                subscription: {
                    updating: false,
                    show: false
                },
                seats: {
                    activeMember: null,
                  loading: false,
                  results: [],
                    selected: {
                        team: null
                    }
                },
                team: {},
                table: {
                    rpp: 10,
                    loading: true,
                    results: [

                    ],
                    meta: {}
                }
            }
        }
    }
</script>
<style>
    .el-option-select--seat-data {
        height: auto !important;
    }
</style>
