<template>
    <div>

        <navigation/>

        <div v-if="!loading">
            <page-header :title="title"/>

            <page>

                <div class="columns">
                    <div class="column w-full">
                        <dashboard-widget
                                :title="'Edit Alert ' + title"
                                          description="Recently decided planning applications">

                            <template slot="cta">
                                <el-button
                                        @click="confirmDelete"
                                        :disabled="deleting"
                                        :loading="deleting"
                                        type="danger" icon="el-icon-delete">Delete
                                </el-button>
                            </template>

                            <div v-loading="deleting">
                                <application-alert-create v-model="object" />
                            </div>
                        </dashboard-widget>
                    </div>
                </div>

            </page>
        </div>

    </div>

</template>

<script>

    import ApplicationAlertCreate from "../../components/incs/ApplicationAlertCreateEdit";
    export default {
        name: 'AlertsRead',
        components: {ApplicationAlertCreate},
        data(){
          return {
              loading: true,
              deleting: false,
              object: {}
          }
        },
        methods: {
            confirmDelete() {
                let vm = this;

                vm.deleting = true;
                vm.$confirm(
                    'Are you sure you want to delete this alert? This cannot be undone.',
                    'Delete Alert?',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel'
                    })
                    .then(() => {

                        vm.$store.dispatch('alert/remove', {
                            hash: this.object.id
                        })
                            .then(() => {
                                console.log('Deleted')
                                vm.$notify({
                                    type: 'success',
                                    title: 'Alert Deleted',
                                    message: 'Application alert deleted successfully.',
                                    position: 'top-right',
                                    offset: 140,
                                });
                                vm.$router.push('/alerts')
                            })
                            .catch(() => {
                                vm.deleting = false;
                                vm.$notify({
                                    type: 'warning',
                                    title: 'Something went wrong',
                                    message: 'Sorry, but we could not delete this alert.',
                                    position: 'top-right',
                                    offset: 140
                                });
                            })

                    }).catch((e) => {
                    vm.deleting = false;
                    console.log(e);
                });

            },
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {

                vm.$store.dispatch('alert/get', {
                    hash: vm.$route.params.hash
                })
                    .then((response) => {

                        vm.$store.commit('auth/setBreadcrumbs', [
                            {
                                title: 'Application Alerts',
                                path: '/alerts'
                            },
                            {
                                title: response.data.data.name
                            }
                        ]);

                        vm.object = response.data.data;

                        vm.title = response.data.data.name;

                        vm.loading = false;
                        next();
                })
                    .catch(()=>{

                    })
            })
        }
    }
</script>