<template>

    <div>
        <!--

        </div>
        -->

        <transition
                enter-active-class="transition ease-in-out duration-300 transform"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition ease-in-out duration-300 transform"
                leave-class="opacity-100"
                leave-to-class="opacity-0"
        >
            <div
                    v-if="active_option !== null"
                    class="absolute z-3001 inset-0 transition-opacity bg-opacity-50 bg-black">


                <transition
                        enter-active-class="transition  delay-700 ease-in-out duration-300 transform"
                        enter-class="-translate-x-full"
                        enter-to-class="translate-x-0"
                        leave-active-class="transition  ease-in-out duration-300 transform"
                        leave-class="translate-x-0"
                        leave-to-class="-translate-x-full"
                >
                    <menu-sidebar-layer
                            v-if="active_option === 'info'"
                            :show-close="!modal_data.loading"
                            class="bg-white ml-20 mt-16 relative el-block--job-data el-block--job-data--land-hawk"
                            @close="onClose">


                        <ownership-loading
                                v-if="modal_data.loading"
                        />

                        <ownership-layer-details
                                v-if="!modal_data.loading"
                                v-model="modal_data.data"
                                :show-snap-to="false"
                                :show-find-properties="false"
                                @close="()=>{ active_option = null  }"
                        />

                    </menu-sidebar-layer>
                </transition>


            </div>


        </transition>

        <page v-loading="loading">

            <template slot="header">
                <page-header :title="title">
                    <template v-slot:tabs>
                        <land-hawk-nav />
                    </template>
                </page-header>
            </template>



            <template slot="content">
                <div class="columns">
                    <div class="column w-full">
                        <dashboard-widget
                                :title="title"
                                padding-class="">

                            <template slot="cta">
                                <export-button
                                        :exporting="exporting"
                                        @selected="exportData"
                                />
                            </template>

                            <empty-state class="p-3"
                                         title="No LandHawk results found"
                                         message="Sorry, but we could not find any LandHawk results"
                                         :support="false"
                                         v-if="!table.loading && !table.results.length"/>


                            <div class="p-3 py-12 text-center" v-if="table.loading">
                                <property-loader />
                                <h3 class="text-blue-800">Loading Results...</h3>
                            </div>

                            <div v-if="table.results.length > 0 && !table.loading">
                                <div class="p-3">
                                    <el-alert effect="dark" type="info" :closable="false">
                                        {{ table.meta.totalResult.toLocaleString() }} sites found. Displaying page {{ table.meta.current }} of {{ table.meta.pageCount }}
                                    </el-alert>
                                </div>
                                <div class="columns p-3">
                                    <div class="column ml-auto">
                                        <el-pagination
                                                background
                                                layout="prev, pager, next"
                                                @current-change="(page)=>{ fetchJob(page) }"
                                                :page-size="table.rpp"
                                                :current-page="table.meta.current"
                                                :total="table.meta.totalResult">
                                        </el-pagination>
                                    </div>
                                </div>
                                <el-table
                                        :default-sort="default_sort"
                                        @sort-change="onSortChange"
                                        :data="table.results">
                                    <el-table-column
                                            width="250"
                                            prop="address.keyword"
                                            sortable="custom"
                                            label="Location">
                                        <template slot-scope="scope">
                                            <ownership-overview-map
                                                    :height="150"
                                                    :bbox="scope.row.bbox"
                                                    :coords="scope.row.center"
                                                    :polygons="scope.row.polygons"
                                            />
                                            <div class="text-xs mt-1 leading-tight">
                                                {{ scope.row.address }}
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            prop="title_number.keyword"
                                            sortable="custom"
                                            label="Title Number">
                                        <template slot-scope="scope">
                                           <strong> {{ scope.row.title_number }}</strong>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            width="120"
                                            prop="postcode.keyword"
                                            sortable="custom"
                                            label="Postcode">
                                        <template slot-scope="scope">
                                            <strong> {{ scope.row.postcode }}</strong>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            prop="tenure.keyword"
                                            width="110"
                                            label="Tenure">
                                        <template slot-scope="scope">
                                            {{ scope.row.tenure }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            prop="distance_m"
                                            sortable="custom"
                                            width="110"
                                            align="right"
                                            label="Distance">
                                        <template slot-scope="scope">
                                            {{ scope.row.distance_km ? scope.row.distance_km.toLocaleString()  : '0' }}km
                                        </template>
                                    </el-table-column>

                                    <el-table-column
                                            width="120"
                                            prop="distance_m"
                                            sortable="custom"
                                            align="right"
                                            label="Area">
                                        <template slot-scope="scope">
                                            {{ scope.row.area_acres ? scope.row.area_acres.toLocaleString() + '  acres' : '' }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column width="140" align="right">
                                        <template slot-scope="scope">

                                            <el-dropdown @command="(cmd)=>{ handleCommand(cmd, (cmd === 'lm' ? scope.row.title_number : scope.row.inspire_id))  }">
                                                <el-button size="medium" type="info">
                                                    Actions <i class="el-icon-arrow-down el-icon--right"></i>
                                                </el-button>
                                                <el-dropdown-menu slot="dropdown">
                                                    <el-dropdown-item command="view">
                                                        View Details
                                                    </el-dropdown-item>
                                                    <el-dropdown-item command="lm">
                                                        View on Land Monitor
                                                    </el-dropdown-item>
                                                </el-dropdown-menu>
                                            </el-dropdown>


                                        </template>
                                    </el-table-column>
                                </el-table>
                                <div class="columns p-3">
                                    <div class="column ml-auto">
                                        <el-pagination
                                                background
                                                layout="prev, pager, next"
                                                @current-change="(page)=>{ fetchJob(page) }"
                                                :page-size="table.rpp"
                                                :current-page="table.meta.current"
                                                :total="table.meta.totalResult">
                                        </el-pagination>
                                    </div>
                                </div>
                            </div>


                        </dashboard-widget>
                    </div>
                </div>
            </template>

        </page>

    </div>


</template>
<script>

    import OwnershipOverviewMap from "../../components/LandMonitor/OwnershipLayerComponents/incs/OwnershipOverviewMap";

    import MenuSidebarLayer from "../../components/LandMonitor/MenuSidebarLayer";
    import OwnershipLoading from "../../components/LandMonitor/OwnershipLayerComponents/OwnershipLoading";
    import OwnershipLayerDetails from "../../components/LandMonitor/OwnershipLayerDetails";
    import PropertyLoader from "../../components/incs/PropertyLoader";
    import EmptyState from "../../components/EmptyState";
    import LandHawkNav from "./LandHawkNav";
    import ExportButton from "../../components/ExportButton";

    import { download } from './../../utils/download'

    export default {
        name: 'LandHawkJob',
        components: {
            ExportButton,
            LandHawkNav,
            EmptyState,
            PropertyLoader, OwnershipLayerDetails, OwnershipOverviewMap, MenuSidebarLayer, OwnershipLoading},
        data() {
            return {
                name: null,
                title: 'LandHawk Results',
                exporting: false,
                active_option: null,
                modal_data: {
                    loading: true,
                    data: {}
                },
                loading: true,
                default_sort: {
                    prop: 'distance_m',
                    order: 'desc'
                },
                table: {
                    loading: true,
                    results: [],
                    rpp: 10,
                    meta: {}
                },
            }
        },
        watch: {
            '$store.state.map.polygon_filters.radius'() {
                this.fitBounds();
            }
        },
        methods: {
            onClose(){
                const vm = this;
                vm.active_option = null;
                console.log('Closed!')
                vm.setBreadcrumbs();
            },
            onSortChange(object) {
                this.default_sort = object.prop && object.order ? object : null;
                this.fetchJob(1);
            },
            handleCommand(cmd, id){
                const vm = this;
                switch(cmd){
                    case "lm":

                        vm.$confirm(
                            'Are you sure you want to view this title number on Land Monitor? You will be redirected from this page.',
                            'View on Land Monitor map?',
                            {
                                confirmButtonText: 'Yes',
                                cancelButtonText: 'Cancel'
                            })
                            .then(() => {
                                vm.$router.push('/?title-number=' + id )
                            })
                            .catch(() => {

                            })

                        break;
                    case "view":
                        vm.fetchOwnership(id);
                        break;
                }
            },
            setBreadcrumbs(){
                this.$store.commit('auth/setBreadcrumbs', [
                    {
                        title: 'LandHawk Jobs',
                        path: '/land-hawk-jobs'
                    },
                    {
                        title: this.name
                    }
                ]);
            },
            exportData(format){

                const vm = this;
                vm.exporting = true;
                vm.loading = true;


                vm.$store.dispatch('job/download', {
                    jobId: vm.$route.params.jobId,
                    format: format,
                }).then((response)=>{

                    download(response.data.data);
                    vm.exporting = false;
                    vm.loading = false;

                }).catch(()=> {
                    vm.exporting = false;
                    vm.loading = false;
                })

            },
            onLocationSearchChange(opts) {
                const vm = this;
                if (opts.latitude && opts.longitude) {
                    vm.map.center = [opts.latitude, opts.longitude];
                    vm.fitBounds();
                }
            },
            onMapClick(event) {
                console.log('onMapClick', event)
                this.map.center = event.latlng;
            },
            fetchOwnership(inspireId) {
                const vm = this;
                vm.active_option = 'info';
                vm.modal_data.loading = true;
                vm.modal_data.data = {};
                return vm.$store.dispatch('map/fetch', {
                    inspireId: inspireId
                })
                    .then((response) => {
                        vm.modal_data.data = response;
                        vm.modal_data.loading = false;
                    })
                    .catch(() => {
                        vm.modal_data.loading = false;
                    })
            },
            fetchJob(page) {
                const vm = this;
                vm.table.loading = true;
                return vm.$store.dispatch('job/read', {
                    jobId: vm.$route.params.jobId,
                    params: {
                        page: page || 1,
                        rpp: vm.table.rpp,
                        sort: vm.default_sort && vm.default_sort.prop ? vm.default_sort.prop : null,
                        order: vm.default_sort && vm.default_sort.order ? vm.default_sort.order === 'descending' ? 'desc' : 'asc' : null,

                    }
                }).then((response) => {
                    vm.table.loading = false;
                    vm.table.results = response.data.data.results;
                    vm.table.meta = response.data.data.meta;
                }).catch(() => {
                    vm.table.loading = false;
                    vm.table.results = [];
                })

            }
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.loading = true;
                vm.$store.dispatch('job/read', {
                    jobId: vm.$route.params.jobId,
                    params: {
                        page: 1,
                        rpp: vm.table.rpp
                    }
                }).then((response)=>{

                    vm.loading = false;
                    vm.table.loading = false;
                    vm.table.results = response.data.data.results;
                    vm.table.meta = response.data.data.meta;

                    vm.name = response.data.data.job.name;


                    vm.title += ' - ' + vm.name;

                    vm.setBreadcrumbs();

                }).catch(()=>{

                    vm.$alert('Sorry, but this LandHawk Job does not exist.', 'LandHawk Job not Found', {
                        confirmButtonText: 'OK',
                        callback: () => {
                            vm.$router.push('/land-hawk-jobs')
                        }
                    });

                    vm.table.loading = false;
                    vm.table.results = [];
                    vm.table.meta = {};
                })

            })
        }
    }
</script>
<style lang="scss">
    .z-3001 {
        z-index: 3001;
    }
    .el-block--job-data--land-hawk {
        .el-block--scroll-detail {
            height: calc(100vh - 248px) !important
        }
    }
</style>

