<template>
        <page>

            <template slot="header">
                <page-header title="Search Planning Applications">
                    <template v-slot:tabs>
                        <planning-applications-nav />
                    </template>
                </page-header>
            </template>

            <template slot="content">
                <div class="columns">
                    <div class="column w-full" v-if="$store.state.applications.search.show">
                        <applications-search-form
                                @change="(val)=>{ $store.state.applications.search.options = val }"
                                @search="fetch(1)"
                        />
                    </div>
                    <div class="column w-full"
                         v-if="!$store.state.applications.search.show">
                        <dashboard-widget
                                padding-class=""
                                title="Planning Applications">

                            <template slot="cta">
                                <el-button
                                        @click="showSearch"
                                        :disabled="$store.state.applications.search.loading"
                                        type="success" icon="el-icon-search">Show Search</el-button>
                            </template>



                            <empty-state class="p-3"
                                         title="No applications found"
                                         message="Sorry, but we could not find any applications matching your search"
                                         :support="false"
                                         v-if="!$store.state.applications.search.loading && !$store.state.applications.search.results.length" />


                            <div class="h-32 w-full"
                                 v-if="$store.state.applications.search.loading"
                                 v-loading="true"></div>

                            <div class="columns p-4"
                                 v-if="$store.state.applications.search.meta.totalResult && !$store.state.applications.search.loading"
                            >
                                <div class="column flex items-center">
                                <span class="text-sm">
                                  {{ $store.state.applications.search.meta.totalResult.toLocaleString() }} applications found. Displaying page {{ $store.state.applications.search.meta.current }} of {{ $store.state.applications.search.meta.pageCount.toLocaleString() }}
                                </span>
                                </div>
                                <div class="column ml-auto">
                                    <div class="columns">
                                        <div class="column flex items-center">
                                            <span class="text-gray-600 uppercase text-xs">per page</span>
                                        </div>
                                        <div class="column">
                                            <el-select
                                                    class="w-32"
                                                    v-model="$store.state.applications.search.rpp" @change="fetch(1)">
                                                <el-option :value="10">10</el-option>
                                                <el-option :value="20">20</el-option>
                                                <el-option :value="30">30</el-option>
                                                <el-option :value="40">40</el-option>
                                                <el-option :value="50">50</el-option>
                                                <el-option :value="100">100</el-option>
                                            </el-select>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="columns py-4"
                                 v-if="$store.state.applications.search.meta.totalResult && !$store.state.applications.search.loading"
                            >
                                <div class="column ml-auto">
                                    <el-pagination
                                            background
                                            layout="prev, pager, next"
                                            @current-change="(page)=>{ fetch(page) }"
                                            :page-size="$store.state.applications.search.rpp"
                                            :current-page="$store.state.applications.search.meta.current"
                                            :total="$store.state.applications.search.meta.totalResult">
                                    </el-pagination>
                                </div>
                            </div>

                            <el-table
                                    v-if="$store.state.applications.search.results.length > 0"
                                    class="el--table"
                                    :data="$store.state.applications.search.results"
                                    :default-sort="$store.state.applications.search.default_sort"
                                    @sort-change="onSortChange"
                                    style="width: 100%">
                                <el-table-column
                                        prop="proposal.keyword"
                                        sortable="custom"
                                        label="Proposal">
                                    <template slot-scope="scope">

                                        <div class="break-normal text-xs leading-tight">
                                            <p class="font-medium text-blue-700 leading-loose truncate w-full">{{ scope.row.proposal }}</p>
                                            <p class="pt-1"> {{ scope.row.local_authority }} - {{ scope.row.site_address }}</p>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        width="160"
                                        prop="status.keyword"
                                        sortable="custom"
                                        label="Status">
                                    <template slot-scope="scope">
                                          <span class="w-2 h-2 inline-block rounded-full"
                                                :class="{
                                        'bg-green-500': scope.row.status_simplified === 'DECIDED',
                                        'bg-blue-500': scope.row.status_simplified === 'PENDING',
                                        'bg-gray-500': scope.row.status_simplified === 'WITHDRAWN'
                                        }"
                                          ></span>
                                        <span class="flex-grow ml-1">{{ scope.row.status }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        width="160"
                                        prop="decision.keyword"
                                        sortable="custom"
                                        label="Decision">
                                    <template slot-scope="scope">

                                        <span class="w-2 h-2 inline-block rounded-full"
                                              :class="{
                                        'bg-red-500': scope.row.decision_simplified === 'REFUSED',
                                        'bg-green-500': scope.row.decision_simplified === 'APPROVED',
                                        'bg-blue-500': scope.row.decision_simplified === 'PENDING',
                                        'bg-gray-500': scope.row.decision_simplified === 'WITHDRAWN',
                                        'bg-orange-500': scope.row.decision_simplified === 'SPLIT'
                                        }"
                                        ></span>
                                        <span class="flex-grow ml-1">{{ scope.row.decision }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        width="140"
                                        prop="date_received"
                                        sortable="custom"
                                        label="Date Received">
                                    <template slot-scope="scope">
                                        <date-human-view v-model="scope.row.date_received" />
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        width="140"
                                        prop="date_decided"
                                        sortable="custom"
                                        label="Date Decided">
                                    <template slot-scope="scope">
                                            <date-human-view v-model="scope.row.date_decided" />
                                    </template>
                                </el-table-column>

                                <el-table-column
                                        fixed="right"
                                        align="right"
                                        width="140"
                                >
                                    <template slot-scope="scope">


                                        <el-dropdown @command="(cmd)=>{ handleCommand(cmd, scope.row.id)  }">
                                            <el-button size="medium"

                                                       :type="scope.row.watching ? 'warning' : 'success'">
                             Actions <i class="el-icon-arrow-down el-icon--right"></i>
                                            </el-button>
                                            <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item command="view">
                                                   View
                                                </el-dropdown-item>
                                                <el-dropdown-item
                                                        :disabled="scope.row.watching"
                                                        command="watch">
                                                    Watch
                                                </el-dropdown-item>
                                                <el-dropdown-item
                                                        :disabled="!scope.row.watching"
                                                        command="unwatch">
                                                    Un-watch
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>



                                    </template>
                                </el-table-column>



                            </el-table>


                            <div class="columns p-4"
                                 v-if="$store.state.applications.search.meta.totalResult && !$store.state.applications.search.loading"
                            >
                                <div class="column flex items-center">
                                <span class="text-sm">
                                  {{ $store.state.applications.search.meta.totalResult.toLocaleString() }} applications found. Displaying page {{ $store.state.applications.search.meta.current }} of {{ $store.state.applications.search.meta.pageCount.toLocaleString() }}
                                </span>
                                </div>
                                <div class="column ml-auto">
                                    <el-pagination
                                            background
                                            layout="prev, pager, next"
                                            @current-change="(page)=>{ fetch(page) }"
                                            :page-size="$store.state.applications.search.rpp"
                                            :current-page="$store.state.applications.search.meta.current"
                                            :total="$store.state.applications.search.meta.totalResult">
                                    </el-pagination>
                                </div>
                            </div>


                        </dashboard-widget>
                    </div>
                </div>
            </template>

        </page>
</template>

<script>

    import PageHeader from "../../components/incs/PageHeader";
    import Page from "../Page";
    import ApplicationsSearchForm from "../../components/ApplicationsSearchForm";
    import EmptyState from "../../components/EmptyState";
    import PlanningApplicationsNav from "./PlanningApplicationsNav";

    export default {
        name: 'ApplicationsSearch',
        components: {PlanningApplicationsNav, EmptyState, ApplicationsSearchForm, Page, PageHeader},
        data(){
            return {
                selected: this.$route.path,
                options: [

                    {label: 'Dashboard', url: '/planning-applications'},
                    {label: 'Search', url: '/planning-applications/search'},
                ]
            }
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.$store.commit('auth/setBreadcrumbs', [
                    {
                        title: 'Planning Applications',
                        path: '/planning-applications'
                    },
                    {
                        title: 'Search'
                    }
                ]);
            })
        },
        methods: {
            handleCommand(cmd, id){
                const vm = this;
                switch(cmd){
                    case "view":
                        vm.$router.push('/planning-application/' + id )
                        break;
                    case "watch":
                        vm.toggleWatchlist(false, id);
                        break;
                    case "unwatch":
                        vm.toggleWatchlist(true, id);
                        break;
                }
            },
            toggleWatchlist(watching, hash) {
                let vm = this;
                if (watching) {
                    this.$store.dispatch('watchlist/remove', {
                        hash: hash
                    }).then(() => {
                        vm.refreshWatch();
                        vm.$notify({
                            position: 'top-right',
                            offset: 140,
                            type: 'success',
                            title: 'Application Removed',
                            message: 'Application removed from watchlist',
                        });
                    })
                } else {
                    this.$store.dispatch('watchlist/add', {
                        hash: hash
                    }).then(() => {
                        vm.refreshWatch();
                        vm.$notify({
                            position: 'top-right',
                            offset: 140,
                            type: 'success',
                            title: 'Application Added',
                            message: 'Application added to watchlist',
                        });
                    })
                }
            },
            refreshWatch(){
                let vm = this;
                if(vm.$store.state.applications.search.meta && vm.$store.state.applications.search.meta.current){
                    console.log('refresh')
                    let page = vm.$store.state.applications.search.meta.current;
                    vm.$store.state.applications.search.meta = {};
                    vm.$store.state.applications.search.results = [];
                    vm.$store.state.applications.search.show = false;
                    vm.$store.state.applications.search.loading = true;
                    setTimeout(()=>{
                        vm.fetch(page)
                    }, 1000)
                }
            },
            showSearch(){
                this.$store.state.applications.search.meta = {};
                this.$store.state.applications.search.results = [];
                this.$store.state.applications.search.show = true;
            },
            onSortChange(object){
                if(object.prop && object.order){
                    this.$store.state.applications.search.default_sort = object;
                }else{
                    this.$store.state.applications.search.default_sort = null;
                }
                this.fetch(1);
            },
          fetch(page){
              let vm = this;
              vm.$store.state.applications.search.results = [];
              vm.$store.state.applications.search.show = false;
              vm.$store.state.applications.search.loading = true;
              vm.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/applications', {
                  params: Object.assign( this.$store.getters['applications/getFormObject'], {
                      page: page || 1,
                      rpp: vm.$store.state.applications.search.rpp,
                      sort: vm.$store.state.applications.search.default_sort && vm.$store.state.applications.search.default_sort.prop ? vm.$store.state.applications.search.default_sort.prop : null,
                      order: vm.$store.state.applications.search.default_sort && vm.$store.state.applications.search.default_sort.order ? vm.$store.state.applications.search.default_sort.order === 'descending' ? 'desc' : 'asc' : null,
                  })
              }).then((response) => {
                  vm.$store.state.applications.search.results = response.data.results;
                  vm.$store.state.applications.search.meta = response.data.meta;
                  vm.$store.state.applications.search.loading = false;
              }).catch(() => {
                  vm.$store.state.applications.search.loading = false;
              })
          }
        }
    }
</script>
<style lang="scss">
    .el--table {
        tr td {
            vertical-align: top !important;
        }
    }
</style>