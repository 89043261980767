<template>
    <div class="columns">
                <div class="column w-full">
                    <dashboard-widget title="Search Property Sales">
                        <slot>

                            <div class="p-4">
                                <div class="columns">
                                    <div class="column w-full lg:w-1/2">
                                        <h3 class="text-md uppercase mb-2 font-medium text-gray-500 uppercase">
                                            Type
                                        </h3>


                                        <el-form label-position="left" label-width="220px" :model="$store.state.propertyPrices.search.form">


                                            <el-form-item label="Tenure">

                                                <el-select
                                                        class="w-full"
                                                        filterable
                                                        placeholder="Select Local Authority..."
                                                        v-model="$store.state.propertyPrices.search.form.tenure"
                                                >
                                                    <el-option
                                                            :key="k"
                                                            :value="i.value"
                                                            :label="i.label"
                                                            v-for="(i, k) of options.tenure"></el-option>
                                                </el-select>
                                                
                                            </el-form-item>


                                            <el-form-item label="Property Type">

                                                <el-select
                                                        class="w-full"
                                                        filterable
                                                        placeholder="Select Local Authority..."
                                                        v-model="$store.state.propertyPrices.search.form.type"
                                                >
                                                    <el-option
                                                            :key="k"
                                                            :value="i.value"
                                                            :label="i.label"
                                                            v-for="(i, k) of options.type"></el-option>
                                                </el-select>

                                            </el-form-item>

                                            <el-form-item label="New Build">
                                                <el-select
                                                        class="w-full"
                                                        filterable
                                                        placeholder="Select Local Authority..."
                                                        v-model="$store.state.propertyPrices.search.form.build"
                                                >
                                                    <el-option
                                                            :key="k"
                                                            :value="i.value"
                                                            :label="i.label"
                                                            v-for="(i, k) of options.build"></el-option>
                                                </el-select>
                                            </el-form-item>


                                        </el-form>

                                        <h3 class="text-md uppercase mb-2 font-medium text-gray-500 uppercase">
                                            Price Paid
                                        </h3>


                                        <el-form label-position="left" label-width="220px" :model="$store.state.propertyPrices.search.form">
                                            <el-form-item label="Price min">
                                                <currency-input
                                                        class="el-input__inner"
                                                        placeholder="Price min"
                                                        v-model="$store.state.propertyPrices.search.form.price_min"
                                                        currency="GBP"
                                                />
                                            </el-form-item>
                                            <el-form-item label="Price max">
                                                <currency-input
                                                        class="el-input__inner"
                                                        placeholder="Price max"
                                                        v-model="$store.state.propertyPrices.search.form.price_max"
                                                        currency="GBP"
                                                />
                                            </el-form-item>
                                        </el-form>


                                        <h3 class="text-md uppercase mb-2 font-medium text-gray-500 uppercase">
                                            Transaction Date
                                        </h3>


                                        <el-form label-position="left" label-width="220px" :model="$store.state.propertyPrices.search.form">
                                            <el-form-item label="Transaction Date">
                                                <el-date-picker
                                                        v-model="$store.state.propertyPrices.search.form.transaction_date"                                                        :clearable="true"
                                                        :picker-options="pickerOptions"
                                                        type="daterange"
                                                        format="dd MMM yyyy"
                                                        range-separator="To"
                                                        start-placeholder="Start date"
                                                        end-placeholder="End date">
                                                </el-date-picker>
                                            </el-form-item>
                                        </el-form>

                                    </div>

                                    <div class="column w-full lg:w-1/2">
                                        <h3 class="text-md uppercase mb-2 font-medium text-gray-500 uppercase">
                                            Address
                                        </h3>

                                        <el-form label-position="left" label-width="220px" :model="$store.state.propertyPrices.search.form">

                                            <el-form-item label="Building Name/Street Number">
                                                <el-input

                                                        v-model="$store.state.propertyPrices.search.form.paon" clearable></el-input>
                                            </el-form-item>

                                            <el-form-item label="Secondary Address (e.g. floor)">
                                                <el-input

                                                        v-model="$store.state.propertyPrices.search.form.saon" clearable></el-input>
                                            </el-form-item>

                                            <el-form-item label="Town/City">
                                                <el-input

                                                        v-model="$store.state.propertyPrices.search.form.city" clearable></el-input>
                                            </el-form-item>

                                            <el-form-item label="Street">
                                                <el-input

                                                        v-model="$store.state.propertyPrices.search.form.street" clearable></el-input>
                                            </el-form-item>

                                            <el-form-item label="Locality">
                                                <el-input

                                                        v-model="$store.state.propertyPrices.search.form.locality" clearable></el-input>
                                            </el-form-item>


                                            <el-form-item label="District">
                                                <el-input

                                                        v-model="$store.state.propertyPrices.search.form.district" clearable></el-input>
                                            </el-form-item>

                                            <el-form-item label="County">
                                                <el-input

                                                        v-model="$store.state.propertyPrices.search.form.county" clearable></el-input>
                                            </el-form-item>

                                            <el-form-item label="Postcode">
                                                <el-input

                                                        v-model="$store.state.propertyPrices.search.form.postcode" clearable></el-input>
                                            </el-form-item>


                                        </el-form>

                                    </div>

                                    <div class="column w-full text-right">
                                        <el-button type="success"
                                                   @click="$emit('search')"
                                                   icon="el-icon-search">Search</el-button>
                                    </div>
                                </div>

                            </div>



                        </slot>
                    </dashboard-widget>
                </div>
            </div>
</template>

<script>


    import {getDefaultDateOptions} from './../utils/dates'

    export default {
        name: 'PropertySearchForm',
        data() {
            return {
                pickerOptions: getDefaultDateOptions(),
                loading: {
                    status: true,
                    type: true,
                    decision: true,
                    authority: true
                },
                options: {
                    tenure: [
                        {value: 'A', label: 'All'},
                        {value: 'F', label: 'Freehold'},
                        {value: 'L', label: 'Leasehold'},
                    ],
                    build: [
                        {value: 'A', label: 'Any'},
                        {value: 'Y', label: 'Yes'},
                        {value: 'N', label: 'No'},
                    ],
                    type: [
                        {value: 'A', label: 'All'},
                        {value: 'D', label: 'Detached'},
                        {value: 'S', label: 'Semi-detached'},
                        {value: 'T', label: 'Terraced'},
                        {value: 'F', label: 'Flats/Maisonettes'},
                        {value: 'O', label: 'Other'},
                    ]
                }
            }
        }
    }
</script>
<style>
    .is-full-width {
        width: 100% !important;
    }
    .el-range-editor.el-input__inner {
        width:  100% !important;
    }
</style>