<template>
    <div class="h-full bg-gray-200 overflow-scroll">
        <div class="bg-blue-900 p-6">
            <h4 class="font-semibold overflow-hidden text-white">
                Current Occupiers
            </h4>
            <p class="text-xs text-white">Find out the latest current occupiers from private/social rentals and owner
                occupied housing.</p>
        </div>
        <div class="bg-gray-200 p-3">
            <div class="columns">
                <div class="column w-full">
                    <dashboard-widget title="Search Current Occupiers">
                        <template v-slot:cta>
                            <export-button
                                    @export="(format) => exportData(format) "
                                    :exporting="$store.state.map.data_metrics.current_occupiers.fetching"
                                    :disabled="$store.state.map.data_metrics.current_occupiers.fetching"
                            />
                        </template>

                        <data-layer-filters
                                ref="dataLayerFilters"
                                :loading="$store.state.map.data_metrics.current_occupiers.fetching"
                                :filters="filters"
                                @change="()=> { fetchTable(1) } "
                        />

                        <div class="h-1 border-b mt-3 border-gray-200"></div>

                        <data-layer-table-wrapper
                                ref="tableWrapper"
                                :filters="filters"
                                @change="(page)=> {  fetchTable(page) }"
                                v-model="$store.state.map.data_metrics.current_occupiers">
                            <div
                                    :key="k"
                                    v-for="(i, k) of $store.state.map.data_metrics.current_occupiers.results"
                                    class="bg-white p-4 mb-6 border shadow border border-gray-200">

                                <div class="columns">
                                    <div class="column w-full">
                                        <h3 class="font-bold mt-1 leading-tight my-2 mt-6">{{ i.single_line_address
                                            }}</h3>
                                        <div class="text-xs">
                                            <span v-if="i.tenure">
                                            <span class="font-bold">Tenure:</span> {{ i.tenure }} <br/>
                                            </span>
                                            <span v-if="i.property_type">
                                                <span class="font-bold">Property Type:</span> {{ i.property_type }} <br/>
                                            </span>
                                            <span v-if="i.built_form">
                                                <span class="font-bold">Built Form:</span> {{ i.built_form }} <br/>
                                            </span>
                                            <span v-if="i.total_floor_area">
                                               <span class="font-bold">Total Floor Area:</span> {{ i.total_floor_area.toLocaleString() }} ft²<br/>
                                           </span>
                                        </div>
                                        <div class="flex justify-end" v-if="i.uprn">
                                            <el-button size="mini"
                                                       @click="$emit('uprn', i.uprn)"
                                                       type="success" icon="el-icon-view">View
                                            </el-button>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </data-layer-table-wrapper>


                    </dashboard-widget>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


    import DataLayerFilters from "../../DataLayerFilters";
    import DataLayerTableWrapper from "../../DataLayerTableWrapper";
    import ExportButton from "../../ExportButton";
    import {download} from "../../../utils/download";

    export default {
        name: "CurrentOccupierDataLayer",
        components: {ExportButton, DataLayerTableWrapper, DataLayerFilters},
        data() {
            return {
                can_export: false,
                filters: [
                    {
                        id: 'single_line_address',
                        label: 'Address',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'tenure',
                        label: 'Tenure',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'property_type',
                        label: 'Property Type',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'built_form',
                        label: 'Built Form',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },

                    {
                        type: "numeric",
                        id: "total_floor_area",
                        label: "Floor area (ft²)",
                        operators: ["=", "<", "<=", '>', ">="],
                        date: true
                    },


                ]
            }
        },
        created() {
            this.fetchTable(1);
            this.$store.commit('map/addLayerAvailability', 'occupiers');
            this.$store.commit('map/addLayerVisibility', 'occupiers');
        },
        beforeDestroy() {
            this.$store.commit('map/removeLayerAvailability', 'occupiers')
            this.$store.commit('map/removeLayerVisibility', 'occupiers')
        },

        watch: {
            '$store.state.map.bounds'() {
                const vm = this;
                setTimeout(() => {
                    vm.fetchTable(1);
                }, 500);
            }
        },
        methods: {
            exportData(type){
                const vm = this, object = this.buildFilters();
                vm.$store.state.map.data_metrics.current_occupiers.fetching = true;
                object.format = type;
                vm.$store.dispatch('map/searchCurrentOccupiers', object)
                    .then((response) => {
                        vm.$store.state.map.data_metrics.current_occupiers.fetching = false;
                        download(response.data.data);
                }).catch(() => {
                    vm.$store.state.map.data_metrics.current_occupiers.fetching = false;
                    })
            },
            buildFilters(page) {

                let vm = this,
                    filters = {},
                    object = {
                        rpp: 10,
                        page: page || 1
                    },
                    queryObject = this.$refs.dataLayerFilters ? this.$refs.dataLayerFilters.getFilters() : {};

                if (queryObject && queryObject.match && queryObject.rules) {
                    filters.match = queryObject.match;
                    filters.rules = queryObject.rules;
                }

                if (this.$refs.tableWrapper && this.$refs.tableWrapper.getSortField().length > 0) {
                    let ex = this.$refs.tableWrapper.getSortField().split('|');
                    console.log(ex);
                    if (ex.length === 2) {
                        object.sort = ex[0];
                        object.order = ex[1];
                    }
                }

                const bounds = this.$store.state.map.search_area === 'bounds' ? vm.$store.getters['map/getMapBoundsAsObject'] : {};

                object.search_area = this.$store.state.map.search_area;

                return Object.assign(object, filters, bounds);

            },

            fetchTable(page) {
                const vm = this, object = this.buildFilters(page)

                vm.$store.state.map.data_metrics.current_occupiers.fetching = true;
                vm.$store.dispatch('map/searchCurrentOccupiers', object).then((response) => {
                    vm.$store.state.map.data_metrics.current_occupiers.fetching = false;
                    vm.$store.state.map.data_metrics.current_occupiers.results = response.data.data.results;
                    vm.$store.state.map.data_metrics.current_occupiers.meta = response.data.data.meta;
                }).catch(() => {
                    vm.$store.state.map.data_metrics.current_occupiers.fetching = false;
                    vm.$store.state.map.data_metrics.current_occupiers.results = [];
                    vm.$store.state.map.data_metrics.current_occupiers.meta = {};
                })
            },
        }
    }
</script>