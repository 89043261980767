import Vue from 'vue'
import axios from './utils/api'

import App from './App.vue'
import './assets/tailwind.scss'
import './plugins/element.js'

import VueSocialSharing from 'vue-social-sharing'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import VueApexCharts from 'vue-apexcharts'
import Draggable from 'vuedraggable'
import * as VueGoogleMaps from "gmap-vue";

import VueCurrencyInput from 'vue-currency-input';

import VCluster from 'vue2-leaflet-markercluster'

import Avatar from 'vue-avatar';

import vueKanban from 'vue-kanban'

import Clipboard from 'v-clipboard'

import fileDownload from 'js-file-download';

import VueShepherd from 'vue-shepherd';

import LoadScript from 'vue-plugin-load-script';

import { StripeElementCard, StripePlugin } from '@vue-stripe/vue-stripe';

Vue.use(StripePlugin, {
    pk: 'pk_test_51IWfIiB3ODPpbJmcy9INTWxFuM345wRYcAjQZkZ8YiCJQQaFtxb2tm5B3WGoYdjc9NOSa8r9KNMrHfWQN243TLVI0062cmHna3'
});
Vue.use(VueShepherd);
Vue.use(vueKanban);
Vue.use(LoadScript);
Vue.use(Clipboard)
Vue.use(VueCurrencyInput);
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAENZy2SL14YFJdl4T9OiaPIgWgKejcCPg',
        libraries: 'places',
        v: "3.26"
    },
    installComponents: true
});

import 'leaflet/dist/leaflet.css';
import {
    LMap,
    LWMSTileLayer,
    LTooltip,
    LPopup,
    LTileLayer,
    LMarker,
    LCircle,
    LIcon,
    LGeoJson,
    LControlZoom,
    LPolyline
} from 'vue2-leaflet';
import LeafletHeatmap from './components/VueHeatmap'
import LeafletGoogleMutant from './components/Leaflet/GoogleMutant'

import store from './store'
import router from './router'
import DashboardWidget from "./components/incs/DashboardWidget";
import StatBox from "./components/incs/StatBox";
import Navigation from "./components/incs/Navigation";
import PageHeader from "./components/incs/PageHeader";
import ActionModal from "./components/incs/ActionModal";
import Page from "./Pages/Page";
import Loader from "./components/Loader";
import FloatingLabelInput from "./components/FloatingLabelInput";

import Affix from 'vue-affix';
import DateHumanView from "./components/DateHumanView";
import LProtobuf from './components/LProtobuf'
import LDrawToolbar from './components/Leaflet/Draw';

Vue.config.productionTip = false

Vue.use(VueSocialSharing);

Vue.use(require('vue-moment'));
Vue.use(Affix);

Vue.component('l-draw-toolbar', LDrawToolbar);
Vue.component('stripe-element-card', StripeElementCard);
Vue.component('floating-label-input', FloatingLabelInput);
Vue.component('draggable', Draggable);
Vue.component('date-human-view', DateHumanView);
Vue.component('avatar', Avatar);
Vue.component('v-cluster', VCluster);
Vue.component('v-map', VueGoogleMaps.Map);
Vue.component('action-modal', ActionModal);
Vue.component('v-map-street', VueGoogleMaps.StreetViewPanorama);
Vue.component('l-icon', LIcon);
Vue.component('l-heat-map', LeafletHeatmap);
Vue.component('l-protobuf', LProtobuf);
Vue.component('l-control-zoom', LControlZoom);
Vue.component('l-circle', LCircle);
Vue.component('l-map', LMap);
Vue.component('l-wms-tile-layer', LWMSTileLayer);
Vue.component('l-tooltip', LTooltip);
Vue.component('l-popup', LPopup);
Vue.component('l-geo-json', LGeoJson);
Vue.component('l-polyline', LPolyline);
Vue.component('l-google-tile-layer', LeafletGoogleMutant);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('dashboard-widget', DashboardWidget);
Vue.component('apexchart', VueApexCharts)
Vue.component('stat-box', StatBox);
Vue.component('loading-spinner', Loader);

Vue.component('navigation', Navigation);
Vue.component('page-header', PageHeader);
Vue.component('page', Page);

Vue.component('VueGoogleAutocomplete', VueGoogleAutocomplete);

Vue.prototype.$http = axios;
Vue.prototype.$download = fileDownload;
new Vue({
    store,
    router,
    watch:{
        '$route' (){
            this.$store.state.auth.showMenu = false;
        }
    },
    render: h => h(App)
}).$mount('#app')
