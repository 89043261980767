<template>
    <div>
        <div
                v-if="loading"
                class="flex h-full mt-12 pt-12 items-center justify-center">
            <div class="p-3 text-center">
                <property-loader />
                <h3 class="font-semibold text-blue-800">Fetching Crime Data...</h3>
            </div>
        </div>
        <div class="columns" v-if="!loading">

            <div
                    class="column w-full">
                <dashboard-widget
                        padding-class=""
                        title="Nearby Crime Data">
                    <slot>

                        <div class="p-2" v-if="hasCrimes">
                            <el-alert effect="dark" type="warning" :closable="false">
                                {{ crimes.length.toLocaleString() }} crimes have been committed within a 1 mile radius of this area in past 6 months
                            </el-alert>
                        </div>

                        <div v-if="hasCrimes">
                            <div class="p-2 pt-0">
                                <ownership-crime-map
                                        :crimes="crimes"
                                        :bbox="bbox"
                                />
                            </div>
                            <el-table
                                    :data="crimes"
                                    style="width: 100%">
                                <el-table-column
                                        prop="crime"
                                        class-name="font-semibold text-xs"
                                        label="Crime">
                                    <template slot-scope="scope">
                                        <div class="leading-snug">
                                            {{ scope.row.category_pretty }}<br/>
                                            <small>{{ scope.row.street }}</small>
                                        </div>

                                    </template>
                                </el-table-column>
                                <el-table-column
                                        width="90"
                                        align="right"
                                        class-name="text-xs"
                                        prop="Distance"
                                        label="Distance">
                                    <template slot-scope="scope">
                                        {{ scope.row.distance }} miles
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        width="80"
                                        class-name="text-xs"
                                        align="right"
                                        prop="date_pretty"
                                        label="Date">
                                </el-table-column>
                            </el-table>
                        </div>
                        <div v-else>
                            <empty-state />
                        </div>


                    </slot>
                </dashboard-widget>
            </div>
        </div>

    </div>
</template>

<script>

    import OwnershipCrimeMap from "./incs/OwnershipCrimeMap";
    import PropertyLoader from "../../incs/PropertyLoader";
    import EmptyState from "../../EmptyState";
    export default {
        name: "OwnershipCrimeComponent",
        components: {EmptyState, PropertyLoader, OwnershipCrimeMap},
        props: {
            id: {
                type: [Number, String],
                default(){
                    return null
                }
            },
            polygon: {
                type: Object,
                default(){
                    return null;
                }
            }
        },

        data() {
            return {
                loading: true,
                crime: {},
                map: {
                    center: [0, 0],
                    zoom: 3
                },
            }
        },
        mounted(){
            this.fetch();
        },
        methods: {
            fetch(){
                if(this.polygon !== null){
                    this.fetchCrimePolygon()
                }else{
                    this.fetchOwnership()
                }
            },
            fetchCrimePolygon(){
                const vm = this;
                vm.loading = true;
                this.$http.post('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/custom-draw', {
                    polygon: this.polygon,
                    view: 'crime'
                })
                    .then((response) => {
                        vm.loading = false;
                        vm.crime = response.data.data;
                    }).catch(() => {
                    vm.loading = false;
                    vm.crime = {};
                })
            },
            fetchOwnership() {
                const vm = this;
                vm.loading = true;
                this.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/ownership/' + this.id + '/crime')
                    .then((response) => {
                        vm.loading = false;
                        vm.crime = response.data.data;
                    }).catch(() => {
                    vm.loading = false;
                    vm.crime = {};
                })
            },
        },
        computed: {
            hasCrimes(){
                return this.crimes.length > 0;
            },
            crimes() {
                return this.crime && this.crime.crimes ? this.crime.crimes : []
            },
            bbox() {
                return this.crime && this.crime.bbox ? this.crime.bbox : []
            }
        }
    }
</script>