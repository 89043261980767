<template>
     <div>
         <empty-state class="p-3"
                      title="No results found"
                      message="You currently have no lookup history"
                      :support="false"
                      v-if="!table.loading && !table.results.length"/>


         <div class="p-3 py-12 text-center" v-if="table.loading">
             <property-loader />
         </div>


         <div v-if="table.results.length > 0 && !table.loading">
             <div class="py-3">
                 <el-alert effect="dark" type="success" :closable="false">
                     {{ table.meta.totalResult.toLocaleString() }} historical records found. Displaying page {{ table.meta.current }} of {{ table.meta.pageCount }}
                 </el-alert>
             </div>
             <div class="columns p-3">
                 <div class="column ml-auto">
                     <el-pagination
                             background
                             layout="prev, next"
                             @current-change="(page)=>{ fetch(page) }"
                             :page-size="table.rpp"
                             :current-page="table.meta.current"
                             :total="table.meta.totalResult">
                     </el-pagination>
                 </div>
             </div>
             <el-table :data="table.results">
                 <el-table-column width="130" label="Title Number">
                     <template slot-scope="scope">
                         <strong>{{ scope.row.title_number }}</strong>
                     </template>
                 </el-table-column>
                 <el-table-column label="Location">
                     <template slot-scope="scope">
                         {{ scope.row.location }}
                     </template>
                 </el-table-column>
                 <el-table-column width="160" label="Last Viewed">
                     <template slot-scope="scope">
                         <span class="text-xs">Visited {{ scope.row.created_at | moment("MMM Do YYYY") }}</span><br />
                         <date-human-view size="text-xs" v-model="scope.row.created_at" />
                     </template>
                 </el-table-column>

                 <el-table-column width="200" align="right">


                     <template slot-scope="scope">
                         <el-button size="small" type="info"  icon="el-icon-view" @click="$emit('view', scope.row.title_number)">View</el-button>
                         <el-button size="small" type="info" icon="el-icon-refresh" @click="$emit('share', scope.row.title_number)">Share</el-button>
                     </template>
                 </el-table-column>
             </el-table>
             <div class="columns p-3">
                 <div class="column ml-auto">
                     <el-pagination
                             background
                             layout="prev, next"
                             @current-change="(page)=>{ fetch(page) }"
                             :page-size="table.rpp"
                             :current-page="table.meta.current"
                             :total="table.meta.totalResult">
                     </el-pagination>
                 </div>
             </div>
         </div>
     </div>
</template>
<script>
    import PropertyLoader from "./incs/PropertyLoader";
    export default {
        name: 'LookupHistory',
        components: {PropertyLoader},
        data(){
            return {
                table: {
                    rpp: 5,
                    loading: true,
                    results: [

                    ],
                    meta: {}
                }
            }
        },
        created(){
            this.fetch(1);
        },
        methods: {
            fetch(page) {
                const vm = this;
                vm.table.loading = true;
                return vm.$store.dispatch('teamMember/lookupsIndex', {
                    teamId: vm.$store.state.auth.user.team.id,
                    params: {
                        page: page || 1,
                        rpp: vm.table.rpp
                    }
                }).then((response) => {
                    vm.table.loading = false;
                    vm.table.results = response.data.data.results;
                    vm.table.meta = response.data.data.meta;
                }).catch(() => {
                    vm.table.loading = false;
                    vm.table.results = [];
                    vm.table.meta = {};
                })

            }
        }
    }
</script>