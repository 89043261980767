<template>
    <dashboard-widget title="Please verify your email address"
                      description="Sorry about this, but we get a lot of robots!">

        <div class="columns">
            <div class="column w-full">
                <el-alert type="info" effect="dark" :closable="false">We have sent a code to the email address <span
                        class="font-semibold">{{ $store.state.auth.user.email }}</span>. Please enter the code below in
                    order to verify your email address.
                </el-alert>
            </div>
        </div>
        <div class="columns py-3">
            <div class="column w-auto flex-grow">
                <input
                        :disabled="isRunning"
                        v-model="code"
                        class="p-2 text-lg border border-gray-400 rounded w-full"
                         />
            </div>
            <div class="column flex-none flex items-center justify-center">
                <el-button
                        :disabled="isRunning"
                        :loading="verifying"
                        @click="verify"
                        icon="el-icon-refresh-right"
                        type="success">
                    Verify Email
                </el-button>
            </div>
            <div class="column w-1/2">
                <el-link @click="openProfile" :disabled="isRunning">Change email address?</el-link>
            </div>
            <div class="column w-1/2 text-right">
                <el-link
                        @click="resend"
                        :disabled="isRunning"
                >Resend verification code?</el-link>
            </div>
        </div>


    </dashboard-widget>
</template>
<script>


    export default {
        name: 'AccountEmailVerification',
        data() {
            return {
                profile: {},
                code: null,
                loaded: false,
                verifying: false,
                resending: false
            }
        },
        computed: {
            isRunning(){
                return this.resending || this.verifying;
            }
        },
        methods: {
            openProfile(){
                this.$store.state.auth.account.activeTab = 'profile';
                this.$store.state.auth.account.visible = true;
            },
            verify(){
                let vm = this;
                vm.verifying = true;

                return vm.$http.post('/api/v1/auth/email-verification', {
                    code: vm.code
                })
                    .then(() => {

                        vm.$http.get('/api/v1/auth/user')
                            .then((response)=>{
                                vm.$notify({
                                    type: 'success',
                                    title: 'Email verified',
                                    message: 'Thank you. Your email address has been verified!',
                                    position: 'top-right',
                                    offset: 140,
                                });
                                vm.$store.state.auth.user.email_verified = true;
                                vm.$store.commit('auth/setUser', response.data.data);
                                this.$router.push('/')
                            })
                            .catch(()=>{

                            })

                    }).catch(() => {
                        vm.verifying = false;
                        this.$notify({
                            type: 'warning',
                            title: 'Incorrect code',
                            message: 'The verification code entered is not correct.',
                            position: 'top-right',
                            offset: 140,
                        });
                    })

            },
            resend(){
                let vm = this;
                vm.resending = true;

                return vm.$http.put('/api/v1/auth/email-verification')
                    .then(() => {

                        vm.resending = false;
                        this.$notify({
                            type: 'success',
                            title: 'Verification code sent',
                            message: 'Please check your inbox (' + vm.$store.state.auth.user.email + '), we have emailed you a new verification code.',
                            position: 'top-right',
                            offset: 140,
                        });
                    }).catch((e) => {
                        vm.resending = false;
                        vm.$store.dispatch('auth/handleServerError', {
                            vm: vm,
                            error: e
                        });
                    })
            }
        },
    }
</script>