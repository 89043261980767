import api from "./../../utils/api";
import {getAddressBaseCategories} from "../../utils/ownership";

const moment = require('moment');
const humanNumber = require('human-number')

console.log('api', api);

let cancelTokenSource = null;

const filters = {
    show_leaseholders: true,
    show_listed_buildings: false,
    show_planning_applications: false,
    show_unregistered_land: true,
    buildings_footprint_percentage: [0, 100],
    greenbelt_coverage_percentage: [0, 100],
    aonb_coverage_percentage: [0, 100],
    buildings_count: [0, 100],
    ownership: 'any',
    buildings_footprint: [0, 20000],
    area: [0, 40],
    radius: 250,
    use_types: [],
    epc_ratings: [],
    sales_types: [],
    sales_tenures: [],
    sales_price_paid: [0, 40000000],
    sales_build_type: 'any',
    sales_date_sold: null,
    environmental_must_include: [],
    environmental_must_not_include: [],
    exclude_listed_buildings: false,
};

const initialState = {
    fetching: true,
    loading_polygons: false,
    show_freeholder_icons: false,
    active_filter_category: '',
    polygon_filters: JSON.parse(JSON.stringify(filters)),
    active_overlays: [],
    visible_overlays: [],
    os_types: [
        {
            "code": 1,
            "name": "Multi Surface",
            "color": "#eeefda"
        },
        {
            "code": 2,
            "name": "Archway",
            "color": "#dcd7c6"
        },
        {
            "code": 3,
            "name": "Road Bridge",
            "color": "#e6dddd"
        },
        {
            "code": 4,
            "name": "Rail Bridge",
            "color": "#fcfdff"
        },
        {
            "code": 5,
            "name": "Bridge",
            "color": "#d6d2d2"
        },
        {
            "code": 6,
            "name": "Level Crossing",
            "color": "#fcfdff"
        },
        {
            "code": 7,
            "name": "Traffic Calming",
            "color": "#fcfdff"
        },
        {
            "code": 8,
            "name": "Pylon",
            "color": "#eee8d3"
        },
        {
            "code": 9,
            "name": "Track",
            "color": "#dcdcdb"
        },
        {
            "code": 10,
            "name": "Step",
            "color": "#dcdcdb"
        },
        {
            "code": 11,
            "name": "Canal",
            "color": "#aadeef"
        },
        {
            "code": 12,
            "name": "Footbridge",
            "color": "#e8cfcc"
        },
        {
            "code": 13,
            "name": "Mixed Woodland",
            "color": "#cee6bd"
        },
        {
            "code": 14,
            "name": "Nonconiferous Tree",
            "color": "#cee6bd"
        },
        {
            "code": 15,
            "name": "Coniferous Tree",
            "color": "#cee6bd"
        },
        {
            "code": 16,
            "name": "Agricultural Land",
            "color": "#d6edcf"
        },
        {
            "code": 17,
            "name": "Orchard",
            "color": "#cee6bd"
        },
        {
            "code": 18,
            "name": "Coppice Or Osiers",
            "color": "#cee6bd"
        },
        {
            "code": 19,
            "name": "Scrub",
            "color": "#e2efce"
        },
        {
            "code": 20,
            "name": "Boulders",
            "color": "#eaeae4"
        },
        {
            "code": 21,
            "name": "Rock",
            "color": "#eaeae4"
        },
        {
            "code": 22,
            "name": "Scree",
            "color": "#eaeae4"
        },
        {
            "code": 23,
            "name": "Rough Grassland",
            "color": "#e2efce"
        },
        {
            "code": 24,
            "name": "Heath",
            "color": "#e2efce"
        },
        {
            "code": 25,
            "name": "Saltmarsh",
            "color": "#e4f3f4"
        },
        {
            "code": 26,
            "name": "Sand",
            "color": "#f4f0d3"
        },
        {
            "code": 27,
            "name": "Mud",
            "color": "#e8e4dd"
        },
        {
            "code": 28,
            "name": "Shingle",
            "color": "#eaeae4"
        },
        {
            "code": 29,
            "name": "Marsh",
            "color": "#e4f3f4"
        },
        {
            "code": 30,
            "name": "Reeds",
            "color": "#aadeef"
        },
        {
            "code": 31,
            "name": "Foreshore",
            "color": "#eaead3"
        },
        {
            "code": 32,
            "name": "Slope",
            "color": "#669966"
        },
        {
            "code": 33,
            "name": "Cliff",
            "color": "#666666"
        },
        {
            "code": 34,
            "name": "Building",
            "color": "#dcd7c6"
        },
        {
            "code": 35,
            "name": "Natural",
            "color": "#e4efda"
        },
        {
            "code": 36,
            "name": "Manmade",
            "color": "#f2f2e9"
        },
        {
            "code": 37,
            "name": "Road Or Track",
            "color": "#fcfdff"
        },
        {
            "code": 38,
            "name": "Roadside Natural",
            "color": "#dde6d5"
        },
        {
            "code": 39,
            "name": "Roadside Manmade",
            "color": "#f2f2e9"
        },
        {
            "code": 40,
            "name": "Inland Water",
            "color": "#aadeef"
        },
        {
            "code": 41,
            "name": "Path",
            "color": "#dcdcdb"
        },
        {
            "code": 42,
            "name": "Rail Manmade",
            "color": "#cccbcb"
        },
        {
            "code": 43,
            "name": "Rail Natural",
            "color": "#dce5d3"
        },
        {
            "code": 44,
            "name": "Structure",
            "color": "#e7c9c8"
        },
        {
            "code": 45,
            "name": "Glasshouse",
            "color": "#f3f9f4"
        },
        {
            "code": 46,
            "name": "Landform Natural",
            "color": "#e4efda"
        },
        {
            "code": 47,
            "name": "Tidal Water",
            "color": "#aadeef"
        },
        {
            "code": 48,
            "name": "Landform Manmade",
            "color": "#f2f2e9"
        },
        {
            "code": 99,
            "name": "Unclassified",
            "color": "#f8f6f0"
        }
    ],
    exclude_listed_buildings: [
        {
            value: false,
            label: "No"
        },
        {
            value: true,
            label: "Yes"
        }
    ],
    use_types: getAddressBaseCategories(),
    epc_ratings: ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
    sales_tenures: [{value: 'F', label: 'Freehold'}, {value: 'L', label: 'Leasehold'}, {
        value: 'U',
        label: 'Unclassified'
    }],
    sales_build_type: [{value: 'any', label: 'Any'}, {value: 'new', label: 'Only New Builds'}],
    sales_types: [
        {value: 'D', label: 'Detached'},
        {value: 'S', label: 'Semi-detached'},
        {value: 'T', label: 'Terraced'},
        {value: 'F', label: 'Flat/Apartment/Maisonette'},
        {value: 'O', label: 'Other'}],

    ownership_types: [{value: 'any', label: 'Any'}, {value: 'corporate', label: 'Company Owned'}, {
        value: 'private',
        label: 'Privately Owned'
    }]
};

export default {
    namespaced: true,
    state: initialState,
    mutations: {
        reset(state) {
            Object.keys(initialState).forEach(key => {
                state[key] = initialState[key]
            })
        },
        resetFilter: (state) => {
            state.polygon_filters = JSON.parse(JSON.stringify(filters));
        }
    },
    getters: {
        isVisibleOverlay: (state) => (overlay) => {
            const active = state.active_overlays.indexOf(overlay) > -1;
            return active ? state.visible_overlays.indexOf(overlay) > -1 : false;
        },
        hasActiveOverlay: (state) => (overlay) => {
            return state.active_overlays.indexOf(overlay) > -1;
        },
        getEnvironmentalFilterCount: (state, getters) => {

            let total = 0,
                params = getters.searchParams;
            total += params.environmental_must_include ? params.environmental_must_include.length : 0;
            total += params.environmental_must_not_include ? params.environmental_must_not_include.length : 0;

            return total;
        },

        getUseTypeFilterCount: (state, getters) => {
            let total = 0,
                params = getters.searchParams;

            total += params.epc_ratings ? params.epc_ratings.length : 0;
            total += params.use_types ? params.use_types.length : 0;

            return total;
        },

        getBuildingsFilterCount: (state, getters) => {
            let total = 0,
                params = getters.searchParams;


            if (params.min_buildings_footprint_percentage || params.max_buildings_footprint_percentage) {
                total++;
            }
            if (params.min_buildings_count || params.max_buildings_count) {
                total++;
            }
            if (params.exclude_listed_buildings && params.exclude_listed_buildings === true) {
                total++;
            }

            return total;
        },

        getPropertySalesFilterCount: (state, getters) => {
            let total = 0,
                params = getters.searchParams;

            if (params.sales_last_sold_from || params.sales_last_sold_to) {
                total++;
            }
            if (params.sale_price_min || params.sale_price_max) {
                total++;
            }


            total += params.sales_tenure ? params.sales_tenure.length : 0;
            total += params.sales_types ? params.sales_types.length : 0;

            if (params.sales_build_type !== 'any') {
                total++;
            }

            return total;
        },

        getFiltersCount: (state, getters) => {

            let params = getters.searchParams;

            let total = 0;
            if (params.ownership !== 'any') {
                total++;
            }

            if (params.radius !== 250) {
                total++;
            }

            if (params.area_min || params.area_max) {
                total++;
            }
            if (params.min_buildings_footprint || params.max_buildings_footprint) {
                total++;
            }


            total += getters.getEnvironmentalFilterCount;
            total += getters.getUseTypeFilterCount;
            total += getters.getPropertySalesFilterCount;
            total += getters.getBuildingsFilterCount;

            return total;
        },

        getSoldPricesDateRange: () => {
            return {
                disabledDate(time) {
                    return time.getTime() > moment().subtract(1, 'month') || time.getTime() < moment('1995-01-01')
                }
            }
        },
        getEPCSelectionCount: (state) => {
            return state.polygon_filters.epc_ratings && state.polygon_filters.epc_ratings.length ? state.polygon_filters.epc_ratings.length : 0
        },
        getUseTypeCount: (state) => {
            return state.polygon_filters.use_types && state.polygon_filters.use_types.length ? state.polygon_filters.use_types.length : 0
        },
        getSalePropertyTypesCount: (state) => {
            return state.polygon_filters.sales_types && state.polygon_filters.sales_types.length ? state.polygon_filters.sales_types.length : 0
        },
        getSalesTenureCount: (state) => {
            return state.polygon_filters.sales_tenures && state.polygon_filters.sales_tenures.length ? state.polygon_filters.sales_tenures.length : 0
        },
        getSalePriceRange: (state, getters) => {
            let data = getters.searchParams;
            let min = data.sale_price_min ? data.sale_price_min : '0';
            let max = data.sale_price_max ? data.sale_price_max : '£40m +';
            return (typeof min === 'string' ? min : '£' + humanNumber(min)) + ' - ' + (typeof max === 'string' ? max : '£' + humanNumber(max))
        },
        getSalePriceDateRange: (state, getters) => {
            let data = getters.searchParams, format = 'MMM YYYY';
            let min = data.sales_last_sold_from ? moment(data.sales_last_sold_from).format(format) : 'Jan 95';
            let max = data.sales_last_sold_to ? moment(data.sales_last_sold_to).format(format) : moment().subtract('1', 'month').format(format);
            return min + ' - ' + max;
        },
        getPropertySizeBreakdown: (state, getters) => {
            let data = getters.searchParams;
            let min = data.area_min ? data.area_min : '0';
            let max = data.area_max ? data.area_max : '40+';
            return min + ' - ' + max + ' acres';
        },
        getBuildingsFootprintPercentage: (state, getters) => {
            let data = getters.searchParams;
            let min = data.min_buildings_footprint_percentage ? data.min_buildings_footprint_percentage : '0';
            let max = data.max_buildings_footprint_percentage ? data.max_buildings_footprint_percentage : '100';
            return min + ' - ' + max + '%';
        },
        getBuildingsCount: (state, getters) => {
            let data = getters.searchParams;
            let min = data.min_buildings_count ? data.min_buildings_count : '0';
            let max = data.max_buildings_count ? data.max_buildings_count : '100+';
            return min + ' - ' + max;
        },


        getBuildingFootprintBreakdown: (state, getters) => {
            let data = getters.searchParams;
            let min = data.min_buildings_footprint ? data.min_buildings_footprint.toLocaleString() : '0';
            let max = data.max_buildings_footprint ? data.max_buildings_footprint.toLocaleString() : (20000).toLocaleString() + '+';
            return min + ' - ' + max + ' sqft';
        },
        getEnvMustInclude: (state, getters) => {
            let data = getters.searchParams;
            return data.environmental_must_include ? data.environmental_must_include.length : 0;
        },
        getEnvMustNotInclude: (state, getters) => {
            let data = getters.searchParams;
            return data.environmental_must_not_include ? data.environmental_must_not_include.length : 0;
        },
        dateSoldRangeBreakdown: (state, getters) => {
            console.log('getters', getters)
            return getters.searchParams;
        },
        searchParams: (state) => {
            let object = {
                show_leaseholders: state.polygon_filters.show_leaseholders,
                show_listed_buildings: state.polygon_filters.show_listed_buildings,
                show_planning_applications: state.polygon_filters.show_planning_applications,
                radius: state.polygon_filters.radius,
                ownership: state.polygon_filters.ownership,

                sales_types: state.polygon_filters.sales_types.length > 0 ? state.polygon_filters.sales_types : [],
                sales_tenure: state.polygon_filters.sales_tenures.length > 0 ? state.polygon_filters.sales_tenures : [],
                sales_build_type: state.polygon_filters.sales_build_type,
                use_types: state.polygon_filters.use_types.length > 0 ? state.polygon_filters.use_types : [],
                epc_ratings: state.polygon_filters.epc_ratings && state.polygon_filters.epc_ratings.length > 0 ? state.polygon_filters.epc_ratings : [],
                environmental_must_include: state.polygon_filters.environmental_must_include && state.polygon_filters.environmental_must_include.length > 0 ? state.polygon_filters.environmental_must_include : [],
                environmental_must_not_include: state.polygon_filters.environmental_must_not_include && state.polygon_filters.environmental_must_not_include.length > 0 ? state.polygon_filters.environmental_must_not_include : [],
                exclude_listed_buildings: state.polygon_filters.exclude_listed_buildings,
            };

            if (
                state.polygon_filters.buildings_footprint_percentage &&
                Array.isArray(state.polygon_filters.buildings_footprint_percentage) &&
                state.polygon_filters.buildings_footprint_percentage.length === 2
            ) {
                let min = state.polygon_filters.buildings_footprint_percentage[0],
                    max = state.polygon_filters.buildings_footprint_percentage[1];

                if (min !== 0) {
                    object.min_buildings_footprint_percentage = min;
                }
                if (max !== 100) {
                    object.max_buildings_footprint_percentage = max;
                }
            }


            if (
                state.polygon_filters.buildings_footprint &&
                Array.isArray(state.polygon_filters.buildings_footprint) &&
                state.polygon_filters.buildings_footprint.length === 2
            ) {
                let min = state.polygon_filters.buildings_footprint[0],
                    max = state.polygon_filters.buildings_footprint[1];

                if (min !== 0) {
                    object.min_buildings_footprint = min;
                }
                if (max !== 20000) {
                    object.max_buildings_footprint = max;
                }
            }


            if (
                state.polygon_filters.buildings_count &&
                Array.isArray(state.polygon_filters.buildings_count) &&
                state.polygon_filters.buildings_count.length === 2
            ) {

                let min = state.polygon_filters.buildings_count[0],
                    max = state.polygon_filters.buildings_count[1];

                if (min !== 0) {
                    object.min_buildings_count = min;
                }
                if (max !== 100) {
                    object.max_buildings_count = max;
                }
            }

            if (
                state.polygon_filters.area &&
                Array.isArray(state.polygon_filters.area) &&
                state.polygon_filters.area.length === 2
            ) {

                let min = state.polygon_filters.area[0],
                    max = state.polygon_filters.area[1];

                if (min !== 0) {
                    object.area_min = min;
                }
                if (max !== 40) {
                    object.area_max = max;
                }
            }

            if (
                state.polygon_filters.sales_price_paid &&
                Array.isArray(state.polygon_filters.sales_price_paid) &&
                state.polygon_filters.sales_price_paid.length === 2
            ) {

                let min = state.polygon_filters.sales_price_paid[0],
                    max = state.polygon_filters.sales_price_paid[1];

                if (min !== 0) {
                    object.sale_price_min = min;
                }
                if (max !== 40000000) {
                    object.sale_price_max = max;
                }
            }


            if (
                state.polygon_filters.sales_date_sold &&
                Array.isArray(state.polygon_filters.sales_date_sold) &&
                state.polygon_filters.sales_date_sold.length === 2
            ) {
                object.sales_last_sold_from = moment(state.polygon_filters.sales_date_sold[0]).format('YYYY-MM-DD')
                object.sales_last_sold_to = moment(state.polygon_filters.sales_date_sold[1]).format('YYYY-MM-DD')
            }

            return object;
        },
    },
    actions: {

        fetchPolygons: ({rootState, state, getters}, opts) => {


            state.loading_polygons = true;
            state.show_freeholder_icons = false;

            opts = opts || {};
            try {
                cancelTokenSource.cancel();
            } catch (e) {
                console.warn(e);
            }


            cancelTokenSource = api.CancelToken.source();
            return api.get('/api/v1/team/' + rootState.auth.user.team.id + '/ownership', {
                cancelToken: cancelTokenSource.token,
                params: Object.assign(opts, getters.searchParams)
            });
        },
        fetch: ({rootState, state}, inspireId) => {

            try {
                cancelTokenSource.cancel();
            } catch (e) {
                console.warn(e);
            }

            console.log('Fetching...', inspireId);

            state.fetching = true;

            cancelTokenSource = api.CancelToken.source();

            return api.get('/api/v1/team/' + rootState.auth.user.team.id + '/ownership/' + inspireId, {
                cancelToken: cancelTokenSource.token
            }).then((response) => {
                state.fetching = false;
                return response.data.data;
            }).catch(() => {
                state.fetching = false;
                return {}
            });

        },
    }
}