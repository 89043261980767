<template>
       <el-tabs v-model="$store.state.team.activeTab">
           <el-tab-pane label="My Teams"  class="py-6" name="invites">
               <my-teams-component />
           </el-tab-pane>
           <el-tab-pane label="Create a Team" class="py-6" name="security">
               <create-team-component />
           </el-tab-pane>
       </el-tabs>
</template>
<script>

    import MyTeamsComponent from "./MyTeamsComponent";
    import CreateTeamComponent from "./CreateTeamComponent";
    export default {
        name: 'Team',
        components: {CreateTeamComponent, MyTeamsComponent},
    }
</script>