<template>
    <div>


        <el-table
                v-if="value.length > 0 && !loading"
                :data="getData"
                narrow
                @sort-change="onSortChange"
                :default-sort="default_sort"
                style="width: 100%">


            <el-table-column type="expand" v-if="showExpander">
                <template slot-scope="props">

                    <market-tracker-rental-yield-nested
                        v-model="props.row.label"
                        :avg="avg"
                        :avg-detached="avgDetached"
                        :avg-semi="avgSemi"
                        :avg-bungalow="avgBungalow"
                        :avg-flat="avgFlat"
                    />

                </template>
            </el-table-column>

            <el-table-column
                    prop="label"
                    sortable="custom"
                    :label="labelText">
                <template slot-scope="scope">
                    <h4 class="font-semibold">{{ scope.row.label }}</h4>
                </template>
            </el-table-column>

            <el-table-column
                    width="120px"
                    prop="rental_yield"
                    sortable="custom"
                    align="right"
                    label="Average">
                <template slot-scope="scope">

                    <market-tracker-nested-value
                    v-model="scope.row.rental_yield"
                    :avg="avg"
                    show-as="percentage"
                    />


                </template>
            </el-table-column>


            <el-table-column
                    width="120px"
                    prop="rental_yield_detached"
                    sortable="custom"
                    align="right"
                    label="Detached">
                <template slot-scope="scope">
                    <market-tracker-nested-value
                            v-model="scope.row.rental_yield_detached"
                            :avg="avgDetached"
                            show-as="percentage"
                    />
                </template>
            </el-table-column>

            <el-table-column
                    width="120px"
                    prop="rental_yield_semi_detached"
                    sortable="custom"
                    align="right"
                    label="Semi">
                <template slot-scope="scope">
                    <market-tracker-nested-value
                            v-model="scope.row.rental_yield_semi_detached"
                            :avg="avgSemi"
                            show-as="percentage"
                    />
                </template>
            </el-table-column>

            <el-table-column
                    width="120px"
                    prop="rental_yield_bungalows"
                    sortable="custom"
                    align="right"
                    label="Bungalow">
                <template slot-scope="scope">
                    <market-tracker-nested-value
                            v-model="scope.row.rental_yield_bungalows"
                            :avg="avgBungalow"
                            show-as="percentage"
                    />
                </template>
            </el-table-column>

            <el-table-column
                    width="180px"
                    prop="rental_yield_flats"
                    sortable="custom"
                    align="right"
                    label="Flats/Apartments">
                <template slot-scope="scope">
                    <market-tracker-nested-value
                            v-model="scope.row.rental_yield_flats"
                            :avg="avgFlat"
                            show-as="percentage"
                    />
                </template>
            </el-table-column>

            <el-table-column
                    align="right"
                    width="120px"
            >
                <template slot-scope="scope">
                <el-button
                        size="small"
                        type="info"
                        icon="el-icon-view"
                        @click="openLink(scope.row.label)"
                        class="info">View</el-button>
                </template>
            </el-table-column>


        </el-table>

    </div>
</template>

<script>
    import MarketTrackerNestedValue from "../MarketTrackerNestedValue";
    import MarketTrackerRentalYieldNested from "./MarketTrackerRentalYieldNested";
    export default {
        name: "MarketTrackerRentalYieldTable",
        components: {MarketTrackerRentalYieldNested, MarketTrackerNestedValue},
        props: {
            avg: {
                type: Number,
                default(){
                    return 0
                }
            },
            avgSemi: {
                type: Number,
                default(){
                    return 0
                }
            },
            avgDetached: {
                type: Number,
                default(){
                    return 0
                }
            },
            avgBungalow: {
                type: Number,
                default(){
                    return 0
                }
            },
            avgFlat: {
                type: Number,
                default(){
                    return 0
                }
            },
            value: {
                type: Array,
                default(){
                    return [];
                }
            },
            showExpander: {
                type: Boolean,
                default(){
                    return false;
                }
            },
            labelText: {
              type: String,
              default(){
                  return 'Postcode Area';
              }
            },
            loading: {
                type: Boolean,
                default(){
                    return false;
                }
            }
        },
        data() {
            return {
                default_sort: {
                    prop: 'rent_avg',
                    order: 'ascending'
                },
                col: 'rent_avg'
            }
        },
        computed: {
            getData(){

                if(!this.value.length){
                    return [];
                }

                if(!this.col){
                    return this.value;
                }

                let col = this.default_sort.prop, value = this.value;
                if(this.default_sort.order === 'descending'){
                    return value.sort((a,b) => (a[col] > b[col]) ? 1 : ((b[col] > a[col]) ? -1 : 0))
                }
                if(this.default_sort.order === 'ascending'){
                    return value.sort((a,b) => (a[col] < b[col]) ? 1 : ((b[col] < a[col]) ? -1 : 0))
                }
                return value;
            }
        },
        methods: {
            toPrice(value){
                return value ? "£" + Math.floor(value).toLocaleString() : 'N/A';
            },
            openLink(code){
                window.open('https://www.rightmove.co.uk/property-for-sale/' + code + '.html')
            },
            onSortChange(col){
                this.default_sort = {
                    prop: col.column.property,
                    order: col.column.order
                }
            }
        }
    }
</script>

<style scoped>

</style>