<template>
    <lottie :options="{animationData: animationData}" :height="height"  />
</template>

<script>
    import Lottie from "vue-lottie";
    import * as animationData from "./../components/incs/Animations/construction";

    export default {
        name: "Missing",
        props: {
          height: {
              type: Number,
              default(){
                  return 250;
              }
          }
        },
        components: {
            Lottie
        },
        data(){
            return {
                animationData: animationData.default
            }
        }
    }
</script>

<style scoped>

</style>