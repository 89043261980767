<template>

    <page>

        <template slot="header">
            <page-header title="Commercial Availability">
                <template v-slot:tabs>
                    <market-tracker-nav />
                </template>
            </page-header>
        </template>



        <template slot="content">

            <div class="columns">
                <div class="column w-full" v-if="show">
                    <commercial-availability-search-form
                            @search="()=>{ fetch(1) }"
                    />
                </div>
                <div class="column w-full" v-if="!show">

                    <dashboard-widget
                            title="Commercial Availability"
                            padding-class="">

                        <template slot="cta">
                           <div class="columns">
                               <div class="column">
                                   <el-button
                                           size="medium"
                                           @click="showSearch"
                                           :disabled="loading"
                                           type="info" icon="el-icon-search">Search</el-button>
                               </div>
                               <div class="column">
                                   <export-button
                                   :disabled="loading"
                                   />
                               </div>
                           </div>
                        </template>


                        <div class="h-32 w-full"
                             v-if="loading"
                             v-loading="true"></div>

                        <empty-state class="p-3"
                                     title="No properties found"
                                     message="Sorry, but we could not find any properties matching your search"
                                     :support="false"
                                     v-if="!loading && !results.length" />

                        <div class="columns p-4"
                             v-if="meta.totalResult && !loading"
                        >
                            <div class="column flex items-center">
                                <span class="text-sm">
                                  {{ meta.totalResult.toLocaleString() }} properties found. Displaying page {{ meta.current }} of {{ meta.pageCount }}
                                </span>
                            </div>
                            <div class="column ml-auto">
                                <div class="columns">
                                    <div class="column flex items-center">
                                        <span class="text-gray-600 uppercase text-xs">per page</span>
                                    </div>
                                    <div class="column">
                                        <el-select
                                                class="w-32"
                                                v-model="rpp" @change="fetch(1)">
                                            <el-option :value="10">10</el-option>
                                            <el-option :value="20">20</el-option>
                                            <el-option :value="30">30</el-option>
                                            <el-option :value="40">40</el-option>
                                            <el-option :value="50">50</el-option>
                                            <el-option :value="100">100</el-option>
                                        </el-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <el-table
                                v-if="meta.totalResult && !loading"
                                :data="results"
                                :default-sort="default_sort"
                                @sort-change="onSortPropertyChange"
                                style="width: 100%">
                            <el-table-column
                                    prop="address.keyword"
                                    sortable="custom"
                                    class-name="text-xs"
                                    label="Address">
                                <template slot-scope="scope">

                                    <div class="flex justify-center">
                                        <div class="p-1">
                                            <div class="w-20">
                                                <el-image
                                                        :src="scope.row.image"
                                                ></el-image>
                                            </div>
                                        </div>
                                        <div class="p-1 w-64 text-xs font-bold">
                                            {{ scope.row.address }}

                                        </div>
                                    </div>

                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="property_sub_type.keyword"
                                    width="200px"
                                    sortable="custom"
                                    class-name="text-xs"
                                    label="Type">
                                <template slot-scope="scope">
                                    {{ scope.row.type }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="channel.keyword"
                                    sortable="custom"
                                    width="120px"
                                    class-name="text-xs"
                                    label="Channel">
                                <template slot-scope="scope">
                                    {{ scope.row.channel }}
                                </template>
                            </el-table-column>
                            <!---
                            <el-table-column
                                    prop="auction_only"
                                    width="120px"
                                    sortable="custom"
                                    align="center"
                                    class-name="text-xs"
                                    label="Auction">
                                <template slot-scope="scope">
                                    {{ scope.row.auction_only ? 'Yes' : 'No' }}

                                </template>
                            </el-table-column>
                            --->


                            <el-table-column
                                    width="120px"
                                    prop="added"
                                    sortable="custom"
                                    align="right"
                                    class-name="text-xs"
                                    label="Date">
                                <template slot-scope="scope">
                                    <date-human-view v-model="scope.row.date_added" />
                                </template>
                            </el-table-column>

                            <el-table-column
                                    width="100px"
                                    prop="price"
                                    sortable="custom"
                                    align="right"
                                    class-name="text-xs"
                                    label="Price">
                                <template slot-scope="scope">
                                    {{ scope.row.price ? '£' + scope.row.price.toLocaleString() : 'N/A' }}
                                </template>
                            </el-table-column>

                            <el-table-column
                                    width="120px"
                                    prop="max_size_ft"
                                    sortable="custom"
                                    align="right"
                                    class-name="text-xs"
                                    label="Size (ft²)">
                                <template slot-scope="scope">
                                    {{ scope.row.max_size_ft ? scope.row.max_size_ft.toLocaleString() + 'ft²' : 'N/A' }}
                                </template>
                            </el-table-column>


                            <el-table-column
                                    v-if="showDistance"
                                    width="110px"
                                    prop="distance_km"
                                    sortable="custom"
                                    align="right"
                                    class-name="text-xs"
                                    label="Distance">
                                <template slot-scope="scope">
                                    {{ scope.row.distance }}
                                </template>
                            </el-table-column>


                            <el-table-column
                                    width="210px"
                                    align="right">

                                <template slot-scope="scope">
                                <el-dropdown @command="(cmd)=>{ handleCommand(cmd, scope.row)  }">
                                    <el-button size="medium" type="info">
                                        Actions <i class="el-icon-arrow-down el-icon--right"></i>
                                    </el-button>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item command="view">
                                            View Details
                                        </el-dropdown-item>
                                        <el-dropdown-item
                                                :disabled="!scope.row.coords"
                                                command="lm">
                                            View on Land Monitor
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                                </template>
                            </el-table-column>


                        </el-table>

                        <div class="columns p-4"
                             v-if="meta.totalResult && !loading"
                        >
                            <div class="column flex items-center">
                                <span class="text-sm">
                                  {{ meta.totalResult.toLocaleString() }} properties found. Displaying page {{ meta.current }} of {{ meta.pageCount }}
                                </span>
                            </div>
                            <div class="column ml-auto">
                                <el-pagination
                                        background
                                        layout="prev, pager, next"
                                        @current-change="(page)=>{ fetch(page) }"
                                            :page-size="rpp"
                                        :current-page="meta.current"
                                        :total="meta.totalResult">
                                </el-pagination>

                            </div>
                        </div>


                    </dashboard-widget>

                </div>
            </div>

        </template>`

    </page>


</template>
<script>
    import EmptyState from "../../../components/EmptyState";
    import MarketTrackerNav from "../MarketTrackerNav";
    import CommercialAvailabilitySearchForm from "../../../components/CommercialAvailabilitySearchForm";
    import ExportButton from "../../../components/ExportButton";

    export default {
        name: "CommercialAvailabilitySearchPage",
        components: {ExportButton, CommercialAvailabilitySearchForm, MarketTrackerNav, EmptyState},
        data(){
          return {
              show: true,
              loading: false,
              default_sort: {
                  prop: 'price',
                  order: 'ascending'
              },
              options: {},
              search: {},
              rpp: 20,
              meta: {},
              results: [],
          }
        },
        computed: {
          showDistance(){
              return this.$store.getters['commercialAvailability/getFormObject'] && this.$store.getters['commercialAvailability/getFormObject'].radius;
          }
        },
        methods: {
            handleCommand(cmd, details) {
                const vm = this;
                switch (cmd) {
                    case "view":
                        return this.open(details.id);
                    case "lm":
                        return vm.openMap(details.coords);
                }
            },
            onSortPropertyChange(object){
                if(object.prop && object.order){
                    this.default_sort = object;
                }else{
                    this.default_sort = null;
                }
                this.fetch(1);
            },
            openMap(coords){
                const vm = this;
                vm.$confirm(
                    'Are you sure you want to map this property? This will take you away from this page.',
                    'Map Property?',
                    {
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'Cancel'
                    })
                    .then(() => {
                        this.$router.push('/?action=coords&lat=' + coords.lat + '&lng=' + coords.lon)
                    })
                    .catch(() => {

                    })
            },
            open(id){
                window.open('https://www.rightmove.co.uk/commercial-property-for-sale/property-' + id + '.html')
            },
            showSearch(){
                this.meta = {};
                this.search = {};
                this.results = [];
                this.show = true;
            },
            fetch(page){
                let vm = this;
                vm.results = [];
                vm.show = false;
                vm.loading = true;
                vm.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id  + '/commercial-availability', {
                    params: Object.assign( this.$store.getters['commercialAvailability/getFormObject'], {
                        page: page || 1,
                        rpp: vm.rpp,
                        sort: vm.default_sort && vm.default_sort.prop ? vm.default_sort.prop : null,
                        order: vm.default_sort && vm.default_sort.order ? vm.default_sort.order === 'descending' ? 'desc' : 'asc' : null,
                    })
                }).then((response) => {
                    vm.results = response.data.results;
                    vm.meta = response.data.meta;
                    vm.loading = false;
                }).catch(() => {
                    vm.loading = false;
                })
            },
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.$store.commit('propertyPrices/reset');
                vm.$store.commit('auth/setBreadcrumbs', [
                    {
                        title: 'MarketTracker',
                        path: '/market-tracker'
                    },
                    {
                        title: 'Commercial Availability'
                    }
                ]);
            })
        }
    }
</script>
