<template>
    <div>
        <div v-if="value">

            <span class="w-full text-right leading-none"
            :class="{
            'text-green-600': value > avg,
            'text-red-600': value < avg
            }">
                          <span class="block w-full flex items-center">
                        <i
                               :class="{
                                 'el-icon-arrow-up': value > avg,
            'el-icon-arrow-down': value < avg
                               }"
                                class="text-xs mr-1 ml-auto"></i>

                              {{ showAs === 'price' ? toPrice(value) : toFloat(value)  }}
                    </span>
                    </span>
        </div>
        <div v-else>
            N/A
        </div>
    </div>
</template>

<script>
    export default {
        name: "MarketTrackerNestedValue",
        props: {
            value: {
                type: Number,
                default(){
                    return null
                }
            },
            showAs: {
              type: String,
              default(){
                  return "price"
              }
            },
            avg: {
                type: Number,
                default(){
                    return null
                }
            }
        },
        methods: {
            difference(value){
                if(value){
                    let val =  (this.avg / value);
                    return val;
                }
                return null;
            },
            toPrice(value){
                return value ? "£" + Math.floor(value).toLocaleString() : 'N/A';
            },
            toFloat(value){
                return value ? value.toFixed(2) + '%' : 'N/A';
            },
        }
    }
</script>

<style scoped>

</style>