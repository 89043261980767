<template>
    <div class="bg-white w-full">
        <div class="block mx-6 pb-6 pr-3 lg:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <select id="tabs" name="tabs"
                    v-model="selected"
                    @change="onChange"
                    class="block w-full pl-3 pr-10 py-2 text-base border border-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                <option
                        :key="key"
                        :value="item.url"
                        v-for="(item, key) of items">
                    {{ item.label }}
                </option>
            </select>
        </div>
    <div class="hidden lg:block lg:w-full">
        <div class="flex h-12 -mt-4 mx-0">
            <router-link
                    active-class="border-blue-800 text-gray-800"
                    :to="'/team/' + $route.params.teamId"
                    class="ml-8 cursor-pointer inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500  transition duration-150 ease-in-out"
                    exact>
                My Team
            </router-link>

            <router-link
                    active-class="border-blue-800 text-gray-800"
                    :to="'/team/' + $route.params.teamId + '/seats'"
                    class="ml-8 cursor-pointer inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500  transition duration-150 ease-in-out"
            >
               Manage Seats
            </router-link>

            <router-link
                    active-class="border-blue-800 text-gray-800"
                    :to="'/team/' + $route.params.teamId + '/invites'"
                    class="ml-8 cursor-pointer inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500  transition duration-150 ease-in-out"
            >
                Invites
            </router-link>

            <router-link
                    active-class="border-blue-800 text-gray-800"
                    :to="'/team/' + $route.params.teamId + '/invoices'"
                    class="ml-8 cursor-pointer inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500  transition duration-150 ease-in-out"
            >
               Invoices
            </router-link>

            <router-link
                    active-class="border-blue-800 text-gray-800"
                    :to="'/team/' + $route.params.teamId + '/settings'"
                    class="ml-8 cursor-pointer inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500  transition duration-150 ease-in-out"
            >
                Settings
            </router-link>

        </div>
    </div>
    </div>
</template>

<script>
    export default {
        name: "ActiveTeamNav",
        computed: {
            isJobView(){
                return this.$route.path === '/teams' || this.$route.path.startsWith('/team/');
            }
        },
        methods: {
            onChange(){
                this.$router.push(this.selected);
            }
        },
        data(){
            return {
                selected: this.$route.path,
                items: [
                    {label: 'My Team', url: '/team/' + this.$route.params.teamId},
                    {label: 'Mange Seats', url: '/team/' + this.$route.params.teamId + '/seats'},
                    {label: 'Invites', url: '/team/' + this.$route.params.teamId + '/invites'},
                    {label: 'Invoices', url: '/team/' + this.$route.params.teamId + '/invoices'},
                    {label: 'Settings', url: '/team/' + this.$route.params.teamId + '/settings'},
                ]
            }
        }
    }
</script>

<style scoped>

</style>