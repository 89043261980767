<template>
   <div class="my-3" style="width: 750px">
       <l-map
               style="height: 270px;"
               ref="map"
               @ready="onMapReady"
               :center="center"
               :options="{zoomControl: false, attributionControl: false}"
       >
           <l-google-tile-layer
                   :apikey="$store.state.auth.map.google.key"
                   :options="{type: 'hybrid'} "/>
           <slot></slot>
       </l-map>
   </div>
</template>

<script>
    export default {
        name: "ReportMap",
        props: {
            center: {
                type: Object
            },
            bbox: {
                type: Array
            }
        },
        methods: {
            onMapReady(){
                const vm = this;
                console.log(vm.bbox)
                if (vm.$refs && vm.$refs['map'] && vm.bbox) {
                    this.$refs['map'].mapObject.fitBounds([
                        [vm.bbox[1], vm.bbox[0]],
                        [vm.bbox[3], vm.bbox[2]],
                    ])
                }
            },
        }
    }
</script>

<style scoped>

</style>