import api from "../../utils/api";

const initialState = {
    active: {
        center: [52.09228255792897, -0.9937155504271413]
    },
    bounds: null,
    zoom: 8,
    running: false
};

export default {
    namespaced: true,
    state: initialState,
    mutations: {
        reset(state) {
            console.log('reset')
            Object.keys(initialState).forEach(key => {
                state[key] = initialState[key]
            })
        },
    },
    actions: {

        add: ({state}, opts) => {
            state.running = true;
            return api.post('/api/v1/alerts', opts)
        },
        update: ({state}, opts) => {
            state.running = true;
            return api.put('/api/v1/alerts/' + opts.hash, opts.form)
        },
        get: ({state}, opts) => {
            state.running = true;
            return api.get('/api/v1/alerts/' + opts.hash)
        },
        remove: ({state}, opts) => {
            state.running = true;
            console.log('Remove')
            return api.delete('/api/v1/alerts/' + opts.hash)
        },
    }
}