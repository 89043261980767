<template>
      <span :class="getClassForMobileCoverage(value)">
                                        <i
                                                v-if="value === 'A'"
                                                class="el-icon-warning-outline"></i>
                                               <i
                                                       v-else-if="value === 'G'"
                                                       class="el-icon-check"></i>
                                           <i
                                                   v-else
                                                   class="el-icon-close"></i>
                                    </span>
</template>

<script>
    import { getClassForMobileCoverage } from "./BroadbandCoverage"

    export default {
        name: "MobileCoverageCheckboxComponent",
        props: {
          value: {
              type: String,
              default(){
                  return ''
              }
          }  
        },
        methods: {
            getClassForMobileCoverage(rating){
                return getClassForMobileCoverage(rating);
            }
        }
    }
</script>