<template>
    <div class="bg-white w-full">
        <div class="block mx-6 pb-6 pr-3 lg:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <select id="tabs" name="tabs"
                    v-model="selected"
                    @change="onChange"
                    class="block w-full pl-3 pr-10 py-2 text-base border border-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                <option
                        :key="key"
                        :value="item.url"
                        v-for="(item, key) of items">
                    {{ item.label }}
                </option>
            </select>
        </div>
    <div class="hidden lg:block lg:w-full">
        <div class="flex h-12 -mt-4 mx-0">
            <router-link
                    :key="key"
                    v-for="(item, key) of items"
                    active-class="border-blue-800 text-gray-800"
                    :to="item.url"
                    class="ml-8 cursor-pointer inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500  transition duration-150 ease-in-out"
                    exact>
                {{ item.label }}
            </router-link>

        </div>
    </div>
    </div>
</template>

<script>
    export default {
        name: "PropertyPricesNav",
        methods: {
            onChange(){
                this.$router.push(this.selected);
            }
        },
        data(){
            return {
                selected: this.$route.path,
                items: [
                    {label: 'Overview', url: '/property-sales'},
                    {label: 'Civil Parishes', url: '/property-sales/civil-parishes'},
                    {label: 'Districts', url: '/property-sales/districts'},
                    {label: 'Welsh Communities', url: '/property-sales/communities'},
                    {label: 'London Boroughs', url: '/property-sales/london-boroughs'},
                    {label: 'Unitary Authorities', url: '/property-sales/unitary-authorities'},
                    {label: 'Search', url: '/property-sales/search'}
                ]
            }
        }
    }
</script>

<style scoped>

</style>