<template>

    <div>

        <transition
                enter-active-class="transition ease-in-out duration-300 transform"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition ease-in-out duration-300 transform"
                leave-class="opacity-100"
                leave-to-class="opacity-0"
        >
            <div
                    v-if="active_option !== null"
                    class="absolute z-3001 inset-0 transition-opacity bg-opacity-50 bg-black">


                <transition
                        enter-active-class="transition  delay-700 ease-in-out duration-300 transform"
                        enter-class="-translate-x-full"
                        enter-to-class="translate-x-0"
                        leave-active-class="transition  ease-in-out duration-300 transform"
                        leave-class="translate-x-0"
                        leave-to-class="-translate-x-full"
                >
                    <menu-sidebar-layer
                            v-if="active_option === 'info'"
                            :show-close="!modal_data.loading"
                            class="bg-white ml-20 mt-16 relative el-block--job-data el-block--job-data--land-hawk"
                            @close="()=>{ active_option = null; setBreadcrumbs(); }">


                        <ownership-loading
                                v-if="modal_data.loading"
                        />

                        <ownership-layer-details
                                v-if="!modal_data.loading"
                                v-model="modal_data.data"
                                :show-snap-to="false"
                                :show-add-to-workflow="false"
                                :show-view-on-map="true"
                                :show-find-properties="false"
                                @close="()=>{ active_option = null;  }"
                        />

                    </menu-sidebar-layer>
                </transition>


            </div>


        </transition>


        <page :show-content="false"
                class="bg-gray-900" v-loading="loading">

            <template slot="header">
                <page-header :title="title" />
            </template>


            <div class="p-6 el-block--full-height-board">
               <workflow-kanban-board
                       ref="kanban"
                       v-model="$route.params.workflowId"
                       @loading="(bool) => loading = bool"
                       @loaded="handleLoaded"
                       @deleted="()=>{ $router.push('/workflows') }"
                       @error="handleLoadError"
                       :confirm-view="({inspireId})=>{ fetchOwnership(inspireId) }"
               />
            </div>

        </page>

    </div>


</template>
<script>

    import WorkflowKanbanBoard from "../../components/Workflow/WorkflowKanbanBoard";
    import MenuSidebarLayer from "../../components/LandMonitor/MenuSidebarLayer";
    import OwnershipLoading from "../../components/LandMonitor/OwnershipLayerComponents/OwnershipLoading";
    import OwnershipLayerDetails from "../../components/LandMonitor/OwnershipLayerDetails";

    export default {
        name: 'Workflow',
        components: {
            OwnershipLayerDetails,
            OwnershipLoading,
            MenuSidebarLayer,
            WorkflowKanbanBoard
        },
        data() {
            return {
                title: 'Workflow',
                loading: true,
                active_option: null,
                modal_data: {
                    loading: true,
                    data: {}
                },
            }
        },
        methods: {
            fetchOwnership(inspireId) {
                const vm = this;
                vm.active_option = 'info';
                vm.modal_data.loading = true;
                vm.modal_data.data = {};
                return vm.$store.dispatch('map/fetch', {
                    inspireId: inspireId
                })
                    .then((response) => {
                        vm.modal_data.data = response;
                        vm.modal_data.loading = false;
                    })
                    .catch(() => {
                        vm.modal_data.loading = false;
                    })
            },
            setBreadcrumbs(){
                this.$store.commit('auth/setBreadcrumbs', [
                    {
                        title: 'My Workflows',
                        path: '/workflows'
                    },
                    {
                        title: this.title
                    }
                ]);
            },
            handleLoaded(){
              let vm = this;
              console.log('loaded', vm.$refs);
              vm.$refs.kanban.setBreadcrumbs();
              vm.title = vm.$refs.kanban.getWorkflowName();
            },
            handleLoadError(){
                let vm = this;
                vm.$alert('Sorry, but this Workflow does not exist.', 'Workflow not Found', {
                    confirmButtonText: 'OK',
                    callback: () => {
                        vm.$router.push('/workflows')
                    }
                });
            }
        }
    }
</script>
<style>
    .z-3001 {
        z-index: 3001;
    }
    .el-block--full-height-board {
        height: calc(100vh - 140px)
    }
    .el-block--job-data--workflow .el-block--scroll-detail {
        height:542px !important
    }
</style>

