<template>
    <div class="h-full bg-gray-200 overflow-scroll">
        <div class="bg-blue-900 p-6">
            <h4 class="font-semibold overflow-hidden text-white">
                EPC Ratings
            </h4>
            <p class="text-xs text-white">Quickly identify homes that require improvements and compare electricity performance certificates.</p>
        </div>
        <div class="bg-gray-200 p-3">
            <div class="columns">
                <div class="column w-full">
                    <dashboard-widget title="Search EPC Ratings">
                        <template v-slot:cta>
                            <export-button
                                    @export="(format) => exportData(format) "
                                    :exporting="$store.state.map.data_metrics.epcs.fetching"
                                    :disabled="$store.state.map.data_metrics.epcs.fetching"
                            />
                        </template>


                        <data-layer-filters
                                ref="dataLayerFilters"
                                :loading="$store.state.map.data_metrics.epcs.fetching"
                                :filters="filters"
                                @change="()=> { fetchTable(1) } "
                        />

                        <div class="h-1 border-b mt-3 border-gray-200"></div>

                        <data-layer-table-wrapper
                                ref="tableWrapper"
                                :filters="filters"
                                @change="(page)=> {  fetchTable(page) }"
                                v-model="$store.state.map.data_metrics.epcs">

                            <div
                                    :key="k"
                                    v-for="(i, k) of $store.state.map.data_metrics.epcs.results"
                                    class="bg-white p-4 mb-6 border shadow border border-gray-200">

                                <div class="columns">
                                    <div class="column w-1/3 my-4">
                                        <div class="border border-gray-300  p-2 text-center h-full rounded" :style="{
                                        background: getEPCColor(i.current_energy_rating)
                                        }">

                                            <h4 class="text-5xl font-semibold">{{ i.current_energy_rating }}</h4>
                                            <div class="text-xs mt-1">
                                                Lodged <date-human-view size="text-xs"  v-model="i.lodgement_date"/>
                                            </div>


                                        </div>
                                    </div>
                                    <div class="column w-2/3">
                                        <h3 class="font-bold mt-1 leading-tight my-2 mt-6">{{ i.single_line_address }}</h3>
                                        <div class="text-xs">
                                            <span v-if="i.potential_energy_rating" >
                                            <span class="font-bold">Potential Rating:</span> {{ i.potential_energy_rating }} <br />
                                            </span>
                                            <span v-if="i.property_type">
                                                <span class="font-bold">Property Type:</span> {{ i.property_type }} <br />
                                            </span>
                                            <span v-if="i.built_form">
                                                <span class="font-bold">Built Form:</span> {{ i.built_form }} <br />
                                            </span>
                                            <span  v-if="i.total_floor_area">
                                               <span class="font-bold">Total Floor Area:</span> {{ i.total_floor_area.toLocaleString() }} ft²<br />
                                           </span>
                                        </div>
                                        <div class="flex justify-end" v-if="i.uprn">
                                            <el-button size="mini"
                                                       @click="$emit('uprn', i.uprn)"
                                                       type="success" icon="el-icon-view">View</el-button>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </data-layer-table-wrapper>


                    </dashboard-widget>
                </div>
            </div>
        </div>
    </div>
</template>

<script>



    import {getEPCRatingColor} from "../../../utils/ownership";
    import DataLayerFilters from "../../DataLayerFilters";
    import ExportButton from "../../ExportButton";
    import DataLayerTableWrapper from "../../DataLayerTableWrapper";
    import {download} from "../../../utils/download";

    export default {
        name: "EpcDataLayer",
        components: {DataLayerTableWrapper, ExportButton, DataLayerFilters},
        data() {
            return {
                filters: [
                    {
                        id: 'single_line_address',
                        label: 'Address',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'property_type',
                        label: 'Property Type',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'current_energy_rating',
                        label: 'Current Rating',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'potential_energy_rating',
                        label: 'Potential Rating',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },

                    {
                        type: "text",
                        id: "lodgement_date",
                        label: "Date Lodged",
                        operators: ["=", "<",  "<=", '>', ">="],
                        date: true
                    },

                    {
                        type: "numeric",
                        id: "total_floor_area",
                        label: "Total floor area (ft²)",
                        operators: ["=", "<",  "<=", '>', ">="],
                        date: true
                    },


                ]
            }
        },
        created() {
            this.fetchTable(1);
            this.$store.commit('map/addLayerAvailability', 'epcs');
            this.$store.commit('map/addLayerVisibility', 'epcs');
        },
        beforeDestroy(){
            this.$store.commit('map/removeLayerAvailability', 'epcs')
            this.$store.commit('map/removeLayerVisibility', 'epcs')
        },

        watch: {
            '$store.state.map.bounds'(){
                const vm = this;

                setTimeout(()=>{
                    vm.fetchTable(1);
                }, 500);

            }
        },
        computed: {
            getQueryObject() {
                const match = this.query && this.query.logicalOperator ? this.query.logicalOperator : null;
                if (!match) {
                    return null;
                }
                return {
                    match: match,
                    rules: this.query && this.query.children ? this.query.children.map((i) => {
                        return i.query
                    }).filter((item) => {
                        return item && ((typeof item.value === 'string' && item.value.length > 0) || item.type === 'custom-component' || (typeof item.value === 'boolean'));
                    }) : []
                }
            },
        },
        methods: {
            getEPCColor(rating){
              return getEPCRatingColor(rating)
            },
            buildFilters(page) {

                let vm = this,
                    filters = {},
                    object = {
                        rpp: 10,
                        page: page || 1
                    },
                    queryObject = this.$refs.dataLayerFilters ? this.$refs.dataLayerFilters.getFilters() : {};

                if (queryObject && queryObject.match && queryObject.rules) {
                    filters.match = queryObject.match;
                    filters.rules = queryObject.rules;
                }

                if (this.$refs.tableWrapper && this.$refs.tableWrapper.getSortField().length > 0) {
                    let ex = this.$refs.tableWrapper.getSortField().split('|');
                    console.log(ex);
                    if (ex.length === 2) {
                        object.sort = ex[0];
                        object.order = ex[1];
                    }
                }

                const bounds = this.$store.state.map.search_area === 'bounds' ? vm.$store.getters['map/getMapBoundsAsObject'] : {};

                object.search_area = this.$store.state.map.search_area;

                return Object.assign(object, filters, bounds);

            },
            exportData(type){
                const vm = this, object = this.buildFilters();
                vm.$store.state.map.data_metrics.epcs.fetching = true;
                object.format = type;
                vm.$store.dispatch('map/searchEPCs', object)
                    .then((response) => {
                        vm.$store.state.map.data_metrics.epcs.fetching = false;
                        download(response.data.data);
                    }).catch(() => {
                    vm.$store.state.map.data_metrics.epcs.fetching = false;
                })
            },
            fetchTable(page) {
                const vm = this, object = this.buildFilters(page);

                vm.$store.state.map.data_metrics.epcs.fetching = true;
                vm.$store.dispatch('map/searchEPCs', object).then((response) => {
                    vm.$store.state.map.data_metrics.epcs.fetching = false;
                    vm.$store.state.map.data_metrics.epcs.results = response.data.data.results;
                    vm.$store.state.map.data_metrics.epcs.meta = response.data.data.meta;
                }).catch(() => {
                    vm.$store.state.map.data_metrics.epcs.fetching = false;
                    vm.$store.state.map.data_metrics.epcs.results = [];
                    vm.$store.state.map.data_metrics.epcs.meta = {};
                })
            },
        }
    }
</script>
<style>
    .el-date-picker {
        z-index: 50001 !important;
    }
</style>