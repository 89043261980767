<template>
    <dashboard-widget title="Please select a starting Location">

     <div v-loading="isLoading">
         <div class="mb-3">
             <el-alert effect="dark" type="info" :closable="false">
                 Please search for a location below in order to get started with Land Monitor.
             </el-alert>
         </div>

         <vue-google-autocomplete
                 class="p-2 rounded px-4 w-full bg-white text-sm border-gray-400 border h-12"
                 id="google-autocomplete"
                 placeholder="Search for a location or postcode..."
                 ref="search-google-autocomplete"
                 :enable-geolocation="true"
                 :disabled="isLoading"
                 :country="['uk']"
                 types="geocode"
                 v-on:placechanged="onLocationSearchChange"
         />

         <div class="py-4">

             <l-map
                     class="h-64 w-full"
                     ref="initLocationMapObject"
                     :zoom="16"
                     :max-zoom="18"
                     :min-zoom="8"
                     :center="location"
                     @update:center="(e) => { location = {lat: e.lat, lon: e.lng};  }"
                     :options="{zoomControl: false, attributionControl: false}"
             >
                 <l-google-tile-layer
                         :apikey="$store.state.auth.map.google.key" />
             </l-map>
         </div>

         <div class="text-right mt-6">
             <el-button
                     @click="setLocation"
                     type="success" icon="el-icon-plus">
                 Set my Location
             </el-button>
         </div>
     </div>

    </dashboard-widget>
</template>
<script>

    import L from "leaflet";

    window.L = L;

    export default {
        name: 'AccountSetInitialLocation',
        data() {
            return {
                isLoading: false,
                location: {
                    lat: 51.5074256,
                    lon: -0.1271814
                },

            }
        },
        methods: {
            setLocation(){
              const vm = this;
              vm.isLoading = true;

                vm.$store.state.auth.user.location = {
                    lat: parseFloat(vm.location.lat),
                    lng: parseFloat(vm.location.lon)
                };

                vm.$store.state.auth.user.zoom = 18;

                vm.$store.commit('auth/setUserLocation', Object.assign(this.$store.state.auth.user.location, {
                    zoom: this.$store.state.auth.user.zoom,
                    set_initial: true
                }));



                setTimeout(() => {
                    vm.$http.get('/api/v1/auth/user')
                        .then((response)=> {
                            vm.$store.commit('auth/setUser', response.data.data);
                        })
                }, 3000)

            },
            onLocationSearchChange(opts) {
                const vm = this;

                if (opts.latitude && opts.longitude) {
                    new Promise((resolve) => {
                        return resolve(vm.$refs.initLocationMapObject.mapObject.setView(L.latLng(opts.latitude, opts.longitude)));
                    })
                }
            }
        }
    }
</script>