export function openCompanySearch (companyName) {
  window.open('https://suite.endole.co.uk/insight/search/?q=' + companyName)
}

export function openContactSearch (companyName) {
  window.open('https://www.linkedin.com/search/results/people/?keywords=' + companyName)
}

export function openBirdseyeView ({lat, lng}) {
  window.open('https://www.bing.com/maps?cp=' + lat + '~' + lng + '&lvl=16&style=b&toWww=1')
}

export function openGoogleMaps ({lat, lng}) {
  window.open('https://www.google.com/maps?q=' + lat + ',' + lng )
}


export function openRightMoveSearch (string) {
  window.open('https://www.google.com/search?q=site%3Arightmove.co.uk ' + string )
}

