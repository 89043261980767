<template>
    <div>

        <div class="columns">
            <div
                    v-if="!getUseClasses.length"
                    class="column w-full">

                <dashboard-widget
                        padding-class=""
                        title="Property Use Classes">
                    <slot>
                        <div class="p-3">
                            <empty-state />
                        </div>
                    </slot>
                </dashboard-widget>
            </div>


            <div class="column w-full" v-if="getUseClasses.length > 0">
                <dashboard-widget
                        padding-class="''"
                        title="Property Use Classes">

                    <div class="p-2">
                        <el-alert
                                effect="dark" type="success" :closable="false">
                            {{ getUseClasses.length }} property classifications within this site
                        </el-alert>
                    </div>

                    <el-table
                            :data="getUseClasses"
                            style="width: 100%">
                        <el-table-column
                                class-name="font-semibold text-xs"
                                label="Description">
                            <template slot-scope="scope">
                                    {{ scope.row.classification_class_name }}
                            </template>
                        </el-table-column>
                        <el-table-column
                                class-name="font-semibold text-xs"
                                width="100px"
                                label="Use Class">
                            <template slot-scope="scope">
                                {{ scope.row.classification_class ? scope.row.classification_class : '-' }}
                            </template>
                        </el-table-column>
                        <el-table-column
                                align="right"
                                width="100px"
                                class-name="font-semibold text-xs"
                                label="Properties">
                            <template slot-scope="scope">
                                {{ scope.row.count }}
                            </template>
                        </el-table-column>
                    </el-table>

                </dashboard-widget>
            </div>
        </div>

    </div>
</template>

<script>
    import EmptyState from "../../EmptyState";

    export default {
        name: "OwnershipUseClasses",
        components: {EmptyState},
        props: {
            value: {
                type: Array,
                default(){
                    return []
                }
            },
            activeSiteId: {
                type: Number,
                default(){
                    return null;
                }
            },
        },

        computed: {
            getUseClasses(){
                let useCases = this.properties.filter((i)=> i.classification_class);
                let objects = {};
                for(let u of useCases){
                    if(!objects[u.classification_code]){
                        objects[u.classification_code] = {
                            classification_class: u.classification_class,
                            classification_class_name: u.classification_class_name,
                            count: 0
                        }
                    }
                    objects[u.classification_code].count++;
                }
                return Object.values(objects);
            },
            properties(){
                if(this.activeSiteId){
                    return this.value.filter((i)=>{
                        return i.site_id === this.activeSiteId;
                    })
                }
                return this.value
            }
        }
    }
</script>

<style scoped>

</style>