<template>

    <div>
        <div
                v-if="loading"
                class="flex h-full mt-12 pt-12 items-center justify-center">
            <div class="p-3 text-center">
                <property-loader />
                <h3 class="font-semibold text-blue-800">Fetching Flood Risk Data...</h3>
            </div>
        </div>

        <div class="columns" v-if="!loading">
            <div class="column w-full">

                <dashboard-widget
                        padding-class=""
                        title="Flood Risk">

                    <div class="p-3 pb-0">

                        <div class="pb-3">
                            <el-alert
                                    effect="dark"
                                    :type="fetchCoverageBreakdown.flood_risk ? 'warning': 'success'" :closable="false">
                                This site is {{ fetchCoverageBreakdown.flood_risk ? 'within': 'not within' }} a flood risk area.
                            </el-alert>
                        </div>

                        <ownership-overview-map
                                ref="ownership-map"
                                :height="180"
                                :coords="fetchCoverageBreakdown.center"
                                :polygons="fetchCoverageBreakdown.polygon"
                                :bbox="fetchCoverageBreakdown.bbox"
                        >
                            <div
                                    :key="'layer_' + index"
                                    v-for="(layer, index) of flood_layers"
                            >
                                <l-wms-tile-layer
                                        :base-url="layer.url ? layer.url : ''"
                                        :layers="layer.layers ? layer.layers : ''"
                                        :styles="layer.styles ? layer.styles : ''"
                                        :opacity="layer.opacity ? layer.opacity : 1"
                                        :visible="true"
                                        :transparent="true"
                                        class="el-leaflet-item"
                                        format="image/png"
                                        layer-type="base"
                                />
                            </div>
                        </ownership-overview-map>
                        <div class="flex py-2">
                            <div class="flex items-center p-1"><div class="w-4 h-4 rounded border border-gray-800" style="background-color: rgb(82, 255, 252);"></div><span class="text-xs ml-2  leading-tight">Zone 2 (0.1%)</span></div>
                            <div class="flex items-center p-1"><div class="w-4 h-4 rounded border border-gray-800" style="background-color: rgb(168, 178, 249);"></div><span class="text-xs ml-2  leading-tight">Zone 3 (0.5%)</span></div>
                        </div>
                    </div>


                    <div class="p-3 pt-0">

                        <el-button
                                @click="openPlanningFloodMap"
                                class="w-full" size="medium" type="info">
 <span class="flex items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 class="w-5 h-5 text-white"
                                 fill="currentColor"
                                 viewBox="0 0 24 24"><path d="M12 3.571c3.658 5.437 6 9.223 6 12.503 0 3.268-2.691 5.926-6 5.926s-6-2.658-6-5.925c0-3.281 2.341-7.067 6-12.504zm0-3.571c-4.87 7.197-8 11.699-8 16.075 0 4.378 3.579 7.925 8 7.925s8-3.547 8-7.925c0-4.376-3.13-8.878-8-16.075z"/></svg>
                            <span class="ml-1">View flood Map for planning</span>
                        </span>
                        </el-button>
                    </div>

                    <div class="p-3 pt-0">
                        <el-button
                                @click="openLongTermFlooding"
                                class="w-full" size="medium" type="info">
 <span class="flex items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 class="w-5 h-5 text-white"
                                 fill="currentColor"
                                 viewBox="0 0 24 24"><path d="M12 3.571c3.658 5.437 6 9.223 6 12.503 0 3.268-2.691 5.926-6 5.926s-6-2.658-6-5.925c0-3.281 2.341-7.067 6-12.504zm0-3.571c-4.87 7.197-8 11.699-8 16.075 0 4.378 3.579 7.925 8 7.925s8-3.547 8-7.925c0-4.376-3.13-8.878-8-16.075z"/></svg>
                            <span class="ml-1">View long term flood risk information</span>
                        </span>
                        </el-button>
                    </div>


                </dashboard-widget>

            </div>
        </div>

    </div>

</template>

<script>
    import PropertyLoader from "../../incs/PropertyLoader";
    import OwnershipOverviewMap from "./incs/OwnershipOverviewMap";
    export default {
        name: "OwnershipFloodingRisk",
        components: {OwnershipOverviewMap, PropertyLoader},
        props: {
            activeSiteId: {
                type: [Number, String],
                default(){
                    return null
                }
            },
            value: {
              type: Object,
              default(){
                  return {}
              }
            },
            loading: {
                type: Boolean,
                default(){
                    return true;
                }
            }
        },
        data(){
            return {
                flood_layers: [
                    {
                        url: "https://tiles.landmonitor.com/geoserver/lm/wms",
                        type: "wms",
                        layers: "lm:planning-flood-risk-2"
                    },
            {
                url: "https://tiles.landmonitor.com/geoserver/lm/wms",
                    type: "wms",
                layers: "lm:planning-flood-risk-3"
            }
        ]
            }
        },
        methods: {
            openPlanningFloodMap(){
                if(this.getEastingNorthing && this.getEastingNorthing.easting && this.getEastingNorthing.northing){
                    window.open('https://flood-map-for-planning.service.gov.uk/confirm-location?easting=' + this.getEastingNorthing.easting + '&northing=' + this.getEastingNorthing.northing);
                }
            },
            openLongTermFlooding(){
                if(this.getEastingNorthing && this.getEastingNorthing.easting && this.getEastingNorthing.northing){
                    window.open('https://check-long-term-flood-risk.service.gov.uk/map?easting=' + this.getEastingNorthing.easting + '&northing=' + this.getEastingNorthing.northing);
                }
            }
        },
        computed: {
            fetchCoverageBreakdown(){
              let overview = this.value && this.value.overview ? this.value.overview : null;
              if(!this.activeSiteId){
                 return overview;
              }
              let find  = this.value.sites.find((i) => i.site_id === this.activeSiteId);
                return find ? find : null;
            },
            getEastingNorthing(){
              let item = this.fetchCoverageBreakdown;
              return item && item.eastings_northings ? item.eastings_northings : null
            }
        }
    }
</script>