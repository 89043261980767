<template>
    <action-modal
            :show="visible"
            :has-actions="hasActions"
            :has-header="hasHeader"
            :width="width"
            :auto-height="autoHeight"
            @close="visible = false; $emit('close')">

        <template v-slot:header v-if="hasHeader">
            <slot name="header"></slot>
        </template>



        <template v-slot:content>
            <slot name="content"></slot>
        </template>
        <template v-slot:actions>
            <div v-if="hasActions">
                <slot name="actions"></slot>
            </div>
        </template>
    </action-modal>
</template>

<script>
    export default {
        name: "MapModal",
        props: {
            show: {
                type: Boolean,
                default: false
            },
            autoHeight: {
                type: Boolean,
                default: false
            },
            hasActions: {
                type: Boolean,
                default: true
            },
            hasHeader: {
                type: Boolean,
                default: true
            },
            width: {
                type: String,
                default(){
                    return null
                }
            }
        },
        watch: {
          show(val){
              this.visible = val;
          }
        },
        data(){
            return {
                visible: this.show
            }
        }
    }
</script>

<style scoped>

</style>