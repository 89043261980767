<template>
   <div>
       <el-tabs v-model="$store.state.auth.account.activeTab">
           <el-tab-pane label="Your Profile"  class="py-6" name="profile">
               <account-profile-component />
           </el-tab-pane>
           <el-tab-pane label="Invites"  class="py-6" name="invites">
               <invites-profile-component />
           </el-tab-pane>
           <el-tab-pane label="Account Security" class="py-6" name="security">
               <security-profile-component />
           </el-tab-pane>
           <el-tab-pane label="Delete Account"  class="py-6" name="delete">
               <delete-account-profile-component />
           </el-tab-pane>
       </el-tabs>
   </div>
</template>
<script>
    import AccountProfileComponent from "./AccountProfileComponent";
    import InvitesProfileComponent from "./InvitesProfileComponent";
    import SecurityProfileComponent from "./SecurityProfileComponent";
    import DeleteAccountProfileComponent from "./DeleteAccountProfileComponent";
    export default {
        name: 'Account',
        components: {
            DeleteAccountProfileComponent,
            SecurityProfileComponent, InvitesProfileComponent, AccountProfileComponent}
    }
</script>