<template>
    <dl class="el-block--dl">
        <div class="item">
            <dt>
                Name
            </dt>
            <dd>
                {{ $store.state.applications.application.agent_name }}
            </dd>
        </div>
        <div class="item">
            <dt>
                Company
            </dt>
            <dd>
                {{ $store.state.applications.application.agent_company }}
            </dd>
        </div>
        <div class="item">
            <dt>
                Address
            </dt>
            <dd>
                {{ $store.state.applications.application.agent_address }}
            </dd>
        </div>
        <div class="item">
            <dt>
                Email
            </dt>
            <dd>
                {{ $store.state.applications.application.agent_email }}
            </dd>
        </div>
    </dl>
</template>

<script>
    export default {
        name: "AgentDetails"
    }
</script>

<style scoped>

</style>