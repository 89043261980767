var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',{staticClass:"p-3 text-center"},[_c('property-loader')],1):_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.switching),expression:"switching"}]},[_c('div',{staticClass:"py-3"},[_c('el-alert',{attrs:{"effect":"dark","type":"info","closable":false}},[_vm._v(" You are currently a member of "+_vm._s(_vm.results.length.toLocaleString())+" teams. ")])],1),(_vm.results.length > 0)?_c('div',[_c('div',{staticClass:"relative bg-white h-64 overflow-scroll rounded-md -space-y-px w-full"},_vm._l((_vm.results),function(item,index){return _c('label',{key:item.id,staticClass:"relative border p-4 flex cursor-pointer md:pl-4 md:pr-6",class:{
                                      'border-gray-200' : _vm.selectedTeamId !== item.id,
                                      'bg-indigo-100 border-indigo-200 z-10': _vm.selectedTeamId  === item.id,
                                      'rounded-tl-md rounded-tr-md': index === 0 && _vm.results.length > 1,
                                       'rounded-bl-md rounded-br-md': index === (_vm.results.length - 1) && _vm.results.length > 1,
                                      }},[_c('span',{staticClass:"flex w-1/3 items-center text-sm leading-tight w-full"},[_c('span',[_c('avatar-manager',{attrs:{"name":item.name},model:{value:(item.avatar),callback:function ($$v) {_vm.$set(item, "avatar", $$v)},expression:"item.avatar"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedTeamId),expression:"selectedTeamId"}],staticClass:"hidden",attrs:{"type":"radio","aria-labelledby":"pricing-plans-0-label","aria-describedby":"pricing-plans-0-description-0 pricing-plans-0-description-1"},domProps:{"value":item.id,"checked":_vm._q(_vm.selectedTeamId,item.id)},on:{"change":function($event){_vm.selectedTeamId=item.id}}}),_c('span',{staticClass:"ml-3 w-full",class:{
                                                'text-gray-900' : _vm.selectedTeamId  !== item.id,
                                      'text-indigo-900': _vm.selectedTeamId  === item.id,
                                          }},[_c('span',{staticClass:"block flex items-center"},[_c('span',{staticClass:"font-medium mr-4"},[_vm._v(_vm._s(item.name))]),(item.is_selected)?_c('span',{staticClass:"px-2 py-1 rounded text-xxs bg-green-600 text-green-100"},[_vm._v("Current Team")]):_vm._e(),_c('br')]),_c('span',{staticClass:"flex w-full mt-2 text-xs"},[_c('span',{staticClass:"flex items-center"},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(item.team_members)+" member"+_vm._s(item.team_members > 1 ? 's' : '')+" ")])]),(item.is_owner)?_c('span',{staticClass:"flex items-center ml-2"},[_c('i',{staticClass:"el-icon-check text-green-500 mr-2"}),_c('span',[_vm._v("You are the owner")])]):_vm._e(),(item.subscriptions !== null)?_c('span',{staticClass:"flex items-center ml-2"},[_c('i',{staticClass:"el-icon-user text-gray-700 mr-2"}),_c('span',[_vm._v(_vm._s(item.subscriptions)+" active subscription"+_vm._s(item.subscriptions > 1 ? 's' : ''))])]):_vm._e()])])]),_c('span',{staticClass:"ml-6 pl-1 text-sm md:ml-0 md:pl-0 leading-tight text-right w-1/2"},[_c('span',{staticClass:"font-medium",class:{
                                                'text-gray-900' : _vm.selectedTeamId  !== item.id,
                                      'text-indigo-900': _vm.selectedTeamId  === item.id,
                                          }},[(item.subscription_expires_in)?_c('span',[_vm._v(" Subscription till "+_vm._s(_vm._f("moment")(item.subscription_expires_in,"MMM Do YYYY"))),_c('br'),_c('span',{staticClass:"text-xs"},[_c('date-human-view',{attrs:{"size":"text-xs","prefix":"Expires in "},model:{value:(item.subscription_expires_in),callback:function ($$v) {_vm.$set(item, "subscription_expires_in", $$v)},expression:"item.subscription_expires_in"}})],1)]):_c('span',[_c('span',{staticClass:"tag is-warning"},[_vm._v("No Subscription")])])])])])}),0),(_vm.selectedTeamId !== null)?_c('div',{staticClass:"pt-8 text-right"},[_c('el-button',{attrs:{"icon":"el-icon-refresh","type":"success"},on:{"click":_vm.switchTeam}},[_vm._v("Switch Team")])],1):_vm._e()]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }