<template>

        <page>

            <template slot="header">
                <page-header title="Teams">
                    <template v-slot:tabs>
                        <team-nav />
                    </template>
                </page-header>

            </template>

            <template slot="content">
                <div class="columns">
                    <div class="column w-full">
                        <dashboard-widget
                                title="My Teams"
                                padding-class="">

                            <empty-state class="p-3"
                                         title="No teams found"
                                         message="Sorry, but we could not find any teams that you a member of"
                                         :support="false"
                                         v-if="!table.loading && !table.results.length"/>


                            <div class="p-3 py-12 text-center" v-if="table.loading">
                                <property-loader />
                            </div>


                            <div v-if="table.results.length > 0 && !table.loading">
                                <div class="p-3">
                                    <el-alert effect="dark" type="info" :closable="false">
                                        You are currently a member of {{ table.results.length.toLocaleString() }} teams.
                                    </el-alert>
                                </div>
                                <el-table :data="table.results">
                                    <el-table-column label="Name">
                                        <template slot-scope="scope">

                                            <div class="flex items-center">
                                                <div class="flex-shrink-0 h-10 w-10">
                                                    <avatar-manager
                                                            :name="scope.row.name"
                                                            v-model="scope.row.avatar" />
                                                </div>
                                                <div class="ml-4">
                                                    <div class="text-sm font-medium text-gray-900">
                                                        {{ scope.row.name }}
                                                    </div>
                                                </div>
                                            </div>

                                        </template>
                                    </el-table-column>

                                    <el-table-column
                                            width="120"
                                            align="center"
                                            label="Status">
                                        <template slot-scope="scope">
                                            <pretty-status-label-component
                                            v-model="scope.row.status"
                                            />

                                        </template>
                                    </el-table-column>

                                    <el-table-column
                                            width="150"
                                            align="left"
                                            label="Subscription">
                                        <template slot-scope="scope">
                                            <date-human-view
                                                    prefix="Expires "
                                                    v-if="scope.row.subscription_expires_in"
                                                    v-model="scope.row.subscription_expires_in" />
                                            <span class="tag is-warning" v-else>None</span>
                                        </template>
                                    </el-table-column>

                                    <el-table-column
                                            width="100"
                                            align="center"
                                            label="Owner">
                                        <template slot-scope="scope">
                                            <i :class="{
                                            'el-icon-check text-green-500':  scope.row.is_owner,
                                            'el-icon-close text-red-500':  !scope.row.is_owner,
                                            }"></i>
                                        </template>
                                    </el-table-column>



                                    <el-table-column
                                            width="100"
                                            align="center"
                                            label="Members">
                                        <template slot-scope="scope">
                                            {{ scope.row.members }}
                                        </template>
                                    </el-table-column>


                                    <el-table-column
                                            width="150"
                                            align="left"
                                            label="Member Since">
                                        <template slot-scope="scope">
                                            <date-human-view v-model="scope.row.created_at" />
                                        </template>
                                    </el-table-column>


                                    <el-table-column width="120" align="right">
                                        <template slot-scope="scope">
                                            <el-button
                                                    v-if="!scope.row.is_owner"
                                                    @click="remove(scope.row.id)"
                                                    icon="el-icon-delete"
                                                    size="small" type="danger">Leave</el-button>
                                            <el-button
                                                    v-if="scope.row.is_owner"
                                                    @click="()=>{ $router.push('/team/' + scope.row.id) }"
                                                    icon="el-icon-edit"
                                                    size="small" type="info">Manage</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>


                        </dashboard-widget>
                    </div>
                </div>
            </template>

        </page>

</template>
<script>
    import PropertyLoader from "../components/incs/PropertyLoader";
    import EmptyState from "../components/EmptyState";
    import TeamNav from "./Team/TeamNav";
    import AvatarManager from "../components/AvatarManager";
    import PrettyStatusLabelComponent from "../components/PrettyStatusLabelComponent";
    export default {
        name: 'Teams',
        components: {PrettyStatusLabelComponent, AvatarManager, TeamNav, EmptyState, PropertyLoader},
        methods: {

            remove(teamId){
                const vm = this;
                vm.$confirm(
                    'Are you sure you want to leave this team? This cannot be undone and will be removed permanently.',
                    'Leave Team?',
                    {
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'Cancel'
                    })
                    .then(() => {
                        vm.table.loading = true;
                        vm.$store.dispatch('team/leave', {
                            teamId: teamId
                        }).then(()=>{
                            vm.$notify({
                                position: 'top-right',
                                title: 'Team Left',
                                message: 'You successfully left the team.',
                                type: 'success',
                                offset: 140
                            });
                            vm.fetch(1);
                        }).catch((e)=>{
                            vm.$store.dispatch('auth/handleServerError', {
                                vm: vm,
                                error: e
                            });
                            vm.table.loading = false;
                        })

                    })
                    .catch(() => {

                    })
            },
            fetch() {
                const vm = this;
                vm.table.loading = true;
                return vm.$store.dispatch('team/index').then((response) => {
                    vm.table.loading = false;
                    vm.table.results = response.data.data;
                }).catch(() => {
                    vm.table.loading = false;
                    vm.table.results = [];
                })
            }
        },
        mounted(){
          this.fetch();
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.$store.commit('auth/setBreadcrumbs', [
                    {
                        title: 'My Teams'
                    }
                ]);
            })
        },
        data(){
            return {
                table: {
                    loading: true,
                    results: [],
                }
            }
        }
    }
</script>

