import api from "../../utils/api";

const initialState = {

};

export default {
    namespaced: true,
    state: initialState,
    mutations: {
        reset(state) {
            console.log('reset')
            Object.keys(initialState).forEach(key => {
                state[key] = initialState[key]
            })
        },
    },
    actions: {
        index: ({rootState}, params) => {
            return api.get('/api/v1/team/' + rootState.auth.user.team.id + '/workflow/' + params.workflowId + '/items');
        },
        download: ({rootState}, params) => {
            return api.get('/api/v1/team/' + rootState.auth.user.team.id + '/workflow/' + params.workflowId + '/items', {
                params: {
                    format: params.format,
                    export: true
                }
            });
        },
        update: ({rootState}, params) => {
            return api.put('/api/v1/team/' + rootState.auth.user.team.id + '/workflow/' + params.workflowId + '/item/' + params.itemId, {
                column: params.columnId
            });
        },
        remove: ({rootState}, params) => {
            console.log(params);
            return api.delete('/api/v1/team/' + rootState.auth.user.team.id + '/workflow/' + params.workflowId + '/column/' + params.columnId + '/item/' + params.itemId)
        },
        create: ({rootState}, params) => {
            return api.post('/api/v1/team/' + rootState.auth.user.team.id + '/workflow/' + params.workflowId + '/items', {
                type: params.type,
                reference: params.reference,
                description: params.description
            });
        }
    }
}