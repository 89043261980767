module.exports = class {

    constructor(vm) {
        this.vm = vm;
    }

    init() {
        const vm = this.vm, _ = this, tour = this.vm.$shepherd({
            useModalOverlay: true,
            defaultStepOptions: {
                classes: 'land-monitor-theme'
            }
        });

        const currentLocation = JSON.parse(JSON.stringify(vm.$store.state.auth.user.location)),
            currentZoom = vm.$store.state.auth.user.zoom,
            currentMapView = vm.map_mode;

        vm.map_mode = 'hybrid';
        vm.$store.state.auth.user.location = {
            lat: 51.83499921653787,
            lng: -0.4593806611490115
        };

        vm.$store.state.auth.user.zoom = 17;

        vm.searchReset();

        tour.addStep({
            title: 'Welcome to Land Monitor!',
            text: "This tutorial will walk you through how to use Land Monitor in order to find development opportunities faster.",
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });

        tour.addStep({
            title: 'Searching for Sites & Property',
            text: "Land Monitor gives you the ability to search for properties and in land in a number of ways.",
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });

        tour.addStep({
            attachTo: {element: '.el--search-types--location', on: 'left'},
            text: "Locations lets you search via Google Maps",
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.search_mode = 'address';
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });

        tour.addStep({
            attachTo: {element: '.el--search-types--address', on: 'left'},
            text: "Locations lets you search UK registered addresses via Ordinance Survey.",
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.search_mode = 'title_number';
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });

        tour.addStep({
            attachTo: {element: '.el--search-types--title_number', on: 'left'},
            text: "If you know the title number of a property you can easily search for it.",
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.search_mode = 'company';
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });
        tour.addStep({
            attachTo: {element: '.el--search-types--company', on: 'left'},
            text: "Search registered companies that own property in the UK.",
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.search_mode = 'searches';
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });

        tour.addStep({
            attachTo: {element: '.el--search-types--searches', on: 'left'},
            text: "Load previous searches that you have saved.",
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.search_mode = 'location';
                        vm.filter.show = true;
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });

        tour.addStep({
            text: "Land Monitor gives you the ability to be incredibly granular when trying to find opportunities via filters.",
            attachTo: {
                element: '.el-block--search-form',
                on: 'right'
            },
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    return setTimeout(() => {
                        return resolve();
                    }, 2000)
                })
            },
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.filter.show = false;
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });


        tour.addStep({
            title: 'Ownership Polygons',
            text: "Once you have performed a search you will be presented with polygons of Land Ownership. Find a parcel of land you are interested in and click it.",

            beforeShowPromise: () => {

                vm.$store.state.auth.user.location = {
                    lat: 51.83499921653787,
                    lng: -0.4593806611490115
                };

                vm.$store.state.auth.user.zoom = 17;

                vm.searchReset();

                let object = Object.assign(vm.$store.state.auth.user.location, {
                    export_data: false
                });

                return new Promise((resolve) => {
                    return vm.$store.dispatch('map/fetchPolygons', object)
                        .then((response) => {
                            vm.setSearchResponse(response);
                            return resolve();
                        });
                });

            },
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });


        tour.addStep({
            text: "Clicking on a polygon brings up a wealth of information",
            attachTo: {
                element: '.el-block--property-details-overlay',
                on: 'right'
            },
            beforeShowPromise: () => {

                vm.$store.state.auth.user.zoom = 20;

                let inspireId = 33318563;
                vm.fetchOwnershipDetails(inspireId);
                vm.active_polygon_id = inspireId;
                vm.active_option = 'info';

                return new Promise((resolve) => {
                    vm.modal_data.resource.loading = true;
                    vm.modal_data.resource.data = {};
                    return vm.$store.dispatch('map/fetch', {
                        inspireId: inspireId
                    })
                        .then((response) => {
                            vm.modal_data.resource.data = response;
                            vm.modal_data.resource.loading = false;

                            setTimeout(() => {
                                vm.$refs.ownershipLayerDetails.fetchTopography();
                                return resolve();
                            }, 1000)
                        })
                        .catch(() => {
                            vm.modal_data.resource.loading = false;
                        })
                });

            },
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });


        tour.addStep({
            text: 'Here you can find the name and location of the property in question along with the ability to change views and share the property.',
            attachTo: {
                element: '.el-block--detail-menu',
                on: 'right'
            },
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });
        tour.addStep({
            text: 'You data block holds all the information that you are currently viewing',
            attachTo: {
                element: '.el-block--scroll-detail',
                on: 'right'
            },
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });
        tour.addStep({
            text: 'Here you can close or add a property to view later.',
            attachTo: {
                element: '.el-block--detail-actions',
                on: 'right'
            },
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });

        tour.addStep({
            title: 'Ownership & Downloads',
            text: 'Get an overview of the site in question including  the ownership whether it is owned privately or commercially and download a Land Monitor report.',

            attachTo: {
                element: '.el-block--scroll-detail',
                on: 'right'
            },
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.$refs.ownershipLayerDetails.setTab('administrative');
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });

        tour.addStep({
            title: 'Administration & Demographics',
            text: 'Find out useful information regarding the administrative and demographic area such as local authority output areas, deprivation, income and much more.',

            attachTo: {
                element: '.el-block--scroll-detail',
                on: 'right'
            },
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.$refs.ownershipLayerDetails.setTab('buildings-site');
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });

        tour.addStep({
            title: 'Site Area & Buildings',
            text: 'An in-depth breakdown of the current site coverage including building details such as area, heights and when it was last modified or built.',
            attachTo: {
                element: '.el-block--scroll-detail',
                on: 'right'
            },
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.$refs.ownershipLayerDetails.setTab('topography');
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });

        tour.addStep({
            title: 'Topography Analysis',
            text: 'Find out what makes up the topography of the sites environment such as bodies of water, buildings, woodland, tracks etc.',
            attachTo: {
                element: '.el-block--scroll-detail',
                on: 'right'
            },
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.$refs.ownershipLayerDetails.setTab('environment');
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });

        tour.addStep({
            title: 'Environment',
            text: 'See whether a site is within Greenbelt, Areas of outstanding natural beauty or a Flood Risk Area.',
            attachTo: {
                element: '.el-block--scroll-detail',
                on: 'right'
            },
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.$refs.ownershipLayerDetails.setTab('population');
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });

        tour.addStep({
            title: 'Population',
            text: 'Quickly a view detailed breakdown of the local population.',
            attachTo: {
                element: '.el-block--scroll-detail',
                on: 'right'
            },
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.$refs.ownershipLayerDetails.setTab('traffic');
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });

        tour.addStep({
            title: 'Traffic Analysis',
            text: 'Check traffic levels of local major/minor roads close to the site.',
            attachTo: {
                element: '.el-block--scroll-detail',
                on: 'right'
            },
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.$refs.ownershipLayerDetails.setTab('local-schools');
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });
/*
        tour.addStep({
            title: 'Mobile/Broadband Coverage',
            text: 'Determine if mobile & broadband coverage is adequate in the local area',
            attachTo: {
                element: '.el-block--scroll-detail',
                on: 'right'
            },
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.$refs.ownershipLayerDetails.setTab('local-schools');
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });
*/
        tour.addStep({
            title: 'Local Schools',
            text: 'View local schools and ofsted ratings.',
            attachTo: {
                element: '.el-block--scroll-detail',
                on: 'right'
            },
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.$refs.ownershipLayerDetails.setTab('planning-applications');
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });

        tour.addStep({
            title: 'Planning Applications',
            text: 'Retrieve all planning history that has been submitted over the years for properties within the site.',
            attachTo: {
                element: '.el-block--scroll-detail',
                on: 'right'
            },
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.$refs.ownershipLayerDetails.setTab('epc');
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });

        tour.addStep({
            title: 'EPC Ratings',
            text: 'Energy Performance Certificate\'s are available for each registered address within the site.',
            attachTo: {
                element: '.el-block--scroll-detail',
                on: 'right'
            },
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.$refs.ownershipLayerDetails.setTab('business-rates');
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });

        tour.addStep({
            title: 'Business Rates',
            text:'View the latest rates for properties within the site.',
            attachTo: {
                element: '.el-block--scroll-detail',
                on: 'right'
            },
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.$refs.ownershipLayerDetails.setTab('sale-history');
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });
        tour.addStep({
            title: 'Sale History',
            text: 'View price and price per square foot over the past 15 years for all properties on the site.',
            attachTo: {
                element: '.el-block--scroll-detail',
                on: 'right'
            },
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.$refs.ownershipLayerDetails.setTab('properties');
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });

        tour.addStep({
            title: 'Registered Addresses',
            text: 'Provides a list of all registered addresses with the site along with there usage and classification.',
            attachTo: {
                element: '.el-block--scroll-detail',
                on: 'right'
            },
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.$refs.ownershipLayerDetails.setTab('leaseholders');
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });

        tour.addStep({
            title: 'Leaseholders',
            text: 'Find out what leases are attached to sites and when they are set to expire.',
            attachTo: {
                element: '.el-block--scroll-detail',
                on: 'right'
            },
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.$refs.ownershipLayerDetails.setTab('listed');
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });

        tour.addStep({
            title: 'Listed Buildings',
            text: 'Check for listed buildings within a site including date and grade.',
            attachTo: {
                element: '.el-block--scroll-detail',
                on: 'right'
            },
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.$refs.ownershipLayerDetails.setTab('overview');
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });

        tour.addStep({
            text: 'If you find a site interest you can share with your team by adding it to your workflow.',
            attachTo: {
                element: '.el-button--add-to-workflow',
                on: 'top'
            },
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.active_option = null;
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });

        tour.addStep({
            text: "Let's have a look at some of the other features that Land Monitor has to offer.",

            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.$store.state.auth.user.zoom = 17;

                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });


        tour.addStep({
            attachTo: {element: '.el-block--map-layer-switch', on: 'top'},
            title: 'Map Views',
            text: 'Land Monitor offers you a range of different map views.',

            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.map_mode = 'hybrid';
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });

        tour.addStep({
            attachTo: {element: '.el-block--map-layer-switch--hybrid', on: 'top'},
            title: 'Hybrid Map',
            text: 'Powered via GoogleMaps, hybrid lets you see Land and a satellite view of the terrain.',
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.map_mode = 'satellite';
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });

        tour.addStep({
            attachTo: {element: '.el-block--map-layer-switch--satellite', on: 'top'},
            title: 'Satellite Map',
            text: 'via GoogleMaps, view a  satellite view of the terrain.',

            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.map_mode = 'roadmap';
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });

        tour.addStep({
            attachTo: {element: '.el-block--map-layer-switch--roadmap', on: 'top'},
            text: 'via GoogleMaps, view a  breakdown of the local area with road map names.',
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.map_mode = 'os';
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });


        tour.addStep({
            attachTo: {element: '.el-block--map-layer-switch--os', on: 'top'},
            title: 'MasterMap',
            text: 'MasterMap is supplied by the Ordinance Survey and records every fixed feature within the UK.',
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.map_mode = 'hybrid';
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        });


        tour.addStep({
            attachTo: {element: '.el-block--location-layers', on: 'top'},
            title: 'Overlays',
            text: 'Land Monitor offers you the ability to include powerful overlays such as deprivation, greenbelt and more.',
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        })

        tour.addStep({
            attachTo: {element: '.el-block--bottom-left-element', on: 'top'},
            title: 'Birdseye View',
            text: 'View a potential property from a birdseye aerial view.',
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        })

        tour.addStep({
            attachTo: {element: '.el-action--export-data', on: 'top'},
            text: 'The Export Data feature lets you download information regarding the entire search area.',
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        })

        tour.addStep({
            attachTo: {element: '.el-action--launch-workflows', on: 'top'},
            title: 'Workflows',
            text: 'Launch and view your existing workflows.',
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.pins_info_visible = true;
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        })

        tour.addStep({
            attachTo: {element: '.el-block--icon-overlay-info', on: 'top'},
            title: 'Overlays Info',
            text: 'Overlays information gives you a reference and details on what icons mean on the map.',
            buttons: [
                {
                    action() {
                        return this.cancel();
                    },
                    classes: 'shepherd-cancel-btn',
                    text: 'Cancel'
                },
                {
                    action() {
                        vm.pins_info_visible = false;
                        return this.next();
                    },
                    text: 'Next'
                }
            ]
        })


        /*
           tour.addStep({
             attachTo: { element: '.el-block--filter', on: 'top' },
             text: 'Test',
             buttons: [
               {
                 action() {
                   let _ = this;
                   vm.filter.show = true;
                   setTimeout(()=> {
                     return _.next();
                   }, 1000)
                 },
                 text: 'Next'
               }
             ]
           });

           tour.addStep({
             attachTo: { element: '.el-filter-item--search-radius', on: 'top' },
             text: 'Test',
             buttons: [
               {
                 action() {
                   return this.next();
                 },
                 text: 'Next'
               }
             ]
           });

           tour.addStep({
             attachTo: { element: '.el-filter-item--additional-pins', on: 'top' },
             text: 'Test',
             buttons: [
               {
                 action() {
                   return this.next();
                 },
                 text: 'Next'
               }
             ]
           });

           tour.addStep({
             attachTo: { element: '.el-filter-item--ownership', on: 'top' },
             text: 'Test',
             buttons: [
               {
                 action() {
                   return this.next();
                 },
                 text: 'Next'
               }
             ]
           });

           tour.addStep({
             attachTo: { element: '.el-filter-item--property-size', on: 'top' },
             text: 'Test',
             buttons: [
               {
                 action() {
                   return this.next();
                 },
                 text: 'Next'
               }
             ]
           });

           tour.addStep({
             attachTo: { element: '.el-filter-item--buildings-footprint', on: 'top' },
             text: 'Test',
             buttons: [
               {
                 action() {
                   return this.next();
                 },
                 text: 'Next'
               }
             ]
           });

           tour.addStep({
             attachTo: { element: '.el-filter-item--additional-filters', on: 'top' },
             text: 'Test',
             buttons: [
               {
                 action() {
                   return this.next();
                 },
                 text: 'Next'
               }
             ]
           });


           tour.addStep({
               attachTo: { element: '.el-block--bottom-left-element', on: 'top' },
               text: 'Test',
               buttons: [
                   {
                       action() {
                           return this.next();
                       },
                       text: 'Next'
                   }
               ]
           })

           tour.addStep({
               attachTo: { element: '.el-block--location-layers', on: 'top' },
               text: 'Test',
               buttons: [
                   {
                       action() {
                           return this.next();
                       },
                       text: 'Next'
                   }
               ]
           });

       */

        const closeEvents = ['close', 'cancel'], startEvents = ['start'];

        closeEvents.forEach(event => tour.on(event, () => {
            // some code here
            _.reset(vm, {
              location: currentLocation,
              zoom: currentZoom,
              mapMode: currentMapView,
            });
            _.removeOverlay();
        }));


        startEvents.forEach(event => tour.on(event, () => {
            // some code here
            _.addOverlay();
        }));


        tour.start();
    }


    reset(vm, opts) {
      console.log(vm, opts)
    }

    removeOverlay() {
        const elem = document.getElementById("el-block--shepered-overlay-no-select");
        if(elem){
            elem.parentNode.removeChild(elem);
        }
    }

    addOverlay() {
        let el = document.createElement('div');
        el.id = 'el-block--shepered-overlay-no-select';
        el.style.cssText = 'position: fixed; width: 100%; height: 100%; z-index: 9001; top: 0; left: 0;';
        document.body.appendChild(el);
    }

}