<template>
    <div>
        <div class="h-32 w-full"
             v-if="loading"
             v-loading="true"></div>

        <div class="columns pb-4"
             v-if="meta.totalResult && !loading"
        >
            <div class="column flex items-center">
                                <span class="text-sm">
                                  {{ meta.totalResult.toLocaleString() }} applications found. Displaying page {{ meta.current.toLocaleString() }} of {{ meta.pageCount.toLocaleString() }}
                                </span>
            </div>

            <div class="column ml-auto"   v-if="meta.totalResult && !loading">
                <el-pagination
                        background
                        layout="prev, pager, next"
                        @current-change="(page)=>{ fetch(page) }"
                        :page-size="rpp"
                        :current-page="meta.current"
                        :total="meta.totalResult">
                </el-pagination>
            </div>
        </div>



        <el-table
                v-if="results.length > 0"
                class="el--table"
                :data="results"
                :default-sort="default_sort"
                @sort-change="onSortChange"
                style="width: 100%">
            <el-table-column
                    prop="proposal.keyword"
                    sortable="custom"
                    label="Proposal">
                <template slot-scope="scope">

                    <div class="break-normal text-xs leading-tight">
                        <p class="font-medium text-blue-700 leading-loose truncate w-full">{{ scope.row.proposal }}</p>
                        <p class="pt-1"> {{ scope.row.local_authority }} - {{ scope.row.site_address }}</p>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                    width="160"
                    prop="status.keyword"
                    sortable="custom"
                    label="Status">
                <template slot-scope="scope">
                                          <span class="w-2 h-2 inline-block rounded-full"
                                                :class="{
                                        'bg-green-500': scope.row.status_simplified === 'DECIDED',
                                        'bg-blue-500': scope.row.status_simplified === 'PENDING',
                                        'bg-gray-500': scope.row.status_simplified === 'WITHDRAWN'
                                        }"
                                          ></span>
                    <span class="flex-grow ml-1">{{ scope.row.status }}</span>
                </template>
            </el-table-column>
            <el-table-column
                    width="130"
                    prop="decision.keyword"
                    sortable="custom"
                    label="Decision">
                <template slot-scope="scope">

                                        <span class="w-2 h-2 inline-block rounded-full"
                                              :class="{
                                        'bg-red-500': scope.row.decision_simplified === 'REFUSED',
                                        'bg-green-500': scope.row.decision_simplified === 'APPROVED',
                                        'bg-blue-500': scope.row.decision_simplified === 'PENDING',
                                        'bg-gray-500': scope.row.decision_simplified === 'WITHDRAWN',
                                        'bg-orange-500': scope.row.decision_simplified === 'SPLIT'
                                        }"
                                        ></span>
                 <span class="flex-grow ml-1">{{ scope.row.decision }}</span>
                </template>
            </el-table-column>
            <el-table-column
                    width="140"
                    prop="date_received"
                    sortable="custom"
                    label="Date Received">
                <template slot-scope="scope">
                        <date-human-view v-model="scope.row.date_received" />
                </template>
            </el-table-column>
            <el-table-column
                    width="140"
                    prop="date_decided"
                    sortable="custom"
                    label="Date Decided">
                <template slot-scope="scope">
                    <date-human-view v-model="scope.row.date_decided" />
                </template>
            </el-table-column>

            <el-table-column
                    fixed="right"
                    align="right"
                    width="120"
            >
                <template slot-scope="scope">



                    <el-dropdown @command="(cmd)=>{ handleCommand(cmd, scope.row.id)  }">
                        <el-button size="medium"

                                   :type="scope.row.watching ? 'warning' : 'success'">
                            Actions <i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="view">
                                View
                            </el-dropdown-item>
                            <el-dropdown-item
                                    :disabled="scope.row.watching"
                                    command="watch">
                                Watch
                            </el-dropdown-item>
                            <el-dropdown-item
                                    :disabled="!scope.row.watching"
                                    command="unwatch">
                                Un-watch
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>


                </template>
            </el-table-column>


        </el-table>


        <div class="columns pt-4"
             v-if="meta.totalResult && !loading"
        >
            <div class="column flex items-center">
                                <span class="text-sm">
                                  {{ meta.totalResult.toLocaleString() }} applications found. Displaying page {{ meta.current.toLocaleString() }} of {{ meta.pageCount.toLocaleString() }}
                                </span>
            </div>
            <div class="column ml-auto">
                <el-pagination
                        background
                        layout="prev, pager, next"
                        @current-change="(page)=>{ fetch(page) }"
                        :page-size="rpp"
                        :current-page="meta.current"
                        :total="meta.totalResult">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ApplicationTable",
        props: {
            filters: {
                type: Object,
                default(){
                    return {}
                }
            },
            sort: {
                type: Object,
                default() {
                    return null;
                }
            }
        },
        created(){
          this.fetch(1);
        },
        data(){
            return {
                results: [],
                rpp: 5,
                loading: true,
                default_sort: this.sort,
                meta: {}
            }
        },
        methods: {
            handleCommand(cmd, id){
                const vm = this;
                switch(cmd){
                    case "view":
                        vm.$router.push('/planning-application/' + id )
                        break;
                    case "watch":
                        vm.toggleWatchlist(false, id);
                        break;
                    case "unwatch":
                        vm.toggleWatchlist(true, id);
                        break;
                }
            },
            toggleWatchlist(watching, hash) {

                let vm = this;
                if (watching) {
                    this.$store.dispatch('watchlist/remove', {
                        hash: hash
                    }).then(() => {
                        vm.refreshWatch();
                        vm.$notify({
                            type: 'success',
                            title: 'Application Removed',
                            message: 'Application removed from watchlist',
                            position: 'top-right',
                            offset: 140,
                        });
                    })
                } else {
                    this.$store.dispatch('watchlist/add', {
                        hash: hash
                    }).then(() => {
                        vm.refreshWatch();
                        vm.$notify({
                            type: 'success',
                            title: 'Application Added',
                            message: 'Application added to watchlist',
                            position: 'top-right',
                            offset: 140,
                        });
                    })
                }
            },
            refreshWatch(){
                let vm = this;
                if(vm.meta && vm.meta.current){
                    console.log('refresh')
                    let page = vm.meta.current;
                    vm.meta = {};
                    vm.results = [];
                    vm.loading = true;
                    setTimeout(()=>{
                        vm.fetch(page)
                    }, 1000)
                }
            },
            onSortChange(object){
                if(object.prop && object.order){
                    this.default_sort = object;
                }else{
                    this.default_sort = null;
                }
                this.fetch(1);
            },

            fetch(page){
                let vm = this;
                vm.meta = {};
                vm.results = [];
                vm.loading = true;
                vm.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/applications', {
                    params: Object.assign( this.filters, {
                        page: page || 1,
                        rpp: vm.rpp,
                        sort: vm.default_sort && vm.default_sort.prop ? vm.default_sort.prop : null,
                        order: vm.default_sort && vm.default_sort.order ? vm.default_sort.order === 'descending' ? 'desc' : 'asc' : null,
                    })
                }).then((response) => {
                    vm.results = response.data.results;
                    vm.meta = response.data.meta;
                    vm.loading = false;
                }).catch(() => {
                    vm.loading = false;
                })
            }
        }
    }
</script>

<style scoped>

</style>