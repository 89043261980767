import api from "../../utils/api";

const initialState = {

};

export default {
    namespaced: true,
    state: initialState,
    mutations: {
        reset(state) {
            console.log('reset')
            Object.keys(initialState).forEach(key => {
                state[key] = initialState[key]
            })
        },
    },
    actions: {
        outstanding: () => {
            return api.get('/api/v1/auth/outstanding-invites');
        },
        index: () => {
            return api.get('/api/v1/auth/invites');
        },
        updateOutstanding:({rootState}, params) => {
            console.log(rootState);
            return api.post('/api/v1/auth/outstanding-invites', {
                invites: params.invites
            });
        },

        join:({rootState}, params) => {
            console.log(rootState);
            return api.post('/api/v1/auth/invite/' + params.inviteId);
        },

    }
}