<template>
    <div>

        <div class="flex flex-wrap py-2 w-full">
            <label class="uppercase block text-xs text-gray-800 mb-2">Name</label>
            <div class="w-full flex-none">
                <el-input placeholder="e.g. London Sites"
                          :disabled="running"
                          v-model="model.name"/>
            </div>
        </div>

        <div class="text-right my-2">
            <el-button type="info"
                       :disabled="running"
                       :size="btnSize"
                       @click="addNewWorkflowColumn"
                       icon="el-icon-plus">Add Column
            </el-button>
        </div>

        <empty-state
                v-if="!workflow_columns.length"
                :height="100"
        title="You Currently have no states"
        message="This workflow currently has no states. Click Add Column to add a state above."
        />

        <div
                v-if="workflow_columns.length > 0"
                class="block--el-draggable-columns border border-gray-200 bg-white overflow-hidden mt-4 rounded-md">
            <draggable v-model="workflow_columns">
                <transition-group>
                    <div
                            class="p-2 border-b border-gray-200 bg-white"
                            v-for="(element, i) in workflow_columns" :key="'workflow-column-' + i">
                        <div class="columns">
                            <div class="column flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     class="el-drag--handler cursor-pointer"
                                     width="15" height="15" viewBox="0 0 24 24"><path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z"/></svg>
                            </div>
                            <div class="column flex items-center justify-center">
                                <span>{{ i + 1 }}</span>
                            </div>
                            <div class="column flex-grow">
                                <el-input
                                        :disabled="running"
                                        :size="btnSize"
                                        v-model="element.name" />
                            </div>
                            <div class="column">
                                <el-color-picker
                                        :size="btnSize"
                                        :disabled="running"
                                        v-model="element.color"
                                        :predefine="[
          '#ff4500',
          '#ff8c00',
          '#ffd700',
          '#90ee90',
          '#00ced1',
          '#1e90ff',
          '#c71585',
          '#c7158577'
        ]">
                                </el-color-picker>
                            </div>
                            <div class="column">
                                <el-button type="danger"
                                           :disabled="running || element.items > 0"
                                           @click="removeWorkflowColumn(i)"
                                           icon="el-icon-delete" :size="btnSize">Remove</el-button>
                            </div>
                        </div>

                    </div>
                </transition-group>
            </draggable>



        </div>

        <div class="columns mt-4">
            <div class="column items-center justify-center ml-auto">
                <el-link v-if="showClose"
                        @click="$emit('close')"
                        :disabled="running"
                >Close</el-link>
            </div>
            <div class="column items-center justify-center">
                <el-button
                        v-if="id === null"
                        type="success"
                           :disabled="running"
                           :loading="running"
                           @click="createNewWorkflow"
                           icon="el-icon-plus" :size="btnSize">
                    Create Workflow
                </el-button>

                <el-button
                        v-if="id !== null"
                        type="success"
                        :disabled="running"
                        :loading="running"
                        @click="updateWorkflow"
                        icon="el-icon-refresh" :size="btnSize">
                    Update Workflow
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import Draggable from 'vuedraggable'
    import EmptyState from "./../EmptyState";

    export default {
        name: "WorkflowCreateEditForm",
        props: {
            id: {
                type: String,
                default(){
                    return null;
                }
            },
            columns: {
              type: Array,
              default(){
                  return [];
              }
            },
            name: {
                type: String,
                default(){
                    return null;
                }
            },
            btnSize: {
              type: String,
              default(){
                  return 'small'
              }  
            },
            showClose: {
                type: Boolean,
                default(){
                    return true;
                }
            },
        },
        components: {
            EmptyState,
            Draggable
        },
        data(){
            return {
                running: false,
                model: {
                    id: this.id,
                    name: this.name,
                },
                workflow_columns: this.columns
            }
        },
        watch: {
            running(){
                this.$emit('running', this.running);
            },
            id(val){
                this.model.id = val;
            },
            name(val){
                this.model.name = val;
            },
            columns(val){
                this.workflow_columns = JSON.parse(JSON.stringify(val));
            }
        },
        methods: {
            reset(){
                this.model = {
                    id: null,
                    name: null
                };
                this.workflow_columns = [];
            },
            createNewWorkflow(){
                const vm = this;
                vm.running = true;
                vm.$emit('loading', true);
                return vm.$store.dispatch('workflow/create', {
                    name: vm.model.name,
                    columns: vm.workflow_columns
                }).then((response)=>{
                    vm.running = false;
                    vm.$emit('close');
                    vm.$emit('loading', false);
                    vm.$emit('created', response.data.data.id);
                    vm.$notify({
                        position: 'top-right',
                        offset: 140,
                        title: 'Workflow Created',
                        message: 'New workflow created successfully.',
                        type: 'success',
                    });

                }).catch(()=>{
                    vm.$emit('loading', false);
                    vm.running = false;
                    vm.$emit('close');
                })
            },
            updateWorkflow(){
                const vm = this;
                vm.running = true;
                return vm.$store.dispatch('workflow/update', {
                    workflowId: vm.model.id,
                    name: vm.model.name,
                    columns: vm.workflow_columns
                }).then(()=>{
                    vm.running = false;

                    vm.$emit('updated', vm.model.id);
                    vm.$emit('close');


                    vm.$notify({
                        position: 'top-right',
                        title: 'Workflow Updated',
                        message: 'Workflow updated successfully.',
                        type: 'success',
                        offset: 140
                    });

                }).catch(()=>{
                    vm.running = false;
                    vm.$emit('close');
                })
            },
            removeWorkflowColumn(index){
                this.workflow_columns.splice(index, 1);
            },
            addNewWorkflowColumn(){
                this.workflow_columns.push({
                    name: 'Column',
                    color: '#1e90ff',
                    items: 0
                })
               this.workflow_columns = JSON.parse(JSON.stringify(this.workflow_columns));
            },
        }
    }
</script>

<style scoped>

</style>