<template>

    <div>


        <div
                v-if="loading"
                class="flex h-full mt-12 pt-12 items-center justify-center">
            <div class="p-3 text-center">
                <property-loader />
                <h3 class="font-semibold text-blue-800">Fetching EPC Ratings...</h3>
            </div>
        </div>
        <div class="columns">

            <div class="column w-full" v-if="!loading">
                <dashboard-widget
                        padding-class="''"
                        title="EPC Ratings">
                    <slot>

                        <div class="p-3" v-if="!propertiesList.length">
                            <empty-state />
                        </div>


                        <div
                                class="el-collapse--epc-ratings"
                                v-if="propertiesList.length > 0">

                            <div class="p-2">
                                <el-alert
                                        effect="dark" type="success" :closable="false">
                                    {{ propertiesList.length }} EPCs within this site
                                </el-alert>
                            </div>

                            <el-collapse v-model="activeProperties">
                                <el-collapse-item
                                        :key="item.id"
                                        v-for="(item) of value"
                                        :title="item.single_line_address" :name="item.single_line_address">

                                    <template slot="title">
                                        <div class="flex flex-wrap w-full flex-row">
                                            <div class="w-full py-2 pb-3">
                                                {{ item.single_line_address }}
                                            </div>
                                            <div class="text-xs w-full flex">
                                                <div class="mr-3">
                                                    <span>Current rating: {{ item.current_energy_rating }}</span>
                                                </div>
                                                <div class="mr-3" v-if="item.potential_energy_rating">
                                                    <span>Potential rating: {{ item.potential_energy_rating }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>

                                    <div class="p-4">
                                        <div class="columns flex-no-wrap">
                                            <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                                                Rating
                                            </div>
                                            <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                                {{ item.current_energy_rating }}
                                            </div>
                                        </div>
                                        <div class="columns flex-no-wrap">
                                            <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                                                Potential Rating
                                            </div>
                                            <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                                {{ item.potential_energy_rating ? item.potential_energy_rating : 'Not recorded'  }}
                                            </div>
                                        </div>

                                        <div class="columns flex-no-wrap">
                                            <div class="column mr-auto text-xs text-gray-500 w-2/3 font-medium flex">
                                                Last Inspected
                                            </div>
                                            <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                                <date-human-view size="text-xs" v-model="item.last_inspected" />
                                            </div>
                                        </div>
                                    </div>

                                </el-collapse-item>
                            </el-collapse>


                        </div>


                    </slot>
                </dashboard-widget>
            </div>


        </div>

    </div>


</template>

<script>
    import EmptyState from "../../EmptyState";
    import PropertyLoader from "../../incs/PropertyLoader";
    export default {
        name: "OwnershipEpc",
        components: {PropertyLoader, EmptyState},
        props: {
            value: {
                type: Array,
                default(){
                    return []
                }
            },
            activeSiteId: {
                type: Number,
                default(){
                    return null;
                }
            },
            loading: {
                type: Boolean,
                default(){
                    return true;
                }
            }
        },
        computed: {
            propertiesList(){
                if(this.activeSiteId){
                    return this.value.filter((i)=>{
                        return i.site_id === this.activeSiteId;
                    })
                }
                return this.value
            },
        },
        data(){
            return {
                activeProperties: []
            }
        }
    }
</script>

<style lang="scss">
    .el-collapse--epc-ratings  .el-collapse-item__header {
        line-height: 15px !important;
        height: auto !important;
        padding: 10px !important;
    }

    .el-collapse--epc-ratings .el-collapse-item__content {
        padding-bottom: 0;
    }
</style>