<template>
        <div class="flex w-full">
            <div class="flex-none w-32">
                    <filepond-avatar
                            :size="10"
                            @change="(val)=>{ team.avatar = val; }"
                            v-model="team.avatar"
                    />
            </div>
            <div class="flex-grow pl-10">
                <el-form label-position="left" label-width="120px"
                         :rules="rules" ref="teamCreateForm"
                         :model="team">
                    <el-form-item label="Team Name" prop="name">
                        <el-input v-model="team.name"
                                  :disabled="creating"
                        />
                    </el-form-item>

                </el-form>
                <div class="mt-8 flex justify-end">
                    <el-button
                            @click="preCreate"
                            :disabled="creating"
                            :loading="creating"
                            type="success" icon="el-icon-plus">Create Team</el-button>
                </div>
            </div>
        </div>
</template>

<script>

    import FilepondAvatar from "../../components/FilepondAvatar";

    export default {
        name: "CreateTeamComponent",
        components: {FilepondAvatar},
        data() {
            return {
                validators: {
                  form: false,
                  card: false
                },
                token: {
                    generating: false,
                    value: null
                },
                team: {
                    avatar: null,
                    name: null,
                    card: 'null',
                    billing: {
                        name: null
                    },
                    plan: 'PLAN_1'
                },
                pricing_plans: [
                    {id: 'PLAN_1', name: 'Yearly', price: 999, term: 'year', description: 'Our most popular package with 12 months for the price of 10.'},
                    {id: 'PLAN_2', name: 'Monthly', price: 99, term: 'month', description: 'For those looking to try out Land Monitor.'},
                ],
                creating: false,
                rules: {
                    name: [
                        {required: true, message: 'A name is required', trigger: 'blur'},
                    ],
                    card: [
                        {required: true, message: '', trigger: 'none'},
                    ],
                    plan: [
                        {required: true, message: '', trigger: 'blur'},
                    ]
                }
            }
        },
        methods: {
            preCreate(){
                let vm = this;
                vm.creating = true;
                vm.$refs['teamCreateForm'].validate((valid) => {
                    if (!valid) {
                        vm.creating = false;
                        return false;
                    } else {
                        this.create();
                    }
                });
            },
            onStripeError(){
                let vm = this;
                vm.creating = false;
                vm.validators.form = false;
                vm.token.generating = false;
            },
            tokenCreated (token) {
                // handle the token
                // send it to your server
                if(token && token.id){
                    this.create(token.id);
                }
            },
            create(){
                let vm = this;
                vm.creating = true;

                vm.$http.post('/api/v1/teams', {
                    avatar: vm.team.avatar,
                    name: vm.team.name,
                })
                    .then(() => {

                        vm.$http.get('/api/v1/auth/user')
                            .then((response) => {
                                vm.$store.commit('auth/setUser', response.data.data);
                                vm.$store.state.team.visible = false;
                                location.reload();
                            }).catch((e)=>{
                            vm.$store.dispatch('auth/handleServerError', {
                                vm: vm,
                                error: e
                            });
                        });

                    })
                    .catch((e) => {
                        vm.creating = false;

                        vm.$store.dispatch('auth/handleServerError', {
                            vm: vm,
                            error: e
                        });
                    })


            }
        },
    }
</script>

<style>
    #stripe-element-errors {
        font-size: 0.8rem;
        color: #F56C6C;
    }
</style>