exports.broadbandCoverageTable = (object) => {
    return [
        {type: 'Standard',
            download_speed: object.max_adsl_predicted_down,
            upload_speed: object.max_adsl_predicted_up,
            availability: object.adsl_availability },

        {type: 'Super-fast',
            download_speed: object.max_sfbb_predicted_down,
            upload_speed: object.max_sfbb_predicted_up,
            availability: object.sfbb_availability },

        {type: 'Ultra-fast',
            download_speed: object.max_ufbb_predicted_down,
            upload_speed: object.max_ufbb_predicted_up,
            availability: object.ufbb_availability },
    ];
}

exports.getClassForMobileCoverage = (rating) => {
    let className = 'block mx-auto w-6 h-6 text-white rounded-full flex items-center justify-center';
    switch(rating){
        case  'A':
            return  className + ' bg-orange-600';
        case  'G':
            return  className + ' bg-green-600';
    }
    return className + ' bg-red-600';
}