<template>
    <header class="bg-white shadow-sm">
        <div class="max-w-7xl mx-auto pt-4 px-4 sm:px-6 lg:px-8">
            <div class="columns w-full no-wrap py-2 items-center">
                <div class="column w-full truncate flex-grow-0 lg:flex-grow">
                    <h1 class="text-lg truncate leading-6 py-2 font-semibold text-gray-900">
                        {{ title }}
                    </h1>
                </div>
                <div class="column">
                    <slot name="actions">
                    </slot>
                </div>

            </div>
        </div>
        <slot name="tabs"></slot>
    </header>
</template>

<script>
    export default {
        name: "PageHeader",
        props: {
            title: {
                type: String,
                default: 'Title'
            }
        },
    }
</script>

<style scoped>
    .no-wrap {
        flex-wrap: nowrap !important;
    }
</style>