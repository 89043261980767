<template>
    <div class="bg-white" v-loading="isLoading">
        <div class="px-6 pt-6 pb-4">
            <h2 class="text-lg font-medium text-gray-900">
                {{ title }} >> {{ isLoading }}
            </h2>
            <p class="mt-1 text-sm text-gray-600">
                Search from {{ data.length.toLocaleString() }} results
            </p>
            <div class="mt-4">
                <el-input
                        v-model="search"
                        prefix-icon="el-icon-search"
                />
            </div>
        </div>
        <nav class="flex-1 min-h-0 overflow-y-auto bg-white el-directory--block"
             aria-label="Directory">

            <div class="relative"
                 :id="'view-' + k"
                 :key="k"
                 v-for="(i, k) in  categorisedFiltered">
                <div class="z-10 sticky top-0 border-t border-b border-gray-200 bg-gray-100 px-6 py-1 text-sm font-medium text-gray-500">
                    <h3>{{ k }}</h3>
                </div>
                <ul class="relative z-0 divide-y divide-gray-200">

                    <li
                            class="cursor-pointer hover:bg-gray-700 text-gray-900 hover:text-white"
                            @click="$emit('change', item.id)"
                            :key="item.id"
                            v-for="item of i">
                        <div class="relative px-6 py-5 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-pink-500">
                            <div class="flex-1 min-w-0">
                                    <!-- Extend touch target to entire panel -->
                                    <span class="absolute inset-0" aria-hidden="true"></span>
                                    <p class="text-sm font-medium">
                                        {{ item.name }}
                                    </p>
                            </div>
                        </div>
                    </li>


                </ul>
            </div>

        </nav>
    </div>
</template>

<script>
    export default {
        name: "PropertySearchList",
        props: {
            loading: {
              type: Boolean,
              default(){
                  return true;
              }
            },
            title: {
                type: String,
                default: 'Districts'
            },
            url: {
                type: String,
                default: '/api/v1/types/postcode-districts'
            }
        },
        created(){
            this.fetch()
        },
        data(){
            return {
                data: [],
                search: '',
                loadingOptions: true
            }
        },
        computed: {
            isLoading(){
              return this.loadingOptions === true;
            },
            letters() {
                let letters = []
                for(let i = "A".charCodeAt(0); i <= "Z".charCodeAt(0); i++) {letters.push(String.fromCharCode([i]))}
                return letters
            },
            categorisedFiltered(){
                let items = {};
                for(let item of this.data){
                    if(
                        item.name.toLowerCase().includes(this.search) ||
                        (!this.search || !this.search.length || !this.search.toString().trim().length)
                    ){
                        let first = item.name.charAt(0).toString();
                        if(!items[first]){
                            items[first] = [];
                        }
                        items[first].push(item);
                    }
                }

                return Object.keys(items).sort().reduce((acc, key) => {
                    acc[key] = items[key];
                    return acc;
                }, {});
            }
        },
        methods: {
            fetch(){
                let vm = this;
                vm.loadingOptions = true;
                vm.$http.get(vm.url)
                    .then((response) => {
                        vm.data = response.data;
                        vm.$emit('change', vm.data[1].id);
                        vm.loadingOptions = false;
                    })
                    .catch(() => {
                        vm.loadingOptions = false;
                    })
            },
        }
    }
</script>

<style>
    .el-directory--block {
        height: calc(100vh - 328px);
    }
</style>