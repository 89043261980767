import moment from 'moment';

export function getDefaultDateOptions() {

    return {
        disabledDate(time) {
            return time.getTime() > moment().startOf('day');
        },
        shortcuts: [
            {
                text: 'Last 7 days',
                onClick(picker) {
                    console.log('Last 7 days')
                    picker.$emit('pick', [
                        moment().startOf('day').subtract(7, 'day').toDate(),
                        moment().endOf('day').toDate()
                    ]);
                }
            },
            {
                text: 'Last 14 days',
                onClick(picker) {
                    picker.$emit('pick', [
                        moment().startOf('day').subtract(14, 'day').toDate(),
                        moment().endOf('day').toDate()
                    ]);
                }
            },
            {
                text: 'Last 30 days',
                onClick(picker) {
                    picker.$emit('pick', [
                        moment().startOf('day').subtract(30, 'day').toDate(),
                        moment().endOf('day').toDate()
                    ]);
                }
            },
            {
                text: 'Last 90 days',
                onClick(picker) {
                    console.log('jere');
                    picker.$emit('pick', [
                        moment().startOf('day').subtract(90, 'day').toDate(),
                        moment().endOf('day').toDate()
                    ]);
                }
            },
            {
                text: 'Last 180 days',
                onClick(picker) {
                    picker.$emit('pick', [
                        moment().startOf('day').subtract(180, 'day').toDate(),
                        moment().endOf('day').toDate()
                    ]);
                }
            },
            {
                text: 'This month',
                onClick(picker) {
                    picker.$emit('pick', [
                        moment().startOf('month').toDate(),
                        moment().endOf('day').toDate()
                    ]);
                }
            },
            {
                text: 'Last month',
                onClick(picker) {
                    picker.$emit('pick', [
                        moment().subtract(1, 'month').startOf('month').toDate(),
                        moment().subtract(1, 'month').endOf('month').toDate()
                    ]);
                }
            },
            {
                text: 'YTD',
                onClick(picker) {
                    picker.$emit('pick', [
                        moment().startOf('year').toDate(),
                        moment().endOf('day').toDate()
                    ]);
                }
            }
        ]
    };
}