<template>
    <div class="flex flex-wrap py-1 w-full el-filter-item--ownership">
        <label class="uppercase block text-xs text-gray-800 mb-1">{{ title }}</label>
        <div class="w-full flex-none">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FilterComponent",
        props: {
            title: {
                type: String,
                default(){
                    return ''
                }
            }
        }
    }
</script>

<style scoped>

</style>