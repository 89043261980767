<template>
    <div>
        <div class="py-2" v-if="value.directors && value.directors.length > 0">
            <div
                    :key="i"

                    class="mb-2"
                    v-for="(d, i) of value.directors">

                <h4 class="text-sm text-xs mb-1 justify justify-center font-medium mb-2">
                    <span class="p-1 text-xxs uppercase bg-green-600 rounded text-white">Active</span>
                    <span class="ml-1">
                                           {{ d.name }}
                                       </span>
                </h4>

                <div class="text-xs text-gray-500 font-medium">
                    {{ d.description_string }}
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CorporateOwnershipDirectors",
        props: {
            value: {
                type: Object,
                default(){
                    return {}
                }
            }
        }
    }
</script>

<style scoped>

</style>