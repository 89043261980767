<template>
    <el-timeline>
        <el-timeline-item
                v-for="(activity, index) in $store.getters['applications/buildTimeline']"
                :key="index"
                :timestamp="activity.timestamp">
            {{activity.content}}
        </el-timeline-item>
    </el-timeline>
</template>

<script>
    export default {
        name: "ApplicationTimeline"
    }
</script>

<style scoped>

</style>