import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)


import alert from './modules/alert'
import auth from './modules/auth'
import applications from './modules/applications'
import watchlist from './modules/watchlist'
import applicationMap from './modules/applicationMap'
import ods from './modules/ods'
import propertyPrices from './modules/propertyPrices'
import ownership from './modules/ownership'
import map from './modules/map';
import workflow from './modules/workflow';
import workflowColumn from './modules/workflowColumn';
import workflowItem from './modules/workflowItem';
import job from './modules/job';
import commercialAvailability from './modules/commercialAvailability';
import search from './modules/search';
import team from './modules/team';
import teamMember from './modules/teamMember';
import teamSeat from './modules/teamSeat';
import teamInvite from './modules/teamInvite';
import invites from './modules/invites';
import epc from './modules/epc';
import report from './modules/report';

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    map,
    epc,
    applications,
    applicationMap,
    alert,
    watchlist,
    ods,
    job,
    auth,
    team,
    invites,
    teamMember,
    teamSeat,
    teamInvite,
    propertyPrices,
    ownership,
    workflow,
    workflowColumn,
    workflowItem,
    commercialAvailability,
    search,
    report
  },
  plugins: [
    createPersistedState({
      key: 'planning'
    })
  ]
})
