<template>

    <page>

        <template slot="header">
            <page-header title="LandHawk Jobs">
                <template v-slot:tabs>
                    <land-hawk-nav/>
                </template>
            </page-header>
        </template>

        <template slot="content">
            <div class="columns">
                <div class="column w-full">
                    <dashboard-widget
                            title="LandHawk Jobs"
                            padding-class="">

                        <empty-state class="p-3"
                                     title="No Jobs found"
                                     message="Sorry, but we could not find any LandHawk Jobs"
                                     :support="false"
                                     v-if="!table.loading && !table.results.length"/>


                        <div class="p-3 py-12 text-center" v-if="table.loading">
                            <property-loader/>
                        </div>


                        <div v-if="table.results.length > 0 && !table.loading">
                            <div class="p-3">
                                <el-alert effect="dark" type="info" :closable="false">
                                    {{ table.meta.totalResult.toLocaleString() }} jobs found. Displaying page {{
                                    table.meta.current }} of {{ table.meta.pageCount }}
                                </el-alert>
                            </div>
                            <div class="columns p-3">
                                <div class="column ml-auto">
                                    <el-pagination
                                            background
                                            layout="prev, pager, next"
                                            @current-change="(page)=>{ fetch(page) }"
                                            :page-size="table.rpp"
                                            :current-page="table.meta.current"
                                            :total="table.meta.totalResult">
                                    </el-pagination>
                                </div>
                            </div>
                            <el-table :data="table.results">
                                <el-table-column label="Name">
                                    <template slot-scope="scope">
                                      <span class="font-bold">
                                            {{ scope.row.name }}
                                      </span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Location">
                                    <template slot-scope="scope">
                                        {{ scope.row.locality }}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        width="120"
                                        align="right"
                                        label="Radius">
                                    <template slot-scope="scope">
                                        {{ formatRadius(scope.row.radius) }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Filters">
                                    <template slot-scope="scope">
                                        <filter-breakdown
                                                v-model="scope.row.params"
                                                :exclude-location="true"
                                        />
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        width="120"
                                        align="center"
                                        label="Status">
                                    <template slot-scope="scope">
                                                      <span class="tag text-xs"
                                                            :class="{
                                            'is-success': scope.row.status === 'COMPLETE',
                                            'is-danger': scope.row.status === 'FAILED',
                                            'is-info': scope.row.status === 'RUNNING',
                                            'is-warning': scope.row.status === 'QUEUED',
                                            }"
                                                      >{{ scope.row.status }}</span>

                                    </template>
                                </el-table-column>
                                <el-table-column
                                        width="90"
                                        align="right"
                                        label="Results">
                                    <template slot-scope="scope">
                                        {{ scope.row.results ? scope.row.results.toLocaleString() : 0 }}
                                    </template>
                                </el-table-column>

                                <el-table-column width="180" align="right">
                                    <template slot-scope="scope">

                                        <el-button
                                                v-if="scope.row.status === 'COMPLETE' || scope.row.status === 'RUNNING'"
                                                :disabled="scope.row.status === 'RUNNING'"
                                                @click="$router.push('/land-hawk-job/' + scope.row.id)"
                                                size="small" type="success">View
                                        </el-button>

                                        <el-button size="small"
                                                   @click="confirmDelete(scope.row.id)"
                                                   v-if="scope.row.status === 'COMPLETE' || scope.row.status === 'FAILED'"
                                                   type="danger">Remove
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="columns p-3">
                                <div class="column ml-auto">
                                    <el-pagination
                                            background
                                            layout="prev, pager, next"
                                            @current-change="(page)=>{ fetch(page) }"
                                            :page-size="table.rpp"
                                            :current-page="table.meta.current"
                                            :total="table.meta.totalResult">
                                    </el-pagination>
                                </div>
                            </div>
                        </div>


                    </dashboard-widget>
                </div>
            </div>
        </template>

    </page>


</template>
<script>
    import PropertyLoader from "../components/incs/PropertyLoader";
    import EmptyState from "../components/EmptyState";
    import LandHawkNav from "./LandHawk/LandHawkNav";
    import FilterBreakdown from "../components/incs/FilterBreakdown";

    export default {
        name: 'LandHawk',
        components: {FilterBreakdown, LandHawkNav, EmptyState, PropertyLoader},
        methods: {
            confirmDelete(id) {
                let vm = this;

                vm.$confirm(
                    'Are you sure you want to delete this LandHawk Job? This cannot be undone.',
                    'Delete LandHawk Job?',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel'
                    })
                    .then(() => {
                        vm.table.loading = true;

                        vm.$store.dispatch('job/remove', {
                            jobId: id
                        })
                            .then(() => {
                                vm.$notify({
                                    type: 'success',
                                    title: 'LandHawk Job Deleted',
                                    message: 'LandHawk Job deleted successfully.',
                                    position: 'top-right',
                                    offset: 140,
                                });
                                vm.fetch(1);
                            })
                            .catch((e) => {
                                vm.table.loading = false;
                                vm.$store.dispatch('auth/handleServerError', {
                                    vm: vm,
                                    error: e
                                });
                            })

                    }).catch(() => {
                    vm.table.loading = false;
                });

            },
            formatRadius(radius) {
                return radius < 1000 ? radius + 'm' : (radius / 1000) + 'km'
            },
            fetch(page) {
                const vm = this;
                vm.table.loading = true;
                return vm.$store.dispatch('job/index', {
                    params: {
                        page: page || 1,
                        rpp: vm.table.rpp
                    }
                }).then((response) => {
                    vm.table.loading = false;
                    vm.table.results = response.data.data.results;
                    vm.table.meta = response.data.data.meta;
                }).catch(() => {
                    vm.table.loading = false;
                    vm.table.results = [];
                    vm.table.meta = {};
                })

            }
        },
        mounted() {
            this.fetch(1);
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.$store.commit('auth/setBreadcrumbs', [
                    {
                        title: 'LandHawk Jobs'
                    }
                ]);
            })
        },
        data() {
            return {
                table: {
                    loading: true,
                    results: [],
                    rpp: 10,
                    meta: {}
                }
            }
        }
    }
</script>

