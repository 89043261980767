<template>
    <div>

        <el-dialog title="Update Workflow"
                   append-to-body
                   :close-on-press-escape="!updateWorkflow.updating"
                   :close-on-click-modal="!updateWorkflow.updating"
                   :show-close="!updateWorkflow.updating"
                   @close="()=>{ updateWorkflow.visible = false }"
                   :visible.sync="updateWorkflow.visible">


            <workflow-create-edit-form
                    ref="workflowUpdate"
                    :name="updateWorkflow.model.name"
                    :id="value"
                    :columns="updateWorkflow.model.columns"
                    @updated="()=>{ fetch() }"
                    @close="()=>{ updateWorkflow.visible = false }"
            />

        </el-dialog>


        <div class="w-full h-32" v-if="loading"
             :element-loading-background="loaderColor"
             v-loading="true">
        </div>

        <div class="columns mb-2" v-if="!loading">
            <div class="column ml-auto">
                <div class="columns">
                    <div class="column" v-if="!hideEdit">
                        <el-button
                                @click="launchEditModal"
                                type="info" icon="el-icon-edit" size="medium">Edit</el-button>
                    </div>
                    <div class="column" v-if="!hideDeletion">
                        <el-button
                                @click="confirmDeleteWorkflow"
                                type="danger" icon="el-icon-delete" size="medium">Delete</el-button>
                    </div>
                    <div class="column">
                        <export-button
                                :tooltip-disabled="true"
                                :exporting="exporting"
                                :disabled="exporting"
                                @export="(format) => exportData(format) "
                        />
                    </div>
                </div>



            </div>
        </div>

        <div v-if="!loading">

            <empty-state text-color="text-white" v-if="!stages.length"/>
            <kanban-board
                    v-if="stages.length > 0"
                    @update-block="updateBlock"
                    :stages="stages.map(item => item.id)" :blocks="mappedBlocks">
                <div v-for="stage in stages" :slot="stage.id" :key="stage.id">
                    <h2 :style="{
                    'border-color': stage.color
                    }">{{ stage.name }}</h2>
                </div>
                <div v-for="block in mappedBlocks" :slot="block.id" :key="block.id">
                    <div class="p-1">
                        <h3 class="text-xs mt-1 font-semibold leading-snug">
                            {{ block.description }}
                        </h3>
                        <h4 class="text-xs mt-1 uppercase">
                            {{ block.type }} - {{ block.reference }}
                        </h4>
                        <div class="flex w-full mt-1">
                            <div class="px-1 ml-auto">
                                <el-tooltip content="Delete" placement="top">
                                    <i
                                            @click="confirmDeleteItem(block.status, block.id)"
                                            class="text-red-700 hover:text-red-900 hover:font-bold el-icon-delete"></i>
                                </el-tooltip>

                            </div>
                            <div class="px-1">
                                <el-tooltip content="View" placement="top">
                                <i
                                        @click="confirmView({
                                        id: block.id,
                                        titleNumber: block.reference,
                                        inspireId: block.inspire_id,
                                        })"
                                        class="text-blue-700 hover:text-blue-900 hover:font-bold  el-icon-map-location"></i>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </kanban-board>
        </div>



    </div>
</template>

<script>
    import EmptyState from "../EmptyState";
    import ExportButton from "../ExportButton";
    import WorkflowCreateEditForm from "./WorkflowCreateEditForm";
    import { download } from './../../utils/download'

    export default {
        name: "WorkflowKanbanBoard",
        components: {WorkflowCreateEditForm, ExportButton, EmptyState},
        props: {
          value: {
              type: String,
              default(){
                  return null;
              }
          },
            confirmView: {
              type: Function,
                default(){
                  return null
                }
            },
            showDelete: {
              type: Boolean,
                default(){
                  return true
                }
            },
            showEdit: {
                type: Boolean,
                default(){
                    return true
                }
            },
            loaderColor: {
              type: String,
                default(){
                  return '#1a202c';
                }
            }
        },
        data(){
          return {
              exporting: false,
              loading: true,
              details: {},
              stages: [],
              blocks: [],
              updateWorkflow: {
                  visible: false,
                  updating: false,
                  model: {}
              },
          }
        },
        computed: {
            hideDeletion() {
                return !this.showDelete || !(this.details && this.details.can_delete);
            },
            hideEdit() {
                return  !this.showEdit || !(this.details && this.details.can_edit);
            },
            mappedBlocks() {
                return this.blocks.map((item) => {
                    return {
                        id: item.id,
                        status: item.column,
                        description: item.description,
                        type: item.type,
                        reference: item.reference,
                        inspire_id: item.inspire_id,
                    }
                })
            }
        },
        created(){
            this.fetch();
        },
        watch: {
          loading(val){
              this.$emit('loading', val);
          }
        },
        methods: {
            confirmDeleteItem(columnId, itemId){
                const vm = this;
                vm.$confirm(
                    'Are you sure you want to remove this property? This cannot be undone and will be removed from the watchlist.',
                    'Remove Property?',
                    {
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'Cancel'
                    })
                    .then(() => {
                        vm.loading = true;
                        vm.$store.dispatch('workflowItem/remove', {
                            workflowId: vm.value,
                            columnId: columnId,
                            itemId: itemId
                        }).then(()=>{
                            vm.$notify({
                                position: 'top-right',
                                title: 'Property Removed from Workflow',
                                message: 'Property removed from workflow successfully.',
                                type: 'success',
                                offset: 140
                            });
                            vm.fetch();
                        }).catch(()=>{
                            vm.loading = false;
                        })
                    })
                    .catch(() => {
                    })
            },
            exportData(format){

                const vm = this;
                vm.exporting = true;

                vm.$store.dispatch('workflowItem/download', {
                    workflowId: vm.value,
                    format: format,
                }).then((response)=>{

                    vm.exporting = false;
                    download(response.data.data);

                }).catch(()=> {
                    vm.exporting = false;
                })

            },
            launchEditModal(){
                const vm = this;
                vm.updateWorkflow.model = {
                    id: vm.details.name,
                   name: vm.details.name,
                    columns: vm.stages
                };
                vm.updateWorkflow.visible = true;
            },
            confirmDeleteWorkflow(){
                const vm = this;
                vm.$confirm(
                    'Are you sure you want to delete this workflow? This cannot be undone and will be removed permanently.',
                    'Delete Workflow?',
                    {
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'Cancel'
                    })
                    .then(() => {
                        vm.loading = true;
                        vm.$store.dispatch('workflow/delete', {
                            workflowId: vm.value
                        }).then(()=>{
                            vm.$notify({
                                position: 'top-right',
                                title: 'Workflow Deleted',
                                message: 'Workflow deleted successfully.',
                                type: 'success',
                                offset: 140
                            });
                            vm.$emit('deleted')

                        }).catch((e)=>{
                            vm.$store.dispatch('auth/handleServerError', {
                                vm: vm,
                                error: e
                            });
                            vm.loading = false;
                        })

                    })
                    .catch(() => {

                    })
            },
            getWorkflowName(){
                return this.details.name;
            },
            setBreadcrumbs(){
                this.$store.commit('auth/setBreadcrumbs', [
                    {
                        title: 'My Workflows',
                        path: '/workflows'
                    },
                    {
                        title: this.details.name
                    }
                ]);
            },
            updateBlock(blockId, columnId) {

                console.log(blockId, blockId);
                let index = this.blocks.findIndex(block => block.id === blockId);
                this.blocks[index].column = columnId;
                let itemIds = this.blocks.filter(item => item.column === columnId).map(item => item.id);

                this.$store.dispatch('workflowColumn/update', {
                    workflowId: this.value,
                    columnId: columnId,
                    itemIds: itemIds
                });
            },
            fetch(){
                const vm = this;
                vm.loading = true;

                return Promise.all([
                    vm.$store.dispatch('workflow/read', {
                        workflowId: vm.value,
                    }),
                    vm.$store.dispatch('workflowColumn/index', {
                        workflowId: vm.value,
                    }),
                    vm.$store.dispatch('workflowItem/index', {
                        workflowId: vm.value,
                    })
                ]).then(([details, stages, items]) => {

                    vm.details = details.data.data;
                    vm.stages = stages.data.data;
                    vm.blocks = items.data.data;

                    vm.$emit('loaded');

                    vm.loading = false;
                }).catch(() => {
                    vm.$emit('error');
                })
            }
        }
    }
</script>

<style scoped>

</style>