<template>
    <div>
        <div v-if="$store.getters['applications/hasSiteCoords']">

            <div class="columns">

                <div class="column w-full" :class="statBoxClass">
                    <stat-box title="Avg. Price"
                              :loading="$store.state.applications.saleHistory.loading.stats"
                              :prefix="'£'"
                              :value="$store.state.applications.saleStats.avg ? $store.state.applications.saleStats.avg : 0"
                    >
                    </stat-box>
                </div>
                <div class="column w-full" :class="statBoxClass">
                    <stat-box title="Avg. Freehold Price"
                              :loading="$store.state.applications.saleHistory.loading.stats"
                              :prefix="'£'"
                              :value="$store.state.applications.saleStats.freehold ? $store.state.applications.saleStats.freehold : 0"
                    >
                    </stat-box>
                </div>
                <div class="column w-full" :class="statBoxClass">
                    <stat-box title="Avg. Leasehold Price"
                              :loading="$store.state.applications.saleHistory.loading.stats"
                              :prefix="'£'"
                              :value="$store.state.applications.saleStats.leasehold ? $store.state.applications.saleStats.leasehold : 0"
                    >
                    </stat-box>
                </div>
                <div class="column w-full" :class="statBoxClass">
                    <stat-box title="Avg. Detached Price"
                              :loading="$store.state.applications.saleHistory.loading.stats"
                              :prefix="'£'"
                              :value="$store.state.applications.saleStats.detached ? $store.state.applications.saleStats.detached : 0"
                    >
                    </stat-box>
                </div>
                <div class="column w-full" :class="statBoxClass">
                    <stat-box title="Avg. Semi-detached Price"
                              :loading="$store.state.applications.saleHistory.loading.stats"
                              :prefix="'£'"
                              :value="$store.state.applications.saleStats.semi_detached ? $store.state.applications.saleStats.semi_detached : 0"
                    >
                    </stat-box>
                </div>
                <div class="column w-full" :class="statBoxClass">
                    <stat-box title="Avg. Terraced Price"
                              :loading="$store.state.applications.saleHistory.loading.stats"
                              prefix="£"
                              :value="$store.state.applications.saleStats.terraced ? $store.state.applications.saleStats.terraced : 0"
                    >
                    </stat-box>
                </div>
                <div class="column w-full" :class="statBoxClass">
                    <stat-box title="Avg. Flat/Maisonette Price"
                              :loading="$store.state.applications.saleHistory.loading.stats"
                              :prefix="'£'"
                              :value="$store.state.applications.saleStats.flats ? $store.state.applications.saleStats.flats : 0"
                    >
                    </stat-box>
                </div>

                <div class="column w-full" :class="statBoxClass">
                    <stat-box title="Avg. Other Price"
                              :loading="$store.state.applications.saleHistory.loading.stats"
                              :prefix="'£'"
                              :value="$store.state.applications.saleStats.other ? $store.state.applications.saleStats.other : 0"
                    >
                    </stat-box>
                </div>


                <div class="column" :class="className">
                    <dashboard-widget title="Avg. Sale Price by Property Type">
                        <slot>
                            <div class="h-32 w-full" v-loading="true"
                                 v-if="$store.state.applications.saleHistory.loading.stats"
                            >
                            </div>

                            <apexchart type="line"
                                       v-if="!$store.state.applications.saleHistory.loading.stats && $store.getters['applications/getPricesByType'] !== null"
                                       :options="$store.getters['applications/getPricesByType'].options"
                                       :series="$store.getters['applications/getPricesByType'].series"></apexchart>
                        </slot>
                    </dashboard-widget>
                </div>
                <div class="column" :class="className">
                    <dashboard-widget title="Avg. Sale Price by Tenure">
                        <slot>
                            <div class="h-32 w-full" v-loading="true"
                                 v-if="$store.state.applications.saleHistory.loading.stats"
                            >
                            </div>
                            <apexchart type="line"
                                       v-if="!$store.state.applications.saleHistory.loading.stats && $store.getters['applications/getPricesByTenure'] !== null"
                                       :options="$store.getters['applications/getPricesByTenure'].options"
                                       :series="$store.getters['applications/getPricesByTenure'].series"></apexchart>
                        </slot>
                    </dashboard-widget>
                </div>
                <div class="column" :class="className">
                    <dashboard-widget title="Sales by Property Type">
                        <slot>
                            <div class="h-32 w-full" v-loading="true"
                                 v-if="$store.state.applications.saleHistory.loading.stats"
                            >
                            </div>
                            <apexchart type="line"
                                       v-if="!$store.state.applications.saleHistory.loading.stats && $store.getters['applications/getSalesByType'] !== null"
                                       :options="$store.getters['applications/getSalesByType'].options"
                                       :series="$store.getters['applications/getSalesByType'].series"></apexchart>
                        </slot>
                    </dashboard-widget>
                </div>
                <div class="column" :class="className">
                    <dashboard-widget title="Sales by Tenure">
                        <slot>
                            <div class="h-32 w-full" v-loading="true"
                                 v-if="$store.state.applications.saleHistory.loading.stats"
                            >
                            </div>

                            <apexchart type="line"
                                       v-if="!$store.state.applications.saleHistory.loading.stats && $store.getters['applications/getSalesByTenure'] !== null"
                                       :options="$store.getters['applications/getSalesByTenure'].options"
                                       :series="$store.getters['applications/getSalesByTenure'].series"></apexchart>
                        </slot>
                    </dashboard-widget>
                </div>

                <div class="column" :class="className">
                    <dashboard-widget title="Avg. New Build Sale Price by Property Type">
                        <slot>
                            <div class="h-32 w-full" v-loading="true"
                                 v-if="$store.state.applications.saleHistory.loading.stats"
                            >
                            </div>

                            <apexchart type="line"
                                       v-if="!$store.state.applications.saleHistory.loading.stats && $store.getters['applications/getNewBuildAvgSaleByType'] !== null"
                                       :options="$store.getters['applications/getNewBuildAvgSaleByType'].options"
                                       :series="$store.getters['applications/getNewBuildAvgSaleByType'].series"></apexchart>
                        </slot>
                    </dashboard-widget>
                </div>
                <div class="column" :class="className">
                    <dashboard-widget title="Avg. New Build Sale Price by Tenure">
                        <slot>
                            <div class="h-32 w-full" v-loading="true"
                                 v-if="$store.state.applications.saleHistory.loading.stats"
                            >
                            </div>
                            <apexchart type="line"
                                       v-if="!$store.state.applications.saleHistory.loading.stats && $store.getters['applications/getNewBuildAvgSaleByTenure'] !== null"
                                       :options="$store.getters['applications/getNewBuildAvgSaleByTenure'].options"
                                       :series="$store.getters['applications/getNewBuildAvgSaleByTenure'].series"></apexchart>
                        </slot>
                    </dashboard-widget>
                </div>

                <div class="column" :class="className">
                    <dashboard-widget title="New Build Sales by Property Type">
                        <slot>
                            <div class="h-32 w-full" v-loading="true"
                                 v-if="$store.state.applications.saleHistory.loading.stats"
                            >
                            </div>
                            <apexchart type="line"
                                       v-if="!$store.state.applications.saleHistory.loading.stats && $store.getters['applications/getNewBuildByType'] !== null"
                                       :options="$store.getters['applications/getNewBuildByType'].options"
                                       :series="$store.getters['applications/getNewBuildByType'].series"></apexchart>

                        </slot>
                    </dashboard-widget>
                </div>

                <div class="column" :class="className">
                    <dashboard-widget title="New Build Sales by Tenure">
                        <slot>
                            <div class="h-32 w-full" v-loading="true"
                                 v-if="$store.state.applications.saleHistory.loading.stats"
                            >
                            </div>
                            <apexchart type="line"
                                       v-if="!$store.state.applications.saleHistory.loading.stats && $store.getters['applications/getNewBuildByTenure'] !== null"
                                       :options="$store.getters['applications/getNewBuildByTenure'].options"
                                       :series="$store.getters['applications/getNewBuildByTenure'].series"></apexchart>

                        </slot>
                    </dashboard-widget>
                </div>
                <div class="column w-full">
                    <dashboard-widget title="Nearby Property Sales">
                        <slot>
                            <div class="h-32 w-full" v-loading="true"
                                 v-if="$store.state.applications.saleHistory.loading.table"
                            >
                            </div>
                            <div class="columns" v-if="!$store.state.applications.saleHistory.loading.table">
                                <div class="column ml-auto">
                                    <el-pagination
                                            background
                                            layout="prev, pager, next"
                                            @current-change="(page)=>{ fetch(page) }"
                                            :page-size="$store.state.applications.sales.rpp"
                                            :current-page="$store.state.applications.sales.meta.current"
                                            :total="$store.state.applications.sales.meta.totalResult">
                                    </el-pagination>
                                </div>
                            </div>

                            <div class="py-4"
                                 v-if="$store.state.applications.sales.results.length > 0"
                            >
                                <el-table
                                        class="el--table"
                                        :data="$store.state.applications.sales.results"
                                        :default-sort="$store.state.applications.sales.default_sort"
                                        @sort-change="onSortChange"
                                        style="width: 100%">
                                    <el-table-column
                                            prop="address"
                                            sortable="custom"
                                            label="Address">
                                        <template slot-scope="scope">
                                            <div class="text-xs break-words">
                                                <span class="font-bold">{{ scope.row.address }}</span>
                                                <br/>

                                                <div class="columns">
                                                    <div class="column" v-if="scope.row.new_build">
                                                        <i class="el-icon-star-off"></i> New Build
                                                    </div>
                                                    <div class="column">
                                                        {{ scope.row.duration_string }}
                                                    </div>
                                                    <div class="column">
                                                        {{ scope.row.property_type_string }}
                                                    </div>
                                                </div>


                                            </div>

                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            prop="price"
                                            align="right"
                                            label="Price"
                                            sortable="custom"
                                            width="100">
                                        <template slot-scope="scope">
                                            <div class="text-xs break-words">
                                                £{{ scope.row.price.toLocaleString() }}
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            prop="date_of_transfer"
                                            sortable="custom"
                                            align="right"
                                            label="Date"
                                            width="140">
                                        <template slot-scope="scope">
                                            <div class="text-xs break-words">
                                                {{ scope.row.date_of_transfer | moment("MMM Do YYYY") }}
                                                <br/><small>{{ scope.row.date_of_transfer | moment("from",
                                                "now") }}</small>
                                            </div>
                                        </template>
                                    </el-table-column>

                                </el-table>

                            </div>

                            <div class="columns" v-if="!$store.state.applications.saleHistory.loading.table">
                                <div class="column ml-auto">
                                    <el-pagination
                                            background
                                            layout="prev, pager, next"
                                            @current-change="(page)=>{ fetch(page) }"
                                            :page-size="$store.state.applications.sales.rpp"
                                            :current-page="$store.state.applications.sales.meta.current"
                                            :total="$store.state.applications.sales.meta.totalResult">
                                    </el-pagination>
                                </div>
                            </div>

                        </slot>
                    </dashboard-widget>
                </div>

            </div>
        </div>
        <div v-if="!$store.getters['applications/hasSiteCoords']">
            Applcation
        </div>
    </div>
</template>

<script>

    export default {
        name: 'ApplicationHousePrices',
        props: {
            hash: {
                type: String
            },
            statBoxClass: {
                type: String,
                default(){
                    return 'w-full md:w-1/2 lg:w-1/4'
                }
            },
            className: {
                type: String,
                default(){
                    return 'w-full md:w-1/2'
                }
            }
        },
        watch: {
            hash(){
                this.fetchSoldPrices()
                this.fetch(1);
            }
        },
        methods: {
            onSortChange(object){
                if(object.prop && object.order){
                    this.$store.state.applications.sales.default_sort = object;
                }else{
                    this.$store.state.applications.sales.default_sort = null;
                }
                this.fetch(1);
            },
            fetch(page){
                let vm = this;
                vm.$store.state.applications.sales.results = [];
                vm.$store.state.applications.sales.show = false;
                vm.$store.state.applications.saleHistory.loading.table = true;
                vm.$http.get('/api/v1/application/' + this.hash +  '/sale-history', {
                    params: {
                        page: page || 1,
                        rpp: vm.$store.state.applications.sales.rpp,
                        sort: vm.$store.state.applications.sales.default_sort && vm.$store.state.applications.sales.default_sort.prop ? vm.$store.state.applications.sales.default_sort.prop : null,
                        order: vm.$store.state.applications.sales.default_sort && vm.$store.state.applications.sales.default_sort.order ? vm.$store.state.applications.sales.default_sort.order === 'descending' ? 'desc' : 'asc' : null,
                    }
                }).then((response) => {
                    vm.$store.state.applications.sales.results = response.data.results;
                    vm.$store.state.applications.sales.meta = response.data.meta;
                    vm.$store.state.applications.saleHistory.loading.table = false;
                }).catch(() => {
                    vm.$store.state.applications.saleHistory.loading.table = false;
                })
            },
            fetchSoldPrices(){
                console.log(this.hash)
                this.$store.state.applications.saleHistory.loading.stats = true;
                this.$store.dispatch('applications/fetchSoldPrices', {
                    hash: this.hash
                })
                    .then(()=>{
                        this.$store.state.applications.saleHistory.loading.stats = false;
                    })
            }
        },
        created(){
            this.fetchSoldPrices()
            this.fetch(1);
        }
    }
</script>

<style scoped>

</style>