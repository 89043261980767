import api from "../../utils/api";

const initialState = {

};

export default {
    namespaced: true,
    state: initialState,
    mutations: {
        reset(state) {
            console.log('reset')
            Object.keys(initialState).forEach(key => {
                state[key] = initialState[key]
            })
        },
    },
    actions: {
        index: ({rootState}, params) => {
            console.log(rootState);
            return api.get('/api/v1/team/' + params.teamId + '/epc-ratings', {
                params: params && params.params ? params.params : {all: true}
            });
        },
    }
}