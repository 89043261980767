<template>

    <div>
        <div
                v-if="loading"
                class="flex h-full mt-12 pt-12 items-center justify-center">
            <div class="p-3 text-center">
                <property-loader />
                <h3 class="font-semibold text-blue-800">Fetching School Data...</h3>
            </div>
        </div>
        <div
                v-if="!loading && getLocationList.length"
                class="columns">

            <div
                    v-if="getLocationList.length > 0"
                    class="column w-full">

                <dashboard-widget
                        padding-class=""
                        title="Local Schools">
                    <slot>


                    <div class="p-2">
                        <l-map
                                ref="schools-map"
                                style="height: 220px;"
                                @ready="onMapReady"
                                :zoom="map.zoom"
                                :center="map.center"
                                :options="{zoomControl: false}"
                        >

                            <l-marker
                                    :key="index"
                                    v-for="(prop, index) of getSchoolsCoords"
                                    :lat-lng="prop.coords">
                                <l-icon>
                                    <div class="w-5 h-5 flex items-center justify-center shadow-lg border border-white text-xxs rounded-full bg-blue-700 text-white">
                                        {{ index + 1 }}
                                    </div>
                                </l-icon>
                            </l-marker>

                            <l-google-tile-layer
                                    ref="googleLayer"
                                    :apikey="$store.state.auth.map.google.key"
                                    :options="{type: 'hybrid'} "/>

                        </l-map>
                    </div>


                        <el-table
                                striped
                                border
                                :data="getLocationList"
                                header-row-class-name="bg-blue-300"
                                style="width: 100%">
                            <el-table-column
                                    prop="name"
                                    label="School">
                                <template slot-scope="scope">
                                    <div class="w-full flex">
                                        <div class="w-5 mr-2 flex-none">
                                            <div class="w-5 h-5 mt-0 flex text-white items-center justify-center rounded-full bg-blue-600 text-xxs ">
                                                {{ scope.$index + + 1 }}
                                            </div>
                                        </div>
                                        <div class="w-auto">
                                            {{ scope.row.address }}
                                        </div>
                                    </div>

                                    <small>{{ scope.row.educationPhases }} - {{ scope.row.typeOfEstablishmentName }}</small>
                                    <small class="block w-full flex items-center">
                                        <span v-if="scope.row.ofstedRating > 0">
                                            <span class="w-4 h-4 block font-bold flex items-center justify-center rounded-full text-xs text-white mr-1"
                                            :class="{
                                            'bg-green-700': scope.row.ofstedRating === 1,
                                            'bg-blue-700': scope.row.ofstedRating === 2,
                                            'bg-orange-700': scope.row.ofstedRating === 3,
                                            'bg-red-700': scope.row.ofstedRating === 4,
                                            }"
                                            >
                                                {{ scope.row.ofstedRating }}
                                            </span>
                                        </span>
                                        {{ scope.row.ofstedRatingText }}</small>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    width="85"
                                    prop="distance"
                                    class-name="text-xs"
                                    align="right"
                                    label="Distance">
                                <template slot-scope="scope">
                                    {{ scope.row.distance }}
                                </template>
                            </el-table-column>
                        </el-table>
                    </slot>
                </dashboard-widget>

            </div>

        </div>

        <div v-if="!loading && !getLocationList.length">
            <empty-state />
        </div>

    </div>

</template>

<script>

    import EmptyState from "../../EmptyState";
    import PropertyLoader from "../../incs/PropertyLoader";

    export default {
        name: "OwnershipLocalSchools",
        components: {PropertyLoader, EmptyState},
        props: {
            id: {
                type: [Number, String],
                default(){
                    return null
                }
            },
            polygon: {
                type: Object,
                default(){
                    return null;
                }
            }
        },
        data() {
            return {
                data: {

                },
                loading: true,
                showPolygons: true,
                activeTabs: [],
                map: {
                    center: [0, 0],
                    zoom: 3
                },
            }
        },
        mounted(){
          this.fetchSchools();
        },
        computed: {
            getLocationList(){
              return  this.data && this.data.schools ?  this.data.schools : [];
            },
            getSchoolsCoords(){
              return this.getLocationList.map((item)=>{
                  return {
                      coords: {
                          lat: item.latitude,
                          lng: item.longitude
                      }
                  }
              })
            }
        },
        methods: {
            fetchSchools(){
                if(this.polygon !== null){
                    this.fetchSchoolsPolygon()
                }else{
                    this.fetchSchoolsOwnership()
                }
            },
            onMapReady(){
                const vm = this;
                console.log(vm.data);
                if (vm.$refs && vm.$refs['schools-map'] && vm.data.bbox) {
                    this.$refs['schools-map'].mapObject.fitBounds([
                        [vm.data.bbox[1], vm.data.bbox[0]],
                        [vm.data.bbox[3], vm.data.bbox[2]],
                    ])
                }
            },
            fetchSchoolsPolygon(){
                const vm = this;
                vm.loading = true;
                this.$http.post('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/custom-draw', {
                    polygon: this.polygon,
                    view: 'local-schools'
                })
                    .then((response)=>{
                        vm.loading = false;
                        vm.data = response.data.data;
                    }).catch(()=>{
                    vm.loading = false;
                    vm.data = {};
                })
            },
            fetchSchoolsOwnership(){
                const vm = this;
                vm.loading = true;
                this.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/ownership/' + this.id + '/local-schools')
                    .then((response)=>{
                        vm.loading = false;
                         vm.data = response.data.data;
                    }).catch(()=>{
                    vm.loading = false;
                    vm.data = {};
                    })
            }
        }
    }
</script>

<style scoped>

</style>