<template>
  <div>
      <dashboard-widget
              padding-class=""
              :title="title">
          <template slot="cta">
              <floating-label-input label="View as">
                  <el-select
                          size="mini"
                          class="w-20"
                          filterable
                          placeholder="Select Tenure Types..."
                          v-model="areaType">
                      <el-option
                              :key="k"
                              :label="i.label"
                              :value="i.value"
                              v-for="(i, k) of area_types"></el-option>
                  </el-select>
              </floating-label-input>
          </template>
          <slot>

              <div class="border-b border-bg-gray-900 my-4" v-if="value.length > 0">
                  <apexchart type="pie"
                             height="200"
                             :options="getChartData.options"
                             :series="getChartData.series"></apexchart>
              </div>

              <el-table :data="value">
                  <el-table-column :label="typeLabel">
                      <template slot-scope="scope">
                          <span class="font-bold">{{ scope.row.label }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column align="right" width="110px" label="Count">
                      <template slot-scope="scope">
                          {{ scope.row.count }}
                      </template>
                  </el-table-column>
                  <el-table-column align="right" width="110px" label="Coverage (%)">
                      <template slot-scope="scope">
                          {{ scope.row.area && scope.row.area.percentage ? scope.row.area.percentage.toLocaleString() : 0  }}%
                      </template>
                  </el-table-column>
                  <el-table-column align="right"  width="110px"  label="Area">
                      <template slot-scope="scope">
                          {{ scope.row.area && scope.row.area[areaType] ? scope.row.area[areaType].toLocaleString() : 0  }} <small>{{ getAreaType }}</small>
                      </template>
                  </el-table-column>
              </el-table>

          </slot>
      </dashboard-widget>
  </div>
</template>

<script>
    export default {
        name: "TopographyAnalysisComponent",
        props: {
            value: {
                type: [Array, Object]
            },
            title: {
                type: String
            },
            typeLabel: {
                type: String,
                default(){
                    return 'Type'
                }
            }
        },
        computed: {
          getAreaType(){
              let find = this.area_types.find((i) => i.value === this.areaType);
              return find ? find.label : null;
          },
            getChartData(){
                return {

                    series: this.value.map((i)=>{
                        return i.area.total;
                    }),
                        options: {
                    chart: {
                            type: 'pie',
                    },
                    labels: this.value.map((i)=>{
                        return i.label;
                    }),
                            legend: {
                                position: 'top'
                            }
                }
                }
            }
        },
        data(){
            return {
                areaType: 'sqft',
                area_types: [
                    {label: 'm²', value: 'total'},
                    {label: 'acres', value: 'acres'},
                    {label: 'ft²', value: 'sqft'}
                ]
            }
        }
    }
</script>

<style scoped>

</style>