import collect from 'collect.js';
import {
    sentenceCase
} from "change-case";
import moment from "moment";
import api from "./../../utils/api";

const initialState = {
    search: {
        show: true,
        loading: false,
        options: {},
        default_sort: {prop: 'date_decided', order: 'descending'},
        results: [],
        meta: {},
        rpp: 20,
        form: {
            application: {
                local_authority: '',
                applicant_name: '',
                applicant_address: '',
                proposal: '',
                application_type: '',
                application_status: '',
                decision: '',
            },
            agent: {
                agent_name: '',
                agent_company: '',
                agent_address: '',
                agent_email: ''
            },
            applicant: {
                applicant_name: '',
                applicant_company: '',
                applicant_address: '',
            },
            dates: {
                date_received: null,
                date_validated: null,
                date_decided: null,
                date_decision_expected: null
            }
        }
    },
    saleHistory: {
        data: [],
        computedData: [],
        currentPage: 1,
        sortColumn: null,
        rpp: 10,
        loading: {
            stats: true,
            data: true,
            table: true
        }
    },
    localArea: {},
    saleCharts: {},
    saleStats: {},
    sales: {
        show: true,
        loading: false,
        options: {},
        default_sort: {prop: 'date_of_transfer', order: 'descending'},
        results: [],
        meta: {},
        rpp: 10
    },
    application: {},
    loading: false
};

export default {
    namespaced: true,
    state: initialState,
    mutations: {
        reset(state) {
            console.log('reset')
            Object.keys(initialState).forEach(key => {
                state[key] = initialState[key]
            })
        },
    },
    actions: {
        fetch: ({rootState,state}, opts) => {
            api.get('/api/v1/team/' + rootState.auth.user.team.id + '/application/' + opts.hash)
                .then((response) => {
                    state.application = response.data.data;
                }).catch(() => {
                state.application = {};
            })
        },
        fetchSoldPrices: ({rootState, state}, opts) => {
            state.saleHistory.currentPage = 1;
            return api.get('/api/v1/team/' + rootState.auth.user.team.id + '/application/' + opts.hash + '/sale-history-stats')
                .then((response) => {
                    state.saleCharts = response.data.charts;
                    state.saleStats = response.data.stats;

                })
                .catch(() => {
                    state.saleHistory.data = []
                    state.saleCharts = {};
                    state.saleStats = {};
                })
        },
        fetchLocalArea: ({rootState, state}, opts) => {
            state.saleHistory.currentPage = 1;

            return api.get('/api/v1/team/' + rootState.auth.user.team.id + '/application/' + opts.hash + '/local-area')
                .then((response) => {
                    state.localArea = response.data.data;
                }).catch(() => {
                state.localArea = {}
            })
        }
    },
    getters: {
        hasSiteCoords: (state) => {
           return state.application && state.application.site_address_coords;
        },
        buildTimeline: (state) => {

            return collect([
                'date_received',
                'date_validated',
                'date_decided',
                'date_decision_expected',
                'date_s106',
                'date_committee',
                'date_consultation_start',
                'date_consultation_end',
                'date_site_visit',
                'date_temp_permission_expiry_date',
                'date_permission_expiry_date',
                'date_appeal_decision',
                'date_appeal_questionnaire_sent',
                'date_appeal_questionnaire_due'
            ].filter((field) => {
                console.log(field);
                return state.application[field];
            }).map((item) => {
                console.log(item);
                return {
                    content: sentenceCase(item).replace(/date/g, '').trim(),
                    timestamp: state.application[item]
                }
            })).sortBy('timestamp').transform((item) => {

                item.timestamp = moment(item.timestamp).format('MMM Do YYYY') + ' - ' + moment(item.timestamp).fromNow()
                return item;
            }).toArray();
        },
        getNearest: (state) => (type) => {
            let data = state.localArea['nearest_' + type] ? state.localArea['nearest_' + type] : [];
            return data.map((item) => {
                item.distance_miles = item.distance_miles.toLocaleString();
                return item;
            })
        },


        getSaleHistoryData: (state, getters) => {
            if (getters.getPriceHistorySortByDirection === 'ascending') {
                return collect(state.saleHistory.data).sortBy(getters.getPriceHistorySortBy).splice((state.saleHistory.currentPage - 1) * state.saleHistory.rpp, state.saleHistory.rpp).toArray();
            } else {
                return collect(state.saleHistory.data).sortByDesc(getters.getPriceHistorySortBy).splice((state.saleHistory.currentPage - 1) * state.saleHistory.rpp, state.saleHistory.rpp).toArray();
            }
        },
        getPriceHistorySortBy: (state) => {
            return state.saleHistory && state.saleHistory.sortColumn && state.saleHistory.sortColumn.property ? state.saleHistory.sortColumn.property : 'address';
        },
        getPriceHistorySortByDirection: (state) => {
            return state.saleHistory && state.saleHistory.sortColumn && state.saleHistory.sortColumn.order ? state.saleHistory.sortColumn.order : 'descending';
        },
        getSalesByType: (state) => {
            try {
                return {
                    options: {
                        chart: {
                            zoom: {
                                enabled: false
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        xaxis: {
                            categories: state.saleCharts.categories
                        },
                        yaxis: {
                            labels: {
                                formatter: (val) => {
                                    return val ? val.toLocaleString() : 0
                                }
                            }
                        }
                    },
                    series: state.saleCharts.sales_by_type ? state.saleCharts.sales_by_type : []
                }
            } catch (e) {
                return null;
            }
        },
        getSalesByTenure: (state) => {
            try {
                return {
                    options: {
                        chart: {
                            zoom: {
                                enabled: false
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        xaxis: {
                            categories: state.saleCharts.categories
                        },
                        yaxis: {
                            labels: {
                                formatter: (val) => {
                                    return val ? val.toLocaleString() : 0
                                }
                            }
                        }
                    },
                    series: state.saleCharts.sales_by_tenure ? state.saleCharts.sales_by_tenure : []
                }
            } catch (e) {
                return null;
            }
        },
        getPricesByType: (state) => {
            try {
                return {
                    options: {
                        chart: {
                            zoom: {
                                enabled: false
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        xaxis: {
                            categories: state.saleCharts.categories
                        },
                        yaxis: {
                            labels: {
                                formatter: (val) => {
                                    return val ? '£' + val.toLocaleString() : 0
                                }
                            }
                        }
                    },
                    series: state.saleCharts.avg_sale_price_by_type ? state.saleCharts.avg_sale_price_by_type : []
                }
            } catch (e) {
                return null;
            }
        },
        getPricesByTenure: (state) => {
            try {
                return {
                    options: {
                        chart: {
                            zoom: {
                                enabled: false
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        xaxis: {
                            categories: state.saleCharts.categories
                        },
                        yaxis: {
                            labels: {
                                formatter: (val) => {
                                    return val ? '£' + val.toLocaleString() : 0
                                }
                            }
                        }
                    },
                    series: state.saleCharts.avg_sale_price_by_tenure ? state.saleCharts.avg_sale_price_by_tenure : []
                }
            } catch (e) {
                return null;
            }
        },
        getNewBuildByType: (state) => {
            try {
                return {
                    options: {
                        chart: {
                            zoom: {
                                enabled: false
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        xaxis: {
                            categories: state.saleCharts.categories
                        },
                        yaxis: {
                            labels: {
                                formatter: (val) => {
                                    return val ? val.toLocaleString() : 0
                                }
                            }
                        }
                    },
                    series: state.saleCharts.new_builds_sales_by_type ? state.saleCharts.new_builds_sales_by_type : []
                }
            } catch (e) {
                return null;
            }
        },
        getNewBuildByTenure: (state) => {
            try {
                return {
                    options: {
                        chart: {
                            zoom: {
                                enabled: false
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        xaxis: {
                            categories: state.saleCharts.categories
                        },
                        yaxis: {
                            labels: {
                                formatter: (val) => {
                                    return val ? val.toLocaleString() : 0
                                }
                            }
                        }
                    },
                    series: state.saleCharts.new_builds_sales_by_tenure ? state.saleCharts.new_builds_sales_by_tenure : []
                }
            } catch (e) {
                return null;
            }
        },
        getNewBuildAvgSaleByTenure: (state) => {
            try {
                return {
                    options: {
                        chart: {
                            zoom: {
                                enabled: false
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        xaxis: {
                            categories: state.saleCharts.categories
                        },
                        yaxis: {
                            labels: {
                                formatter: (val) => {
                                    return val ? '£' + val.toLocaleString() : 0
                                }
                            }
                        }
                    },
                    series: state.saleCharts.new_builds_avg_sale_price_by_tenure ? state.saleCharts.new_builds_avg_sale_price_by_tenure : []
                }
            } catch (e) {
                return null;
            }
        },
        getNewBuildAvgSaleByType: (state) => {
            try {
                return {
                    options: {
                        chart: {
                            zoom: {
                                enabled: false
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        xaxis: {
                            categories: state.saleCharts.categories
                        },
                        yaxis: {
                            labels: {
                                formatter: (val) => {
                                    return val ? '£' + val.toLocaleString() : 0
                                }
                            }
                        }
                    },
                    series: state.saleCharts.new_builds_avg_sale_price_by_type ? state.saleCharts.new_builds_avg_sale_price_by_type : []
                }
            } catch (e) {
                return null;
            }
        },

        getSaleHistory: (state) => {
            if (state.saleHistory.data && Array.isArray(state.saleHistory.data) > 0) {
                return state.saleHistory.data.map((item) => {

                    switch (item.duration) {
                        case "F":
                            item.duration = 'Freehold';
                            break;
                        case "L":
                            item.duration = 'Leasehold';
                            break;
                    }

                    switch (item.property_type) {
                        case "D":
                            item.property_type = 'Detached';
                            break;
                        case "S":
                            item.property_type = 'Semi-Detached';
                            break;

                        case "T":
                            item.property_type = 'Terraced';
                            break;

                        case "F":
                            item.property_type = 'Flat/Maisonette';
                            break;

                        case "O":
                            item.property_type = 'Other';
                            break;
                    }

                    return item;
                });
            }
            return [];
        },
        getFormObject: (state) => {
            let objects = Object.assign(state.search.form.application, state.search.form.agent, state.search.form.applicant);
            Object.keys(objects).forEach((key) => {
                if (objects[key] === null || (typeof objects[key] === 'string' && !objects[key].length)) {
                    delete objects[key]
                }
            })

            let dateObject = state.search.form.dates;


            Object.keys(dateObject).forEach((key) => {
                if (Array.isArray(dateObject[key]) && dateObject[key].length === 2) {
                    objects[key + '_from'] = dateObject[key][0];
                    objects[key + '_to'] = dateObject[key][1];
                } else {
                    delete objects[key + '_from'];
                    delete objects[key + '_to'];
                }
            });

            return objects;
        }
    }
}