import api from "../../utils/api";

const initialState = {

};

export default {
    namespaced: true,
    state: initialState,
    mutations: {
        reset(state) {
            console.log('reset')
            Object.keys(initialState).forEach(key => {
                state[key] = initialState[key]
            })
        },
    },
    actions: {
        index: ({rootState}, params) => {
            return api.get('/api/v1/team/' + rootState.auth.user.team.id + '/workflows', {
                params: params && params.params ? params.params : {all: true}
            });
        },
        create: ({rootState}, params) => {
            return api.post('/api/v1/team/' + rootState.auth.user.team.id + '/workflows', params);
        },
        delete: ({rootState}, params) => {
            return api.delete('/api/v1/team/' + rootState.auth.user.team.id + '/workflow/' + params.workflowId);
        },
        read: ({rootState}, params) => {
            return api.get('/api/v1/team/' + rootState.auth.user.team.id + '/workflow/' + params.workflowId);
        },

        update: ({rootState}, params) => {
            return api.put('/api/v1/team/' + rootState.auth.user.team.id + '/workflow/' + params.workflowId, {
                name: params.name,
                private: params.private,
                columns: params.columns
            });
        },

    }
}