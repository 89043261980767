<template>
    <div>

        <page :show-content="false">

            <template slot="header">
                <page-header title="Property Prices by District" />
            </template>
            <template slot="nav">
                <property-prices-nav />
            </template>


            <div class="flex w-full">
                <div class="w-96 relative el-block--property-stats-scroll-name overflow-hidden bg-white border-t border-gray-200">
                      <div class="absolute w-full">
                          <property-search-list
                                  url="/api/v1/types/districts"
                                  :loading="loading"
                                  @change="(code)=>{ activeCode = code  }"
                          />
                      </div>
                </div>
                <div class="w-auto flex-1">
                    <div class="p-3 bg-gray-900">
                        <h3 class="p-3 text-white text-2xl">
                            Property Information for X
                        </h3>
                    </div>
                    <div class="el-block--property-stats-block bg-red-500 overflow-y-scroll w-full">
                        <div class="container p-3 mx-auto h-full">
                            <area-property-sales
                                    :id="activeCode"
                                    @loading="(bool)=>{ loading = bool }"
                            />
                        </div>
                    </div>
                </div>
            </div>

        </page>


    </div>

</template>
<script>
    import PropertyPricesNav from "./PropertyPricesNav";
    import PropertySearchList from "../../../components/incs/PropertySearchList";
    export default {
        name: "Districts",
        components: {PropertySearchList, PropertyPricesNav},
        data(){
          return {
              loading: true,
              activeCode: null
          }
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.$store.commit('auth/setBreadcrumbs', [
                    {
                        title: 'Property Prices',
                        path: '/property-sales'
                    },
                    {
                        title: 'Districts'
                    }
                ]);
            })
        }
    }
</script>
<style>
    .el-block--property-stats-block {
        height: calc(100vh - 260px);
    }
    .el-block--property-stats-scroll-name {
        height: calc(100vh - 180px);
    }
</style>
