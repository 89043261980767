<template>
    <div
            v-if="loading"
            class="flex h-full items-center justify-center">
        <div class="p-3 text-center">
            <property-loader />
            <h3 class="font-semibold text-blue-800">Fetching Local Property Pricing...</h3>
        </div>
    </div>
    <div v-else>
        <div class="columns">
            <div class="column w-full">
                <dashboard-widget
                        padding-class="''"
                        :title="title + ' Property Prices'">
                    <el-table
                            striped
                            border
                            :data="getTypes"
                            header-row-class-name="bg-blue-300"
                            style="width: 100%">
                        <el-table-column
                                prop="distance"
                                class-name="font-semibold"
                                label="Distance">
                            <template slot-scope="scope">
                                {{ scope.row.distance }}m
                            </template>
                        </el-table-column>
                        <el-table-column
                                align="right"
                                prop="avg"
                                width="110px"
                                label="Avg">
                            <template slot-scope="scope">
                                £{{ scope.row.avg ? scope.row.avg.toLocaleString() : 0 }}
                            </template>
                        </el-table-column>
                        <el-table-column
                                align="right"
                                width="110px"
                                prop="max"
                                label="Max">
                            <template slot-scope="scope">
                                £{{ scope.row.max ? scope.row.max.toLocaleString() : 0 }}
                            </template>
                        </el-table-column>
                        <el-table-column
                                align="right"
                                width="110px"
                                prop="max"
                                label="Min">
                            <template slot-scope="scope">
                                £{{ scope.row.min ? scope.row.min.toLocaleString() : 0 }}
                            </template>
                        </el-table-column>
                    </el-table>
                </dashboard-widget>
            </div>
            <div class="column w-full">
                <dashboard-widget
                        padding-class="''"
                        :title="'Nearby Most Expensive ' + title + ' Streets'">
                    <el-table
                            striped
                            border
                            :data="getStreets"
                            header-row-class-name="bg-blue-300"
                            style="width: 100%">
                        <el-table-column
                                prop="distance"
                                class-name="font-semibold"
                                label="Street">
                            <template slot-scope="scope">
                                {{ scope.row.street_name }}
                            </template>
                        </el-table-column>
                        <el-table-column
                                align="right"
                                prop="avg"
                                width="110px"
                                label="Avg">
                            <template slot-scope="scope">
                                £{{ scope.row.avg ? scope.row.avg.toLocaleString() : 0 }}
                            </template>
                        </el-table-column>
                        <el-table-column
                                align="right"
                                width="110px"
                                prop="max"
                                label="Max">
                            <template slot-scope="scope">
                                £{{ scope.row.max ? scope.row.max.toLocaleString() : 0 }}
                            </template>
                        </el-table-column>
                        <el-table-column
                                align="right"
                                width="110px"
                                prop="max"
                                label="Min">
                            <template slot-scope="scope">
                                £{{ scope.row.min ? scope.row.min.toLocaleString() : 0 }}
                            </template>
                        </el-table-column>
                    </el-table>
                </dashboard-widget>
            </div>
        </div>

    </div>
</template>

<script>
    import PropertyLoader from "../../incs/PropertyLoader";
    export default {
        name: "OwnershipPropertyPricingComponent",
        components: {PropertyLoader},
        props: {
            viewType: {
                type: String,
                default(){
                    return 'residential'
                }
            },
            loading: {
              type: Boolean,
              default(){
                  return true;
              }
            },
            value: {
                type: Object,
                default(){
                    return {}
                }
            },
            title: {
                type: String,
                default(){
                    return ''
                }
            }
        },
        computed: {
            getTypes(){
              return this.value && this.value.types && this.value.types[this.viewType]  ? this.value.types[this.viewType] : []
            },
            getStreets(){
                return this.value && this.value.streets && this.value.streets[this.viewType]  ? this.value.streets[this.viewType] : []
            },
        }
    }
</script>