<template>

        <page>

            <template slot="header">
                <page-header title="Create LandHawk Job">
                    <template v-slot:tabs>
                        <land-hawk-nav  />
                    </template>
                </page-header>
            </template>

            <template slot="content">
                <div class="columns">
                    <div class="column w-full">
                        <dashboard-widget
                                title="Create LandHawk Job"
                                padding-class="">

                            <div class="p-4 pb-0">

                                <label class="uppercase block text-xs text-gray-800">Job Name<span class="text-xs text-red-400 ml-1">*</span></label>
                                <div class="my-3 rounded">
                                    <el-input
                                            :disabled="$store.state.map.loading_polygons"
                                            v-model="form.name" />
                                </div>


                                <label class="uppercase block text-xs text-gray-800">Search Location</label>

                                <div>
                                    <vue-google-autocomplete
                                            class="p-2 mt-3 rounded px-4 w-full text-sm border-gray-400 border"
                                            id="google-autocomplete"
                                            placeholder="Start typing a location"
                                            ref="search-google-autocomplete"
                                            :enable-geolocation="true"
                                            :country="['uk']"
                                            :disabled="$store.state.map.loading_polygons"
                                            types="geocode"
                                            v-on:placechanged="onLocationSearchChange"
                                    />
                                </div>

                                <div class="mt-3 rounded overflow-hidden" v-loading="$store.state.map.loading_polygons">
                                    <l-map
                                            ref="map"
                                            style="height: 300px;"
                                            :center="map.center"
                                            :zoom="map.zoom"
                                            @update:center="(e) => map.center = e"
                                            @update:zoom="(zoom)=>{ map.zoom = zoom }"
                                            :options="{zoomControl: false, attributionControl: false}"
                                            @click="onMapClick"
                                    >

                                        <l-circle
                                                :lat-lng="map.center"
                                                :radius="$store.state.map.polygon_filters.radius"
                                                color="#2d3748"
                                                ref="circle"
                                        />

                                        <l-google-tile-layer
                                                :apikey="$store.state.auth.map.google.key"
                                                :options="{type: 'hybrid'} "/>

                                    </l-map>
                                </div>


                            </div>
                            <polygon-search-filter
                            :full-height="true"
                            :min-radius="1000"
                            :radius-step-size="5000"
                            :max-radius="20000"
                            :hide-additional-options="true"
                            />

                            <div class="p-4 py-8 text-right">
                                <el-button
                                        :disabled="$store.state.map.loading_polygons"
                                        :loading="$store.state.map.loading_polygons"
                                        type="success" @click="create">Create LandHawk Job</el-button>
                            </div>

                        </dashboard-widget>
                    </div>
                </div>
            </template>

        </page>


</template>
<script>
    import PolygonSearchFilter from "../../components/LandMonitor/PolygonSearchFilter";
    import LandHawkNav from "./LandHawkNav";
    export default {
        name: 'LandHawkCreateJob',
        components: {LandHawkNav, PolygonSearchFilter},
        data(){
            return {
                form: {
                  name: ''
                },
                map: {
                    center: this.$store.state.auth.user.location,
                    zoom: this.$store.state.auth.user.zoom
                },
                data: []
            }
        },
        watch: {
          '$store.state.map.polygon_filters.radius'(){
              this.fitBounds();
          }
        },
        methods: {
            create(){
                const vm = this;

                vm.$store.state.map.loading_polygons = true;

                const object = Object.assign({
                    name: this.form.name,
                    radius: this.$store.state.map.polygon_filters.radius,
                    params: this.$store.getters['map/searchParams'],
                }, this.map.center);

               return vm.$store.dispatch('job/create', object).then(() => {
                   vm.$notify({
                       position: 'top-right',
                       title: 'LandHawk Job Created',
                       message: 'Your LandHawk Job has been created and is queued.',
                       type: 'success',
                       offset: 140
                   });
                   vm.$store.state.map.loading_polygons = false;
                   vm.$router.push('/land-hawk-jobs')
                }).catch((e) => {
                   vm.$store.state.map.loading_polygons = false;
                   vm.$store.dispatch('auth/handleServerError', {
                       vm: vm,
                       error: e
                   });
                })

            },
            onLocationSearchChange(opts){
                const vm = this;
                if (opts.latitude && opts.longitude) {
                    vm.map.center = [opts.latitude, opts.longitude];
                    vm.fitBounds();
                    setTimeout(()=>{
                      document.getElementById('google-autocomplete').value = '';
                    }, 5)
                }
            },
            fitBounds(){
                const vm = this;
                setTimeout(()=>{
                    vm.$refs.map.mapObject.fitBounds(vm.$refs.circle.mapObject.getBounds())
                }, 5)
            },
            onMapClick(event) {
                console.log('onMapClick', event)
                this.map.center = event.latlng;
            },
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.$store.commit('auth/setBreadcrumbs', [
                    {
                        title: 'LandHawk Jobs',
                        path: '/land-hawk-jobs'
                    },
                    {
                        title: 'Create a Job'
                    }
                ]);
            })
        },
        mounted(){
            this.$store.commit('map/resetFilter');
            this.fitBounds();
        }
    }
</script>

