<template>
  <div>
      <div v-show="!no_map">
          <div id="street-item-container">
          </div>
      </div>
      <div v-if="no_map">
          <empty-state title="Street View not available" message="Sorry, but this site does not offer a StreetView Service" />
      </div>
  </div>
</template>

<script>

    import EmptyState from "../EmptyState";
    const google = window.google;

    export default {
        name: "StreetViewLayer",
        components: {EmptyState},
        props: {
            location: {
                type: Object,
                default() {
                    return {
                        lat: 0,
                        lng: 0,
                    }
                }
            }
        },
        data() {
            return {
                map: null,
                no_map: false
            }
        },
        watch: {
            location: {
                handler() {
                    console.log('New Location', this.location);
                    this.fetchNearest();
                },
                deep: true
            }
        },

        methods: {
            fetchNearest(){
                let vm = this,
                    sv = new google.maps.StreetViewService();
                let location = new google.maps.LatLng(this.location.lat, this.location.lng);
                sv.getPanoramaByLocation(location, 200, (data, status) => {
                    if(data && status === 'OK'){
                        console.log(data);
                        vm.map.setPano(data.location.pano);
                        vm.map.setPov({
                            heading: 270,
                            pitch: 0
                        });
                    }else{
                        vm.no_map = true;
                    }
                });

            }
        },
        mounted() {
            const vm = this;
            vm.map = new google.maps.StreetViewPanorama(document.getElementById("street-item-container"), {
                position: this.location,
                streetViewControl: false,
                pov: {
                    heading: 0,
                    pitch: 0,
                },
            });

            vm.map.setPosition(new google.maps.LatLng(this.location));

        }
    }
</script>
<style>
    #street-item-container {
        height: 390px;
    }
</style>