
export function download({buffer, content_type, name}) {
  let byteString = atob(buffer),
      ab = new ArrayBuffer(byteString.length),
      ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ab], { type: content_type });

  const url = window.URL.createObjectURL(blob),
      a = document.createElement('a');

  a.href = url;
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(url);
}