<template>
    <div style="display: none;">
        <slot v-if="ready"></slot>
    </div>
</template>

<script>

    const turf = require('@turf/turf');

    import L from 'leaflet'
    import 'leaflet-draw'
    import 'leaflet/dist/leaflet.css';
    import 'leaflet-draw/dist/leaflet.draw.css';

    import { findRealParent } from 'vue2-leaflet'

    const props = {
        options: {
            type: Object,
            default() { return {}; },
        }
    };

    export default {
        props,
        data() {
            return {
                ready: false,
                editing: false,
                deleting: false,
                max_size: 148645.64,
                editableLayers: null
            };
        },
        mounted() {
            try {
                this.destroy()
            } catch (e) {
                console.log('')
            }
            this.mount();
        },
        beforeDestroy() {
            this.destroy()
        },
        methods: {
            redraw(){
                this.destroy();
                this.mount();
            },
            mount(){
                const vm = this;

                this.parentContainer = findRealParent(this.$parent);
                this.editableLayers = new L.FeatureGroup();
               this.parentContainer.mapObject.addLayer(this.editableLayers);
                this.drawControl = new L.Control.Draw({
                    position: 'topright',
                    collapsed: false,
                    draw: {
                        circlemarker: false,
                        marker: false,
                        polyline : false,
                        circle : true,
                        rectangle: {
                            showRadius: true,
                            metric: ['km', 'm']
                        },
                        polygon: {
                            allowIntersection: false,
                            showArea: true,
                            metric: ['km', 'm']
                        }
                    },
                    edit: {
                        featureGroup: this.editableLayers, //REQUIRED!!
                        poly: {
                            allowIntersection: false
                        }
                    }
                });
                this.parentContainer.mapObject.addControl(this.drawControl, !this.visible);

                /* On Created */
                this.parentContainer.mapObject.on(L.Draw.Event.CREATED, (e) => {

                  vm.editableLayers.addLayer(e.layer);

                  e.layer.on('click', (e) => {
                      if(!vm.editing && !vm.deleting){
                          let layer = vm.editableLayers._layers[e.target._leaflet_id];
                          let geoJSON = layer.toGeoJSON();
                          if(geoJSON.geometry.type === 'Point'){
                              geoJSON = turf.buffer(geoJSON, layer.getRadius(), {units: 'meters'});
                          }
                          vm.$emit('draw:click', {
                              geoJSON: geoJSON,
                              area: turf.area(geoJSON),
                              is_valid: turf.area(geoJSON) < vm.max_size
                          });
                      }
                  })

                    let geoJSON = e.layer.toGeoJSON();
                    if(e.layerType === 'circle'){
                        geoJSON = turf.buffer(geoJSON, e.layer.getRadius(), {units: 'meters'});
                    }

                    vm.$emit('draw:created', {
                        geoJSON: geoJSON,
                        area: turf.area(geoJSON),
                        is_valid: turf.area(geoJSON) < vm.max_size
                    });
                });

                /* On Deleted  */
                this.parentContainer.mapObject.on(L.Draw.Event.DELETED, () => {
                    vm.$emit('draw:deleted');
                });


                this.parentContainer.mapObject.on(L.Draw.Event.EDITSTART, () => {
                   vm.editing = true;
                });
                this.parentContainer.mapObject.on(L.Draw.Event.EDITSTOP, () => {
                    vm.editing = false;
                });

                this.parentContainer.mapObject.on(L.Draw.Event.DELETESTART, () => {
                    vm.deleting = true;
                });
                this.parentContainer.mapObject.on(L.Draw.Event.DELETESTOP, () => {
                    vm.deleting = false;
                });

            },
            destroy(){
                this.parentContainer.mapObject.removeLayer(this.editableLayers);
                this.parentContainer.mapObject.removeControl(this.drawControl);
            },
            addLayer(layer, alreadyAdded) {
                if (!alreadyAdded) {
                    this.mapObject.addLayer(layer.mapObject);
                }
            },
            removeLayer(layer, alreadyRemoved) {
                if (!alreadyRemoved) {
                    this.mapObject.removeLayer(layer.mapObject);
                }
            },
        },
    };
</script>