<template>

    <div>
        <div
                v-if="loading"
                class="flex h-full mt-12 pt-12 items-center justify-center">
            <div class="p-3 text-center">
                <property-loader />
                <h3 class="font-semibold text-blue-800">Fetching Environmental Coverage...</h3>
            </div>
        </div>

        <div class="columns" v-if="!loading">
            <div class="column w-full">

                <dashboard-widget
                        padding-class=""
                        title="Greenbelt/AONB Coverage">

                    <div class="p-3 pb-1" v-if="fetchCoverageBreakdown">
                        <el-alert
                                effect="dark"
                                :type="fetchCoverageBreakdown.greenbelt.coverage > 0 ? 'warning': 'success'" :closable="false">
                            This site is {{ fetchCoverageBreakdown.greenbelt.coverage > 0 ? 'within': 'not within' }} greenbelt.
                        </el-alert>
                    </div>

                    <div class="p-3" v-if="fetchCoverageBreakdown">
                        <el-alert
                                effect="dark"
                                :type="fetchCoverageBreakdown.aonb.coverage > 0 ? 'warning': 'success'" :closable="false">
                            This site is {{ fetchCoverageBreakdown.aonb.coverage > 0 ? 'within': 'not within' }} a AONB.
                        </el-alert>
                    </div>


                    <div class="p-3 pb-0" v-if="fetchCoverageBreakdown !== null">
                        <ownership-overview-map
                                ref="ownership-map"
                                :height="180"
                                :coords="fetchCoverageBreakdown.center"
                                :polygons="fetchCoverageBreakdown.polygon"
                                :bbox="fetchCoverageBreakdown.bbox"
                        >
                            <div
                                    :key="'layer_' + index"
                                    v-for="(layer, index) of layers"
                            >
                                <l-wms-tile-layer
                                        :base-url="layer.url ? layer.url : ''"
                                        :layers="layer.layers ? layer.layers : ''"
                                        :styles="layer.styles ? layer.styles : ''"
                                        :opacity="layer.opacity ? layer.opacity : 1"
                                        :visible="true"
                                        :transparent="true"
                                        class="el-leaflet-item"
                                        format="image/png"
                                        layer-type="base"
                                />
                            </div>
                        </ownership-overview-map>
                        <div class="flex py-2">
                            <div class="flex items-center p-1"><div class="w-4 h-4 rounded border border-gray-800" style="background-color: rgb(59, 189, 76);"></div><span class="text-xs ml-2  leading-tight">Greenbelt</span></div>
                            <div class="flex items-center p-1"><div class="w-4 h-4 rounded border border-gray-800 bg-orange-300"></div><span class="text-xs ml-2  leading-tight">AONB</span></div>
                        </div>
                    </div>

                    <el-table
                            class="el-table--nested-values"
                            stripe :data="coverageTableData">
                        <el-table-column type="expand">
                            <template slot-scope="props">
                                <el-table
                                        class="el-table--nested-value-table"
                                        stripe :data="props.row.areas">
                                    <el-table-column
                                            prop="label">
                                        <template slot-scope="scope">
                                            {{ scope.row.name }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column title="Coverage"
                                                     width="120"
                                                     align="right"
                                                     prop="label">
                                        <template slot-scope="scope">
                                            {{ scope.row.coverage }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            prop="percentage"
                                            align="right"
                                            width="120">
                                        <template slot-scope="scope">
                                            {{ scope.row.coverage_percentage }}%
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="label">
                            <template slot-scope="scope">
                            <span class="flex items-center">
                                                            <i class="el-icon-check text-xl text-green-600 mr-2"
                                                               v-if="scope.row.includes"></i>
                                                            <i class="el-icon-close text-xl text-red-600 mr-2"
                                                               v-if="!scope.row.includes"></i>
                                                 <span class="font-semibold">{{ scope.row.label }}</span>
                            </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="acres"
                                align="right"
                                width="100"
                                label="Acres">
                            <template slot-scope="scope">
                                {{ scope.row.acres }}
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="percentage"
                                align="right"
                                width="120"
                                label="Coverage (%)">
                            <template slot-scope="scope">
                                {{ scope.row.coverage }}%
                            </template>
                        </el-table-column>
                    </el-table>

                </dashboard-widget>

            </div>
        </div>

    </div>

</template>

<script>
    import PropertyLoader from "../../incs/PropertyLoader";
    import OwnershipOverviewMap from "./incs/OwnershipOverviewMap";
    export default {
        name: "OwnershipEnvironmentalCoverage",
        components: {OwnershipOverviewMap, PropertyLoader},
        props: {
            activeSiteId: {
                type: [Number, String],
                default(){
                    return null
                }
            },
            value: {
              type: Object,
              default(){
                  return {}
              }
            },
            loading: {
                type: Boolean,
                default(){
                    return true;
                }
            }
        },
        data(){
          return {
              layers: [
                  {
                      url: "https://tiles.landmonitor.com/geoserver/lm/wms",
                      type: "wms",
                      layers: "lm:greenbelt-eng"
                  },
                  {
                      url: "https://tiles.landmonitor.com/geoserver/lm/wms",
                      type: "wms",
                      layers: "lm:planning-flood-risk-3"
                  }
              ]
          }
        },
        computed: {
            fetchCoverageBreakdown(){
              let overview = this.value && this.value.overview ? this.value.overview : null;
              if(!this.activeSiteId){
                 return overview;
              }
              let find  = this.value.sites.find((i) => i.site_id === this.activeSiteId);
                return find ? find : null;
            },
            getEastingNorthing(){
              let item = this.fetchCoverageBreakdown;
              return item && item.eastings_northings ? item.eastings_northings : null
            },
            coverageTableData() {
                let data = this.fetchCoverageBreakdown;
                return [
                    {
                        label: 'Greenbelt',
                        includes: !!(data.greenbelt && data.greenbelt.coverage_percentage && data.greenbelt.coverage_percentage > 0),
                        coverage: data.greenbelt.coverage_percentage ? data.greenbelt.coverage_percentage.toLocaleString() : 0,
                        acres: data.greenbelt.coverage ? data.greenbelt.coverage.toLocaleString() : 0,
                        areas: (data.greenbelt.areas && Array.isArray(data.greenbelt.areas) ? data.greenbelt.areas : []).map((i)=>{
                            i.coverage = i.coverage.toLocaleString();
                            return i;
                        })
                    },
                    {
                        label: 'AONB',
                        includes: !!(data.aonb && data.aonb.coverage_percentage && data.aonb.coverage_percentage > 0),
                        coverage: data.aonb.coverage_percentage ? data.aonb.coverage_percentage.toLocaleString() : 0,
                        acres: data.aonb.coverage ? data.aonb.coverage.toLocaleString() : 0,
                        areas: (data.aonb.areas && Array.isArray(data.aonb.areas) ? data.aonb.areas : []).map((i)=>{
                            i.coverage = i.coverage.toLocaleString();
                            return i;
                        })
                    },
                ];
            }
        }
    }
</script>

<style>
    .el-table--nested-values .el-table__expanded-cell {
        padding: 0 !important;
    }
    .el-table--nested-value-table thead {
        display: none !important;
    }
</style>