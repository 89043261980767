<template>
    <div class="relative el-float--label">
        <slot></slot>
        <label class="absolute block select-none text-blue-400 text-xxs-2 uppercase top-0 left-0 pl-4 pt-1">
            {{ label }}
        </label>
    </div>
</template>

<script>
    export default {
        name: "FloatingLabelInput",
        props: {
            label: {
                type: String
            }
        }
    }
</script>

<style scoped>
.el-float--label ::v-deep .el-input__inner {
    padding-top: 0.5rem !important;
}
</style>