//import api from "../../utils/api";

import moment from "moment";

const initialState = {
    search: {
        show: true,
        loading: false,
        options: {},
        default_sort: {prop: 'price', order: 'descending'},
        results: [],
        meta: {},
        rpp: 20,
        form: {
            tenure: 'A',
            type: 'A',
            build: 'A',
            transaction_date: null,
            paon: null,
            saon: null,
            city: null,
            street: null,
            locality: null,
            district: null,
            county: null,
            postcode: null,
            price_min: null,
            price_max: null
            },
    }
}

export default {
    namespaced: true,
    state: initialState,
    mutations: {
        reset(state) {
            console.log('reset')
            Object.keys(initialState).forEach(key => {
                state[key] = initialState[key]
            })
        },
    },
    actions: {

    },
    getters: {
        getFormObject: (state) => {

         let objects = JSON.parse(JSON.stringify(state.search.form));
         Object.keys(objects).forEach((key) => {
             if (objects[key] === null || (typeof objects[key] === 'string' && !objects[key].length)) {
                 delete objects[key]
             }
         });

         console.log(objects);
         if(objects.transaction_date
             && Array.isArray(objects.transaction_date)
             && objects.transaction_date.length === 2){
             objects['transaction_date_from'] = moment(objects.transaction_date[0]).format('YYYY-MM-DD');
             objects['transaction_date_to'] = moment(objects.transaction_date[1]).format('YYYY-MM-DD');
             delete objects['transaction_date'];
         }

            return objects;
        }
    }
}
