<template>
    <div>


        <el-form label-position="left" label-width="130px" :model="alert">
            <el-form-item label="Active">
                <el-switch
                        :disabled="running"
                        v-model="alert.active"
                        active-color="#13ce66"/>
            </el-form-item>
            <el-form-item label="Name">
                <el-input
                        :disabled="running"
                        v-model="alert.name"/>
            </el-form-item>
            <el-form-item label="Radius (miles)">
                <el-input-number
                        :disabled="running"
                        :max="50"
                        :min="0.3"
                        v-model="alert.radius"/>
            </el-form-item>

            <el-form-item label="Proposal includes">
                <proposal-tags v-model="alert.includes" />
            </el-form-item>

            <el-form-item label="Proposal excludes">
                <proposal-tags type="danger" v-model="alert.excludes" />
            </el-form-item>

            <el-form-item label="Frequency">
                <el-select
                        :disabled="running"
                        v-model="alert.frequency">
                    <el-option
                            label="Daily"
                            value="DAILY">Daily
                    </el-option>
                    <el-option
                            label="Weekly"
                            value="WEEKLY">Weekly
                    </el-option>
                    <el-option
                            label="Monthly"
                            value="MONTHLY">Monthly
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Location">
                <vue-google-autocomplete
                        id="google-autocomplete"
                        classname="el-input__inner mb-6"
                        :enable-geolocation="true"
                        placeholder="Search for an address/location"
                        :country="['uk']"
                        types="geocode"
                        v-on:placechanged="(opts)=>{ onLocationChange(opts) }"
                />
                <div class="el-block--map-lmap relative">

                    <l-map
                            ref="map"
                            :min-zoom="$store.state.auth.map.min_zoom"
                            :max-zoom="$store.state.auth.map.max_zoom"
                            :max-bounds="$store.getters['auth/getMaxBounds']"
                            :center="$store.state.alert.active.center"
                            :zoom="$store.state.alert.zoom"
                            @click="onMapClick"
                    >

                        <l-circle
                                v-if="alert.marker_location"
                                :lat-lng="alert.marker_location"
                                :radius="meters"
                                color="#2d3748"
                                ref="circle"
                        />
                        <l-google-tile-layer
                                :apikey="$store.state.auth.map.google.key"
                                :options="$store.state.auth.map.google.opts"/>
                    </l-map>
                </div>
            </el-form-item>
        </el-form>

        <div class="w-full text-right">
            <el-button type="success"
                       v-if="mode === 'create'"
                       @click="create"
                       :disabled="running"
                       :loading="running"
                       icon="el-icon-plus">Create
            </el-button>
            <el-button type="success"
                       v-if="mode === 'edit'"
                       @click="update"
                       :disabled="running"
                       :loading="running"
                       icon="el-icon">Update
            </el-button>
        </div>


    </div>
</template>

<script>

    import ProposalTags from "../ProposalTags";
    export default {
        name: "ApplicationAlertCreateEdit",
        components: {ProposalTags},
        props: {
            value: {
                type: Object,
                default() {
                    return {
                    };
                }
            }
        },
        data() {
            return {
                running: false,
                mode: Object.keys(this.value).length > 0 && this.value.id ? 'edit' : 'create',
                alert: {
                    name: this.value.name ? this.value.name : '',
                    frequency: this.value.frequency ? this.value.frequency : 'WEEKLY',
                    location: null,
                    includes: this.value.includes ? this.value.includes : [],
                    excludes: this.value.excludes ? this.value.excludes : [],
                    marker_location: this.value.lat && this.value.lon ? [this.value.lat, this.value.lon] : [52.09228255792897, -0.9937155504271413],
                    active: this.value.active ? this.value.active : true,
                    radius: this.value.radius ? this.value.radius : 10,
                }
            }
        },
        methods: {


            update() {
                this.$store.dispatch('alert/update', {
                    hash: this.value.id,
                    form: {
                        active: this.alert.active,
                        name: this.alert.name,
                        frequency: this.alert.frequency,
                        lat: this.alert.marker_location[0],
                        lon: this.alert.marker_location[1],
                        includes: this.alert.includes,
                        excludes: this.alert.excludes,
                        radius: this.alert.radius
                    }
                }).then(() => {
                    this.running = false;

                    this.$notify({
                        type: 'success',
                        title: 'Alert Updated',
                        message: 'Application alert created.',
                        position: 'top-right',
                        offset: 140,
                    });

                })
                    .catch(() => {
                        this.running = false;
                        this.$notify({
                            type: 'warning',
                            title: 'Something is missing',
                            message: 'Please check all form fields before submitting.',
                            position: 'top-right',
                            offset: 140,
                        });
                    })
            },
            create() {
                this.$store.dispatch('alert/add', {
                    active: this.alert.active,
                    name: this.alert.name,
                    frequency: this.alert.frequency,
                    lat: this.alert.marker_location[0],
                    lon: this.alert.marker_location[1],
                    includes: this.alert.includes,
                    excludes: this.alert.excludes,
                    radius: this.alert.radius
                }).then(() => {
                    this.running = false;

                    this.$notify({
                        type: 'success',
                        title: 'Alert Created',
                        message: 'Application alert created.',
                        position: 'top-right',
                        offset: 140,
                    });

                    this.alert.active = true;
                    this.alert.name = '';
                    this.alert.frequency = 'WEEKLY';
                    this.alert.radius = 10;

                    this.alert.includes = [];
                    this.alert.excludes = [];

                    this.$router.push('/alerts')
                })
                    .catch(() => {
                        this.running = false;
                        this.$notify({
                            type: 'warning',
                            title: 'Something is missing',
                            message: 'Please check all form fields before submitting.',
                            position: 'top-right',
                            offset: 140,
                        });
                    })
            },
            onMapClick(event) {
                console.log('onMapClick', event)
                this.alert.marker_location = event.latlng;
            },
            onLocationChange(opts) {
                if (opts.latitude && opts.longitude) {

                    this.alert.marker_location = [opts.latitude, opts.longitude];

                    let el = document.getElementById('google-autocomplete');
                    if (el) {
                        el.value = '';
                    }

                    setTimeout(() => {
                        this.$refs.map.mapObject.fitBounds(this.$refs.circle.mapObject.getBounds())
                    }, 100)


                }
            }
        },
        computed: {
            meters() {
                return this.alert.radius * 1609;
            }
        }
    }
</script>

<style scoped>
    .el-block--map-lmap {
        height: 22rem;
    }
</style>