const moment = require('moment');
const humanFormat = require('human-format');
const polylabel = require('polylabel');
const turf = require('@turf/turf');

export function toHumanNumber(value){
    return humanFormat(value, {
        separator: ''
    })
}

export function getArraySize(array) {
    return array && Array.isArray(array) ? array.length : 0;
}

export function getKeyIntegerValue(dataset, key, human){
    return dataset[key] ? human ? toHumanNumber(dataset[key]) : dataset[key] : 0;
}

export function getTotalProperties(properties) {
    return properties && Array.isArray(properties) ? toHumanNumber(properties.length) : 0;
}

export function getLeaseholders(properties) {
    return properties && Array.isArray(properties) ? properties.filter((i) => i.leasehold_details) : []
}


export function getTotalBuildings(topography){
    return topography && topography.overview && topography.overview.features ? toHumanNumber(topography.overview.features.filter((x) => x.properties.type === 'Buildings' ).length) : 0;
}

export function getNearestSubstation(substation){
    return substation && substation.min_distance ? substation.min_distance : null
}

export function isFloodRisk(environment){
    return environment && environment.overview ? environment.overview.flood_risk : false;
}
export function isGreenbelt(environment){
    return environment && environment.overview && environment.overview.greenbelt && environment.overview.greenbelt.coverage ?  environment.overview.greenbelt.coverage > 0 :  false;
}

export function isAONB(environment){
    return environment && environment.overview && environment.overview.aonb && environment.overview.aonb.coverage ?  environment.overview.aonb.coverage > 0 :  false;
}
export function getTotalLeaseholders(properties) {
    return toHumanNumber(getLeaseholders(properties).length);
}
export function getTotalListedBuildings(listed_buildings) {
    return listed_buildings && Array.isArray(listed_buildings) ? toHumanNumber(listed_buildings.length) : 0;
}
export function getTotalEPCRatings(epcs) {
    return epcs && Array.isArray(epcs) ? toHumanNumber(epcs.length) : 0;
}

export function getTotalUseClasses(properties) {
    properties = Array.isArray(properties) ? properties : [];
    let useCases = properties.filter((i) => i.classification_class);
    let objects = {};
    for (let u of useCases) {
        if (!objects[u.classification_code]) {
            objects[u.classification_class_name]++;
        }
        objects[u.classification_class_name]++;
    }
    return toHumanNumber(Object.values(objects).length);
}

export function getTotalPlanningApplications(planning) {
    return planning && Array.isArray(planning) ? toHumanNumber(planning.length) : 0;
}

export function getTotalSales(sale_history) {
    let sales = sale_history && Array.isArray(sale_history) ? sale_history : [],
        total = 0;

    for (let property of sales) {
        total += property.total_sales;
    }

    return toHumanNumber(total);
}

export function getTotalKnownListings(known_listings) {
    let listings = known_listings && Array.isArray(known_listings) && known_listings.length > 0 ? known_listings : [],
        total = 0;
    for (let l of listings) {
        total += l.total_listings;
    }
    return toHumanNumber(total);
}



export function getKnowMarketedSaleColor(date){
    let d = moment(date);
    if(moment().isBefore(d.add(6, 'months'))){
        return '#2a9d8f';
    }
    if(moment().isBefore(d.add(12, 'months'))){
        return '#f4a261';
    }
    if(moment().isBefore(d.add(24, 'months'))){
        return '#e9c46a';
    }
    if(moment().isBefore(d.add(3, 'years'))){
        return '#e76f51';
    }
    return '#c90500'
}

export function getCurrentOccupierColor(occupier){
    switch (occupier) {
        case "Rented (private)":
            return '#ffd166';
        case "Rented (social)":
            return '#e76f51';
    }
    return '#118ab2'
}


export function getPlanningObjectColor(item) {
   // item
   const colors =  getPlanningObjectColors();
   for(let key of Object.keys(item)){
       if(key.startsWith('is_')){
           console.log(item[key])
           if(item[key] === true){
               let found = colors.find((c) => {
                   return c.key === key;
               })
               console.log(found)
               if(found){
                   return found.color;
               }
           }
       }
   }
   return  '#a8a8a8'
}

export function getPlanningObjectColors() {
   return [
       {label: 'TPO/Trees', key: 'is_tpo', color: '#37ff00'},
       {label: 'Agricultural', key: 'is_agricultural', color: '#4b1f1f'},
       {label: 'Advert/Advertising', key: 'is_advert', color: '#358fff'},
       {label: 'Certificate of Lawfulness', key: 'is_certificate', color: '#ff8000'},
       {label: 'Change of Use', key: 'is_change_of_use', color: '#ff00d8'},
       {label: 'Conservation Area', key: 'is_conservation', color: '#20702b'},
       {label: 'Appeal', key: 'is_appeal', color: '#b841ff'},
       {label: 'Full Application', key: 'is_full', color: '#ff9abb'},
       {label: 'Major Application', key: 'is_major', color: '#fcaa65'},
       {label: 'Minor Application', key: 'is_minor', color: '#ef8023'},
       {label: 'Listed Building', key: 'is_listed_building', color: '#c1ff9a'},
       {label: 'Householder Application', key: 'is_household', color: '#ff5900'},
       {label: 'Extension', key: 'is_extension', color: '#3d9c97'},
       {label: 'Dwelling', key: 'is_dwelling', color: '#002c73'},
       {label: 'Discharge of Conditions', key: 'is_discharge_of_conditions', color: '#ece413'},
       {label: 'Demolition', key: 'is_demolition', color: '#ec003e'},
   ]
}

export function getEPCRatingColor(rating){
    switch (rating) {
        case "A":
            return '#008054';
        case "B":
            return '#19b459';
        case "C":
            return '#8dce46';
        case "D":
            return '#ffd500';
        case "E":
            return '#fcaa65';
        case "F":
            return '#ef8023';
        case "G":
            return '#e9153b';
    }
    return 'white'
}

export function getDefaultFilterParams() {
    return {
        area_min: null,
        area_max: null,
        tenure: 'any',
        ownership: [],
        registered_properties_min: null,
        registered_properties_max: null,
        leaseholders_min: null,
        leaseholders_max: null,
        use_types: [],
        use_classes: []

    }
}

export function getOrdnanceSurveyStyles() {
    return [
        {
            "code": 1,
            "name": "Multi Surface",
            "color": "#eeefda"
        },
        {
            "code": 2,
            "name": "Archway",
            "color": "#dcd7c6"
        },
        {
            "code": 3,
            "name": "Road Bridge",
            "color": "#e6dddd"
        },
        {
            "code": 4,
            "name": "Rail Bridge",
            "color": "#fcfdff"
        },
        {
            "code": 5,
            "name": "Bridge",
            "color": "#d6d2d2"
        },
        {
            "code": 6,
            "name": "Level Crossing",
            "color": "#fcfdff"
        },
        {
            "code": 7,
            "name": "Traffic Calming",
            "color": "#fcfdff"
        },
        {
            "code": 8,
            "name": "Pylon",
            "color": "#eee8d3"
        },
        {
            "code": 9,
            "name": "Track",
            "color": "#dcdcdb"
        },
        {
            "code": 10,
            "name": "Step",
            "color": "#dcdcdb"
        },
        {
            "code": 11,
            "name": "Canal",
            "color": "#aadeef"
        },
        {
            "code": 12,
            "name": "Footbridge",
            "color": "#e8cfcc"
        },
        {
            "code": 13,
            "name": "Mixed Woodland",
            "color": "#cee6bd"
        },
        {
            "code": 14,
            "name": "Nonconiferous Tree",
            "color": "#cee6bd"
        },
        {
            "code": 15,
            "name": "Coniferous Tree",
            "color": "#cee6bd"
        },
        {
            "code": 16,
            "name": "Agricultural Land",
            "color": "#d6edcf"
        },
        {
            "code": 17,
            "name": "Orchard",
            "color": "#cee6bd"
        },
        {
            "code": 18,
            "name": "Coppice Or Osiers",
            "color": "#cee6bd"
        },
        {
            "code": 19,
            "name": "Scrub",
            "color": "#e2efce"
        },
        {
            "code": 20,
            "name": "Boulders",
            "color": "#eaeae4"
        },
        {
            "code": 21,
            "name": "Rock",
            "color": "#eaeae4"
        },
        {
            "code": 22,
            "name": "Scree",
            "color": "#eaeae4"
        },
        {
            "code": 23,
            "name": "Rough Grassland",
            "color": "#e2efce"
        },
        {
            "code": 24,
            "name": "Heath",
            "color": "#e2efce"
        },
        {
            "code": 25,
            "name": "Saltmarsh",
            "color": "#e4f3f4"
        },
        {
            "code": 26,
            "name": "Sand",
            "color": "#f4f0d3"
        },
        {
            "code": 27,
            "name": "Mud",
            "color": "#e8e4dd"
        },
        {
            "code": 28,
            "name": "Shingle",
            "color": "#eaeae4"
        },
        {
            "code": 29,
            "name": "Marsh",
            "color": "#e4f3f4"
        },
        {
            "code": 30,
            "name": "Reeds",
            "color": "#aadeef"
        },
        {
            "code": 31,
            "name": "Foreshore",
            "color": "#eaead3"
        },
        {
            "code": 32,
            "name": "Slope",
            "color": "#669966"
        },
        {
            "code": 33,
            "name": "Cliff",
            "color": "#666666"
        },
        {
            "code": 34,
            "name": "Building",
            "color": "#dcd7c6"
        },
        {
            "code": 35,
            "name": "Natural",
            "color": "#e4efda"
        },
        {
            "code": 36,
            "name": "Manmade",
            "color": "#f2f2e9"
        },
        {
            "code": 37,
            "name": "Road Or Track",
            "color": "#fcfdff"
        },
        {
            "code": 38,
            "name": "Roadside Natural",
            "color": "#dde6d5"
        },
        {
            "code": 39,
            "name": "Roadside Manmade",
            "color": "#f2f2e9"
        },
        {
            "code": 40,
            "name": "Inland Water",
            "color": "#aadeef"
        },
        {
            "code": 41,
            "name": "Path",
            "color": "#dcdcdb"
        },
        {
            "code": 42,
            "name": "Rail Manmade",
            "color": "#cccbcb"
        },
        {
            "code": 43,
            "name": "Rail Natural",
            "color": "#dce5d3"
        },
        {
            "code": 44,
            "name": "Structure",
            "color": "#e7c9c8"
        },
        {
            "code": 45,
            "name": "Glasshouse",
            "color": "#f3f9f4"
        },
        {
            "code": 46,
            "name": "Landform Natural",
            "color": "#e4efda"
        },
        {
            "code": 47,
            "name": "Tidal Water",
            "color": "#aadeef"
        },
        {
            "code": 48,
            "name": "Landform Manmade",
            "color": "#f2f2e9"
        },
        {
            "code": 99,
            "name": "Unclassified",
            "color": "#f8f6f0"
        }
    ]
}




export function getUseClassNameByClassCode(useClass) {
    let obj = {
        'Sui Generis': '',
        'B2': 'General industrial',
        'B8': 'Storage or distribution',
        'C1': 'Hotel/Motel/Boardings/Guest Houses',
        'C2': 'Residential institutions',
        'C3': 'Dwellinghouses',
        'C4': 'Houses in multiple occupation',
        'D1': 'Non-residential institutions',
        'D2': 'Assembly and leisure',
        'E': 'Commercial, business and service',
        'F1': 'Local Community and Learning',
    }
    return obj[useClass] ? obj[useClass] : null;
}

export function getClassificationCodeFromCategory(category) {
    let item =  getAddressBaseCategories().find((item)=>{
        return item.value === category;
    })
    return item ? item.class : null;
}

export function getAddressBaseCategories() {
    return [
        {
            value: "C",
            label: "Commercial",
            class: 'E'
        },
        {
            value: "CA",
            label: "Agricultural",
            class: 'Sui Generis'
        },

        {
            value: "CC",
            label: "Community Services",
            class: 'F'
        },
        {
            value: "CE",
            label: "Education",
            class: 'F'

        },
        {
            value: "CH",
            label: "Hotel/Motel/Boardings/Guest Houses",
            class: 'C1'
        },
        {
            value: "CI",
            label: "Industrial",
            class: 'B2'
        },
        {
            value: "CL",
            label: "Leisure",
            class: 'D2'
        },
        {
            value: "CM",
            label: "Medical",
            class: 'E'
        },
        {
            value: "CN",
            label: "Animal Centre/Vet",
            class: 'Sui Generis'
        },
        {
            value: "CO",
            label: "Office",
            class: 'E'
        },
        {
            value: "CR",
            label: "Retail",
            class: 'E'
        },
        {
            value: "CT",
            label: "Transport/Car Parks",
            class: 'Sui Generis'
        },
        {
            value: "CU",
            label: "Utility",
            class: 'Sui Generis'
        },
        {
            value: "CX",
            label: "Emergency / Rescue Service",
            class: 'Sui Generis'
        },
        {
            value: "LD",
            label: "Development",
            class: 'Sui Generis'
        },
        {
            value: "LP",
            label: "Park",
            class: 'Sui Generis'
        },
        {
            value: "M",
            label: "Military",
            class: 'Sui Generis'
        },
        {
            value: "MA",
            label: "Army",
            class: 'Sui Generis'
        },
        {
            value: "MF",
            label: "Air Force",
            class: 'Sui Generis'
        },
        {
            value: "MG",
            label: "Defence Estates",
            class: 'Sui Generis'
        },
        {
            value: "MN",
            label: "Navy",
            class: 'Sui Generis'
        },
        {
            value: "OR",
            label: "Royal Mail Infrastructure",
            class: 'Sui Generis'
        },
        {
            value: "P",
            label: "Parent Shell"
        },
        {
            value: "PP",
            label: "Property Shell"
        },
        {
            value: "R",
            label: "Residential",
            class: 'C3'
        },
        {
            value: "RD",
            label: "Dwelling",
            class: 'C3'
        },
        {
            value: "RG",
            label: "Garage",
            class: 'B8'
        },
        {
            value: "RH",
            label: "House In Multiple Occupation",
            class: 'C4'
        },
        {
            value: "RI",
            label: "Residential Institution",
            class: 'C2'
        },
        {
            value: "U",
            label: "Unclassified"
        },
        {
            value: "UC",
            label: "Awaiting Classification"
        },
        {
            value: "UP",
            label: "Pending Internal Investigation"
        },
        {
            value: "X",
            label: "Dual Use",
            class: 'Sui Generis'
        },
        {
            value: "ZM",
            label: "Monument"
        },
        {
            value: "ZS",
            label: "Stately Home",
            class: 'C3'
        },
        {
            value: "ZW",
            label: "Place Of Worship",
            class: 'D1'
        }
    ]
}

    export function filterPolygon(properties, filters) {


    if (filters && filters.tenure && filters.tenure !== 'any') {
        if (filters.tenure === 'freehold' && properties.tenure !== 'Freehold') {
            return false;
        }
        if (filters.tenure === 'leasehold' && properties.tenure !== 'Leasehold') {
            return false;
        }
    }


      const size = properties.area_acres;

    if(size){
        if(size && filters.area_min && filters.area_max){
            console.log('filter-range', size);
            if(!(size >= filters.area_min && size <= filters.area_max)){
  return false;
            }
        }else{

            if(size && filters.area_min){
  if(size <= filters.area_min){
      return false;
  }
            }

            if(size && filters.area_max){
  if(size >= filters.area_max){
      return false;
  }
            }
        }
    }


    const registeredProperties =  properties.registered_addresses;

    if(Number.isInteger(registeredProperties)){

        let min = filters.registered_properties_min,
            max = filters.registered_properties_max;

        if(min !== null && max !== null){

            let greaterThanMin = registeredProperties >= min,
  lessThanMax = registeredProperties <= max,
  bounds =  greaterThanMin && lessThanMax;


            if(!bounds){
  return false;
            }

        }else{

            if(min !== null){
  if(registeredProperties < min){
      return false;
  }
            }

            if(max !== null){
                if(registeredProperties <= max){
                    return false;
  }
            }
        }
    }


        const leaseholders =  properties.leaseholders;

        if(Number.isInteger(leaseholders)){

            let min = filters.leaseholders_min,
                max = filters.leaseholders_max;

          //  console.log(leaseholders, min, max)

            if(min !== null && max !== null){

                let greaterThanMin = leaseholders >= min,
                    lessThanMax = leaseholders <= max,
                    bounds =  greaterThanMin && lessThanMax;


                if(!bounds){
                    return false;
                }

            }else{

                if(min !== null){
                    if(leaseholders < min){
                        return false;
                    }
                }

                if(max !== null){
                    console.log('here', leaseholders, max)
                    if(leaseholders <= max){
                        return false;
                    }
                }
            }
        }


        if (filters.ownership && Array.isArray(filters.ownership) && filters.ownership.length > 0 && !filters.ownership.includes(properties.ownership)) {
        return false;
    }

       // console.log(filters.use_types);

    if (filters.use_types && Array.isArray(filters.use_types) && filters.use_types.length > 0) {
        const classifications = JSON.parse(properties.classifications);

        let filtered = classifications.filter((classification)=>{
            return filters.use_types.includes(classification);
        });

        if(!filtered.length){
            return false;
        }
    }

        if (filters.use_classes && Array.isArray(filters.use_classes) && filters.use_classes.length > 0) {
            const classifications = JSON.parse(properties.classifications);

            let useTypes = [];
            for(let useClass of filters.use_classes){
                let dd = getAddressBaseCategories().filter((i)=>{
                    return i.class === useClass;
                }).map((i)=>{
                    return i.value;
                })
                useTypes = useTypes.concat(dd);
            }


            console.log(filters.use_classes, useTypes);

            let filtered = classifications.filter((classification)=>{
                return useTypes.includes(classification);
            });

            if(!filtered.length){
                return false;
            }
        }

    //console.log(registeredProperties, properties)

    return true;
}

export function getFreeholderPolygonStyle(item, hover) {
    return {
        weight: 1,
        fillColor: getColor(item.ownership),
        fillOpacity: hover ? 0.9 : 0.4,
        color: '#fff',
        fill: true
    }
}

export function getEPCRatingHoverHTML(i) {
    let html = '<div class="p-3 rounded shadow bg-white w-96">' +
        '<h3 class="font-bold text-sm leading-tight mb-2">' + i.single_line_address  + '</h3>' +
        ' <div class="columns mt-2">' +
        '  <div class="column w-1/3">' +
        '      <div class="border border-gray-300 flex items-center justify-center  p-2 text-center h-full rounded" ' +
        'style="background: ' + getEPCRatingColor(i.rating) + '">' +
        '<h4 class="text-5xl font-semibold">' + i.rating + '</h4>' +
        '      </div>' +
        '  </div>' +
        '  <div class="column w-2/3">' +
        '      <div class="text-xs">';


    if(i.potential_rating) {
        html += generateLabel({
            label: 'Potential Rating',
            value: i.potential_rating
        })
    }
    if(i.property_type) {
        html += generateLabel({
            label: 'Property Type',
            value: i.property_type
        })
    }
    if(i.built_form) {
        html += generateLabel({
            label: 'Built Form',
            value: i.built_form
        })
    }
    if(i.total_floor_area) {
        html += generateLabel({
            label: 'Total Floor Area',
            value: i.total_floor_area + ' m²'
        })
    }

    if(i.lodgement_date) {
        html += generateLabel({
            label: 'Date Lodged',
            value: moment(i.lodgement_date).format('MMM Do YYYY') +  '<br /><small>' + moment(i.lodgement_date).fromNow() + '</small>'
        })
    }

    html += '<div class="text-sm text-center text-green-700 uppercase mt-2 font-bold">Click to View Property</div>';

    html += '      </div>' +
        '  </div>' +
        ' </div>' +
        '</div>';
    return html;
}

export function getLeaseholderHoverHTML(i){
    let html = '<div class="w-65 p-2 rounded shadow">' +
        '    <h3 class="font-bold mb-2">' +  (i.register_property_description.startsWith('Unavailable') ? i.associated_property_description : i.register_property_description )  + '</h3>' +
        '<h4 class="text-xs font-medium">' + i.associated_property_description + '</h4>' +
        '<div class="text-xs">' +
        '' +
        '<div class="py-2">' + i.term + '</div>' +
        '';




    if(i.price_paid) {
        html += '<div class="flex mb-1 text-xs">' +
            '<div class="w-/2 uppercase mr-auto font-semibold">Price Paid</div>' +
            '<div>£' + i.price_paid.toLocaleString() + '</div>' +
            '</div>';
    }

        '</div>' +
        '</div>'
        '</div>'


    return html;
}

export function generateLabel({label, value}){
    return '<div class="flex mb-1 text-xs leading-tight mb-1">' +
    '<div class="pr-3 uppercase mr-auto font-semibold flex-grow">' + label + '</div>' +
    '<div class="text-right">' +  value  + '</div>' +
    '</div>'
}

export function getPricePaidHoverHTML(props){
    console.log(props)
    let html = '<div class="bg-white p-2 rounded border border-gray-300 overflow-hidden w-96">' +
        '<div class="bg-white">' +
        '<h4 class="my-1 font-semibold text-blue-900">£' + props.price_paid.toLocaleString() + '</h4>' +
        '<h3 class="font-bold text-xs leading-tight mb-2">' +  props.single_line_address + '</h3>';

    if(props.current_tenure){
        html += generateLabel({
            label: 'Ownership',
            value: props.tenure
        })
    }

    if(props.current_tenure){
        html += generateLabel({
            label: 'Current Tenure',
            value: props.current_tenure
        })
    }


    if(props.total_area_sft){
        html += generateLabel({
            label: 'Size (ft²)',
            value:  props.total_area_sft.toLocaleString() + ' ft²'
        })
    }

    if(props.price_per_sqft){
        html += generateLabel({
            label: 'Price per Sqft',
            value: '£' + props.price_per_sqft.toLocaleString()
        })
    }


    if(props.price_paid){
        html += generateLabel({
            label: 'Last Sold',
            value: moment(props.last_sold).format('MMM Do YYYY') +  '<br /><small>' + moment(props.last_sold).fromNow() + '</small>'
        })
    }

    if(props.property_type){
        html += generateLabel({
            label: 'Property Type',
            value: props.property_type
        })
    }
    if(props.built_form){
        html += generateLabel({
            label: 'Built Form',
            value: props.built_form
        })
    }

if(props.new_build){
    html += generateLabel({
        label: 'New Build',
        value: props.new_build
    })
}

    if(props.construction_age_band){
        html += generateLabel({
            label: 'Age',
            value: props.construction_age_band
        })
    }
    html += '<div class="text-sm text-center text-green-700 uppercase mt-2 font-bold">Click to View Property</div>';

       html += '</div>' +
           '</div>';

    return html;
}

export function getListedBuildingsIcon() {
    return {
        weight: 1,
        fillColor: '#c43f0a',
        color: '#ffffff',
        fill: true,
        radius: 6,
        fillOpacity: true
    };
}

export function getCurrentOccupiersHTML(props){
    console.log(props)
    let html = '<div class="w-65 p-2 rounded border border-gray-300 bg-white overflow-hidden">' +
        '<h3 class="font-bold text-sm leading-tight mb-2">' + props.single_line_address  + '</h3>';

    if(props.tenure) {
        html += generateLabel({
            label: 'Tenure',
            value: props.tenure
        })
    }
    if(props.property_type) {
        html += generateLabel({
            label: 'Property Type',
            value: props.property_type
        })
    }
    if(props.built_form) {
        html += generateLabel({
            label: 'Built Form',
            value: props.built_form
        })
    }
    if(props.total_floor_area) {
        html += generateLabel({
            label: 'Total Floor Area',
            value: props.total_floor_area.toLocaleString() + ' ft²'
        })
    }

    html += '<div class="text-sm text-center text-green-700 uppercase mt-2 font-bold">Click to View Property</div>';

    html += '</div>'

    return html;
}

export function getListedBuildingsHTML(props){
    console.log(props)
    let html = '<div class="w-92 p-2 rounded border border-gray-300 bg-white overflow-hidden">' +
        '<h3 class="font-bold text-sm leading-tight mb-2">' + props.name  + '</h3>';

    if(props.grade) {
        html += generateLabel({
            label: 'Grade',
            value: props.grade
        })
    }

    if(props.date_listed) {
        html += generateLabel({
            label: 'Date Listed',
            value: moment(props.date_listed).format('MMM Do YYYY') +  '<br /><small>' + moment(props.date_listed).fromNow() + '</small>'
        })
    }

    html += '<div class="text-sm text-center text-green-700 uppercase mt-2 font-bold">Click to View More Details</div>';

    html += '</div>'

    return html;
}

export function getHmoHTML(props) {
    console.log(props);
    let html = '<div class="w-96 p-2 rounded border border-gray-300 bg-white overflow-hidden">';
    html += '<h3 class="font-bold text-sm leading-tight mb-2 uppercase">' + props.single_line_address  + '</h3>';

    if(props.property_type) {
        html += generateLabel({
            label: 'Property Type',
            value: props.property_type
        })
    }

    if(props.built_form) {
        html += generateLabel({
            label: 'Built Form',
            value: props.built_form
        })
    }

    if(props.total_floor_area) {
        html += generateLabel({
            label: 'Total Floor Area',
            value: props.total_floor_area.toLocaleString() + 'ft²'
        })
    }

    html += '<div class="text-sm text-center text-green-700 uppercase mt-2 font-bold">Click to View Property</div>';
    html += '</div>'
    return html;
}

export function getPlanningHTML(props) {
    console.log(props);
    let html = '<div class="w-96 p-2 rounded border border-gray-300 bg-white overflow-hidden">';

    if(props.date_received){

        html += '  <div class="flex -pr-1 items-center mb-2  text-xs" style="font-size: 10px !important;">\n' +
            '                                    <div class="p-1 w-1/2">\n' +
            '                                        Received ' + moment(props.date_received).format('MMM Do YYYY') +
            '                                    </div>\n';
        if(props.date_decided){
         html += '<div class="p-1 w-1/2 text-right">' +
             ' Decided ' + moment(props.date_decided).format('MMM Do YYYY') +
             '</div>';
        }
          html +=  '</div>';

    }

    html += '<h3 class="font-bold text-sm leading-tight mb-2 uppercase">' + props.site_address  + '</h3>';

    if(props.proposal){
        html += '<p class="text-xs leading-tight">' + props.proposal  + '</p>';
    }

    let decision = '';
    if(props.decision){
        decision += ' - ' + props.decision
    }

    if(props.status){
        html += '<p class="text-xs mt-1"><strong>' + props.status  + '</strong> ' + decision + '</p>';
    }

    let tags = getPlanningTypeTags(props);

    if(tags.length > 0){
        html += '<div class="my-3 flex w-full flex-wrap -mx-1">';
        for(let tag of tags){
            html += "<div class='m-1 text-xs bg-blue-100 text-blue-500 border border-blue-500 p-1 rounded'>" + tag;

            html += "</div>"
        }
        html += '</div>';
    }

    html += '<div class="text-sm text-center text-green-700 uppercase mt-2 font-bold">Click to View Property</div>';

    html += '</div>'
    return html;
}

export function getPlanningTypeTags(item) {
    let matches = [];
    if(item.is_tpo){
        matches.push('TPO/Trees')
    }
    if(item.is_appeal){
        matches.push('Appeal')
    }
    if(item.is_advert){
        matches.push('Advert/Advertising')
    }
    if(item.is_major){
        matches.push('Major Application')
    }
    if(item.is_minor){
        matches.push('Minor Application')
    }
    if(item.is_full){
        matches.push('Full Application')
    }
    if(item.is_household){
        matches.push('Householder Application')
    }
    if(item.is_conservation){
        matches.push('Conservation Area')
    }
    if(item.is_listed_building){
        matches.push('Listed Building')
    }
    if(item.is_discharge_of_conditions){
        matches.push('Change of Use')
    }
    if(item.is_change_of_use){
        matches.push('Change of Use')
    }
    if(item.is_certificate){
        matches.push('Certificate')
    }
    if(item.is_agricultural){
        matches.push('Agricultural')
    }
    if(item.is_demolition){
        matches.push('Demolition')
    }
    if(item.is_dwelling){
        matches.push('Dwelling')
    }
    if(item.is_extension){
        matches.push('Extension')
    }

    return matches;
}


export function getLastKnownMarkedSaleHTML(props){
    console.log(props)
    let html = '<div class="w-65 p-2 rounded border border-gray-300 bg-white overflow-hidden">' +
        '<h3 class="font-bold text-sm leading-tight mb-2">' + props.single_line_address  + '</h3>';

    if(props.property_type) {
        html += generateLabel({
            label: 'Property Type',
            value: props.property_type
        })
    }
    if(props.built_form) {
        html += generateLabel({
            label: 'Built Form',
            value: props.built_form
        })
    }
    if(props.total_floor_area) {
        html += generateLabel({
            label: 'Total Floor Area',
            value: props.total_floor_area.toLocaleString() + ' ft²'
        })
    }

    if(props.marketed_date) {
        html += generateLabel({
            label: 'Date Marketed',
            value: moment(props.marketed_date).format('MMM Do YYYY') +  '<br /><small>' + moment(props.marketed_date).fromNow() + '</small>'
        })
    }


    html += '<div class="text-sm text-center text-green-700 uppercase mt-2 font-bold">Click to View Property</div>';

       html += '</div>'

    return html;
}

export function getPolygonHoverHTML({address, ownership, area_acres, tenure, registered_addresses, leaseholders, ownership_name}) {

    let html = '<div class="text-xs w-65 shadow rounded border border-gray-100 overflow-hidden">' +
        '<div class="uppercase font-semibold p-1 px-2 break-words text-white h-auto block bg-black w-full elipsis truncate overflow-hidden">' +
        address +
        '</div>' +
        '<div class="p-1 px-2 text-xs">';

    html += '<div class="flex mb-1">' +
        '<div class="w-1/4 uppercase mr-auto font-semibold pr-1">Owner</div>' +
        '<div class="text-right leading-snug uppercase">' +  (ownership_name ? ownership_name : 'Privately Owned') + '</div>' +
        '</div>';

    html += '<div class="flex mb-1">' +
        '<div class="w-10 uppercase mr-auto font-semibold">Ownership</div>' +
        '<div class="flex items-center justify-center">' +
        '<div class="mr-1 w-3 h-3 rounded text-xs" ' +
        'style="background: ' + getColor(ownership) + '"></div>' +
        '<div>' + getName(ownership) + '</div>' +
        '</div>' +
        '</div>';

    html += '<div class="flex mb-1">' +
        '<div class="w-20 uppercase mr-auto font-semibold">Tenure</div>' +
        '<div>' + tenure + '</div>' +
        '</div>';

    if (area_acres) {
        html += '<div class="flex mb-1">' +
            '<div class="w-20 uppercase mr-auto font-semibold">Size</div>' +
            '<div>' + area_acres + ' acres</div>' +
            '</div>';
    }

    if(!isNaN(registered_addresses)){

        html += '<div class="flex mb-1">' +
            '<div class="w-20 uppercase mr-auto font-semibold">Properties</div>' +
            '<div>' + registered_addresses + '</div>' +
            '</div>';
    }

    if(!isNaN(leaseholders)){

        html += '<div class="flex mb-1">' +
            '<div class="w-20 uppercase mr-auto font-semibold">Leaseholders</div>' +
            '<div>' + leaseholders + '</div>' +
            '</div>';
    }




    html += '</div>';

    return html;
}


export function getSalePricePolygonStyle(item, fill, hover) {
    return {
        weight: 1,
        fillColor: fill,
        color: '#5b5b5b',
        fill: true,
        radius: 7,
        fillOpacity: hover ? 0.8 : 1
    };
}

export function getHmoPolygonStyle(hover) {
    return {
        weight: 1,
        fillColor: '#ff6800',
        color: '#fff',
        fill: true,
        radius: 5,
        fillOpacity: hover ? 0.8 : 1
    };
}

export function getEPCPolygonStyle(color, hover) {
    return {
        weight: 1,
        fillColor: color,
        color: '#fff',
        fill: true,
        radius: 5,
        fillOpacity: hover ? 0.8 : 1
    };
}

export function findPolylabel(feature){
    let output = [];
    if (feature.geometry.type === "Polygon"){
        output = polylabel(feature.geometry.coordinates);
    } else {
        let maxArea = 0, maxPolygon = [], coords = feature.geometry.coordinates;
        console.log('coords', coords, coords.length)

        for (let i = 0, l = coords.length; i < l; i++){
            const p = feature.geometry.coordinates[i];
            const area = turf.area({type: "Polygon", coordinates: p})
            if (area > maxArea){
                maxPolygon = p;
                maxArea = area;
            }
        }
        output = polylabel(maxPolygon);
    }
    return output;
}

export function getPlanningPolygonColor(color, hover) {
    return {
        weight: 1,
        fillColor: color,
        color: '#fff',
        fill: true,
        radius: 7,
        fillOpacity: hover ? 0.8 : 1
    };
}

export function getPolygonStyle(color, hover) {
    return {
        weight: 1,
        fillColor: color,
        color: '#fff',
        fill: true,
        radius: 6,
        fillOpacity: hover ? 0.8 : 1
    };
}

export function getPricePerSqftSalePolygonStyle(price, colors) {

    let find = colors.find((y) => {
        return price >= y.min && price <= y.max;
    })

    return {
        weight: 1,
        fillColor: find && find.color ? find.color : '#ECECEC',
        fillOpacity:  0.6,
        color: '#fff',
        fill: true
    };
}

export function getLeaseholderPolygonStyle(item, hover) {
    let style = {
        weight: 1,
        fillColor: getColor(item.ownership),
        color: '#fff',
        fill: true,
        radius: 8
    };

    if (hover) {
        style.fillOpacity = item.type !== 'Point' ? 0.4 : 0.9;
    } else {
        style.fillOpacity = item.type !== 'Point' ? 0.4 : 1;
    }

    if (item.type !== 'Point') {
        style.dashArray = '4';
    }
    if (item.type !== 'Point' && hover) {
        style.fillOpacity = 0.9;
    }

    return style;

}

export function getName(type) {
    switch (type) {
        case "private":
            return 'Private';
        case "corporate":
            return 'Corporate';
        case "company":
            return 'Company';
        case "ha":
            return 'Housing Association';
        case "council":
            return 'Local Council/Authority';
        case "ownership":
            return 'Company Owned';
    }
    return '';
}


export function getHighlightColor(type) {
    switch (type) {
        case "private":
            return '#1D4ED8';
        case "corporate":
            return '#B91C1C';
        case "company":
            return '#6D28D9';
        case "ha":
            return '#F6E05E';
        case "council":
            return '#BE185D';
        case "ownership":
            return '#C05621';
    }
    return '';
}

export function getSalesPriceColorByIndex(index){
    switch(index){
        case 0:
            return '#FFD8D8ff';
        case 1:
            return '#FFB9B9ff';
        case 2:
            return '#FE9A9Aff';
        case 3:
            return '#FE7B7Bff';
        case 4:
            return '#FD5C5Cff';
        case 5:
            return '#FD3D3Dff';
    }
}

export function getColor(type) {
    switch (type) {
        case "private":
            return '#3B82F6';
        case "corporate":
            return '#EF4444';
        case "company":
            return '#8B5CF6';
        case "ha":
            return '#F6E05E';
        case "council":
            return '#EC4899';
        case "ownership":
            return '#D97706';
    }
    return '';
}
