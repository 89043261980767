<template>
    <div>

        <div
                v-if="loading"
                class="flex h-full mt-12 pt-12 items-center justify-center">
            <div class="p-3 text-center">
                <property-loader/>
                <h3 class="font-semibold text-blue-800">Fetching Site Areas...</h3>
            </div>
        </div>

        <div class="columns" v-if="!loading">
            <div class="column w-full">
                <dashboard-widget
                        padding-class=""
                        title="Site Areas">
                    <template slot="cta">
                        <div class="flex text-gray-600">
                            <div class="mr-2">
                                <label class="text-xs uppercase">Toggle Overlays</label>
                            </div>
                            <div>
                                <el-switch
                                        v-model="showOverlays"
                                        active-color="#13ce66"
                                        inactive-color="#ff4949">
                                </el-switch>
                            </div>
                        </div>
                    </template>

                    <slot>
                        <ownership-topography-map
                                v-if="getSelectedTopographyData && getSelectedTopographyData.features && getSelectedTopographyData.center && getSelectedTopographyData.bbox"
                                v-model="getSelectedTopographyData"
                                :show-polygons="showOverlays"
                                :opacity="1"
                                :fill-opacity="0.3"
                                :fill-color="$store.state.auth.user.polygon_color"
                        />

                        <div class="el-table--areas" >
                            <el-table :data="buildAreas">
                                <el-table-column>
                                    <template slot-scope="scope">
                                        <span class="font-bold"> {{ scope.row.label }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column align="right" width="130">
                                    <template slot-scope="scope">
                                        {{ scope.row.value }}
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>

                    </slot>




                </dashboard-widget>
            </div>
        </div>


    </div>

</template>

<script>

    import PropertyLoader from "../../../incs/PropertyLoader";
    import OwnershipTopographyMap from "../incs/OwnershipTopographyMap";

    export default {
        name: "OwnershipTopographySiteAreas",
        components: {OwnershipTopographyMap, PropertyLoader},
        props: {
            loading: {
                type: Boolean,
                default: true
            },
            value: {
                type: Object,
                default() {
                    return []
                }
            },
            activeSiteId: {
                type: Number,
                default() {
                    return null;
                }
            }
        },
        watch: {
          value: {
              handler(val){
                  this.object = JSON.parse(JSON.stringify(val));
              },
              deep: true
          }
        },
        data() {
            return {
                showOverlays: true,
                object: JSON.parse(JSON.stringify(this.value)),
                activeBuildings: [],
                activeTabs: [],
                selected: 0,
                map: {
                    zoom: 3
                },
            }
        },
        computed: {
            buildAreas(){
                if(this.getSelectedTopographyData && this.getSelectedTopographyData.area){
                    const area = this.getSelectedTopographyData.area;
                    return [
                        {label: 'm²', value: (area.total ? area.total : 0).toLocaleString() + ' m²' },
                        {label: 'ft²', value: (area.sqft ? area.sqft : 0).toLocaleString() + ' ft²' },
                        {label: 'Acres', value: (area.acres ? area.acres : 0).toLocaleString() + ' acres' },
                    ];
                }
                return []
            },
            getSelectedTopographyData() {
                let overview = this.object && this.object.overview ? this.object.overview : null;
                if(!overview){
                    return  null;
                }
                let sites = this.object && this.object.sites ? this.object.sites : [];

                if(this.activeSiteId && sites.length > 0){
                    overview = sites.find((i)=>{
                        return i.site_id === this.activeSiteId;
                    });
                }

                overview.features = overview.polygon;

                return overview;
            }

        }
    }
</script>
<style lang="scss">
    .el-table--areas {
        thead {
            display: none !important;
        }
    }
</style>