<template>
    <div class="rounded bg-white w-56 overflow-hidden shadow">
        <h4 class="text-white text-sm p-2 border border-blue-900 bg-blue-800 font-semibold p-1">Ownership Filters</h4>
        <div class="p-2">

            <filter-component title="Size (Acres)">
                <div class="flex w-full">
                    <div class="w-2/5">

                        <input
                                v-model="$store.state.map.polygon_filters.area_min" v-on:keypress="isNumber"
                                placeholder="0"
                                min="0"
                                max="40"
                                type="number" class="p-1 rounded px-4 w-full text-sm border-gray-400 border pac-target-input" />

                   </div>
                    <div class="w-1/5 flex items-center justify-center">
                        <span class="text-xs text-gray-800 uppercase">to</span>
                    </div>
                    <div class="w-2/5">
                        <input
                                v-model="$store.state.map.polygon_filters.area_max" v-on:keypress="isNumber"
                                @change="()=>{
                                $store.state.map.polygon_filters.area_max = $store.state.map.polygon_filters.area_max > 40 ? null : $store.state.map.polygon_filters.area_max;
                                }"
                                placeholder="40+"
                                min="0"
                                max="40"
                                type="number" class="p-1 rounded px-4 w-full text-sm border-gray-400 border pac-target-input" />
                    </div>
                </div>
            </filter-component>

            <filter-component title="Tenure">
                <el-select
                        class="w-full"
                        filterable
                        size="medium"
                        placeholder="Select Tenure Types..."
                        v-model="$store.state.map.polygon_filters.tenure">
                    <el-option
                            v-for="item in $store.state.map.tenure_types"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        {{ item.label }}
                    </el-option>
                </el-select>
            </filter-component>

            <filter-component title="Owner Type">
                <el-select
                        class="w-full"
                        filterable
                        clearable
                        :collapse-tags="true"
                        placeholder="Filter Owner Types..."
                        multiple
                        size="medium"
                        :disabled="$store.state.map.loading_polygons"
                        v-model="$store.state.map.polygon_filters.ownership">
                    <el-option
                            v-for="item in $store.state.map.ownership_types"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        {{ item.label }}
                    </el-option>
                </el-select>
            </filter-component>

            <span
                    @click="show_advanced = !show_advanced"
                    class="cursor-pointer py-2 inline-block text-xs hover:underline hover:text-blue-600">{{ show_advanced ? 'Hide' : 'Show' }} Advanced Filters</span>

            <div  v-if="show_advanced" class="el--advanced-filters">
                <filter-component title="Registered Properties">
                    <div class="flex w-full">
                        <div class="w-2/5">

                            <input
                                    v-model="$store.state.map.polygon_filters.registered_properties_min"
                                    v-on:keypress="isNumber"
                                    placeholder="0"
                                    min="0"
                                    type="number" class="p-1 rounded px-4 w-full text-sm border-gray-400 border pac-target-input" />

                        </div>
                        <div class="w-1/5 flex items-center justify-center">
                            <span class="text-xs text-gray-800 uppercase">to</span>
                        </div>
                        <div class="w-2/5">
                            <input
                                    v-model="$store.state.map.polygon_filters.registered_properties_max"
                                    v-on:keypress="isNumber"
                                    @change="()=>{
                                $store.state.map.polygon_filters.registered_properties_max = $store.state.map.polygon_filters.registered_properties_max > 99 ? null : $store.state.map.polygon_filters.registered_properties_max;
                                }"
                                    placeholder="99+"
                                    min="0"
                                    type="number" class="p-1 rounded px-4 w-full text-sm border-gray-400 border pac-target-input" />
                        </div>
                    </div>
                </filter-component>

                <filter-component title="Leaseholders">
                    <div class="flex w-full">
                        <div class="w-2/5">

                            <input
                                    v-model="$store.state.map.polygon_filters.leaseholders_min"
                                    v-on:keypress="isNumber"
                                    placeholder="0"
                                    min="0"
                                    type="number" class="p-1 rounded px-4 w-full text-sm border-gray-400 border pac-target-input" />

                        </div>
                        <div class="w-1/5 flex items-center justify-center">
                            <span class="text-xs text-gray-800 uppercase">to</span>
                        </div>
                        <div class="w-2/5">
                            <input
                                    v-model="$store.state.map.polygon_filters.leaseholders_max"
                                    v-on:keypress="isNumber"
                                    @change="()=>{
                                $store.state.map.polygon_filters.leaseholders_max = $store.state.map.polygon_filters.leaseholders_max > 99 ? null : $store.state.map.polygon_filters.leaseholders_max;
                                }"
                                    placeholder="99+"
                                    min="0"
                                    type="number" class="p-1 rounded px-4 w-full text-sm border-gray-400 border pac-target-input" />
                        </div>
                    </div>
                </filter-component>

                <filter-component title="Use Types">
                    <el-select
                            filterable
                            clearable
                            class="w-full"
                            size="medium"
                            multiple
                            :collapse-tags="true"
                            :disabled="$store.state.map.loading_polygons"
                            v-model="$store.state.map.polygon_filters.use_types"
                            placeholder="Select Use Types..">
                        <el-option
                                v-for="item in $store.getters['map/getUseTypes']"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </filter-component>

                <filter-component title="Use Classes">
                    <el-select
                            filterable
                            clearable
                            class="w-full"
                            size="medium"
                            multiple
                            :collapse-tags="true"
                            :disabled="$store.state.map.loading_polygons"
                            v-model="$store.state.map.polygon_filters.use_classes"
                            placeholder="Select Use Types..">
                        <el-option
                                v-for="item in $store.getters['map/getUseClassTypes']"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </filter-component>
            </div>


        </div>


        <div class="flex p-1 mb-1">
            <div class="w-1/2 px-1">
                <el-button type="success" class="flex justify-center items-center w-full" @click="$emit('refresh')">
                    <div class="flex items-center justify-center">
                        <svg class="mr-2 w-3 h-3" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 24 24" fill="#fff">
                            <path d="M1 0l9 15.094v5.906l4 3v-8.906l9-15.094h-22zm18.479 2l-2.981 5h-8.996l-2.981-5h14.958z"/>
                        </svg>
                        <span>Update</span>
                    </div>
                </el-button>
            </div>
            <div class="w-1/2 px-1">
                <el-button type="danger" class="flex justify-center items-center w-full"
                           @click="reset()"
                >
                    <div class="flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg"  class="mr-2 w-3 h-3" fill="#fff" viewBox="0 0 24 24"><path d="M13.5 2c-5.288 0-9.649 3.914-10.377 9h-3.123l4 5.917 4-5.917h-2.847c.711-3.972 4.174-7 8.347-7 4.687 0 8.5 3.813 8.5 8.5s-3.813 8.5-8.5 8.5c-3.015 0-5.662-1.583-7.171-3.957l-1.2 1.775c1.916 2.536 4.948 4.182 8.371 4.182 5.797 0 10.5-4.702 10.5-10.5s-4.703-10.5-10.5-10.5z"/></svg>
                        <span>Reset</span>
                    </div>
                </el-button>
            </div>
        </div>

        <div class="p-2 pt-0">

            <el-button
                    @click="$store.state.map.show_metrics = true"
                    type="info" class="flex justify-center items-center w-full">
                    <div class="flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg"  class="mr-2 w-3 h-3" fill="#fff" viewBox="0 0 24 24"><path d="M5 20v2h-2v-2h2zm2-2h-6v6h6v-6zm6-1v5h-2v-5h2zm2-2h-6v9h6v-9zm6-2v9h-2v-9h2zm2-2h-6v13h6v-13zm0-11l-6 1.221 1.716 1.708-6.85 6.733-3.001-3.002-7.841 7.797 1.41 1.418 6.427-6.39 2.991 2.993 8.28-8.137 1.667 1.66 1.201-6.001z"/></svg>
                        <span>Data Insights</span>
                    </div>
            </el-button>
        </div>


    </div>
</template>

<script>
    import FilterComponent from "./FilterComponent";

    export default {
        name: "OwnershipFilter",
        components: {FilterComponent},
        data(){
            return {
                show_advanced: false
            }
        },
        methods: {
            reset(){
              this.$store.commit('map/resetFilter');
                this.$emit('refresh');
            },
            isNumber(evt) {
                evt = (evt) ? evt : window.event;
                const charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            }
        }
    }
</script>

<style>
    .el--advanced-filters {
        max-height: 200px;
        overflow: scroll;
    }
</style>