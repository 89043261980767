import api from "../../utils/api";

const initialState = {

};

export default {
    namespaced: true,
    state: initialState,
    mutations: {
        reset(state) {
            console.log('reset')
            Object.keys(initialState).forEach(key => {
                state[key] = initialState[key]
            })
        },
    },
    actions: {
        lookupsIndex: ({rootState}, params) => {
            console.log(rootState);
            return api.get('/api/v1/team/' + params.teamId + '/lookup-history', {
                params: params && params.params ? params.params : {all: true}
            });
        },

        customDrawAreasIndex: ({rootState}, params) => {
            console.log(rootState);
            return api.get('/api/v1/team/' + params.teamId + '/custom-draw-areas', {
                params: params && params.params ? params.params : {all: true}
            });
        },
        customDrawAreasCreate: ({rootState}, params) => {
            console.log(rootState);
            return api.post('/api/v1/team/' + params.teamId + '/custom-draw-areas', params);
        },

        customDrawAreasRemove: ({rootState}, params) => {
            console.log(rootState);
            return api.delete('/api/v1/team/' + params.teamId + '/custom-draw-areas/' + params.id);
        },

        index: ({rootState}, params) => {
            console.log(rootState);
            return api.get('/api/v1/team/' + params.teamId + '/members', {
                params: params && params.params ? params.params : {all: true}
            });
        },
        create: ({rootState}, params) => {
            console.log(rootState);
            return api.post('/api/v1/team/' + params.teamId + '/members', params);
        },
        remove: ({rootState}, params) => {
            console.log(rootState);
            return api.delete('/api/v1/team/' + params.teamId + '/member/' + params.memberId);
        },
        revokeSubscription:({rootState}, params) => {
    console.log(rootState);
            return api.delete('/api/v1/team/' + params.teamId + '/member/' + params.memberId + '/subscription');
        },
        addSubscription:({rootState}, params) => {
            console.log(rootState);
            return api.post('/api/v1/team/' + params.teamId + '/member/' + params.memberId + '/subscription', {
                seatId: params.seatId
            });
        },
        read: ({rootState}, params) => {
    console.log(rootState);
            return api.get('/api/v1/team/' + params.teamId + '/member/' + params.memberId);
        },


    }
}