<template>

    <div>

        <div
                v-if="loading"
                class="flex h-full mt-12 pt-12 items-center justify-center">
            <div class="p-3 text-center">
                <property-loader />
                <h3 class="font-semibold text-blue-800">Fetching Nearest Sub-stations...</h3>
            </div>
        </div>

        <div class="columns" v-if="!loading">
            <div class="column w-full">

                <dashboard-widget
                        padding-class=""
                        title="Nearest Sub-stations">

                    <div class="p-3">

                        <ownership-overview-map
                                ref="ownership-map"
                                :height="250"
                                :polygons="getPolygons"
                                :coords="value.center"
                                :bbox="value.bbox"
                        />

                    </div>

                    <div class="flex p-2 pt-0">
                        <div class="flex items-center p-1"><div class="w-4 h-4 rounded border border-gray-200" style="background-color: rgb(255,0,54);"></div><span class="text-xs ml-2  leading-tight">Sites</span></div>
                        <div class="flex items-center p-1"><div class="w-4 h-4 rounded border border-gray-200" style="background-color: #a300a5;"></div><span class="text-xs ml-2  leading-tight">Sub-station locations</span></div>
                    </div>


                    <el-table
                            class="text-xs"
                            border stripe :data="getTableData">
                        <el-table-column
                                prop="date"
                                label="Sub-station Location">
                            <template slot-scope="scope">
                                <span class="font-semibold">
                                    {{ scope.row.address }}
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="date"
                                width="100px"
                                align="right"
                                label="Distance">
                            <template slot-scope="scope">
                                {{ scope.row.distance }}m
                            </template>
                        </el-table-column>
                    </el-table>


                </dashboard-widget>

            </div>
        </div>

    </div>

</template>

<script>
    import PropertyLoader from "../../incs/PropertyLoader";
    import OwnershipOverviewMap from "./incs/OwnershipOverviewMap";
    export default {
        name: "OwnershipNearestSubStations",
        components: {OwnershipOverviewMap, PropertyLoader},
        props: {
            value: {
              type: Object,
              default(){
                  return {}
              }
            },
            loading: {
                type: Boolean,
                default(){
                    return true;
                }
            }
        },
        data(){
            return {
                flood_layers: [
                    {
                        url: "https://tiles.landmonitor.com/geoserver/lm/wms",
                        type: "wms",
                        layers: "lm:planning-flood-risk-2"
                    },
            {
                url: "https://tiles.landmonitor.com/geoserver/lm/wms",
                    type: "wms",
                layers: "lm:planning-flood-risk-3"
            }
        ]
            }
        },
        methods: {
            openPlanningFloodMap(){
                if(this.getEastingNorthing && this.getEastingNorthing.easting && this.getEastingNorthing.northing){
                    window.open('https://flood-map-for-planning.service.gov.uk/confirm-location?easting=' + this.getEastingNorthing.easting + '&northing=' + this.getEastingNorthing.northing);
                }
            },
            openLongTermFlooding(){
                if(this.getEastingNorthing && this.getEastingNorthing.easting && this.getEastingNorthing.northing){
                    window.open('https://check-long-term-flood-risk.service.gov.uk/map?easting=' + this.getEastingNorthing.easting + '&northing=' + this.getEastingNorthing.northing);
                }
            }
        },
        computed: {
            getTableData(){
                let stations = this.value.stations ? this.value.stations : [];
                return stations.map((x) => {
                    return x.properties
                })
            },
            getPolygons(){
                let polygons = this.value.polygons ? this.value.polygons : [];
                return this.value.stations && Array.isArray(this.value.stations) ? this.value.stations.map((x) => {
                    x.properties.color = '#a300a5';
                    return x
                }).concat(polygons) : polygons;
            },

            fetchCoverageBreakdown(){
              let overview = this.value && this.value.overview ? this.value.overview : null;
              if(!this.activeSiteId){
                 return overview;
              }
              let find  = this.value.sites.find((i) => i.site_id === this.activeSiteId);
                return find ? find : null;
            },
            getEastingNorthing(){
              let item = this.fetchCoverageBreakdown;
              return item && item.eastings_northings ? item.eastings_northings : null
            }
        }
    }
</script>