<template>
    <div>

        <navigation/>

        <page-header title="Unitary Authorities">
            <template v-slot:tabs>
                <property-prices-nav />
            </template>
        </page-header>
        <page>

            <location-type
                    url="/api/v1/types/unitary-authorities"
                    prefix="unitary-authorities"
            />
        </page>
    </div>

</template>
<script>
    import LocationType from "../../../components/LocationType";
    import PropertyPricesNav from "./PropertyPricesNav";
    export default {
        name: "UAs",
        components: {PropertyPricesNav, LocationType},
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.$store.commit('auth/setBreadcrumbs', [
                    {
                        title: 'Property Prices',
                        path: '/property-sales'
                    },
                    {
                        title: 'Unitary Authorities'
                    }
                ]);
            })
        }
    }
</script>
