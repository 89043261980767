<template>
    <map-modal
            :has-actions="false"
            :show="true"
            :has-header="!overview.loading && overview.details"
            @close="()=>{ $emit('close') }"
    >

        <template v-slot:header>
            <h1
                    v-if="getCompanyName"
                    class="text-lg font-semibold">
                {{ getCompanyName }}
            </h1>
            <h4 class="text-xs uppercase mb-2" v-if="getCompanyAddress">{{ getCompanyAddress }}</h4>
            <div class="-mt-3">
                <div class="columns text-xs">
                    <div v-if="getCompanyStatus" class="column"><span class="font-bold">Status:</span> {{ getCompanyStatus }}</div>
                    <div v-if="getDateIncorporated"  class="column"><span class="font-bold">Incorporated:</span> {{ getDateIncorporated }}</div>
                    <div v-if="getCompanyNumber" class="column"><span class="font-bold">Company No:</span> {{ getCompanyNumber }}</div>
                </div>
            </div>
        </template>


        <template v-slot:content>
            <div v-if="overview.loading" class="flex h-full justify-center items-center flex-1">
                <property-loader />
            </div>

            <div v-if="!overview.loading && !overview.details">
                <empty-state class="p-3"
                             title="No details found"
                             message="Sorry, but we could not find any company details"
                             :support="false"/>
            </div>

            <div v-if="!overview.loading && overview.details">

                <el-tabs v-model="activeTab">

                    <el-tab-pane :label="'Properties'" name="list">


                        <empty-state class="p-3"
                                     title="No properties found"
                                     message="Sorry, but we could not find any Properties"
                                     :support="false"
                                     v-if="!table.loading && !table.results.length"/>


                        <div class="columns">
                            <div class="column w-1/4">
                                <stat-box custom-class="bg-gray-100 rounded border border-gray-200"  title="Total Properties"
                                          :value="overview.details && overview.details.property_snapshot && overview.details.property_snapshot.total ? overview.details.property_snapshot.total.toLocaleString() : 0"
                                          :loading="false" />
                            </div>
                            <div class="column w-1/4">
                                <stat-box custom-class="bg-gray-100 rounded border border-gray-200" title="Leasehold"
                                          :value="overview.details && overview.details.property_snapshot && overview.details.property_snapshot.leaseholders ? overview.details.property_snapshot.leaseholders.toLocaleString() : 0"
                                          :loading="false" />
                            </div>
                            <div class="column w-1/4">
                                <stat-box custom-class="bg-gray-100 rounded border border-gray-200"  title="Freehold"
                                          :value="overview.details && overview.details.property_snapshot && overview.details.property_snapshot.freeholders ? overview.details.property_snapshot.freeholders.toLocaleString() : 0"
                                          :loading="false" />
                            </div>
                            <div class="column w-1/4">
                                <stat-box custom-class="bg-gray-100 rounded border border-gray-200"  title="Last Purchase"
                                          :value="overview.details && overview.details.property_snapshot && overview.details.property_snapshot.last_purchase ? formatDate(overview.details.property_snapshot.last_purchase) : ''"
                                          :loading="false" />
                            </div>
                        </div>


                        <div class="p-3 py-12 text-center" v-if="table.loading">
                            <property-loader />
                        </div>


                        <div v-if="table.results.length > 0 && !table.loading">

                            <div class="py-3">
                                <el-alert effect="dark" type="info" :closable="false">
                                    {{ table.meta.totalResult.toLocaleString() }} properties found. Displaying page {{ table.meta.current }} of {{ table.meta.pageCount }}
                                </el-alert>
                            </div>
                            <div class="columns p-3">
                                <div class="column ml-auto">
                                    <el-pagination
                                            background
                                            layout="prev, pager, next"
                                            @current-change="(page)=>{ fetchTable(page) }"
                                            :page-size="table.rpp"
                                            :current-page="table.meta.current"
                                            :total="table.meta.totalResult">
                                    </el-pagination>
                                </div>
                            </div>
                            <el-table
                                    :default-sort="table.default_sort"
                                    @sort-change="onSortChange"
                                    :data="table.results">
                                <el-table-column
                                        prop="property_address"
                                        sortable="custom"
                                        label="Address">
                                    <template slot-scope="scope">
                                        {{ scope.row.property_address }}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        prop="tenure"
                                        width="120"
                                        sortable="custom"
                                        label="Tenure">
                                    <template slot-scope="scope">
                                        <div class="py-2">
                                            <pretty-tenure v-model="scope.row.tenure" />
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        prop="title_number"
                                        width="120"
                                        sortable="custom"
                                        label="Title">
                                    <template slot-scope="scope">
                                        {{ scope.row.title_number }}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        prop="price_paid"
                                        width="120"
                                        align="right"
                                        sortable="custom"
                                        label="Price Paid">
                                    <template slot-scope="scope">
                                        {{ scope.row.price_paid ?  '£' + scope.row.price_paid.toLocaleString()  : '' }}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        prop="date_proprietor_added"
                                        align="right"
                                        width="140"
                                        sortable="custom"
                                        label="Dated Added">
                                    <template slot-scope="scope">
                                        <date-human-view
                                                v-if="scope.row.date_proprietor_added"
                                                v-model="scope.row.date_proprietor_added" />
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        align="right"
                                        width="110">
                                    <template slot-scope="scope">
                                        <el-button type="success" size="mini"
                                                   @click="$emit('view', scope.row.title_number)"
                                                   icon="el-icon-view">View</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="columns p-3">
                                <div class="column ml-auto">
                                    <el-pagination
                                            background
                                            layout="prev, pager, next"
                                            @current-change="(page)=>{ fetchTable(page) }"
                                            :page-size="table.rpp"
                                            :current-page="table.meta.current"
                                            :total="table.meta.totalResult">
                                    </el-pagination>
                                </div>
                            </div>
                        </div>

                    </el-tab-pane>
                    <el-tab-pane
                            :disabled="activity.loading"
                            label="Activity" name="activity">

                        <div v-if="activity.fetching"
                             class="rounded-md p-4 w-full my-12 mx-auto">
                            <div class="animate-pulse flex space-x-4">
                                <div class="rounded-full bg-gray-400 h-12 w-12"></div>
                                <div class="flex-1 space-y-4 py-1">
                                    <div class="h-4 bg-gray-400 rounded w-3/4"></div>
                                    <div class="space-y-2">
                                        <div class="h-4 bg-gray-400 rounded"></div>
                                        <div class="h-4 bg-gray-400 rounded w-5/6"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!activity.fetching && !activity.results">
                            <empty-state title="Sorry, no results found" />
                        </div>

                        <div v-if="!activity.fetching && activity.results">

                            <ownership-activity-chart v-model="activity.results" />
                        </div>

                    </el-tab-pane>
                    <el-tab-pane
                            :disabled="table.loading"
                            label="Finances" name="finances">


                        <div v-if="getFinances.length > 0">

                            <div class="columns mb-1" v-if="getFinanceStats.length > 0">
                                <div class="column w-1/2 md:w-1/4"
                                     :key="i"
                                     v-for="(val, i) of getFinanceStats">
                                    <div class="w-full rounded border border-gray-300 overflow-hidden">
                                        <div class="h-2 w-full" :style="'background-color:' + val.color "></div>
                                       <div class="p-3">
                                           <h4 class="uppercase text-xs">{{ val.label }}</h4>
                                           <h3 class="text-3xl font-semibold">{{ val.value }}</h3>
                                       </div>
                                    </div>
                                </div>
                            </div>

                            <apexchart type="line" height="350" :options="getFinanceChart.options" :series="getFinanceChart.series"></apexchart>
                        </div>
                        <div v-else>
                            <empty-state />
                        </div>

                    </el-tab-pane>
                    <el-tab-pane
                            :disabled="table.loading"
                            label="Mortgages and Charges" name="mortgages">

                        <div v-if="getMortgagesChargesTable.results.length > 0">

                            <div class="py-3">
                                <el-alert effect="dark" type="info" :closable="false">
                                    {{ getMortgagesChargesTable.meta.total.toLocaleString() }} mortgages/charges found.
                                </el-alert>
                            </div>
                            <div class="columns p-3">
                                <div class="column ml-auto">
                                    <el-pagination
                                            background
                                            layout="prev, pager, next"
                                            @current-change="(page)=>{ mortgages.page = page }"
                                            :page-size="getMortgagesChargesTable.meta.rpp"
                                            :current-page="getMortgagesChargesTable.meta.current"
                                            :total="getMortgagesChargesTable.meta.total">
                                    </el-pagination>
                                </div>
                            </div>
                            <el-table
                                    :default-sort="mortgages.default_sort"
                                    @sort-change="(object)=> {  mortgages.default_sort = object.prop && object.order ? object : null }"
                                    :data="getMortgagesChargesTable.results">
                                <el-table-column
                                        prop="type"
                                        sortable="custom"
                                        label="Type">
                                    <template slot-scope="scope">
                                        {{ scope.row.type }}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        prop="created"
                                        width="120"
                                        sortable="custom"
                                        label="Created">
                                    <template slot-scope="scope">
                                        <date-human-view
                                                v-if="scope.row.created"
                                                v-model="scope.row.created" />
                                    </template>
                                </el-table-column>

                                <el-table-column
                                        prop="registered"
                                        width="120"
                                        sortable="custom"
                                        label="Registered">
                                    <template slot-scope="scope">
                                        <date-human-view
                                                v-if="scope.row.registered"
                                                v-model="scope.row.registered" />
                                    </template>
                                </el-table-column>

                                <el-table-column
                                        prop="persons_entitled"
                                        sortable="custom"
                                        label="Persons Entitled">
                                    <template slot-scope="scope">
                                        {{ scope.row.persons_entitled.join(', ') }}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        prop="status"
                                        width="140"
                                        sortable="custom"
                                        label="Status">
                                    <template slot-scope="scope">

                                        <div class="py-1 text-center">
                                              <span class="p-1 text-sm select-none rounded border"
                                                    :class="scope.row.status === 'OUTSTANDING' ? 'bg-orange-300 text-orange-600 border-orange-600' : 'bg-green-300 text-green-600 border-green-600' ">
  {{ scope.row.status }}    </span>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="columns p-3">
                                <div class="column ml-auto">
                                    <el-pagination
                                            background
                                            layout="prev, pager, next"
                                            @current-change="(page)=>{ mortgages.page = page }"
                                            :page-size="getMortgagesChargesTable.meta.rpp"
                                            :current-page="getMortgagesChargesTable.meta.current"
                                            :total="getMortgagesChargesTable.meta.total">
                                    </el-pagination>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <empty-state />
                        </div>

                    </el-tab-pane>
                    <el-tab-pane
                            :disabled="table.loading"
                            label="Directors" name="directors">

                        <div v-if="getDirectorsTable.results.length > 0">

                            <div class="py-3">
                                <el-alert effect="dark" type="info" :closable="false">
                                    {{ getDirectorsTable.meta.total.toLocaleString() }} directors found.
                                </el-alert>
                            </div>
                            <div class="columns p-3">
                                <div class="column ml-auto">
                                    <el-pagination
                                            background
                                            layout="prev, pager, next"
                                            @current-change="(page)=>{ directors.page = page }"
                                            :page-size="getDirectorsTable.meta.rpp"
                                            :current-page="getDirectorsTable.meta.current"
                                            :total="getDirectorsTable.meta.total">
                                    </el-pagination>
                                </div>
                            </div>
                            <el-table
                                    :default-sort="directors.default_sort"
                                    @sort-change="(object)=> {  directors.default_sort = object.prop && object.order ? object : null }"
                                    :data="getDirectorsTable.results">
                                <el-table-column
                                        prop="name"
                                        sortable="custom"
                                        label="Name">
                                    <template slot-scope="scope">
                                        {{ scope.row.name }}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        prop="role"
                                        width="150"
                                        sortable="custom"
                                        label="Role">
                                    <template slot-scope="scope">
                                        {{ scope.row.role }}
                                    </template>
                                </el-table-column>


                                <el-table-column
                                        prop="date_of_birth"
                                        width="150"
                                        sortable="custom"
                                        label="Date of Birth">
                                    <template slot-scope="scope">
                                        {{ scope.row.date_of_birth }}
                                    </template>
                                </el-table-column>


                                <el-table-column
                                        prop="date_appointed"
                                        width="150"
                                        sortable="custom"
                                        label="Date Appointed">
                                    <template slot-scope="scope">
                                        <date-human-view
                                                v-if="scope.row.date_appointed"
                                                v-model="scope.row.date_appointed" />
                                    </template>
                                </el-table-column>

                                <el-table-column
                                        prop="date_resigned"
                                        width="140"
                                        sortable="custom"
                                        label="Date Resigned">
                                    <template slot-scope="scope">
                                        <date-human-view
                                                v-if="scope.row.resigned"
                                                v-model="scope.row.resigned" />
                                    </template>
                                </el-table-column>


                            </el-table>
                            <div class="columns p-3">
                                <div class="column ml-auto">
                                    <el-pagination
                                            background
                                            layout="prev, pager, next"
                                            @current-change="(page)=>{ directors.page = page }"
                                            :page-size="getDirectorsTable.meta.rpp"
                                            :current-page="getDirectorsTable.meta.current"
                                            :total="getDirectorsTable.meta.total">
                                    </el-pagination>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <empty-state />
                        </div>

                    </el-tab-pane>
                </el-tabs>
            </div>

        </template>

    </map-modal>
</template>

<script>
    import moment from 'moment';
    import MapModal from "./MapModal";
    import PropertyLoader from "../incs/PropertyLoader";
    import EmptyState from "../EmptyState";

    import { paginate } from './../../utils/pagination';
    import PrettyTenure from "../PrettyTenure";
    import OwnershipActivityChart from "./OwnershipLayerComponents/OwnershipActivityChart";

    export default {
        name: "CorporateOwnershipModal",
        components: {OwnershipActivityChart, PrettyTenure, EmptyState, PropertyLoader, MapModal},
        props: {
            value: {
                type: Object,
                default(){
                    return {}
                }
            },
            show: {
                type: Boolean,
                default: false
            },
            hash: {
                type: String,
                default(){
                    return null;
                }
            }
        },
        data(){
          return {
              overview: {
                  loading: true,
                  details: {}
              },
              activity: {
                  fetching: true,
                  results: null
              },
              mortgages: {
                  rpp: 10,
                  page: 1,
                  default_sort: {
                      prop: 'created',
                      order: 'descending'
                  },
              },
              directors: {
                  rpp: 10,
                  page: 1,
                  default_sort: {
                      prop: 'date_appointed',
                      order: 'descending'
                  },
              },
              table: {
                  default_sort: {
                      prop: 'date_proprietor_added',
                      order: 'descending'
                  },
                  loading: true,
                  results: [

                  ],
                  rpp: 10,
                  meta: {}
              },
              activeTab: 'list'
          }
        },
        mounted(){
            this.fetch();
        },
        computed: {
            getDirectorsTable(){
                return paginate({
                    results: this.getDirectors,
                    page: this.directors.page,
                    rpp: this.directors.rpp,
                    sort: this.directors.default_sort ? this.directors.default_sort.prop : 'date_appointed',
                    order: this.directors.default_sort ? this.directors.default_sort.order : 'descending'
                })
            },
            getMortgagesChargesTable(){
                return paginate({
                    results: this.getMortgagesCharges,
                    page: this.mortgages.page,
                    rpp: this.mortgages.rpp,
                    sort: this.mortgages.default_sort ? this.mortgages.default_sort.prop : 'created',
                    order: this.mortgages.default_sort ? this.mortgages.default_sort.order : 'descending'
                })
            },
            getFinanceChart(){
                return {
                    series: [
                        {
                            name: "Cash",
                            data: this.getFinances.map((l)=>{
                                return l.cash;
                            }),
                        },
                        {
                            name: "Net worth",
                            data: this.getFinances.map((l)=>{
                                return l.net_worth;
                            }),
                        },
                        {
                            name: "Total Current Assets",
                            data: this.getFinances.map((l)=>{
                                return l.assets;
                            }),
                },
                        {
                            name: "Total Current Liabilities",
                            data: this.getFinances.map((l)=>{
                                return l.liabilities;
                            }),
            }
                    ],
                    options: {
                        chart: {
                            height: 350,
                            type: 'line',
                            zoom: {
                                enabled: false
                            }
                        },
                        dataLabels: {
                            enabled: false
                        },
                        stroke: {
                            curve: 'straight'
                        },
                        markers: {
                            size: 5
                        },
                        colors:['#0060ba', '#43854d', '#ff8300', '#ffe404'],
                        title: {
                            text: this.getCompanyName,
                            align: 'center'
                        },
                        grid: {
                            row: {
                                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                                opacity: 0.5
                            },
                        },
                        yaxis: {
                            title: {
                                text: 'GBP (£)',
                            },
                            labels: {
                                formatter(val) {
                                    return '£' + val.toLocaleString();
                                },
                            },
                        },
                        xaxis: {
                            title: {
                                text: 'Year',
                            },
                            categories: this.getFinances.map((l)=>{
                                return l.year;
                            })
                        }
                    },
                }
            },
            getCompanyName(){
                if(this.overview.details && this.overview.details.company_name && this.overview.details.finances && this.overview.details.finances.summary && this.overview.details.finances.summary.company_name){
                    return this.overview.details.finances.summary.company_name;
                }
                return this.overview.details && this.overview.details.company_name ? this.overview.details.company_name : null;
            },
            getCompanyAddress(){
                if(this.overview.details && this.overview.details.finances && this.overview.details.finances.summary && this.overview.details.finances.summary.address){
                    return this.overview.details.finances.summary.address;
                }
                return this.overview.details && this.overview.details.company_address ? this.overview.details.company_address : null;
            },
            getCompanyNumber(){
                return this.overview.details && this.overview.details.company_number ? this.overview.details.company_number : null;
            },
            getCompanyStatus(){
                if(this.overview.details && this.overview.details.finances && this.overview.details.finances.summary && this.overview.details.finances.summary.company_status){
                    return this.overview.details.finances.summary.company_status;
                }
                return  null;
            },
            getDateIncorporated(){
                if(this.overview.details && this.overview.details.finances && this.overview.details.finances.summary && this.overview.details.finances.summary.incorporated_on){
                    return this.overview.details.finances.summary.incorporated_on;
                }
                return  null;
            },
            getDirectors(){
                if(this.overview.details && this.overview.details.finances && this.overview.details.finances.directors){
                    return this.overview.details.finances.directors;
                }
                return  [];
            },
            getFinances(){
                if(this.overview.details && this.overview.details.finances && this.overview.details.finances.finances && this.overview.details.finances.finances.data){
                    return this.overview.details.finances.finances.data;
                }
                return  [];
            },
            getFinanceStats(){
                if(this.overview.details && this.overview.details.finances && this.overview.details.finances.finances && this.overview.details.finances.finances.stats){
                    let colors = ['#0060ba', '#43854d', '#ff8300', '#ffe404'];
                        return this.overview.details.finances.finances.stats.map((v, i)=> {
                            v.color = colors[i];
                            return v;
                        })
                }
                return  [];
            },
            getMortgagesCharges(){
                if(this.overview.details && this.overview.details.finances && this.overview.details.finances.mortgages_charges){
                    return this.overview.details.finances.mortgages_charges;
                }
                return  [];
            },
        },
        methods: {
            formatDate(value){
                return moment(value).format("MMM Do YYYY")
            },
            onSortChange(object) {
                this.table.default_sort = object.prop && object.order ? object : null;
                this.fetchTable(1);
            },
            fetchActivity(){
                const vm = this;
                vm.activity.fetching = true;
                return vm.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/corporate-ownership', {
                    params: {
                        view: 'activity',
                        hash: this.hash
                    }
                })
                    .then((response)=>{
                        vm.activity.fetching = false;
                        vm.activity.results = response.data.data;
                    })
                    .catch(()=>{
                        vm.activity.fetching = false;
                        vm.activity.results = null;
                    })
            },
            fetchTable(page) {
                const vm = this;
                vm.table.loading = true;

                return vm.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/company/' + this.hash + '/properties', {
                    params: {
                        page: page || 1,
                        rpp: vm.table.rpp,
                        sort: vm.table.default_sort && vm.table.default_sort.prop ? vm.table.default_sort.prop : null,
                        order: vm.table.default_sort && vm.table.default_sort.order ? vm.table.default_sort.order === 'descending' ? 'desc' : 'asc' : null,
                    }
                }).then((response) => {
                    vm.table.loading = false;
                    vm.table.results = response.data.data.results;
                    vm.table.meta = response.data.data.meta;
                }).catch(() => {
                    vm.table.loading = false;
                    vm.table.results = [];
                    vm.table.meta = {};
                })

            },
            fetch() {
                const vm = this;
                vm.overview.loading = true;
                return vm.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/company/' + this.hash)
                    .then((response) => {
                        vm.overview.loading = false;
                        vm.overview.details = response.data.data;
                        vm.fetchTable(1);
                        vm.fetchActivity();
                    }).catch(() => {
                        vm.overview.loading = false;
                        vm.overview.details = null
                    })
            },
        }
    }
</script>

<style scoped>

</style>