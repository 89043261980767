import axios from 'axios'
import store from './../store/index'
import router from './../router/index'

//axios.defaults.baseURL = 'https://api.Land Monitor.co.uk'
axios.defaults.baseURL = process.env.API_URL;

axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? 'https://api.landmonitor.com' : 'https://localhost';


function hasBotHeader(config) {
  return config.headers['x-report-bot'];
}

// Append the Access Token on all API Requests
axios.interceptors.request.use( (config) => {

  if (store.getters['auth/accessToken']) {
    config.headers.Authorization = 'Bearer ' + store.getters['auth/accessToken']
  }

  if(hasBotHeader(config) && config.headers.Authorization){
    delete config.headers.Authorization;
  }

  return config
}, (error) => {
  return Promise.reject(error)
})

// Handle Refresh token here.
axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  const originalRequest = error.config;

  if (error.response) {
    console.log('error', error.response)

    console.log('originalRequest.url', originalRequest.url)
    console.log('originalRequest._retry', originalRequest._retry)

    if (error.response.status === 401 && originalRequest.url === '/api/v1/auth/refresh-token') {
      router.push('/login?mode=refresh-expired&redirect=/');
      return Promise.reject(error)
    }

    if (error.response.status === 401 && !originalRequest._retry) {

      originalRequest._retry = true;

      return axios.post('/api/v1/auth/refresh-token',
          {
            refresh_token: store.getters['auth/refreshToken']
          })
          .then(response => {

            console.log(response);

            store.commit('auth/setAccessToken', response.data.data.token)
            store.commit('auth/setRefreshToken', response.data.data.refresh_token)

            return axios(originalRequest);

          })
          .catch(() => {
            router.push('/login?mode=refresh-expired&redirect=/');
            return Promise.reject(error);
          })

    }
  }
  return Promise.reject(error)
})

export default axios
