<template>


        <page>

            <template slot="header">
                <page-header title="`Team Invites" />
            </template>

            <template slot="content">
                <div class="columns">
                    <div class="column w-full">
                        <dashboard-widget title="Team Invites">


                            <div class="p-8" v-if="loading">
                                <property-loader />
                            </div>
                            <div class="columns" v-if="!loading">

                                <div class="mx-auto"   v-if="!invites.length">
                                    <empty-state class="p-3"
                                                 title="No invites found"
                                                 message="You currently have no team invites"
                                                 :support="false"
                                               />
                                </div>

                                <div class="w-full" v-if="invites.length > 0">
                                    <div class="p-4">
                                        <el-alert effect="dark" type="info" :closable="false">
                                           You currently have {{ invites.length.toLocaleString() }} invites
                                        </el-alert>
                                    </div>
                                    <el-table :data="invites">
                                        <el-table-column label="Team">
                                            <template slot-scope="scope">
                                               <span class="font-semibold"> {{ scope.row.name }}</span>
                                            </template>
                                        </el-table-column>

                                        <el-table-column
                                                width="130"
                                                label="Invited">
                                            <template slot-scope="scope">
                                             <date-human-view v-model="scope.row.invited" />
                                            </template>
                                        </el-table-column>

                                        <el-table-column
                                                width="130"
                                                align="right"
                                                label="Invited">
                                            <template slot-scope="scope">
                                                <el-button
                                                        @click="join(scope.row.id)"
                                                        type="success">Join</el-button>
                                            </template>
                                        </el-table-column>

                                    </el-table>
                                </div>


                            </div>


                        </dashboard-widget>
                    </div>
                </div>
            </template>

        </page>

</template>

<script>

    import PropertyLoader from "../../components/incs/PropertyLoader";
    import EmptyState from "../../components/EmptyState";

    export default {
        name: 'UserInvites',
        components: {EmptyState, PropertyLoader},
        data() {
            return {
                loading: true,
                invites: []
            }
        },
        methods: {
            join(inviteId){
                const vm = this;
                vm.loading = true;

                vm.$store.dispatch('invites/join', {
                    inviteId: inviteId
                })
                    .then(() => {

                        vm.$notify({
                            type: 'success',
                            title: 'Joined Team Successfully',
                            message: 'You have successfully joined your new team.',
                            position: 'top-right',
                            offset: 140,
                        });

                         vm.$http.get('/api/v1/auth/user')
                            .then((response)=>{
                                vm.$store.commit('auth/setUser', response.data.data);
                            })
                        vm.fetch();



                    }).catch(() => {
                    vm.loading = false;

                })
            },
            fetch(){
                const vm = this;
                vm.$store.dispatch('invites/index')
                    .then((response) => {
                        vm.invites = response.data.data;
                        vm.loading = false;
                    }).catch(() => {
                    vm.loading = false;

                })
            }
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.$store.commit('auth/setBreadcrumbs', [
                    {
                        title: 'Your Profile'
                    }
                ]);
                vm.fetch();
            })
        }
    }
</script>