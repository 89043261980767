<template>
    <div>
    <div v-loading="loading">

        <h4 class="py-3 uppercase text-xs uppercase text-gray-600">Search Area</h4>
        <floating-label-input label="Search Bounds"  class="mb-2">
            <map-bounds-select
                    :disabled="loading"
                    @change="()=>{ $emit('change') }"
            />
        </floating-label-input>
        <h4 class="py-3 uppercase text-xs uppercase text-gray-600">Filters</h4>
            <vue-query-builder
                    v-model="query"
                    :maxDepth="1"
                    :rules="filters"/>
    </div>

    <div class="flex w-full justify-end my-3">
        <el-button
                type="success"
                icon="el-icon-search"
                :disabled="loading"
                :loading="loading"
                @click="()=>{ $emit('change') }"
        >Search
        </el-button>
    </div>

</div>
</template>

<script>
    import MapBoundsSelect from "./MapBoundsSelect";
    import VueQueryBuilder from 'vue-query-builder';

    export default {
        name: "DataLayerFilters",
        components: {MapBoundsSelect, VueQueryBuilder},
        props: {
            loading: {
                type: Boolean,
                default(){
                    return true;
                }
            },
            filters: {
                type: Array,
                default(){
                    return []
                }
            }
        },
        data(){
            return {
                query: {},
            }
        },
        methods: {
            getFilters(){
                const match = this.query && this.query.logicalOperator ? this.query.logicalOperator : null;
                if (!match) {
                    return null;
                }
                return {
                    match: match,
                    rules: this.query && this.query.children ? this.query.children.map((i) => {
                        return i.query
                    }).filter((item) => {
                        return item && ((typeof item.value === 'string' && item.value.length > 0) || item.type === 'custom-component' || (typeof item.value === 'boolean'));
                    }) : []
                }
            }
        }
    }
</script>

<style scoped>

</style>