var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overflow-hidden overflow-y-scroll el-block--overlay-legend"},_vm._l((_vm.$store.state.map.active_overlays),function(o,i){return _c('div',{key:i},[(o === 'salePrices' && _vm.$store.state.map.data_metrics.sale_prices.legend)?_c('overlay-detail',{staticClass:"mb-2",attrs:{"keyed":true,"show-close":false},on:{"close":function (){
                _vm.$store.commit('map/toggleLayerAvailability', 'salePrices')
                }}},[_c('template',{slot:"title"},[_vm._v("Sale Prices")]),_c('template',{slot:"legend"},_vm._l((_vm.$store.state.map.data_metrics.sale_prices.legend),function(i,k){return _c('div',{key:k,staticClass:"flex items-center p-1"},[_c('div',{staticClass:"w-4 h-4 rounded",style:({
                    background: _vm.getSalesPriceColorByIndex(k)
                    })}),_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v("£"+_vm._s(i.min)+" - £"+_vm._s(i.max))])])}),0)],2):_vm._e(),(o === 'ownership')?_c('overlay-detail',{ref:"overlay-polygons-ownership",refInFor:true,staticClass:"mb-2",attrs:{"keyed":true,"show-close":false}},[_c('template',{slot:"title"},[_vm._v("Ownership")]),_c('template',{slot:"legend"},[_c('div',{staticClass:"flex items-center p-1"},[_c('div',{staticClass:"w-4 h-4 rounded border border-gray-800 bg-blue-500"}),_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v("Private")])]),_c('div',{staticClass:"flex items-center p-1"},[_c('div',{staticClass:"w-4 h-4 rounded border border-gray-800 bg-red-500"}),_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v("Corporate")])]),_c('div',{staticClass:"flex items-center p-1"},[_c('div',{staticClass:"w-4 h-4 rounded border border-gray-800 bg-purple-500"}),_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v("Company")])]),_c('div',{staticClass:"flex items-center p-1"},[_c('div',{staticClass:"w-4 h-4 rounded border border-gray-800 bg-yellow-400"}),_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v("Housing Association")])]),_c('div',{staticClass:"flex items-center p-1"},[_c('div',{staticClass:"w-4 h-4 rounded border border-gray-800 bg-pink-500"}),_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v("Local Council/Authority")])])])],2):_vm._e(),(o === 'salePrices' && !_vm.$store.state.map.data_metrics.sale_prices.chart.fetching && _vm.$store.getters['map/getSaleDataMarkers'].length > 0)?_c('overlay-detail',{staticClass:"mb-2",attrs:{"keyed":true,"show-close":false},on:{"close":function (){
                _vm.$store.commit('map/toggleLayerAvailability', 'salePrices')
                }}},[_c('template',{slot:"title"},[_vm._v("Sale Prices")]),_c('template',{slot:"legend"},_vm._l((_vm.$store.getters['map/getSaleDataLegend']),function(i,k){return _c('div',{key:k,staticClass:"flex items-center p-1"},[_c('div',{staticClass:"w-4 h-4 rounded border border-gray-800",style:({background: i.color })}),_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v(_vm._s(i.min)+"-"+_vm._s(i.max))])])}),0)],2):_vm._e(),(o === 'epcs')?_c('overlay-detail',{staticClass:"mb-2",attrs:{"keyed":true,"show-close":false}},[_c('template',{slot:"title"},[_vm._v("EPC Ratings")]),_c('template',{slot:"legend"},[_c('div',{staticClass:"flex flex-wrap"},_vm._l((_vm.$store.getters['map/getEPCRatingDataLegend']),function(i,k){return _c('div',{key:k,staticClass:"w-1/2 flex p-1 items-center"},[_c('div',{staticClass:"w-4 h-4 rounded border border-gray-800",style:({background: i.color })}),_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v(_vm._s(i.label))])])}),0)])],2):_vm._e(),(o === 'planning')?_c('overlay-detail',{staticClass:"mb-2",attrs:{"keyed":true,"show-close":false}},[_c('template',{slot:"title"},[_vm._v("Planning Applications")]),_c('template',{slot:"legend"},[_c('div',{staticClass:"flex flex-wrap"},_vm._l((_vm.$store.getters['map/getPlanningApplicationDataLegend']),function(i,k){return _c('div',{key:k,staticClass:"w-full flex p-1 items-center"},[_c('div',{staticClass:"w-4 h-4 rounded border border-gray-800",style:({background: i.color })}),_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v(_vm._s(i.label))])])}),0)])],2):_vm._e(),(o === 'leaseholders' && !_vm.$store.state.map.data_metrics.leaseholders.fetching_polygons && _vm.$store.getters['map/getLeaseholderDataMarkers'].length > 0)?_c('overlay-detail',{staticClass:"mb-2",attrs:{"keyed":true,"show-close":false},on:{"close":function (){
                _vm.$store.commit('map/toggleLayerAvailability', 'leaseholders')
                }}},[_c('template',{slot:"title"},[_vm._v("Leaseholders")]),_c('template',{slot:"legend"},[_c('div',{staticClass:"flex items-center p-1"},[_c('div',{staticClass:"w-2 h-2 border transform rotate-45 border-white bg-blue-400"}),_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v("Leaseholder")])])])],2):_vm._e(),(o === 'listedBuildings')?_c('overlay-detail',{staticClass:"mb-2",attrs:{"keyed":true},on:{"close":function (){
                _vm.$store.commit('map/toggleLayerAvailability', 'listedBuildings')
                }}},[_c('template',{slot:"title"},[_vm._v("Listed Buildings")]),_c('template',{slot:"legend"},[_c('div',{staticClass:"flex items-center p-1"},[_c('div',{staticClass:"w-3 h-3 border rounded-full border-white bg-orange-700"}),_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v("Listed Buildings")])])])],2):_vm._e(),(o === 'aonb')?_c('overlay-detail',{staticClass:"mb-2",attrs:{"keyed":true},on:{"close":function (){
                _vm.$store.commit('map/toggleLayerAvailability', 'aonb')
                }}},[_c('template',{slot:"title"},[_vm._v("Areas of Outstanding Natural Beauty")]),_c('template',{slot:"legend"},[_c('div',{staticClass:"flex items-center p-1"},[_c('div',{staticClass:"w-4 h-4 rounded border border-gray-800 bg-orange-300"}),_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v("AONB")])])])],2):_vm._e(),(o === 'conservation')?_c('overlay-detail',{staticClass:"mb-2",attrs:{"keyed":true},on:{"close":function (){
                _vm.$store.commit('map/toggleLayerAvailability', 'conservation')
                }}},[_c('template',{slot:"title"},[_vm._v("Conservation Area")]),_c('template',{slot:"legend"},[_c('div',{staticClass:"flex items-center p-1"},[_c('div',{staticClass:"w-4 h-4 rounded border border-gray-800 bg-orange-500"}),_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v("Conservation Area")])])])],2):_vm._e(),(o === 'greenbelt')?_c('overlay-detail',{ref:"overlay-greenbelt",refInFor:true,staticClass:"mb-2",attrs:{"keyed":true},on:{"close":function (){
                _vm.$store.commit('map/toggleLayerAvailability', 'greenbelt')
                }}},[_c('template',{slot:"title"},[_vm._v("Greenbelt")]),_c('template',{slot:"legend"},[_c('div',{staticClass:"flex items-center p-1"},[_c('div',{staticClass:"w-4 h-4 rounded border border-gray-800",style:({
            'background-color': '#3bbd4c'
            })}),_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v("Greenbelt")])])])],2):_vm._e(),(o === 'floodZones')?_c('overlay-detail',{ref:"overlay-flood-zones",refInFor:true,staticClass:"mb-2",attrs:{"keyed":true},on:{"close":function (){
                _vm.$store.commit('map/toggleLayerAvailability', 'floodZones')
                }}},[_c('template',{slot:"title"},[_vm._v("Flood Zones")]),_c('template',{slot:"legend"},[_c('div',{staticClass:"flex items-center p-1"},[_c('div',{staticClass:"w-4 h-4 rounded border border-gray-800",style:({
            'background-color': '#52fffc'
            })}),_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v("Zone 2 (0.1%)")])]),_c('div',{staticClass:"flex items-center p-1"},[_c('div',{staticClass:"w-4 h-4 rounded border border-gray-800",style:({
            'background-color': '#a8b2f9'
            })}),_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v("Zone 3 (0.5%)")])])])],2):_vm._e(),(o === 'pricePerSqft')?_c('overlay-detail',{ref:"overlay-price-per-sqft",refInFor:true,staticClass:"mb-2",attrs:{"keyed":true},on:{"close":function (){
                _vm.$store.commit('map/toggleLayerAvailability', 'pricePerSqft')
                }}},[_c('template',{slot:"title"},[_vm._v("Residential Price (per sqft)")]),_c('template',{slot:"legend"},_vm._l((_vm.$store.getters['map/getPricePerSqftLegend']),function(item,k){return _c('div',{key:k + '-price-per-sqft-legend',staticClass:"flex items-center p-1"},[_c('div',{staticClass:"w-4 h-4 rounded border border-gray-800",style:({
            'background-color': item.color
            })}),_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v(_vm._s(item.label))])])}),0)],2):_vm._e(),(o === 'rentalYield')?_c('overlay-detail',{ref:"overlay-rental-yield",refInFor:true,staticClass:"mb-2",attrs:{"keyed":true},on:{"close":function (){
                _vm.$store.commit('map/toggleLayerAvailability', 'rentalYield')
                }}},[_c('template',{slot:"title"},[_vm._v("Rental Yield (%)")]),_c('template',{slot:"legend"},_vm._l((_vm.rental_yield),function(item,i){return _c('div',{key:i,staticClass:"flex items-center p-1"},[_c('div',{staticClass:"w-4 h-4 rounded border border-gray-800",style:({
            'background-color': item.color
            })}),_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v(_vm._s(item.label))])])}),0)],2):_vm._e(),(o === 'deprivation')?_c('overlay-detail',{ref:"overlay-deprivation",refInFor:true,staticClass:"mb-2",attrs:{"keyed":true},on:{"close":function (){
                _vm.$store.commit('map/toggleLayerAvailability', 'deprivation')
                }}},[_c('template',{slot:"title"},[_vm._v("Deprivation")]),_c('template',{slot:"legend"},_vm._l((_vm.deprivation_map_colours),function(color,i){return _c('div',{key:i,staticClass:"flex items-center p-1"},[_c('div',{staticClass:"w-4 h-4 rounded border border-gray-800",style:({
            'background-color': color
            })}),(i === 0)?_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v("Least")]):_vm._e(),(i === _vm.deprivation_map_colours.length - 1)?_c('span',{staticClass:"text-xs ml-2 leading-tight"},[_vm._v("Most")]):_vm._e()])}),0)],2):_vm._e(),(o === 'income')?_c('overlay-detail',{ref:"overlay-income",refInFor:true,staticClass:"mb-2",attrs:{"keyed":true},on:{"close":function (){
                _vm.$store.commit('map/toggleLayerAvailability', 'income')
                }}},[_c('template',{slot:"title"},[_vm._v("Income")]),_c('template',{slot:"legend"},_vm._l((_vm.income_map_colours),function(color,i){return _c('div',{key:i,staticClass:"flex items-center p-1"},[_c('div',{staticClass:"w-4 h-4 rounded border border-gray-800",style:({
            'background-color': color
            })}),(i === 0)?_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v("Lowest")]):_vm._e(),(i === _vm.income_map_colours.length - 1)?_c('span',{staticClass:"text-xs ml-2 leading-tight"},[_vm._v("Highest")]):_vm._e()])}),0)],2):_vm._e(),(o === 'builtUpAreas')?_c('overlay-detail',{ref:"overlay-built-up-areas",refInFor:true,staticClass:"mb-2",attrs:{"keyed":true},on:{"close":function (){
                _vm.$store.commit('map/toggleLayerAvailability', 'builtUpAreas')
                }}},[_c('template',{slot:"title"},[_vm._v("Built up areas")]),_c('template',{slot:"legend"},[_c('div',{staticClass:"flex items-start p-1"},[_c('div',{staticClass:"w-4 h-4 rounded border border-gray-800",style:({
            'background-color': '#bfbfbf'
            })}),_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v("Built up areas")])])])],2):_vm._e(),(o === 'agriculturalLandClassification')?_c('overlay-detail',{ref:"overlay-agricultural-land-classification",refInFor:true,staticClass:"mb-2",attrs:{"keyed":true},on:{"close":function (){
                _vm.$store.commit('map/toggleLayerAvailability', 'agriculturalLandClassification')
                }}},[_c('template',{slot:"title"},[_vm._v(" Agricultural Land Classification (Eng) ")]),_c('template',{slot:"legend"},_vm._l((_vm.agricultural_land_classification),function(item,i){return _c('div',{key:i,staticClass:"flex items-center p-1"},[_c('div',{staticClass:"w-4 h-4 rounded border border-gray-800",style:({
            'background-color': item.color
            })}),_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v(_vm._s(item.label))])])}),0)],2):_vm._e(),(o === 'nationalParks')?_c('overlay-detail',{ref:"overlay-national-parks",refInFor:true,staticClass:"mb-2",attrs:{"keyed":true},on:{"close":function (){
                _vm.$store.commit('map/toggleLayerAvailability', 'nationalParks')
                }}},[_c('template',{slot:"title"},[_vm._v("National Parks")]),_c('template',{slot:"legend"},[_c('div',{staticClass:"flex items-start p-1"},[_c('div',{staticClass:"w-4 h-4 rounded border border-gray-800",style:({
            'background-color': '#43854d'
            })}),_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v("National Parks")])])])],2):_vm._e(),(o === 'sitesOfScientificInterest')?_c('overlay-detail',{staticClass:"mb-2",attrs:{"keyed":true},on:{"close":function (){
                _vm.$store.commit('map/toggleLayerAvailability', 'sitesOfScientificInterest')
                }}},[_c('template',{slot:"title"},[_vm._v("Sites of Scientific Interest")]),_c('template',{slot:"legend"},[_c('div',{staticClass:"flex items-start p-1"},[_c('div',{staticClass:"w-4 h-4 rounded border border-gray-800",style:({
            'background-color': '#5900d6'
            })}),_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v("Special Protection Area Sites")])])])],2):_vm._e(),(o === 'specialProtectionOrder')?_c('overlay-detail',{ref:"overlay-special-protection-order",refInFor:true,staticClass:"mb-2",attrs:{"keyed":true},on:{"close":function (){
                _vm.$store.commit('map/toggleLayerAvailability', 'specialProtectionOrder')
                }}},[_c('template',{slot:"title"},[_vm._v("Special Protection Order Areas")]),_c('template',{slot:"legend"},[_c('div',{staticClass:"flex items-start p-1"},[_c('div',{staticClass:"w-4 h-4 rounded border border-gray-800",style:({
            'background-color': '#bd1800'
            })}),_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v("Special Protection Area Sites")])])])],2):_vm._e(),(o === 'knownMarketedSales')?_c('overlay-detail',{ref:"known-marketed-sales",refInFor:true,staticClass:"mb-2",attrs:{"keyed":true},on:{"close":function (){
                _vm.$store.commit('map/toggleLayerAvailability', 'knownMarketedSales')
                }}},[_c('template',{slot:"title"},[_vm._v("Known Marketed Sales")]),_c('template',{slot:"legend"},_vm._l((_vm.known_marketed_sale),function(x,i){return _c('div',{key:i,staticClass:"flex items-start p-1"},[_c('div',{staticClass:"w-4 h-4 rounded border border-gray-800",style:({
            'background-color': x.color
            })}),_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v(_vm._s(x.label))])])}),0)],2):_vm._e(),(o === 'occupiers')?_c('overlay-detail',{ref:"occupiers",refInFor:true,staticClass:"mb-2",attrs:{"keyed":true},on:{"close":function (){
                _vm.$store.commit('map/toggleLayerAvailability', 'occupiers')
                }}},[_c('template',{slot:"title"},[_vm._v("Current Occupiers")]),_c('template',{slot:"legend"},_vm._l((_vm.occupiers),function(x,i){return _c('div',{key:i,staticClass:"flex items-start p-1"},[_c('div',{staticClass:"w-4 h-4 rounded border border-gray-800",style:({
            'background-color': x.color
            })}),_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v(_vm._s(x.label))])])}),0)],2):_vm._e(),(o === 'ancientWoodland')?_c('overlay-detail',{ref:"overlay-ancient-woodland",refInFor:true,staticClass:"mb-2",attrs:{"keyed":true},on:{"close":function (){
                _vm.$store.commit('map/toggleLayerAvailability', 'ancientWoodland')
                }}},[_c('template',{slot:"title"},[_vm._v("Ancient Woodland")]),_c('template',{slot:"legend"},[_c('div',{staticClass:"flex items-start p-1"},[_c('div',{staticClass:"w-4 h-4 rounded border border-gray-800",style:({
            'background-color': '#43854d'
            })}),_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v("Ancient Semi-planted woodland")])]),_c('div',{staticClass:"flex items-start p-1"},[_c('div',{staticClass:"w-4 h-4 rounded border border-gray-800",style:({
            'background-color': '#8ea232'
            })}),_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v("Ancient replanted woodland")])])])],2):_vm._e(),(o === 'floodRiskCommunities')?_c('overlay-detail',{ref:"overlay-flood-risk-communities",refInFor:true,staticClass:"mb-2",attrs:{"keyed":true},on:{"close":function (){
                _vm.$store.commit('map/toggleLayerAvailability', 'floodRiskCommunities')
                }}},[_c('template',{slot:"title"},[_vm._v("Flood Risk Communities")]),_c('template',{slot:"legend"},[_c('div',{staticClass:"flex items-center p-1"},[_c('div',{staticClass:"w-4 h-4 rounded border border-gray-800",style:({
            'background-color': '#009dd5'
            })}),_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v("Flood Risk Communities")])])])],2):_vm._e(),(o === 'conservation')?_c('overlay-detail',{ref:"overlay-conservation",refInFor:true,staticClass:"mb-2",attrs:{"keyed":true},on:{"close":function (){
                _vm.$store.commit('map/toggleLayerAvailability', 'conservation')
                }}},[_c('template',{slot:"title"},[_vm._v("Conservation Area")]),_c('template',{slot:"legend"},[_c('div',{staticClass:"flex items-center p-1"},[_c('div',{staticClass:"w-4 h-4 rounded border border-gray-800 bg-orange-500"}),_c('span',{staticClass:"text-xs ml-2  leading-tight"},[_vm._v("Conservation Area")])])])],2):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }