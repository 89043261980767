<template>
<div class="my-4">
    <div class="border-green-200 bg-green-100 p-3">
        <div class="flex">
            <div class="flex-shrink-0">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </div>
            <div class="ml-2">
                <h3 class="text-xs font-medium text-green-800">
                   {{ title }}
                </h3>
                <div class="mt-2 text-xs text-green-700">
                    <p>
                        <slot></slot>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    export default {
        name: "SuccessInfo",
        props: {
            title: {
                type: String,
                default(){
                    return ''
                }
            }
        }
    }
</script>

<style scoped>

</style>