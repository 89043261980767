<template>
    <div>
        <lottie :options="{animationData: animationData}" :height="50" :width="100" />
    </div>
</template>

<script>
    import Lottie from "vue-lottie";
    import * as animationData from "./Animations/loading-house"
    export default {
        name: "PropertyLoader",
        components: {
            Lottie
        },
        data(){
            return {
                animationData: animationData.default
            }
        }
    }
</script>

<style scoped>

</style>