<template>
    <div>
        <apexchart
                type="bar" :options="getChartOptions(categories)" :series="series"></apexchart>
    </div>
</template>

<script>
    export default {
        name: "OwnershipPropertySalesChart",
        props: {
            height: {
              type: Number,
              default(){
                  return 350;
              }
            },
            width: {
                type: Number,
                default(){
                    return 350;
                }
            },
            categories: {
                type: Array
            },
            series: {
                type: Array
            },
        },
        methods: {
            getChartOptions(categories){
                return {
                    chart: {
                        height: this.height,
                        width: this.width,
                        type: 'bar',
                        zoom: {
                            enabled: false
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    xaxis: {
                        categories: categories.map((y) => this.$moment(y).format('MMM Do YYYY')),
                        position: 'bottom',
                        axisBorder: {
                            show: true
                        },
                        axisTicks: {
                            show: true
                        },
                        tooltip: {
                            enabled: true,
                        }
                    },
                    yaxis: [
                        {
                            seriesName: 'Price Paid',
                            title: {
                                text: 'Price Paid (£)'
                            },
                            labels: {
                                formatter(y) {
                                    return '£' + y.toLocaleString();
                                }
                            }
                        },
                        {
                            opposite: true,
                            title: {
                                text: "Price Paid per ft² (£)",
                            },
                            labels: {
                                formatter(y) {
                                    return '£' + y.toLocaleString();
                                }
                            }
                        }
                    ]
                };
            }
        }
    }
</script>

<style scoped>

</style>