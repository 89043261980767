<template>
    <div class="el-block--search-form" :class="{
    'is-full-height': fullHeight
    }">
        <div class="bg-blue-900 p-6">
            <h4 class="font-semibold overflow-hidden text-white">Ownership Filters</h4>
        </div>
        <el-form :model="{}" class="el-form--map-options" label-position="top">

            <div class="p-4">
                <div class="flex flex-wrap py-2 w-full el-filter-item--search-radius">
                    <label class="uppercase block text-xs text-gray-800">Search Radius</label>
                    <label class=" ml-auto uppercase block text-xs text-gray-900">

                        <span v-if="$store.state.map.polygon_filters.radius < 1000">
                            {{ $store.state.map.polygon_filters.radius }}m
                        </span>
                        <span v-if="$store.state.map.polygon_filters.radius >= 1000">
                            {{ ($store.state.map.polygon_filters.radius / 1000)  }}km
                        </span>

                    </label>
                    <div class="w-full flex-none px-3 mb-2">
                        <el-slider
                                :disabled="$store.state.map.loading_polygons"
                                v-model="$store.state.map.polygon_filters.radius"
                                :min="minRadius"
                                :max="maxRadius"
                                :format-tooltip="formatRadiusTooltip"
                                :marks="getRadiusMarkers"
                                :step="50">
                        </el-slider>
                    </div>
                </div>

                <div class="flex flex-wrap py-2 w-full el-filter-item--ownership">
                    <label class="uppercase block text-xs text-gray-800 mb-2">Tenure</label>
                    <div class="w-full flex-none">
                        <el-select
                                class="w-full"
                                :collapse-tags="true"
                                filterable
                                placeholder="Select Tenure Types..."
                                :disabled="$store.state.map.loading_polygons"
                                v-model="$store.state.map.polygon_filters.tenure">
                            <el-option
                                    v-for="item in $store.state.map.tenure_types"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                {{ item.label }}
                            </el-option>
                        </el-select>
                    </div>
                </div>

                <div class="flex flex-wrap py-2 w-full el-filter-item--ownership">
                    <label class="uppercase block text-xs text-gray-800 mb-2">Owner Type</label>
                    <div class="w-full flex-none">
                        <el-select
                                class="w-full"
                                :collapse-tags="true"
                                filterable
                                placeholder="Select Owner Types..."
                                multiple
                                :disabled="$store.state.map.loading_polygons"
                                v-model="$store.state.map.polygon_filters.ownership">
                            <el-option
                                    v-for="item in $store.state.map.ownership_types"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                {{ item.label }}
                            </el-option>
                        </el-select>
                    </div>
                </div>

                <div class="flex flex-wrap py-2 w-full el-filter-item--property-size">
                    <label class="uppercase block text-xs text-gray-800">Property Size</label>
                    <label class=" ml-auto uppercase block text-xs text-gray-900">{{
                        $store.getters['map/getPropertySizeBreakdown'] }}</label>
                    <div class="w-full flex-none px-3">
                        <el-slider
                                :format-tooltip="formatPropertySizeTooltip"
                                :disabled="$store.state.map.loading_polygons"
                                range
                                :marks="{
                                              0: 'Any',
                                              40: '40+ acres'
                                              }"
                                v-model="$store.state.map.polygon_filters.area"
                                :min="0"
                                :max="40"
                                :step="0.1">
                        </el-slider>
                    </div>
                </div>

                <div class="flex flex-wrap py-2 w-full el-filter-item--buildings-footprint">
                    <label class="uppercase block text-xs text-gray-800">Total Buildings Footprint</label>
                    <label class=" ml-auto uppercase block text-xs text-gray-900">{{
                        $store.getters['map/getBuildingFootprintBreakdown'] }}</label>
                    <div class="w-full flex-none px-3">
                        <el-slider
                                :disabled="$store.state.map.loading_polygons"
                                :format-tooltip="formatBuildingsFootprintTooltip"
                                range
                                v-model="$store.state.map.polygon_filters.buildings_footprint"
                                :min="0"
                                :marks="{
                                              0: 'Any',
                                              20000: '20,000+ sqft'
                                              }"
                                :max="20000"
                                :step="100">
                        </el-slider>
                    </div>
                </div>

            </div>

            <el-collapse class="el-filter-item--additional-filters" v-model="$store.state.map.active_filter_category">

                <el-collapse-item
                        :title="'Environmental Filters (' + $store.getters['map/getEnvironmentalFilterCount']  + ')'"
                        name="environment">

                    <div class="flex flex-wrap items-center justify-center py-2 w-full">
                        <label class="uppercase block text-xs text-gray-800">Exclude Greenbelt</label>
                        <div class="ml-auto">
                            <el-switch
                                    :disabled="$store.state.map.loading_polygons"
                                    v-model="$store.state.map.polygon_filters.exclude_greenbelt"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949">
                            </el-switch>
                        </div>
                    </div>
                    <div class="flex flex-wrap items-center justify-center py-2 w-full">
                        <label class="uppercase block text-xs text-gray-800">Exclude AONB</label>
                        <div class="ml-auto">
                            <el-switch
                                    :disabled="$store.state.map.loading_polygons"
                                    v-model="$store.state.map.polygon_filters.exclude_aonb"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949">
                            </el-switch>
                        </div>
                    </div>

                    <div class="flex flex-wrap py-2 w-full">
                        <label class="uppercase block text-xs text-gray-800 mb-2">Environment must include</label>
                        <label class=" ml-auto uppercase block text-xs text-gray-900">{{
                            $store.getters['map/getEnvMustInclude'] > 0 ? $store.getters['map/getEnvMustInclude'] + ' selected' : 'Any' }}</label>
                        <div class="w-full flex-none">
                            <el-select
                                    filterable
                                    clearable
                                    class="w-full"
                                    :disabled="$store.state.map.loading_polygons"
                                    multiple
                                    v-model="$store.state.map.polygon_filters.environmental_must_include"
                                    placeholder="Select Environmental Types..">
                                <el-option
                                        v-for="item in $store.state.map.os_types" :key="item.code"
                                        :label="item.name"
                                        :value="item.name">
                                </el-option>
                            </el-select>
                        </div>
                    </div>

                    <div class="flex flex-wrap py-2 w-full">
                        <label class="uppercase block text-xs text-gray-800 mb-2">Environment must not include</label>
                        <label class=" ml-auto uppercase block text-xs text-gray-900">{{
                            $store.getters['map/getEnvMustNotInclude'] > 0 ? $store.getters['map/getEnvMustNotInclude'] + ' selected' : 'Any' }}</label>
                        <div class="w-full flex-none">
                            <el-select
                                    filterable
                                    clearable
                                    class="w-full"
                                    :disabled="$store.state.map.loading_polygons"
                                    multiple
                                    v-model="$store.state.map.polygon_filters.environmental_must_not_include"
                                    placeholder="Select Environmental Types..">
                                <el-option
                                        v-for="item in $store.state.map.os_types" :key="item.code"
                                        :label="item.name"
                                        :value="item.name">
                                </el-option>
                            </el-select>
                        </div>
                    </div>

                </el-collapse-item>
                <el-collapse-item
                        :title="'Use Types Filters (' + $store.getters['map/getUseTypeFilterCount']  + ')'"
                        name="uses">

                    <div class="flex flex-wrap py-2 w-full">
                        <label class="uppercase block text-xs text-gray-800 mb-2">Property Types</label>
                        <label class=" ml-auto uppercase block text-xs text-gray-900">{{
                            $store.getters['map/getUseTypeCount'] > 0 ? $store.getters['map/getUseTypeCount'] + ' selected' : 'Any' }}</label>
                        <div class="w-full flex-none">
                            <el-select
                                    filterable
                                    clearable
                                    class="w-full"
                                    multiple
                                    :disabled="$store.state.map.loading_polygons"
                                    v-model="$store.state.map.polygon_filters.use_types"
                                    placeholder="Select Use Types..">
                                <el-option
                                        v-for="item in $store.state.map.use_types" :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </div>

                    <!--
                    <div class="flex flex-wrap py-2 w-full">
                        <label class="uppercase block text-xs text-gray-800 mb-2">Business Type Categories</label>
                        <label class=" ml-auto uppercase block text-xs text-gray-900">{{
                            $store.getters['map/getBusinessTypeCategories'] > 0 ? $store.getters['map/getBusinessTypeCategories'] + ' selected' : 'Any' }}</label>

                        <div class="w-full flex-none mt-2">

                            <el-select
                                    filterable
                                    clearable
                                    class="w-full"
                                    multiple
                                    :disabled="$store.state.map.loading_polygons"
                                    v-model="$store.state.map.polygon_filters.business_types"
                                    placeholder="Select Use Types..">
                                <el-option
                                        v-for="item in $store.state.map.business_types" :key="item"
                                        :label="item"
                                        :value="item">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
--->
                    <div class="flex flex-wrap py-2 w-full">
                        <label class="uppercase block text-xs text-gray-800 mb-2">EPC Ratings</label>
                        <label class=" ml-auto uppercase block text-xs text-gray-900">{{
                            $store.getters['map/getEPCSelectionCount'] > 0 ? $store.getters['map/getEPCSelectionCount'] + ' selected' : 'Any' }}</label>
                        <div class="w-full flex-none">
                            <el-checkbox-group
                                    :disabled="$store.state.map.loading_polygons"
                                    v-model="$store.state.map.polygon_filters.epc_ratings">
                                <el-checkbox-button
                                        v-for="epc in $store.state.map.epc_ratings" :label="epc" :key="epc">{{epc}}
                                </el-checkbox-button>
                            </el-checkbox-group>
                        </div>
                    </div>


                </el-collapse-item>
                <el-collapse-item
                        :title="'Property Sales Filters (' + $store.getters['map/getPropertySalesFilterCount']  + ')'"
                        name="sales">

                    <div class="flex flex-wrap py-2 w-full">
                        <label class="uppercase block text-xs text-gray-800">Date Sold</label>
                        <label class=" ml-auto uppercase block text-xs text-gray-900">{{
                            $store.getters['map/getSalePriceDateRange'] }}</label>


                        <div class="w-full flex-none pt-3">
                            <el-date-picker
                                    :disabled="$store.state.map.loading_polygons"
                                    v-model="$store.state.map.polygon_filters.sales_date_sold"
                                    type="monthrange"
                                    :picker-options="$store.getters['map/getSoldPricesDateRange']"
                                    align="right"
                                    format="MMM yyyy"
                                    unlink-panels
                                    range-separator="To"
                                    start-placeholder="Start month"
                                    end-placeholder="End month">
                            </el-date-picker>
                        </div>
                    </div>

                    <div class="flex flex-wrap py-2 w-full">
                        <label class="uppercase block text-xs text-gray-800">Price Paid</label>
                        <label class=" ml-auto uppercase block text-xs text-gray-900">{{
                            $store.getters['map/getSalePriceRange'] }}</label>
                        <div class="w-full flex-none px-3">
                            <el-slider
                                    range
                                    :disabled="$store.state.map.loading_polygons"
                                    :marks="{
                                              0: 'Any',
                                              40000000: '£40m +'
                                              }"
                                    v-model="$store.state.map.polygon_filters.sales_price_paid"
                                    :format-tooltip="formatPricePaidTooltip"
                                    :min="0"
                                    :max="40000000"
                                    :step="10000">
                            </el-slider>
                        </div>
                    </div>


                    <div class="flex flex-wrap py-2 w-full">
                        <label class="uppercase block text-xs text-gray-800 mb-2">Property Types</label>
                        <label class=" ml-auto uppercase block text-xs text-gray-900">{{
                            $store.getters['map/getSalePropertyTypesCount'] > 0 ?
                            $store.getters['map/getSalePropertyTypesCount'] + ' selected' : 'Any' }}</label>

                        <div class="w-full flex-none">
                            <el-select
                                    class="w-full"
                                    filterable
                                    multiple
                                    clearable
                                    :disabled="$store.state.map.loading_polygons"
                                    v-model="$store.state.map.polygon_filters.sales_types"
                                    placeholder="Select Property Types..">
                                <el-option
                                        v-for="item in $store.state.map.sales_types"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    {{ item.label }}
                                </el-option>
                            </el-select>
                        </div>
                    </div>

                    <div class="flex flex-wrap py-2 w-full">
                        <label class="uppercase block text-xs text-gray-800 mb-2">Tenure</label>
                        <label class=" ml-auto uppercase block text-xs text-gray-900">{{
                            $store.getters['map/getSalesTenureCount'] > 0 ? $store.getters['map/getSalesTenureCount'] + ' selected' : 'Any' }}</label>

                        <div class="w-full flex-none">
                            <el-select
                                    class="w-full"
                                    filterable
                                    multiple
                                    clearable
                                    placeholder="Select Tenure Types.."
                                    :disabled="$store.state.map.loading_polygons"
                                    v-model="$store.state.map.polygon_filters.sales_tenures">
                                <el-option
                                        v-for="item in $store.state.map.sales_tenures"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    {{ item.label }}
                                </el-option>
                            </el-select>
                        </div>
                    </div>

                    <div class="flex flex-wrap py-2 w-full">
                        <label class="uppercase block text-xs text-gray-800 mb-2">Build</label>
                        <div class="w-full flex-none">
                            <el-select
                                    class="w-full"
                                    filterable
                                    placeholder="Select Build Type.."
                                    :disabled="$store.state.map.loading_polygons"
                                    v-model="$store.state.map.polygon_filters.sales_build_type">
                                <el-option
                                        v-for="item in $store.state.map.sales_build_type"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    {{ item.label }}
                                </el-option>
                            </el-select>
                        </div>
                    </div>

                </el-collapse-item>
                <el-collapse-item

                        :title="'Building Filters (' + $store.getters['map/getBuildingsFilterCount']  + ')'"

                        name="buildings">

                    <div class="flex flex-wrap items-center justify-center py-2 w-full">
                        <label class="uppercase block text-xs text-gray-800">Exclude listed buildings</label>
                        <div class="ml-auto">
                            <el-switch
                                    :disabled="$store.state.map.loading_polygons"
                                    v-model="$store.state.map.polygon_filters.exclude_listed_buildings"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949">
                            </el-switch>
                        </div>
                    </div>

                    <div class="flex flex-wrap py-2 w-full">
                        <label class="uppercase block text-xs text-gray-800">Buildings Footprint %</label>
                        <label class=" ml-auto uppercase block text-xs text-gray-900">{{
                            $store.getters['map/getBuildingsFootprintPercentage'] }}</label>
                        <div class="w-full flex-none px-3">
                            <el-slider
                                    range
                                    :disabled="$store.state.map.loading_polygons"
                                    v-model="$store.state.map.polygon_filters.buildings_footprint_percentage"
                                    :format-tooltip="formatPercentageTooltip"
                                    :min="0"
                                    :max="100"
                                    :marks="{
                                              0: 'Any',
                                              100: '100%'
                                              }"
                                    :step="1">
                            </el-slider>
                        </div>
                    </div>

                    <div class="flex flex-wrap py-2 w-full">
                        <label class="uppercase block text-xs text-gray-800">Number of Buildings</label>
                        <label class=" ml-auto uppercase block text-xs text-gray-900">{{
                            $store.getters['map/getBuildingsCount'] }}</label>

                        <div class="w-full flex-none px-3">
                            <el-slider
                                    range
                                    :disabled="$store.state.map.loading_polygons"
                                    v-model="$store.state.map.polygon_filters.buildings_count"
                                    :format-tooltip="formatBuildingCountTooltip"
                                    :min="0"
                                    :max="100"
                                    :marks="{
                                              0: 'Any',
                                              100: '100+'
                                              }"
                                    :step="1">
                            </el-slider>
                        </div>
                    </div>


                </el-collapse-item>
            </el-collapse>

        </el-form>
    </div>
</template>

<script>
    const humanNumber = require('human-number')

    export default {
        name: "PolygonSearchFilter",
        props: {
            fullHeight: {
                type: Boolean,
                default: false
            },
            hideAdditionalOptions: {
                type: Boolean,
                default: false
            },
            minRadius: {
                type: Number,
                default: 250
            },
            maxRadius: {
                type: Number,
                default: 1000
            },
            radiusStepSize: {
                type: Number,
                default: 250
            }
        },
        computed: {
          getRadiusMarkers(){
              let object = {};

              let steps = this.maxRadius / this.radiusStepSize;

              console.log(steps, this.radiusStepSize);

              for(let i = 0; i <= steps; i++){
                  let s = i * this.radiusStepSize;

                  if(i === 0){
                      s = this.minRadius;
                  }

                  let label = s;
                  if(s >= 1000){
                      label = s / 1000 + 'km';
                  }else{
                      label = s + 'm';
                  }

                  object[s] = label;
              }


              return object;
          }
        },
        methods: {
            formatRadiusTooltip(val) {
                return val < 1000 ? val + 'm' : val / 1000 + 'km';
            },
            formatPropertySizeTooltip(val) {
                if (val === 0) {
                    return 'Any';
                }
                if (val === 40) {
                    return '40+ acres';
                }
                return val + ' acres';
            },
            formatBuildingsFootprintTooltip(val) {
                if (val === 0) {
                    return 'Any';
                }
                if (val === 20000) {
                    return val.toLocaleString() + '+ sqft';
                }
                return (val ? val.toLocaleString() : 0) + ' sqft';
            },
            formatPricePaidTooltip(val) {
                if (val === 0) {
                    return 'Any';
                }
                if (val === 40000000) {
                    return '£40m+';
                }
                return '£' + humanNumber(val).toString().toLowerCase();
            },
            formatPercentageTooltip(val) {
                if (val === 0) {
                    return 'Any';
                }
                return val + '%';
            },
            formatBuildingCountTooltip(val) {
                if (val === 0) {
                    return 'Any';
                }
                if (val === 100) {
                    return '100+';
                }
                return val
            }
        }
    }
</script>

<style lang="scss">

    .el-date-range-picker {
        z-index: 9001 !important;
    }

    .el-block--search-form div[role="tab"] .el-collapse-item__header {
        padding: 0 20px;
    }

    .el-block--search-form .el-collapse-item__content {
        padding: 0 20px 10px 20px
    }

    .el-block--search-form .el-slider__marks-text {
        margin: 0;
        width: 50px !important;
        line-height: 10px;
        font-size: 10px;
    }

    .el-form--switch {
        display: flex;
        flex-wrap: nowrap;
        height: 40px;

        .el-form-item__label {
            text-transform: uppercase;
            font-size: 12px;
            margin-bottom: 0 !important;
            padding-bottom: 0 !important;
        }
    }

    .el-slider__marks-text {
        padding-top: 1rem;
        font-size: 11px;
        width: 120px;
        text-align: center;
    }


</style>