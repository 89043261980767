<template>
    <div
            :class="showOverlay && show ? 'top-0  w-full top-0 h-full absolute' : ''">

        <transition
                enter-active-class="transition ease-in-out duration-300 transform"
                enter-class="opacity-0"
                enter-to-class="opacity-50"
                leave-active-class="transition ease-in-out duration-300 transform"
                leave-class="opacity-0"
                leave-to-class="opacity-0"
        >
        <div v-if="show && showOverlay" class="absolute w-full h-full bg-black bg-opacity-50" :style="{
    'z-index': 5001
    }">
        </div>
        </transition>



        <transition
                enter-active-class="transition ease-in-out duration-300 transform"
               :enter-class="position !== 'left' ? 'translate-x-full' : '-translate-x-full'"
                enter-to-class="translate-x-0"
                leave-active-class="transition ease-in-out duration-300 transform"
                leave-class="translate-x-0"
                :leave-to-class="position !== 'left' ? 'translate-x-full' : '-translate-x-full'"
        >
        <div
                v-if="show"
                :style="{
    'z-index': 6001,
    }"
                class="absolute h-full top-0 shadow-lg bg-white shadow el-block--sidebar-option w-full md:w-2/3 md:max-w-lg md:w-auto"
        :class="{
        'left-0': position === 'left',
        'right-0': position !== 'left',
        customClass
        }"
        >
            <el-tooltip  class="item" effect="dark"

                         :placement="position === 'left' ? 'right' : 'left'" content="Close">
                <div
                        v-if="showClose"
                        @click="$emit('close')"
                        :class="{
                        'right-0 -mr-10': position === 'left',
                        'left-0 -ml-10': position !== 'left',
                        }"
                        class="bg-red-700 text-white cursor-pointer mt-2 w-8 text-xs h-8 flex items-center justify-center shadow-lg rounded-full absolute">
                    X
                </div>
            </el-tooltip>




            <slot></slot>
        </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "MenuSidebarLayer",
        props: {
            customClass: {
              type: String,
              default(){
                  return ''
              }
            },
            showOverlay: {
                type: Boolean,
                default(){
                    return true;
                }
            },
            showCollapse: {
                type: Boolean,
                default(){
                    return false;
                }
            },
            position: {
                type: String,
                default(){
                    return 'left'
                }
            },
            showClose: {
                type: Boolean,
                default(){
                    return true;
                }
            },
            show: {
                type: Boolean,
                default(){
                    return false;
                }
            },
            width: {
                type: Number,
                default(){
                    return 500;
                }
            }
        },
        data(){
            return {
                collapsed: false
            }
        }
    }
</script>

<style scoped>
    .el--block-collapse-action {
        position: absolute;
        --transform-rotate: 270deg !important;
        z-index: 3000;
        left: -48px;
        top: calc(50% - 20px)
    }
</style>