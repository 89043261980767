<template>
    <div>

        <navigation/>

        <page-header title="Create Application Alerts"/>

        <page>

            <div class="columns">
                <div class="column w-full">
                    <dashboard-widget title="Create Application Alert"
                                      description="Recently decided planning applications">
                       <application-alert-create />
                    </dashboard-widget>
                </div>
            </div>

        </page>
    </div>

</template>

<script>

    import ApplicationAlertCreate from "../../components/incs/ApplicationAlertCreateEdit";
    export default {
        name: 'AlertsCreate',
        components: {ApplicationAlertCreate},
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.$store.commit('auth/setBreadcrumbs', [
                    {
                        title: 'Application Alerts',
                        path: '/alerts'
                    },
                    {
                        title: 'Create Alert'
                    }
                ]);
            })
        }
    }
</script>