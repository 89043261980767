<template>

        <page v-loading="loading">

            <template slot="header">
                <page-header
                        :title="hasTeams ? 'Select a Team' : 'No Team Found'"
                />

            </template>

            <template slot="content">
                <div class="columns">
                    <div class="column w-full">
                        <dashboard-widget
                                padding-class="p-6"
                                :title="hasTeams ? 'Select a Team' : 'Team Required'">

                            <div class="flex flex-col text-center justify-center" v-if="!teams.length && !loading">

                                <h2 class="leading-none text-3xl font-semibold mb-3">Your not a member of a team!</h2>

                                <div class="m-8 mx-auto">
                                    <lottie :options="{animationData: animationData}" :height="150"  />
                                </div>
                                <div class="leading-loose text-sm">
                                    <p class="mb-1">Looks like your not a member of a team. In order to use Land Monitor you need to setup a team (even if it's just yourself).</p>

                                    <p class="mt-4">
                                        <el-button
                                                @click="()=> { $router.push('/teams/create') }"
                                                type="success">Create a Team</el-button>
                                    </p>
                                </div>

                            </div>

                            <div v-if="teams.length > 0 && !loading">
                                <h2 class="leading-none text-xl text-center font-semibold mb-6">Please select a team</h2>
                                <div class="columns justify-center">
                                    <div
                                            :key="team.id"
                                            v-for="(team) of teams"
                                            class="column">

                                        <div @click="selectTeam(team.id)" class="p-4 cursor-pointer hover:bg-gray-200 rounded hover:border-gray-300">
                                            <div class="flex-shrink-0 mx-auto h-12 w-12">
                                                <avatar-manager
                                                        :name="team.name"
                                                        v-model="team.avatar" />
                                            </div>
                                            <div class="mt-1 truncate w-11/12 text-xs uppercase">
                                                {{ team.name }}
                                            </div>
                                            <div class="text-xs text-center text-gray-500">Owner</div>
                                        </div>


                                    </div>
                                </div>
                            </div>


                        </dashboard-widget>
                    </div>
                </div>
            </template>

        </page>

</template>
<script>

    import Lottie from "vue-lottie";
    import * as animationData from "./../components/incs/Animations/construction";
    import AvatarManager from "../components/AvatarManager";
    export default {
        name: 'SelectTeam',
        components: {AvatarManager, Lottie},
        methods: {
            selectTeam(id){

                    const vm = this;

                    vm.loading = true;
                    vm.$store.dispatch('team/switch', {
                        teamId: id
                    }).then(()=>{

                        vm.$http.get('/api/v1/auth/user')
                            .then((response)=>{
                                vm.$store.commit('auth/setUser', response.data.data);
                                window.location = '/';
                            })

                    }).catch(()=>{

                    })


            }
        },
        computed: {
          hasTeams(){
              return this.teams.length > 0;
          }
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.$store.commit('auth/setBreadcrumbs', []);
                vm.loading = true;
                vm.$store.dispatch('team/index').then((response) => {
                    vm.teams = response.data.data;
                    vm.loading = false;

                    vm.$store.commit('auth/setBreadcrumbs', [
                        {
                            title: vm.teams.length > 0 ? 'Select a Team' : 'Create a Team'
                        }
                    ]);
                }).catch(() => {
                    vm.teams = [];
                    vm.loading = false;

                    vm.$store.commit('auth/setBreadcrumbs', [
                        {
                            title: 'Create a Team'
                        }
                    ]);
                })
            })
        },
        data(){
            return {
                loading: true,
                teams: [],
                animationData: animationData.default
            }
        }
    }
</script>

