<template>
    <div class="columns">
        <div class="column w-full text-left">
            <el-alert
                    :closable="false"
                    type="error"
                    effect="dark">Warning! Deleting your account cannot be undone. You will forfeit all current team subscriptions and all team members will be removed.</el-alert>

            <div class="mt-3 text-sm leading-loose">
                <p>If you wish to delete your account, please enter your password below and click confirm.</p>
            </div>

            <el-form class="mt-4" label-position="top" label-width="175px"
                     :rules="rules" ref="deleteAccountForm"
                     :model="password">
                <el-form-item label="Current Password" prop="password">
                    <el-input v-model="password.password"
                              type="password"
                              :disabled="updating"
                              :show-password="true"/>
                </el-form-item>
            </el-form>

        </div>
        <div class="column w-full text-right">
            <el-button
                    @click="deleteAccount"
                    :disabled="updating"
                    :loading="updating"
                    icon="el-icon-delete"
                    type="danger">
                Delete Account
            </el-button>
        </div>
    </div>
</template>
<script>

    import { reset } from '../../utils/auth'

    export default {
        name: 'DeleteAccountProfileComponent',
        data() {
            return {
                deleting: false,
                updating: false,
                rules: {
                    password: [
                        {required: true, message: 'Your current password is required', trigger: 'blur'},
                    ]
                },
                password: {
                    password: ''
                },
            }
        },
        methods: {
            deleteAccount() {
                let vm = this;

                vm.$refs['deleteAccountForm'].validate((valid) => {
                    if (!valid) {
                        return false;
                    } else {

                        vm.$confirm(
                            'Are you sure you want to delete your account? Deleting your account cannot be undone. You will forfeit all current team subscriptions and all team members will be removed.',
                            'Delete Account?',
                            {
                                confirmButtonText: 'Yes',
                                cancelButtonText: 'Cancel'
                            })
                            .then(() => {
                                vm.deleting = true;

                                vm.$http.post('/api/v1/auth/user', vm.password)
                                    .then(() => {
                                        reset(vm)
                                        window.location = '/';
                                    })
                                    .catch((e) => {
                                        vm.deleting = false;
                                        vm.$store.dispatch('auth/handleServerError', {
                                            vm: vm,
                                            error: e
                                        });
                                    })

                            })
                            .catch(() => {

                            })


                        /*
                            vm.updating = true;

                        vm.$http.post('/api/v1/auth/password', vm.password)
                            .then(() => {

                                vm.$notify({
                                    title: 'Password Updated',
                                    message: 'Your password has been updated successfully',
                                    type: 'success',
                                    position: 'top-right',
                                    offset: 140,
                                });

                                vm.updating = false;

                            })
                            .catch((e) => {
                                vm.updating = false;
                                vm.$store.dispatch('auth/handleServerError', {
                                    vm: vm,
                                    error: e
                                });
                            })
                         */

                    }
                })

            }
        },
    }
</script>