<template>
    <div>

        <div class="columns">
            <div class="column w-full" :key="i.key" v-for="(i) of types">
                <dashboard-widget
                        padding-class=""
                        :title="i.title">
                    <slot>
                        <el-table
                                :data="buildTable(i.key)">
                            <el-table-column
                                    prop="distance"
                                    class-name="font-semibold"
                                    label="Name">
                                <template slot-scope="scope">
                                    {{ scope.row.name }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                    width="100"
                                    align="right"
                                    label="Distance">
                                <template slot-scope="scope">
                                    {{ scope.row.distance_miles ? scope.row.distance_miles.toLocaleString() : 0 }} <small>miles</small>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    width="120"
                                    align="right">
                                <template slot-scope="scope">
                                <el-button size="small" type="info" icon="el-icon-view" @click="open(scope.row.lon, scope.row.lat)">View</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </slot>
                </dashboard-widget>
            </div>
        </div>

    </div>
</template>

<script>

    import {openGoogleMaps} from "../../../utils/external";
    const collect  = require('collect.js');

    export default {
        name: "OwnershipTransportLinksComponent",
        props: {
            value: {
                type: Array,
                default(){
                    return []
                }
            },
            loading: {
                type: Boolean,
                default(){
                    return true
                }
            }
        },
        data(){
          return {
              types: [
                  {key: 'rail', title: 'Nearest Railways'},
                  {key: 'coach', title: 'Nearest Coach Stations'},
                  {key: 'ferry', title: 'Nearest Ferries'}
              ]
          }
        },
        methods: {
            buildTable(type){
                return collect(this.value).filter((i)=>{
                    return i.type === type;
                }).sortBy('distance_miles').toArray()
            },
            open(lon, lat){
                openGoogleMaps({
                    lat: lat,
                    lng: lon
                })
            }
        }
    }
</script>

<style scoped>

</style>