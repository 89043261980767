<template>

        <page>

            <template slot="header">
                <page-header title="dd" />
            </template>

            <template slot="content">
                <div class="columns">
                    <div class="column w-full">
                        <dashboard-widget title="Your Application Alerts"
                                          description="Recently decided planning applications">
                            <template slot="cta">
                                <el-button
                                        @click="()=>{ $router.push('/alerts/create') }"
                                        type="success" icon="el-icon-plus">Create Alert
                                </el-button>
                            </template>


                            <div class="h-32 w-full"
                                 v-if="loading"
                                 v-loading="true"></div>

                            <empty-state class="p-3"
                                         title="You have no application alerts"
                                         message="It look's like you have not created any application alerts"
                                         :support="false"
                                         v-if="!loading && !results.length" />

                            <div class="columns pb-4"
                                 v-if="meta.totalResult && !loading"
                            >
                                <div class="column flex items-center">
                                <span class="text-sm">
                                  {{ meta.totalResult.toLocaleString() }} alerts found. Displaying page {{ meta.current.toLocaleString() }} of {{ meta.pageCount.toLocaleString() }}
                                </span>
                                </div>
                                <div class="column ml-auto">
                                    <div class="columns">
                                        <div class="column flex items-center">
                                            <span class="text-gray-600 uppercase text-xs">per page</span>
                                        </div>
                                        <div class="column">
                                            <el-select
                                                    class="w-32"
                                                    v-model="rpp" @change="fetch(1)">
                                                <el-option :value="10">10</el-option>
                                                <el-option :value="20">20</el-option>
                                                <el-option :value="30">30</el-option>
                                                <el-option :value="40">40</el-option>
                                                <el-option :value="50">50</el-option>
                                                <el-option :value="100">100</el-option>
                                            </el-select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <el-table
                                    v-if="meta.totalResult && !loading"
                                    :data="results"
                                    :default-sort="default_sort"
                                    @sort-change="onSortChange"
                                    style="width: 100%">
                                <el-table-column
                                        prop="name"
                                        sortable="custom"
                                        label="Alert">
                                    <template slot-scope="scope">
                                        <h4 class="font-semibold">{{ scope.row.name }}</h4>
                                    </template>
                                </el-table-column>

                                <el-table-column
                                        width="120px"
                                        prop="created_at"
                                        sortable="custom"
                                        align="left"
                                        label="Created">
                                    <template slot-scope="scope">
                                        {{ scope.row.created_at | moment("MMM Do YYYY") }}
                                        <br/><small>{{ scope.row.created_at | moment("from", "now")
                                        }}</small>
                                    </template>
                                </el-table-column>

                                <el-table-column
                                        width="120px"
                                        prop="radius"
                                        sortable="custom"
                                        align="center"
                                        label="Radius">
                                    <template slot-scope="scope">
                                        {{ scope.row.radius }} miles
                                    </template>
                                </el-table-column>



                                <el-table-column
                                        width="120px"
                                        prop="active"
                                        sortable="custom"
                                        align="center"
                                        label="Active">
                                    <template slot-scope="scope">
                                        <i :class="{
                                    'el-icon-check text-green-500 text-xl':  scope.row.active,
                                    'el-icon-close text-red-500 text-xl':  !scope.row.active,
                                    }"></i>
                                    </template>
                                </el-table-column>

                                <el-table-column
                                        width="120px"
                                        prop="frequency"
                                        sortable="custom"
                                        class-name="text-xs"
                                        label="Frequency">
                                    <template slot-scope="scope">
                                        {{ scope.row.frequency_pretty }}
                                    </template>
                                </el-table-column>

                                <el-table-column
                                        align="right"
                                        fixed="right"
                                        width="150px"
                                        class-name="text-xs">
                                    <template slot-scope="scope">
                                        <el-button
                                                size="small"
                                                type="info"
                                                icon="el-icon-edit"
                                                @click="()=>{ $router.push('/alert/' + scope.row.id); }"
                                        >Edit
                                        </el-button>
                                        <el-button
                                                @click="remove(scope.row.id)"
                                                size="small"
                                                icon="el-icon-delete"
                                                type="danger">Delete
                                        </el-button>
                                    </template>
                                </el-table-column>


                            </el-table>

                            <div class="columns pt-4"
                                 v-if="meta.totalResult && !loading"
                            >
                                <div class="column flex items-center">
                                <span class="text-sm">
                                  {{ meta.totalResult.toLocaleString() }} alerts found. Displaying page {{ meta.current.toLocaleString() }} of {{ meta.pageCount.toLocaleString() }}
                                </span>
                                </div>
                                <div class="column ml-auto">
                                    <el-pagination
                                            background
                                            layout="prev, pager, next"
                                            @current-change="(page)=>{ fetch(page) }"
                                            :page-size="rpp"
                                            :current-page="meta.current"
                                            :total="meta.totalResult">
                                    </el-pagination>

                                </div>
                            </div>

                        </dashboard-widget>
                    </div>
                </div>
            </template>

        </page>

</template>

<script>

    import EmptyState from "../components/EmptyState";
    export default {
        name: 'Alerts',
        components: {EmptyState},
        data() {
            return {
                rpp: 10,
                loading: true,
                default_sort: {
                    prop: 'name',
                    order: 'desc'
                },
                results: [],
                meta: {}
            }
        },
        created() {
            this.fetch(1);
        },
        methods: {
            onSortChange(object) {
                this.default_sort = object.prop && object.order ? object : null;
                this.fetch(1);
            },
            remove(id) {
                let vm = this;

                vm.$confirm(
                    'Are you sure you want to delete this alert? This cannot be undone.',
                    'Delete Alert?',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel'
                    })
                    .then(() => {
                        vm.loading = true;

                        vm.$store.dispatch('alert/remove', {
                            hash: id
                        })
                            .then(() => {
                                console.log('Deleted')
                                vm.$notify({
                                    type: 'success',
                                    title: 'Alert Deleted',
                                    message: 'Application alert deleted successfully.',
                                    position: 'top-right',
                                    offset: 140,
                                });
                                vm.fetch(1);
                            })
                            .catch(() => {
                                console.log('error')
                                vm.loading = false;
                                vm.$notify({
                                    type: 'warning',
                                    title: 'Something went wrong',
                                    message: 'Sorry, but we could not delete this alert.',
                                    position: 'top-right',
                                    offset: 140,
                                });
                            })

                    }).catch((e) => {
                    console.log(e);
                });

            },
            fetch(page) {
                let vm = this;
                vm.results = [];
                vm.loading = true;
                vm.$http.get('/api/v1/alerts', {
                    params: {
                        page: page || 1,
                        rpp: vm.rpp,
                        sort: vm.default_sort && vm.default_sort.prop ? vm.default_sort.prop : null,
                        order: vm.default_sort && vm.default_sort.order ? vm.default_sort.order === 'descending' ? 'desc' : 'asc' : null,
                    }
                }).then((response) => {
                    vm.results = response.data.results;
                    vm.meta = response.data.meta;
                    vm.loading = false;
                }).catch(() => {
                    vm.loading = false;
                })
            },
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.$store.commit('auth/setBreadcrumbs', [
                    {
                        title: 'Application Alerts'
                    }
                ]);
            })
        }
    }
</script>