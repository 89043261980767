<template>
        <div class="relative">
            <div class="el-button--map-change">
                <el-button size="mini"
                           @click="toggleMapView"

                           :icon="activeMapView === 'street' ?  'el-icon-location-information' : 'el-icon-place'"
                           type="info">
                    {{ activeMapView === 'map' ? 'Street View' : 'Map View' }}
                </el-button>
            </div>

            <div class="relative">


                <v-map-street
                        v-if="activeMapView === 'street'"
                        class="el-block--application-map-preview"
                        :position="{lat: $store.state.applications.application.site_address_coords.lat, lng: $store.state.applications.application.site_address_coords.lon}"
                        :zoom="1"
                >
                </v-map-street>


                <v-map
                        v-if="activeMapView === 'map'"
                        :center="{lat: $store.state.applications.application.site_address_coords.lat,
                                    lng: $store.state.applications.application.site_address_coords.lon}"
                        :zoom="16"
                        ref="applicationInfoMap"
                        class="el-block--application-map-preview"
                        style="width: 100%"
                        :options="{
   streetViewControl: false
 }"
                >
                    <gmap-custom-marker
                            alignment="center"
                            :marker="{lat: $store.state.applications.application.site_address_coords.lat,
                                    lng: $store.state.applications.application.site_address_coords.lon}"
                    >
                        <div class="h-10 w-10 flex items-center justify-center rounded-full bg-blue-800">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 width="20"
                                 class="fill-current text-white"
                                 height="20" viewBox="0 0 24 24">
                                <path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/>
                            </svg>
                        </div>
                    </gmap-custom-marker>
                </v-map>
            </div>
        </div>
</template>

<script>
    import GmapCustomMarker from "vue2-gmap-custom-marker";

    export default {
        name: "LocationMap",
        components: {
            GmapCustomMarker
        },
        data(){
            return {
                activeMapView: 'map'
            }
        },
        methods: {
            toggleMapView() {
                this.activeMapView = this.activeMapView === 'street' ? 'map' : 'street';
            },
        }
    }
</script>
<style>
    .el-button--map-change {
        bottom: 20px !important;
        left: 20px !important;
        z-index: 1001 !important;
    }
</style>