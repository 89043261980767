<template>

        <page>

            <template slot="header">
                <page-header title="Create Workflow">
                    <template v-slot:tabs>
                        <workflow-nav />
                    </template>
                </page-header>
            </template>

            <template slot="content">
                <div class="columns">
                    <div class="column w-full">
                        <dashboard-widget
                                title="Create Workflow"
                                padding-class="">

                            <div class="p-4">
                                <workflow-item
                                btn-size="default"
                                :show-close="false"
                                @created="(id) => { $router.push('/workflow/' + id) }"
                                />
                            </div>


                        </dashboard-widget>
                    </div>
                </div>
            </template>

        </page>


</template>
<script>
    import WorkflowNav from "./WorkflowNav";
    import WorkflowItem from "../../components/Workflow/WorkflowCreateEditForm";
    export default {
        name: 'WorkflowCreate',
        components: {WorkflowItem, WorkflowNav},
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.$store.commit('auth/setBreadcrumbs', [
                    {
                        title: 'Workflows',
                        path: '/workflows'
                    },
                    {
                        title: 'Create a Workflow'
                    }
                ]);
            })
        }
    }
</script>

