<template>
    <input
            ref="avatar"
            type="file"
            class="filepond"
            name="filepond"
            accept="image/png, image/jpeg, image/gif"
    >
</template>
<script>
    import * as FilePond from 'filepond';
    import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
    import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
    import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
    import FilePondPluginImageResize from 'filepond-plugin-image-resize';
    import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
    import FilePondPluginImageEdit from 'filepond-plugin-image-edit';


    export default {
        props: {
            value: {
                type: String,
                default(){
                    return null;
                },
                size: {
                    type: Number,
                    default(){
                        return 128;
                    }
                }
            }
        },
        mounted() {
            this.createAvatar();
        },
        methods: {
            createAvatar() {
                // Register the plugin
                FilePond.registerPlugin(
                    FilePondPluginFileValidateType,
                    FilePondPluginImageExifOrientation,
                    FilePondPluginImagePreview,
                    FilePondPluginImageCrop,
                    FilePondPluginImageResize,
                    FilePondPluginImageTransform,
                    FilePondPluginImageEdit
                );
                // Check if we have a image path or BASE64 encoded image
                let file = [];

                if(this.value && this.value.length > 0) {

                    file.push({source: this.value});
                }

                    // Create the filePond image element
                    FilePond.create(
                        this.$refs.avatar,
                        {
                            allowImagePreview: true,

                            multiple: false,
                            name: 'avatar',
                            labelIdle: 'Set Profile Picture',
                            allowImageResize: true,
                            imagePreviewHeight: this.size,
                            imageCropAspectRatio: '1:1',
                            imageResizeTargetWidth: this.size,
                            imageResizeTargetHeight: this.size,
                            stylePanelLayout: 'compact circle',
                            styleLoadIndicatorPosition: 'center bottom',
                            styleButtonRemoveItemPosition: 'center bottom',
                            files: file
                        }
                    );
                    // Register events
                    let filePondContainer = document.querySelector('.filepond--root');
                    filePondContainer.addEventListener('FilePond:addfile', this.fileUploaded);
                    filePondContainer.addEventListener('FilePond:removefile', this.fileRemoved);


            },
            fileUploaded(ev) {
                let reader = new FileReader();
                reader.readAsDataURL(ev.detail.file.file);
                reader.onload = () => {
                    this.$emit('change', reader.result);
                };
                reader.onerror = () => {
                    this.$message.error('An error occurred processing that file upload.');
                };
            },
            fileRemoved() {
                this.$emit('change', null);
            }
        }
    }
</script>
<style lang="scss">
    .filepond--drop-label.filepond--drop-label {
        min-height: auto !important;
        label {
            font-size: 10px;
            cursor: pointer;
        }
    }
    .filepond--file-wrapper {
        position: relative;
    }
    .filepond--action-remove-item {
        top: 0;
        left: 0 !important;
        width: 16px !important;
        height: 16px !important;
        border-radius: 9999px !important;
    }
    .filepond--root[data-style-panel-layout~=circle] {
        overflow: visible !important;
    }
    .filepond--root {
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
        cursor: pointer;
    }
</style>