<template>

        <page v-loading="loading">

            <template slot="header">
                <page-header
                        :title="team.name ? team.name + ' Invoices' : 'Manage Invoices'">
                    <template v-slot:tabs>
                        <active-team-nav />
                    </template>
                </page-header>

            </template>

            <template slot="content">
                <div class="columns">
                    <div class="column w-full">

                        <dashboard-widget
                                padding-class=""
                                title="Invoices">


                            <empty-state class="p-3"
                                         title="No invoices found"
                                         message="You currently have no invoices"
                                         :support="false"
                                         v-if="!table.loading && !table.results.length"/>


                            <div class="p-3 py-12 text-center" v-if="table.loading">
                                <property-loader />
                            </div>


                            <div v-if="table.results.length > 0 && !table.loading">
                                <div class="p-3">
                                    <el-alert effect="dark" type="info" :closable="false">
                                        {{ table.meta.totalResult.toLocaleString() }} invoices found. Displaying page {{ table.meta.current }} of {{ table.meta.pageCount }}
                                    </el-alert>
                                </div>
                                <div class="columns p-3">
                                    <div class="column ml-auto">
                                        <el-pagination
                                                background
                                                layout="prev, pager, next"
                                                @current-change="(page)=>{ fetch(page) }"
                                                :page-size="table.rpp"
                                                :current-page="table.meta.current"
                                                :total="table.meta.totalResult">
                                        </el-pagination>
                                    </div>
                                </div>
                                <el-table :data="table.results">
                                    <el-table-column label="Invoice ID">
                                        <template slot-scope="scope">
                                            {{ scope.row.invoice_id }}
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="Description">
                                        <template slot-scope="scope">
                                            {{ scope.row.description }}
                                        </template>
                                    </el-table-column>

                                    <el-table-column

                                            align="right"  width="170" label="Total">
                                        <template slot-scope="scope">
                                            £{{ scope.row.price.toLocaleString() }}
                                        </template>
                                    </el-table-column>

                                    <el-table-column width="170" label="Date">
                                        <template slot-scope="scope">
                                            <date-human-view v-model="scope.row.created_at" />
                                        </template>
                                    </el-table-column>


                                    <el-table-column width="150" align="right">
                                        <template slot-scope="scope">
                                            <el-button type="success"
                                                       size="medium"
                                                       @click="download(scope.row.link)"
                                                       icon="el-icon-download">Download</el-button>
                                        </template>


                                    </el-table-column>
                                </el-table>
                                <div class="columns p-3">
                                    <div class="column ml-auto">
                                        <el-pagination
                                                background
                                                layout="prev, pager, next"
                                                @current-change="(page)=>{ fetch(page) }"
                                                :page-size="table.rpp"
                                                :current-page="table.meta.current"
                                                :total="table.meta.totalResult">
                                        </el-pagination>
                                    </div>
                                </div>
                            </div>

                        </dashboard-widget>
                    </div>
                </div>
            </template>


        </page>

</template>
<script>

    import EmptyState from "../../components/EmptyState";
    import PropertyLoader from "../../components/incs/PropertyLoader";
    import ActiveTeamNav from "./ActiveTeamNav";

    export default {
        name: 'TeamInvoices',
        components: {ActiveTeamNav, PropertyLoader, EmptyState},
        mounted(){
            this.fetch(1);
        },
        methods: {
            download(link){
              window.open(link);
            },
            fetch(page) {
                const vm = this;
                vm.table.loading = true;
                return vm.$store.dispatch('team/invoices', {
                    teamId: vm.$route.params.teamId,
                    params: {
                        page: page || 1,
                        rpp: vm.table.rpp
                    }
                }).then((response) => {
                    vm.table.loading = false;
                    vm.table.results = response.data.data.results;
                    vm.table.meta = response.data.data.meta;
                }).catch(() => {
                    vm.table.loading = false;
                    vm.table.results = [];
                    vm.table.meta = {};
                })

            }
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.team = {};
                vm.loading = true;
                vm.$http.get('/api/v1/team/' + vm.$route.params.teamId)
                    .then((response) => {

                        vm.team = response.data.data;

                        vm.$store.commit('auth/setBreadcrumbs', [
                            {
                                title: 'My Teams',
                                path: '/teams'
                            },
                            {
                                title: response.data.data.name,
                                path: '/team/' + response.data.data.id
                            },
                            {
                                title: 'Invoices',
                            }
                        ]);



                        vm.fetch(1);

                        vm.loading = false;

                    }).catch(() => {

                })
            })
        },
        data(){
            return {
                loading: true,
                team: {},
                table: {
                    loading: true,
                    results: [

                    ],
                    meta: {}
                }
            }
        }
    }
</script>
<style>
    .el-option-select--seat-data {
        height: auto !important;
    }
</style>
