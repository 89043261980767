<template>

    <div>
        <div
                v-if="loading"
                class="flex h-full mt-12 pt-12 items-center justify-center">
            <div class="p-3 text-center">
                <property-loader />
                <h3 class="font-semibold text-blue-800">Fetching Traffic Data...</h3>
            </div>
        </div>
        <div
                v-if="!loading && hasData"
                class="columns">

            <div
                    v-if="getCoords.length > 0"
                    class="column w-full">

                <dashboard-widget
                        padding-class=""
                        title="Traffic Analysis">
                    <slot>

                        <div class="p-2">
                            <l-map
                                    ref="traffic-analysis-map"
                                    style="height: 220px;"
                                    @ready="onMapReady"
                                    :zoom="map.zoom"
                                    :center="map.center"
                                    :options="{zoomControl: false}"
                            >

                                <l-marker
                                        :key="index"
                                        v-for="(prop, index) of getCoords"
                                        :lat-lng="prop.coords">
                                    <l-icon>
                                        <div class="w-5 h-5 flex items-center justify-center shadow-lg rounded-full text-white text-xs"
                                             :class="{
                                'bg-green-700': selected_road === prop.id,
                                'bg-blue-700': selected_road !== prop.id
                                }"
                                        >
                                            {{ index + 1 }}
                                        </div>
                                    </l-icon>
                                </l-marker>

                                <l-google-tile-layer
                                        ref="googleLayer"
                                        :apikey="$store.state.auth.map.google.key"
                                        :options="{type: 'hybrid'} "/>

                            </l-map>
                        </div>

                        <div class="px-4 py-2">
                            <label class="uppercase block text-xs text-gray-800 mb-2 w-full">Select a Road</label>
                            <el-select
                                    class="w-full"
                                    filterable
                                    placeholder="Please select a road..."
                                    v-model="selected_road" clearable
                                    @change="()=>{ selected_year = null; }"
                            >
                                <el-option
                                        :key="i.id"
                                        :value="i.id"
                                        :label="(k + 1) + ' - ' + i.road_name"
                                        v-for="(i, k) of getCoords">
                                    {{ k + 1 }} - {{ i.road_name }}
                                </el-option>
                            </el-select>

                        </div>

                        <div class="px-4 py-2" v-if="selected_road !== ''">
                            <label class="uppercase block text-xs text-gray-800 mb-2 w-full">Select a Year</label>
                            <el-select
                                    class="w-full"
                                    filterable
                                    placeholder="Please select a year..."
                                    v-model="selected_year" clearable
                            >
                                <el-option
                                        :key="i.year"
                                        :value="i.year"
                                        :label="i.year"
                                        v-for="(i) of getYears">
                                    {{ i.year }}
                                </el-option>
                            </el-select>

                        </div>

                        <div class="mt-2 px-4" v-if="trafficTable.length > 0">

                            <h4 class="uppercase block text-sm font-semibold text-blue-900 mb-2">Annual Average daily flow</h4>

                            <div class="el-block--detail" :key="k"
                                 v-for="(i, k) of trafficTable">
                                <div class="flex flex-no-wrap mb-2">
                                    <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                        {{ i.label }}
                                    </div>
                                    <div class="w-32 text-right text-xs text-gray-900">
                                        {{ i.value }}
                                    </div>
                                </div>
                            </div>
                        </div>



                    </slot>
                </dashboard-widget>

            </div>

        </div>

        <div v-if="!loading && !hasData">
            <empty-state />
        </div>

    </div>

</template>

<script>

    import EmptyState from "../../EmptyState";
    import PropertyLoader from "../../incs/PropertyLoader";

    export default {
        name: "OwnershipTrafficAnalysis",
        components: {PropertyLoader, EmptyState},
        props: {
            id: {
                type: [Number, String],
                default(){
                    return null
                }
            },
            polygon: {
                type: Object,
                default(){
                    return null;
                }
            }
        },
        data() {
            return {
                data: {},
                loading: true,
                showPolygons: true,
                activeTabs: [],
                selected_road: '',
                selected_year: null,
                map: {
                    center: [0, 0],
                    zoom: 3
                },
            }
        },
        mounted(){
          this.fetchOwnership();
        },
        computed: {
          hasData(){
              return Object.keys(this.data).length > 0;
          },
            trafficTable(){
              let data = [];

              let selected = this.getSelectedYearData;

              if(!selected){
                  return []
              }

              data.push({
                  label: 'Method',
                  value: selected.estimation_method ? selected.estimation_method : 'Unknown'
              });

                data.push({
                    label: 'Pedal Cycles',
                    value: selected.pedal_cycles ? selected.pedal_cycles.toLocaleString() : 'N/A'
                });
                data.push({
                    label: 'Two wheeled motor vehicles',
                    value: selected.two_wheeled_motor_vehicles ? selected.two_wheeled_motor_vehicles.toLocaleString() : 'N/A'
                });
                data.push({
                    label: 'Cars and taxis',
                    value: selected.cars_and_taxis ? selected.cars_and_taxis.toLocaleString() : 'N/A'
                });
                data.push({
                    label: 'Buses and coaches',
                    value: selected.buses_and_coaches ? selected.buses_and_coaches.toLocaleString() : 'N/A'
                });

                data.push({
                    label: 'Light goods vehicles',
                    value: selected.lgvs ? selected.lgvs.toLocaleString() : 'N/A'
                });


                data.push({
                    label: 'Heavy goods vehicles',
                    value: selected.all_hgvs ? selected.all_hgvs.toLocaleString() : 'N/A'
                });

                data.push({
                    label: 'All motor vehicles',
                    value: selected.all_motor_vehicles ? selected.all_motor_vehicles.toLocaleString() : 'N/A'
                });

                return data;
            },
            getSelectedYearData(){
                const vm = this;
                let years = this.getYears;
                if(vm.selected_year && years.length > 0){
                    let item = years.find((item)=>{
                        return item.year === vm.selected_year;
                    });
                    return item ? item : null;
                }
                return null;

            },
            getRoadList(){
                return this.data && this.data.points && Array.isArray(this.data.points) ? this.data.points : [];
            },
            getYears(){
              const vm = this;
              if(vm.selected_road){
                  let item = this.getRoadList.find((item)=>{
                      return item.id === vm.selected_road;
                  });
                  return item && item.data ? item.data : [];
              }
              return [];
            },
            getCoords(){
                let coords = this.data && this.data.polygon && this.data.polygon.features && Array.isArray(this.data.polygon.features) ? this.data.polygon.features : [];
                return coords.map((item)=>{
                  return {
                      id: item.properties.count_point_id,
                      road_name: item.properties.road_name,
                      coords: {
                          lat: item.geometry.coordinates[1],
                          lng: item.geometry.coordinates[0]
                      }
                  }
              })
            }
        },
        methods: {
            onMapReady(){
                const vm = this;
                console.log(vm.data);
                if (vm.$refs && vm.$refs['traffic-analysis-map'] && vm.data.bbox) {
                    this.$refs['traffic-analysis-map'].mapObject.fitBounds([
                        [vm.data.bbox[1], vm.data.bbox[0]],
                        [vm.data.bbox[3], vm.data.bbox[2]],
                    ])
                }
            },
            fetchOwnership(){
              if(this.polygon !== null){
                  this.fetchTrafficDataPolygon()
              }else{
                  this.fetchTrafficDataOwnership()
              }
            },
            fetchTrafficDataPolygon(){
                const vm = this;
                vm.loading = true;
                this.$http.post('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/custom-draw', {
                    polygon: this.polygon,
                    view: 'traffic-analysis'
                })
                    .then((response)=>{
                        vm.loading = false;
                        vm.data = response.data.data;
                    }).catch(()=>{
                    vm.loading = false;
                    vm.data = {};
                })
            },
            fetchTrafficDataOwnership(){
                const vm = this;
                vm.loading = true;
                this.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/ownership/' + vm.id + '/traffic-analysis')
                    .then((response)=>{
                        vm.loading = false;
                         vm.data = response.data.data;
                    }).catch(()=>{
                    vm.loading = false;
                    vm.data = {};
                    })
            }
        }
    }
</script>

<style scoped>

</style>