<template>
    <div>
        <page>
            <template slot="header">
                <page-header title="Property for Sale">
                    <template v-slot:tabs>
                        <market-tracker-nav />
                    </template>
                </page-header>

            </template>

            <template slot="content">


                <div class="columns">
                    <div class="column w-1/2 lg:w-1/5">
                        <stat-box title="Average Price"
                                  :loading="loading"
                                  :value="avgs ? toPrice(avgs.buy_avg) : 'N/A'" />
                    </div>
                    <div class="column w-1/2 lg:w-1/5">
                        <stat-box title="Average Detached Price"
                                  :loading="loading"
                                  :value="avgs ? toPrice(avgs.buy_avg_detached) : 'N/A'" />
                    </div>
                    <div class="column w-1/2 lg:w-1/5">
                        <stat-box title="Average Semi-Detached"
                                  :loading="loading"
                                  :value="avgs ? toPrice(avgs.buy_avg_semi_detached) : 'N/A'" />
                    </div>
                    <div class="column hidden lg:block lg:w-1/5">
                        <stat-box title="Average Bungalow"
                                  :loading="loading"
                                  :value="avgs ? toPrice(avgs.buy_avg_bungalows) : 'N/A'" />
                    </div>
                    <div class="column w-1/2 lg:w-1/5">
                        <stat-box title="Average Flat/Apartment"
                                  :loading="loading"
                                  :value="avgs ? toPrice(avgs.buy_avg_flats) : 'N/A'" />
                    </div>

                    <div class="column w-full">
                        <dashboard-widget title="Property for Sale"
                                          padding-class="">
                            <template slot="cta">
                               <export-button
                               :exporting="loading"
                               />
                            </template>


                            <div class="h-32 w-full"
                                 v-if="loading"
                                 v-loading="true"></div>

                            <empty-state class="p-3"
                                         title="You have no application alerts"
                                         message="It look's like you have not created any application alerts"
                                         :support="false"
                                         v-if="!loading && !results.length" />

                            <market-tracker-table
                                    v-model="results"
                                    :loading="loading"
                                    :show-expander="true"
                                    :avg="avgs.buy_avg"
                                    :avg-detached="avgs.buy_avg_detached"
                                    :avg-semi="avgs.buy_avg_semi_detached"
                                    :avg-bungalow="avgs.buy_avg_bungalows"
                                    :avg-flat="avgs.buy_avg_flats"
                            />

                        </dashboard-widget>
                    </div>
                </div>


            </template>

        </page>


    </div>
</template>

<script>

    import EmptyState from "../../components/EmptyState";
    import MarketTrackerTable from "../../components/MarketTracker/Buy/MarketTrackerBuyTable";
    import ExportButton from "../../components/ExportButton";
    import MarketTrackerNav from "./MarketTrackerNav";

    export default {
        name: "MarketTrackerForSale",
        components: {MarketTrackerNav, ExportButton, MarketTrackerTable, EmptyState},
        data() {
            return {
                loading: true,
                default_sort: {
                    prop: 'buy_avg',
                    order: 'descending'
                },
                col: 'buy_avg',
                results: [],
                avgs: {}
                }
        },
        created() {
            this.fetch();
        },
        computed: {
            getData(){

                if(!this.results.length){
                    return [];
                }

                if(!this.col){
                    return this.results;
                }

                let col = this.default_sort.prop, results = this.results;
                if(this.default_sort.order === 'descending'){
                    return results.sort((a,b) => (a[col] > b[col]) ? 1 : ((b[col] > a[col]) ? -1 : 0))
                }
                if(this.default_sort.order === 'ascending'){
                    return results.sort((a,b) => (a[col] < b[col]) ? 1 : ((b[col] < a[col]) ? -1 : 0))
                }
                return results;
            }
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.$store.commit('auth/setBreadcrumbs', [
                    {
                        title: 'MarketTracker',
                        path: '/market-tracker'
                    },
                    {
                        title: 'Property for Sale'
                    }
                ]);
            })
        },
        methods: {
            toPrice(value){
              return value ? "£" + Math.floor(value).toLocaleString() : 'N/A';
            },
            onSortChange(col){
                this.default_sort = {
                    prop: col.column.property,
                    order: col.column.order
                }
            },
            fetch() {
                let vm = this;
                vm.results = [];
                vm.loading = true;
                vm.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/property-prices/area').then((response) => {
                    vm.avgs = response.data.data.avgs;
                    vm.results = response.data.data.results;
                    vm.loading = false;
                }).catch(() => {
                    vm.loading = false;
                })
            }
        }
    }
</script>