<template>
    <div class="columns">
                <div class="column w-full">
                    <dashboard-widget title="Search Planning Applications">
                        <template slot="cta">
                            <el-button type="success"
                                       @click="$emit('search')"
                                       icon="el-icon-search">Search</el-button>
                        </template>
                        <slot>

                            <div class="p-4">
                                <div class="columns">
                                    <div class="column w-1/2">
                                        <h3 class="text-md uppercase mb-2 font-medium text-gray-500 uppercase">
                                            Application
                                        </h3>
                                        <el-form label-position="left" label-width="220px" :model="$store.state.applications.search.form.application">
                                            <el-form-item label="Authority">
                                                <el-select
                                                        class="w-full"
                                                        filterable
                                                        placeholder="Select Local Authority..."
                                                        v-model="$store.state.applications.search.form.application.local_authority" clearable
                                                >
                                                    <el-option
                                                            :key="k"
                                                            :value="i.value"
                                                            :label="i.label"
                                                            v-for="(i, k) of options.authority"></el-option>
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item label="Application Reference">
                                                <el-input
                                                        @keyup.enter.native="$emit('search')"
                                                        v-model="$store.state.applications.search.form.application.application_reference" clearable></el-input>
                                            </el-form-item>

                                            <el-form-item label="Type">
                                                <el-input
                                                        @keyup.enter.native="$emit('search')"
                                                        v-model="$store.state.applications.search.form.application.type" clearable></el-input>
                                            </el-form-item>

                                            <el-form-item label="Proposal">
                                                <el-input
                                                        @keyup.enter.native="$emit('search')"
                                                        v-model="$store.state.applications.search.form.application.proposal" clearable></el-input>
                                            </el-form-item>

                                            <el-form-item label="Site Address">
                                                <el-input
                                                        @keyup.enter.native="$emit('search')"
                                                        v-model="$store.state.applications.search.form.application.site_address" clearable></el-input>
                                            </el-form-item>

                                            <el-form-item label="Ward">
                                                <el-input
                                                        @keyup.enter.native="$emit('search')"
                                                        v-model="$store.state.applications.search.form.application.ward" clearable></el-input>
                                            </el-form-item>

                                            <el-form-item label="District">
                                                <el-input
                                                        @keyup.enter.native="$emit('search')"
                                                        v-model="$store.state.applications.search.form.application.district" clearable></el-input>
                                            </el-form-item>

                                            <el-form-item label="Parish">
                                                <el-input
                                                        @keyup.enter.native="$emit('search')"
                                                        v-model="$store.state.applications.search.form.application.parish" clearable></el-input>
                                            </el-form-item>

                                            <el-form-item label="Status">
                                                <el-select
                                                        class="w-full"
                                                        filterable
                                                        placeholder="Select Application Status..."
                                                        v-model="$store.state.applications.search.form.application.application_status" clearable
                                                >
                                                    <el-option
                                                            :key="k"
                                                            :value="i.value"
                                                            :label="i.label"
                                                            v-for="(i, k) of options.status"></el-option>
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item label="Decision">
                                                <el-select
                                                        class="w-full"
                                                        filterable
                                                        placeholder="Select Decision..."
                                                        v-model="$store.state.applications.search.form.application.decision" clearable
                                                >
                                                    <el-option
                                                            :key="k"
                                                            :value="i.value"
                                                            :label="i.label"
                                                            v-for="(i, k) of options.decision"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-form>



                                    </div>
                                    <div class="column w-1/2">
                                        <h3 class="text-md uppercases mb-2 font-medium text-gray-500 uppercase">
                                            Applicant Details
                                        </h3>
                                        <el-form label-position="left" label-width="220px" :model="$store.state.applications.search.form.applicant">

                                            <el-form-item label="Applicant Name">
                                                <el-input
                                                        @keyup.enter.native="$emit('search')"
                                                        v-model="$store.state.applications.search.form.applicant.applicant_name" clearable></el-input>
                                            </el-form-item>


                                            <el-form-item label="Applicant Address">
                                                <el-input
                                                        @keyup.enter.native="$emit('search')"
                                                        v-model="$store.state.applications.search.form.applicant.applicant_address" clearable></el-input>
                                            </el-form-item>


                                            <el-form-item label="Applicant Company">
                                                <el-input
                                                        @keyup.enter.native="$emit('search')"
                                                        v-model="$store.state.applications.search.form.applicant.applicant_company" clearable></el-input>
                                            </el-form-item>

                                        </el-form>
                                        <h3 class="text-md uppercases mb-2 font-medium text-gray-500 uppercase">
                                            Agent Details
                                        </h3>
                                        <el-form label-position="left" label-width="220px" :model="$store.state.applications.search.form.agent">
                                            <el-form-item label="Agent Name">
                                                <el-input
                                                        @keyup.enter.native="$emit('search')"
                                                        v-model="$store.state.applications.search.form.agent.agent_name" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="Agent Company">
                                                <el-input
                                                        @keyup.enter.native="$emit('search')"
                                                        v-model="$store.state.applications.search.form.agent.agent_company" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="Agent Address">
                                                <el-input
                                                        @keyup.enter.native="$emit('search')"
                                                        v-model="$store.state.applications.search.form.agent.agent_address" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="Agent Email">
                                                <el-input
                                                        @keyup.enter.native="$emit('search')"
                                                        v-model="$store.state.applications.search.form.agent.agent_email" clearable></el-input>
                                            </el-form-item>

                                        </el-form>
                                        <h3 class="text-md uppercase mb-2 font-medium text-gray-500 uppercase">
                                            Dates
                                        </h3>

                                        <el-form label-position="left" label-width="220px" :model="$store.state.applications.search.form.dates">
                                            <el-form-item label="Received">
                                                        <el-date-picker
                                                                v-model="$store.state.applications.search.form.dates.date_received"
                                                                :clearable="true"
                                                                :picker-options="pickerOptions"
                                                                type="daterange"
                                                                format="dd MMM yyyy"
                                                                range-separator="To"
                                                                start-placeholder="Start date"
                                                                end-placeholder="End date">
                                                        </el-date-picker>
                                            </el-form-item>


                                            <el-form-item label="Validated">
                                                <el-date-picker
                                                        v-model="$store.state.applications.search.form.dates.date_validated"
                                                        :clearable="true"
                                                        :picker-options="pickerOptions"
                                                        type="daterange"
                                                        format="dd MMM yyyy"
                                                        range-separator="To"
                                                        start-placeholder="Start date"
                                                        end-placeholder="End date">
                                                </el-date-picker>
                                            </el-form-item>


                                            <el-form-item label="Decided">
                                                <el-date-picker
                                                        v-model="$store.state.applications.search.form.dates.date_decided"
                                                        :clearable="true"
                                                        :picker-options="pickerOptions"
                                                        type="daterange"
                                                        format="dd MMM yyyy"
                                                        range-separator="To"
                                                        start-placeholder="Start date"
                                                        end-placeholder="End date">
                                                </el-date-picker>
                                            </el-form-item>


                                            <el-form-item label="Decision Expected">
                                                <el-date-picker
                                                        v-model="$store.state.applications.search.form.dates.date_decision_expected"
                                                        :clearable="true"
                                                        :picker-options="pickerOptions"
                                                        type="daterange"
                                                        format="dd MMM yyyy"
                                                        range-separator="To"
                                                        start-placeholder="Start date"
                                                        end-placeholder="End date">
                                                </el-date-picker>
                                            </el-form-item>



                                        </el-form>
                                    </div>
                                    <div class="column w-full text-right">
                                        <el-button type="success"
                                                   @click="$emit('search')"
                                                   icon="el-icon-search">Search</el-button>
                                    </div>
                                </div>
                            </div>



                        </slot>
                    </dashboard-widget>
                </div>
            </div>
</template>

<script>


    import {getDefaultDateOptions} from './../utils/dates'

    export default {
        name: 'ApplicationsSearchForm',
        methods: {
            fetchLocalAuthorities() {
                let vm = this;
                vm.loading.authority = true;
                vm.$http.get('/api/v1/types/application/local-authority').then((response) => {
                    vm.options.authority = response.data.data;
                    vm.loading.authority = false;
                }).catch(() => {
                    vm.loading.authority = false;
                })
            }
        },
        created() {
            this.fetchLocalAuthorities();
        },
        data() {
            return {
                pickerOptions: getDefaultDateOptions(),
                loading: {
                    status: true,
                    type: true,
                    decision: true,
                    authority: true
                },
                options: {
                    status: [
                        {value: 'DECIDED', label: 'Decided'},
                        {value: 'PENDING', label: 'Pending'},
                        {value: 'WITHDRAWN', label: 'Withdrawn'},
                    ],
                    decision: [
                        {value: 'APPROVED', label: 'Approved'},
                        {value: 'PENDING', label: 'Pending'},
                        {value: 'REFUSED', label: 'Refused'},
                        {value: 'WITHDRAWN', label: 'Withdrawn'},
                        {value: 'SPLIT', label: 'Split Decision'}
                    ],
                    authority: []
                }
            }
        }
    }
</script>
<style>
    .is-full-width {
        width: 100% !important;
    }
    .el-range-editor.el-input__inner {
        width:  100% !important;
    }
</style>