<template>

        <page v-loading="loading">

            <template slot="header">
                <page-header
                        :title="team.name ? team.name + ' Invites' : 'Manage Invites'">
                    <template v-slot:tabs>
                        <active-team-nav />
                    </template>
                </page-header>

            </template>

            <template slot="content">
                <div class="columns">
                    <div class="column w-full">

                        <dashboard-widget
                                padding-class=""
                                title="Invites">

                            <template slot="cta">
                                <el-button type="success"
                                           @click="invite.show = true"
                                           icon="el-icon-plus">Create Invite</el-button>
                            </template>

                            <empty-state class="p-3"
                                         title="No invites found"
                                         message="You currently have no pending invites"
                                         :support="false"
                                         v-if="!table.loading && !table.results.length"/>


                            <div class="p-3 py-12 text-center" v-if="table.loading">
                                <property-loader />
                            </div>


                            <div v-if="table.results.length > 0 && !table.loading">
                                <div class="p-3">
                                    <el-alert effect="dark" type="info" :closable="false">
                                        {{ table.meta.totalResult.toLocaleString() }} invites found. Displaying page {{ table.meta.current }} of {{ table.meta.pageCount }}
                                    </el-alert>
                                </div>
                                <div class="columns p-3">
                                    <div class="column ml-auto">
                                        <el-pagination
                                                background
                                                layout="prev, pager, next"
                                                @current-change="(page)=>{ fetch(page) }"
                                                :page-size="table.rpp"
                                                :current-page="table.meta.current"
                                                :total="table.meta.totalResult">
                                        </el-pagination>
                                    </div>
                                </div>
                                <el-table :data="table.results">
                                    <el-table-column label="Email">
                                        <template slot-scope="scope">
                                            {{ scope.row.email }}
                                        </template>
                                    </el-table-column>

                                    <el-table-column
                                            width="170"
                                            label="Last Sent">
                                        <template slot-scope="scope">
                                            <date-human-view v-model="scope.row.created_at" />
                                        </template>
                                    </el-table-column>


                                    <el-table-column width="140" align="right">
                                        <template slot-scope="scope">


                                        <el-dropdown @command="(cmd)=>{ handleCommand(cmd, scope.row.id)  }">
                                            <el-button size="medium" type="info">
                                                Actions <i class="el-icon-arrow-down el-icon--right"></i>
                                            </el-button>
                                            <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item command="revoke">
                                                    Revoke
                                                </el-dropdown-item>
                                                <el-dropdown-item command="resend">
                                                    Resend
                                                </el-dropdown-item>
                                            </el-dropdown-menu>

                                        </el-dropdown>

                                        </template>


                                    </el-table-column>
                                </el-table>
                                <div class="columns p-3">
                                    <div class="column ml-auto">
                                        <el-pagination
                                                background
                                                layout="prev, pager, next"
                                                @current-change="(page)=>{ fetch(page) }"
                                                :page-size="table.rpp"
                                                :current-page="table.meta.current"
                                                :total="table.meta.totalResult">
                                        </el-pagination>
                                    </div>
                                </div>
                            </div>

                        </dashboard-widget>
                    </div>
                </div>
            </template>


            <el-dialog
                    title="Create New Invite"
                    :append-to-body="true"
                    :close-on-press-escape="false"
                    :close-on-click-modal="false"
                    :show-close="!invite.updating"
                    :visible.sync="invite.show">



                    <el-form label-position="top"
                             :rules="rules"
                             :model="invite.model">
                        <el-form-item label="Email Address" prop="email">
                            <el-input
                                    :disabled="invite.updating"
                                    v-model="invite.model.email" />
                        </el-form-item>
                    </el-form>

                    <div class="flex items-center w-full -px-2">
                       <div class="ml-auto flex items-center p-2">
                           <el-link
                                   :disabled="invite.updating"
                           @click="invite.show = false"
                           >Cancel</el-link>
                       </div>
                        <div class="p-2">
                            <el-button
                                    :disabled="invite.updating"
                                    :loading="invite.updating"
                                    @click="addInvite"
                                    type="success">Invite</el-button>
                        </div>
                    </div>


            </el-dialog>

        </page>

</template>
<script>

    import EmptyState from "../../components/EmptyState";
    import PropertyLoader from "../../components/incs/PropertyLoader";
    import ActiveTeamNav from "./ActiveTeamNav";

    export default {
        name: 'Invites',
        components: {ActiveTeamNav, PropertyLoader, EmptyState},
        mounted(){
            this.fetch(1);
        },
        watch: {
            'invite.show'(){
                if(this.invite.show){
                    this.invite.model.email = '';
                }
            }
        },
        methods: {
            handleCommand(command, id){
                switch(command){
                    case "revoke":
                        return this.revoke(id);
                    case "resend":
                        return this.resend(id);
                }
            },
            addInvite(){
                const vm = this;
               vm.invite.updating = true;

                return vm.$store.dispatch('teamInvite/create', {
                    teamId: vm.$route.params.teamId,
                    email: vm.invite.model.email
                }).then(() => {
                    vm.invite.updating = false;

                    vm.$notify({
                        type: 'success',
                        title: 'Invite Sent',
                        message: 'Invite was sent successfully',
                        position: 'top-right',
                        offset: 140,
                    });

                    vm.invite.show = false;

                    setTimeout(()=>{
                        vm.fetch(1);
                    }, 100)

                }).catch((e) => {
                    vm.invite.updating = false;
                    vm.$store.dispatch('auth/handleServerError', {
                        vm: vm,
                        error: e
                    });
                })

            },
            revoke(id){

                let vm = this;

                vm.$confirm(
                    'Are you sure you want to revoke this invite? This cannot be undone.',
                    'Revoke Invite?',
                    {
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'Cancel'
                    })
                    .then(() => {
                        //vm.loading = true;


                        this.$store.dispatch('teamInvite/remove', {
                            teamId: vm.$route.params.teamId,
                            inviteId: id
                        }).then(()=>{
                            vm.$notify({
                                type: 'success',
                                title: 'Invite Revoked',
                                message: 'Invite was revoked successfully',
                                position: 'top-right',
                                offset: 140,
                            });

                            setTimeout(()=>{
                                vm.fetch(1);
                            }, 100)
                        }).catch((e)=>{
                            console.log('error', e);
                            vm.$store.dispatch('auth/handleServerError', {
                                vm: vm,
                                error: e
                            });
                        })


                    }).catch((e) => {
                    console.log(e);
                });
            },

            resend(id){

                let vm = this;
                vm.table.loading = true;

                vm.$confirm(
                    'Are you sure you want to resend this invite? This cannot be undone.',
                    'Resend Invite?',
                    {
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'Cancel'
                    })
                    .then(() => {
                        //vm.loading = true;
                        vm.table.loading = true;
                        this.$store.dispatch('teamInvite/resend', {
                            teamId: vm.$route.params.teamId,
                            inviteId: id
                        }).then(()=>{

                            vm.$notify({
                                type: 'success',
                                title: 'Invite Resent',
                                message: 'Invite was resent successfully',
                                position: 'top-right',
                                offset: 140,
                            });

                            setTimeout(()=>{
                                vm.fetch(1);
                            }, 100)
                        }).catch((e)=>{
                            vm.table.loading = false;
                            console.log('error', e);
                            vm.$store.dispatch('auth/handleServerError', {
                                vm: vm,
                                error: e
                            });
                        })


                    }).catch((e) => {
                    vm.table.loading = false;
                    console.log(e);
                });
            },

            fetch(page) {
                const vm = this;
                vm.table.loading = true;
                return vm.$store.dispatch('teamInvite/index', {
                    teamId: vm.$route.params.teamId,
                    params: {
                        page: page || 1,
                        rpp: vm.table.rpp
                    }
                }).then((response) => {
                    vm.table.loading = false;
                    vm.table.results = response.data.data.results;
                    vm.table.meta = response.data.data.meta;
                }).catch(() => {
                    vm.table.loading = false;
                    vm.table.results = [];
                    vm.table.meta = {};
                })

            }
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.team = {};
                vm.loading = true;
                vm.$http.get('/api/v1/team/' + vm.$route.params.teamId)
                    .then((response) => {

                        vm.team = response.data.data;

                        vm.$store.commit('auth/setBreadcrumbs', [
                            {
                                title: 'My Teams',
                                path: '/teams'
                            },
                            {
                                title: response.data.data.name,
                                path: '/team/' + response.data.data.id
                            },
                            {
                                title: 'Invites',
                            }
                        ]);



                        vm.fetch(1);

                        vm.loading = false;

                    }).catch(() => {

                })
            })
        },
        data(){
            return {
                loading: true,
                invite: {
                    updating: false,
                    show: false,
                    model: {
                       email: ''
                    }
                },
                rules: {
                    email: [
                        {required: true, message: 'An email address is required', trigger: 'blur'},
                    ]
                },
                team: {},
                table: {
                    loading: true,
                    results: [

                    ],
                    meta: {}
                }
            }
        }
    }
</script>
<style>
    .el-option-select--seat-data {
        height: auto !important;
    }
</style>
