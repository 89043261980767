<template>
  <div>
      <div class="p-3 text-center" v-if="loading">
          <property-loader />
      </div>
      <div v-loading="switching" v-else>
          <div class="py-3">
              <el-alert effect="dark" type="info" :closable="false">
                  You are currently a member of {{ results.length.toLocaleString() }} teams.
              </el-alert>
          </div>

          <div v-if="results.length > 0">
              <div class="relative bg-white h-64 overflow-scroll rounded-md -space-y-px w-full">
                  <label
                          :key="item.id"
                          v-for="(item, index) of results"
                          :class="{
                                        'border-gray-200' : selectedTeamId !== item.id,
                                        'bg-indigo-100 border-indigo-200 z-10': selectedTeamId  === item.id,
                                        'rounded-tl-md rounded-tr-md': index === 0 && results.length > 1,
                                         'rounded-bl-md rounded-br-md': index === (results.length - 1) && results.length > 1,
                                        }"
                          class="relative border p-4 flex cursor-pointer md:pl-4 md:pr-6">
                      <span class="flex w-1/3 items-center text-sm leading-tight w-full">
                          <span>
                                <avatar-manager
                                        :name="item.name"
                                        v-model="item.avatar" />
                          </span>
                          <input type="radio"
                                 v-model="selectedTeamId" :value="item.id" class="hidden" aria-labelledby="pricing-plans-0-label" aria-describedby="pricing-plans-0-description-0 pricing-plans-0-description-1">
                          <span
                                  :class="{
                                                  'text-gray-900' : selectedTeamId  !== item.id,
                                        'text-indigo-900': selectedTeamId  === item.id,
                                            }"
                                  class="ml-3 w-full">
                              <span class="block flex items-center">
                                  <span class="font-medium mr-4">{{ item.name  }}</span>
                                  <span v-if="item.is_selected" class="px-2 py-1 rounded text-xxs bg-green-600 text-green-100">Current Team</span><br />
                              </span>
                                           <span class="flex w-full mt-2 text-xs">
                                                    <span class="flex items-center">
                                                        <span class="mr-1">{{ item.team_members }} member{{ item.team_members > 1 ? 's' : '' }} </span>
                                                    </span>
                                                     <span v-if="item.is_owner" class="flex items-center ml-2">
                                                             <i class="el-icon-check text-green-500 mr-2"></i>
                                                        <span>You are the owner</span>
                                                    </span>
                                                  <span v-if="item.subscriptions !== null" class="flex items-center ml-2">
                                                      <i class="el-icon-user text-gray-700 mr-2"></i>
                                                        <span>{{ item.subscriptions }} active subscription{{ item.subscriptions > 1 ? 's' : '' }}</span>
                                                    </span>
                                                </span>
                                        </span>
                      </span>
                      <span class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 leading-tight text-right w-1/2">
                                        <span
                                                :class="{
                                                  'text-gray-900' : selectedTeamId  !== item.id,
                                        'text-indigo-900': selectedTeamId  === item.id,
                                            }"
                                                class="font-medium">
                                          <span v-if="item.subscription_expires_in">
                                             Subscription till {{ item.subscription_expires_in | moment("MMM Do YYYY") }}<br />
                                              <span class="text-xs"><date-human-view size="text-xs" prefix="Expires in " v-model="item.subscription_expires_in" /></span>
                                          </span>
                                            <span v-else>
                                                <span class="tag is-warning">No Subscription</span>
                                            </span>
                                        </span>
                      </span>
                  </label>

              </div>

              <div class="pt-8 text-right" v-if="selectedTeamId !== null">
                  <el-button
                          @click="switchTeam"
                          icon="el-icon-refresh" type="success">Switch Team</el-button>
              </div>
          </div>
      </div>

  </div>
</template>
<script>


    import AvatarManager from "../AvatarManager";
    import PropertyLoader from "../incs/PropertyLoader";
    import collect from "collect.js";

    export default {
        name: 'SwitchTeam',
        components: {PropertyLoader, AvatarManager},
        data() {
            return {
                results: [],
                selectedTeamId: null,
                loading: true,
                switching: false
            }
        },
        mounted(){
            this.selectedTeamId = this.$store.state.auth.user.team.id;
          this.fetch();
        },
        methods: {
            switchTeam(){
                const vm = this;
                vm.switching = true;
                vm.$store.dispatch('team/switch', {
                    teamId: vm.selectedTeamId
                }).then(()=>{

                    vm.$http.get('/api/v1/auth/user')
                        .then((response)=>{
                            vm.$store.commit('auth/setUser', response.data.data);
                            vm.$store.state.auth.showSwitchTeam = false;
                            window.location = '/?switch=false';
                        })

                }).catch(()=>{
                    vm.switching = false;
                })
            },
            fetch() {
                const vm = this;
                vm.loading = true;
                return vm.$store.dispatch('team/index').then((response) => {
                    vm.loading = false;
                    vm.results = collect(response.data.data).map((x) => {
                        x.is_selected = x.id === vm.selectedTeamId;
                        return x;
                    }).sortByDesc('is_selected').toArray();
                }).catch(() => {
                    vm.loading = false;
                    vm.results = [];
                })
            }
        }
    }
</script>