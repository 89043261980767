<template>
    <div
            v-if="loading"
            class="flex h-full items-center justify-center">
        <div class="p-3 text-center">
            <property-loader />
            <h3 class="font-semibold text-blue-800">Fetching Local Property Density...</h3>
        </div>
    </div>
    <div v-else>


            <dashboard-widget
                    v-if="getStats.stats && getStats.stats.length > 0"
                    padding-class="''"
                    :title="title">

                <ownership-overview-map
                        class="p-2"
                        ref="ownership-map"
                        :height="240"
                        :coords="filteredList.center"
                        :polygons="[]"
                        :bbox="filteredList.bbox"
                >

                    <l-heat-map
                            ref="heatMapObject"
                            :radius="6"
                            :blur="10"
                            :max-zoom="14"
                            :max="1.0"
                            :lat-lng="getStats.map"
                    ></l-heat-map>
                    <l-circle
                            v-for="(x, i) of radius"
                            :key="i"
                            :lat-lng="filteredList.center"
                            :radius="x.distance"
                            :opacity="0.7"
                            color="#fff"
                            :weight="2"
                    />




                </ownership-overview-map>

                <p class="p-2 leading-tight text-xs text-blue-500">Distance is calculated from the furthest boundary. Radical circles are calculated from the center.</p>

                <el-table
                        striped
                        border
                        :data="getStats.stats"
                        header-row-class-name="bg-blue-300"
                        style="width: 100%">
                    <el-table-column
                            prop="distance"
                            class-name="font-semibold"
                            label="Distance">
                        <template slot-scope="scope">
                            {{ scope.row.label }}
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="count"
                            width="100px"
                            align="right"
                            label="Properties">
                        <template slot-scope="scope">
                            {{ scope.row.count ? scope.row.count.toLocaleString() : 0 }}
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="distance"
                            width="130px"
                            align="right"
                            label="Density Ratio">
                        <template slot-scope="scope">
                            {{ scope.row.ratio ? scope.row.ratio : 0 }}%
                        </template>
                    </el-table-column>
                </el-table>

            </dashboard-widget>

    </div>
</template>

<script>
    import PropertyLoader from "../../incs/PropertyLoader";
    import OwnershipOverviewMap from "./incs/OwnershipOverviewMap";
    export default {
        name: "OwnershipPropertyDensityComponent",
        components: {OwnershipOverviewMap, PropertyLoader},
        props: {
            activeSiteId: {
                type: Number,
                default(){
                    return null;
                }
            },
            viewType: {
                type: String,
                default(){
                    return 'residential'
                }
            },
            loading: {
              type: Boolean,
              default(){
                  return true;
              }
            },
            value: {
                type: Object,
                default(){
                    return {}
                }
            },
            title: {
                type: String,
                default(){
                    return ''
                }
            }
        },
        watch: {
          getStats(){
              if(this.$refs.heatMapObject){
                  this.$refs.heatMapObject.redraw()
              }
          }
        },
        computed: {
            getStats(){
              return this.filteredList && this.filteredList.stats && this.filteredList.stats[this.viewType] ? this.filteredList.stats[this.viewType] : {}
            },
            filteredList(){
                return this.value
            }
        },
        data(){
            return {
                selected: null,
                radius: [
                    {distance: 50},
                    {distance: 100},
                    {distance: 200},
                    {distance: 300},
                    {distance: 400},
                    {distance: 500}
                ]
            }
        }
    }
</script>