<template>
        <div class="overflow-hidden overflow-y-scroll el-block--overlay-legend">
            <div
                    :key="i"
                    v-for="(o, i) of $store.state.map.active_overlays">

                <overlay-detail
                        v-if="o === 'salePrices' && $store.state.map.data_metrics.sale_prices.legend"
                        :keyed="true"
                        :show-close="false"
                        @close="()=> {
                        $store.commit('map/toggleLayerAvailability', 'salePrices')
                        }"
                        class="mb-2">
                    <template slot="title">Sale Prices</template>
                    <template slot="legend">
                        <div class="flex items-center p-1"
                             :key="k"
                             v-for="(i, k) of $store.state.map.data_metrics.sale_prices.legend">
                            <div class="w-4 h-4 rounded" :style="{
                            background: getSalesPriceColorByIndex(k)
                            }"></div>
                            <span class="text-xs ml-2  leading-tight">£{{ i.min }} - £{{ i.max }}</span>
                        </div>
                    </template>
                </overlay-detail>

                <overlay-detail
                        v-if="o === 'ownership'"
                        :keyed="true"
                        :show-close="false"
                        ref="overlay-polygons-ownership" class="mb-2">
                    <template slot="title">Ownership</template>
                    <template slot="legend">
                        <div class="flex items-center p-1">
                            <div class="w-4 h-4 rounded border border-gray-800 bg-blue-500"></div>
                            <span class="text-xs ml-2  leading-tight">Private</span>
                        </div>
                        <div class="flex items-center p-1">
                            <div class="w-4 h-4 rounded border border-gray-800 bg-red-500"></div>
                            <span class="text-xs ml-2  leading-tight">Corporate</span>
                        </div>
                        <div class="flex items-center p-1">
                            <div class="w-4 h-4 rounded border border-gray-800 bg-purple-500"></div>
                            <span class="text-xs ml-2  leading-tight">Company</span>
                        </div>
                        <div class="flex items-center p-1">
                            <div class="w-4 h-4 rounded border border-gray-800 bg-yellow-400"></div>
                            <span class="text-xs ml-2  leading-tight">Housing Association</span>
                        </div>
                        <div class="flex items-center p-1">
                            <div class="w-4 h-4 rounded border border-gray-800 bg-pink-500"></div>
                            <span class="text-xs ml-2  leading-tight">Local Council/Authority</span>
                        </div>
                    </template>
                </overlay-detail>

                <overlay-detail
                        v-if="o === 'salePrices' && !$store.state.map.data_metrics.sale_prices.chart.fetching && $store.getters['map/getSaleDataMarkers'].length > 0"
                        :keyed="true"
                        :show-close="false"
                        @close="()=> {
                        $store.commit('map/toggleLayerAvailability', 'salePrices')
                        }"
                        class="mb-2">
                    <template slot="title">Sale Prices</template>
                    <template slot="legend">
                        <div class="flex items-center p-1"
                             :key="k"
                             v-for="(i, k) of $store.getters['map/getSaleDataLegend']">
                            <div class="w-4 h-4 rounded border border-gray-800" :style="{background: i.color }"></div>
                            <span class="text-xs ml-2  leading-tight">{{ i.min }}-{{ i.max }}</span>
                        </div>
                    </template>
                </overlay-detail>


                <overlay-detail
                        v-if="o === 'epcs'"
                        :keyed="true"
                        :show-close="false"
                        class="mb-2">
                    <template slot="title">EPC Ratings</template>
                    <template slot="legend">
                        <div class="flex flex-wrap">
                            <div class="w-1/2 flex p-1 items-center"
                                 :key="k"
                                 v-for="(i, k) of $store.getters['map/getEPCRatingDataLegend']"
                            >
                                <div class="w-4 h-4 rounded border border-gray-800" :style="{background: i.color }"></div>
                                <span class="text-xs ml-2  leading-tight">{{ i.label }}</span>
                            </div>
                        </div>
                    </template>
                </overlay-detail>

                <overlay-detail
                        v-if="o === 'planning'"
                        :keyed="true"
                        :show-close="false"
                        class="mb-2">
                    <template slot="title">Planning Applications</template>
                    <template slot="legend">
                        <div class="flex flex-wrap">
                            <div class="w-full flex p-1 items-center"
                                 :key="k"
                                 v-for="(i, k) of $store.getters['map/getPlanningApplicationDataLegend']"
                            >
                                <div class="w-4 h-4 rounded border border-gray-800" :style="{background: i.color }"></div>
                                <span class="text-xs ml-2  leading-tight">{{ i.label }}</span>
                            </div>
                        </div>
                    </template>
                </overlay-detail>

                <overlay-detail
                        v-if="o === 'leaseholders' && !$store.state.map.data_metrics.leaseholders.fetching_polygons && $store.getters['map/getLeaseholderDataMarkers'].length > 0"
                        :keyed="true"
                        :show-close="false"
                        @close="()=> {
                        $store.commit('map/toggleLayerAvailability', 'leaseholders')
                        }"
                        class="mb-2">
                    <template slot="title">Leaseholders</template>
                    <template slot="legend">
                        <div class="flex items-center p-1">
                            <div class="w-2 h-2 border transform rotate-45 border-white bg-blue-400"></div>
                            <span class="text-xs ml-2  leading-tight">Leaseholder</span>
                        </div>
                    </template>
                </overlay-detail>


                <overlay-detail
                        v-if="o === 'listedBuildings'"
                        :keyed="true"
                        @close="()=> {
                        $store.commit('map/toggleLayerAvailability', 'listedBuildings')
                        }"
                        class="mb-2">
                    <template slot="title">Listed Buildings</template>
                    <template slot="legend">
                        <div class="flex items-center p-1">
                            <div class="w-3 h-3 border rounded-full border-white bg-orange-700"></div>
                            <span class="text-xs ml-2  leading-tight">Listed Buildings</span>
                        </div>
                    </template>
                </overlay-detail>


                <overlay-detail
                        :keyed="true"
                        v-if="o === 'aonb'"
                        @close="()=> {
                        $store.commit('map/toggleLayerAvailability', 'aonb')
                        }"
                        class="mb-2">
                    <template slot="title">Areas of Outstanding Natural Beauty</template>
                    <template slot="legend">
                        <div class="flex items-center p-1">
                            <div class="w-4 h-4 rounded border border-gray-800 bg-orange-300"></div>
                            <span class="text-xs ml-2  leading-tight">AONB</span>
                        </div>
                    </template>
                </overlay-detail>


                <overlay-detail
                        :keyed="true"
                        v-if="o === 'conservation'"
                        @close="()=> {
                        $store.commit('map/toggleLayerAvailability', 'conservation')
                        }"
                        class="mb-2">
                    <template slot="title">Conservation Area</template>
                    <template slot="legend">
                        <div class="flex items-center p-1">
                            <div class="w-4 h-4 rounded border border-gray-800 bg-orange-500"></div>
                            <span class="text-xs ml-2  leading-tight">Conservation Area</span>
                        </div>
                    </template>
                </overlay-detail>

                <overlay-detail
                        :keyed="true"
                        ref="overlay-greenbelt"
                        v-if="o === 'greenbelt'"
                        @close="()=> {
                        $store.commit('map/toggleLayerAvailability', 'greenbelt')
                        }"
                        class="mb-2">
                    <template slot="title">Greenbelt</template>
                    <template slot="legend">
                        <div class="flex items-center p-1">
                            <div class="w-4 h-4 rounded border border-gray-800"
                                 :style="{
                    'background-color': '#3bbd4c'
                    }"
                            ></div>
                            <span class="text-xs ml-2  leading-tight">Greenbelt</span>
                        </div>
                    </template>
                </overlay-detail>

                <overlay-detail
                        :keyed="true"
                        ref="overlay-flood-zones"
                        v-if="o === 'floodZones'"
                        @close="()=> {
                        $store.commit('map/toggleLayerAvailability', 'floodZones')
                        }"
                        class="mb-2">
                    <template slot="title">Flood Zones</template>
                    <template slot="legend">
                        <div class="flex items-center p-1">

                            <div class="w-4 h-4 rounded border border-gray-800"
                                 :style="{
                    'background-color': '#52fffc'
                    }"
                            ></div>
                            <span class="text-xs ml-2  leading-tight">Zone 2 (0.1%)</span>
                        </div>
                        <div class="flex items-center p-1">
                            <div class="w-4 h-4 rounded border border-gray-800"
                                 :style="{
                    'background-color': '#a8b2f9'
                    }"
                            ></div>
                            <span class="text-xs ml-2  leading-tight">Zone 3 (0.5%)</span>
                        </div>
                    </template>
                </overlay-detail>

                <overlay-detail
                        :keyed="true"
                        ref="overlay-price-per-sqft"
                        v-if="o === 'pricePerSqft'"
                        @close="()=> {
                        $store.commit('map/toggleLayerAvailability', 'pricePerSqft')
                        }"
                        class="mb-2">
                    <template slot="title">Residential Price (per sqft)</template>
                    <template slot="legend">
                        <div class="flex items-center p-1"
                             :key="k + '-price-per-sqft-legend'"
                             v-for="(item, k) of $store.getters['map/getPricePerSqftLegend']">
                            <div class="w-4 h-4 rounded border border-gray-800"
                                 :style="{
                    'background-color': item.color
                    }"
                            ></div>
                            <span class="text-xs ml-2  leading-tight">{{ item.label }}</span>
                        </div>
                    </template>
                </overlay-detail>

                <overlay-detail
                        :keyed="true"
                        ref="overlay-rental-yield"
                        v-if="o === 'rentalYield'"
                        @close="()=> {
                        $store.commit('map/toggleLayerAvailability', 'rentalYield')
                        }"
                        class="mb-2">
                    <template slot="title">Rental Yield (%)</template>
                    <template slot="legend">
                        <div class="flex items-center p-1"
                             :key="i"
                             v-for="(item, i) of rental_yield">
                            <div class="w-4 h-4 rounded border border-gray-800"
                                 :style="{
                    'background-color': item.color
                    }"
                            ></div>
                            <span class="text-xs ml-2  leading-tight">{{ item.label }}</span>
                        </div>
                    </template>
                </overlay-detail>

                <overlay-detail
                        :keyed="true"
                        ref="overlay-deprivation"
                        v-if="o === 'deprivation'"
                        @close="()=> {
                        $store.commit('map/toggleLayerAvailability', 'deprivation')
                        }"
                        class="mb-2">
                    <template slot="title">Deprivation</template>
                    <template slot="legend">
                        <div class="flex items-center p-1"
                             :key="i"
                             v-for="(color, i) of deprivation_map_colours">
                            <div class="w-4 h-4 rounded border border-gray-800"
                                 :style="{
                    'background-color': color
                    }"
                            ></div>
                            <span v-if="i === 0" class="text-xs ml-2  leading-tight">Least</span>
                            <span v-if="i === deprivation_map_colours.length - 1" class="text-xs ml-2 leading-tight">Most</span>
                        </div>
                    </template>
                </overlay-detail>

                <overlay-detail
                        :keyed="true"
                        ref="overlay-income"
                        v-if="o === 'income'"
                        @close="()=> {
                        $store.commit('map/toggleLayerAvailability', 'income')
                        }"
                        class="mb-2">
                    <template slot="title">Income</template>
                    <template slot="legend">
                        <div class="flex items-center p-1"
                             :key="i"
                             v-for="(color, i) of income_map_colours">
                            <div class="w-4 h-4 rounded border border-gray-800"
                                 :style="{
                    'background-color': color
                    }"
                            ></div>
                            <span v-if="i === 0" class="text-xs ml-2  leading-tight">Lowest</span>
                            <span v-if="i === income_map_colours.length - 1" class="text-xs ml-2 leading-tight">Highest</span>
                        </div>
                    </template>
                </overlay-detail>

                <overlay-detail
                        :keyed="true"
                        ref="overlay-built-up-areas"
                        v-if="o === 'builtUpAreas'"
                        @close="()=> {
                        $store.commit('map/toggleLayerAvailability', 'builtUpAreas')
                        }"
                        class="mb-2">
                    <template slot="title">Built up areas</template>
                    <template slot="legend">
                        <div class="flex items-start p-1">
                            <div class="w-4 h-4 rounded border border-gray-800"
                                 :style="{
                    'background-color': '#bfbfbf'
                    }"
                            ></div>
                            <span class="text-xs ml-2  leading-tight">Built up areas</span>
                        </div>
                    </template>
                </overlay-detail>

                <overlay-detail
                        :keyed="true"
                        ref="overlay-agricultural-land-classification"
                        v-if="o === 'agriculturalLandClassification'"
                        @close="()=> {
                        $store.commit('map/toggleLayerAvailability', 'agriculturalLandClassification')
                        }"
                        class="mb-2">
                    <template slot="title">
                        Agricultural Land Classification (Eng)
                    </template>
                    <template slot="legend">
                        <div class="flex items-center p-1"
                             :key="i"
                             v-for="(item, i) of agricultural_land_classification">
                            <div class="w-4 h-4 rounded border border-gray-800"
                                 :style="{
                    'background-color': item.color
                    }"
                            ></div>
                            <span class="text-xs ml-2  leading-tight">{{ item.label }}</span>
                        </div>
                    </template>
                </overlay-detail>

                <overlay-detail
                        :keyed="true"
                        ref="overlay-national-parks"
                        v-if="o === 'nationalParks'"
                        @close="()=> {
                        $store.commit('map/toggleLayerAvailability', 'nationalParks')
                        }"
                        class="mb-2">
                    <template slot="title">National Parks</template>
                    <template slot="legend">
                        <div class="flex items-start p-1">
                            <div class="w-4 h-4 rounded border border-gray-800"
                                 :style="{
                    'background-color': '#43854d'
                    }"
                            ></div>
                            <span class="text-xs ml-2  leading-tight">National Parks</span>
                        </div>
                    </template>
                </overlay-detail>

                <overlay-detail
                        :keyed="true"
                        v-if="o === 'sitesOfScientificInterest'"
                        @close="()=> {
                        $store.commit('map/toggleLayerAvailability', 'sitesOfScientificInterest')
                        }"
                        class="mb-2">
                    <template slot="title">Sites of Scientific Interest</template>
                    <template slot="legend">
                        <div class="flex items-start p-1">
                            <div class="w-4 h-4 rounded border border-gray-800"
                                 :style="{
                    'background-color': '#5900d6'
                    }"
                            ></div>
                            <span class="text-xs ml-2  leading-tight">Special Protection Area Sites</span>
                        </div>
                    </template>
                </overlay-detail>

                <overlay-detail
                        :keyed="true"
                        ref="overlay-special-protection-order"
                        v-if="o === 'specialProtectionOrder'"
                        @close="()=> {
                        $store.commit('map/toggleLayerAvailability', 'specialProtectionOrder')
                        }"
                        class="mb-2">
                    <template slot="title">Special Protection Order Areas</template>
                    <template slot="legend">
                        <div class="flex items-start p-1">
                            <div class="w-4 h-4 rounded border border-gray-800"
                                 :style="{
                    'background-color': '#bd1800'
                    }"
                            ></div>
                            <span class="text-xs ml-2  leading-tight">Special Protection Area Sites</span>
                        </div>
                    </template>
                </overlay-detail>

                <overlay-detail
                        :keyed="true"
                        ref="known-marketed-sales"
                        v-if="o === 'knownMarketedSales'"
                        @close="()=> {
                        $store.commit('map/toggleLayerAvailability', 'knownMarketedSales')
                        }"
                        class="mb-2">
                    <template slot="title">Known Marketed Sales</template>
                    <template slot="legend">
                        <div class="flex items-start p-1"
                             :key="i"
                             v-for="(x, i) of known_marketed_sale">
                            <div class="w-4 h-4 rounded border border-gray-800"
                                 :style="{
                    'background-color': x.color
                    }"
                            ></div>
                            <span class="text-xs ml-2  leading-tight">{{ x.label }}</span>
                        </div>
                    </template>
                </overlay-detail>


                <overlay-detail
                        :keyed="true"
                        ref="occupiers"
                        v-if="o === 'occupiers'"
                        @close="()=> {
                        $store.commit('map/toggleLayerAvailability', 'occupiers')
                        }"
                        class="mb-2">
                    <template slot="title">Current Occupiers</template>
                    <template slot="legend">
                        <div class="flex items-start p-1"
                             :key="i"
                             v-for="(x, i) of occupiers">
                            <div class="w-4 h-4 rounded border border-gray-800"
                                 :style="{
                    'background-color': x.color
                    }"
                            ></div>
                            <span class="text-xs ml-2  leading-tight">{{ x.label }}</span>
                        </div>
                    </template>
                </overlay-detail>


                <overlay-detail
                        :keyed="true"
                        ref="overlay-ancient-woodland"
                        v-if="o === 'ancientWoodland'"
                        @close="()=> {
                        $store.commit('map/toggleLayerAvailability', 'ancientWoodland')
                        }"
                        class="mb-2">
                    <template slot="title">Ancient Woodland</template>
                    <template slot="legend">
                        <div class="flex items-start p-1">
                            <div class="w-4 h-4 rounded border border-gray-800"
                                 :style="{
                    'background-color': '#43854d'
                    }"
                            ></div>
                            <span class="text-xs ml-2  leading-tight">Ancient Semi-planted woodland</span>
                        </div>
                        <div class="flex items-start p-1">
                            <div class="w-4 h-4 rounded border border-gray-800"
                                 :style="{
                    'background-color': '#8ea232'
                    }"
                            ></div>
                            <span class="text-xs ml-2  leading-tight">Ancient replanted woodland</span>
                        </div>
                    </template>
                </overlay-detail>

                <overlay-detail
                        :keyed="true"
                        ref="overlay-flood-risk-communities"
                        v-if="o === 'floodRiskCommunities'"
                        @close="()=> {
                        $store.commit('map/toggleLayerAvailability', 'floodRiskCommunities')
                        }"
                        class="mb-2">
                    <template slot="title">Flood Risk Communities</template>
                    <template slot="legend">
                        <div class="flex items-center p-1">
                            <div class="w-4 h-4 rounded border border-gray-800"
                                 :style="{
                    'background-color': '#009dd5'
                    }"
                            ></div>
                            <span class="text-xs ml-2  leading-tight">Flood Risk Communities</span>
                        </div>
                    </template>
                </overlay-detail>


                <overlay-detail
                        :keyed="true"
                        ref="overlay-conservation"
                        v-if="o === 'conservation'"
                        @close="()=> {
                        $store.commit('map/toggleLayerAvailability', 'conservation')
                        }"
                        class="mb-2">
                    <template slot="title">Conservation Area</template>
                    <template slot="legend">
                        <div class="flex items-center p-1">
                            <div class="w-4 h-4 rounded border border-gray-800 bg-orange-500"></div>
                            <span class="text-xs ml-2  leading-tight">Conservation Area</span>
                        </div>
                    </template>
                </overlay-detail>


            </div>
        </div>
</template>

<script>
    import {getSalesPriceColorByIndex} from "../../utils/ownership";

    import OverlayDetail from "./OverlayDetail";
    export default {
        name: "OverlayDetails",
        components: {OverlayDetail},
        methods: {
            getSalesPriceColorByIndex(index){
                return getSalesPriceColorByIndex(index);
            }
        },
        data(){
            return {
                rental_yield: [
                    {color: "#7d3e1c", label: "7.0% +"},
                    {color: "#B3611D", label: "6.5 - 7.0%"},
                    {color: "#BC7539", label: "6.0 - 6.5%"},
                    {color: "#C68856", label: "5.5 - 6.0%"},
                    {color: "#CF9C72", label: "5.0 - 5.5%"},
                    {color: "#D9B08E", label: "4.5 - 5.0%"},
                    {color: "#E2C4AA", label: "4.0 - 4.5%"},
                    {color: "#ECD7C7", label: "3.5 - 4.0%"},
                    {color: "#F5EBE3", label: "0.0 - 3.5%"},
                    {color: "#ECECEC", label: "No Data"},
                ],
                occupiers: [
                    {color: "#118ab2", label: "Owner Occupied"},
                    {color: "#e76f51", label: "Rented (social)"},
                    {color: "#ffd166", label: "Rented (private)"},
                ],
                known_marketed_sale: [
                    {color: "#2a9d8f", label: "< 6 months"},
                    {color: "#f4a261", label: "< 1 year"},
                    {color: "#e9c46a", label: "< 2 years"},
                    {color: "#e76f51", label: "< 3 years"},
                    {color: "#c90500", label: "> 3 years"}
                ],
                agricultural_land_classification: [
                    {color: "#059669", label: "Grade 1"},

                    {color: "#3B82F6", label: "Grade 2"},
                    {color: "#6366F1", label: "Grade 3"},
                    {color: "#8B5CF6", label: "Grade 4"},

                    {color: "#EC4899", label: "Grade 5"},
                    {color: "#DC2626", label: "Non Agricultural"},

                    {color: "#A8A29E", label: "Urban"},
                    {color: "#1F2937", label: "Excluded"},
                ],
                income_map_colours: [
                    '#F1E6F3ff',
                    '#CBB9CFff',
                    '#A58DAAff',
                    '#806086ff',
                    '#5A3461ff',
                    '#34073Dff'
                ],
                deprivation_map_colours: [
                    '#234A26ff',
                    '#638434',
                    '#bcaf52',
                    '#8B422Fff',
                    '#A04031ff',
                    '#CA3D34ff'
                ]
            }
        }
    }
</script>

<style scoped>
.el-block--overlay-legend {
    z-index: 3000 !important;
    width: 12rem;
    max-height: 20rem;
}
</style>