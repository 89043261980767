<template>
    <div id="land-monitor-report">

        <pdf-page>
            <div v-if="overview.title_number" class="text-center w-full absolute flex items-center justify-center h-full">
                <div>

                        <div class="flex items-center justify-center mb-12">
                            <svg viewBox="0 0 100 133"
                                 version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="w-8">
                                <g stroke="none" stroke-width="1" fill-rule="evenodd" class="fill-current text-green-600"><g><g><path d="M99.469,49.958 C99.469,66.251 84.687,89.43 71.25,107.167 L63.289,97.727 C76.121,80.427 87.375,61.413 87.375,49.958 C87.375,29.198 70.512,12.301 49.718,12.301 C28.957,12.301 12.094,29.198 12.094,49.958 C12.094,65.411 32.552,94.57 49.718,114.792 L57.578,124.131 C52.908,129.641 49.751,132.999 49.751,132.999 C49.751,132.999 0,79.823 0,49.958 C0,22.479 22.272,0.207 49.751,0.207 C77.196,0.207 99.469,22.479 99.469,49.958" id="Fill-13"></path><path d="M60.568,101.288 L68.529,110.728 C65.674,114.423 62.919,117.782 60.467,120.739 L52.573,111.4 L49.718,108.007 C32.652,87.246 16.46,62.22 16.46,49.958 C16.46,31.583 31.376,16.668 49.718,16.668 C68.093,16.668 83.008,31.583 83.008,49.958 C83.008,59.633 72.964,77.168 60.366,94.301 L52.338,84.76 C65.069,67.091 70.914,56.214 70.914,49.958 C70.914,38.268 61.408,28.761 49.718,28.761 C38.027,28.761 28.554,38.268 28.554,49.958 C28.554,56.214 35.205,68.804 49.718,88.388 L57.679,97.862 L60.568,101.288" id="Fill-15"></path></g></g></g></svg>
                            <h1 class="ml-3 text-gray-900 font-bold text-3xl">Land Monitor</h1>
                        </div>

                    <div class="mx-auto mb-12" style="width:400px">
                        <ownership-overview-map
                                :coords="overview.center"
                                :polygons="overview.polygons"
                                :bbox="overview.bbox"
                        />
                    </div>

                    <h3 class="text-bold text-3xl mb-3 text-gray-800">Land Monitor report regarding {{ overview.title_number }}</h3>
                    <h4 class="text-bold text-lg mb-3 text-gray-800">{{ overview.address }}</h4>
                </div>
            </div>
         </pdf-page>

        <pdf-page>
            <div class="block w-8 h-8"></div>
        </pdf-page>

        <pdf-page v-if="overview.polygons">

            <div class="py-6">
                <h3 class="text-2xl font-bold">Overview & Ownership</h3>

                <div class="mt-12">

                    <report-widget class="mb-12" title="Property Overview">
                        <div class="p-2">
                            <div class="columns">
                                <div class="column w-1/2">
                                    <div class="columns">
                                        <div class="column mr-auto text-xs text-gray-500 font-medium flex items-center">
                                            Title Number
                                        </div>
                                        <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                            {{ overview.title_number ? overview.title_number : 'Unknown' }}
                                        </div>
                                    </div>
                                    <div class="columns flex-no-wrap">
                                        <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                                            Address
                                        </div>
                                        <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                            {{ overview.address ? overview.address : 'Unknown' }}
                                        </div>
                                    </div>
                                    <div class="columns">
                                        <div class="column mr-auto text-xs text-gray-500 font-medium flex items-center">
                                            Tenure
                                        </div>
                                        <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                            {{ overview.tenure ? overview.tenure : 'Unknown' }}
                                        </div>
                                    </div>

                                    <div class="columns">
                                        <div class="column mr-auto text-xs text-gray-500 font-medium flex items-center">
                                            Ownership
                                        </div>
                                        <div class="column w-auto text-xs text-gray-900 flex items-center">
                                            {{ isPrivateOwnership ? 'Privately Owned' : 'Company Owned' }}
                                        </div>
                                    </div>
                                    <div class="columns flex-no-wrap" v-if="value.area_acres">
                                        <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                                            Area
                                        </div>
                                        <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                            {{ overview.area_acres }} acres
                                        </div>
                                    </div>

                                    <div class="columns flex-no-wrap">
                                        <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                                            What 3 Words
                                        </div>
                                        <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                            {{ overview.w3w ? overview.w3w : '' }}
                                        </div>
                                    </div>
                                    <div class="columns flex-no-wrap">
                                        <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                                            Latitude
                                        </div>
                                        <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                            {{  overview.center ? overview.center.lat.toFixed(6) : 0 }}
                                        </div>
                                    </div>
                                    <div class="columns flex-no-wrap">
                                        <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                                            Longitude
                                        </div>
                                        <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                            {{ overview.center ? overview.center.lon.toFixed(6) : 0 }}
                                        </div>
                                    </div>

                                </div>
                                <div class="column w-1/2">

                                    <div class="mx-auto" style="width:400px">
                                        <ownership-overview-map
                                                :coords="overview.center"
                                                :polygons="overview.polygons"
                                                :bbox="overview.bbox"
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </report-widget>


                    <report-widget
                            v-if="isPrivateOwnership"
                            title="Owner Detail">
                        <div class="el-block--private-ownership p-1">
                            <p class="text-xs leasing-leading-loose mb-1">This property is privately owned.</p>
                            <p class="text-xs leasing-leading-loose mb-1">This information is currently held by HM Land Registry.</p>
                            <p class="text-xs leasing-leading-loose mb-1">In order to find the owner you will need to purchase the title number</p>
                        </div>
                    </report-widget>

                    <report-widget
                            v-if="!isPrivateOwnership"
                            title="Purchase Information">

                        <div class="p-2">
                            <div class="columns">
                                <div class="column mr-auto text-xs text-gray-500 font-medium flex items-center">
                                    Tenure
                                </div>
                                <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                    {{ overview.company_ownership.tenure  }}
                                </div>
                            </div>



                            <div class="columns" v-if="overview.company_ownership.property_address">
                                <div class="column mr-auto text-xs text-gray-500 font-medium flex items-center">
                                    Property Address
                                </div>
                                <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                    {{ overview.company_ownership.property_address  }}
                                </div>
                            </div>

                            <div class="columns" v-if="overview.company_ownership.price_paid">
                                <div class="column mr-auto text-xs text-gray-500 font-medium flex items-center">
                                   Price Paid
                                </div>
                                <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                    £{{ overview.company_ownership.price_paid.toLocaleString() }}
                                </div>
                            </div>

                            <div class="columns" v-if="overview.company_ownership.date_proprietor_added">
                                <div class="column mr-auto text-xs text-gray-500 font-medium flex items-center">
                                    Date Purchased
                                </div>
                                <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                    <div class="break-normal h-full text-xs leading-tight">
                                        {{ overview.company_ownership.date_proprietor_added | moment("MMM Do YYYY") }}
                                        <br /><small>{{ overview.company_ownership.date_proprietor_added | moment("from", "now") }}</small>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </report-widget>

                    <table
                            v-if="!isPrivateOwnership"
                            class="w-full mt-12">
                        <tr>
                            <td class="w-full"
                                :key="i"
                                v-for="(item, i) of overview.company_ownership.proprietors"
                            >
                                <div class="mb-6">
                                    <report-widget
                                            :padding-class="'p-4'"
                                            :title="'Proprietor (' + ( i + 1) + ')'">


                                        <h3 class="uppercase block text-sm font-semibold text-blue-900 mb-2">
                                            Company Overview
                                        </h3>


                                        <corporate-ownership-details
                                                class="py-2"
                                                :value="item"
                                        />

                                        <h3
                                                v-if="item.cash_in_bank || item.turnover || item.net_assets"
                                                class="uppercase block text-sm font-semibold text-blue-900 mb-2">
                                            Financials
                                        </h3>

                                        <corporate-ownership-financials
                                                class="py-2"
                                                :value="item"
                                        />

                                        <h3
                                                v-if="item.directors && item.directors.length > 0"
                                                class="uppercase block text-sm font-semibold text-blue-900 mb-2">
                                            Directors
                                        </h3>

                                        <corporate-ownership-directors
                                                :value="item"
                                        />

                                    </report-widget>
                                </div>
                            </td>
                        </tr>
                    </table>


                </div>

            </div>



        </pdf-page>

        <pdf-page>

            <div class="py-6">

                <h3 class="text-2xl font-bold">Local Area & Demographics</h3>
                <div class="columns mt-12">
                    <div class="column w-1/2">

                        <div class="columns w-full">
                            <div class="column w-full">
                                <report-widget title="Administrative Areas">
                                    <div class="el-block--detail mt-3"
                                         :key="k"
                                         v-for="(i, k) of administrativeDetails">
                                        <div class="flex flex-no-wrap mb-2">
                                            <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                                {{ i.label }}
                                            </div>
                                            <div class="w-32 text-right text-xs text-gray-900">
                                                {{ i.value }}
                                            </div>
                                        </div>
                                    </div>
                                </report-widget>
                            </div>
                        </div>
                    </div>
                    <div class="column w-1/2">

                        <div class="columns w-full">
                            <div class="column w-full">
                                <report-widget title="Demographics">
                                    <div class="el-block--detail mt-3"
                                         :key="k"
                                         v-for="(i, k) of demographicsTable">
                                        <div class="flex flex-no-wrap mb-2">
                                            <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                                {{ i.label }}
                                            </div>
                                            <div class="w-32 text-right text-xs text-gray-900">
                                                {{ i.value }}
                                            </div>
                                        </div>
                                    </div>
                                </report-widget>
                            </div>
                            <div class="column w-full">
                                <report-widget title="Deprivation & Earnings">
                                    <div class="el-block--detail mt-3" v-if="deprivation.avg_income">
                                        <div class="flex flex-no-wrap mb-2">
                                            <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                                Average Income
                                            </div>
                                            <div class="w-32 text-right text-xs text-gray-900">
                                                £{{ deprivation.avg_income.toLocaleString() }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="el-block--detail mt-3" v-if="deprivation.index_of_multiple_deprivation">
                                        <div class="flex flex-no-wrap mb-2">
                                            <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                                Index of multiple deprivation
                                            </div>
                                            <div class="w-32 text-right text-xs text-gray-900">
                                                {{ deprivation.index_of_multiple_deprivation.toLocaleString() }}
                                            </div>
                                        </div>
                                    </div>
                                </report-widget>
                            </div>
                        </div>
                    </div>
                    <div class="column w-full" v-if="population.length > 0">

                        <report-widget
                                padding-class="''"
                                title="Population">
                            <table class="el-report-table">
                                <thead>
                                <tr>
                                    <th>Distance</th>
                                    <th width="100px" class="text-right">Population</th>
                                    <th width="100px" class="text-right">Households</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                        :key="k"
                                        v-for="(i, k) of population">
                                    <td class="font-bold">
                                        {{ i.label }}
                                    </td>
                                    <td class="text-right">
                                        {{ i.population.toLocaleString() }}
                                    </td>
                                    <td class="text-right">
                                        {{ i.households.toLocaleString() }}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </report-widget>


                    </div>
                </div>


            </div>

        </pdf-page>


        <pdf-page v-if="topography.length > 0">
            <div class="py-6">
                <h3 class="text-2xl font-bold">Site Areas & Buildings</h3>

                <table class="w-full mt-12">
                    <tbody>
                    <tr v-if="site_buildings && site_buildings.sites && site_buildings.sites.length > 0">
                        <td class="w-full">
                            <div class="mb-6">
                                <report-widget
                                        :padding-class="''"
                                        title="Site Areas">

                                    <table class="el-report-table">
                                        <thead>
                                        <tr>
                                            <th>Site</th>
                                            <th class="text-right">Size (acres)</th>
                                            <th  class="text-right">Size (hectares)</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                               :key="i"
                                                v-for="(item, i) of site_buildings.sites">
                                            <td class="font-bold">{{ item.site }}</td>
                                            <td width="120" class="text-right">{{ item.area_acres }}</td>
                                            <td width="150" class="text-right">{{ item.area_hectares }}</td>
                                        </tr>
                                        </tbody>
                                    </table>


                                </report-widget>
                            </div>
                        </td>
                    </tr>


                    <tr v-if="site_buildings && site_buildings.building_footprints && site_buildings.building_footprints.length > 0">
                        <td class="w-full">
                            <div class="mb-6">
                                <report-widget
                                        :padding-class="''"
                                        title="Building Footprints">

                                    <table class="el-report-table">
                                        <thead>
                                        <tr>
                                            <th>Building</th>
                                            <th>Location</th>
                                            <th class="text-right">Area (m²)</th>
                                            <th  class="text-right">Area (ft²)</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                                :key="i"
                                                v-for="(item, i) of site_buildings.building_footprints">
                                            <td class="font-bold">{{ item.building }}</td>
                                            <td width="120">{{ item.site }}</td>
                                            <td width="150" class="text-right">
                                                {{ item.area_sqm.toLocaleString() }} <small>m²</small>
                                            </td>
                                            <td width="150" class="text-right">
                                                {{ item.area_sqft.toLocaleString() }} <small>ft²</small>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>


                                </report-widget>
                            </div>
                        </td>
                    </tr>

                    <tr v-if="site_buildings && site_buildings.building_heights && site_buildings.building_heights.length > 0">
                        <td class="w-full">
                            <div class="mb-6">
                                <report-widget
                                        :padding-class="''"
                                        title="Building Heights">

                                    <table class="el-report-table">
                                        <thead>
                                        <tr>
                                            <th>Building</th>
                                            <th>Location</th>
                                            <th class="text-right">to Eaves</th>
                                            <th class="text-right">to Chimney</th>
                                            <th class="text-right">Above Sea Level</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                                :key="i"
                                                v-for="(item, i) of site_buildings.building_heights">
                                            <td class="font-bold">{{ item.building }}</td>
                                            <td width="120">{{ item.site }}</td>
                                            <td width="150" class="text-right">
                                                {{ item.eaves.toLocaleString() }} <small>m</small>
                                            </td>
                                            <td width="150" class="text-right">
                                                {{ item.chimney.toLocaleString() }} <small>m</small>
                                            </td>
                                            <td width="150" class="text-right">
                                                {{ item.sea_level.toLocaleString() }} <small>m</small>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>


                                </report-widget>
                            </div>
                        </td>
                    </tr>

                    <tr v-if="site_buildings && site_buildings.building_locations && site_buildings.building_locations.length > 0">
                        <td class="w-full">
                            <div class="mb-6">
                                <report-widget
                                        :padding-class="''"
                                        title="Building Physical Locations">

                                    <table class="el-report-table">
                                        <thead>
                                        <tr>
                                            <th>Building</th>
                                            <th>Location</th>
                                            <th>Physical Level</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                                :key="i"
                                                v-for="(item, i) of site_buildings.building_heights">
                                            <td class="font-bold">{{ item.building }}</td>
                                            <td width="120">{{ item.site }}</td>
                                            <td width="120">{{ item.physical_level_string }}</td>

                                        </tr>
                                        </tbody>
                                    </table>


                                </report-widget>
                            </div>
                        </td>
                    </tr>

                    <tr v-if="site_buildings && site_buildings.building_changes && site_buildings.building_changes.length > 0">
                        <td class="w-full">
                            <div class="mb-6">
                                <report-widget
                                        :padding-class="''"
                                        title="Building Changes">

                                    <table class="el-report-table">
                                        <thead>
                                        <tr>
                                            <th>Building</th>
                                            <th>Location</th>
                                            <th class="text-right">Change Count</th>
                                            <th>Last Changed</th>
                                            <th>Location</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                                :key="i"
                                                v-for="(item, i) of site_buildings.building_changes">
                                            <td class="font-bold">{{ item.building }}</td>
                                            <td width="120">{{ item.site }}</td>
                                            <td class="text-right" width="80">{{ item.change_count }}</td>
                                            <td width="120">
                                                {{ item.last_changed | moment("MMM Do YYYY") }}
                                                <br/><small>{{ item.last_changed | moment("from", "now") }}</small>
                                            </td>
                                            <td width="120">{{ item.reason_for_change }}</td>

                                        </tr>
                                        </tbody>
                                    </table>


                                </report-widget>
                            </div>
                        </td>
                    </tr>


                    </tbody>
                </table>


            </div>
        </pdf-page>

        <pdf-page v-if="topography.length > 0">
            <div class="py-6">
                <h3 class="text-2xl font-bold">Topography Analysis</h3>

                <table class="w-full mt-12"
                       :key="i"
                       v-for="(item, i) of topography"
                >
                    <tbody>
                    <tr>
                        <td class="w-full">
                            <div class="mb-1">
                                <report-widget
                                        :padding-class="''"
                                        :title="'Site ' + (i + 1)">

                                    <div style="width: 1000px">
                                        <ownership-topography-map
                                                v-if="item.polygons && item.polygons.features"
                                                v-model="topography[i]"
                                                :show-polygons="true"
                                        />
                                    </div>


                                    <div class="p-3">
                                        <div class="el-block--detail mt-3">
                                            <div class="flex flex-no-wrap mb-2">
                                                <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                                    Total Area (m²)
                                                </div>
                                                <div class="w-32 text-right text-xs text-gray-900">
                                                    {{ parseFloat(item.breakdown.area.toFixed(2)).toLocaleString() }} m²
                                                </div>
                                            </div>
                                        </div>
                                        <div class="el-block--detail mt-3">
                                            <div class="flex flex-no-wrap mb-2">
                                                <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                                    Total Area (ft²)
                                                </div>
                                                <div class="w-32 text-right text-xs text-gray-900">
                                                    {{ parseFloat(item.breakdown.area_sqft.toFixed(2)).toLocaleString() }} ft²
                                                </div>
                                            </div>
                                        </div>

                                        <div class="el-block--detail mt-3">
                                            <div class="flex flex-no-wrap mb-2">
                                                <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                                    Total Area (acres)
                                                </div>
                                                <div class="w-32 text-right text-xs text-gray-900">
                                                    {{ parseFloat(item.breakdown.area_acres.toFixed(2)).toLocaleString() }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <table
                                            :key="cIndex"
                                          v-for="(b, cIndex) of item.breakdown.categories"
                                            class="el-report-table">
                                        <thead>
                                        <tr>
                                            <th class="bg-gray-500 text-gray-800" colspan="12">{{  b.label + ' (' + (b.items.length) + ')' }}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colspan="12" class="w-full">
                                                    <div class="el-block--detail mt-3">
                                                        <div class="flex flex-no-wrap mb-2">
                                                            <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                                                Total Area (m²)
                                                            </div>
                                                            <div class="w-32 text-right text-xs text-gray-900">
                                                                {{ parseFloat(b.total_area.toFixed(2)).toLocaleString() }} m²
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="el-block--detail mt-3">
                                                        <div class="flex flex-no-wrap mb-2">
                                                            <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                                                Total Area (ft²)
                                                            </div>
                                                            <div class="w-32 text-right text-xs text-gray-900">
                                                                {{ parseFloat(b.total_area_sqft.toFixed(2)).toLocaleString() }} ft²
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="el-block--detail mt-3">
                                                        <div class="flex flex-no-wrap mb-2">
                                                            <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                                                Total Area (acres)
                                                            </div>
                                                            <div class="w-32 text-right text-xs text-gray-900">
                                                                {{ parseFloat(b.total_area_acres.toFixed(2)).toLocaleString() }}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="el-block--detail mt-3">
                                                        <div class="flex flex-no-wrap mb-2">
                                                            <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                                                Total Area (%)
                                                            </div>
                                                            <div class="w-32 text-right text-xs text-gray-900">
                                                                {{ parseFloat(b.percentage.toFixed(2)).toLocaleString() }}%
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <thead>
                                        <tr>
                                            <th>Description</th>
                                            <th class="text-right" width="40px">m²</th>
                                            <th class="text-right" width="40px">acres</th>
                                            <th class="text-left" width="40px">Make</th>
                                            <th class="text-left" width="40px">Location</th>
                                            <th class="text-right" width="40px">Change Count</th>
                                            <th class="text-left" width="30px">Last Changed</th>
                                            <th class="text-left" width="80px">Reason for Change</th>

                                            <th class="text-right" width="120px">
                                                Height from Ground Level (AbsHMin)
                                            </th>
                                            <th class="text-right" width="120px">
                                                Height to Chimney (AbsHMax)
                                            </th>
                                            <th class="text-right" width="120px">
                                                Height to Eaves (AbsH2)
                                            </th>

                                        </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                    :key="j"
                                                    v-for="(item, j) of b.items">
                                                <td class="font-bold">{{ j+1 }}. {{ item.properties.descriptive_group }}</td>
                                                <td class="text-right">
                                                    {{ parseFloat(item.properties.area.toFixed(2)).toLocaleString() }}
                                                </td>
                                                <td class="text-right">
                                                    {{ parseFloat(item.properties.area_acres.toFixed(2)).toLocaleString() }}
                                                </td>
                                                <td>
                                                    {{ item.properties.make }}
                                                </td>
                                                <td>
                                                    {{ item.properties.physical_level_string }}
                                                </td>
                                                <td class="text-right">
                                                    {{ item.properties.change_count }}
                                                </td>
                                                <td>
                                                    {{ item.properties.last_changed | moment("MMM Do YYYY") }}
                                                    <br/><small>{{ item.properties.last_changed | moment("from", "now") }}</small>
                                                </td>
                                                <td>
                                                    {{ item.properties.reason_for_change }}
                                                </td>

                                                <td  class="text-right">
                                                    {{ item.properties.abs_h_min ? item.properties.abs_h_min + 'm' : 'N/A' }}
                                                </td>
                                                <td  class="text-right">
                                                    {{ item.properties.abs_h_max ? item.properties.abs_h_max + 'm' : 'N/A' }}
                                                </td>
                                                <td class="text-right">
                                                    {{ item.properties.abs_h2 ? item.properties.abs_h2 + 'm' : 'N/A' }}
                                                </td>


                                            </tr>
                                        </tbody>

                                    </table>



                                </report-widget>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>



            </div>
        </pdf-page>

        <pdf-page v-if="environment && environment.sites && environment.sites.length > 0">
            <div class="py-6">
                <h3 class="text-2xl font-bold">Environment</h3>
            </div>

            <table class="w-full mt-12">
                <tbody>
                <tr>
                    <td class="w-full">
                        <report-widget
                                padding-class=""
                                title="Environmental Breakdown">

                            <div class="p-3">
                                <div class="el-block--detail mt-3">
                                    <div class="flex flex-no-wrap mb-2">
                                        <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                            Greenbelt Coverage
                                        </div>
                                        <div class="w-32 text-right text-xs text-gray-900">
                                            {{ environment.greenbelt_coverage_percentage }}%
                                        </div>
                                    </div>
                                </div>
                                <div class="el-block--detail mt-3">
                                    <div class="flex flex-no-wrap mb-2">
                                        <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                            Area of Outstanding Natural Beauty
                                        </div>
                                        <div class="w-32 text-right text-xs text-gray-900">
                                            {{ environment.aonb_coverage_percentage }}%
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <table class="el-report-table mt-6">
                                <thead>
                                <tr>
                                    <th>Site</th>
                                    <th class="text-right" width="100">Greenbelt</th>
                                    <th class="text-right" width="300">Area of Outstanding Natural Beauty</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr
                                            :key="i"
                                            v-for="(s, i) of environment.sites">
                                        <td>
                                            <strong>Site {{ i + 1 }}</strong>
                                        </td>
                                        <td class="text-right">
                                            {{ s.greenbelt_coverage_percentage }}%
                                        </td>
                                        <td class="text-right">
                                            {{ s.aonb_coverage_percentage }}%
                                        </td>
                                    </tr>
                                </tbody>
                            </table>


                        </report-widget>

                    </td>
                </tr>
                </tbody>
            </table>


        </pdf-page>

        <pdf-page  v-if="getTrafficAnalysisPoints.length > 0">

            <div class="py-6">
                <h3 class="text-2xl font-bold">Traffic Analysis</h3>

                <table class="w-full mt-12"
                       :key="item.id"
                       v-for="(item) of getTrafficAnalysisPoints"
                >
                    <tbody>
                    <tr>
                        <td class="w-full">
                            <div class="mb-1">
                                <report-widget
                                        :padding-class="''"
                                        :title="'Traffic Flow - ' + item.data[0].road_name">

                                    <div class="p-3">
                                        <div class="columns w-full">
                                            <div class="column w-1/2">
                                                <div class="el-block--detail mt-3">
                                                    <div class="flex flex-no-wrap mb-2">
                                                        <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                                            Road
                                                        </div>
                                                        <div class="w-32 text-right text-xs text-gray-900">
                                                            {{ item.data[0].road_name }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="el-block--detail mt-3">
                                                    <div class="flex flex-no-wrap mb-2">
                                                        <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                                            Road Type
                                                        </div>
                                                        <div class="w-32 text-right text-xs text-gray-900">
                                                            {{ item.data[0].road_type }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="el-block--detail mt-3">
                                                    <div class="flex flex-no-wrap mb-2">
                                                        <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                                            Start Junction
                                                        </div>
                                                        <div class="w-32 text-right text-xs text-gray-900">
                                                            {{ item.data[0].start_junction_road_name }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="el-block--detail mt-3">
                                                    <div class="flex flex-no-wrap mb-2">
                                                        <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                                            End Junction
                                                        </div>
                                                        <div class="w-32 text-right text-xs text-gray-900">
                                                            {{ item.data[0].end_junction_road_name }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="el-block--detail mt-3">
                                                    <div class="flex flex-no-wrap mb-2">
                                                        <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                                            Link Length
                                                        </div>
                                                        <div class="w-32 text-right text-xs text-gray-900">
                                                   <span v-if="item.data[0].link_length_miles">
                                                   {{ item.data[0].link_length_miles }} miles
                                                   </span>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="column w-1/2">
                                                <div class="mb-3">
                                                    <img
                                                            class="w-full"
                                                            :src="buildTrafficAnalysisMap(item.data[0])"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <table class="el-report-table -mt-3">
                                        <thead>


                                        <tr>
                                            <th>Year</th>
                                            <th class="text-right" width="100px">Pedal Cycles</th>
                                            <th class="text-right" width="100px">Motorcycles</th>
                                            <th class="text-right" width="100px">Cars/Taxis</th>
                                            <th class="text-right" width="100px">Buses/Coaches</th>
                                            <th class="text-right" width="100px">Light Goods Vehicles</th>
                                            <th class="text-right" width="100px">V2 Axle Rigid HGV</th>
                                            <th class="text-right" width="100px">V3 Axle Rigid HGV</th>
                                            <th class="text-right" width="100px">V4 or 5 Axle Rigid HGV</th>
                                            <th class="text-right" width="100px">V3 or 4 Axle Artic HGV</th>
                                            <th class="text-right" width="100px">V5 Axle Artic HGV</th>
                                            <th class="text-right" width="100px">V6 or More Axle Artic</th>
                                            <th class="text-right" width="100px">All HGVs</th>
                                            <th class="text-right" width="100px">All Motor Vehicles</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                                :key="k"
                                                v-for="(i, k) of item.data">

                                            <td class="font-bold">
                                                {{ i.year }}
                                            </td>
                                            <td class="text-right">
                                                {{ i.pedal_cycles.toLocaleString() }}
                                            </td>
                                            <td class="text-right">
                                                {{ i.two_wheeled_motor_vehicles.toLocaleString() }}
                                            </td>
                                            <td class="text-right">
                                                {{ i.cars_and_taxis.toLocaleString() }}
                                            </td>
                                            <td class="text-right">
                                                {{ i.buses_and_coaches.toLocaleString() }}
                                            </td>
                                            <td class="text-right">
                                                {{ i.lgvs.toLocaleString() }}
                                            </td>
                                            <td class="text-right">
                                                {{ i.hgvs_2_rigid_axle.toLocaleString() }}
                                            </td>
                                            <td class="text-right">
                                                {{ i.hgvs_3_rigid_axle.toLocaleString() }}
                                            </td>
                                            <td class="text-right">
                                                {{ i.hgvs_4_or_more_rigid_axle.toLocaleString() }}
                                            </td>
                                            <td class="text-right">
                                                {{ i.hgvs_3_or_4_articulated_axle.toLocaleString() }}
                                            </td>
                                            <td class="text-right">
                                                {{ i.hgvs_3_or_4_articulated_axle.toLocaleString() }}
                                            </td>
                                            <td class="text-right">
                                                {{ i.hgvs_6_articulated_axle.toLocaleString() }}
                                            </td>
                                            <td class="text-right">
                                                {{ i.all_hgvs.toLocaleString() }}
                                            </td>
                                            <td class="text-right">
                                                {{ i.all_motor_vehicles.toLocaleString() }}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>

                                </report-widget>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>

            </div>

        </pdf-page>

        <!--
        <pdf-page v-if="getBroadbandList.length > 0 || getMobileCoverage.length > 0">

            <div class="py-6">
                <h3 class="text-2xl font-bold">Broadband/Mobile Coverage</h3>


                <table class="w-full mt-12">
                    <tr v-if="getBroadbandList.length > 0">
                        <td class="w-full">
                            <div class="mb-6">

                                <report-widget
                                        padding-class="''"
                                        title="Broadband Coverage">
                                    <table class="el-report-table">
                                        <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th width="140px" class="text-right">Download Speed</th>
                                            <th width="140px" class="text-right">Upload Speed</th>
                                            <th width="100px" class="text-right">Coverage</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                                :key="k"
                                                v-for="(i, k) of getBroadbandList">

                                            <td class="font-bold">
                                                {{ i.type }}
                                            </td>
                                            <td class="text-right">
                                                <span v-if="i.download_speed > -1">
                                      {{ i.download_speed }}   <small>mbps</small>
                                    </span>
                                                <span v-else>
                                        <i class="el-icon-close text-red-600"></i>
                                    </span>
                                            </td>
                                            <td class="text-right">
                                         <span v-if="i.upload_speed > -1">
                                      {{ i.upload_speed }}   <small>mbps</small>
                                    </span>
                                                <span v-else>
                                        <i class="el-icon-close text-red-600"></i>
                                    </span>
                                            </td>
                                            <td class="text-right">
                                         <span v-if="i.availability > -1">
                                      {{ i.availability }}%
                                    </span>
                                                <span v-else>
                                        <i class="el-icon-close text-red-600"></i>
                                    </span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </report-widget>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="getMobileCoverage.length > 0">
                        <td class="w-full">
                            <div class="mb-6">
                                <report-widget
                                        padding-class="''"
                                        title="Mobile Coverage">
                                    <table class="el-report-table">
                                        <thead>
                                        <tr>
                                            <th colspan="3" class="bg-gray-500 text-gray-800">Indoor - 4G</th>
                                        </tr>
                                        <tr>
                                            <th>Provider</th>
                                            <th width="80px" class="text-center">Voice</th>
                                            <th width="80px" class="text-center">Data</th>
                                        </tr>
                                        </thead>
                                        <tbody
                                                :key="i"
                                                v-for="(m, i) of getMobileCoverage">
                                        <tr>
                                            <td class="font-bold">{{ m.provider }}</td>
                                            <td class="text-center">
                                                <mobile-coverage-checkbox-component
                                                        v-model="m.voice_indoor"
                                                />
                                            </td>
                                            <td class="text-center">
                                                <mobile-coverage-checkbox-component
                                                        v-model="m.data_indoor"
                                                />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table class="el-report-table">
                                        <thead>
                                        <tr>
                                            <th colspan="3" class="bg-gray-500 text-gray-800">Indoor - No 4G</th>
                                        </tr>
                                        <tr>
                                            <th>Provider</th>
                                            <th width="80px" class="text-center">Voice</th>
                                            <th width="80px" class="text-center">Data</th>
                                        </tr>
                                        </thead>
                                        <tbody
                                                :key="i"
                                                v-for="(m, i) of getMobileCoverage">
                                        <tr>
                                            <td class="font-bold">{{ m.provider }}</td>
                                            <td class="text-center">
                                                <mobile-coverage-checkbox-component
                                                        v-model="m.voice_indoor_no4g"
                                                />
                                            </td>
                                            <td class="text-center">
                                                <mobile-coverage-checkbox-component
                                                        v-model="m.data_indoor_no4g"
                                                />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table class="el-report-table">
                                        <thead>
                                        <tr>
                                            <th colspan="4" class="bg-gray-500 text-gray-800">Outdoor - 4G</th>
                                        </tr>
                                        <tr>
                                            <th>Provider</th>
                                            <th width="80px" class="text-center">Voice</th>
                                            <th width="80px" class="text-center">Data</th>
                                            <th width="130px" class="text-center">Enhanced Data</th>
                                        </tr>
                                        </thead>
                                        <tbody
                                                :key="i"
                                                v-for="(m, i) of getMobileCoverage">
                                        <tr>
                                            <td class="font-bold">{{ m.provider }}</td>
                                            <td class="text-center">
                                                <mobile-coverage-checkbox-component
                                                        v-model="m.voice_outdoor"
                                                />
                                            </td>
                                            <td class="text-center">
                                                <mobile-coverage-checkbox-component
                                                        v-model="m.data_outdoor"
                                                />
                                            </td>
                                            <td class="text-center">
                                                <mobile-coverage-checkbox-component
                                                        v-model="m.enhanced_data_outdoor"
                                                />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table class="el-report-table">
                                        <thead>
                                        <tr>
                                            <th colspan="3" class="bg-gray-500 text-gray-800">Outdoor - No 4G</th>
                                        </tr>
                                        <tr>
                                            <th>Provider</th>
                                            <th width="80px" class="text-center">Voice</th>
                                            <th width="80px" class="text-center">Data</th>
                                        </tr>
                                        </thead>
                                        <tbody
                                                :key="i"
                                                v-for="(m, i) of getMobileCoverage">
                                        <tr>
                                            <td class="font-bold">{{ m.provider }}</td>
                                            <td class="text-center">
                                                <mobile-coverage-checkbox-component
                                                        v-model="m.voice_outdoor_no4g"
                                                />
                                            </td>
                                            <td class="text-center">
                                                <mobile-coverage-checkbox-component
                                                        v-model="m.data_outdoor_no4g"
                                                />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </report-widget>
                            </div>
                        </td>
                    </tr>
                </table>


            </div>

        </pdf-page>
-->
        <pdf-page v-if="schools.length > 0">

            <div class="py-6">
                <h3 class="text-2xl font-bold">Local Schools</h3>


                <div class="mt-12">
                    <report-widget
                            padding-class="''"
                            title="Local Schools">
                        <table class="el-report-table">
                            <thead>
                            <tr>
                                <th>School</th>
                                <th>Address</th>
                                <th width="130px">Education Phases</th>
                                <th width="130px">Type</th>
                                <th width="130px">Ofstead Rating</th>
                                <th width="100px" class="text-right">Distance</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                    :key="k"
                                    v-for="(i, k) of schools">
                                <td class="font-bold">{{ i.name }}</td>
                                <td>{{ i.address }}</td>
                                <td>
                                    {{ i.educationPhases }}
                                </td>
                                <td>
                                    {{ i.typeOfEstablishmentName }}
                                </td>
                                <td>
                                    {{ i.ofstedRatingText }}
                                </td>
                                <td class="text-right">{{ i.distance }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </report-widget>

                </div>

            </div>
        </pdf-page>
        <pdf-page v-if="planning_history.length > 0">

            <div class="py-6">
                <h3 class="text-2xl font-bold">Planning History</h3>


                <div class="mt-12">
                    <report-widget
                            padding-class="''"
                            title="Planning History">
                        <table class="el-report-table">
                            <thead>
                            <tr>
                                <th width="200px">Site Address</th>
                                <th>Proposal</th>
                                <th width="100px" class="text-right">Date Received</th>
                                <th width="100px" class="text-right">Date Decided</th>
                                <th width="100px">Status</th>
                                <th width="100px">Decision</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                    :key="k"
                                    v-for="(i, k) of planning_history">
                                <td class="font-bold">{{ i.site_address }}</td>
                                <td>
                                    {{ i.proposal }}
                                </td>
                                <td>
                                    {{ i.date_received | moment("MMM Do YYYY") }}
                                    <br/><small>{{ i.date_received | moment("from", "now") }}</small>
                                </td>
                                <td class="text-right">
                                    {{ i.date_decided | moment("MMM Do YYYY") }}
                                    <br/><small>{{ i.date_decided | moment("from", "now") }}</small>
                                </td>
                                <td>
                                    {{ i.status }}
                                </td>
                                <td>
                                    {{ i.decision }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </report-widget>

                </div>

            </div>
        </pdf-page>



        <pdf-page v-if="epcs.length > 0">
            <div class="py-6">
                <h3 class="text-2xl font-bold">EPC Ratings</h3>
                <div class="columns mt-12">
                    <div class="column w-full">
                        <report-widget
                                padding-class="''"
                                title="EPC Ratings">
                            <table class="el-report-table">
                                <thead>
                                <tr>
                                    <th width="80px">UPRN</th>
                                    <th>Address</th>
                                    <th width="100px" class="text-center">Rating</th>
                                    <th width="80px">Valid til</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                        :key="k"
                                        v-for="(i, k) of epcs">
                                    <td>
                                        {{ i.uprn }}
                                    </td>
                                    <td class="font-bold">
                                        {{ i.address }}
                                    </td>
                                    <td class="text-center">
                                        {{ i.rating }}
                                    </td>
                                    <td>
                                        {{ i.valid_until | moment("MMM Do YYYY") }}
                                        <br/><small>{{ i.valid_until | moment("from", "now") }}</small>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </report-widget>
                    </div>
                </div>
            </div>
        </pdf-page>


        <pdf-page v-if="business_rates.length > 0">

            <div class="py-6">
                <h3 class="text-2xl font-bold">Business Rates</h3>

                <table class="w-full mt-12"
                       :key="k"
                       v-for="(item, k) of getBusinessRates"
                >
                    <tbody>
                    <tr>
                        <td class="w-full">
                            <div class="mb-1">
                                <report-widget
                                        :padding-class="''"
                                        :title="item.address">

                                    <div class="p-3" v-if="getBusinessRateOverview(item).length > 0">
                                        <div class="el-block--detail mt-3"
                                             :key="index"
                                             v-for="(i, index) of getBusinessRateOverview(item)">
                                            <div class="flex flex-no-wrap mb-2">
                                                <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                                    {{ i.label }}
                                                </div>
                                                <div class="w-32 text-right text-xs text-gray-900">
                                                   {{ i.value }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <table class="el-report-table" v-if="item.items.length > 0">
                                        <thead>
                                        <tr>
                                            <th class="bg-gray-500 text-gray-800" colspan="5">Parts of the property</th>
                                        </tr>
                                        <tr>
                                            <th>Floor</th>
                                            <th width="180px">Description</th>
                                            <th class="text-right" width="100px">Area m²/unit</th>
                                            <th class="text-right" width="130px">Price per m²/unit</th>
                                            <th class="text-right" width="100px">Value</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                                :key="k"
                                                v-for="(i, k) of item.items">

                                            <td>
                                                {{ i.floor }}
                                            </td>
                                            <td>
                                                {{ i.description }}
                                            </td>
                                            <td class="text-right">
                                                <span v-if="i.area"> {{ i.area }}</span>
                                            </td>
                                            <td class="text-right">
                                                <span v-if="i.price">{{ i.price.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) }}</span>
                                            </td>
                                            <td class="text-right">
                                                <span v-if="i.value">{{ i.value.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) }}</span>
                                            </td>

                                        </tr>
                                        <tr v-if="item.total_area && item.sub_total">
                                            <td class="font-bold" colspan="2">
                                                Total
                                            </td>
                                            <td class="text-right font-bold">
                                                {{ item.total_area.toLocaleString() }}
                                            </td>
                                            <td colspan="2" class="text-right font-bold">
                                                {{ item.sub_total.toLocaleString('en-GB', { style: 'currency', currency:
                                                'GBP' }) }}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>

                                    <table class="el-report-table" v-if="item.additional_items.length > 0">
                                        <thead>
                                        <tr>
                                            <th class="bg-gray-500 text-gray-800" colspan="4">Additional Items</th>
                                        </tr>
                                        <tr>
                                            <th>Description</th>
                                            <th class="text-right" width="100px">Area m²/unit</th>
                                            <th class="text-right" width="130px">Price per m²/unit</th>
                                            <th class="text-right" width="100px">Value</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                                :key="k"
                                                v-for="(i, k) of item.additional_items">


                                            <td>
                                                {{ i.description }}
                                            </td>
                                            <td class="text-right">
                                                <span v-if="i.size"> {{ i.size }}</span>
                                            </td>
                                            <td class="text-right">
                                                {{ i.price.toLocaleString('en-GB', { style: 'currency', currency: 'GBP'
                                                }) }}
                                            </td>
                                            <td class="text-right">
                                                {{ i.value.toLocaleString('en-GB', { style: 'currency', currency: 'GBP'
                                                }) }}
                                            </td>

                                        </tr>
                                        <tr v-if="item.additional_items.length > 0">
                                            <td class="font-bold" colspan="3">
                                                Total
                                            </td>
                                            <td colspan="2" class="text-right font-bold">
                                                {{ item.additional_items.map((index)=> index.value).reduce((a, b) => a + b, 0).toLocaleString('en-GB', { style: 'currency', currency:
                                                'GBP' }) }}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table class="el-report-table" v-if="item.car_parking_spaces > 0">
                                        <thead>
                                        <tr>
                                            <th colspan="3" class="bg-gray-500 text-gray-800">Car Parks</th>
                                        </tr>
                                        <tr>
                                            <th>Spaces</th>
                                            <th class="text-right" width="100px">Area</th>
                                            <th class="text-right" width="100px">Value</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>


                                            <td>
                                                {{ item.car_parking_spaces }}
                                            </td>
                                            <td class="text-right">
                                                <span v-if="item.car_parking_area_value"> {{ item.car_parking_area_value }}</span>
                                            </td>
                                            <td class="text-right">
                                               <span v-if="item.car_parking_spaces_value">
                                                   {{ item.car_parking_spaces_value.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) }}
                                               </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="font-bold" colspan="2">Total</td>
                                            <td class="font-bold text-right">{{
                                                item.car_parking_total.toLocaleString('en-GB', { style: 'currency',
                                                currency: 'GBP' }) }}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table class="el-report-table" v-if="item.adjustments.length > 0">
                                        <thead>
                                        <tr>
                                            <th colspan="2" class="bg-gray-500 text-gray-800">Adjustments made to Property</th>
                                        </tr>
                                        <tr>
                                            <th>Description</th>
                                            <th class="text-right" width="100px">Adjustment</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                                :key="k"
                                                v-for="(i, k) of item.adjustments">

                                            <td>
                                                {{ i.description }}
                                            </td>

                                            <td class="text-right">
                                                {{ i.area }}
                                            </td>

                                        </tr>
                                        </tbody>
                                    </table>

                                    <table class="el-report-table">
                                        <tbody>
                                        <tr>
                                            <td class="font-bold text-lg">
                                                Total value
                                            </td>
                                            <td class="font-bold text-lg text-right">
                                                {{ item.total_value.toLocaleString('en-GB', { style: 'currency',
                                                currency: 'GBP' }) }}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>


                                </report-widget>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>

            </div>

        </pdf-page>

        <pdf-page v-if="property_sales.length > 0">
            <div class="py-6">
                <h3 class="text-2xl font-bold">Property Sales</h3>

                <table class="w-full mt-12">
                    <tbody>
                    <tr
                            :key="k"
                            v-for="(item, k) of property_sales">
                        <td class="w-full">
                            <div class="mb-6">
                                <report-widget
                                        padding-class="''"
                                        :title="item.address">

                                    <div class="my-3" style="width: 400px !important; margin: 0 auto">
                                        <ownership-property-sales-chart
                                                :height="300"
                                                :categories="item.chart.categories"
                                                :series="item.chart.series"
                                        />
                                    </div>

                                    <table class="el-report-table">
                                        <thead>
                                        <tr>
                                            <th width="100px">Tenure</th>
                                            <th width="100px">Type</th>
                                            <th width="100px" class="text-center">New Build</th>
                                            <th width="80px">Date</th>
                                            <th width="100px" class="text-right">Price (ft²)</th>
                                            <th width="100px" class="text-right">Price</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                                :key="k"
                                                v-for="(i, k) of item.sales">

                                            <td>
                                                {{ i.duration_string }}
                                            </td>
                                            <td>
                                                {{ i.property_type_string }}
                                            </td>
                                            <td class="text-center">
                                                <i v-if="i.new_build"
                                                   class="text-xl el-icon-check text-green-600 font-bold"></i>
                                                <i v-else class="el-icon-close text-red-600 font-bold"></i>
                                            </td>
                                            <td>
                                                {{ i.date_of_transfer | moment("MMM Do YYYY") }}
                                                <br/><small>{{ i.date_of_transfer | moment("from", "now") }}</small>
                                            </td>
                                            <td class="text-right">
                                                {{ i.price_per_ft2 ? '£' + i.price_per_ft2.toLocaleString() : null }}
                                            </td>
                                            <td class="text-right">
                                                £{{ i.price.toLocaleString() }}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>


                                </report-widget>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>

            </div>
        </pdf-page>



        <pdf-page v-if="recently_sold.length > 0">
            <div class="py-6">
                <h3 class="text-2xl font-bold">Recently Sold Nearby</h3>

                <table class="w-full mt-12">
                    <tr
                            :key="j"
                            v-for="(r, j) of getGroupedRecentlySold"
                    >
                        <td class="w-full">

                            <div class="mb-6">
                                <report-widget
                                        padding-class="''"
                                        :title="'Recently Sold ' + j + ' Properties Nearby'">
                                    <table class="el-report-table">
                                        <thead>
                                        <tr>
                                            <th width="80px">UPRN</th>
                                            <th>Address</th>
                                            <th class="text-right" width="120px">Distance</th>
                                            <th width="100px">Tenure</th>
                                            <th width="100px">Type</th>

                                            <th width="100px" class="text-center">New Build</th>
                                            <th width="80px">Date</th>
                                            <th width="100px" class="text-right">Price Paid</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                                :key="k"
                                                v-for="(i, k) of r">
                                            <td>
                                                {{ i.uprn }}
                                            </td>
                                            <td class="font-bold">
                                                {{ i.address }}
                                            </td>
                                            <td class="text-right">
                                                {{ i.distance ? Math.round(i.distance) + 'm' : '' }}
                                            </td>
                                            <td>
                                                {{ i.duration_string }}
                                            </td>
                                            <td>
                                                {{ i.property_type_string }}
                                            </td>
                                            <td class="text-center">
                                                <i v-if="i.new_build"
                                                   class="text-xl el-icon-check text-green-600 font-bold"></i>
                                                <i v-else class="text-xl el-icon-close text-red-600 font-bold"></i>
                                            </td>
                                            <td>
                                                {{ i.date_of_transfer | moment("MMM Do YYYY") }}
                                                <br/><small>{{ i.date_of_transfer | moment("from", "now") }}</small>
                                            </td>
                                            <td class="text-right">
                                                £{{ i.price.toLocaleString() }}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </report-widget>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </pdf-page>


        <pdf-page v-if="properties.length > 0">
            <div class="py-6">
                <h3 class="text-2xl font-bold">Registered Addresses</h3>
                <div class="columns mt-12">
                    <div class="column w-full">


                        <report-widget
                                padding-class="''"
                                title="Properties">
                            <table class="el-report-table">
                                <thead>
                                <tr>
                                    <th width="120px">Classification</th>
                                    <th>Address</th>
                                    <th width="100px">Ownership</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                        :key="key"
                                        v-for="(item, key) of properties">
                                    <td>
                                        {{ item.classification }}
                                    </td>
                                    <td class="font-bold">
                                        {{ item.address }}
                                    </td>
                                    <td width="100px">
                                        {{ item.ownership }}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </report-widget>
                    </div>
                </div>
            </div>
        </pdf-page>

        <pdf-page v-if="leaseholders.length > 0">
            <div class="py-6">
                <h3 class="text-2xl font-bold">Leaseholders</h3>
                <div class="columns mt-12">
                    <div class="column w-full">


                        <report-widget
                                padding-class="''"
                                title="Leaseholders">
                            <table class="el-report-table">
                                <thead>
                                <tr>
                                    <th>Address</th>
                                    <th width="100px">Tenure</th>
                                    <th width="100px" class="text-right">Price Paid</th>
                                    <th width="80px">Date of Lease</th>
                                    <th width="120px">Term</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                        :key="key"
                                        v-for="(item, key) of leaseholders">
                                    <td class="font-bold">
                                        {{ item.address }}
                                    </td>
                                    <td width="100px">
                                        {{ item.tenure }}
                                    </td>
                                    <td class="text-right">
                               <span v-if="item.price_paid">
                                      £{{ item.price_paid.toLocaleString() }}
                                      </span>
                                    </td>
                                    <td>
                                        {{ item.date_of_lease | moment("MMM Do YYYY") }}
                                        <br/><small>{{ item.date_of_lease | moment("from", "now") }}</small>
                                    </td>
                                    <td>
                                        {{ item.term }}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </report-widget>
                    </div>
                </div>
            </div>
        </pdf-page>


        <pdf-page v-if="listed_buildings.length > 0">

            <div class="py-6">
                <h3 class="text-2xl font-bold">Heritage & Listed Buildings</h3>


                <div class="mt-12">
                    <report-widget
                            padding-class="''"
                            title="Listed Buildings">
                        <table class="el-report-table">
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th width="130px">Grade</th>
                                <th width="100px" class="text-right">Date</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                    :key="k"
                                    v-for="(i, k) of listed_buildings">
                                <td class="font-bold">{{ i.name }}</td>
                                <td>
                                    {{ i.grade }}
                                </td>
                                <td>
                                    {{ i.date_listed | moment("MMM Do YYYY") }}
                                    <br/><small>{{ i.date_listed | moment("from", "now") }}</small>
                                </td>

                            </tr>
                            </tbody>
                        </table>
                    </report-widget>

                </div>

            </div>
        </pdf-page>


        <pdf-page v-if="crimes.length > 0">


            <div class="py-6">
                <h3 class="text-2xl font-bold">Crime</h3>
                <div class="columns mt-12">
                    <div class="column w-full">

                        <report-widget
                                padding-class="''"
                                title="Crimes">
                            <table class="el-report-table">
                                <thead>
                                <tr>
                                    <th width="170px">Category</th>
                                    <th>Street</th>
                                    <th width="100px" class="text-right">Distance</th>
                                    <th width="100px" class="text-right">Date</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                        :key="k"
                                        v-for="(i, k) of crimes">

                                    <td>
                                        {{ i.category_pretty }}
                                    </td>
                                    <td class="font-bold">
                                        {{ i.street }}
                                    </td>
                                    <td class="text-right">
                                        {{ i.distance }} miles
                                    </td>
                                    <td class="text-right">
                                        {{ i.date_pretty }}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </report-widget>

                    </div>
                </div>
            </div>

        </pdf-page>


        <pdf-page v-if="companyOwnershipFreeholders.length > 0 || companyOwnershipLeaseholders.length > 0">
            <div class="py-6">
                <h3 class="text-2xl font-bold">Nearby Company Freeholders</h3>
                <div class="mt-12">
                <report-widget
                        padding-class="''"
                        title="Company Freeholders">
                    <table class="el-report-table">
                        <thead>
                        <tr>
                            <th width="110px">Title Number</th>
                            <th width="300px">Company</th>
                            <th>Address</th>
                            <th class="text-right" width="80px">Distance</th>
                            <th class="text-right" width="80px">Price Paid</th>
                            <th width="150px">Date Purchased</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                                :key="k"
                                v-for="(i, k) of companyOwnershipFreeholders">
                            <td>
                                {{ i.title_number }}
                            </td>
                            <td>
                                <span class="font-bold"> {{ i.proprietor }}</span><br />
                                <small>{{ i.proprietor_category  }}<span v-if="i.proprietor_company_registration_no"> - {{ i.proprietor_company_registration_no }}</span><span v-if="i.overseas"> (Overseas Company)</span></small>
                            </td>
                            <td>
                                {{ i.property_address }}
                            </td>
                            <td class="text-right">
                                {{ i.distance ? i.distance : 0 }}m
                            </td>
                            <td class="text-right">
                                {{ i.price_paid ? i.price_paid.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) : null }}
                            </td>
                            <td>
                                {{ i.date_proprietor_added | moment("MMM Do YYYY") }}
                                <br/><small>{{ i.date_proprietor_added | moment("from", "now") }}</small>
                            </td>

                        </tr>
                        </tbody>
                    </table>
                </report-widget>
                </div>
            </div>
        </pdf-page>

        <pdf-page v-if="companyOwnershipLeaseholders.length > 0">

            <div class="py-6">
                <h3 class="text-2xl font-bold">Nearby Company Leaseholders</h3>
                <div class="mt-12">
                <report-widget
                        padding-class="''"
                        title="Company Leaseholders">
                    <table class="el-report-table">
                        <thead>
                        <tr>
                            <th width="110px">Title Number</th>
                            <th width="300px">Company</th>
                            <th>Address</th>
                            <th class="text-right" width="80px">Distance</th>
                            <th class="text-right" width="80px">Price Paid</th>
                            <th width="150px">Date Purchased</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                                :key="k"
                                v-for="(i, k) of companyOwnershipLeaseholders">
                            <td>
                                {{ i.title_number }}
                            </td>
                            <td>
                                <span class="font-bold"> {{ i.proprietor }}</span><br />
                                <small>{{ i.proprietor_category  }}<span v-if="i.proprietor_company_registration_no"> - {{ i.proprietor_company_registration_no }}</span><span v-if="i.overseas"> (Overseas Company)</span></small>
                            </td>
                            <td>
                                {{ i.property_address }}
                            </td>
                            <td class="text-right">
                                {{ i.distance ? i.distance : 0 }}m
                            </td>
                            <td class="text-right">
                                {{ i.price_paid ? i.price_paid.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) : null }}
                            </td>
                            <td>
                                {{ i.date_proprietor_added | moment("MMM Do YYYY") }}
                                <br/><small>{{ i.date_proprietor_added | moment("from", "now") }}</small>
                            </td>

                        </tr>
                        </tbody>
                    </table>
                </report-widget>
                </div>
            </div>

        </pdf-page>

        <pdf-page v-if="companyActivityFreeholders.length > 0">

            <div class="py-6">
                <h3 class="text-2xl font-bold">Company Activity Freeholders</h3>
                <div class="mt-12">
                <report-widget
                        padding-class="''"
                        title="Company Leaseholders">
                    <table class="el-report-table">
                        <thead>
                        <tr>
                            <th class="font-bold" width="270px">Company</th>
                            <th>Address</th>
                            <th class="text-right" width="50px">Past 6 Months</th>
                            <th class="text-right" width="50px">Past 12 Months</th>
                            <th class="text-right" width="50px">Past 18 Months</th>
                            <th class="text-right"  width="200px">Purchased Nationally (Past 18 months)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                                :key="k"
                                v-for="(i, k) of companyActivityFreeholders">
                            <td>
                                <span class="font-bold"> {{ i.proprietor }}</span><br />
                                <small>{{ i.category  }}<span v-if="i.registration_no"> - {{ i.registration_no }}</span><span v-if="i.overseas"> (Overseas Company)</span></small>
                            </td>
                            <td>
                                {{ i.company_address }}
                            </td>
                            <td class="text-right">
                                {{ i.last_6_months }}
                            </td>
                            <td class="text-right">
                                {{ i.last_12_months }}
                            </td>
                            <td class="text-right">
                                {{ i.last_18_months }}
                            </td>
                            <td class="text-right">
                                {{ i.last_18_months_nationally }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </report-widget>
                </div>
            </div>

        </pdf-page>

        <pdf-page v-if="companyActivityLeaseholders.length > 0">

            <div class="py-6">
                <h3 class="text-2xl font-bold">Company Activity Leaseholders</h3>
                <div class="mt-12">
                <report-widget
                        padding-class="''"
                        title="Company Leaseholders">
                    <table class="el-report-table">
                        <thead>
                        <tr>
                            <th class="font-bold" width="270px">Company</th>
                            <th>Address</th>
                            <th class="text-right" width="50px">Past 6 Months</th>
                            <th class="text-right" width="50px">Past 12 Months</th>
                            <th class="text-right" width="50px">Past 18 Months</th>
                            <th class="text-right"  width="200px">Leased Nationally (Past 18 months)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                                :key="k"
                                v-for="(i, k) of companyActivityLeaseholders">
                            <td>
                                <span class="font-bold"> {{ i.proprietor }}</span><br />
                                <small>{{ i.category  }}<span v-if="i.registration_no"> - {{ i.registration_no }}</span><span v-if="i.overseas"> (Overseas Company)</span></small>
                            </td>
                            <td>
                                {{ i.company_address }}
                            </td>
                            <td class="text-right">
                                {{ i.last_6_months }}
                            </td>
                            <td class="text-right">
                                {{ i.last_12_months }}
                            </td>
                            <td class="text-right">
                                {{ i.last_18_months }}
                            </td>
                            <td class="text-right">
                                {{ i.last_18_months_nationally }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </report-widget>
                </div>
            </div>

        </pdf-page>


        <div class="divFooter w-full text-center text-xs">
            Copyright Land Monitor {{ (new Date().getFullYear()) }}. Land Monitor is a registered company in England and Wales.
        </div>

        <div id="loaded" style="display: none">Loaded</div>

        <h4 id="fileName" style="display: none">{{ overview.title_number }} - {{ overview.address }}</h4>

    </div>
</template>

<script>

    import { broadbandCoverageTable, getClassForMobileCoverage } from "../LandMonitor/OwnershipLayerComponents/incs/BroadbandCoverage"

    import collect from 'collect.js';

    import PdfPage from "./incs/PdfPage";
    import OwnershipPropertySalesChart from "../LandMonitor/OwnershipLayerComponents/incs/OwnershipPropertySalesChart";
    import ReportWidget from "./incs/ReportWidget";

    import moment from 'moment';
    import OwnershipOverviewMap from "../LandMonitor/OwnershipLayerComponents/incs/OwnershipOverviewMap";
    import CorporateOwnershipDetails from "../LandMonitor/OwnershipLayerComponents/Corporate/CorporateOwnershipDetails";
    import CorporateOwnershipFinancials from "../LandMonitor/OwnershipLayerComponents/Corporate/CorporateOwnershipFinancials";
    import CorporateOwnershipDirectors from "../LandMonitor/OwnershipLayerComponents/Corporate/CorporateOwnershipDirectors";

    import OwnershipTopographyMap from "../LandMonitor/OwnershipLayerComponents/incs/OwnershipTopographyMap";

    export default {
        name: "LandMonitorReport",
        components: {
            OwnershipTopographyMap,
            CorporateOwnershipDirectors,
            CorporateOwnershipFinancials,
            CorporateOwnershipDetails,
            OwnershipOverviewMap, ReportWidget, OwnershipPropertySalesChart, PdfPage},
        computed: {
            isPrivateOwnership(){
                return this.overview && this.overview.is_corporate_entity ? this.overview.is_corporate_entity !== true : true;
            },
            getBusinessRates() {
                return this.business_rates && Array.isArray(this.business_rates) ? this.business_rates : [];
            },
            getTrafficAnalysisPoints() {
                return this.traffic_analysis && this.traffic_analysis.points ? this.traffic_analysis.points : [];
            },
            getGroupedRecentlySold(){
                let types = collect(this.recently_sold).transform((item)=> item.property_type_string).unique().toArray();

                let results = {};

                for(let t of types){
                    results[t] = collect(this.recently_sold).filter((item)=> item.property_type_string === t).unique().toArray()
                }
                return results;
            },
            administrativeDetails() {
                return [
                    {label: 'Altitude', value: this.demographics.altitude ? this.demographics.altitude : null},
                    {label: 'Parish', value: this.administrativeArea.parish ? this.administrativeArea.parish : null},
                    {label: 'Ward', value: this.administrativeArea.ward ? this.administrativeArea.ward : null},
                    {
                        label: 'Local Authority',
                        value: this.administrativeArea.local_authority ? this.administrativeArea.local_authority : null
                    },
                    {
                        label: 'District',
                        value: this.administrativeArea.district ? this.administrativeArea.district : null
                    },
                    {label: 'County', value: this.administrativeArea.county ? this.administrativeArea.county : null},
                    {label: 'Region', value: this.administrativeArea.region ? this.administrativeArea.region : null},
                    {label: 'Country', value: this.administrativeArea.country ? this.administrativeArea.country : null},

                    {
                        label: 'Police force',
                        value: this.administrativeArea.police_force ? this.administrativeArea.police_force : null
                    },
                    {
                        label: 'Water company',
                        value: this.administrativeArea.water_company ? this.administrativeArea.water_company : null
                    },
                    {
                        label: 'Sewage company',
                        value: this.administrativeArea.sewage_company ? this.administrativeArea.sewage_company : null
                    },

                ];
            },
            demographicsTable() {
                return [
                    {label: 'Rural/urban', value: this.demographics.rural_urban ? this.demographics.rural_urban : null},
                    {
                        label: 'Built up area',
                        value: this.demographics.built_up_area ? this.demographics.built_up_area : null
                    },
                    {
                        label: 'Lower layer super output area',
                        value: this.demographics.lower_layer_super_output_layer ? this.demographics.lower_layer_super_output_layer : null
                    },
                    {
                        label: 'Middle layer super output area',
                        value: this.demographics.middle_layer_super_output_layer ? this.demographics.middle_layer_super_output_layer : null
                    },
                    {
                        label: 'Travel to work area',
                        value: this.demographics.travel_to_work_area ? this.demographics.travel_to_work_area : null
                    },
                ];
            },
            companyOwnershipFreeholders(){
                let vm = this;
                return vm.company_ownership && vm.company_ownership.freeholders  ? vm.company_ownership.freeholders : [];
            },
            companyOwnershipLeaseholders(){
                let vm = this;
                return vm.company_ownership && vm.company_ownership.leaseholders  ? vm.company_ownership.leaseholders : [];
            },


            companyActivityFreeholders(){
                let vm = this;
                return vm.company_activity && vm.company_activity.freeholders  ? vm.company_activity.freeholders : [];
            },
            companyActivityLeaseholders(){
                let vm = this;
                return vm.company_activity && vm.company_activity.leaseholders  ? vm.company_activity.leaseholders : [];
            },

            deprivation() {
                let vm = this;
                return vm.overview && vm.overview.local_authority && vm.overview.local_authority.deprivation_earnings ? vm.overview.local_authority.deprivation_earnings : {};
            },
            administrativeArea() {
                let vm = this;
                return vm.overview && vm.overview.local_authority && vm.overview.local_authority.administrative_area ? vm.overview.local_authority.administrative_area : {};
            },
            demographics() {
                let vm = this;
                return vm.overview && vm.overview.local_authority && vm.overview.local_authority.demographics ? vm.overview.local_authority.demographics : {};
            },
            getBroadbandList(){
                let broadband = this.mobile_broadband && this.mobile_broadband.broadband ? this.mobile_broadband.broadband : {};
                if(!Object.keys(broadband).length){
                    return [];
                }
                return broadbandCoverageTable(broadband);
            },
        },
        methods: {
            getClassForMobileCoverage(rating){
                return getClassForMobileCoverage(rating);
            },
            fetch() {
                const vm = this, inspireId = this.$route.params.inspireId;

                Promise.all([

                    this.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/ownership/' + inspireId, {
                        headers: {
                            'x-report-bot': this.$route.query && this.$route.query.key ? this.$route.query.key : null
                        }
                    })
                        .then((response) => {
                            return response.data.data;
                        }).catch(() => {
                        return {};
                    }),
                    this.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/ownership/' + inspireId + '/environment', {
                        headers: {
                            'x-report-bot': this.$route.query && this.$route.query.key ? this.$route.query.key : null
                        }
                    })
                        .then((response) => {
                            return response.data.data;
                        }).catch(() => {
                        return {};
                    }),

                    this.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/ownership/' + inspireId + '/sale-history', {
                        headers: {
                            'x-report-bot': this.$route.query && this.$route.query.key ? this.$route.query.key : null
                        }
                    })
                        .then((response) => {
                            return response.data.data;
                        }).catch(() => {
                        return {};
                    }),

                    this.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/ownership/' + inspireId + '/topography', {
                        headers: {
                            'x-report-bot': this.$route.query && this.$route.query.key ? this.$route.query.key : null
                        }
                    })
                        .then((response) => {
                            return response.data.data;
                        }).catch(() => {
                        return {};
                    }),

                    this.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/ownership/' + inspireId + '/traffic-analysis', {
                        headers: {
                            'x-report-bot': this.$route.query && this.$route.query.key ? this.$route.query.key : null
                        }
                    })
                        .then((response) => {
                        return response.data.data;
                    }).catch(() => {
                        return {};
                    }),
                    this.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/ownership/' + inspireId + '/local-schools', {
                        headers: {
                            'x-report-bot': this.$route.query && this.$route.query.key ? this.$route.query.key : null
                        }
                    })
                        .then((response) => {
                        return response.data.data.locations;
                    }).catch(() => {
                        return [];
                    }),
                    this.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/ownership/' + inspireId + '/planning', {
                        headers: {
                            'x-report-bot': this.$route.query && this.$route.query.key ? this.$route.query.key : null
                        }
                    })
                        .then((response) => {
                        return response.data.data;
                    }).catch(() => {
                        return [];
                    }),
                    this.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/ownership/' + inspireId + '/mobile-broadband', {
                        headers: {
                            'x-report-bot': this.$route.query && this.$route.query.key ? this.$route.query.key : null
                        }
                    })
                        .then((response) => {
                        return response.data.data;
                    }).catch(() => {
                        return {};
                    }),
                    this.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/ownership/' + inspireId + '/company-ownership-nearby', {
                        headers: {
                            'x-report-bot': this.$route.query && this.$route.query.key ? this.$route.query.key : null
                        }
                    })
                        .then((response) => {
                            return response.data.data;
                        }).catch(() => {
                        return {};
                    }),

                    this.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/ownership/' + inspireId + '/company-activity', {
                        headers: {
                            'x-report-bot': this.$route.query && this.$route.query.key ? this.$route.query.key : null
                        }
                    })
                        .then((response) => {
                            return response.data.data;
                        }).catch(() => {
                        return {};
                    }),
                ]).then(([item, environment, saleHistory, topography, trafficAnalysis, localSchools, planning, mobileBroadband, companyOwnershipNearby, companyActivity]) => {

                    vm.environment = environment;
                    vm.traffic_analysis = trafficAnalysis;
                    vm.schools = localSchools;
                    vm.planning_history = planning;
                    vm.mobile_broadband = mobileBroadband;
                    vm.leaseholders = item.leaseholders ? item.leaseholders : [];
                    vm.properties = item.properties ? item.properties : [];
                    vm.property_sales = saleHistory && saleHistory.property_sales ? saleHistory.property_sales : [];
                    vm.recently_sold = saleHistory && saleHistory.nearby ? saleHistory.nearby : [];
                    vm.epcs = item.epc ? item.epc : [];
                    vm.listed_buildings = item.listed_buildings ? item.listed_buildings : [];
                    vm.population = item.population ? item.population : [];

                    vm.overview = item;


                    vm.company_ownership = companyOwnershipNearby;

                    vm.crimes = item.crime && item.crime.crimes ? item.crime.crimes : [];
                    vm.crimes_bbox = item.crime && item.crime.bbox ? item.crime.bbox : [];

                    vm.topography = topography.topography ? topography.topography : []
                    vm.site_buildings = topography.site_buildings ? topography.site_buildings : []

                    vm.company_activity = companyActivity;

                })
            },
            topographyListWithHeights(items){
              return items.filter((item)=>{
                  return item.abs_h_min;
              })
            },
            getBusinessRateOverview(item){
                return [
                    {label: 'Description', value: item.primary_description_text },
                    {label: 'Local authority', value: item.ba_name },
                    {label: 'Local authority reference', value: item.ba_reference_number },
                    {label: 'Total area', value: item.total_area + 'm²'},
                    {label: 'Base rate', value: item.unadjusted_price.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) },
                    {label: 'Total rateable value', value: item.adopted_rv.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) },
                    {label: 'Basis of measurement', value: item.unit_of_measurement },
                    {label: 'Valuation scheme reference', value: item.scheme_reference },
                    {label: 'Special category code', value: item.scat_code_only },
                    {label: 'Effective date', value: moment(item.from_date).format("MMM Do YYYY") },
                ];
            },
            buildTrafficAnalysisMap(data) {

                let obj = {
                    size: '800x400',
                    maptype: 'hybrid',
                    center: data.latitude + ',' + data.longitude,
                    zoom: 16,
                    markers: 'color:red|label:' + data.road_name + '|' + data.latitude + ',' + data.longitude,
                    key: this.$store.state.auth.map.google.key,
                }

                let str = [];


                for (let p in obj) {
                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                }

                return 'http://maps.googleapis.com/maps/api/staticmap?' + str.join('&')
            },
        },
        created() {
            this.fetch();
            console.log('axios', window.axiosRaw)
        },
        data() {
            return {
                planning_history: [],
                population: [],
                epcs: [],
                properties: [],
                listed_buildings: [],
                schools: [],
                business_rates: [],
                property_sales: [],
                recently_sold: [],
                leaseholders: [],
                overview: {},
                topography: [],
                mobile_broadband: {},
                crimes: [],
                crimes_bbox: [],
                site_buildings: [],
                value: {},
                environment: {},
                traffic_analysis: {},
                company_ownership: {},
                company_activity: {}
            }
        }
    }
</script>

<style lang="scss">



    #land-monitor-report {


        .el-report-table {
            width: 100%;

            td, th {
                padding: 0.5rem 0.7rem;
                font-size: 0.7em;
            }

            th {
                border-top: 1px solid #dddddd;
                border-bottom: 1px solid #dddddd;
                text-align: left;
                text-transform: uppercase;
                letter-spacing: 0.001rem;
                font-weight: bold;
                color: rgba(0, 23, 98, 0.82);
            }

            td {
                vertical-align: top;
                border-bottom: 1px solid #dddddd;
            }
        }

        table {
            page-break-inside: auto;
        }

        tr {
            page-break-inside: avoid;
            page-break-after: auto
        }


        @media print {
            @page {
                margin: 5mm 5mm 5mm 5mm;
            }

            * {
                -webkit-print-color-adjust: exact !important;
            }


            .el-block--report-widget {
                border-color: #f2f2f2 !important;
                border: 1px solid;

                .title {
                    border-color: #f2f2f2 !important;
                    background: #0d203b;

                    h3 {
                        color: #fff !important;
                        font-size: 0.6rem !important;
                    }
                }
            }


            .is-full-page {
                width: 100%;
                height: 100%;
                position: absolute;
                display: flex;
                justify-items: center;
                align-items: center;
                text-align: center;
                color: #fff;
                -webkit-print-color-adjust: exact !important;
            }
            footer {
                page-break-after: always;
            }
        }
    }

    @media screen {
        div.divFooter {
            display: none;
        }
    }
    @media print {
        div.divFooter {
            position: fixed;
            bottom: 0;
        }
    }

</style>