<template>
    <div class="columns">
        <div class="column w-full">
            <dashboard-widget title="Deprivation & Earnings">
                <div class="el-block--detail mt-3" v-if="deprivation.avg_income">
                    <div class="flex flex-no-wrap mb-2">
                        <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                            Average Income
                        </div>
                        <div class="w-32 text-right text-xs text-gray-900">
                            £{{ deprivation.avg_income.toLocaleString() }}
                            <div class="mt-2">
                                <el-progress
                                        color="#2a4365"
                                        v-if="deprivation && deprivation.avg_income_percentage"
                                        :percentage="deprivation.avg_income_percentage"
                                        :show-text="false" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="el-block--detail mt-3" v-if="deprivation.index_of_multiple_deprivation">
                    <div class="flex flex-no-wrap mb-2">
                        <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                            Index of multiple deprivation
                        </div>
                        <div class="w-32 text-right text-xs text-gray-900">
                            {{ deprivation.index_of_multiple_deprivation.toLocaleString() }}
                            <div class="mt-2">
                                <el-progress
                                        color="#009c64"
                                        v-if="deprivation && deprivation.index_of_multiple_deprivation_percentage"
                                        :percentage="deprivation.index_of_multiple_deprivation_percentage"
                                        :show-text="false" />
                            </div>
                        </div>
                    </div>
                </div>
            </dashboard-widget>
        </div>
        <div class="column w-full">
            <dashboard-widget title="Administrative Areas">
                <div class="el-block--detail mt-3"
                     :key="k"
                     v-for="(i, k) of administrativeDetails">
                    <div class="flex flex-no-wrap mb-2">
                        <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                            {{ i.label }}
                        </div>
                        <div class="w-32 text-right text-xs text-gray-900">
                            {{ i.value }}
                        </div>
                    </div>
                </div>
            </dashboard-widget>
        </div>
        <div class="column w-full">

            <dashboard-widget title="Demographics">
                <div class="el-block--detail mt-3"
                     :key="k"
                     v-for="(i, k) of demographicsTable">
                    <div class="flex flex-no-wrap mb-2">
                        <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                            {{ i.label }}
                        </div>
                        <div class="w-32 text-right text-xs text-gray-900">
                            {{ i.value }}
                        </div>
                    </div>
                </div>
            </dashboard-widget>
        </div>
    </div>

</template>

<script>
    export default {
        name: "OwnershipAdministrativeDetails",
        props: {
            administrativeArea: {
              type: Object,
              default(){
                  return {}
              }
          },
            demographics: {
                type: Object,
                default(){
                    return {}
                }
            },
            deprivation: {
                type: Object,
                default(){
                    return {}
                }
            }
        },
        computed: {
            administrativeDetails(){
                return [
                    {label: 'Altitude', value: this.demographics.altitude ? this.demographics.altitude + 'm' : null },
                    {label: 'Parish', value: this.administrativeArea.parish ? this.administrativeArea.parish : null },
                    {label: 'Ward', value: this.administrativeArea.ward ? this.administrativeArea.ward : null},
                    {label: 'Local Authority', value: this.administrativeArea.local_authority ? this.administrativeArea.local_authority : null},
                    {label: 'District', value: this.administrativeArea.district ? this.administrativeArea.district : null},
                    {label: 'County', value: this.administrativeArea.county ? this.administrativeArea.county : null},
                    {label: 'Region',  value: this.administrativeArea.region ? this.administrativeArea.region : null},
                    {label: 'Police force',  value: this.administrativeArea.police_force ? this.administrativeArea.police_force : null},
                    {label: 'Water company', value: this.administrativeArea.water_company ? this.administrativeArea.water_company : null},
                    {label: 'Sewage company', value: this.administrativeArea.sewage_company ? this.administrativeArea.sewage_company : null},

                ];
            },
            demographicsTable(){
                return [
                    {label: 'Rural/urban', value: this.demographics.rural_urban ? this.demographics.rural_urban : null},
                    {label: 'Built up area', value: this.demographics.built_up_area ? this.demographics.built_up_area : null},
                    {label: 'Lower layer super output area', value: this.demographics.lower_layer_super_output_layer ? this.demographics.lower_layer_super_output_layer : null},
                    {label: 'Middle layer super output area', value: this.demographics.middle_layer_super_output_layer ? this.demographics.middle_layer_super_output_layer : null},
                    {label: 'Travel to work area', value: this.demographics.travel_to_work_area ? this.demographics.travel_to_work_area : null},
                ];
            }
        }
    }
</script>

<style scoped>

</style>