<template>
    <div>
        <div v-if="!isImage">
            <avatar :size="size" :username="name"></avatar>
        </div>
        <div class="w-10 h-10" v-else>
            <el-avatar :size="size"
                       class="bg-white border-gray-400 border shadow-sm"
                       fit="fill"
                       :src="value"></el-avatar>

        </div>
    </div>
</template>

<script>
    export default {
        name: "AvatarManager",
        props: {
            value: {
                type: String,
                default(){
                    return null;
                }
            },
            size: {
              type: Number,
              default(){
                  return 40;
              }
            },
            name: {
                type: String,
                default(){
                    return null;
                }
            }
        },

        computed: {
            isImage(){
                if(this.value === null){
                    return false;
                }
                return this.value && typeof this.value === 'string' && this.value.startsWith('data:image');
            }
        }
    }
</script>

<style scoped>

</style>