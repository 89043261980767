<template>
     <div>
         <div class="columns">
             <div class="column w-full">

                 <dashboard-widget
                         padding-class=""
                         title="Listed Buildings">
                     <slot>
                         <div class="p-3" v-if="!propertiesList.length">
                             <empty-state />
                         </div>

                         <div v-if="propertiesList.length > 0">

                             <div class="m-2">
                                 <el-alert
                                         effect="dark" type="success" :closable="false">
                                     {{ propertiesList.length }} listed buildings within this site
                                 </el-alert>
                             </div>

                             <ownership-uprn-map
                                     class="p-2 pt-0"
                                     :bbox="bbox"
                                     v-model="items"
                             />


                             <el-collapse
                                     class="el-collapse--listed"
                                     v-model="selected">
                                 <el-collapse-item
                                         :key="i"
                                         v-for="(item, i) of propertiesList" :name="i">


                                     <template slot="title">
                                         <div class="flex flex-wrap w-full flex-row">
                                             <div class="w-full flex py-2 pb-3">
                                                 <div class="w-5 mr-2 -mt-1 flex-none">
                                                     <div class="w-5 h-5 mt-1 flex text-white items-center justify-center rounded-full bg-blue-600 text-xxs ">
                                                         {{ i + 1 }}
                                                     </div>
                                                 </div>
                                                 <div class="w-auto">
                                                     {{ item.name }}
                                                 </div>
                                             </div>
                                             <div class="text-xs w-full flex">
                                                 <div class="mr-3">
                                                     <span>Grade {{ item.grade }} Listed</span>
                                                 </div>
                                                 <div class="mr-3">
                                                     <span>Listed </span> <date-human-view size="text-xs" v-model="item.date_listed" />
                                                 </div>
                                             </div>
                                         </div>
                                     </template>

                                     <div class="p-3">
                                         <div class="columns flex-no-wrap" v-if="item.name">
                                             <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                                                 Name
                                             </div>
                                             <div class="column uppercase ml-auto text-xs text-right text-gray-900 flex items-center">
                                                 {{ item.name }}
                                             </div>
                                         </div>

                                         <div class="columns flex-no-wrap" v-if="item.grade">
                                             <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                                                 Grade
                                             </div>
                                             <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                                 {{ item.grade }}
                                             </div>
                                         </div>

                                         <div class="columns flex-no-wrap" v-if="item.date_listed">
                                             <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                                                 Date listed
                                             </div>
                                             <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                                 <div class="break-normal h-full text-xs leading-tight">
                                                     {{ item.date_listed | moment("MMM Do YYYY") }}
                                                     <br/><small>{{ item.date_listed | moment("from", "now") }}</small>
                                                 </div>
                                             </div>
                                         </div>

                                         <el-button class="w-full mt-4" type="success"
                                                    icon="el-icon-view"
                                                    @click="gotTo(item.id)">View Details</el-button>

                                     </div>


                                 </el-collapse-item>
                             </el-collapse>


                         </div>



                     </slot>
                 </dashboard-widget>



             </div>
         </div>
     </div>
</template>

<script>
    import EmptyState from "../../EmptyState";
    import OwnershipUprnMap from "./incs/OwnershipUprnMap";
    export default {
        name: "OwnershipListedBuildingsComponent",
        components: {OwnershipUprnMap, EmptyState},
        props: {
            value: {
                type: Array,
                default(){
                    return []
                }
            },
            activeSiteId: {
                type: Number,
                default(){
                    return null;
                }
            },
            bbox: {
                type: Array,
                default(){
                    return [];
                }
            }
        },
        computed: {
            propertiesList(){
                if(this.activeSiteId){
                    return this.value.filter((i)=>{
                        return i.site_id === this.activeSiteId;
                    })
                }
                return this.value
            },
            items(){
                    return this.propertiesList.map((i)=>{
                        return {
                            i: i.id,
                            lat: i.lat,
                            lon: i.lon
                        }
                    })
            }
        },
        methods: {
            gotTo(id){
                window.open('https://historicengland.org.uk/listing/the-list/list-entry/' + id);
            }
        },
        data(){
            return {
                selected: null
            }
        }
    }
</script>

<style>
    .el-collapse--listed  .el-collapse-item__header {
        line-height: 15px !important;
        height: auto !important;
        padding: 10px !important;
    }
</style>