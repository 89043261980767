<template>
    <div>

        <dashboard-widget
                padding-class="''"
                title="Street View">
            <div v-if="value.lat">
                <street-view-layer
                        :location="{
                lat: value.lat,
                lng: value.lon
                }"
                />
            </div>
            <div v-else>
                <empty-state />
            </div>
        </dashboard-widget>

    </div>
</template>

<script>
    import StreetViewLayer from "../StreetViewLayer";
    import EmptyState from "../../EmptyState";

    export default {
        name: "OwnershipStreetView",
        props: {
          value: {
              type: Object,
              default(){
                  return null;
              }
          }
        },
        components: {EmptyState, StreetViewLayer}
    }

</script>