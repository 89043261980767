import Vue from 'vue'
import VueRouter from 'vue-router'
import Applications from "../Pages/Applications";
import ApplicationSearch from "../Pages/Applications/Search";
import Application from "../Pages/Application";
import ApplicationMap from "../Pages/Applications/ApplicationMap";
import Alerts from "../Pages/Alerts";
import WatchList from "../Pages/Applications/WatchList";
import Login from "../Pages/Login";
import LandHawk from "../Pages/LandHawk";

import store from "./../store/index";
//import HousePrices from "../Pages/PropertyPrices";
import AreaPropertyPrices from "../Pages/PropertyMarket/Prices/Area";
import AlertsCreate from "./../Pages/Alerts/Create"
import AlertsRead from "./../Pages/Alerts/Read";
import CivilParishes from "../Pages/PropertyMarket/Prices/CivilParishes";
import Communities from "../Pages/PropertyMarket/Prices/Communities";
import LondonBoroughs from "../Pages/PropertyMarket/Prices/LondonBoroughs";
import UAs from "../Pages/PropertyMarket/Prices/UAs";
import Districts from "../Pages/PropertyMarket/Prices/Districts";
import PropertyPricesSearch from "../Pages/PropertyMarket/Prices/Search";
import CommercialAvailabilitySearch from "../Pages/PropertyMarket/CommercialAvailability/Search";
import Error404 from "../Pages/Error404";
import SubscriptionRequired from "./../Pages/SubscriptionRequired"
import LandMonitor from "./../Pages/LandMonitor"

import Profile from '../Pages/Account/Profile';
import Security from "../Pages/Account/Security";
import VerifyEmail from "../Pages/VerifyEmail";
import LandMonitorReport from "../components/PDFs/LandMonitorReport";
import EVReport from "../components/PDFs/EVReport";
import LandHawkCreateJob from "../Pages/LandHawk/LandHawkCreateJob";
import LandHawkJob from "../Pages/LandHawk/LandHawkJob";
import Workflows from "../Pages/Workflows";
import WorkflowCreate from "../Pages/Workflows/WorkflowCreate";
import Workflow from "../Pages/Workflows/Workflow";
import SavedSearches from "../Pages/SavedSearches";
import Teams from "../Pages/Teams";
import MarketTrackerForSale from "../Pages/PropertyMarket/MarketTrackerForSale";
import MarketTrackerRental from "../Pages/PropertyMarket/MarketTrackerRental";
import MarketTrackerRentalYield from "../Pages/PropertyMarket/MarketTrackerRentalYield";
import Team from "../Pages/Team/Team";
import Invites from "../Pages/Team/Invites";
import Register from "../Pages/Register";
import PasswordReset from '../Pages/PasswordReset';
import ResetPassword from "../Pages/ResetPassword";
import OutstandingInvites from "../Pages/OutstandingInvites";
import TeamSettings from "../Pages/Team/TeamSettings";
import TeamInvoices from "../Pages/Team/TeamInvoices";
import SelectTeam from "../Pages/SelectTeam";
import CreateTeam from "../Pages/CreateTeam";
import EPCRatings from "../Pages/EPCRatings";
import TeamSeats from "../Pages/Team/TeamSeats";
import ActiveTeam from "../Pages/ActiveTeam";
import DeleteAccount from "../Pages/Account/DeleteAccount";
import UserInvites from "../Pages/Account/UserInvites";
import Reports from "../Pages/Reports";

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login
    },

    {
        path: '/register',
        name: 'Register',
        component: Register
    },

    {
        path: '/password-reset',
        name: 'PasswordReset',
        component: PasswordReset
    },

    {
        path: '/team-invites',
        name: 'UserInvites',
        component: UserInvites
    },

    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword
    },

    {
        path: '/teams',
        name: 'Teams',
        component: Teams,
        meta: {
            requiresAuth: true,
            manageOutstandingInvites: true,
            requiresEmailVerification: true
        }
    },

    {
        path: '/teams/create',
        name: 'TeamCreate',
        component: CreateTeam,
        meta: {
            requiresAuth: true,
            manageOutstandingInvites: true,
            requiresEmailVerification: true
        }
    },

    {
        path: '/select-team',
        name: 'SelectTeam',
        component: SelectTeam,
        meta: {
            requiresAuth: true,
            manageOutstandingInvites: true,
            requiresEmailVerification: true
        }
    },

    {
        path: '/invites',
        name: 'OutstandingInvites',
        component: OutstandingInvites,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true
        }
    },

    {
        path: '/team/:teamId',
        name: 'ManageTeam',
        component: Team,
        meta: {
            requiresAuth: true,
            manageOutstandingInvites: true,
            requiresEmailVerification: true,
            requiresActiveTeam: true
        }
    },

    {
        path: '/team/:teamId/invites',
        name: 'TeamInvites',
        component: Invites,
        meta: {
            requiresAuth: true,
            manageOutstandingInvites: true,
            requiresEmailVerification: true,
            requiresActiveTeam: true
        }
    },
    {
        path: '/team',
        name: 'ActiveTeam',
        component: ActiveTeam,
        meta: {
            requiresAuth: true,
            manageOutstandingInvites: true,
            requiresEmailVerification: true,
            requiresActiveTeam: true
        }
    },
    {
        path: '/team/:teamId/seats',
        name: 'TeamSeats',
        component: TeamSeats,
        meta: {
            requiresAuth: true,
            manageOutstandingInvites: true,
            requiresEmailVerification: true,
            requiresActiveTeam: true
        }
    },


    {
        path: '/team/:teamId/settings',
        name: 'TeamSettings',
        component: TeamSettings,
        meta: {
            requiresAuth: true,
            manageOutstandingInvites: true,
            requiresEmailVerification: true,
            requiresActiveTeam: true
        }
    },

    {
        path: '/team/:teamId/invoices',
        name: 'TeamInvoices',
        component: TeamInvoices,
        meta: {
            requiresAuth: true,
            manageOutstandingInvites: true,
            requiresEmailVerification: true,
            requiresActiveTeam: true
        }
    },


    {
        path: '/planning-applications',
        name: 'Applications',
        component: Applications,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
            requiresSubscription: true
        }
    },
    {
        path: '/verify-email',
        name: 'VerifyEmail',
        component: VerifyEmail,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/subscription-required',
        name: 'SubscriptionRequired',
        component: SubscriptionRequired,
        meta: {
            requiresAuth: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
        }
    },
    {
        path: '/',
        name: 'LandMonitor',
        component: LandMonitor,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
            requiresSubscription: true
        }
    },
    {
        path: '/land-hawk-jobs',
        name: 'LandHawk',
        component: LandHawk,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
            requiresSubscription: true
        }
    },
    {
        path: '/epc-ratings',
        name: 'EPCRatings',
        component: EPCRatings,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
            requiresSubscription: true
        }
    },
    {
        path: '/land-hawk-jobs/create',
        name: 'LandHawkCreateJob',
        component: LandHawkCreateJob,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
            requiresSubscription: true
        }
    },
    {
        path: '/land-hawk-job/:jobId',
        name: 'LandHawkJob',
        component: LandHawkJob,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
            requiresSubscription: true
        }
    },

    {
        path: '/workflows',
        name: 'Workflows',
        component: Workflows,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
            requiresSubscription: true
        }
    },
    {
        path: '/workflows/create',
        name: 'WorkflowCreate',
        component: WorkflowCreate,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
            requiresSubscription: true
        }
    },
    {
        path: '/workflow/:workflowId',
        name: 'Workflow',
        component: Workflow,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
            requiresSubscription: true
        }
    },


    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/security',
        name: 'Security',
        component: Security,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/delete-account',
        name: 'DeleteAccount',
        component: DeleteAccount,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/alerts',
        name: 'Alerts',
        component: Alerts,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
            requiresSubscription: true
        }
    },
    {
        path: '/alert/:hash',
        name: 'AlertsRead',
        component: AlertsRead,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
            requiresSubscription: true
        }
    },
    {
        path: '/alerts/create',
        name: 'AlertsCreate',
        component: AlertsCreate,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
            requiresSubscription: true
        }
    },
    {
        path: '/planning-applications/watchlist',
        name: 'Watchlist',
        component: WatchList,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
            requiresSubscription: true
        }
    },

    {
        path: '/property-sales/civil-parishes',
        name: 'PropertyPricesCivilParishes',
        component: CivilParishes,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
            requiresSubscription: true
        }
    },
    {
        path: '/property-sales/communities',
        name: 'PropertyPricesCommunities',
        component: Communities,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
            requiresSubscription: true
        }
    },
    {
        path: '/market-tracker',
        name: 'PropertyMarketTrackerForSale',
        component: MarketTrackerForSale,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
            requiresSubscription: true
        }
    },

    {
        path: '/market-tracker/commercial-availability',
        name: 'PropertyMarketCommercialAvailabilitySearch',
        component: CommercialAvailabilitySearch,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
            requiresSubscription: true
        }
    },


    {
        path: '/market-tracker/rent',
        name: 'PropertyMarketTrackerForRent',
        component: MarketTrackerRental,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
            requiresSubscription: true
        }
    },
    {
        path: '/market-tracker/rental-yield',
        name: 'PropertyMarketTrackerForRentYield',
        component: MarketTrackerRentalYield,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
            requiresSubscription: true
        }
    },
    {
        path: '/property-sales/london-boroughs',
        name: 'PropertyPricesLondonBoroughs',
        component: LondonBoroughs,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
            requiresSubscription: true
        }
    },
    {
        path: '/property-sales',
        name: 'PropertySales',
        component: PropertyPricesSearch,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
            requiresSubscription: true
        }
    },
    {
        path: '/property-sales/districts',
        name: 'PropertyPricesDistricts',
        component: Districts,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
            requiresSubscription: true
        }
    },
    {
        path: '/property-sales/unitary-authorities',
        name: 'PropertyPricesUAs',
        component: UAs,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
            requiresSubscription: true
        }
    },
    {
        path: '/property-sales/:type/:id',
        name: 'Area',
        component: AreaPropertyPrices,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
            requiresSubscription: true
        }
    },

    {
        path: '/map',
        name: 'Map',
        component: ApplicationMap,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
            requiresSubscription: true
        }
    },
    {
        path: '/saved-searches',
        name: 'SavedSearches',
        component: SavedSearches,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
            requiresSubscription: true
        }
    },
    {
        path: '/reports',
        name: 'Reports',
        component: Reports,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
            requiresSubscription: true
        }
    },
    {
        path: '/planning-application/:applicationId',
        name: 'Application',
        component: Application,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
            requiresSubscription: true
        }
    },
    {
        path: '/planning-applications/search',
        name: 'ApplicationSearch',
        component: ApplicationSearch,
        meta: {
            requiresAuth: true,
            requiresEmailVerification: true,
            manageOutstandingInvites: true,
            requiresActiveTeam: true,
            requiresSubscription: true
        }
    },
    {
        path: '/land-monitor-report/:inspireId',
        name: 'LandMonitorReportGenerate',
        component: LandMonitorReport
    },
    {
     path: '/ev-report',
        name: 'EVReport',
        component: EVReport
    },
    {
        path: '*',
        component: Error404,
        meta: {
            requiresAuth: false
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView();
    }
})

router.beforeEach((to, from, next) => {

    // Clear any notifications
    store._vm.$notify.closeAll();

    // $alert

    store.commit('auth/setBreadcrumbs', []);

    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
      //  requiresEmailVerification = to.matched.some(record => record.meta.requiresEmailVerification),
      //  manageOutstandingInvites = to.matched.some(record => record.meta.manageOutstandingInvites),
     //   requiresActiveTeam = to.matched.some(record => record.meta.requiresActiveTeam),
      //  requiresSubscription = to.matched.some(record => record.meta.requiresSubscription);


    /*
      if (requiresAuth && !store.getters['auth/isAuthenticated']) {
        next({
            path: '/login',
            query: {redirect: to.fullPath}
        })
    } else if (!store.getters['auth/emailVerified'] && requiresEmailVerification) {
        next({
            path: '/verify-email'
        })
    } else if (store.getters['auth/hasOutstandingInvites'] && manageOutstandingInvites) {
        console.log('Has invites', store.getters['auth/hasOutstandingInvites'])
        next({
            path: '/invites'
        })
    } else if (!store.getters['auth/hasActiveTeam'] && requiresActiveTeam) {
        console.log('hasActiveTeam', store.getters['auth/hasActiveTeam'])
        next({
            path: '/select-team'
        })
    } else if (!store.getters['auth/hasSubscription'] && requiresSubscription) {
        next({
            path: '/subscription-required'
        })
    } else {
        next();
    }
     */

    if (requiresAuth && !store.getters['auth/isAuthenticated']) {
        next({
            path: '/login',
            query: {redirect: to.fullPath}
        })
    } else {
        next();
    }

})

export default router
