<template>
    <div class="bg-white overflow-hidden shadow-sm"
         :class="[paddingClass, customClass]">
            <div class="flex items-center">
                    <slot name="icon">
                    </slot>
                <div class="ml-5 w-0 flex-1" v-loading="loading">
                    <dl>
                        <dt
                                :class="labelSize"
                                class="leading-5 font-medium text-gray-500 truncate">
                            {{ title }}
                        </dt>
                        <dd class="flex items-baseline" >
                            <div class=" leading-8 font-semibold text-gray-900" :class="fontSize">
                               {{ prefix }}{{ value ? value.toLocaleString() : 0 }}
                                <span class="text-xs text-gray-500" v-if="suffix.length > 0">{{ suffix }}</span>
                            </div>
                        </dd>
                    </dl>
                </div>
            </div>
    </div>
</template>

<script>
    export default {
        name: "StatBox",
        props: {
            loading: {
              type: Boolean,
              default: true
            },
            value: {
              type: [Number, String],
              default(){
                  return 0;
              }
            },
            labelSize: {
              type: String,
              default: 'text-sm'
            },
            fontSize: {
              type: String,
              default: 'text-2xl'
            },
            paddingClass: {
              type: String,
              default: 'px-4 py-5 sm:p-6'
            },
            customClass: {
                type: String,
                default: ''
            },
            title: {
                type: String,
                default: ''
            },
            description: {
                type: String,
                default: ''
            },
            prefix: {
                type: String,
                default: ''
            },
            suffix: {
                type: String,
                default: ''
            },
        }
    }
</script>

<style scoped>

</style>