<template>
    <span class="el--tenure-tag p-1 select-none rounded" :class="style">
        {{ value }}
    </span>
</template>

<script>
    export default {
        name: "PrettyTenure",
        props: {
            value: {
                type: String,
                default(){
                    return 'FREEHOLD'
                }
            }
        },
        computed: {
            style(){
                return ['Freehold', 'FREEHOLD'].includes(this.value) ? 'bg-green-300 text-green-600 border border-green-600' : 'bg-blue-300 text-blue-600 border border-blue-600'
            }
        }
    }
</script>

<style scoped>
    .el--tenure-tag {
        font-size: 12px;
    }
</style>