<template>
    <div class="el-block--property-details-overlay">
        <!--- Header --->
        <div class="el-block--detail-menu w-full p-3 bg-blue-900 h-36">

            <div class="columns">
                <div class="column w-full">
                    <h1 class="font-semibold h-12 p-1 overflow-hidden text-white">
                        {{ getTitle }}
                    </h1>
                </div>
                <div class="column flex w-1/4 items-center">
                    <el-tooltip content="Share Title Number" placement="top">
                <span v-if="value && value.title_number"
                      @click="$emit('share', value.title_number)"
                      class="text-md text-white cursor-pointer">
                    <i class="el-icon-share"></i>
                   Share
                </span>
                    </el-tooltip>

                </div>
                <div class="column ml-auto w-3/5">
                    <ownership-report-select
                            v-model="activeTab"
                            :loading="loading_remote"
                            :is-point="isPoint"
                            :planning="planning"
                            :listed-buildings="value.listed_buildings"
                            :environment="environment"
                            :epcs="value.epcs"
                            :substations="sub_stations"
                            :properties="value.properties"
                            :known-listings="value.known_listings"
                            :sale-history="sale_history.data"
                            :topography="topography"
                            :local-pricing="local_property_pricing"
                            :density-averages="density.averages ? density.averages : {}"
                            :local-pricing-averages="local_property_pricing.averages ? local_property_pricing.averages : {}"
                    />
                </div>
            </div>
        </div>

        <div class="absolute bg-gray-900 w-full el--select-site p-2"
             v-if="value.area.sites.length > 1 && activeViewIsSiteSpecific">
            <el-select
                    class="w-full"
                    size="medium"
                    filterable
                    autocomplete="off"
                    :popper-append-to-body="false"
                    v-model="activeSite" placeholder="Select Site...">
                <el-option
                        v-for="item in getSites"
                        :key="item.id"
                        :label="item.label"
                        :value="item.id">
                    <div class="flex text-xs w-full">
                        <span class="pr-2 max-w-sm truncate mr-auto">{{ item.label }}</span>
                        <span class="text-gray-500">{{ item.acres.toLocaleString() }} ac</span>
                    </div>
                </el-option>
            </el-select>
        </div>


        <div class="el-block--scroll-detail" :class="{
       'pt-20': value.polygons.length > 1 && activeViewIsSiteSpecific
        }">


            <ownership-overview
                    v-if="showOwnership"
                    :show-snap-to="showSnapTo"
                    :show-find-properties="showFindProperties"
                    @snap="(bounds)=>{ $emit('snap', bounds)}"
                    @open-corporate-ownership="(hash)=>{ $emit('open-corporate-ownership', hash) }"
                    v-model="value"
            />

            <ownership-street-view
                    v-if="showStreetView"
                    v-model="getActiveSiteCenter"
            />


            <ownership-properties
                    v-if="showProperties"
                    v-model="value.properties"
                    :active-site-id="activeSite"
                    :bbox="activeBBOX"
            />


            <ownership-use-classes
                    v-if="showUseClasses"
                    :active-site-id="activeSite"
                    v-model="value.properties"
            />


            <ownership-business-rates
                    v-if="showBusinessRates"
                    :id="value.site_id"
            />


            <ownership-nearest-sub-stations
                    v-if="showNearestSubStations"
                    v-model="sub_stations"
                    :loading="loading_remote"
            />

            <ownership-planning-applications
                    v-if="showPlanning"
                    v-model="planning"
                    :active-site-id="activeSite"
            />

            <ownership-listed-buildings-component
                    v-if="showListedBuildings"
                    v-model="value.listed_buildings"
                    :bbox="activeBBOX"
                    :active-site-id="activeSite"
            />


            <ownership-property-density-component
                    v-if="showResidentialPropertyDensity"
                    title="Residential Property Density"
                    v-model="density"
                    view-type="residential"
                    :loading="loading_remote"
                    :active-site-id="activeSite"
            />


            <ownership-property-density-component
                    v-if="showDetachedSemiDetachedPropertyDensity"
                    title="Detached/Semi-detached Property Density"
                    view-type="detached_semi_detached"
                    v-model="density"
                    :loading="loading_remote"
                    :active-site-id="activeSite"
            />

            <ownership-property-density-component
                    v-if="showSocialRentalsDensity"
                    title="Social Rental Density"
                    view-type="social_rentals"
                    v-model="density"
                    :loading="loading_remote"
                    :active-site-id="activeSite"
            />
            <ownership-property-density-component
                    v-if="showOwnerOccupiedDensity"
                    title="Owner-occupied Density"
                    view-type="owner_occupied"
                    v-model="density"
                    :loading="loading_remote"
                    :active-site-id="activeSite"
            />

            <ownership-property-density-component
                    v-if="showPrivateRentalsDensity"
                    title="Private Rental Density"
                    view-type="private_rentals"
                    v-model="density"
                    :loading="loading_remote"
                    :active-site-id="activeSite"
            />

            <ownership-property-density-component
                    v-if="showOfficesPropertyDensity"
                    title="Office Density"
                    view-type="offices"
                    v-model="density"
                    :loading="loading_remote"
                    :active-site-id="activeSite"
            />

            <ownership-property-density-component
                    v-if="showHMOsDensity"
                    title="Houses of Multiple Occupation Density"
                    view-type="hmos"
                    v-model="density"
                    :loading="loading_remote"
                    :active-site-id="activeSite"
            />

            <ownership-property-density-component
                    v-if="showTerracedDensity"
                    title="Terraced Housing Density"
                    view-type="terraced_housing"
                    v-model="density"
                    :loading="loading_remote"
                    :active-site-id="activeSite"
            />

            <ownership-property-density-component
                    v-if="showFlatsDensity"
                    title="Flats/Maisonette Density"
                    view-type="flats_maisonette"
                    v-model="density"
                    :loading="loading_remote"
                    :active-site-id="activeSite"
            />

            <ownership-property-density-component
                    v-if="showRetailLeisureDensity"
                    title="Retail & Leisure Density"
                    view-type="retail_leisure_dual_use"
                    v-model="density"
                    :loading="loading_remote"
                    :active-site-id="activeSite"
            />


            <ownership-property-pricing-component
                    v-if="showRetailLeisurePricing"
                    title="Retail & Leisure"
                    view-type="retail_leisure_dual_use"
                    v-model="local_property_pricing"
                    :loading="loading_remote"
            />

            <ownership-property-pricing-component
                    v-if="showFlatsPricing"
                    title="Flats & Maisonettes"
                    view-type="flats"
                    v-model="local_property_pricing"
                    :loading="loading_remote"
            />

            <ownership-property-pricing-component
                    v-if="showResidentialPricing"
                    title="Residential"
                    view-type="residential"
                    v-model="local_property_pricing"
                    :loading="loading_remote"
            />

            <ownership-property-pricing-component
                    v-if="showNonResidentialPricing"
                    title="Non-Residential"
                    view-type="non_residential"
                    v-model="local_property_pricing"
                    :loading="loading_remote"
            />

            <ownership-property-pricing-component
                    v-if="showOfficesPricing"
                    title="Offices"
                    view-type="offices"
                    v-model="local_property_pricing"
                    :loading="loading_remote"
            />

            <ownership-property-pricing-component
                    v-if="showDetachedPricing"
                    title="Detached"
                    view-type="detached"
                    v-model="local_property_pricing"
                    :loading="loading_remote"
            />

            <ownership-property-pricing-component
                    v-if="showSemiDetachedPricing"
                    title="Semi-detached"
                    view-type="semi_detached"
                    v-model="local_property_pricing"
                    :loading="loading_remote"
            />

            <ownership-property-pricing-component
                    v-if="showTerracedPricing"
                    title="Terraced"
                    view-type="terraced"
                    v-model="local_property_pricing"
                    :loading="loading_remote"
            />

            <ownership-property-pricing-component
                    v-if="showFlatsPricing"
                    title="Flats/Maisonettes"
                    view-type="flats"
                    v-model="local_property_pricing"
                    :loading="loading_remote"
            />



            <div v-if="showNeighborCompare">
                <el-button @click="$emit('compare', getStreets[0].usrn)">Launch</el-button>
            </div>

            <ownership-topography-overview
                    v-if="showTopography"
                    v-model="topography"
                    :loading="loading_remote"
                    :active-site-id="activeSite"
            />

            <ownership-topography-buildings
                    v-if="showBuildingsSiteArea"
                    v-model="topography"
                    :loading="loading_remote"
                    :active-site-id="activeSite"
            />

            <ownership-topography-site-areas
                    v-if="showTopographySites"
                    v-model="topography"
                    :loading="loading_remote"
                    :active-site-id="activeSite"
            />

            <ownership-leaseholders
                    v-if="showLeaseholders"
                    v-model="leaseholders"
                    :active-site-id="activeSite"
                    :loading="false"
            />

            <ownership-epc
                    v-if="showEPC"
                    :active-site-id="activeSite"
                    v-model="value.epcs"
                    :loading="false"
            />

            <ownership-known-listings
                    v-if="showKnownListings"
                    :active-site-id="activeSite"
                    v-model="value.known_listings"
            />


            <ownership-local-schools
                    v-if="showLocalSchools"
                    :id="value.site_id"
            />

            <ownership-mobile-broadband-coverage
                    v-if="showMobileBroadband"
                    :id="value.site_id"
            />

            <ownership-crime-component
                    v-if="showCrime"
                    :id="value.site_id"
            />

            <ownership-traffic-analysis
                    v-if="showTrafficAnalysis"
                    :id="value.site_id"
            />


            <ownership-administrative-details
                    v-if="showAdministrative"
                    :administrative-area="value.local_authority && value.local_authority.administrative_area ? value.local_authority.administrative_area : {}"
                    :demographics="value.local_authority && value.local_authority.demographics ? value.local_authority.demographics : {}"
                    :deprivation="value.local_authority && value.local_authority.deprivation_earnings ? value.local_authority.deprivation_earnings : {}"
            />

            <ownership-transport-links-component
                    v-if="showTransport"
                    v-model="nearby.data.transport"
                    :loading="loading_remote"
            />

            <ownership-population-component
                    v-if="showPopulation"
                    v-model="nearby.data.population"
                    :loading="loading_remote"
            />


            <ownership-environmental-coverage
                    v-if="showEnvironment"
                    :active-site-id="activeSite"
                    :loading="loading_remote"
                    v-model="environment"
            />

            <ownership-flooding-risk
                    v-if="showFloodingRisk"
                    :active-site-id="activeSite"
                    :loading="loading_remote"
                    v-model="environment"
            />


            <ownership-sale-history
                    v-if="showSaleHistory"
                    v-model="sale_history.data"
                    :stats="sale_history.stats"
                    :loading="loading_remote"
                    :active-site-id="activeSite"
            />


            <ownership-sold-nearby
                    v-if="showSoldNearby"
                    v-model="nearby.data.sold_nearby"
                    @uprn="(val) => $emit('uprn', val)"
                    :loading="loading_remote"
            />

            <ownership-listed-buildings-nearby-component
                    v-if="showListedBuildingsNearby"
                    v-model="nearby.data.nearby_listed_buildings"
                    :loading="loading_remote"
            />

        </div>

        <div class="el-block--detail-actions p-2 flex w-full bg-gray-900">
            <el-button
                    class="w-full el-button--add-to-workflow"
                    icon="el-icon-plus"
                    v-if="showAddToWorkflow"
                    type="success"
                    @click="workflow.confirmShow = true"
            >
                Add to Workflow
            </el-button>
            <el-button
                    class="w-full el-button--add-to-workflow"
                    icon="el-icon-location"
                    v-if="showViewOnMap"
                    @click="showOnMap"
                    type="success"
            >
                Show on Land Monitor
            </el-button>
            <el-button
                    type="danger"
                    @click="$emit('close')"
                    icon="el-icon-close"
                    class="w-full">
                Close
            </el-button>
        </div>


        <el-dialog
                title="Add to Workflow"
                :append-to-body="true"
                :close-on-press-escape="!workflow.addingItem"
                :close-on-click-modal="!workflow.addingItem"
                :show-close="!workflow.addingItem"
                :visible.sync="workflow.confirmShow"
                width="30%">

            <div v-loading="workflow.loadingExisting">
                <div class="flex flex-wrap pb-4 w-full" v-if="workflow.existing.length > 0">
                    <div class="w-full flex-none">
                        <el-radio-group
                                :disabled="workflow.addingItem"
                                v-model="workflow.type">
                            <el-radio label="existing">Existing</el-radio>
                            <el-radio label="new">Create New</el-radio>
                        </el-radio-group>
                    </div>
                </div>

                <div class="flex flex-wrap pb-4 w-full">
                    <div class="w-full flex-none" v-if="workflow.type === 'existing' && workflow.existing.length > 0">
                        <el-select
                                class="w-full"
                                filterable
                                :disabled="workflow.addingItem"
                                placeholder="Select Existing Workflow..."
                                v-model="workflow.selected"
                        >
                            <el-option
                                    :key="k"
                                    :value="i.id"
                                    :label="i.name"
                                    v-for="(i, k) of workflow.existing"></el-option>
                        </el-select>
                    </div>
                    <div class="w-full flex-none" v-if="workflow.type === 'new'">
                        <el-input
                                :disabled="workflow.addingItem"
                                v-model="workflow.name"
                                placeholder="Enter a name for your new workflow"
                                class="w-full"/>
                    </div>
                </div>


                <div class="text-right">
                    <el-button
                            :disabled="workflow.addingItem"
                            :loading="workflow.addingItem"
                            @click="addToWorkflow"
                            type="success" icon="el-icon-plus">
                        Add to Workflow
                    </el-button>
                </div>
            </div>

        </el-dialog>

    </div>

</template>

<script>
    import OwnershipListedBuildingsComponent from "./OwnershipLayerComponents/OwnershipListedBuildingsComponent";
    import OwnershipOverview from "./OwnershipLayerComponents/OwnershipOverview";
    import OwnershipProperties from "./OwnershipLayerComponents/OwnershipProperties";
    import OwnershipSaleHistory from "./OwnershipLayerComponents/OwnershipSaleHistory";
    import OwnershipLeaseholders from "./OwnershipLayerComponents/OwnershipLeaseholders";
    import OwnershipTopographyOverview from "./OwnershipLayerComponents/Topography/OwnershipTopographyOverview";
    import OwnershipEnvironmentalCoverage from "./OwnershipLayerComponents/OwnershipEnvironmentalCoverage";
    import OwnershipCrimeComponent from "./OwnershipLayerComponents/OwnershipCrimeComponent";
    import OwnershipPopulationComponent from "./OwnershipLayerComponents/OwnershipPopulationComponent";
    import OwnershipAdministrativeDetails from "./OwnershipLayerComponents/OwnershipAdministrativeDetails";
    import OwnershipLocalSchools from "./OwnershipLayerComponents/OwnershipLocalSchools";
    import OwnershipMobileBroadbandCoverage from "./OwnershipLayerComponents/OwnershipMobileBroadbandCoverage";
    import OwnershipTrafficAnalysis from "./OwnershipLayerComponents/OwnershipTrafficAnalysis";
    import OwnershipEpc from "./OwnershipLayerComponents/OwnershipEpc";
    import OwnershipBusinessRates from "./OwnershipLayerComponents/OwnershipBusinessRates";
    import OwnershipPlanningApplications from "./OwnershipLayerComponents/OwnershipPlanningApplications";
    //import OwnershipBuildingsAndSiteAreaComponent from "./OwnershipLayerComponents/OwnershipBuildingsAndSiteAreaComponent";
    import OwnershipStreetView from "./OwnershipLayerComponents/OwnershipStreetView";
    import OwnershipSoldNearby from "./OwnershipLayerComponents/OwnershipSoldNearby";
    import OwnershipUseClasses from "./OwnershipLayerComponents/OwnershipUseClasses";
    import OwnershipListedBuildingsNearbyComponent
        from "./OwnershipLayerComponents/OwnershipListedBuildingsNearbyComponent";
    import OwnershipKnownListings from "./OwnershipLayerComponents/OwnershipKnownListings";
    import OwnershipTopographyBuildings from "./OwnershipLayerComponents/Topography/OwnershipTopographyBuildings";
    import OwnershipTopographySiteAreas from "./OwnershipLayerComponents/Topography/OwnershipTopographySiteAreas";
    import OwnershipTransportLinksComponent from "./OwnershipLayerComponents/OwnershipTransportLinksComponent";
    import OwnershipFloodingRisk from "./OwnershipLayerComponents/OwnershipFloodingRisk";
    import OwnershipPropertyDensityComponent from "./OwnershipLayerComponents/OwnershipPropertyDensityComponent";
    import OwnershipPropertyPricingComponent from "./OwnershipLayerComponents/OwnershipPropertyPricingComponent";
    import OwnershipReportSelect from "./OwnershipReportSelect";
    import OwnershipNearestSubStations from "./OwnershipLayerComponents/OwnershipNearestSubStations";

    export default {
        name: "OwnershipLayerDetails",
        components: {
            OwnershipNearestSubStations,
            OwnershipReportSelect,
            OwnershipPropertyPricingComponent,
            OwnershipPropertyDensityComponent,
            OwnershipFloodingRisk,
            OwnershipTransportLinksComponent,
            OwnershipTopographySiteAreas,
            OwnershipTopographyBuildings,
            OwnershipKnownListings,
            OwnershipListedBuildingsNearbyComponent,
            OwnershipUseClasses,
            OwnershipSoldNearby,
            OwnershipStreetView,
            //OwnershipBuildingsAndSiteAreaComponent,
            OwnershipPlanningApplications,
            OwnershipBusinessRates,
            OwnershipEpc,
            OwnershipTrafficAnalysis,
            OwnershipMobileBroadbandCoverage,
            OwnershipLocalSchools,
            OwnershipAdministrativeDetails,
            OwnershipPopulationComponent,
            OwnershipCrimeComponent,
            OwnershipEnvironmentalCoverage,
            OwnershipTopographyOverview,
            OwnershipLeaseholders,
            OwnershipSaleHistory,
            OwnershipProperties,
            OwnershipOverview,
            OwnershipListedBuildingsComponent
        },
        props: {
            showSnapTo: {
                type: Boolean,
                default: true
            },
            showAddToWorkflow: {
                type: Boolean,
                default: true
            },
            showViewOnMap: {
                type: Boolean,
                default: false
            },
            showFindProperties: {
                type: Boolean,
                default: true
            },
            value: {
                type: Object,
                default() {
                    return null
                }
            }
        },
        mounted() {
            this.fetchRequests();
            //this.fetchTopography();
            this.$store.commit('auth/setBreadcrumbs', [
                {
                    title: this.getTitle
                }
            ]);
        },
        methods: {
            showOnMap() {
                this.$router.push('/?title-number=' + this.value.title_number)
            },
            setTab(value) {
                this.activeTab = value;
            },

            fetchRequests() {
                const vm = this;

                let id = this.value && this.value.site_id ? this.value.site_id : null;
                if (!id) {
                    return;
                }

                vm.loading_remote = true;

                this.$http.all([
                    this.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/ownership/' + id + '/environment'),
                    this.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/ownership/' + id + '/topography'),
                    this.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/ownership/' + id + '/local-area'),
                    this.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/ownership/' + id + '/sale-history'),
                    this.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/ownership/' + id + '/property-density'),
                    this.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/ownership/' + id + '/planning'),
                    this.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/ownership/' + id + '/local-property-pricing'),
                    this.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/ownership/' + id + '/nearest-sub-stations'),
                ]).then(([environment, topography, localArea, saleHistory, density, planning, localPropertyPricing, subStations]) => {

                    vm.loading_remote = false;

                    vm.environment = environment.data.data;
                    vm.topography = topography.data.data;
                    vm.density = density.data.data;
                    vm.planning = planning.data.data;
                    vm.sub_stations = subStations.data.data;
                    vm.local_property_pricing = localPropertyPricing.data.data;

                    vm.nearby.data.population = localArea.data.data.population;
                    vm.nearby.data.transport = localArea.data.data.travel;
                    vm.nearby.data.sold_nearby = localArea.data.data.nearby_sales;
                    vm.nearby.data.nearby_listed_buildings = localArea.data.data.nearby_listed_buildings;
                    vm.sale_history.data = saleHistory.data.data.sales;
                    vm.sale_history.stats = saleHistory.data.data.stats;

                }).catch(errors => {
                    // react on errors.
                    console.warn(errors)
                })
            },

            addToWorkflow() {
                const vm = this;
                vm.workflow.addingItem = true;

                if (vm.workflow.type === 'new') {
                    return vm.$store.dispatch('workflow/create', {
                        name: vm.workflow.name,
                        private: vm.workflow.private
                    }).then((response) => {
                        return vm.addItemToWorkflow(response.data.data.id);
                    }).catch(() => {
                        vm.workflow.addingItem = false;
                    })
                }

                if (vm.workflow.type !== 'new') {
                    return vm.addItemToWorkflow(this.workflow.selected);
                }

            },
            addItemToWorkflow(workflowId) {
                const vm = this;

                vm.workflow.addingItem = true;

                return vm.$store.dispatch('workflowItem/create', {
                    workflowId: workflowId,
                    type: this.value.tenure,
                    reference: this.value.title_number,
                    description: this.value.address
                }).then(() => {
                    vm.workflow.addingItem = false;
                    vm.workflow.confirmShow = false;

                    vm.$notify({
                        position: 'top-right',
                        title: 'Added to Workflow',
                        message: 'Property item added to workflow successfully.',
                        type: 'success',
                        offset: 140
                    });

                }).catch(() => {
                    vm.workflow.addingItem = false;
                })


                //  vm.workflow.addingItem = false;

            },
            fetchWorkflows() {
                const vm = this;
                vm.workflow.loadingExisting = true;
                vm.workflow.name = '';
                return vm.$store.dispatch('workflow/index').then((response) => {
                    vm.workflow.existing = response.data.data.results;
                    vm.workflow.loadingExisting = false;
                    vm.workflow.type = vm.workflow.existing.length > 0 ? 'existing' : 'new';

                    if (response.data.data.latest) {
                        vm.workflow.selected = response.data.data.latest;
                    }

                }).catch(() => {
                    vm.workflow.type = 'new';
                    vm.workflow.selected = null;
                    vm.workflow.existing = [];
                    vm.workflow.loadingExisting = false;
                })
            }
        },
        computed: {
            getStreets() {
                return this.value.streets;
            },
            activeViewIsSiteSpecific() {
                return ['properties', 'leaseholders', 'topography',
                    'use-classes', 'listed', 'known-listings',
                    'environment', 'buildings', 'site-areas', 'flood-risk',
                    'planning-applications', 'epc', 'business-rates', 'street-view'].indexOf(this.activeTab) > -1
            },
            getActiveSiteCenter() {
                if (this.activeSite) {
                    let find = this.value.area.sites.find((i) => i.site_id === this.activeSite);
                    if (find && find.center) {
                        return find.center;
                    }
                }
                return this.value.area.total.center;
            },
            activeBBOX() {
                if (this.activeSite) {
                    let find = this.value.area.sites.find((i) => i.site_id === this.activeSite);
                    if (find && find.bbox) {
                        return find.bbox;
                    }
                }
                return this.value.area.total.bbox;
            },
            leaseholders() {
                return this.value.properties.filter((i) => i.leasehold_details);
            },
            getSites() {
                let array = this.value.area.sites.map((i) => {
                    return {
                        id: i.site_id,
                        label: i.address,
                        acres: i.area.acres
                    }
                });

                array.unshift({
                    id: null,
                    label: 'All Sites',
                    acres: this.value.area.total.area.acres
                });

                return array;
            },
            isPoint() {
                return this.value.polygons && this.value.polygons.filter((item) => item.geometry.type === 'Point').length === this.value.polygons.length;
            },
            getTitle() {
                if (this.value.address && this.value.address) {
                    return this.value.address;
                }
                return 'Land Monitor Property Details'
            },
            showResidentialPropertyDensity() {
                return this.value && this.value.site_id && this.activeTab === 'density-residential';
            },
            showDetachedSemiDetachedPropertyDensity() {
                return this.value && this.value.site_id && this.activeTab === 'density-detached-semi';
            },
            showOwnerOccupiedDensity() {
                return this.value && this.value.site_id && this.activeTab === 'density-owner-occupied';
            },
            showSocialRentalsDensity() {
                return this.value && this.value.site_id && this.activeTab === 'density-social-rentals';
            },
            showPrivateRentalsDensity() {
                return this.value && this.value.site_id && this.activeTab === 'density-private-rentals';
            },
            showOfficesPropertyDensity() {
                return this.value && this.value.site_id && this.activeTab === 'density-offices';
            },
            showHMOsDensity() {
                return this.value && this.value.site_id && this.activeTab === 'density-hmos';
            },
            showTerracedDensity() {
                return this.value && this.value.site_id && this.activeTab === 'density-terraced';
            },
            showFlatsDensity() {
                return this.value && this.value.site_id && this.activeTab === 'density-flats';
            },
            showRetailLeisureDensity() {
                return this.value && this.value.site_id && this.activeTab === 'density-retail-leisure';
            },
            showResidentialPricing() {
                return this.value && this.value.site_id && this.activeTab === 'pricing-residential';
            },
            showNonResidentialPricing() {
                return this.value && this.value.site_id && this.activeTab === 'pricing-non-residential';
            },
            showOfficesPricing() {
                return this.value && this.value.site_id && this.activeTab === 'pricing-offices';
            },
            showDetachedPricing() {
                return this.value && this.value.site_id && this.activeTab === 'pricing-detached';
            },
            showSemiDetachedPricing() {
                return this.value && this.value.site_id && this.activeTab === 'pricing-semi-detached';
            },
            showTerracedPricing() {
                return this.value && this.value.site_id && this.activeTab === 'pricing-terraced';
            },
            showFlatsPricing() {
                return this.value && this.value.site_id && this.activeTab === 'pricing-flats';
            },
            showRetailLeisurePricing() {
                return this.value && this.value.site_id && this.activeTab === 'pricing-retail-leisure';
            },
            showListedBuildings() {
                return this.value && this.value.site_id && this.activeTab === 'listed';
            },
            showCrime() {
                return this.value && this.value.site_id && this.activeTab === 'crime';
            },
            showStreetView() {
                return this.value && this.activeTab === 'street-view';
            },
            showProperties() {
                return this.value && this.value.properties && Array.isArray(this.value.properties) && this.activeTab === 'properties';
            },
            showUseClasses() {
                return this.value && this.value.properties && Array.isArray(this.value.properties) && this.activeTab === 'use-classes';
            },
            showEPC() {
                return this.value && this.value.epcs && this.activeTab === 'epc';
            },
            showKnownListings() {
                return this.value && this.value.known_listings && this.activeTab === 'known-listings';
            },
            showRegisteredBusinessess() {
                return this.value && this.value.site_id && this.activeTab === 'businesses';
            },
            showOwnership() {
                return this.value && this.value.site_id && this.activeTab === 'ownership';
            },
            showAdministrative() {
                return this.value && this.value.local_authority && this.activeTab === 'administrative';
            },
            showBuildingsSiteArea() {
                return this.value && this.value.site_id && this.activeTab === 'buildings';
            },
            showTopographySites() {
                return this.value && this.value.site_id && this.activeTab === 'site-areas';
            },
            showNeighborCompare() {
                return this.value && this.activeTab === 'neighbour-compare';
            },
            showPopulation() {
                return this.value && this.activeTab === 'population';
            },
            showTransport() {
                return this.value && this.activeTab === 'transport';
            },
            showPlanning() {
                return this.value && this.value.site_id && this.activeTab === 'planning-applications';
            },
            showBusinessRates() {
                return this.value && this.value.site_id && this.activeTab === 'business-rates';
            },
            showNearestSubStations(){
                return this.value && this.activeTab === 'sub-stations';
            },
            showMobileBroadband() {
                return this.value && this.value.site_id && this.activeTab === 'mobile-broadband';
            },
            showTrafficAnalysis() {
                return this.value && this.value.site_id && this.activeTab === 'traffic';
            },
            showEnvironment() {
                return this.value && this.value.site_id && this.activeTab === 'environment';
            },
            showFloodingRisk() {
                return this.value && this.value.site_id && this.activeTab === 'flood-risk';
            },
            showTopography() {
                return this.value && this.value.site_id && this.activeTab === 'topography';
            },
            showLocalSchools() {
                return this.value && this.value.site_id && this.activeTab === 'local-schools';
            },
            showSaleHistory() {
                return this.value && this.value.site_id && this.activeTab === 'sale-history';
            },
            showSoldNearby() {
                return this.value && this.activeTab === 'sold-nearby';
            },
            showListedBuildingsNearby() {
                return this.value && this.activeTab === 'nearby-listed-buildings';
            },
            showLeaseholders() {
                return this.value && this.activeTab === 'leaseholders';
            }
        },
        watch: {
            'workflow.confirmShow'() {
                this.fetchWorkflows();
            }
        },
        data() {
            return {
                loading_remote: true,
                nearby: {
                    data: {
                        population: [],
                        sold_nearby: [],
                        transport: []
                    }
                },
                sale_history: {
                    data: [],
                    stats: {}
                },
                sub_stations: {},
                environment: {},
                density: {},
                planning: [],
                local_property_pricing: {},
                topography: {},
                workflow: {
                    type: 'existing',
                    name: '',
                    selected: null,
                    private: true,
                    existing: [],
                    loadingExisting: false,
                    addingItem: false,
                    confirmShow: false
                },
                activeTab: 'ownership',
                activeSite: null
            }
        }
    }
</script>

<style scoped>
    .el--select-site {
        z-index: 9001 !important;
    }

    .el-dialog__body {
        padding: 1rem 1.3rem;
    }
</style>