<template>

    <page>


        <template slot="content">

        <div
                :class="{
            'h-screen': !$store.getters['auth/isAuthenticated'],
            'mt-12 pt-12': $store.getters['auth/isAuthenticated']
            }"
                class="w-full text-center">
            <div class="mx-auto flex items-center h-full justify-center">
                <div class="columns w-full lg:w-auto">
                    <div class="column w-full">
                        <h2 class="leading-none text-4xl font-semibold mb-3">Sorry, nothing here....</h2>
                        <div class="m-8 mx-auto">
                           <missing />
                        </div>
                        <div class="leading-loose text-sm">
                            <p class="mb-1">Looks like we can't find that page. </p>
                            <p class="mb-1">Try going back to the previous page or contact us if you are still having problems.</p>

                            <p class="mt-4">
                                <el-button
                                        @click="$store.dispatch('auth/openSupport')"
                                        type="info">Contact Support </el-button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </template>

    </page>
</template>

<script>
    import Missing from "../components/Missing";

    export default {
        name: "Error404",
        components: {
            Missing
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.$store.commit('auth/setBreadcrumbs', [
                    {
                        title: 'Page Not Found'
                    }
                ]);
            })
        }
    }
</script>

<style scoped>

</style>