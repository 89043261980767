<template>

        <page v-loading="loading" v-cloak>
            <template slot="header">
                <page-header :title="getApplicationTitle">
                    <template v-slot:tabs>
                        <planning-applications-nav />
                    </template>

                    <template v-slot:actions>
                        <div class="flex">
                            <el-button
                                    size="mini"
                                    @click="toggleWatchlist( application.id)"
                                    :disabled="$store.state.watchlist.running"
                                    :loading="$store.state.watchlist.running"
                                    class="w-full"
                                    :type=" application.watching ? 'warning' : 'success'"
                                    :icon=" application.watching ? 'el-icon-delete' : 'el-icon-star-off'">
                                {{ $store.state.watchlist.running ? 'Updating...' :
                                 application.watching ? 'Un-watch' : 'Watch' }}
                            </el-button>
                            <el-button
                                    :disabled="! application.url"
                                    @click="openUrl( application.url)"
                                    size="mini" type="info" icon="el-icon-view">
                                View
                            </el-button>
                        </div>
                    </template>
                </page-header>
            </template>
            <template slot="content">

                <!--
                <div class="flex bg-white  mb-6 h-12 mx-0">

                    <a
                            :key="key"
                            v-for="(item, key) of items"
                            :class="{
                            'border-blue-800 text-gray-800': item.tab === activeTab,
                         'hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300': item.tab !== activeTab,
                            }"
                            @click="()=>{ activeTab = item.tab; }"
                            class="ml-8 cursor-pointer inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500  transition duration-150 ease-in-out">
                        {{ item.label }}
                    </a>

                </div>
                -->


                <div class="columns" v-if="activeTab === 'map'">

                    <div class="column w-full" v-if="!$store.getters['applications/hasSiteCoords']">
                        <empty-state />
                    </div>

                    <div class="column w-full" v-if="$store.getters['applications/hasSiteCoords']">
                        <dashboard-widget
                                title="Application Overview"
                                padding-class="''">
                            <slot>
                                <location-map/>
                            </slot>
                        </dashboard-widget>
                    </div>

                </div>

                <div class="columns" v-if="activeTab === 'property-sales'">
                    <div class="column w-full" v-if="$store.getters['applications/hasSiteCoords']">
                        <application-house-prices
                                class-name="w-full"
                                :hash=" application.id"
                        />
                    </div>
                    <div class="column w-full" v-if="!$store.getters['applications/hasSiteCoords']">
                        <empty-state />
                    </div>
                </div>

                <div class="columns" v-if="activeTab === 'ods'">
                    <div class="column w-full" v-if="!$store.getters['applications/hasSiteCoords']">
                        <empty-state />
                    </div>
                    <div class="column w-full" v-if="$store.getters['applications/hasSiteCoords']">
                        <dashboard-widget
                                title="Application Overview"
                                padding-class="''">
                            <slot>


                                <ordinance-survey-component
                                        :lat=" application.site_address_coords.lat"
                                        :lon=" application.site_address_coords.lon"
                                        :show-search="false"
                                />
                            </slot>
                        </dashboard-widget>
                    </div>
                </div>

                <div class="columns" v-show="activeTab === 'details'">
                    <div class="column w-full lg:w-2/3">
                        <div class="columns">
                            <div class="column w-full">
                                <dashboard-widget title="Application Overview"
                                                  padding-class="''">
                                    <slot>
                                        <application-details/>
                                    </slot>
                                </dashboard-widget>
                            </div>
                        </div>
                    </div>
                    <div class="column w-full lg:w-1/3">
                        <div class="columns">
                            <div class="column w-full">
                                <dashboard-widget title="Agent Details"
                                                  padding-class="''">
                                    <slot>
                                        <agent-details/>
                                    </slot>
                                </dashboard-widget>
                            </div>

                            <div class="column w-full">
                                <dashboard-widget title="Timeline">
                                    <slot>
                                        <application-timeline/>
                                    </slot>
                                </dashboard-widget>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="columns" v-if="activeTab === 'house-prices'">
                    <div class="column w-full" v-if="!$store.getters['applications/hasSiteCoords']">
                        <empty-state />
                    </div>
                    <div class="column w-full" v-if="!$store.getters['applications/hasSiteCoords']">
                        <application-house-prices :hash="$route.params.applicationId"/>
                    </div>
                </div>

                <div class="columns" v-if="activeTab === 'local-area'">
                    <div class="column w-full" v-if="!$store.getters['applications/hasSiteCoords']">
                        <empty-state />
                    </div>
                    <div class="column w-full" v-if="$store.getters['applications/hasSiteCoords']">
                        <div class="columns">
                            <div class="column w-full lg:w-1/2">
                                <dashboard-widget title="Nearest Train Stations">
                                    <slot>
                                        <el-table
                                                class="el-table--sale-history"
                                                :data="$store.getters['applications/getNearest']('stations')"
                                                stripe
                                                style="width: 100%">
                                            <el-table-column
                                                    prop="common_name"
                                                    label="Name">
                                            </el-table-column>
                                            <el-table-column
                                                    prop="distance_miles"
                                                    align="right"
                                                    label="Distance (Miles)">
                                            </el-table-column>
                                        </el-table>
                                    </slot>
                                </dashboard-widget>
                            </div>
                            <div class="column w-full lg:w-1/2">
                                <dashboard-widget title="Nearest Airports">
                                    <slot>
                                        <el-table
                                                class="el-table--sale-history"
                                                :data="$store.getters['applications/getNearest']('airports')"
                                                stripe
                                                style="width: 100%">
                                            <el-table-column
                                                    prop="common_name"
                                                    label="Name">
                                            </el-table-column>
                                            <el-table-column
                                                    prop="distance_miles"
                                                    align="right"
                                                    label="Distance (Miles)">
                                            </el-table-column>
                                        </el-table>
                                    </slot>
                                </dashboard-widget>
                            </div>
                            <div class="column w-full lg:w-1/2">
                                <dashboard-widget title="Nearest Coach Stations">
                                    <slot>
                                        <el-table
                                                class="el-table--sale-history"
                                                :data="$store.getters['applications/getNearest']('coaches')"
                                                stripe
                                                style="width: 100%">
                                            <el-table-column
                                                    prop="common_name"
                                                    label="Name">
                                            </el-table-column>
                                            <el-table-column
                                                    prop="distance_miles"
                                                    align="right"
                                                    label="Distance (Miles)">
                                            </el-table-column>
                                        </el-table>
                                    </slot>
                                </dashboard-widget>
                            </div>
                            <div class="column w-full lg:w-1/2">
                                <dashboard-widget title="Nearest Ferries">
                                    <slot>
                                        <el-table
                                                class="el-table--sale-history"
                                                :data="$store.getters['applications/getNearest']('ferries')"
                                                stripe
                                                style="width: 100%">
                                            <el-table-column
                                                    prop="common_name"
                                                    label="Name">
                                            </el-table-column>
                                            <el-table-column
                                                    prop="distance_miles"
                                                    align="right"
                                                    label="Distance (Miles)">
                                            </el-table-column>
                                        </el-table>
                                    </slot>
                                </dashboard-widget>
                            </div>
                        </div>
                    </div>
                </div>

            </template>
        </page>

</template>

<script>

    import PageHeader from "../components/incs/PageHeader";
    import Page from "./Page";

    import ApplicationDetails from "../components/incs/Application/ApplicationDetails";
    import AgentDetails from "../components/incs/Application/AgentDetails";
    import ApplicationTimeline from "../components/incs/Application/ApplicationTimeline";
    import LocationMap from "../components/incs/Application/LocationMap";
    import ApplicationHousePrices from "../components/incs/Application/ApplicationHousePrices";
    import OrdinanceSurveyComponent from "../components/OrdinanceSurveyComponent";
    import EmptyState from "../components/EmptyState";
    import PlanningApplicationsNav from "./Applications/PlanningApplicationsNav";

    export default {
        name: 'Application',
        components: {
            PlanningApplicationsNav,
            EmptyState,
            OrdinanceSurveyComponent,
            ApplicationHousePrices,
            LocationMap, ApplicationTimeline, AgentDetails, ApplicationDetails, Page, PageHeader
        },
        computed: {
            getApplicationTitle() {
                return [
                    this.$store.state.applications.application.reference,
                    this.$store.state.applications.application.proposal,
                ].filter((item) => {
                    return item && item.length > 0
                }).join(' - ')
            }
        },
        methods: {
            openUrl(url) {
                if (url) {
                    window.open(url);
                }
            },
            fetchNearby() {
                let vm = this,
                    bounds = this.$refs.odsMap.mapObject.getBounds();
                this.$store.dispatch('ods/getOdsNearby', {
                    sw_lat: bounds._southWest.lat,
                    sw_lng: bounds._southWest.lng,
                    ne_lat: bounds._northEast.lat,
                    ne_lng: bounds._northEast.lng
                }).then(() => {
                    vm.$notify({
                        position: 'top-right',
                        offset: 140,
                        type: vm.$store.getters['ods/getGeoObjects'].length > 0 ? 'success' : 'warning',
                        title: (vm.$store.getters['ods/getGeoObjects'].length > 0 ? vm.$store.getters['ods/getGeoObjects'].length.toLocaleString() : 'No') + ' objects found',
                        message: 'Application removed from watchlist',
                    });
                })
            },
            getOdsProperty(el) {
                let items = Object.values(el.latlng);
                this.$store.dispatch('ods/getOdsProperty', {
                    lat: items[0],
                    lon: items[1]
                })
            },
            onMapReady() {
                console.log('Ready')
                console.log(this.$refs.odsMap.mapObject.getBounds())
            },


            toggleWatchlist(hash) {

                let vm = this;
                if (vm.application.watching) {
                    this.$store.dispatch('watchlist/remove', {
                        hash: hash
                    }).then(() => {
                        vm.application.watching = false;
                        vm.$notify({
                            position: 'top-right',
                            offset: 140,
                            type: 'success',
                            title: 'Application Removed',
                            message: 'Application removed from watchlist',
                        });
                    })
                } else {
                    this.$store.dispatch('watchlist/add', {
                        hash: hash
                    }).then(() => {
                        vm.application.watching = true;
                        vm.$notify({
                            type: 'success',
                            title: 'Application Added',
                            message: 'Application added to watchlist',
                            position: 'top-right',
                            offset: 140,
                        });
                    })
                }
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.$store.state.applications.application = {};

                vm.loading = true;
                vm.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/application/' + vm.$route.params.applicationId)
                    .then((response) => {


                        vm.$store.state.applications.application = response.data.data;
                        console.log('Fetching local areas')

                        vm.$store.commit('auth/setBreadcrumbs', [
                            {
                                title: 'Planning Applications',
                                path: '/planning-applications'
                            },
                            {
                                title: vm.getApplicationTitle
                            }
                        ]);

                        vm.loading = false;

                    }).catch(() => {

                })
            });
        },
        data() {
            return {
                loading: true,
                activeTab: 'details',
                application: {},
                items: [
                    {label: 'Application Details', tab: 'details'},
                    {label: 'Map', tab: 'map'},
                    {label: 'Ordinance Survey', tab: 'ods'},
                    {label: 'Property Prices', tab: 'property-sales'},
                    {label: 'Local Area', tab: 'local-area'}
                ]
            }
        }
    }
</script>