<template>

        <div class="relative select-none" :class="width">
            <div :style="{
            'font-size': fontSize,
            }" class="absolute h-full  w-full flex items-center justify-center top-0 font-bold h-full text-center text-white">L</div>
            <svg viewBox="0 0 20 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g>
                        <path d="M10,0 C4.478,0 0,4.395 0,9.815 C0,15.32 4.375,19.083 10,24 C15.625,19.083 20,15.32 20,9.815 C20,4.395 15.522,0 10,0 Z" fill="#fff" fill-rule="nonzero"></path>
                        <path  :fill="getColor" d="M10,1 C5.0302,1 1,5.02875 1,9.99708333 C1,15.0433333 4.9375,18.49275 10,23 C15.0625,18.49275 19,15.0433333 19,9.99708333 C19,5.02875 14.9698,1 10,1 Z" fill-rule="nonzero"></path>
                    </g>
                </g>
            </svg>
        </div>
</template>

<script>
    import { getColor } from "./../../utils/ownership"

    export default {
        name: "LeaseholderIcon",
        props: {
            width: {
              type: String,
              default(){
                  return 'w-6';
              }
            },
            fontSize: {
                type: String,
                default(){
                    return '12px';
                }
            },
            type: {
                type: String,
                default(){
                    return '';
                }
            }
        },
        computed: {
            getColor(){
                return getColor(this.type);
            }
        }
    }
</script>