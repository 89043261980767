import axios from 'axios'
import store from './../store/index'

export function authenticate (email, password, recaptcha) {
  return new Promise((resolve, reject) => {
    axios.post('/api/v1/auth/login', {
      email: email,
      password: password,
      recaptcha: recaptcha
    }).then((response) => {
      store.commit('auth/setAccessToken', response.data.data.token)
      store.commit('auth/setRefreshToken', response.data.data.refresh_token)
      return resolve(response)
    })
      .catch((e) => {
        console.log(e)
        return reject(e)
      })
  })
}

export function register (name, email, password, recaptcha) {
  return new Promise((resolve, reject) => {
    axios.post('/api/v1/auth/register', {
      name: name,
      email: email,
      password: password,
      recaptcha: recaptcha
    }).then((response) => {
      store.commit('auth/setAccessToken', response.data.data.token)
      store.commit('auth/setRefreshToken', response.data.data.refresh_token)
      return resolve(response)
    })
        .catch((e) => {
          console.log(e)
          return reject(e)
        })
  })
}


export function reset (vm) {
  vm.$store.commit('alert/reset');
  vm.$store.commit('map/reset');
  vm.$store.commit('applicationMap/reset');
  vm.$store.commit('applications/reset');
  vm.$store.commit('ownership/reset');
  vm.$store.commit('ods/reset');
  vm.$store.commit('watchlist/reset');
  vm.$store.commit('auth/reset');
  vm.$store.commit('propertyPrices/reset');
  vm.$store.commit('commercialAvailability/reset')
}
