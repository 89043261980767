<template>

    <div>

        <div v-if="layersVisible">

            <l-marker
                    v-for="(opts, k) of getParishLabels"
                    :key="'parish-label-' + k"
                    :lat-lng="{
                    lat: opts.lat,
                    lng: opts.lon
                    }">
                <l-icon>
                    <p class="text-white font-bold text-stroke-border-white text-center w-24 text-xs -ml-12">
                        {{ opts.label }}
                    </p>
                </l-icon>
            </l-marker>


            <l-marker
                    v-for="(opts, k) of getPricePerSqftLabels"
                    :key="'price-per-sqft-' + k"
                    :lat-lng="{
                    lat: opts.lat,
                    lng: opts.lon
                    }">
                <l-icon>
                    <p class="text-white font-bold text-stroke-border-white text-center w-24 text-xs -ml-12">
                        {{ opts.label }} <br />£{{ opts.avg_price_per_sqft }} ft²
                    </p>
                </l-icon>
            </l-marker>

            <l-marker
                    v-for="(opts, k) of getDistrictLabels"
                    :key="'parish-label-' + k"
                    :lat-lng="{
                    lat: opts.lat,
                    lng: opts.lon
                    }">
                <l-icon>
                    <p class="text-white font-bold text-stroke-border-white text-center w-24 text-xs -ml-12">
                        {{ opts.label }}
                    </p>
                </l-icon>
            </l-marker>

            <l-marker
                    v-for="(opts, k) of getWardLabels"
                    :key="'ward-label-' + k"
                    :lat-lng="{
                    lat: opts.lat,
                    lng: opts.lon
                    }">
                <l-icon>
                    <p class="text-white font-bold text-stroke-border-white text-center w-24 text-xs -ml-12">
                        {{ opts.label }}
                    </p>
                </l-icon>
            </l-marker>


            <div
                    :key="i"
                    v-for="(layer, i) of activePolygonTileLayers">


                <l-protobuf
                        v-if="layer.type === 'sales' && $store.state.map.data_metrics.sale_prices.legend"
                        ref="salesTileLayer"
                        :url="layer.url"
                        :options="salesPolygonOptions"
                        @mouseover="onMouseOverSalePriceFeature"
                        @click="onUPRNSelect"
                />

                <l-protobuf
                        v-if="layer.type === 'ownership'"
                        ref="polygonTileLayer"
                        :url="layer.url"
                        @mouseover="onMouseOverOwnershipFeature"
                        @mouseleave="onMouseLeaveOwnershipFeature"
                        @click="onOwnershipLayerSelect"
                        :options="ownershipPolygonOptions"
                />


                <l-protobuf
                        v-if="layer.type === 'epcs'"
                        ref="epcTileLayer"
                        :url="layer.url"
                        @mouseover="onMouseOverEPC"
                        @click="onUPRNSelect"
                        :options="epcPolygonOptions"
                />


                <l-protobuf
                        v-if="layer.type === 'parishes'"
                        :url="layer.url"
                        :options="parishesPolygonOptions"
                />

                <l-protobuf
                        v-if="layer.type === 'wards'"
                        :url="layer.url"
                        :options="wardsPolygonOptions"
                />


                <l-protobuf
                        v-if="layer.type === 'counties'"
                        :url="layer.url"
                        :options="countiesPolygonOptions"
                />

                <l-protobuf
                        v-if="layer.type === 'districts'"
                        :url="layer.url"
                        :options="districtsPolygonOptions"
                />


                <l-protobuf
                        v-if="layer.type === 'hmos'"
                        ref="hmoTileLayer"
                        :url="layer.url"
                        @mouseover="onMouseOverHMO"
                        @click="onUPRNSelect"
                        :options="hmoPolygonOptions"
                />

                <l-protobuf
                        v-if="layer.type === 'planning'"
                        ref="planningTileLayer"
                        :url="layer.url"
                        @mouseover="onMouseOverPlanning"
                        @click="onUPRNSelect"
                        :options="planningPolygonOptions"
                />

                <l-protobuf
                        v-if="layer.type === 'knownMarketedSales'"
                        ref="knownMarketedSalesTileLayer"
                        :url="layer.url"
                        @mouseover="onMouseOverKnownMarketedSales"
                        @click="onUPRNSelect"
                        :options="knownMarketedSalesPolygonOptions"
                />

                <l-protobuf
                        v-if="layer.type === 'listedBuildings'"
                        ref="listedBuildingsTileLayer"
                        :url="layer.url"
                        @mouseover="onMouseOverListedBuildings"
                        :options="listedBuildingsPolygonOptions"
                        @click="openExternalListedBuildingsURL"
                />

                <l-protobuf
                        v-if="layer.type === 'pricePerSqft'"
                        ref="pricePerSqftTileLayer"
                        :url="layer.url"
                        :options="pricePerSqftPolygonOptions"
                />


                <l-protobuf
                        v-if="layer.type === 'occupiers'"
                        ref="occupiersTileLayer"
                        :url="layer.url"
                        @click="onUPRNSelect"
                        @mouseover="onMouseOverCurrentOccupiers"
                        :options="occupierPolygonOptions"
                />


                <l-wms-tile-layer
                        v-if="layer.type === 'wms'"
                        :base-url="layer.url ? layer.url : ''"
                        :layers="layer.layers ? layer.layers : ''"
                        :styles="layer.styles ? layer.styles : ''"
                        :opacity="layer.opacity ? layer.opacity : 1"
                        :visible="true"
                        :transparent="true"
                        class="el-leaflet-item"
                        format="image/png"
                        layer-type="base"
                />


            </div>
        </div>

        <l-google-tile-layer
                ref="googleLayer"
                v-if="googleVisible && showGoogleMap"
                @add="onMapLayerAdded"
                :apikey="$store.state.auth.map.google.key"
                :options="{
              type: value
          }"/>

        <l-tile-layer
                v-if="value === 'os'"
                @add="onMapLayerAdded"
                url="https://api.os.uk/maps/raster/v1/zxy/Road_3857/{z}/{x}/{y}.png?key=wC6Him1A448aAquBYlhEJq2IS4GAK51Z"
        />
    </div>


</template>

<script>

    import tippy, {followCursor} from 'tippy.js';

    const turf = require('@turf/turf');
    const collect = require('collect.js');

    import {
        getFreeholderPolygonStyle,
        filterPolygon,
        getLeaseholderPolygonStyle,
        getPolygonHoverHTML,
        getPricePaidHoverHTML,
        getLastKnownMarkedSaleHTML,
        getCurrentOccupiersHTML,
        getEPCRatingColor,
        getSalePricePolygonStyle,
        getSalesPriceColorByIndex,
        getEPCPolygonStyle,
        getCurrentOccupierColor,
        getPolygonStyle,
        getKnowMarketedSaleColor,
        getEPCRatingHoverHTML,
        getListedBuildingsIcon,
        getListedBuildingsHTML,
        getPricePerSqftSalePolygonStyle,
        getPlanningPolygonColor, getPlanningObjectColor, getPlanningHTML, getHmoPolygonStyle, getHmoHTML,
    } from "../../utils/ownership";

    const api = process.env.NODE_ENV === 'production' ? 'https://api.landmonitor.com' : 'https://localhost';

    export default {
        name: "TileManager",
        props: {
            value: {
                type: String,
                default() {
                    return ''
                }
            }
        },
        data() {
            return {
                polyLabels: {
                    parishes: {},
                    wards: {},
                    counties: {},
                    districts: {},
                    pricePerSqft: {}
                },
                layersVisible: true,
                googleVisible: true,
                showLayer: true,
                salesPolygonOptions: {
                    interactive: true,
                    getFeatureId: (f) => {
                        return f.properties.uprn;
                    },
                    vectorTileLayerStyles: {
                        sales: (item) => {
                            return getSalePricePolygonStyle(item, this.getSalesMarkerColorFromPrice(item.price_paid), false)
                        },
                    }
                },

                parishesPolygonOptions: {
                    interactive: true,
                    getFeatureId: (f, coords) => {
                        let geo = f.toGeoJSON(coords.x, coords.y, coords.z)
                        if (!this.polyLabels.parishes[f.properties.global_polygon_id]) {
                            this.$set(this.polyLabels.parishes, f.properties.global_polygon_id, []);
                        }
                        let items = this.polyLabels.parishes[f.properties.global_polygon_id];
                        items.push(geo)
                        this.$set(this.polyLabels.parishes, f.properties.global_polygon_id, items);
                        return f.properties.global_polygon_id;
                    },
                    filter: ({layerName}) => {
                        return layerName === 'parish';
                    },
                    vectorTileLayerStyles: {
                        parish: (p) => {
                            return {
                                color: this.generateRandomColorFromId(p.global_polygon_id),
                                fill: true,
                            };
                        }
                    }
                },
                wardsPolygonOptions: {
                    interactive: true,
                    getFeatureId: (f, coords) => {
                        let geo = f.toGeoJSON(coords.x, coords.y, coords.z)
                        if (!this.polyLabels.wards[f.properties.global_polygon_id]) {
                            this.$set(this.polyLabels.wards, f.properties.global_polygon_id, []);
                        }
                        let items = this.polyLabels.wards[f.properties.global_polygon_id];
                        items.push(geo)
                        this.$set(this.polyLabels.wards, f.properties.global_polygon_id, items);
                        return f.properties.global_polygon_id;
                    },
                    filter: ({layerName}) => {
                        return layerName === 'district_borough_unitary_ward';
                    },
                    vectorTileLayerStyles: {
                        district_borough_unitary_ward: (p) => {
                            return {
                                color: this.generateRandomColorFromId(p.global_polygon_id),
                                fill: true,
                            };
                        }
                    }
                },
                districtsPolygonOptions: {
                    interactive: true,
                    getFeatureId: (f, coords) => {
                        let geo = f.toGeoJSON(coords.x, coords.y, coords.z)
                        if (!this.polyLabels.districts[f.properties.global_polygon_id]) {
                            this.$set(this.polyLabels.districts, f.properties.global_polygon_id, []);
                        }
                        let items = this.polyLabels.districts[f.properties.global_polygon_id];
                        items.push(geo)
                        this.$set(this.polyLabels.districts, f.properties.global_polygon_id, items);
                        return f.properties.global_polygon_id;
                    },
                    filter: ({layerName}) => {
                        return layerName === 'district_borough_unitary';
                    },
                    vectorTileLayerStyles: {
                        district_borough_unitary: (p) => {
                            return {
                                color: this.generateRandomColorFromId(p.global_polygon_id),
                                fill: true,
                            };
                        }
                    }
                },
                pricePerSqftPolygonOptions: {
                    interactive: true,
                    getFeatureId: (f, coords) => {
                        let geo = f.toGeoJSON(coords.x, coords.y, coords.z)
                        if (!this.polyLabels.pricePerSqft[f.properties.code]) {
                            this.$set(this.polyLabels.pricePerSqft, f.properties.code, []);
                        }
                        let items = this.polyLabels.pricePerSqft[f.properties.code];
                        items.push(geo)
                        this.$set(this.polyLabels.pricePerSqft, f.properties.code, items);
                        return f.properties.code;
                    },
                    vectorTileLayerStyles: {
                        'price-per-sqft': (i) => {
                            return getPricePerSqftSalePolygonStyle(i.avg_price_per_sqft, this.$store.getters['map/getPricePerSqftLegend'])
                        },
                    }
                },
                countiesPolygonOptions: {
                    interactive: true,
                    getFeatureId: (f, coords) => {
                        let geo = f.toGeoJSON(coords.x, coords.y, coords.z)
                        if (!this.polyLabels.counties[f.properties.global_polygon_id]) {
                            this.$set(this.polyLabels.counties, f.properties.global_polygon_id, []);
                        }
                        let items = this.polyLabels.counties[f.properties.global_polygon_id];
                        items.push(geo)
                        this.$set(this.polyLabels.counties, f.properties.global_polygon_id, items);
                        return f.properties.global_polygon_id;
                    },
                    filter: ({layerName}) => {
                        return layerName === 'boundary_line_historic_counties';
                    },
                    vectorTileLayerStyles: {
                        boundary_line_historic_counties: () => {
                            return {
                                color: '#f95400',
                                fill: true,
                            };
                        }
                    }
                },



                // onMouseOverHMO
                epcPolygonOptions: {
                    interactive: true,
                    getFeatureId: (f) => {
                        return f.properties.uprn;
                    },
                    vectorTileLayerStyles: {
                        epcs: (item) => {
                            return getEPCPolygonStyle(getEPCRatingColor(item.rating), false)
                        },
                    }
                },
                hmoPolygonOptions: {
                    interactive: true,
                    getFeatureId: (f) => {
                        return f.properties.uprn;
                    },
                    vectorTileLayerStyles: {
                        hmos: () => {
                            return getHmoPolygonStyle(false)
                        },
                    }
                },
                planningPolygonOptions: {
                    interactive: true,
                    getFeatureId: (f) => {
                        return f.properties.uprn;
                    },
                    vectorTileLayerStyles: {
                        planning: (item) => {
                            return getPlanningPolygonColor(getPlanningObjectColor(item), false)
                        },
                    }
                },
                knownMarketedSalesPolygonOptions: {
                    interactive: true,
                    getFeatureId: (f) => {
                        return f.properties.uprn;
                    },
                    vectorTileLayerStyles: {
                        'known-marketed-sales': (item) => {
                            return getPolygonStyle(getKnowMarketedSaleColor(item.marketed_date), false)
                        },
                    }
                },
                listedBuildingsPolygonOptions: {
                    interactive: true,
                    getFeatureId: (f) => {
                        return f.properties.id;
                    },
                    vectorTileLayerStyles: {
                        'listed-buildings': () => {
                            return getListedBuildingsIcon()
                        },
                    }
                },
                occupierPolygonOptions: {
                    interactive: true,
                    getFeatureId: (f) => {
                        return f.properties.uprn;
                    },
                    vectorTileLayerStyles: {
                        occupiers: (item) => {
                            return getPolygonStyle(getCurrentOccupierColor(item.tenure), false)
                        },
                    }
                },
                ownershipPolygonOptions: {
                    interactive: true,
                    getFeatureId: (f) => {
                        return f.properties.site_id;
                    },
                    filter: ({properties}) => {
                        return filterPolygon(properties, this.$store.getters['map/searchParams']);
                    },
                    vectorTileLayerStyles: {
                        freeholders: (item) => {
                            return getFreeholderPolygonStyle(item, false, this.$store.state.map.active_polygon_id)
                        },
                        leaseholders: (item) => {
                            return getLeaseholderPolygonStyle(item, false, this.$store.state.map.active_polygon_id)
                        }
                    }
                },
                layers: {
                    salePrices: [
                        {
                            url: api + "/api/v1/tiles/sale-prices/{z}/{x}/{y}",
                            type: "sales"
                        }
                    ],
                    planning: [
                        {
                            url: api + "/api/v1/tiles/planning-applications/{z}/{x}/{y}",
                            type: "planning"
                        }
                    ],
                    epcs: [
                        {
                            url: api + "/api/v1/tiles/epcs/{z}/{x}/{y}",
                            type: 'epcs'
                        }
                    ],
                    hmos: [
                        {
                            url: api + "/api/v1/tiles/hmos/{z}/{x}/{y}",
                            type: 'hmos'
                        }
                    ],
                    occupiers: [
                        {
                            url: api + "/api/v1/tiles/occupiers/{z}/{x}/{y}",
                            type: 'occupiers'
                        }
                    ],
                    knownMarketedSales: [
                        {
                            url: api + "/api/v1/tiles/known-marketed-sales/{z}/{x}/{y}",
                            type: 'knownMarketedSales'
                        }
                    ],
                    listedBuildings: [
                        {
                            url: api + "/api/v1/tiles/listed-buildings/{z}/{x}/{y}",
                            type: 'listedBuildings'
                        }
                    ],
                    pricePerSqft: [
                        {
                            url: api + "/api/v1/tiles/price-per-sqft/{z}/{x}/{y}",
                            type: 'pricePerSqft'
                        }
                    ],
                    leaseholders: [
                        {
                            type: 'leaseholders'
                        }
                    ],
                    ownership: [
                        {
                            url: api + "/api/v1/tiles/ownership/{z}/{x}/{y}",
                            type: "ownership"
                        }
                    ],
                    parishes: [
                        {
                            url: "https://media.landmonitor.com/tiles/os-boundaries/{z}/{x}/{y}.pbf",
                            type: "parishes"
                        }
                    ],
                    wards: [
                        {
                            url: "https://media.landmonitor.com/tiles/os-boundaries/{z}/{x}/{y}.pbf",
                            type: "wards"
                        }
                    ],
                    counties: [
                        {
                            url: "https://media.landmonitor.com/tiles/os-boundaries/{z}/{x}/{y}.pbf",
                            type: "counties"
                        }
                    ],
                    districts: [
                        {
                            url: "https://media.landmonitor.com/tiles/os-boundaries/{z}/{x}/{y}.pbf",
                            type: "districts"
                        }
                    ],
                    planningAuthorities: [
                        {
                            url: "https://tiles.landmonitor.com/geoserver/lm/wms",
                            type: "wms",
                            layers: "lm:district-borough-unitary-region"
                        }
                    ],
                    aonb: [
                        {
                            url: "https://tiles.landmonitor.com/geoserver/lm/wms",
                            type: "wms",
                            layers: "lm:aonbe"
                        }
                    ],
                    greenbelt: [
                        {
                            url: "https://tiles.landmonitor.com/geoserver/lm/wms",
                            type: "wms",
                            layers: "lm:greenbelt-eng"
                        }
                    ],
                    rentalYield: [
                        {
                            url: "https://tiles.landmonitor.com/geoserver/lm/wms",
                            type: "wms",
                            layers: "lm:rental-yield-data"
                        }
                    ],
                    builtUpAreas: [
                        {
                            url: "https://tiles.landmonitor.com/geoserver/lm/wms",
                            type: "wms",
                            layers: "lm:built-up-areas",
                            opacity: 0.5
                        }
                    ],
                    nationalParks: [
                        {
                            url: "https://tiles.landmonitor.com/geoserver/lm/wms",
                            type: "wms",
                            layers: "lm:national-parks-eng"
                        }
                    ],
                    agriculturalLandClassification: [
                        {
                            url: "https://tiles.landmonitor.com/geoserver/lm/wms",
                            type: "wms",
                            layers: "lm:agricultural-land-classification"
                        }
                    ],
                    floodZones: [
                        {
                            url: "https://tiles.landmonitor.com/geoserver/lm/wms",
                            type: "wms",
                            layers: "lm:planning-flood-risk-2"
                        },
                        {
                            url: "https://tiles.landmonitor.com/geoserver/lm/wms",
                            type: "wms",
                            layers: "lm:planning-flood-risk-3"
                        }
                    ],
                    floodStorageAreas: [],
                    floodDefences: [],
                    floodRiskCommunities: [],
                    deprivation: [
                        {
                            url: "https://tiles.landmonitor.com/geoserver/lm/wms",
                            type: "wms",
                            layers: "lm:imd"
                        }
                    ],
                    population: [
                        {
                            url: "https://tiles.landmonitor.com/geoserver/lm/wms",
                            type: "wms",
                            layers: "lm:imd",
                            styles: "IndexMultipleDeprivationPopulation"
                        }
                    ],
                    income: [
                        {
                            url: "https://tiles.landmonitor.com/geoserver/lm/wms",
                            type: "wms",
                            layers: "lm:imd",
                            styles: "IndexMultipleDeprivationIncome"
                        }
                    ],
                    conservation: [
                        {
                            url: "https://tiles.landmonitor.com/geoserver/lm/wms",
                            type: "wms",
                            layers: "lm:conservation-area-eng-2020",
                        }
                    ],
                    ancientWoodland: [
                        {
                            url: "https://environment.data.gov.uk/spatialdata/ancient-woodland-england/wms",
                            type: "wms",
                            layers: "Ancient_Woodland_England",
                            opacity: 0.7
                        }
                    ],
                    sitesOfScientificInterest: [
                        {
                            url: "https://tiles.landmonitor.com/geoserver/lm/wms",
                            type: "wms",
                            layers: "lm:sites-scientific-interest-eng"
                        }
                    ],
                    specialProtectionOrder: [
                        {
                            url: "https://tiles.landmonitor.com/geoserver/lm/wms",
                            type: "wms",
                            layers: "lm:special-protection-order-eng"
                        }
                    ]
                }
            }
        },
        watch: {
            activePolygonTileLayers() {
                this.refreshOwnershipLayers();
            },
            value() {
                const vm = this;
                vm.googleVisible = false;
                vm.showLayer = false;
                this.$nextTick(() => {
                    if (this.$refs.googleLayer) {
                        this.$refs.googleLayer.redraw();
                    }

                    setTimeout(() => {
                        vm.showLayer = true;
                    }, 10)

                    setTimeout(() => {
                        vm.googleVisible = true;
                    }, 10)

                });
            }
        },
        methods: {
            generateRandomColorFromId(id){
                let color = '#F94144'
                if (id % 3) {
                    color = '#43AA8B'
                } else if (id % 7) {
                    color = '#EE9B00'
                } else if (id % 11) {
                    color = '#6d597a'
                } else if (id % 13) {
                    color = '#f9844a'
                } else if (id % 17) {
                    color = '#e2afff'
                } else if (id % 19) {
                    color = '#9d6b53'
                } else if (id % 23) {
                    color = '#E9D8A6'
                } else if (id % 29) {
                    color = '#94D2BD'
                }
                return color;
            },
            generateBoundaryLabels(polyLabels){
                let bounds = this.$parent.mapObject.getBounds().toBBoxString();
                let bbox = bounds.split(',');

                let items = Object.values(polyLabels).map((features) => {
                    let label = features[0].properties.name;
                    let data = turf.centerOfMass(turf.featureCollection(features));
                    data.properties = {
                        label
                    }
                    return data;
                }).map((x) => {
                    return {
                        lat: x.geometry.coordinates[1],
                        lon: x.geometry.coordinates[0],
                        label: x.properties.label,
                    }
                }).filter((x) => {
                    return turf.booleanPointInPolygon(turf.point([x.lon, x.lat]), turf.bboxPolygon(bbox))
                })
                return collect(items).take(100).toArray();
            },
            getSalesMarkerColorFromPrice(price) {
                const legend = this.$store.state.map.data_metrics.sale_prices.legend;
                //console.log('legend', legend)
                if (legend && Array.isArray(legend) && legend.length > 0) {
                    let find = legend.filter((i) => {
                        return price >= i.min_val && price <= i.max_val;
                    });

                    let index = find && find.length > 0 ? find[0].price_group_index : null;

                    if (index === null) {
                        console.log('index', index, price, this.$store.state.map.data_metrics.sale_prices.legend);
                    }

                    return index !== null ? getSalesPriceColorByIndex(index) : '#908f90';

                }

                return null;

            },
            getEPCColor(rating) {
                return getEPCRatingColor(rating)
            },
            refreshOwnershipLayers() {
                const vm = this;
                vm.layersVisible = false;
                setTimeout(() => {
                    vm.layersVisible = true;
                }, 1)
            },

            onOwnershipLayerSelect(e) {
                if (e && e.layer && e.layer.properties && e.layer.properties.site_id) {
                    this.$emit('fetch', e.layer.properties.site_id);
                }
            },
            onUPRNSelect(e) {
                if (e && e.layer && e.layer.properties && e.layer.properties.uprn) {
                    this.$emit('uprn', e.layer.properties.uprn)
                }
            },
            openExternalListedBuildingsURL(e) {
                if (e && e.layer && e.layer.properties && e.layer.properties.id) {
                    window.open('https://historicengland.org.uk/listing/the-list/list-entry/' + e.layer.properties.id)
                }
            },
            onMapLayerAdded(e) {
                if (e && e.target && e.target._container) {
                    e.target._container.classList.add('el-tile--map');
                }
            },
            onMouseLeaveOwnershipFeature(e) {

                const id = e.layer.properties.site_id,
                    properties = e.layer.properties;

                let style = getFreeholderPolygonStyle(properties, false, this.$store.state.map.active_polygon_id);

                if (properties.tenure === 'Leasehold') {
                    style = getLeaseholderPolygonStyle(properties, false, this.$store.state.map.active_polygon_id)
                }

                this.$refs.polygonTileLayer[0].mapObject.setFeatureStyle(id, style);
            },
            onMouseOverOwnershipFeature(e) {

                if (!e.layer._path._tippy) {
                    tippy(e.layer._path, {
                        content: getPolygonHoverHTML(e.layer.properties),
                        allowHTML: true,
                        followCursor: true,
                        plugins: [followCursor],
                        theme: 'ownership',
                    })
                }

                const id = e.layer.properties.site_id,
                    properties = e.layer.properties;

                let style = getFreeholderPolygonStyle(properties, true, this.$store.state.map.active_polygon_id);

                if (properties.tenure === 'Leasehold') {
                    style = getLeaseholderPolygonStyle(properties, true, this.$store.state.map.active_polygon_id)
                }

                this.$refs.polygonTileLayer[0].mapObject.setFeatureStyle(id, style);
            },


            onMouseOverHMO(e) {
                if (!e.layer._path._tippy) {
                    tippy(e.layer._path, {
                        content: getHmoHTML(e.layer.properties),
                        allowHTML: true,
                        followCursor: true,
                        plugins: [followCursor]
                    })
                }
            },
            onMouseOverPlanning(e) {
                if (!e.layer._path._tippy) {
                    tippy(e.layer._path, {
                        content: getPlanningHTML(e.layer.properties),
                        allowHTML: true,
                        followCursor: true,
                        plugins: [followCursor]
                    })
                }
            },

            onMouseOverEPC(e) {

                if (!e.layer._path._tippy) {
                    tippy(e.layer._path, {
                        content: getEPCRatingHoverHTML(e.layer.properties),
                        allowHTML: true,
                        followCursor: true,
                        plugins: [followCursor]
                    })
                }
            },
            onMouseOverKnownMarketedSales(e) {

                if (!e.layer._path._tippy) {
                    tippy(e.layer._path, {
                        content: getLastKnownMarkedSaleHTML(e.layer.properties),
                        allowHTML: true,
                        followCursor: true,
                        plugins: [followCursor]
                    })
                }

            },

            onMouseOverListedBuildings(e) {


                if (!e.layer._path._tippy) {

                    tippy(e.layer._path, {
                        content: getListedBuildingsHTML(e.layer.properties),
                        allowHTML: true,
                        followCursor: true,
                        plugins: [followCursor]
                    })
                }


            },


            onMouseOverCurrentOccupiers(e) {

                if (!e.layer._path._tippy) {
                    tippy(e.layer._path, {
                        content: getCurrentOccupiersHTML(e.layer.properties),
                        allowHTML: true,
                        followCursor: true,
                        plugins: [followCursor]
                    })
                }

            },
            onMouseOverSalePriceFeature(e) {

                if (!e.layer._path._tippy) {
                    tippy(e.layer._path, {
                        content: getPricePaidHoverHTML(e.layer.properties),
                        allowHTML: true,
                        followCursor: true,
                        plugins: [followCursor],
                        theme: 'ownership',
                    })
                }
            }
        },
        computed: {

            getDistrictLabels(){
                let find = this.activePolygonTileLayers.find((x) => {
                    return x.type === 'districts';
                })
                if (!find) {
                    return []
                }
                return this.generateBoundaryLabels(this.polyLabels.districts)
            },
            getPricePerSqftLabels(){

                let find = this.activePolygonTileLayers.find((x) => {
                    return x.type === 'pricePerSqft';
                })
                if (!find) {
                    return []
                }

                let bounds = this.$parent.mapObject.getBounds().toBBoxString();
                let bbox = bounds.split(',');

                console.log(this.polyLabels.pricePerSqft)
                let items = Object.values(this.polyLabels.pricePerSqft).map((features) => {
                    let label = features[0].properties.ward;
                    let avg_price_per_sqft = features[0].properties.avg_price_per_sqft;
                    let data = turf.centerOfMass(turf.featureCollection(features));
                    data.properties = {
                        label,
                        avg_price_per_sqft
                    }
                    return data;
                }).map((x) => {
                    return {
                        lat: x.geometry.coordinates[1],
                        lon: x.geometry.coordinates[0],
                        label: x.properties.label,
                        avg_price_per_sqft: x.properties.avg_price_per_sqft,
                    }
                }).filter((x) => {
                    return turf.booleanPointInPolygon(turf.point([x.lon, x.lat]), turf.bboxPolygon(bbox))
                })
              //  console.log(items);
                return collect(items).take(100).toArray();
            },

            getParishLabels() {
                let find = this.activePolygonTileLayers.find((x) => {
                    return x.type === 'parishes';
                })
                if (!find) {
                    return []
                }
                return this.generateBoundaryLabels(this.polyLabels.parishes)
            },

            getWardLabels() {
                console.log( this.activePolygonTileLayers)
                let find = this.activePolygonTileLayers.find((x) => {
                    return x.type === 'wards';
                })
                if (!find) {
                    return []
                }
                return this.generateBoundaryLabels(this.polyLabels.wards)
            },
            activePolygonTileLayers() {

                if (this.$store.state.map.custom_draw.visible) {
                    return [];
                }

                const vm = this, activeOverlays = this.$store.state.map.active_overlays,
                    visibleOverlays = this.$store.state.map.visible_overlays;

                vm.activeWardLabelData = [];

                if (this.$store.state.map.data_metrics.sale_prices.search_area === null) {
                    let index = visibleOverlays.indexOf('salePrices');
                    if (index > -1) {
                        visibleOverlays.splice(index, 1);
                    }
                }


                let layers = activeOverlays.filter((layer) => visibleOverlays.includes(layer)).map((layer) => {
                    return vm.layers[layer] ? vm.layers[layer] : null;
                }).filter((layer) => layer !== null);


                return [].concat.apply([], layers).reverse();
            },
            showGoogleMap() {
                return ['roadmap', 'satellite', 'hybrid'].indexOf(this.value) > -1;
            }
        }
    }
</script>

<style lang="scss">
    .el-block---map-keys {
        z-index: 3001;
        max-height: 300px;
        overflow: scroll;
    }

    .leaflet-tile-pane {
        .leaflet-layer {
            z-index: 100 !important;

            &.el-tile--map {
                z-index: 99 !important;
            }
        }
    }
</style>


