<template>
    <div>
        <div v-if="value.fetching"
             class="rounded-md p-4 w-full my-12 mx-auto">
            <div class="animate-pulse flex space-x-4">
                <div class="rounded-full bg-gray-400 h-12 w-12"></div>
                <div class="flex-1 space-y-4 py-1">
                    <div class="h-4 bg-gray-400 rounded w-3/4"></div>
                    <div class="space-y-2">
                        <div class="h-4 bg-gray-400 rounded"></div>
                        <div class="h-4 bg-gray-400 rounded w-5/6"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!value.fetching && !value.results.length">
            <empty-state title="Sorry, no results found" />
        </div>

        <div v-if="!value.fetching && value.meta.totalResult && value.results.length > 0">


            <div class="columns my-3">
                <div class="column w-1/3 text-xs flex items-center uppercase">
                    {{ value.meta.totalResult.toLocaleString() }} results
                </div>

                <div class="column w-2/3">
                    <div class="flex items-center justify-end">
                        <floating-label-input label="Sort by...">
                            <div class="w-48">
                                <el-select
                                        :disabled="!filters.length"
                                        auto-complete="off"
                                        @change="()=>{ $emit('change', 1) }"
                                        placeholder="Sort..."
                                        size="small"
                                        v-model="activeSortField">
                                    <el-option
                                            v-for="item in calculatedSortFields"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                        <div class="flex w-full items-center">
                                            <i v-if="item.value.endsWith('desc')" class="el-icon-arrow-down"></i>
                                            <i v-if="item.value.endsWith('asc')"  class="el-icon-arrow-up"></i>
                                            <span class="ml-1">{{ item.label }}</span>
                                        </div>
                                    </el-option>
                                </el-select>
                            </div>
                        </floating-label-input>
                    </div>
                </div>
            </div>
            
            <slot></slot>

            <div class="columns py-3" v-if="value.meta  && value.results.length > 0">
                <div class="column w-1/3 text-xs flex items-center uppercase">
                    {{ value.meta.totalResult.toLocaleString() }} results
                </div>
                <div class="column ml-auto">
                    <el-pagination
                            background
                            layout="prev, next"
                            @current-change="(page)=>{ $emit('change', page) }"
                            :page-size="value.meta.rpp"
                            :current-page="value.meta.current"
                            :total="value.meta.totalResult">
                    </el-pagination>
                </div>
            </div>


        </div>
        
    </div>
</template>

<script>

    import collect from "collect.js";
    import EmptyState from "./EmptyState";

    export default {
        name: "DataLayerTableWrapper",
        components: {EmptyState},
        props: {
            value: {
                type: Object,
                default(){
                    return {}
                }
            },
            defaultSortField: {
              type: String,
              default(){
                  return null
              }
            },
            filters: {
                type: Array,
                default(){
                    return []
                }
            }
        },
        mounted(){
            this.activeSortField = !this.defaultSortField && this.calculatedSortFields.length > 0 ? this.calculatedSortFields[0].value : this.defaultSortField;
        },
        data(){
            return {
                activeSortField: this.defaultSortField
            }
        },
        computed: {
            calculatedSortFields(){
                let sortFields = [];
                for(let item of this.filters){
                    sortFields.push({
                        value: item.id  + '|desc',
                        label: item.label + ' (desc)'
                    })
                    sortFields.push({
                        value: item.id  + '|asc',
                        label: item.label + ' (asc)'
                    })
                }
                return collect(sortFields).sortBy('label').toArray()
            }
        },
        methods: {
            getSortField(){
                return this.activeSortField;
            }
        }
    }
</script>

<style scoped>

</style>