import api from "../../utils/api";

const initialState = {

};

export default {
    namespaced: true,
    state: initialState,
    mutations: {
        reset(state) {
            console.log('reset')
            Object.keys(initialState).forEach(key => {
                state[key] = initialState[key]
            })
        },
    },
    actions: {
        index: ({rootState}, params) => {
            return api.get('/api/v1/team/' + rootState.auth.user.team.id + '/workflow/' + params.workflowId + '/columns');
        },
        update: ({rootState}, params) => {
            return api.put('/api/v1/team/' + rootState.auth.user.team.id + '/workflow/' + params.workflowId + '/column/' + params.columnId, {
                item_ids: params.itemIds
            });
        }
    }
}