<template>
    <div class="flex h-full items-center justify-center">
        <div class="p-3 text-center">
            <property-loader />
            <h3 class="font-semibold text-blue-800">{{ text }}</h3>
        </div>
    </div>
</template>

<script>
    import PropertyLoader from "../../incs/PropertyLoader";
    export default {
        name: "OwnershipLoading",
        components: {PropertyLoader},
        props: {
            text: {
                type: String,
                default(){
                    return 'Loading Property Details...'
                }
            }
        }
    }
</script>

<style scoped>

</style>