<template>

    <div>

        <div
                v-if="loading"
                class="flex h-full mt-12 pt-12 items-center justify-center">
            <div class="p-3 text-center">
                <property-loader />
                <h3 class="font-semibold text-blue-800">Fetching Business Rates...</h3>
            </div>
        </div>

        <div class="columns" v-if="!loading">
            <div class="column w-full">
                <dashboard-widget
                        padding-class="''"
                        title="Business Rates">
                    <slot>

                        <div class="p-3" v-if="!getRates.length">
                            <empty-state />
                        </div>

                        <div
                                class="el-collapse--epc-ratings"
                                v-if="getRates.length > 0">

                            <el-collapse v-model="activeRates">
                                <el-collapse-item
                                        :key="item.id"
                                        v-for="(item) of value"
                                        :title="item.address" :name="item.address">


                                    <div class="p-3" v-if="getBusinessRateOverview(item).length > 0">
                                        <div class="el-block--detail mt-1"
                                             :key="index"
                                             v-for="(i, index) of getBusinessRateOverview(item)">
                                            <div class="flex flex-no-wrap mb-1">
                                                <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                                    {{ i.label }}
                                                </div>
                                                <div class="w-32 text-right text-xs text-gray-900">
                                                    {{ i.value }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <table class="el-table" v-if="item.items.length > 0">
                                        <thead class="border-t border-b border-gray-200">
                                        <tr>
                                            <th colspan="5" class="px-2 p-1 text-xs uppercase bg-gray-800 text-white">Breakdown</th>
                                        </tr>
                                        <tr>
                                            <th width="100px" class="p-1  text-xs uppercase">Floor</th>
                                            <th class="px-2 p-1 text-xs uppercase">Description</th>
                                            <th class="px-2 p-1 text-right text-xs uppercase" width="100px">Area m²/unit</th>
                                            <th class="px-2 p-1 text-right text-xs uppercase" width="130px">Price per m²/unit</th>
                                            <th class="px-2 p-1 text-right  text-xs uppercase" width="100px">Value</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                                :key="k"
                                                v-for="(i, k) of item.items">

                                            <td class="px-2 p-1 text-xs">
                                                {{ i.floor }}
                                            </td>
                                            <td class="px-2 p-1 text-xs">
                                                {{ i.description }}
                                            </td>
                                            <td class="px-2 p-1 text-xs text-right">
                                                <span v-if="i.area"> {{ i.area }}</span>
                                            </td>
                                            <td class="px-2 p-1 text-xs text-right">
                                                <span v-if="i.price">{{ i.price.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) }}</span>
                                            </td>
                                            <td class="px-2 p-1 text-xs text-right">
                                                <span v-if="i.value">{{ i.value.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) }}</span>
                                            </td>

                                        </tr>
                                        <tr v-if="item.total_area && item.sub_total">
                                            <td class="px-2 p-1 font-bold text-xs" colspan="2">
                                                Total
                                            </td>
                                            <td class="px-2 p-1 text-right text-xs font-bold">
                                                {{ item.total_area.toLocaleString() }}
                                            </td>
                                            <td colspan="2" class="px-2 p-1 text-right text-xs font-bold">
                                                {{ item.sub_total.toLocaleString('en-GB', { style: 'currency', currency:
                                                'GBP' }) }}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>

                                    <table class="el-table mt-4" v-if="item.additional_items.length > 0">
                                        <thead class="border-t border-b border-gray-200">
                                        <tr>
                                            <th class="px-2 p-1 text-xs uppercase bg-gray-800 text-white" colspan="4">Additional Items</th>
                                        </tr>
                                        <tr>
                                            <th class="px-2 p-1 text-xs uppercase">Description</th>
                                            <th class="px-2 p-1 text-xs uppercase text-right" width="100px">Area m²/unit</th>
                                            <th class="px-2 p-1 text-xs uppercase text-right" width="130px">Price per m²/unit</th>
                                            <th class="px-2 p-1 text-xs uppercase text-right" width="100px">Value</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                                :key="k"
                                                v-for="(i, k) of item.additional_items">


                                            <td class="px-2 p-1 text-xs">
                                                {{ i.description }}
                                            </td>
                                            <td class="px-2 p-1 text-xs text-right">
                                                <span v-if="i.size"> {{ i.size }}</span>
                                            </td>
                                            <td class="px-2 p-1 text-xs text-right">
                                                {{ i.price.toLocaleString('en-GB', { style: 'currency', currency: 'GBP'
                                                }) }}
                                            </td>
                                            <td class="px-2 p-1 text-xs text-right">
                                                {{ i.value.toLocaleString('en-GB', { style: 'currency', currency: 'GBP'
                                                }) }}
                                            </td>

                                        </tr>
                                        <tr v-if="item.additional_items.length > 0">
                                            <td class="px-2 p-1 text-xs font-bold" colspan="3">
                                                Total
                                            </td>
                                            <td colspan="2" class="px-2 p-1 text-xs text-right font-bold">
                                                {{ item.additional_items.map((index)=> index.value).reduce((a, b) => a + b, 0).toLocaleString('en-GB', { style: 'currency', currency:
                                                'GBP' }) }}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>

                                    <table class="el-table mt-4" v-if="item.car_parking_spaces > 0">
                                        <thead class="border-t border-b border-gray-200">
                                        <tr>
                                            <th colspan="3" class="px-2 p-1 text-xs uppercase bg-gray-800 text-white">Car Parks</th>
                                        </tr>
                                        <tr>
                                            <th class="px-2 p-1 text-xs uppercase">Spaces</th>
                                            <th class="px-2 p-1 text-xs text-right uppercase" width="100px">Area</th>
                                            <th class="px-2 p-1 text-xs text-right uppercase" width="100px">Value</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>


                                            <td class="px-2 p-1 text-xs uppercase">
                                                {{ item.car_parking_spaces }}
                                            </td>
                                            <td class="px-2 p-1 text-xs uppercase text-right">
                                                <span v-if="item.car_parking_area_value"> {{ item.car_parking_area_value }}</span>
                                            </td>
                                            <td class="px-2 p-1 text-xs uppercase text-right">
                                               <span v-if="item.car_parking_spaces_value">
                                                   {{ item.car_parking_spaces_value.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) }}
                                               </span>
                                            </td>
                                        </tr>
                                        <tr>

                                            <td class="px-2 p-1 text-xs font-bol" colspan="2">Total</td>
                                            <td class="px-2 p-1 text-xs font-bold text-right">{{
                                                item.car_parking_total.toLocaleString('en-GB', { style: 'currency',
                                                currency: 'GBP' }) }}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>


                                    <table class="el-table" v-if="item.adjustments.length > 0">
                                        <thead>
                                        <tr>
                                            <th colspan="2" class="bg-gray-800 text-white">Adjustments made to Property</th>
                                        </tr>
                                        <tr>
                                            <th>Description</th>
                                            <th class="text-right" width="100px">Adjustment</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                                :key="k"
                                                v-for="(i, k) of item.adjustments">

                                            <td>
                                                {{ i.description }}
                                            </td>

                                            <td class="text-right">
                                                {{ i.area }}
                                            </td>

                                        </tr>
                                        </tbody>
                                    </table>

                                    <table class="el-table mt-3">
                                        <tbody>
                                        <tr>
                                            <td class="px-2 p-1 font-bold">
                                                Total value
                                            </td>
                                            <td class="px-2 p-1 font-bold text-right">
                                                {{ item.total_value.toLocaleString('en-GB', { style: 'currency',
                                                currency: 'GBP' }) }}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>

                                </el-collapse-item>
                            </el-collapse>


                        </div>
                    </slot>
                </dashboard-widget>
            </div>
        </div>

    </div>




</template>

<script>
    import moment from "moment";

    import EmptyState from "../../EmptyState";
    import PropertyLoader from "../../incs/PropertyLoader";

    export default {
        name: "OwnershipBusinessRates",
        components: {PropertyLoader, EmptyState},
        props: {
            id: {
                type: [Number, String],
                default() {
                    return null
                }
            }
        },
        computed: {
          getRates(){
              return this.value && Array.isArray(this.value) && this.value.length > 0 ? this.value : [];
          }
        },
        mounted(){
            this.fetch();
        },
        methods: {
            fetch(){
                const vm = this;
                vm.loading = true;
                this.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/ownership/' + this.id + '/business-rates')
                    .then((response)=>{
                        vm.loading = false;
                        vm.value = response.data.data;
                    }).catch(()=>{
                    vm.loading = false;
                    vm.value = []
                })
            },
            getBusinessRateOverview(item){
                return [
                    {label: 'Description', value: item.primary_description_text },
                    {label: 'Local authority', value: item.ba_name },
                    {label: 'Local authority reference', value: item.ba_reference_number },
                    {label: 'Total area', value: item.total_area + 'm²'},
                    {label: 'Base rate', value: item.unadjusted_price.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) },
                    {label: 'Total rateable value', value: item.adopted_rv.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) },
                    {label: 'Basis of measurement', value: item.unit_of_measurement },
                    {label: 'Valuation scheme reference', value: item.scheme_reference },
                    {label: 'Special category code', value: item.scat_code_only },
                    {label: 'Effective date', value: moment(item.from_date).format("MMM Do YYYY") },
                ];
            },
        },
        data(){
            return {
                value: [],
                activeRates: [],
                loading: false
            }
            }

    }
</script>

<style lang="scss">
    .el-collapse--epc-ratings  .el-collapse-item__header {
        line-height: 15px !important;
        height: auto !important;
        padding: 10px !important;
    }

    .el-collapse--epc-ratings .el-collapse-item__content {
        padding-bottom: 0;
    }
</style>