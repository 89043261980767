<template>
    <div>

        <div class="columns">

            <div
                    class="column w-full"
                   >
                <dashboard-widget
                        padding-class="''"
                        title="Registered Addresses">

                    <div class="p-2"
                         v-if="propertiesList.length > 0"
                    >
                        <el-alert
                                effect="dark" type="success" :closable="false">
                           We have found {{ propertiesList.length.toLocaleString() }} registered address{{ propertiesList.length > 1 ? 's' : '' }} within this site
                        </el-alert>
                    </div>

                    <div
                            class="p-2 pt-0"
                         v-if="propertiesList.length > 0 && showMap"
                    >
                        <ownership-uprn-map
                                :bbox="bbox"
                                v-model="uprns"
                        />
                    </div>

                    <el-collapse
                            class="el-collapse--value"
                            v-model="activeProperties">
                        <el-collapse-item
                                :key="item.uprn"
                                v-for="(item, index) of propertiesList"
                                :name="item.uprn">

                            <template slot="title">
                                <div class="flex flex-wrap w-full flex-row">
                                    <div class="w-full flex py-2 pb-3">
                                      <div class="w-5 mr-2 -mt-1 flex-none">
                                          <div class="w-5 h-5 mt-1 flex text-white items-center justify-center rounded-full bg-blue-600 text-xxs ">
                                              {{ index + 1 }}
                                          </div>
                                      </div>
                                        <div class="w-auto">
                                            {{ item.single_line_address }}
                                        </div>
                                    </div>
                                    <div class="text-xs w-full flex">
                                        <div class="mr-3"  v-if="item.property_description">
                                            <span>{{ item.property_description }}</span>
                                        </div>
                                        <div class="mr-3" v-if="item.sqft">
                                            <span>{{ item.sqft.toLocaleString() }} ft²</span>
                                        </div>
                                        <div class="mr-3" v-if="item.tenure">
                                            <span>{{ item.tenure }}</span>
                                        </div>
                                        <div class="mr-3" v-if="item.occupation_type">
                                            <span>{{ item.occupation_type }}</span>
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <div class="p-2 pb-2">



                                <h3 class="uppercase block text-sm font-semibold text-blue-900 mb-2">
                                    Property Details
                                </h3>

                                <div class="columns flex-no-wrap" v-if="item.occupation_type">
                                    <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                                        Occupation type
                                    </div>
                                    <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                        {{ item.occupation_type }}
                                    </div>
                                </div>
                                <div class="columns flex-no-wrap" v-if="item.classification_class_name">
                                    <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                                        Property classification
                                    </div>
                                    <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                        {{ item.classification_class_name }}
                                    </div>
                                </div>

                                <div class="columns flex-no-wrap" v-if="item.classification_class   ">
                                    <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                                        Property classification class
                                    </div>
                                    <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                        {{ item.classification_class }}
                                    </div>
                                </div>

                                <div class="columns flex-no-wrap" v-if="item.property_description">
                                    <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                                        Property type
                                    </div>
                                    <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                        {{ item.property_description }}
                                    </div>
                                </div>


                                <div class="columns flex-no-wrap" v-if="item.construction_age_band">
                                    <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                                        Construction location/age
                                    </div>
                                    <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                        {{ item.construction_age_band }}
                                    </div>
                                </div>


                                <div class="columns flex-no-wrap" v-if="item.tenure">
                                    <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                                        Tenure
                                    </div>
                                    <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                        {{ item.tenure }}
                                    </div>
                                </div>


                                <div class="columns flex-no-wrap" v-if="item.sqft">
                                    <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                                        Floor Area
                                    </div>
                                    <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                        {{ item.sqft.toLocaleString() }} ft²
                                    </div>
                                </div>
                                <div class="columns flex-no-wrap" v-if="item.floor_height">
                                    <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                                        Floor Height
                                    </div>
                                    <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                        {{ item.floor_height }}m
                                    </div>
                                </div>


                                <div class="columns flex-no-wrap" v-if="item.extenion_count">
                                    <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                                        Extensions
                                    </div>
                                    <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                        {{ item.extenion_count }}
                                    </div>
                                </div>

                                <div class="columns flex-no-wrap" v-if="item.number_habitable_rooms">
                                    <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                                        Habitable rooms
                                    </div>
                                    <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                        {{ item.number_habitable_rooms }}
                                    </div>
                                </div>


                                <div class="columns flex-no-wrap" v-if="item.number_heated_rooms">
                                    <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                                        Heated rooms
                                    </div>
                                    <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                        {{ item.number_heated_rooms }}
                                    </div>
                                </div>

                                <div class="columns flex-no-wrap" v-if="item.roof_description">
                                    <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                                        Roof
                                    </div>
                                    <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                        {{ item.roof_description }}
                                    </div>
                                </div>


                                <div class="columns flex-no-wrap" v-if="item.walls_description">
                                    <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                                        Walls
                                    </div>
                                    <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                        {{ item.walls_description }}
                                    </div>
                                </div>


                                <div class="columns flex-no-wrap" v-if="item.windows_description">
                                    <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                                        Windows
                                    </div>
                                    <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                        {{ item.windows_description }}
                                    </div>
                                </div>

                                <div class="columns flex-no-wrap" v-if="item.mainheat_description">
                                    <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                                        Heat Source
                                    </div>
                                    <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                        {{ item.mainheat_description }}
                                    </div>
                                </div>

                                <div class="columns flex-no-wrap" v-if="item.fixed_lighting_outlets_count">
                                    <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                                        Fixed lighting count
                                    </div>
                                    <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                        {{ item.fixed_lighting_outlets_count }}
                                    </div>
                                </div>

                                <div class="columns flex-no-wrap" v-if="item.low_energy_fixed_light_count">
                                    <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                                        Low Energy Fixed lighting count
                                    </div>
                                    <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                        {{ item.low_energy_fixed_light_count }}
                                    </div>
                                </div>





                                <div class="mt-4" v-if="item.leasehold_details">

                                    <h3 class="uppercase block text-sm font-semibold text-blue-900 mb-2">
                                        Leasehold Details
                                    </h3>

                                    <div class="columns flex-no-wrap" v-if="item.leasehold_details.price_paid">
                                        <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                                            Price Paid
                                        </div>
                                        <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                            £{{ item.leasehold_details.price_paid.toLocaleString()  }}
                                        </div>
                                    </div>

                                    <div class="columns flex-no-wrap" v-if="item.leasehold_details.date_of_lease">
                                        <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                                            Date of Lease
                                        </div>
                                        <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                            <date-human-view size="text-xs" v-model="item.leasehold_details.date_of_lease" />
                                        </div>
                                    </div>

                                    <div class="columns flex-no-wrap" v-if="item.leasehold_details.term">
                                        <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                                            Term
                                        </div>
                                        <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                            {{ item.leasehold_details.term }}
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </el-collapse-item>
                    </el-collapse>



                    <div class="p-3" v-if="!propertiesList.length">
                        <empty-state />
                    </div>
                </dashboard-widget>
            </div>
        </div>

    </div>
</template>

<script>
    import EmptyState from "../../EmptyState";
    import OwnershipUprnMap from "./incs/OwnershipUprnMap";
    export default {
        name: "OwnershipProperties",
        components: {OwnershipUprnMap, EmptyState},
        props: {
            value: {
                type: Array,
                default(){
                    return []
                }
            },
            activeSiteId: {
                type: Number,
                default(){
                    return null;
                }
            },
            bbox: {
                type: Array,
                default(){
                    return [];
                }
            }
        },
        data(){
            return {
                activeProperties: [],
                showMap: true
            }
        },
        watch: {
            activeSiteId(){
                let vm = this;
                vm.showMap = false;
                setTimeout(()=> {
                    vm.showMap = true;
                }, 50)
            }
        },
        computed: {
            propertiesList(){
                if(this.activeSiteId){
                    return this.value.filter((i)=>{
                        return i.site_id === this.activeSiteId;
                    })
                }
              return this.value
            },
            uprns(){
                return this.propertiesList.map((i)=>{
                    return {
                        i: i.uprn,
                        lat: i.lat,
                        lon: i.lon
                    }
                })
            }
        }
    }
</script>