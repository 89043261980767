<template>
    <div>

        <div>
            <lottie :options="{animationData: animationData, loop: false}" :height="height" :width="width" />
        </div>
        <h4
                :class="textColor"
                class="text-center text-lg font-semibold mt-6 mb-2">{{ title }}</h4>
        <p
                :class="textColor"
                class="text-center text-sm mb-4">{{ message }}</p>

    </div>
</template>

<script>
    import Lottie from 'vue-lottie';
    import * as animationData from "./incs/Animations/house"

    console.log(animationData);

    export default {
        name: "EmptyState",
        components: {
            Lottie
        },
        props: {
            height: {
              type: Number,
              default(){
                  return 150;
              }
            },
            width: {
                type: Number,
                default(){
                    return 300;
                }
            },
            title: {
                type: String,
                default(){
                    return 'Sorry, but we can\'t find any data.'
                }
            },
            textColor: {
                type: String,
                default(){
                    return ''
                },
            },
            message: {
                type: String,
                default(){
                    return 'If you think this is an error or require help, please contact us.'
                }
            },
            support: {
                type: Boolean,
                default: true
            }
        },
        data(){
            return {
                animationData: animationData.default
            }
        }
    }
</script>

<style scoped>

</style>