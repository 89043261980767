<template>
    <div class="h-screen overflow-hidden flex flex-col">
        <!-- Top nav-->
        <navigation
                v-if="$store.getters['auth/isAuthenticated']"
        />

        <!-- Bottom section -->
        <div class="min-h-0 flex-1 flex">
            <!-- Narrow sidebar-->

            <!-- Main area -->
            <main class="w-full overflow-y-scroll">
               <slot name="header"></slot>
                <slot name="nav"></slot>

                <slot></slot>

                <section aria-labelledby="primary-heading" class="w-full p-4 md:p-6" v-if="showContent">
                    <div class="block h-full w-full xl:container mx-auto">
                        <slot name="content"></slot>
                    </div>
                </section>

            </main>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Page",
        props: {
          showContent: {
              type: Boolean,
              default: true
          }
        }
    }
</script>

<style scoped>

</style>