<template>
    <div id="land-monitor-report">

        <div
                :key="k"
                v-for="(p, k) of getPlots">
            <pdf-page>
                <div class="columns mt-6">
                    <div class="column flex-grow flex items-center">
                        <h3 class="text-xl font-bold">{{ p.property_address }}</h3>
                    </div>
                    <div class="column">
                        <div class="w-12 h-12 bg-blue-600 rounded-full text-white text-xl font-semibold flex items-center justify-center">{{ p.score }}</div>
                    </div>
                </div>
                <div class="columns mt-6">
                    <div class="column w-full">
                        <div style="width: 760px">
                            <ownership-overview-map
                                    class="full"
                                    :height="500"
                                    :coords="{lat: p.lat, lon: p.lon}"
                                    :polygons="[p.feature]"
                                    :bbox="p.bbox"
                            />
                        </div>
                    </div>
                    <div class="column w-full">
                        <div class="el-block--detail mt-3">
                            <div class="flex flex-no-wrap mb-2">
                                <div class="w-auto flex-grow text-xs text-gray-500 font-medium">
                                    Title Number
                                </div>
                                <div class="w-32 text-right text-xs text-gray-900">
                                    {{ p.title_no }}
                                </div>
                            </div>
                            <div class="flex flex-no-wrap mb-2">
                                <div class="w-auto mr-3 flex-grow text-xs text-gray-500 font-medium ">
                                    Ownership
                                </div>
                                <div class="text-right text-xs text-gray-900">
                                    {{ p.owner && p.owner.length > 0 ? p.owner : 'Privately owned' }}
                                </div>
                            </div>
                            <div class="flex flex-no-wrap mb-2">
                                <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                    Total area (ft² / m²)
                                </div>
                                <div class="text-right text-xs text-gray-900">
                                    {{ convertArea('meters', 'feet', p.sqm).toLocaleString() }} ft² / {{ Math.ceil(p.sqm).toLocaleString() }} m²
                                </div>
                            </div>
                            <div class="flex flex-no-wrap mb-2">
                                <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                    Available paved area (ft² / m²)
                                </div>
                                <div class="text-right text-xs text-gray-900">
                                    {{ convertArea('meters', 'feet', p.available_surface_m2).toLocaleString() }} ft² / {{ Math.ceil(p.available_surface_m2).toLocaleString() }} m²
                                </div>
                            </div>
                            <div class="flex flex-no-wrap mb-2">
                                <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                    Available roadside area (ft² / m²)
                                </div>
                                <div class="w-32 text-right text-xs text-gray-900">
                                    {{ convertArea('meters', 'feet', p.available_roadsides_m2).toLocaleString() }} ft² / {{ Math.ceil(p.available_roadsides_m2).toLocaleString() }} m²
                                </div>
                            </div>
                            <div class="flex flex-no-wrap mb-2">
                                <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                    Greenbelt
                                </div>
                                <div class="w-32 text-right text-xs text-gray-900">
                                    <yes-no-component v-model="p.is_greenbelt" />
                                </div>
                            </div>
                            <div class="flex flex-no-wrap mb-2">
                                <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                    Area of Outstanding Natural Beauty
                                </div>
                                <div class="w-32 text-right text-xs text-gray-900">
                                    <yes-no-component v-model="p.is_aonb" />
                                </div>
                            </div>
                            <div class="flex flex-no-wrap mb-2">
                                <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                    Flooding Risk
                                </div>
                                <div class="w-32 text-right text-xs text-gray-900">
                                    {{ p.flooding_risk }}
                                </div>
                            </div>
                            <div class="flex flex-no-wrap mb-2">
                                <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                    Registered Properties
                                </div>
                                <div class="text-right text-xs text-gray-900">
                                    {{ p.properties.length }}
                                </div>
                            </div>
                            <div class="flex flex-no-wrap mb-2">
                                <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                    Property classification(s)
                                </div>
                                <div class="text-right text-xs text-gray-900">
                                    {{ p.classification_codes }}
                                </div>
                            </div>
                            <div class="flex flex-no-wrap mb-2">
                                <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                    Has on site substation
                                </div>
                                <div class="w-32 text-right text-xs text-gray-900">
                                    <yes-no-component :reverse="true"  v-model="p.has_on_site_sub_station" />
                                </div>
                            </div>

                            <div class="flex flex-no-wrap mb-2">
                                <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                    Nearest substation
                                </div>
                                <div class="w-32 text-right text-xs text-gray-900">
                                    {{ Math.ceil(p.nearest_substation).toLocaleString() }}m
                                </div>
                            </div>
                            <div class="flex flex-no-wrap mb-2">
                                <div class="w-auto flex-grow text-xs text-gray-500 font-medium ">
                                    Has on site EV charging
                                </div>
                                <div class="w-32 text-right text-xs text-gray-900">
                                    <yes-no-component v-model="p.has_on_site_ev_chargers" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </pdf-page>


                        <report-title>
                            <slot>Topography</slot>
                        </report-title>

                        <div  style="width: 750px">
                            <ownership-topography-map
                                    :custom-height="true"
                                    :height="400"
                                    :wrapper-class="''"
                                    v-model="p.topography"
                                    :show-polygons="true"
                            />
                        </div>

                        <div :key="k" v-for="(i, k) of getTopographyBreakdowns(p)">
                            <report-sub-title size="text-md">
                                <slot>{{ i.title }}</slot>
                            </report-sub-title>

                            <div class="columns w-full my-3" v-if="i.data.length > 0">
                                <div class="column w-1/2">
                                    <div class="mt-2 mx-auto" style="width: 400px">
                                        <div class="m-2" style="width: 370px">
                                            <apexchart type="pie"
                                                       height="200"
                                                       :options="i.chart.options"
                                                       :series="i.chart.series"></apexchart>
                                        </div>
                                    </div>
                                </div>
                                <div class="column w-1/2">
                                    <table class="el-report-table">
                                        <thead>
                                        <tr>
                                            <th>Address</th>
                                            <th class="text-right" width="100px">Count</th>
                                            <th class="text-right" width="100px">Percentage</th>
                                            <th class="text-right" width="100px">ft²</th>
                                            <th class="text-right" width="100px">m²</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                                :key="k"
                                                v-for="(v, k) of i.data">
                                            <td class="font-bold">
                                                {{ v.label }}
                                            </td>
                                            <td class="text-right">
                                                {{ v.count }}
                                            </td>
                                            <td class="text-right">
                                                {{ v.area.percentage }}%
                                            </td>
                                            <td class="text-right">
                                                {{ Math.ceil(v.area.sqft).toLocaleString() }} ft²
                                            </td>
                                            <td class="text-right">
                                                {{ Math.ceil(v.area.total).toLocaleString() }} m²
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>


                            <div v-if="i.title === 'Type'" class="break-page"></div>

                        </div>


                <div class="break-page"></div>

                <report-sub-title>
                            <slot>Buildings</slot>
                        </report-sub-title>


                        <div class="my-4"  style="width: 750px">
                            <ownership-topography-map
                                    :custom-height="true"
                                    :height="400"
                                    :show-legend="false"
                                    :wrapper-class="''"
                                    v-model="p.buildings"
                                    :show-polygons="true"
                                    :markers="p.buildings.markers"
                            />
                        </div>

                        <table class="el-report-table">
                            <thead>
                            <tr>
                                <th>Building</th>
                                <th class="text-right" width="130px">Estimated Floors</th>
                                <th class="text-right" width="130px">Height to Eaves</th>
                                <th class="text-right" width="130px">Height to Chimney</th>
                                <th class="text-right" width="100px">ft²</th>
                                <th class="text-right" width="100px">m²</th>
                                <th class="text-right" width="100px">%</th>
                                <th class="text-right" width="120px">Change Count</th>
                                <th width="120px">Last Changed</th>
                                <th width="130px">Reason for Change</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr :key="k"
                                v-for="(i, k) of p.buildings.features">
                                <td class="font-bold">

                                    {{ k + 1 }}.  {{ i.properties.registered_address ? i.properties.registered_address : 'Building ' + (k + 1) }}</td>
                                <td class="text-right">{{ i.properties.estimated_floors }}</td>
                                <td class="text-right">
                                    {{ i.properties.rel_h2 }}m
                                </td>
                                <td class="text-right">
                                    {{ i.properties.rel_h2_max }}m
                                </td>
                                <td class="text-right">{{ Math.ceil(i.properties.area_sqft).toLocaleString() }} ft²</td>
                                <td class="text-right">{{ Math.ceil(i.properties.area).toLocaleString() }} m²</td>
                                <td class="text-right">{{ Math.ceil(i.properties.area_percentage).toLocaleString() }}%</td>
                                <td class="text-right">
                                    {{ i.properties.change_count.toLocaleString() }}
                                </td>
                                <td>
                                    {{ i.properties.last_change_date | moment("MMM Do YYYY") }}
                                </td>
                                <td>
                                    {{ i.properties.reason_for_change }}
                                </td>
                            </tr>
                            </tbody>
                        </table>


                <div class="break-page"></div>

                        <report-title>
                            <slot>Local Environment</slot>
                        </report-title>

                        <report-sub-title>
                            <slot>Greenbelt</slot>
                        </report-sub-title>


                        <success-info title="Not Within Greenbelt" v-if="!p.is_greenbelt">
                            This is site is not within Greenbelt
                        </success-info>
                        <missing-info title="Within Greenbelt" v-else>
                            The site is within Greenbelt with a coverage of
                            {{ p.green_belt_coverage.toLocaleString() }}%
                        </missing-info>

                        <report-map
                                :bbox="getBasicMap(p).bbox"
                                :center="getBasicMap(p).center"
                        >
                            <l-geo-json
                                    :options-style="()=>{ return {
                                                    color: $store.state.auth.user.polygon_color,
                                                    weight: 1,
                                                    fillColor: $store.state.auth.user.polygon_color
                                                    } }"
                                    :geojson="p.feature" />

                            <l-wms-tile-layer
                                    base-url="https://tiles.landmonitor.com/geoserver/lm/wms"
                                    layers="lm:greenbelt-eng"
                                    :opacity="1"
                                    :visible="true"
                                    :transparent="true"
                                    class="el-leaflet-item"
                                    format="image/png"
                                    layer-type="base"
                            />

                        </report-map>


                        <report-sub-title>
                            <slot>Area of Outstanding Natural Beauty</slot>
                        </report-sub-title>

                        <success-info title="Not Within Area of Outstanding Natural Beauty" v-if="!p.is_aonb">
                            This is site is not within an Area of Outstanding Natural Beauty
                        </success-info>
                        <missing-info title="Within Area of Outstanding Natural Beauty" v-else>
                            The site is within an Area of Outstanding Natural Beauty with a coverage of
                            {{ p.aonb_coverage.toLocaleString() }}%
                        </missing-info>

                        <report-map
                                :bbox="getBasicMap(p).bbox"
                                :center="getBasicMap(p).center"
                        >
                            <l-geo-json
                                    :options-style="()=>{ return {
                                                    color: $store.state.auth.user.polygon_color,
                                                    weight: 1,
                                                    fillColor: $store.state.auth.user.polygon_color
                                                    } }"
                                    :geojson="p.feature" />

                            <l-wms-tile-layer
                                    base-url="https://tiles.landmonitor.com/geoserver/lm/wms"
                                    layers="lm:aonbe"
                                    :opacity="1"
                                    :visible="true"
                                    :transparent="true"
                                    class="el-leaflet-item"
                                    format="image/png"
                                    layer-type="base"
                            />

                        </report-map>


                <div class="break-page"></div>

                <report-title>
                            <slot>On-site registered addresses</slot>
                        </report-title>


                        <div v-if="p.properties.length > 0">
                            <report-map
                                    :bbox="getOnSiteRegisteredPropertiesMapData(p).bbox"
                                    :center="getOnSiteRegisteredPropertiesMapData(p).center"
                            >
                                <l-geo-json
                                        :options-style="()=>{ return {
                                                    color: $store.state.auth.user.polygon_color,
                                                    weight: 1,
                                                    fillColor: $store.state.auth.user.polygon_color
                                                    } }"
                                        :geojson="p.feature" />

                                <l-marker
                                        :key="index"
                                        v-for="(prop, index) of getOnSiteRegisteredPropertiesMapData(p).points"
                                        :lat-lng="prop">
                                    <l-icon>
                                        <div class="w-4 h-4 text-xxs text-white shadow-lg border-2 flex items-center justify-center bg-purple-600 rounded-full border-solid shadow-sm cursor-pointer">
                                            {{ index + 1 }}
                                        </div>
                                    </l-icon>
                                </l-marker>
                            </report-map>
                        </div>

                        <table class="el-report-table" v-if="p.properties.length > 0">
                            <thead>
                            <tr>
                                <th>Address</th>
                                <th width="130px">Classification</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                    :key="k"
                                    v-for="(p, k) of p.properties">
                                <td class="font-bold">
                                    {{ k + 1 }}.  {{ p.single_line_address }}
                                </td>
                                <td>
                                    {{ p.classification_code }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div v-else>
                            <missing-info />
                        </div>

            <div class="break-page"></div>

            <report-title>
                <slot>Listed Buildings</slot>
            </report-title>

            <report-sub-title>
                <slot>On-site Listed Buildings</slot>
            </report-sub-title>

            <div v-if="p.on_site_listed_buildings.length > 0">
                <report-map
                        :bbox="getOnSiteListedBuildingMapData(p).bbox"
                        :center="getOnSiteListedBuildingMapData(p).center"
                >
                    <l-geo-json
                            :options-style="()=>{ return {
                                                    color: $store.state.auth.user.polygon_color,
                                                    weight: 1,
                                                    fillColor: $store.state.auth.user.polygon_color
                                                    } }"
                            :geojson="p.feature" />

                    <l-marker
                            :key="index"
                            v-for="(prop, index) of getOnSiteListedBuildingMapData(p).points"
                            :lat-lng="prop">
                        <l-icon>
                            <div class="w-4 h-4 text-xxs text-white shadow-lg border-2 flex items-center justify-center bg-purple-600 rounded-full border-solid shadow-sm cursor-pointer">
                                {{ index + 1 }}
                            </div>
                        </l-icon>
                    </l-marker>


                </report-map>
            </div>

            <table class="el-report-table" v-if="p.on_site_listed_buildings.length > 0">
                <thead>
                <tr>
                    <th>Address</th>
                    <th>Operator</th>
                    <th width="110px" class="text-right">Total Chargepoints</th>
                    <th width="110px" class="text-right">Working Chargepoints</th>
                    <th width="130px">Device types</th>
                </tr>
                </thead>
                <tbody>
                <tr
                        :key="k"
                        v-for="(i, k) of p.on_site_listed_buildings">
                    <td class="font-bold">
                        1.  {{ buildEVAddress(i) }}
                    </td>
                    <td>
                        {{ i.network }}
                    </td>
                    <td class="text-right">
                        {{ getTotalEVs(i) }}
                    </td>
                    <td class="text-right">
                        {{ getTotalWorkingEVs(i) }}
                    </td>
                    <td>
                        {{ getEVDeviceTypes(i) }}
                    </td>
                </tr>
                </tbody>
            </table>
            <div v-else>
                <success-info title="No on-site listed buildings found">
                    This site has no known listed buildings on site
                </success-info>
            </div>
            <div class="break-page"></div>

            <report-sub-title>
                <slot>Nearby Listed Buildings</slot>
            </report-sub-title>

            <div v-if="p.nearby_listed_buildings.length > 0">
                <report-map
                        :bbox="getNearbyListedBuildingMapData(p).bbox"
                        :center="getNearbyListedBuildingMapData(p).center"
                >
                    <l-geo-json
                            :options-style="()=>{ return {
                                                    color: $store.state.auth.user.polygon_color,
                                                    weight: 1,
                                                    fillColor: $store.state.auth.user.polygon_color
                                                    } }"
                            :geojson="p.feature" />

                    <l-marker
                            :key="index"
                            v-for="(prop, index) of getNearbyListedBuildingMapData(p).charge_points"
                            :lat-lng="prop">
                        <l-icon>
                            <div class="w-4 h-4 text-xxs text-white shadow-lg border-2 flex items-center justify-center bg-purple-600 rounded-full border-solid shadow-sm cursor-pointer">
                                {{ index + 1 }}
                            </div>
                        </l-icon>
                    </l-marker>


                </report-map>
            </div>

            <table class="el-report-table" v-if="p.nearby_listed_buildings.length > 0">
                <thead>
                <tr>
                    <th>Name</th>
                    <th width="70px">Grade</th>
                    <th width="110px">Date Listed</th>
                    <th width="110px" class="text-right">Distance (m)</th>
                </tr>
                </thead>
                <tbody>
                <tr
                        :key="k"
                        v-for="(i, k) of p.nearby_listed_buildings">
                    <td class="font-bold uppercase">
                        {{ k + 1 }}. {{ i.name }} -  {{ i.location }}
                    </td>
                    <td>
                        {{ i.grade }}
                    </td>
                    <td>
                        {{ i.date_listed | moment("MMM Do YYYY") }}
                    </td>
                    <td class="text-right">
                        {{ i.distance }}m
                    </td>
                </tr>
                </tbody>
            </table>
            <div v-else>
                <success-info title="No nearby listed buildings found">
                    This site has no known nearby listed buildings
                </success-info>
            </div>


            <div class="break-page"></div>

                        <report-title>
                            <slot>EV Charging Infrastructure</slot>
                        </report-title>

                        <report-sub-title>
                            <slot>On-site EV Charging</slot>
                        </report-sub-title>

                        <div v-if="p.on_site_ev_chargers.length > 0">
                            <report-map
                                    :bbox="getOnSiteEVMapData(p).bbox"
                                    :center="getOnSiteEVMapData(p).center"
                            >
                                <l-geo-json
                                        :options-style="()=>{ return {
                                                    color: $store.state.auth.user.polygon_color,
                                                    weight: 1,
                                                    fillColor: $store.state.auth.user.polygon_color
                                                    } }"
                                        :geojson="p.feature" />

                                <l-marker
                                        :key="index"
                                        v-for="(prop, index) of getOnSiteEVMapData(p).charge_points"
                                        :lat-lng="prop">
                                    <l-icon>
                                        <div class="w-4 h-4 text-xxs text-white shadow-lg border-2 flex items-center justify-center bg-purple-600 rounded-full border-solid shadow-sm cursor-pointer">
                                            {{ index + 1 }}
                                        </div>
                                    </l-icon>
                                </l-marker>


                            </report-map>
                        </div>

                        <table class="el-report-table" v-if="p.on_site_ev_chargers.length > 0">
                            <thead>
                            <tr>
                                <th>Address</th>
                                <th>Operator</th>
                                <th width="110px" class="text-right">Total Chargepoints</th>
                                <th width="110px" class="text-right">Working Chargepoints</th>
                                <th width="130px">Device types</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                    :key="k"
                                    v-for="(i, k) of p.on_site_ev_chargers">
                                <td class="font-bold">
                                    1.  {{ buildEVAddress(i) }}
                                </td>
                                <td>
                                    {{ i.network }}
                                </td>
                                <td class="text-right">
                                    {{ getTotalEVs(i) }}
                                </td>
                                <td class="text-right">
                                    {{ getTotalWorkingEVs(i) }}
                                </td>
                                <td>
                                    {{ getEVDeviceTypes(i) }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div v-else>
                            <missing-info />
                        </div>

            <div class="break-page"></div>

            <report-sub-title>
                            <slot>Nearest EV Charging</slot>
                        </report-sub-title>
                        <div v-if="p.nearest_ev_chargers.length > 0">
                            <report-map
                                    :bbox="getLocalEVMapData(p).bbox"
                                    :center="getLocalEVMapData(p).center"
                            >
                                <l-geo-json
                                        :options-style="()=>{ return {
                                                    color: $store.state.auth.user.polygon_color,
                                                    weight: 1,
                                                    fillColor: $store.state.auth.user.polygon_color
                                                    } }"
                                        :geojson="p.feature" />

                                <l-marker
                                        :key="index"
                                        v-for="(prop, index) of getLocalEVMapData(p).charge_points"
                                        :lat-lng="prop">
                                    <l-icon>
                                        <div class="w-4 h-4 text-xxs text-white shadow-lg border-2 flex items-center justify-center bg-purple-600 rounded-full border-solid shadow-sm cursor-pointer">
                                            {{ index + 1 }}
                                        </div>
                                    </l-icon>
                                </l-marker>


                            </report-map>
                        </div>
                        <table class="el-report-table" v-if="p.nearest_ev_chargers.length > 0">
                            <thead>
                            <tr>
                                <th>Address</th>
                                <th>Operator</th>
                                <th width="110px" class="text-right">Total Chargepoints</th>
                                <th width="110px" class="text-right">Working Chargepoints</th>
                                <th width="130px">Device types</th>
                                <th width="110px" class="text-right">Distance (m)</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                    :key="k"
                                    v-for="(i, k) of p.nearest_ev_chargers">
                                <td class="font-bold">
                                    {{ k + 1 }}.  {{ buildEVAddress(i) }}
                                </td>
                                <td>
                                    {{ i.network }}
                                </td>
                                <td class="text-right">
                                    {{ getTotalEVs(i) }}
                                </td>
                                <td class="text-right">
                                    {{ getTotalWorkingEVs(i) }}
                                </td>
                                <td>
                                    {{ getEVDeviceTypes(i) }}
                                </td>
                                <td class="text-right">
                                    {{ Math.ceil(i.distance) }}m
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div v-else>
                            <missing-info />
                        </div>


                        <div class="break-page"></div>

            <report-sub-title>
                <slot>On-site Substations</slot>
            </report-sub-title>
            <div v-if="p.on_site_sub_stations.length > 0">
                <report-map
                        :bbox="getOnSiteSubStation(p).bbox"
                        :center="getOnSiteSubStation(p).center"
                >
                    <l-geo-json
                            :options-style="()=>{ return {
                                                    color: $store.state.auth.user.polygon_color,
                                                    weight: 1,
                                                    fillColor: $store.state.auth.user.polygon_color
                                                    } }"
                            :geojson="p.feature" />

                    <l-marker
                            :key="index"
                            v-for="(prop, index) of getOnSiteSubStation(p).points"
                            :lat-lng="prop">
                        <l-icon>
                            <div class="w-4 h-4 text-xxs text-white shadow-lg border-2 flex items-center justify-center bg-purple-600 rounded-full border-solid shadow-sm cursor-pointer">
                                {{ index + 1 }}
                            </div>
                        </l-icon>
                    </l-marker>


                </report-map>
            </div>
            <table class="el-report-table" v-if="p.on_site_sub_stations.length > 0">
                <thead>
                <tr>
                    <th>Address</th>
                    <th width="110px" class="text-right">Distance (m)</th>
                </tr>
                </thead>
                <tbody>
                <tr
                        :key="k"
                        v-for="(i, k) of p.nearest_sub_stations">
                    <td class="font-bold">
                        {{ k + 1 }}. {{ i.property_address }}
                    </td>
                    <td class="text-right">{{ Math.ceil(i.distance_m) }}m</td>
                </tr>
                </tbody>
            </table>
            <div v-else>
                <missing-info title="No On-site Substations found">
                    This site has no known On-site Substations
                </missing-info>
            </div>

            <div class="break-page"></div>

                        <report-sub-title>
                            <slot>Nearest Substations</slot>
                        </report-sub-title>
                        <div v-if="p.nearest_sub_stations.length > 0">
                            <report-map
                                    :bbox="getNearestSubStationMap(p).bbox"
                                    :center="getNearestSubStationMap(p).center"
                            >
                                <l-geo-json
                                        :options-style="()=>{ return {
                                                    color: $store.state.auth.user.polygon_color,
                                                    weight: 1,
                                                    fillColor: $store.state.auth.user.polygon_color
                                                    } }"
                                        :geojson="p.feature" />

                                <l-marker
                                        :key="index"
                                        v-for="(prop, index) of getNearestSubStationMap(p).points"
                                        :lat-lng="prop">
                                    <l-icon>
                                        <div class="w-4 h-4 text-xxs text-white shadow-lg border-2 flex items-center justify-center bg-purple-600 rounded-full border-solid shadow-sm cursor-pointer">
                                            {{ index + 1 }}
                                        </div>
                                    </l-icon>
                                </l-marker>


                            </report-map>
                        </div>
                        <table class="el-report-table" v-if="p.nearest_sub_stations.length > 0">
                            <thead>
                            <tr>
                                <th>Address</th>
                                <th width="110px" class="text-right">Distance (m)</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                    :key="k"
                                    v-for="(i, k) of p.nearest_sub_stations">
                                <td class="font-bold">
                                    {{ k + 1 }}. {{ i.property_address }}
                                </td>
                                <td class="text-right">{{ Math.ceil(i.distance_m) }}m</td>
                            </tr>
                            </tbody>
                        </table>



                        <div class="break-page"></div>

                        <report-title>
                            <slot>Properties</slot>
                        </report-title>

                        <report-sub-title>
                            <slot>Residential Properties</slot>
                        </report-sub-title>

                        <div v-if="getHeatMap('residential', p)">
                            <report-map
                                    :center="getHeatMap('residential', p).center"
                                    :bbox="getHeatMap('residential', p).bbox"
                            >
                                <l-geo-json
                                        :options-style="()=>{ return {
                                                    color: $store.state.auth.user.polygon_color,
                                                    weight: 1,
                                                    fillColor: $store.state.auth.user.polygon_color
                                                    } }"
                                        :geojson="p.feature" />

                                <l-heat-map
                                        v-if="getHeatMap('residential', p).data.length > 0"
                                        :radius="8"
                                        :blur="10"
                                        :lat-lng="getHeatMap('residential', p).data"
                                ></l-heat-map>

                            </report-map>
                        </div>

                        <table class="el-report-table" v-if="getTableData('dwellings', p).length > 0">
                            <thead>
                            <tr>
                                <th>Distance</th>
                                <th class="text-right" width="130px">Count</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                    :key="k"
                                    v-for="(x, k) of getTableData('dwellings', p)">
                                <td class="font-bold">
                                    {{ x.label }}
                                </td>
                                <td class="text-right">
                                    {{ x.value }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div v-else>
                            <missing-info title="No Residential properties found">
                                This site has no known residential properties within 500 metres.
                            </missing-info>
                        </div>

                        <report-sub-title>
                            <slot>Residential Flats/Apartments</slot>
                        </report-sub-title>

                        <div v-if="getHeatMap('flats', p)">
                            <report-map
                                    :center="getHeatMap('flats', p).center"
                                    :bbox="getHeatMap('flats', p).bbox"
                            >
                                <l-geo-json
                                        :options-style="()=>{ return {
                                                    color: $store.state.auth.user.polygon_color,
                                                    weight: 1,
                                                    fillColor: $store.state.auth.user.polygon_color
                                                    } }"
                                        :geojson="p.feature" />

                                <l-heat-map
                                        v-if="getHeatMap('flats', p).data.length > 0"
                                        :radius="8"
                                        :blur="10"
                                        :lat-lng="getHeatMap('flats', p).data"
                                ></l-heat-map>

                            </report-map>
                        </div>

                        <table class="el-report-table" v-if="getTableData('dwellings_flats', p).length > 0">
                            <thead>
                            <tr>
                                <th>Distance</th>
                                <th class="text-right" width="130px">Count</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                    :key="k"
                                    v-for="(x, k) of getTableData('dwellings_flats', p)">
                                <td class="font-bold">
                                    {{ x.label }}
                                </td>
                                <td class="text-right">
                                    {{ x.value }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div v-else>
                            <missing-info title="No Flats/Apartments found">
                                This site has no known flats/apartments within 500 metres.
                            </missing-info>
                        </div>

                        <report-sub-title>
                            <slot>Residential Terraced </slot>
                        </report-sub-title>

                        <div v-if="getHeatMap('terraced', p)">
                            <report-map
                                    :center="getHeatMap('terraced', p).center"
                                    :bbox="getHeatMap('terraced', p).bbox"
                            >
                                <l-geo-json
                                        :options-style="()=>{ return {
                                                    color: $store.state.auth.user.polygon_color,
                                                    weight: 1,
                                                    fillColor: $store.state.auth.user.polygon_color
                                                    } }"
                                        :geojson="p.feature" />

                                <l-heat-map
                                        v-if="getHeatMap('terraced', p).data.length > 0"
                                        :radius="8"
                                        :blur="10"
                                        :lat-lng="getHeatMap('terraced', p).data"
                                ></l-heat-map>

                            </report-map>
                        </div>

                        <table class="el-report-table" v-if="getTableData('dwellings_terraced', p).length > 0">
                            <thead>
                            <tr>
                                <th>Distance</th>
                                <th class="text-right" width="130px">Count</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                    :key="k"
                                    v-for="(x, k) of getTableData('dwellings_terraced', p)">
                                <td class="font-bold">
                                    {{ x.label }}
                                </td>
                                <td class="text-right">
                                    {{ x.value }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div v-else>
                            <missing-info title="No Terraced housing found">
                                This site has no known terraced housing within 500 metres.
                            </missing-info>
                        </div>




                        <report-sub-title>
                            <slot>Residential Detached/Semi-detached</slot>
                        </report-sub-title>

                        <div v-if="getHeatMap('detached_semi_detached', p)">
                            <report-map
                                    :center="getHeatMap('detached_semi_detached', p).center"
                                    :bbox="getHeatMap('detached_semi_detached', p).bbox"
                            >
                                <l-geo-json
                                        :options-style="()=>{ return {
                                                    color: $store.state.auth.user.polygon_color,
                                                    weight: 1,
                                                    fillColor: $store.state.auth.user.polygon_color
                                                    } }"
                                        :geojson="p.feature" />

                                <l-heat-map
                                        v-if="getHeatMap('detached_semi_detached', p).data.length > 0"
                                        :radius="8"
                                        :blur="10"
                                        :lat-lng="getHeatMap('detached_semi_detached', p).data"
                                ></l-heat-map>

                            </report-map>
                        </div>

                        <table class="el-report-table" v-if="getTableData('dwellings_terraced', p).length > 0">
                            <thead>
                            <tr>
                                <th>Distance</th>
                                <th class="text-right" width="130px">Count</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                    :key="k"
                                    v-for="(x, k) of getTableData('dwellings_detached_semi_detached', p)">
                                <td class="font-bold">
                                    {{ x.label }}
                                </td>
                                <td class="text-right">
                                    {{ x.value }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div v-else>
                            <missing-info title="No Detached/Semi-detached housing found">
                                This site has no known detached/semi-detached housing within 500 metres.
                            </missing-info>
                        </div>




                        <report-sub-title>
                            <slot>Private rentals</slot>
                        </report-sub-title>

                        <div v-if="getHeatMap('private_rentals', p)">
                            <report-map
                                    :center="getHeatMap('private_rentals', p).center"
                                    :bbox="getHeatMap('private_rentals', p).bbox"
                            >
                                <l-geo-json
                                        :options-style="()=>{ return {
                                                    color: $store.state.auth.user.polygon_color,
                                                    weight: 1,
                                                    fillColor: $store.state.auth.user.polygon_color
                                                    } }"
                                        :geojson="p.feature" />

                                <l-heat-map
                                        v-if="getHeatMap('private_rentals', p).data.length > 0"
                                        :radius="8"
                                        :blur="10"
                                        :lat-lng="getHeatMap('private_rentals', p).data"
                                ></l-heat-map>

                            </report-map>
                        </div>

                        <table class="el-report-table" v-if="getTableData('dwellings_private_rentals', p).length > 0">
                            <thead>
                            <tr>
                                <th>Distance</th>
                                <th class="text-right" width="130px">Count</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                    :key="k"
                                    v-for="(x, k) of getTableData('dwellings_private_rentals', p)">
                                <td class="font-bold">
                                    {{ x.label }}
                                </td>
                                <td class="text-right">
                                    {{ x.value }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div v-else>
                            <missing-info title="No Private rentals found">
                                This site has no known private rental's within 500 metres.
                            </missing-info>
                        </div>


                        <report-sub-title>
                            <slot>Social rentals</slot>
                        </report-sub-title>

                        <div v-if="getHeatMap('social_rentals', p)">
                            <report-map
                                    :center="getHeatMap('social_rentals', p).center"
                                    :bbox="getHeatMap('social_rentals', p).bbox"
                            >
                                <l-geo-json
                                        :options-style="()=>{ return {
                                                    color: $store.state.auth.user.polygon_color,
                                                    weight: 1,
                                                    fillColor: $store.state.auth.user.polygon_color
                                                    } }"
                                        :geojson="p.feature" />

                                <l-heat-map
                                        v-if="getHeatMap('social_rentals', p).data.length > 0"
                                        :radius="8"
                                        :blur="10"
                                        :lat-lng="getHeatMap('social_rentals', p).data"
                                ></l-heat-map>

                            </report-map>
                        </div>

                        <table class="el-report-table" v-if="getTableData('dwellings_social_rentals', p).length > 0">
                            <thead>
                            <tr>
                                <th>Distance</th>
                                <th class="text-right" width="130px">Count</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                    :key="k"
                                    v-for="(x, k) of getTableData('dwellings_social_rentals', p)">
                                <td class="font-bold">
                                    {{ x.label }}
                                </td>
                                <td class="text-right">
                                    {{ x.value }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div v-else>
                            <missing-info title="No social rentals found">
                                This site has no known social rental's within 500 metres.
                            </missing-info>
                        </div>



                        <report-sub-title>
                            <slot>Owner-occupied</slot>
                        </report-sub-title>

                        <div v-if="getHeatMap('owner_occupied', p)">
                            <report-map
                                    :center="getHeatMap('owner_occupied', p).center"
                                    :bbox="getHeatMap('owner_occupied', p).bbox"
                            >
                                <l-geo-json
                                        :options-style="()=>{ return {
                                                    color: $store.state.auth.user.polygon_color,
                                                    weight: 1,
                                                    fillColor: $store.state.auth.user.polygon_color
                                                    } }"
                                        :geojson="p.feature" />

                                <l-heat-map
                                        v-if="getHeatMap('owner_occupied', p).data.length > 0"
                                        :radius="8"
                                        :blur="10"
                                        :lat-lng="getHeatMap('owner_occupied', p).data"
                                ></l-heat-map>

                            </report-map>
                        </div>

                        <table class="el-report-table" v-if="getTableData('dwellings_owner_occupied', p).length > 0">
                            <thead>
                            <tr>
                                <th>Distance</th>
                                <th class="text-right" width="130px">Count</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                    :key="k"
                                    v-for="(x, k) of getTableData('dwellings_owner_occupied', p)">
                                <td class="font-bold">
                                    {{ x.label }}
                                </td>
                                <td class="text-right">
                                    {{ x.value }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div v-else>
                            <missing-info title="No Owner occupied properties found">
                                This site has no known owner occupied properties within 500 metres.
                            </missing-info>
                        </div>



                        <report-sub-title>
                            <slot>Registered Offices</slot>
                        </report-sub-title>

                        <div v-if="getHeatMap('offices', p)">
                            <report-map
                                    :center="getHeatMap('offices', p).center"
                                    :bbox="getHeatMap('offices', p).bbox"
                            >
                                <l-geo-json
                                        :options-style="()=>{ return {
                                                    color: $store.state.auth.user.polygon_color,
                                                    weight: 1,
                                                    fillColor: $store.state.auth.user.polygon_color
                                                    } }"
                                        :geojson="p.feature" />

                                <l-heat-map
                                        v-if="getHeatMap('offices', p).data.length > 0"
                                        :radius="8"
                                        :blur="10"
                                        :lat-lng="getHeatMap('offices', p).data"
                                ></l-heat-map>

                            </report-map>
                        </div>

                        <table class="el-report-table" v-if="getTableData('offices', p).length > 0">
                            <thead>
                            <tr>
                                <th>Distance</th>
                                <th class="text-right" width="130px">Count</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                    :key="k"
                                    v-for="(x, k) of getTableData('offices', p)">
                                <td class="font-bold">
                                    {{ x.label }}
                                </td>
                                <td class="text-right">
                                    {{ x.value }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div v-else>
                            <missing-info title="No registered offices found">
                                This site has no known registered offices within 500 metres.
                            </missing-info>
                        </div>


                        <report-sub-title>
                            <slot>Retail and Leisure facilities</slot>
                        </report-sub-title>

                        <div v-if="getHeatMap('retail_leisure_dual_use', p)">
                            <report-map
                                    :center="getHeatMap('retail_leisure_dual_use', p).center"
                                    :bbox="getHeatMap('retail_leisure_dual_use', p).bbox"
                            >
                                <l-geo-json
                                        :options-style="()=>{ return {
                                                    color: $store.state.auth.user.polygon_color,
                                                    weight: 1,
                                                    fillColor: $store.state.auth.user.polygon_color
                                                    } }"
                                        :geojson="p.feature" />

                                <l-heat-map
                                        v-if="getHeatMap('retail_leisure_dual_use', p).data.length > 0"
                                        :radius="8"
                                        :blur="10"
                                        :lat-lng="getHeatMap('retail_leisure_dual_use', p).data"
                                ></l-heat-map>

                            </report-map>
                        </div>

                        <table class="el-report-table" v-if="getTableData('retail_leisure_dual_use', p).length > 0">
                            <thead>
                            <tr>
                                <th>Distance</th>
                                <th class="text-right" width="130px">Count</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                    :key="k"
                                    v-for="(x, k) of getTableData('retail_leisure_dual_use', p)">
                                <td class="font-bold">
                                    {{ x.label }}
                                </td>
                                <td class="text-right">
                                    {{ x.value }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div v-else>
                            <missing-info title="No retail/leisure facilities found">
                                This site has no known retail/leisure facilities within 500 metres.
                            </missing-info>
                        </div>



        </div>


    </div>
</template>

<script>

    import { getClassForMobileCoverage } from "../LandMonitor/OwnershipLayerComponents/incs/BroadbandCoverage"

    import PdfPage from "./incs/PdfPage";

    import moment from 'moment';

    import MissingInfo from "./incs/MissingInfo";
    import ReportTitle from "./incs/ReportTitle";
    import ReportSubTitle from "./incs/ReportSubTitle";
    import ReportMap from "./incs/ReportMap";

    const turf = require("@turf/turf");

    import Data from "./ev-output";
    import OwnershipOverviewMap from "../LandMonitor/OwnershipLayerComponents/incs/OwnershipOverviewMap";
    import SuccessInfo from "./incs/SuccessInfo";
    import YesNoComponent from "./incs/YesNoComponent";
    import OwnershipTopographyMap from "../LandMonitor/OwnershipLayerComponents/incs/OwnershipTopographyMap";

    const plots = Data;

    export default {
        name: "EVReport",
        components: {
            OwnershipTopographyMap,
            YesNoComponent,
            SuccessInfo,
            OwnershipOverviewMap,
            ReportMap,
            ReportSubTitle,
            ReportTitle,
            MissingInfo,
            PdfPage},
        computed: {
            getPlots(){
                return this.plots.map((x) => {
                    let value = JSON.parse(JSON.stringify(x.topography))
                    value.features = value.features.filter((i)=> {
                        return i.properties.type === 'Buildings'
                    })
                    value.bbox = turf.bbox(turf.featureCollection(value.features));

                    value.markers = value.features.map((x) => {
                        return x.properties.center
                    })

                    x.buildings = value;


                    return x;
                })
            },
            administrativeDetails() {
                return [
                    {label: 'Altitude', value: this.demographics.altitude ? this.demographics.altitude : null},
                    {label: 'Parish', value: this.administrativeArea.parish ? this.administrativeArea.parish : null},
                    {label: 'Ward', value: this.administrativeArea.ward ? this.administrativeArea.ward : null},
                    {
                        label: 'Local Authority',
                        value: this.administrativeArea.local_authority ? this.administrativeArea.local_authority : null
                    },
                    {
                        label: 'District',
                        value: this.administrativeArea.district ? this.administrativeArea.district : null
                    },
                    {label: 'County', value: this.administrativeArea.county ? this.administrativeArea.county : null},
                    {label: 'Region', value: this.administrativeArea.region ? this.administrativeArea.region : null},
                    {label: 'Country', value: this.administrativeArea.country ? this.administrativeArea.country : null},

                    {
                        label: 'Police force',
                        value: this.administrativeArea.police_force ? this.administrativeArea.police_force : null
                    },
                    {
                        label: 'Water company',
                        value: this.administrativeArea.water_company ? this.administrativeArea.water_company : null
                    },
                    {
                        label: 'Sewage company',
                        value: this.administrativeArea.sewage_company ? this.administrativeArea.sewage_company : null
                    },

                ];
            },
            demographicsTable() {
                return [
                    {label: 'Rural/urban', value: this.demographics.rural_urban ? this.demographics.rural_urban : null},
                    {
                        label: 'Built up area',
                        value: this.demographics.built_up_area ? this.demographics.built_up_area : null
                    },
                    {
                        label: 'Lower layer super output area',
                        value: this.demographics.lower_layer_super_output_layer ? this.demographics.lower_layer_super_output_layer : null
                    },
                    {
                        label: 'Middle layer super output area',
                        value: this.demographics.middle_layer_super_output_layer ? this.demographics.middle_layer_super_output_layer : null
                    },
                    {
                        label: 'Travel to work area',
                        value: this.demographics.travel_to_work_area ? this.demographics.travel_to_work_area : null
                    },
                ];
            },
            companyOwnershipFreeholders(){
                let vm = this;
                return vm.company_ownership && vm.company_ownership.freeholders  ? vm.company_ownership.freeholders : [];
            },
            companyOwnershipLeaseholders(){
                let vm = this;
                return vm.company_ownership && vm.company_ownership.leaseholders  ? vm.company_ownership.leaseholders : [];
            },


            companyActivityFreeholders(){
                let vm = this;
                return vm.company_activity && vm.company_activity.freeholders  ? vm.company_activity.freeholders : [];
            },
            companyActivityLeaseholders(){
                let vm = this;
                return vm.company_activity && vm.company_activity.leaseholders  ? vm.company_activity.leaseholders : [];
            },

            deprivation() {
                let vm = this;
                return vm.overview && vm.overview.local_authority && vm.overview.local_authority.deprivation_earnings ? vm.overview.local_authority.deprivation_earnings : {};
            },
            administrativeArea() {
                let vm = this;
                return vm.overview && vm.overview.local_authority && vm.overview.local_authority.administrative_area ? vm.overview.local_authority.administrative_area : {};
            },
        },
        methods: {
            getTopographyBreakdowns(p){
                let items = [
                    {title: 'Type', data: p.topography.breakdown.by_type},
                    {title: 'Make', data: p.topography.breakdown.by_make},
                    {title: 'Physical Location', data: p.topography.breakdown.by_physical_locations},
                ].map((x) => {
                    x.chart = {
                            series: x.data.map((i)=>{
                                return i.area.total;
                            }),
                            options: {
                                chart: {
                                    type: 'pie',
                                },
                                labels: x.data.map((i)=>{
                                    return i.label;
                                }),
                                legend: {
                                    position: 'bottom'
                                }
                            }
                        }
                  return x;
                })
                return items;
            },
            convertArea(from, to, value){
                return Math.ceil(turf.convertArea(value, from, to));
            },
            getBasicMap(p){
                let collection = turf.featureCollection([p.feature]),
                    center = turf.center(collection);
                return {
                    bbox: turf.bbox(collection),
                    center: {
                        lat: center.geometry.coordinates[1],
                        lon: center.geometry.coordinates[0],
                    },
                    feature: p.feature
                }
            },
            getTableData(key, p){
                let data = [], items = [100, 200, 300, 400, 500];

                for(let k of items){
                    data.push({
                        label: k + 'm',
                        value: p[key + '_' + k]
                    })
                }
                return data;
            },
            getHeatMap(name, x){
              let data = x.heat_maps[name];

                const features = data.map((p)=> {
                    return turf.point([p[1], p[0]])
                });
                features.push(x.feature);

                let collection = turf.featureCollection(features),
                    center = turf.center(collection);
                return {
                    bbox: turf.bbox(collection),
                    center: {
                        lat: center.geometry.coordinates[1],
                        lon: center.geometry.coordinates[0],
                    },
                    feature: x.feature,
                    data: data
                }

            },
            getNearestSubStationMap(x){
                const features = x.nearest_sub_stations.map((p)=> {
                    return turf.point([p.lon, p.lat])
                });
                features.push(x.feature);

                let collection = turf.featureCollection(features),
                    center = turf.center(collection);
                return {
                    bbox: turf.bbox(collection),
                    center: {
                        lat: center.geometry.coordinates[1],
                        lon: center.geometry.coordinates[0],
                    },
                    feature: x.feature,
                    points: x.nearest_sub_stations.map((y) => {
                        return {
                            lat: y.lat,
                            lon: y.lon
                        }
                    })
                }
            },
            getOnSiteSubStation(x){
                const features = x.on_site_sub_stations.map((p)=> {
                    return turf.point([p.lon, p.lat])
                });
                features.push(x.feature);

                let collection = turf.featureCollection(features),
                    center = turf.center(collection);
                return {
                    bbox: turf.bbox(collection),
                    center: {
                        lat: center.geometry.coordinates[1],
                        lon: center.geometry.coordinates[0],
                    },
                    feature: x.feature,
                    points: x.on_site_sub_stations.map((y) => {
                        return {
                            lat: y.lat,
                            lon: y.lon
                        }
                    })
                }
            },
            getOnSiteRegisteredPropertiesMapData(x){
                const features = x.properties.map((p)=> {
                    return turf.point([p.lon, p.lat])
                });
                features.push(x.feature);

                let collection = turf.featureCollection(features),
                    center = turf.center(collection);
                return {
                    bbox: turf.bbox(collection),
                    center: {
                        lat: center.geometry.coordinates[1],
                        lon: center.geometry.coordinates[0],
                    },
                    feature: x.feature,
                    points: x.properties.map((y) => {
                        return {
                            lat: y.lat,
                            lon: y.lon
                        }
                    })
                }
            },
            getOnSiteListedBuildingMapData(x){
                const features = x.on_site_listed_buildings.map((p)=> {
                    return turf.point([p.lon, p.lat])
                });
                features.push(x.feature);

                let collection = turf.featureCollection(features),
                    center = turf.center(collection);
                return {
                    bbox: turf.bbox(collection),
                    center: {
                        lat: center.geometry.coordinates[1],
                        lon: center.geometry.coordinates[0],
                    },
                    feature: x.feature,
                    points: x.on_site_listed_buildings.map((y) => {
                        return {
                            lat: y.lat,
                            lon: y.lon
                        }
                    })
                }
            },
            getNearbyListedBuildingMapData(x){
                const features = x.nearby_listed_buildings.map((p)=> {
                    return turf.point([p.lon, p.lat])
                });
                features.push(x.feature);

                let collection = turf.featureCollection(features),
                    center = turf.center(collection);
                return {
                    bbox: turf.bbox(collection),
                    center: {
                        lat: center.geometry.coordinates[1],
                        lon: center.geometry.coordinates[0],
                    },
                    feature: x.feature,
                    charge_points: x.nearby_listed_buildings.map((y) => {
                        return {
                            lat: y.lat,
                            lon: y.lon
                        }
                    })
                }
            },
            getOnSiteEVMapData(x){
                const features = x.on_site_ev_chargers.map((p)=> {
                    console.log(turf);
                    return turf.point([p.lon, p.lat])
                });
                features.push(x.feature);

                let collection = turf.featureCollection(features),
                    center = turf.center(collection);
                return {
                    bbox: turf.bbox(collection),
                    center: {
                        lat: center.geometry.coordinates[1],
                        lon: center.geometry.coordinates[0],
                    },
                    feature: x.feature,
                    charge_points: x.on_site_ev_chargers.map((y) => {
                        return {
                            lat: y.lat,
                            lon: y.lon
                        }
                    })
                }
            },

            getLocalEVMapData(x){

                const features = x.nearest_ev_chargers.map((p)=> {
                    console.log(turf);
                    return turf.point([p.lon, p.lat])
                });
                features.push(x.feature);

                let collection = turf.featureCollection(features),
                    center = turf.center(collection);
                return {
                    bbox: turf.bbox(collection),
                    center: {
                        lat: center.geometry.coordinates[1],
                        lon: center.geometry.coordinates[0],
                    },
                    feature: x.feature,
                    charge_points: x.nearest_ev_chargers.map((y) => {
                        return {
                            lat: y.lat,
                            lon: y.lon
                        }
                    })
                }
            },
            buildEVAddress(x){
                return [
                    x.name,
                    x.address_thoroughfare,
                    x.address_street,
                    x.address_town,
                    x.address_county,
                    x.address_postcode
                ].filter((y) => y && y.length > 0).join(', ')
            },
            getTotalEVs(x){
                return x['devices.is_working'].length;
            },
            getTotalWorkingEVs(x){
                return x['devices.is_working'].filter((y) => y === 1).length;
            },
            getEVDeviceTypes(x) {
                return x['device_connectors.name'].filter((value, index, self) => {
                    return self.indexOf(value) === index;
                }).join(', ')
            },
            getClassForMobileCoverage(rating){
                return getClassForMobileCoverage(rating);
            },
            topographyListWithHeights(items){
              return items.filter((item)=>{
                  return item.abs_h_min;
              })
            },
            getBusinessRateOverview(item){
                return [
                    {label: 'Description', value: item.primary_description_text },
                    {label: 'Local authority', value: item.ba_name },
                    {label: 'Local authority reference', value: item.ba_reference_number },
                    {label: 'Total area', value: item.total_area + 'm²'},
                    {label: 'Base rate', value: item.unadjusted_price.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) },
                    {label: 'Total rateable value', value: item.adopted_rv.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) },
                    {label: 'Basis of measurement', value: item.unit_of_measurement },
                    {label: 'Valuation scheme reference', value: item.scheme_reference },
                    {label: 'Special category code', value: item.scat_code_only },
                    {label: 'Effective date', value: moment(item.from_date).format("MMM Do YYYY") },
                ];
            },
            buildTrafficAnalysisMap(data) {

                let obj = {
                    size: '800x400',
                    maptype: 'hybrid',
                    center: data.latitude + ',' + data.longitude,
                    zoom: 16,
                    markers: 'color:red|label:' + data.road_name + '|' + data.latitude + ',' + data.longitude,
                    key: this.$store.state.auth.map.google.key,
                }

                let str = [];


                for (let p in obj) {
                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                }

                return 'http://maps.googleapis.com/maps/api/staticmap?' + str.join('&')
            },
        },
        created() {

        },
        data() {
            return {
                plots: plots,
            }
        }
    }
</script>

<style lang="scss">


    .body {
        background: #fff !important;
    }

    #land-monitor-report {


        .el-report-table {
            width: 100%;
            font-size: 14px !important;
            margin-bottom: 40px;
            border: 1px solid #dddddd;

            td, th {
                padding: 0.5rem 0.7rem;
                font-size: 0.7em;
            }

            th {
                border-top: 1px solid #dddddd;
                border-bottom: 1px solid #dddddd;
                text-align: left;
                text-transform: uppercase;
                letter-spacing: 0.001rem;
                font-weight: bold;
                color: rgba(0, 23, 98, 0.82);
            }

            td {
                vertical-align: top;
                border-bottom: 1px solid #dddddd;
            }
        }

        table {
            page-break-inside: auto;
        }

        tr {
            page-break-inside: avoid;
            page-break-after: auto
        }


        @media print {
            @page {
                margin: 5mm 5mm 5mm 5mm;
            }

            * {
                -webkit-print-color-adjust: exact !important;
            }


            .el-block--report-widget {
                border-color: #f2f2f2 !important;
                border: 1px solid;

                .title {
                    border-color: #f2f2f2 !important;
                    background: #0d203b;

                    h3 {
                        color: #fff !important;
                        font-size: 0.6rem !important;
                    }
                }
            }


            .is-full-page {
                width: 100%;
                height: 100%;
                position: absolute;
                display: flex;
                justify-items: center;
                align-items: center;
                text-align: center;
                color: #fff;
                -webkit-print-color-adjust: exact !important;
            }
            .break-page {
                page-break-after: always;
            }
        }
    }

    @media screen {
        div.divFooter {
            display: none;
        }
    }
    @media print {
        div.divFooter {
            position: fixed;
            bottom: 0;
        }
    }

    #PapercupsChatWidget {
        display: none !important;
    }

</style>