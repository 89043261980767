<template>
    <el-select
            class="w-full"
            @change="()=>{ $emit('change') }"
            placeholder="Select Search Area..."
            size="medium"
            :disabled="disabled"
            v-model="$store.state.map.search_area">
        <el-option-group
                v-for="item in $store.state.map.search_areas"
                :key="item.label"
                :label="item.label">
            <el-option
                    v-for="i in item.options"
                    :key="i.value"
                    :label="i.label"
                    :value="i.value">
                {{ i.label }}
            </el-option>
        </el-option-group>
    </el-select>
</template>

<script>
    export default {
        name: "MapBoundsSelect",
        props: {
            disabled: {
                type: Boolean,
                default(){
                    return false
                }
            }
        }
    }
</script>

<style scoped>

</style>