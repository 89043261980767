<template>

        <page v-loading="deleting">

            <template slot="header">
                <page-header
                        :title="team.name ? team.name + ' Settings' : 'Team Settings'">
                    <template v-slot:tabs>
                        <active-team-nav />
                    </template>
                </page-header>

            </template>

            <template slot="content">
                <div class="columns">
                    <div class="column w-full">

                        <dashboard-widget
                                title="Team Settings">


                            <div class="p-8" v-if="loading">
                                <property-loader />
                            </div>

                            <div class="columns w-full" v-if="!loading">
                                <div class="column w-48">
                                    <div class="w-32 mt-4 mx-auto">
                                        <filepond-avatar
                                                @change="(val)=>{ team.avatar = val; }"
                                                v-model="team.avatar"
                                        />
                                    </div>

                                </div>
                                <div class="column flex-grow">
                                    <el-form class="my-6" label-position="left" label-width="120px"
                                             :rules="rules" ref="teamForm"
                                             :model="team">
                                        <el-form-item label="Name" prop="name">
                                            <el-input v-model="team.name"
                                                      :disabled="updating"
                                            />
                                        </el-form-item>



                                    </el-form>

                                    <div class="columns">
                                        <div class="column w-full text-right">
                                            <el-button
                                                    @click="preUpdate"
                                                    :disabled="updating"
                                                    :loading="updating"
                                                    icon="el-icon-refresh-right"
                                                    type="success">
                                                Update
                                            </el-button>
                                        </div>
                                    </div>

                                </div>
                            </div>



                        </dashboard-widget>
                    </div>

                    <div class="column w-full">

                        <dashboard-widget
                                title="Delete Team">


                            <div class="p-8" v-if="loading">
                                <property-loader />
                            </div>

                            <div class="columns" v-if="!loading">
                                <div class="column w-full text-left">
                                    <el-alert
                                            :closable="false"
                                            type="error"
                                            effect="dark">Warning! Deleting your team cannot be  undone. You will forfeit all current team subscriptions and all team members will be removed.</el-alert>

                                    <div class="mt-3 text-sm leading-loose">
                                       <p>If you wish to delete this team, simply confirm by clicking the button  below.</p>
                                   </div>

                                </div>
                                <div class="column w-full text-right">
                                    <el-button
                                            @click="confirmDelete"
                                            :disabled="updating"
                                            :loading="updating"
                                            icon="el-icon-delete"
                                            type="danger">
                                        Delete Team
                                    </el-button>
                                </div>
                            </div>

                        </dashboard-widget>
                    </div>
                </div>
            </template>


        </page>

</template>
<script>

    import ActiveTeamNav from "./ActiveTeamNav";
    import FilepondAvatar from "../../components/FilepondAvatar";
    import PropertyLoader from "../../components/incs/PropertyLoader";

    export default {
        name: 'TeamSettings',
        components: {PropertyLoader, FilepondAvatar, ActiveTeamNav},

        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.team = {};
                vm.loading = true;
                vm.$http.get('/api/v1/team/' + vm.$route.params.teamId)
                    .then((response) => {

                        vm.team = response.data.data;

                        vm.$store.commit('auth/setBreadcrumbs', [
                            {
                                title: 'My Teams',
                                path: '/teams'
                            },
                            {
                                title: response.data.data.name,
                                path: '/team/' + response.data.data.id
                            },
                            {
                                title: 'Settings',
                            }
                        ]);



                        vm.loading = false;

                    }).catch(() => {

                })
            })
        },
        methods: {
            confirmDelete(){
                const vm = this;
                this.$confirm('Are you sure you want to delete this team? This cannot be undone.', 'Please confirm deletion', {
                    confirmButtonText: 'Delete Team',
                    cancelButtonText: 'Cancel'
                }).then(() => {
                    vm.deleting = true;
                    vm.$store.dispatch('team/delete', {
                        teamId: vm.$route.params.teamId
                    }).then(()=>{
                        console.log('Deleted')
                    }).catch((e)=>{
                        vm.deleting = false;
                        vm.$store.dispatch('auth/handleServerError', {
                            vm: vm,
                            error: e
                        });
                    })

                }).catch(()=>{

                })
            },
            preUpdate(){
                let vm = this;
                vm.$refs['teamForm'].validate((valid) => {
                    if (!valid) {
                        return false;
                    } else {
                        vm.update();
                    }
                });
            },
            update(){
                let vm = this;
                console.log(vm.team);
                vm.updating = true;
                vm.$http.put('/api/v1/team/' + vm.$route.params.teamId, {
                    name: vm.team.name,
                    avatar: vm.team.avatar
                })
                    .then(() => {

                        vm.updating = false;

                        vm.$notify({
                            type: 'success',
                            title: 'Team Updated',
                            message: 'Team updated successfully',
                            position: 'top-right',
                            offset: 140,
                        });

                    })
                    .catch((e) => {
                        vm.updating = false;
                        vm.$store.dispatch('auth/handleServerError', {
                            vm: vm,
                            error: e
                        });
                    })
            }
        },
        data(){
            return {
                deleting: false,
                loading: true,
                updating: false,
                rules: {
                    name: [
                        {required: true, message: 'A name is required', trigger: 'blur'}
                    ],
                },
                team: {
                    avatar: null,
                    name: null
                },
            }
        }
    }
</script>
<style>
    .el-option-select--seat-data {
        height: auto !important;
    }
</style>
