<template>
    <div>

        <navigation/>

        <el-drawer
                size="600px"
                :visible.sync="applicationVisible"
                :with-header="false"
                direction="ltr">

            <div
                    @click="applicationVisible = false"
                    class="absolute w-8 h-8 rounded-full bg-gray-700 text-white top-0 right-0 m-4 flex items-center  cursor-pointer justify-center text-center">
                <i class="el-icon-close text-lg mx-auto"></i>
            </div>

            <div
                    class="absolute z-40 p-4 w-full bottom-0 bg-gray-300 border-t border-gray-400 shadow-lg">
                <div class="columns">
                    <div class="column w-1/2">
                        <el-button

                                @click="toggleWatchlist($store.state.applications.application.id)"
                                :disabled="$store.state.watchlist.running"
                                :loading="$store.state.watchlist.running"
                                class="w-full"
                                :type="$store.state.applications.application.watching ? 'warning' : 'success'"
                                :icon="$store.state.applications.application.watching ? 'el-icon-delete' : 'el-icon-star-off'">
                            {{ $store.state.watchlist.running ? 'Updating...' :
                            $store.state.applications.application.watching ? 'Unwatch' : 'Watch' }}
                        </el-button>
                    </div>
                    <div class="column w-1/2">
                        <el-button class="w-full"
                                   :disabled="!$store.state.applications.application.url"
                                   @click="openUrl($store.state.applications.application.url)"
                                   type="info">
                            View
                        </el-button>
                    </div>
                </div>
            </div>

            <div class="flex items-center flex-col content-center justify-center relative w-full h-full p-4 bg-gray-900"
                 v-if="$store.state.applicationMap.draw.loading || !Object.keys($store.state.applications.application).length">
                <h4 class="text-4xl w-full text-center text-white">
                    <i class="el-icon-loading"></i>
                </h4>
                <h4 class="text-xl w-full text-center text-white">Loading Application...</h4>
            </div>

            <div v-if="!$store.state.applicationMap.draw.loading && Object.keys($store.state.applications.application).length > 0"
                 class="bg-gray-900 p-4">
                <div class="columns flex-grow">
                    <div class="column flex-grow">
                        <h3 class="text-lg text-white">
                            Planning Application
                        </h3>
                        <p class="text-white py-2">
                            <span class="el-icon-location mr-1"></span>
                            <small>
                                {{ $store.state.applications.application.site_address }}
                            </small>
                        </p>
                        <div class="columns text-xs flex items-center">
                            <div class="column text-white">
                                {{ $store.state.applications.application.local_authority }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!$store.state.applicationMap.draw.loading" class="el-block--application-map-tabs">
                <el-tabs v-model="$store.state.applicationMap.details.activeTab">
                    <el-tab-pane label="Overview" name="details">
                        <application-details/>
                    </el-tab-pane>
                    <el-tab-pane label="Agent" name="agent">
                        <agent-details/>
                    </el-tab-pane>
                    <el-tab-pane label="Timeline" name="timeline">
                        <div class="p-4 bg-gray-200">
                            <dashboard-widget title="Application Timeline">
                                <slot>
                                    <application-timeline/>
                                </slot>
                            </dashboard-widget>
                        </div>


                    </el-tab-pane>
                    <el-tab-pane label="Map" name="map">
                        <location-map/>
                    </el-tab-pane>
                    <el-tab-pane label="Property Prices" name="prices">

                        <div class="p-4 bg-gray-200">
                            <application-house-prices
                                    class-name="w-full"
                                    stat-box-class="w-full md:w-1/2"
                                    :hash="$store.state.applications.application.id"
                            />
                        </div>


                    </el-tab-pane>
                    <el-tab-pane label="Local Area" name="area">


                        <div class="p-4 bg-gray-200">

                            <div class="columns">
                                <div class="column w-full">
                                    <dashboard-widget
                                            padding-class="''"
                                            title="Nearest Train Stations">
                                        <slot>
                                            <el-table
                                                    class="el-table--sale-history"
                                                    :data="$store.getters['applications/getNearest']('stations')"
                                                    stripe
                                                    style="width: 100%">
                                                <el-table-column
                                                        prop="common_name"
                                                        label="Name">
                                                </el-table-column>
                                                <el-table-column
                                                        prop="distance_miles"
                                                        align="right"
                                                        label="Distance (Miles)">
                                                </el-table-column>
                                            </el-table>
                                        </slot>
                                    </dashboard-widget>
                                </div>
                                <div class="column w-full">
                                    <dashboard-widget
                                            padding-class="''"
                                            title="Nearest Airports">
                                        <slot>
                                            <el-table
                                                    class="el-table--sale-history"
                                                    :data="$store.getters['applications/getNearest']('airports')"
                                                    stripe
                                                    style="width: 100%">
                                                <el-table-column
                                                        prop="common_name"
                                                        label="Name">
                                                </el-table-column>
                                                <el-table-column
                                                        prop="distance_miles"
                                                        align="right"
                                                        label="Distance (Miles)">
                                                </el-table-column>
                                            </el-table>
                                        </slot>
                                    </dashboard-widget>
                                </div>
                                <div class="column w-full">
                                    <dashboard-widget
                                            padding-class="''"
                                            title="Nearest Coach Stations">
                                        <slot>
                                            <el-table
                                                    class="el-table--sale-history"
                                                    :data="$store.getters['applications/getNearest']('coaches')"
                                                    stripe
                                                    style="width: 100%">
                                                <el-table-column
                                                        prop="common_name"
                                                        label="Name">
                                                </el-table-column>
                                                <el-table-column
                                                        prop="distance_miles"
                                                        align="right"
                                                        label="Distance (Miles)">
                                                </el-table-column>
                                            </el-table>
                                        </slot>
                                    </dashboard-widget>
                                </div>
                                <div class="column w-full">
                                    <dashboard-widget
                                            padding-class="''"
                                            title="Nearest Ferries">
                                        <slot>
                                            <el-table
                                                    class="el-table--sale-history"
                                                    :data="$store.getters['applications/getNearest']('ferries')"
                                                    stripe
                                                    style="width: 100%">
                                                <el-table-column
                                                        prop="common_name"
                                                        label="Name">
                                                </el-table-column>
                                                <el-table-column
                                                        prop="distance_miles"
                                                        align="right"
                                                        label="Distance (Miles)">
                                                </el-table-column>
                                            </el-table>
                                        </slot>
                                    </dashboard-widget>
                                </div>
                            </div>
                        </div>

                    </el-tab-pane>
                </el-tabs>
            </div>


        </el-drawer>

        <div class="el-block--map-wrapper">
            <div class="el-block--details overflow-scroll">

                <div class="p-3 py-6 bg-gray-900">
                    <h4 class="text-2xl text-gray-200 font-semibold">Planning Applications</h4>

                    <div class="columns text-xs text-white pt-2">
                        <div class="column items-center flex">
                            <i
                                    class="mr-1"
                                    :class="{
                                'el-icon-loading': $store.state.applicationMap.loading.location,
                                'el-icon-location': !$store.state.applicationMap.loading.location
                                }"></i>
                            {{ $store.state.applicationMap.loading.location ? 'Loading' :
                            $store.getters['applicationMap/getLocationAddress'] }}
                        </div>
                        <div class="column items-center flex">
                            <i
                                    class="mr-1"
                                    :class="{
                                'el-icon-loading': $store.state.applicationMap.loading.location,
                                'el-icon-map-location': !$store.state.applicationMap.loading.location
                                }"></i>
                            {{ $store.state.applicationMap.loading.location ? 'Loading' :
                            $store.getters['applicationMap/getLocationArea'] ?
                            $store.getters['applicationMap/getLocationArea'] : '' }}
                        </div>
                    </div>

                </div>

                <!--
                   'bg-green-600 border-green-900': prop.decision_simplified === 'APPROVED',
                                    'bg-red-600 border-red-900': prop.decision_simplified === 'REFUSED',
                                    'bg-blue-600 border-blue-900': prop.decision_simplified === 'PENDING',
                                    'bg-orange-400 border-orange-600': prop.decision_simplified === 'SPLIT',
                                    'bg-gray-300 border-gray-600': prop.decision_simplified === 'WITHDRAWN',
                -->

                <div class="p-3">
                    <div class="columns w-full">
                        <div class="column w-1/2">
                            <div class="p-3 rounded shadow bg-white border-gray-200">
                                <h5 class="text-sm font-medium text-blue-800">Total</h5>
                                <h5 class="text-2xl text-center my-1 font-medium text-blue-800">
                                    <i v-if="$store.getters['applicationMap/isLoadingMap']" class="el-icon-loading"></i>
                                    <span v-if="!$store.getters['applicationMap/isLoadingMap']">
                                                                          {{ $store.getters['applicationMap/getStat']('total') }}
                                    </span>
                                </h5>
                            </div>
                        </div>
                        <div class="column w-1/2">
                            <div class="p-3 rounded shadow bg-blue-600">
                                <h5 class="text-sm font-medium text-blue-100">Pending</h5>
                                <h5 class="text-2xl text-center text-blue-100 my-1 font-medium">
                                    <i v-if="$store.getters['applicationMap/isLoadingMap']" class="el-icon-loading"></i>
                                    <span v-if="!$store.getters['applicationMap/isLoadingMap']">
                                        {{ $store.getters['applicationMap/getStat']('pending') }}
                                    </span>
                                </h5>
                            </div>
                        </div>
                        <div class="column w-1/2">
                            <div class="p-3 rounded shadow bg-green-600">
                                <h5 class="text-sm font-medium text-green-100">Approved</h5>
                                <h5 class="text-2xl text-center text-green-100 my-1 font-medium">
                                    <i v-if="$store.getters['applicationMap/isLoadingMap']" class="el-icon-loading"></i>
                                    <span v-if="!$store.getters['applicationMap/isLoadingMap']">
                                        {{ $store.getters['applicationMap/getStat']('approved') }}
                                    </span>
                                </h5>
                            </div>
                        </div>
                        <div class="column w-1/2">
                            <div class="p-3 rounded shadow bg-red-600">
                                <h5 class="text-sm font-medium text-red-100">Refused</h5>
                                <h5 class="text-2xl text-center text-red-100 my-1 font-medium">
                                    <i v-if="$store.getters['applicationMap/isLoadingMap']" class="el-icon-loading"></i>
                                    <span v-if="!$store.getters['applicationMap/isLoadingMap']">
                                        {{ $store.getters['applicationMap/getStat']('refused') }}
                                    </span>
                                </h5>
                            </div>
                        </div>
                        <div class="column w-1/2">
                            <div class="p-3 rounded shadow bg-orange-400">
                                <h5 class="text-sm font-medium text-orange-100">Split Decision</h5>
                                <h5 class="text-2xl text-center text-orange-100 my-1 font-medium">
                                    <i v-if="$store.getters['applicationMap/isLoadingMap']" class="el-icon-loading"></i>
                                    <span v-if="!$store.getters['applicationMap/isLoadingMap']">
                                        {{ $store.getters['applicationMap/getStat']('split') }}
                                    </span>
                                </h5>
                            </div>
                        </div>
                        <div class="column w-1/2">
                            <div class="p-3 rounded shadow bg-gray-600">
                                <h5 class="text-sm font-medium text-gray-100">Withdrawn</h5>
                                <h5 class="text-2xl text-center text-gray-100 my-1 font-medium">
                                    <i v-if="$store.getters['applicationMap/isLoadingMap']" class="el-icon-loading"></i>
                                    <span v-if="!$store.getters['applicationMap/isLoadingMap']">
                                        {{ $store.getters['applicationMap/getStat']('withdrawn') }}
                                    </span>
                                </h5>
                            </div>
                        </div>
                        <div class="column w-full">
                            <dashboard-widget title="Local Authorities">
                                <slot>

                                    <div
                                            v-if="$store.getters['applicationMap/isLoadingMap']"
                                            class="text-4xl text-center">
                                        <i class="el-icon-loading"></i>
                                    </div>


                                    <el-table
                                            v-if="!$store.getters['applicationMap/isLoadingMap']"
                                            :default-sort="{prop: 'authority_name', order: 'ascending'}"
                                            :data="$store.getters['applicationMap/getLocalAuthorities']"
                                            stripe
                                            style="width: 100%">
                                        <el-table-column
                                                prop="authority_name"
                                                sortable
                                                label="Authority">
                                        </el-table-column>
                                        <el-table-column
                                                prop="total"
                                                sortable
                                                align="right"
                                                label="Applications">
                                        </el-table-column>
                                    </el-table>

                                </slot>
                            </dashboard-widget>
                        </div>
                    </div>
                </div>

            </div>
            <div class="el-block--map">

                <div class="el-block--map-search">
                    <div class="columns">
                        <div class="column flex-grow">
                            <div class="text-xs uppercase mr-1 mb-1 text-gray-100">Location Search</div>
                            <vue-google-autocomplete
                                    id="google-autocomplete"
                                    classname="el-input__inner"
                                    :enable-geolocation="true"
                                    placeholder="Search for a location"
                                    :country="['uk']"
                                    types="geocode"
                                    v-on:placechanged="(opts)=>{ onLocationSearchChange(opts) }"
                            >
                            </vue-google-autocomplete>
                        </div>
                        <div class="column">
                            <div class="text-xs uppercase mr-1 mb-1 text-gray-100">Date</div>
                            <el-date-picker
                                    v-model="$store.state.applicationMap.search.dateRange"
                                    :clearable="false"
                                    :disabled="$store.getters['applicationMap/isLoadingMap']"
                                    :picker-options="pickerOptions"
                                    type="daterange"
                                    format="dd MMM yyyy"
                                    range-separator="To"
                                    start-placeholder="Start date"
                                    @change="()=>{ search() }"
                                    end-placeholder="End date">
                            </el-date-picker>
                        </div>
                        <div class="column">
                            <div class="text-xs uppercase mr-1 mb-1 text-gray-100">Date Group by</div>
                            <el-select
                                    @change="()=>{ search() }"
                                    v-model="$store.state.applicationMap.search.date_group_by"
                                    :disabled="$store.getters['applicationMap/isLoadingMap']"
                                    placeholder="Group by Date...">
                                <el-option
                                        v-for="item in $store.state.applicationMap.date_ranges"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="column">
                            <div class="text-xs uppercase mr-1 mb-1 text-gray-100">Decision</div>
                            <el-select
                                    @change="()=>{ search() }"
                                    v-model="$store.state.applicationMap.search.decision"
                                    :disabled="$store.getters['applicationMap/isLoadingMap']"
                                    placeholder="Application Decision...">
                                <el-option
                                        v-for="item in $store.state.applicationMap.decision"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="column flex-grow">
                            <div class="text-xs uppercase mr-1 mb-1 text-gray-100">Search</div>

                            <el-input
                                    @keyup.enter.native="search()"
                                    :disabled="$store.getters['applicationMap/isLoadingMap']"
                                    v-model="$store.state.applicationMap.search.term">
                                <el-select
                                        slot="prepend"
                                        class="w-32"
                                        @change="()=>{ search() }"
                                        :disabled="$store.getters['applicationMap/isLoadingMap']"
                                        v-model="$store.state.applicationMap.search.term_match" placeholder="Match...">
                                    <el-option
                                            v-for="item in $store.state.applicationMap.term_matches"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-input>

                        </div>
                    </div>
                </div>

                <div class="el-block--map-lmap relative">
                    <l-map
                            :min-zoom="$store.state.auth.map.min_zoom"
                            :max-zoom="$store.state.auth.map.max_zoom"
                            :max-bounds="$store.getters['auth/getMaxBounds']"
                            ref="map"
                            :center="$store.state.applicationMap.active.center"
                            :zoom="$store.state.applicationMap.zoom"
                            @update:center="onCenterUpdated"
                            @update:bounds="onBoundsUpdated"
                            @update:zoom="onZoomUpdate"
                            @ready="onMapReady"
                    >
                        <!--
                       $store.state.applicationMap.properties
-->
                        <v-cluster
                                v-if="!$store.getters['applicationMap/isLoadingMap']"
                        >
                            <l-marker
                                    :key="index"
                                    v-for="(prop, index) of $store.state.applicationMap.properties"
                                    :lat-lng="prop.coords" @click="openApplication(prop)">
                                <l-icon>
                                    <div


                                            :class="{

                                    'bg-green-600 border-green-900 hover:bg-green-700': prop.decision_simplified === 'APPROVED',
                                    'bg-red-600 border-red-900 hover:bg-red-700': prop.decision_simplified === 'REFUSED',
                                    'bg-blue-600 border-blue-900 hover:bg-blue-700': prop.decision_simplified === 'PENDING',
                                    'bg-orange-400 border-orange-600 hover:bg-orange-700': prop.decision_simplified === 'SPLIT',
                                    'bg-gray-300 border-gray-600 hover:bg-gray-700': prop.decision_simplified === 'WITHDRAWN',
                                 }"
                                            class="w-3 h-3 rounded-full border-solid border shadow-sm cursor-pointer">

                                    </div>
                                </l-icon>
                            </l-marker>

                        </v-cluster>

                        <l-google-tile-layer
                                :apikey="$store.state.auth.map.google.key"
                                :options="$store.state.auth.map.google.opts"/>


                    </l-map>


                    <div class="el-block--message">
                        <el-button
                                type="success"
                                :icon="!$store.getters['applicationMap/isLoadingMap'] ? 'el-icon-location-outline' : 'el-icon-loading'"
                                :disabled="$store.getters['applicationMap/isLoadingMap']"
                                @click="search()"
                        >
                            {{ !$store.getters['applicationMap/isLoadingMap'] ? 'Click to Update' : 'Loading...' }}
                        </el-button>

                    </div>

                </div>

            </div>
        </div>


    </div>

</template>

<script>


    import Navigation from "../../components/incs/Navigation";
    import VCluster from 'vue2-leaflet-markercluster'
    import "leaflet.markercluster/dist/MarkerCluster.css";
    import ApplicationDetails from "../../components/incs/Application/ApplicationDetails";
    import AgentDetails from "../../components/incs/Application/AgentDetails";
    import ApplicationTimeline from "../../components/incs/Application/ApplicationTimeline";
    import LocationMap from "../../components/incs/Application/LocationMap";
    import ApplicationHousePrices from "../../components/incs/Application/ApplicationHousePrices";


    import {latLng} from "leaflet";

    import {getDefaultDateOptions} from './../../utils/dates'


    export default {
        name: 'ApplicationMap',
        components: {
            ApplicationHousePrices,
            LocationMap,
            ApplicationTimeline,
            AgentDetails, ApplicationDetails, Navigation, VCluster
        },
        methods: {
            onLocationSearchChange(opts) {

                if (opts.latitude && opts.longitude) {

                    console.log('Change', opts);

                    //  setTimeout(()=>{
                    //       vm.$store.state.applicationMap.active.center = [opts.latitude, opts.longitude];
                    //    }, 200)

                    this.$store.state.applicationMap.active.center = [opts.latitude, opts.longitude];
                    this.$store.state.applicationMap.newCenter = [opts.latitude, opts.longitude];

                    this.$refs.map.mapObject.setView(latLng(opts.latitude, opts.longitude), 12);


                    let el = document.getElementById('google-autocomplete');
                    if (el) {
                        el.value = '';
                    }
                    setTimeout(() => {
                        this.search();
                    }, 500)

                }
            },
            toggleWatchlist(hash) {

                let vm = this;
                if (vm.$store.state.applications.application.watching) {
                    this.$store.dispatch('watchlist/remove', {
                        hash: hash
                    }).then(() => {
                        vm.$store.state.applications.application.watching = false;
                        vm.$notify({
                            type: 'success',
                            title: 'Application Removed',
                            message: 'Application removed from watchlist',
                            position: 'top-right',
                            offset: 140,
                        });
                    })
                } else {
                    this.$store.dispatch('watchlist/add', {
                        hash: hash
                    }).then(() => {
                        vm.$store.state.applications.application.watching = true;
                        vm.$notify({
                            type: 'success',
                            title: 'Application Added',
                            message: 'Application added to watchlist',
                            position: 'top-right',
                            offset: 140,
                        });
                    })
                }
            },
            onSortChange({column}) {
                this.$store.state.applications.saleHistory.currentPage = 1;
                this.$store.state.applications.saleHistory.sortColumn = column;
            },

            openUrl(url) {
                if (url) {
                    window.open(url);
                }
            },
            onMapReady() {
                this.$store.state.applicationMap.bounds = this.$refs.map.mapObject.getBounds();
                let center = this.$refs.map.mapObject.getCenter();
                this.$store.state.applicationMap.newCenter = [center.lat, center.lng];
                this.$store.dispatch('applicationMap/updateCenter');
            },
            search() {
                this.$store.dispatch('applicationMap/updateCenter').then(() => {
                    if (!this.$store.state.applicationMap.properties.length) {
                        this.$notify({
                            type: 'warning',
                            title: 'No Applications Found',
                            message: 'Sorry, we couldn\'t find any applications.',
                            position: 'top-right',
                            offset: 140,
                        });
                    }
                })
            },
            openApplication(application) {
                let vm = this;
                vm.$store.state.applications.application = {};
                vm.applicationVisible = true;
                vm.$store.state.applicationMap.draw.loading = true;
                vm.$store.state.applicationMap.details.activeTab = 'details';

                vm.$store.dispatch('applications/fetch', {
                    hash: application.id
                }).then(() => {
                    return vm.$store.dispatch('applications/fetchSoldPrices', {
                        hash: application.id
                    })
                }).then(() => {
                    vm.$store.state.applicationMap.draw.loading = false;
                })


            },
            onCenterUpdated(center) {
                this.$store.state.applicationMap.newCenter = [center.lat, center.lng];
            },
            onBoundsUpdated(bounds) {
                this.$store.state.applicationMap.bounds = bounds;
            },
            onZoomUpdate(zoom) {
                this.$store.state.applicationMap.zoom = zoom;
            }
        },
        data() {
            return {
                pickerOptions: getDefaultDateOptions(),
                applicationVisible: false,
            }
        }
    }
</script>