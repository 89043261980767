<template>
    <div class="bg-white force-bg border border-gray-400">
        <div class="flex justify-between items-center flex-wrap sm:flex-no-wrap border-b border-gray-200">
                <div class="flex force-bg bg-gray-700 w-full p-1 px-2">
                    <div class="flex-grow flex-grow-0">
                        <h3 class="text-sm leading-6 font-medium text-white">
                            {{ title }}
                        </h3>
                        <p class="mt-1 text-sm leading-5 text-gray-500">
                            {{ description }}
                        </p>
                    </div>
                    <div class="flex items-center">
                        <slot name="cta"></slot>
                    </div>
                </div>
        </div>
        <div :class="paddingClass">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ReportWidget",
        props: {
            paddingClass: {
                type: String,
                default: 'p-3'
            },
            title: {
                type: String,
                default: ''
            },
            description: {
                type: String,
                default: ''
            },
        }
    }
</script>

<style>
    .force-bg {
        -webkit-print-color-adjust: exact !important;
    }
</style>