<template>
    <div>
        <div class="columns flex-no-wrap" v-if="value.proprietor_name">
            <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                Company Name
            </div>
            <div class="column ml-auto text-xs text-right text-gray-900 flex values-center">
                {{ value.proprietor_name }}
            </div>
        </div>

        <div class="columns flex-no-wrap" v-if="value.proprietor_company_registration_no">
            <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                Company No
            </div>
            <div class="column ml-auto text-xs text-right text-gray-900 flex values-center">
                {{ value.proprietor_company_registration_no }}
            </div>
        </div>

        <div class="columns flex-no-wrap" v-if="value.status">
            <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                Status
            </div>
            <div class="column ml-auto text-xs text-right text-gray-900 flex values-center">
                {{ value.status }}
            </div>
        </div>
        <div class="columns flex-no-wrap" v-if="value.proprietor_category">
            <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                Category
            </div>
            <div class="column ml-auto text-xs text-right text-gray-900 flex values-center">
                {{ value.proprietor_category }}
            </div>
        </div>

        <div class="columns flex-no-wrap" v-if="value.age">
            <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                Company Age
            </div>
            <div class="column ml-auto text-xs text-right text-gray-900 flex values-center">
                {{ value.age }}
            </div>
        </div>

        <div class="columns flex-no-wrap" v-if="value.size">
            <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                Company Size
            </div>
            <div class="column ml-auto text-xs text-right text-gray-900 flex values-center">
                {{ value.size }}
            </div>
        </div>

        <div class="columns flex-no-wrap" v-if="value.employees">
            <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                Employees
            </div>
            <div class="column ml-auto text-xs text-right text-gray-900 flex values-center">
                {{ value.employees }}
            </div>
        </div>

        <div class="columns flex-no-wrap" v-if="value.proprietor_address_line_one">
            <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                Address
            </div>
            <div class="column ml-auto text-xs text-right text-gray-900 flex values-center">
                {{ value.proprietor_address_line_one }}
            </div>
        </div>


    </div>
</template>

<script>
    export default {
        name: "CorporateOwnershipDetails",
        props: {
            name: {
                type: String,
                default(){
                    return null
                }
            },
            value: {
                type: Object,
                default(){
                    return {}
                }
            }
        }
    }
</script>

<style scoped>

</style>