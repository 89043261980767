<template>

    <page>

        <template slot="header">
            <page-header title="Your subscription has expired" />
        </template>

        <template slot="content">

            <dashboard-widget
                    title="Subscription Expired"
                    padding-class="p-6"
            >
                <div
                        class="text-center">
                    <div class="mx-auto flex items-center h-full justify-center">
                        <div class="columns w-full lg:w-auto">
                            <div class="column w-full">
                                <div class="m-8 mx-auto">
                                    <lottie :options="{animationData: animationData}" :height="200"  />
                                </div>
                                <div class="leading-loose text-sm">
                                    <p class="mb-1">Looks like you don't have a subscription to Land Monitor or your previous subscription has expired.</p>
                                    <p class="mb-1">If you think this is an error or would like to renew please click the button below to speak to our team.</p>

                                    <p class="mt-4">
                                        <el-button
                                                @click="$store.dispatch('auth/openSupport')"
                                                type="success">Renew your Subscription</el-button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </dashboard-widget>


        </template>
    </page>

</template>

<script>
    import Lottie from "vue-lottie";
    import * as animationData from "./../components/incs/Animations/construction";

    export default {
        name: "SubscriptionRequired",
        components: {
          Lottie
        },
        data(){
          return {
              animationData: animationData.default
          }
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.$store.commit('auth/setBreadcrumbs', [
                    {
                        title: 'Subscription Expired'
                    }
                ]);
            })
        }
    }
</script>

<style scoped>

</style>