<template>
    <dl class="el-block--dl">
        <div class="item">
            <dt>
                Local Authority
            </dt>
            <dd>
                {{ $store.state.applications.application.local_authority }}
            </dd>
        </div>
        <div class="item">
            <dt>
                Reference
            </dt>
            <dd>
                {{ $store.state.applications.application.reference }}
            </dd>
        </div>
        <div class="item">
            <dt>
                Alternative Reference
            </dt>
            <dd>
                {{ $store.state.applications.application.alternative_reference }}
            </dd>
        </div>
        <div class="item">
            <dt>
                Address
            </dt>
            <dd>
                {{ $store.state.applications.application.site_address }}
            </dd>
        </div>
        <div class="item">
            <dt>
                Applicant
            </dt>
            <dd>
                {{ $store.state.applications.application.applicant_name }}
            </dd>
        </div>
        <div class="item">
            <dt>
                Applicant Address
            </dt>
            <dd>
                {{ $store.state.applications.application.applicant_address }}
            </dd>
        </div>
        <div class="item">
            <dt>
                Applicant Company
            </dt>
            <dd>
                {{ $store.state.applications.application.applicant_company }}
            </dd>
        </div>

        <div class="item">
            <dt>
                Application Type
            </dt>
            <dd>
                {{ $store.state.applications.application.type }}
            </dd>
        </div>
        <div class="item">
            <dt>
                Proposal
            </dt>
            <dd>
                {{ $store.state.applications.application.proposal }}
            </dd>
        </div>
        <div class="item">
            <dt>
                Status
            </dt>
            <dd>
                     <span class="w-2 h-2 inline-block rounded-full"
                           :class="{
                                        'bg-green-500': $store.state.applications.application.status_simplified === 'DECIDED',
                                        'bg-blue-500': $store.state.applications.application.status_simplified === 'PENDING',
                                        'bg-gray-500': $store.state.applications.application.status_simplified === 'WITHDRAWN'
                                        }"
                     ></span>
                {{ $store.state.applications.application.status }}
            </dd>
        </div>
        <div class="item">
            <dt>
                Decision
            </dt>
            <dd>
                    <span class="w-2 h-2 inline-block rounded-full"
                          :class="{
                                        'bg-red-500': $store.state.applications.application.decision_simplified === 'REFUSED',
                                        'bg-green-500': $store.state.applications.application.decision_simplified === 'APPROVED',
                                        'bg-blue-500': $store.state.applications.application.decision_simplified === 'PENDING',
                                        'bg-gray-500': $store.state.applications.application.decision_simplified === 'WITHDRAWN',
                                        'bg-orange-500': $store.state.applications.application.decision_simplified === 'SPLIT'
                                        }"
                    ></span>
                {{ $store.state.applications.application.decision }}
            </dd>
        </div>
        <div class="item" v-if="$store.state.applications.application.appeal_status">
            <dt>
                Appeal Status
            </dt>
            <dd>
                {{ $store.state.applications.application.appeal_status }}
            </dd>
        </div>
        <div class="item" v-if="$store.state.applications.application.appeal_decision ">
            <dt>
                Appeal Decision
            </dt>
            <dd>
                {{ $store.state.applications.application.appeal_decision }}
            </dd>
        </div>
        <div class="item" v-if="$store.state.applications.application.date_received">
            <dt>
                Application Received
            </dt>
            <dd>
                {{ $store.state.applications.application.date_received | moment("MMM Do YYYY") }}
                <br/><small>{{ $store.state.applications.application.date_received | moment("from",
                "now") }}</small>
            </dd>
        </div>
        <div class="item" v-if="$store.state.applications.application.date_validated">
            <dt>
                Application Validated
            </dt>
            <dd>
                {{ $store.state.applications.application.date_validated | moment("MMM Do YYYY") }}
                <br/><small>{{ $store.state.applications.application.date_validated | moment("from",
                "now") }}</small>

            </dd>
        </div>

        <div class="item" v-if="$store.state.applications.application.date_decided">
            <dt>
                Application Decided
            </dt>
            <dd>
                {{ $store.state.applications.application.date_decided | moment("MMM Do YYYY") }}
                <br/><small>{{ $store.state.applications.application.date_decided | moment("from",
                "now") }}</small>

            </dd>
        </div>

        <div class="item" v-if="$store.state.applications.application.ward">
            <dt>
                Ward
            </dt>
            <dd>
                {{ $store.state.applications.application.ward }}
            </dd>
        </div>
        <div class="item" v-if="$store.state.applications.application.parish">
            <dt>
                Parish
            </dt>
            <dd>
                {{ $store.state.applications.application.parish }}
            </dd>
        </div>
        <div class="item" v-if="$store.state.applications.application.district">
            <dt>
                District
            </dt>
            <dd>
                {{ $store.state.applications.application.district }}
            </dd>
        </div>
        <div class="item" v-if="$store.state.applications.application.case_officer">
            <dt>
                Case Officer
            </dt>
            <dd>
                {{ $store.state.applications.application.case_officer }}
            </dd>
        </div>

    </dl>
</template>

<script>
    export default {
        name: "ApplicationDetails"
    }
</script>

<style scoped>

</style>