import api from "../../utils/api";

const initialState = {
    loading: true,
    updating: false,
    visible: false,
    lookups: {
        loading: false,
        visible: false
    },
    customAreas: {
        loading: false,
        visible: false
    }
};

export default {
    namespaced: true,
    state: initialState,
    mutations: {
        reset(state) {
            console.log('reset')
            Object.keys(initialState).forEach(key => {
                state[key] = initialState[key]
            })
        },
    },
    actions: {
        index: () => {
            return api.get('/api/v1/teams');
        },
        create: ({rootState}, params) => {
            console.log(rootState);
            return api.post('/api/v1/teams', params);
        },
        delete: ({rootState}, params) => {
            console.log(rootState);
            return api.delete('/api/v1/team/' + params.teamId);
        },
        switch: ({rootState}, params) => {
            console.log(rootState);
            return api.put('/api/v1/team/' + params.teamId + '/switch');
        },
        leave: ({rootState}, params) => {
            console.log(rootState);
            return api.delete('/api/v1/team/' + params.teamId + '/leave');
        },
        read: ({rootState}, params) => {
    console.log(rootState);
            return api.get('/api/v1/team/' + params.teamId);
        },
        invoices: ({rootState}, params) => {
            console.log(rootState);
            return api.get('/api/v1/team/' + params.teamId + '/invoices');
        },
        update: ({rootState}, params) => {
            console.log(rootState);
            return api.put('/api/v1/team/' + params.teamId, {
                name: params.name,
                private: params.private,
                columns: params.columns
            });
        },

    }
}