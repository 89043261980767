<template>
    <map-modal
            :has-actions="false"
            :show="true"
            :has-header="!loading"
            @close="()=>{ $emit('close') }"
    >

        <template v-slot:header>
            <h1 v-if="!loading" class="text-lg font-semibold">
              Neighbour Compare -  {{ data.street }}
            </h1>
        </template>


        <template v-slot:content>

            <!--
            {{ getTenureLegend }}
            -->

            <div class="flex h-full w-full">
                <div
                       :class="{
                       'w-1/2': !loading,
                       'w-full': loading
                       }"
                        class="h-full flex items-stretch pr-3">
                    <div v-if="loading" class="flex h-full justify-center items-center flex-1">
                        <property-loader />
                    </div>
                   <div v-if="!loading" class="h-full flex h-full flex-col w-full">
                       <el-select
                               filterable
                               class="w-full"
                               v-model="view"
                               placeholder="Select View..">
                           <el-option
                                   v-for="item in views" :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                           </el-option>
                       </el-select>
                       <div class="pr-2 overflow-scroll mt-3">


                           <el-table
                                   :default-sort="sort.property_types"
                                   @cell-mouse-enter="(val)=> { onCellOverview(true, val) }"
                                   @cell-mouse-leave="(val)=> { onCellOverview(false, val) }"
                                   v-if="view === 'properties'" size="small" :data="getProperties">

                               <el-table-column
                                       sortable
                                       prop="index"
                                       label="Address">
                                   <template slot-scope="scope">
                                       <div class="w-full flex py-2 pb-3">
                                           <div class="w-6 mr-2">
                                               <div class="w-6 h-6 flex text-white items-center justify-center flex justify-center items-center rounded-full bg-blue-600 text-xs ">
                                                   {{ scope.row.index }}
                                               </div>
                                           </div>
                                           <div class="w-auto leading-tight">
                                               {{ scope.row.single_line_address }}
                                           </div>
                                       </div>
                                   </template>
                               </el-table-column>
                               <el-table-column
                                       sortable
                                       prop="type"
                                       label="Type"
                                       width="110">
                                   <template slot-scope="scope">
                                       {{ scope.row.type }}
                                   </template>
                               </el-table-column>
                           </el-table>

                           <el-table
                                   :default-sort="sort.epcs"
                                   @cell-mouse-enter="(val)=> { onCellOverview(true, val) }"
                                   @cell-mouse-leave="(val)=> { onCellOverview(false, val) }"
                                   v-if="view === 'epc'" size="small" :data="getEPCs">
                               <el-table-column
                                       sortable
                                       prop="index"
                                       label="Address">
                                   <template slot-scope="scope">
                                       <div class="w-full flex py-2 pb-3">
                                           <div class="w-6 mr-2">
                                               <div class="w-6 h-6 flex text-white items-center justify-center flex justify-center items-center rounded-full bg-blue-600 text-xs ">
                                                   {{ scope.row.index }}
                                               </div>
                                           </div>
                                           <div class="w-auto leading-tight">
                                               {{ scope.row.single_line_address }}
                                           </div>
                                       </div>
                                   </template>
                               </el-table-column>
                               <el-table-column
                                       sortable
                                       prop="rating"
                                       align="right"
                                       width="90" label="Rating">
                                   <template slot-scope="scope">
                                       {{ scope.row.rating }}
                                   </template>
                               </el-table-column>
                           </el-table>

                           <el-table
                                   :default-sort="sort.sales"
                                   @cell-mouse-enter="(val)=> { onCellOverview(true, val) }"
                                   @cell-mouse-leave="(val)=> { onCellOverview(false, val) }"
                                   v-if="view === 'property-sales'" size="small" :data="getPropertySales">

                               <el-table-column
                                       sortable
                                       prop="index"
                                       label="Address">
                                   <template slot-scope="scope">
                                       <div class="w-full flex py-2 pb-3">
                                           <div class="w-6 mr-2">
                                               <div class="w-6 h-6 flex text-white items-center justify-center flex justify-center items-center rounded-full bg-blue-600 text-xs ">
                                                   {{ scope.row.index }}
                                               </div>
                                           </div>
                                           <div class="w-auto leading-tight">
                                               {{ scope.row.single_line_address }}
                                           </div>
                                       </div>
                                   </template>
                               </el-table-column>
                               <el-table-column
                                       sortable
                                       prop="last_sale_price"
                                       align="right"
                                       width="100" label="Price">
                                   <template slot-scope="scope">
                                       £{{ scope.row.last_sale_price.toLocaleString() }}
                                   </template>
                               </el-table-column>
                               <el-table-column
                                       align="right"
                                       sortable
                                       prop="last_sold_date"
                                       width="120" label="Last Sold">
                                   <template slot-scope="scope">
                                       <date-human-view size="text-xs" v-model="scope.row.last_sold_date" />
                                   </template>
                               </el-table-column>
                           </el-table>







                           <el-table v-if="view === 'known-marketed-sales'" size="small"
                                     :data="getLastMarketedForSale">
                               <el-table-column label="Address">
                                   <template slot-scope="scope">
                                       <p class="leading-tight">
                                           {{ scope.row.single_line_address }}
                                       </p>
                                   </template>
                               </el-table-column>
                               <el-table-column
                                       align="right"
                                       width="110" label="Rating">
                                   <template slot-scope="scope">
                                       <date-human-view
                                               size="text-xs"
                                               v-if="scope.row.known_marketed_sale"
                                               v-model="scope.row.known_marketed_sale" />
                                   </template>
                               </el-table-column>
                           </el-table>
                           <el-table v-if="view === 'property-size'" size="small"
                                     :data="getPropertySizes">
                               <el-table-column label="Address">
                                   <template slot-scope="scope">
                                       <p class="leading-tight">
                                           {{ scope.row.single_line_address }}
                                       </p>
                                   </template>
                               </el-table-column>
                               <el-table-column
                                       align="right"
                                       width="90" label="Rating">
                                   <template slot-scope="scope">
                                       {{ scope.row.total_floor_area }}
                                   </template>
                               </el-table-column>
                           </el-table>
                           <el-table
                                   :default-sort="sort.construction"
                                   @cell-mouse-enter="(val)=> { onCellOverview(true, val) }"
                                   @cell-mouse-leave="(val)=> { onCellOverview(false, val) }"
                                   v-if="view === 'property-construction-band'" size="small"
                                     :data="getConstructionAgeBand">
                               <el-table-column
                                       prop="index"
                                       sortable
                                       label="Address">
                                   <template slot-scope="scope">
                                       <div class="w-full flex py-2 pb-3">
                                           <div class="w-6 mr-2">
                                               <div class="w-6 h-6 flex text-white items-center justify-center flex justify-center items-center rounded-full bg-blue-600 text-xs ">
                                                   {{ scope.row.index }}
                                               </div>
                                           </div>
                                           <div class="w-auto leading-tight">
                                               {{ scope.row.single_line_address }}
                                           </div>
                                       </div>
                                   </template>
                               </el-table-column>
                               <el-table-column
                                       prop="construction_age_band"
                                       sortable
                                       align="right"
                                       width="90" label="Age">
                                   <template slot-scope="scope">
                                       {{ scope.row.construction_age_band }}
                                   </template>
                               </el-table-column>
                           </el-table>


                           <el-table v-if="view === 'property-classification'" size="small"
                                     :data="getPropertyClassifications">
                               <el-table-column label="Address">
                                   <template slot-scope="scope">
                                       <p class="leading-tight">
                                           {{ scope.row.single_line_address }}<br />
                                           <small>  {{ scope.row.classification }}</small>
                                       </p>
                                   </template>
                               </el-table-column>
                               <el-table-column
                                       align="right"
                                       width="130" label="Use Type">
                                   <template slot-scope="scope">
                                       {{ scope.row.classification_class }} - {{ scope.row.classification_class_description }}
                                   </template>
                               </el-table-column>
                           </el-table>
                           <el-table v-if="view === 'property-ownership'" size="small" :data="getPropertyOwnership">
                               <el-table-column label="Address">
                                   <template slot-scope="scope">
                                       <p class="leading-tight">
                                           {{ scope.row.single_line_address }}
                                       </p>
                                   </template>
                               </el-table-column>
                               <el-table-column
                                       align="right"
                                       width="130" label="Ownership">
                                   <template slot-scope="scope">
                                       {{ scope.row.ownership }}
                                   </template>
                               </el-table-column>
                           </el-table>


                           <el-table
                                   :default-sort="sort.occupiers"
                                   @cell-mouse-enter="(val)=> { onCellOverview(true, val) }"
                                   @cell-mouse-leave="(val)=> { onCellOverview(false, val) }"
                                   v-if="view === 'property-occupiers'" size="small"
                                     :data="getPropertyTenure">
                               <el-table-column
                                       prop="index"
                                       sortable
                                       label="Address">
                                   <template slot-scope="scope">
                                       <div class="w-full flex py-2 pb-3">
                                           <div class="w-6 mr-2">
                                               <div class="w-6 h-6 flex text-white items-center justify-center flex justify-center items-center rounded-full bg-blue-600 text-xs ">
                                                   {{ scope.row.index }}
                                               </div>
                                           </div>
                                           <div class="w-auto leading-tight">
                                               {{ scope.row.single_line_address }}
                                           </div>
                                       </div>
                                   </template>
                               </el-table-column>
                               <el-table-column
                                       prop="tenure"
                                       sortable
                                       align="right"
                                       width="130" label="Occupier">
                                   <template slot-scope="scope">
                                       {{ scope.row.tenure }}
                                   </template>
                               </el-table-column>
                           </el-table>


                       </div>
                   </div>
                </div>
                <div
                        v-if="!loading"
                        class="flex-grow h-full">

                    <l-map
           :options="{zoomControl: false}"
           @ready="onMapReady"
           ref="watch"
           :max-bounds="maxBounds">


                        <l-geo-json
                                :options-style="()=>{ return {
                                                    color: '#F51D00',
                                                    weight: 2,
                                                    fillColor: '#F51D00'
                                                    } }"
                                :geojson="data.street_polygon" />


                        <l-protobuf
                                v-if="showProtobuf"
                                ref="neighbourCompareMap"
                                :url="getMapURL"
                                :options="getProtobufSettings"
                        />

                        <!--
                        <div v-if="getPropertyPoints.length > 0">
                            <l-marker
                                    :key="k"
                                    v-for="(p, k) of getPropertyPoints"
                                    :lat-lng="[p.lat, p.lon]">
                                <l-icon v-if="view === 'epc'">
                                    <div :class="p.color"
                                         class="w-6 rounded-full h-6  flex justify-center text-xs items-center border border-white shadow text-gray-900">
                                        {{ p.index }}
                                    </div>
                                </l-icon>

                                <l-icon v-if="view === 'property-sales' ||
                                view === 'properties' ||
                                view === 'property-ownership' ||
                                view === 'property-construction-band' ||
                                view === 'property-occupiers'">
                                    <div
                                            :class="{
                       'bg-blue-600': view === 'property-sales'
                       }"
                                            :style="{
                       'background':  ['property-ownership', 'property-occupiers', 'properties', 'property-construction-band'].indexOf(view) > -1 ? p.background + ' !important' : 'inherit'
                       }"
                                            class="w-6 rounded-full h-6 text-white flex justify-center text-xs items-center border border-white shadow">
                                        {{ p.index }}
                                    </div>
                                </l-icon>
                            </l-marker>
                        </div>
                        --->

                        <!--
       <l-google-tile-layer
               :apikey="$store.state.auth.map.google.key"
               :options="$store.state.auth.map.google.opts"/>
    -->
                        <div
                              v-if="getLegend.length > 0"
                                class="absolute max-w-md p-2 m-2 bg-gray-800 rounded left-0 bottom-0 p-1 el--compare-legend">
                           <div class="flex flex-wrap p-1">
                               <div :key="index"
                                    class="mr-3 flex text-white font-bold items-center"
                                       v-for="(item, index) of getLegend"
                               >
                                   <span
                                           :class="[item.color]"
                                           :style="{'background': item.background}"
                                           class="w-3 h-3  rounded-full mr-1"></span>
                                   <span>{{ item.label }}</span>
                               </div>
                           </div>
                        </div>


                </l-map>
                </div>
            </div>



        </template>

    </map-modal>
</template>

<script>
    import collect from "collect.js";
    import MapModal from "./MapModal";
    import PropertyLoader from "../incs/PropertyLoader";
    import {getEPCPolygonStyle, getEPCRatingColor} from "../../utils/ownership";

    export default {
        name: "NeighbourCompareModal",
        components: {PropertyLoader, MapModal},
        props: {
            value: {
                type: Number,
                default(){
                    return 0;
                }
            },
            show: {
                type: Boolean,
                default: false
            }
        },
        created(){
          this.fetch(this.value);
        },
        watch: {
            view(){
                this.showProtobuf = false;
                setTimeout(()=> {
                    this.showProtobuf = true;
                }, 1)
            }
        },
        computed: {
            getProtobufSettings(){
              switch(this.view) {
                  case "epc":
                      return this.epcProtobufOptions;
              }
              return this.protobufOptions;
            },
            epcProtobufOptions() {
                return {
                    interactive: true,
                    getFeatureId: (f) => {
                        return f.properties.uprn;
                    },
                    filter: (item) => {
                        return item.current_energy_rating
                    },
                    vectorTileLayerStyles: {
                        properties: (item) => {
                            return getEPCPolygonStyle(getEPCRatingColor(item.current_energy_rating))
                        },
                    }
                }
            },
            protobufOptions(){
                return {
                    interactive: true,
                        getFeatureId: (f) => {
                        return f.properties.uprn;
                    },
                        vectorTileLayerStyles: {
                            properties: (item) => {
                           console.log(item);
                       },
                    }
                }
            },
            getMapURL(){
              return 'https://localhost/api/v1/tiles/neighbour-compare/' + this.value + '/{z}/{x}/{y}';
            },
            getLegend(){
                switch(this.view){
                    case "epc":
                        return this.legends.epcs;
                    case "properties":
                        return this.getPropertyLegend;
                    case "property-occupiers":
                        return this.getTenureLegend;
                    case "property-construction-band":
                        return this.getConstructionAgeBandLegend;
                    case "property-ownership":
                        return this.getPropertyOwnershipLegend;
                }
              return []
            },
            getTenureLegend(){
                let vm = this;
                return collect(this.getPropertyTenure).pluck('tenure').unique().sort()
                    .transform((x) => {
                        return {label: x }
                    })
                    .map((x, i) => {
                        x.background =  vm.colors_social[i];
                        return x;
                    })
                    .toArray()
            },
            getPropertyLegend(){
                let vm = this;
                return collect(this.getProperties).pluck('type').unique().sort()
                    .transform((x) => {
                        return {label: x }
                    })
                    .toArray().map((x, i) => {
                        x.background =  vm.colors[i];
                        return x;
                    })
            },
            getPropertyOwnershipLegend(){
                let vm = this;
                return collect(this.getPropertyOwnership).pluck('ownership').unique().sort()
                    .transform((x) => {
                        return {label: x }
                    })
                    .toArray().map((x, i) => {
                        x.background =  vm.colors[i];
                        return x;
                    })
            },
            getConstructionAgeBandLegend(){
                let vm = this;
                return collect(this.getConstructionAgeBand).pluck('construction_age_band').unique().sort()
                    .transform((x) => {
                        return {label: x }
                    })
                    .toArray().map((x, i) => {
                        x.background =  vm.colors[i];
                        return x;
                    })
            },
            getPropertyPoints(){
                let vm = this;
                switch(this.view){
                    case "properties":
                        return (this.active_uprn ? this.getProperties.filter((y) => {
                            return y.uprn === this.active_uprn;
                        }) : this.getProperties).map((y) => {
                            y.background = collect(vm.getPropertyLegend).filter((x) => {
                                return x.label === y.type
                            }).pluck('background').first()
                            return y;
                        })
                    case "property-construction-band":
                        return (
                            this.active_uprn ? this.getConstructionAgeBand.filter((y) => {
                            return y.uprn === this.active_uprn;
                        }) : this.getConstructionAgeBand
                        ).map((y) => {
                            y.background = collect(vm.getConstructionAgeBandLegend).filter((x) => {
                                return x.label === y.construction_age_band
                            }).pluck('background').first()
                            return y;
                        })
                    case "property-occupiers":
                        return (this.active_uprn ? this.getPropertyTenure.filter((y) => {
                            return y.uprn === this.active_uprn;
                        }) : this.getPropertyTenure).map((y) => {
                            y.background = collect(vm.getTenureLegend).filter((x) => {
                                return x.label === y.tenure
                            }).pluck('background').first()
                            return y;
                        })
                    case "property-ownership":
                        return (this.active_uprn ? this.getPropertyOwnership.filter((y) => {
                            return y.uprn === this.active_uprn;
                        }) : this.getPropertyOwnership).map((y) => {
                            y.background = collect(vm.getPropertyOwnershipLegend).filter((x) => {
                                return x.label === y.ownership
                            }).pluck('background').first()
                            return y;
                        })
                    case "epc":
                        return this.active_uprn ? this.getEPCs.filter((y) => {
                            return y.uprn === this.active_uprn;
                        }) : this.getEPCs
                    case "property-sales":
                        return this.active_uprn ? this.getPropertySales.filter((y) => {
                            return y.uprn === this.active_uprn;
                        }) : this.getPropertySales
                }
                return this.data.properties.map((x) => {
                    return {
                        uprn: x.properties.uprn,
                        single_line_address: x.properties.single_line_address,
                        lat: x.properties.lat,
                        lon: x.properties.lon,
                    }
                });
            },
            getEPCs(){
                return collect(this.data.properties).map((x) => {
                    let rating = x.properties.current_energy_rating ? x.properties.current_energy_rating : null;
                    return {
                        uprn: x.properties.uprn,
                        single_line_address: x.properties.single_line_address,
                        rating: rating,
                        color: collect(this.legends.epcs).filter((y) => {
                            return y.label === rating
                        }).pluck('color').first(),
                        lat: x.properties.lat,
                        lon: x.properties.lon,
                    }
                }).filter((x) => x.rating !== null)
                    .map((x, i) => {
                        x.index = i + 1;
                        return x;
                    })
                    .sortBy('rating').toArray();
            },
            getProperties(){
                return collect(this.data.properties).map((x) => {
                    return {
                        uprn: x.properties.uprn,
                        lat: x.properties.lat,
                        lon: x.properties.lon,
                        single_line_address: x.properties.single_line_address,
                        type: x.properties.built_form  && x.properties.property_type ? x.properties.built_form + ' ' + x.properties.property_type  : x.properties.lr_property_type ? x.properties.lr_property_type : x.properties.classififcation,
                        index: null
                    }
                }).sortBy('uprn')
                    .map((x, i) => {
                        x.index = i + 1;
                        return x;
                    })
                    .sortBy('index').toArray();

            },
            getConstructionAgeBand(){
                return collect(this.data.properties).map((x) => {
                    return {
                        uprn: x.properties.uprn,
                        lat: x.properties.lat,
                        lon: x.properties.lon,
                        single_line_address: x.properties.single_line_address,
                        construction_age_band: x.properties.construction_age_band
                    }
                }).filter((x) => x.construction_age_band !== null)
                    .sortBy('uprn')
                    .map((x, i) => {
                        x.index = i + 1;
                        x.construction_age_band = x.construction_age_band.toLowerCase().replace('england and wales:', '')
                        return x;
                    })
                    .sortBy('index').toArray();
            },
            getLastMarketedForSale(){
                return this.data.properties.map((x) => {
                    return {
                        uprn: x.properties.uprn,
                        lat: x.properties.lat,
                        lon: x.properties.lon,
                        single_line_address: x.properties.single_line_address,
                        known_marketed_sale: x.properties.known_marketed_sale,
                    }
                }).filter((x) => x.known_marketed_sale !== null)
                    .sort((a, b)=> {
                    return new Date(b.known_marketed_sale) - new Date(a.known_marketed_sale);
                })
            },
            getPropertySizes(){
                return this.data.properties.map((x) => {
                    return {
                        uprn: x.properties.uprn,
                        lat: x.properties.lat,
                        lon: x.properties.lon,
                        single_line_address: x.properties.single_line_address,
                        total_floor_area: x.properties.total_floor_area
                    }
                }).filter((x) => x.total_floor_area !== null)
            },
            getPropertyClassifications(){
                return this.data.properties.map((x) => {
                    return {
                        uprn: x.properties.uprn,
                        lat: x.properties.lat,
                        lon: x.properties.lon,
                        single_line_address: x.properties.single_line_address,
                        classification: x.properties.classififcation,
                        classification_class: x.properties.classification_class,
                        classification_class_description: x.properties.classification_class_description,
                    }
                })
            },
            getPropertyOwnership(){
                return collect(this.data.properties).map((x) => {
                    return {
                        uprn: x.properties.uprn,
                        lat: x.properties.lat,
                        lon: x.properties.lon,
                        single_line_address: x.properties.single_line_address,
                        ownership: x.properties.ownership
                    }
                }) .sortBy('uprn')
                    .map((x, i) => {
                        x.index = i + 1;
                        return x;
                    })
                    .sortBy('index').toArray();
            },
            getPropertyTenure(){
                return collect(this.data.properties).map((x) => {
                    return {
                        uprn: x.properties.uprn,
                        lat: x.properties.lat,
                        lon: x.properties.lon,
                        single_line_address: x.properties.single_line_address,
                        tenure: x.properties.tenure
                    }
                }).filter((x) => x.tenure !== null)
                    .map((x, i) => {
                        x.tenure = x.tenure.toLowerCase();
                        x.index = i + 1;
                        return x;
                    })
                    .sortBy('index').toArray();
            },
            getPropertySales(){
                return collect(this.data.properties).map((x) => {
                    return {
                        uprn: x.properties.uprn,
                        lat: x.properties.lat,
                        lon: x.properties.lon,
                        single_line_address: x.properties.single_line_address,
                        last_sold_date: x.properties.last_sold_date,
                        last_sale_price: x.properties.last_sale_price,
                        index: null
                    }
                }).filter((x) => x.last_sold_date !== null)
                    .map((x, i) => {
                        x.index = i + 1;
                        return x;
                    })
                    .sortByDesc('last_sold_date').toArray();

            },
            maxBounds(){
                const vm = this;
                return [
                    [vm.data.bbox[1], vm.data.bbox[0]],
                    [vm.data.bbox[3], vm.data.bbox[2]],
                ]
            },
        },
        methods: {
           getEPCColor(){
               return {

               }
           },
            onCellOverview(enter, value){
                this.active_uprn = enter ? value.uprn : null;
            },
            fetch(usrn){
                let vm = this;
                vm.data = {}
                vm.loading = true;
                vm.$http.get('/api/v1/usrn/' + usrn)
                    .then((response) => {
                    vm.data = response.data.data;
                    vm.loading = false;
                }).catch(() => {
                    vm.loading = false;

                    vm.data = {};
                })
            },
            onMapReady(){
                const vm = this;
                if (vm.$refs && vm.$refs['watch'] && vm.data.bbox) {
                    this.$refs['watch'].mapObject.fitBounds([
                        [vm.data.bbox[1], vm.data.bbox[0]],
                        [vm.data.bbox[3], vm.data.bbox[2]],
                    ])
                }
            },  
        },
        data(){
          return {
              showProtobuf: true,
              colors_social:[
                  '#2a9d8f',
                  '#0047ef',
                  '#a70063',
                  '#ec3215',
              ],
              colors: [
                  '#2a9d8f',
                  '#9b2226',
                  '#ca6702',
                  '#003566',
                  '#ef476f',
                  '#720026',
                  '#723d46',
                  '#264653',
                  '#8338ec',
                  '#3a86ff',
                  '#ee6c4d',
                  '#a44a3f',
                  '#9e0059',
                  '#007f5f'
              ],
            loading: true,
              active_uprn: null,
              view: 'properties',
              sort: {
                  construction:  {prop: 'index', order: 'ascending'},
                  occupiers: {prop: 'tenure', order: 'ascending'},
                epcs: {prop: 'rating', order: 'ascending'},
                  property_types: {prop: 'type', order: 'ascending'},
                  sales: {prop: 'last_sold_date', order: 'ascending'}
              },
              legends: {
                epcs: [
                    {label: 'A', color: 'bg-green-700'},
                {label: 'B', color: 'bg-green-500'},
                {label: 'C', color: 'bg-green-300'},
                {label: 'D', color: 'bg-yellow-300'},
                    {label: 'E', color: 'bg-yellow-500'},
                    {label: 'F', color: 'bg-orange-500'},
                    {label: 'G', color: 'bg-orange-700'},
                ]
              },
              views: [
                  {value: 'properties', label: 'Property Types'},
                  {value: 'epc', label: 'EPC Ratings'},
                  {value: 'property-sales', label: 'Sales'},
                  {value: 'property-size', label: 'Size'},
                  {value: 'property-occupiers', label: 'Known Occupiers'},
                  {value: 'property-ownership', label: 'Ownership'},
                  {value: 'property-construction-band', label: 'Construction Age'},
                  {value: 'property-classification', label: 'Use Types/Classifications'},
                  {value: 'known-marketed-sales', label: 'Last marketed for sale'},
              ],
              data: {}
          }
        }
    }
</script>

<style >
    .el--compare-legend {
        z-index: 3001 !important;
    }
</style>