<template>
    <div>
        <div v-if="value.cash_in_bank || value.turnover || value.net_assets">
            <div class="columns flex-no-wrap" v-if="value.cash_in_bank">
                <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                    Cash in Bank
                </div>
                <div class="column ml-auto text-xs text-right text-gray-900 flex values-center">
                    {{ value.cash_in_bank }}
                </div>
            </div>
            <div class="columns flex-no-wrap" v-if="value.net_assets">
                <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                    Net Assets
                </div>
                <div class="column ml-auto text-xs text-right text-gray-900 flex values-center">
                    {{ value.net_assets }}
                </div>
            </div>
            <div class="columns flex-no-wrap" v-if="value.turnover">
                <div class="column mr-auto text-xs text-gray-500 font-medium w-1/3 flex">
                    Turnover
                </div>
                <div class="column ml-auto text-xs text-right text-gray-900 flex values-center">
                    {{ value.turnover }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CorporateOwnershipFinancials",
        props: {
            value: {
                type: Object,
                default(){
                    return {}
                }
            }
        }
    }
</script>

<style scoped>

</style>