<template>


        <page>

            <template slot="header">
                <page-header title="Create a Team">
                    <template v-slot:tabs>
                        <team-nav />
                    </template>
                </page-header>

            </template>

            <template slot="content">
                <div class="columns">
                    <div class="column w-full">
                        <dashboard-widget title="Create a Team"
                                          padding-class="p-8"
                                          description="Create a new team by completing the form below">


                            <div class="columns">


                                <div class="columns w-full">
                                    <div class="column w-48">
                                        <div class="w-32 mt-4 mx-auto">
                                            <filepond-avatar
                                                    @change="(val)=>{ team.avatar = val; }"
                                                    v-model="team.avatar"
                                            />
                                        </div>

                                    </div>
                                    <div class="column flex-grow">

                                        <div class="column flex-grow">
                                            <el-form label-position="left" label-width="120px"
                                                     :rules="rules" ref="teamForm"
                                                     :model="team">
                                                <el-form-item label="Name" prop="name">
                                                    <el-input v-model="team.name"
                                                              :disabled="creating"
                                                    />
                                                </el-form-item>
                                            </el-form>
                                        </div>

                                        <div class="column w-full text-right">
                                            <el-button
                                                    @click="preCreate"
                                                    :disabled="creating"
                                                    :loading="creating"
                                                    type="success">
                                                Create
                                            </el-button>
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </dashboard-widget>
                    </div>
                </div>
            </template>

        </page>

</template>

<script>

    import FilepondAvatar from "../components/FilepondAvatar";
    import TeamNav from "./Team/TeamNav";

    export default {
        name: 'CreateTeam',
        components: {TeamNav, FilepondAvatar},
        data() {
            return {
                team: {
                    avatar: null,
                    name: null
                },
                creating: false,
                rules: {
                    name: [
                        {required: true, message: 'A name is required', trigger: 'blur'}
                    ]
                }
            }
        },
        methods: {
            preCreate(){
                let vm = this;
                vm.$refs['teamForm'].validate((valid) => {
                    if (!valid) {
                        return false;
                    } else {
                        vm.create();
                    }
                });
            },
            create(){
                let vm = this;
                vm.creating = true;
                vm.$http.post('/api/v1/teams', {
                    avatar: vm.team.avatar,
                    name: vm.team.name
                })
                    .then(() => {

                        vm.$http.get('/api/v1/auth/user')
                            .then((response) => {
                                vm.$store.commit('auth/setUser', response.data.data);
                                vm.$router.push('/');
                            }).catch((e)=>{
                            vm.$store.dispatch('auth/handleServerError', {
                                vm: vm,
                                error: e
                            });
                        });

                    })
                    .catch((e) => {
                        vm.$store.dispatch('auth/handleServerError', {
                            vm: vm,
                            error: e
                        });
                    })
            }
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.$store.commit('auth/setBreadcrumbs', [
                    {
                        path: '/teams',
                        title: 'My Teams'
                    },
                    {
                        title: 'Create a Team'
                    }
                ])
            })
        }
    }
</script>