<template>
    <div>


        <page>
            <template slot="header">
                <page-header title="Planning Applications">
                    <template v-slot:tabs>
                        <planning-applications-nav />
                    </template>
                </page-header>
            </template>


            <template slot="content">
            <div class="columns">
                <div class="column w-full">
                    <div class="columns">
                        <div class="column flex items-center">
                            <h3 class="text-sm uppercase font-medium text-gray-500">
                                Last 30 days
                            </h3>
                        </div>
                        <div class="ml-auto column">
                            <el-button type="success"
                                       @click="$router.push('/planning-applications/search')"
                                       icon="el-icon-search">Search
                            </el-button>
                        </div>
                    </div>
                </div>
                <div class="column w-full">
                <div class="columns">
                    <div class="column w-full lg:w-1/3">
                        <div class="grid grid-cols-2 gap-4 sm:grid-cols-2 lg:grid-cols-1">
                            <stat-box title="Applications"
                                      :loading="loading.stats"
                                      :value="stats.total ? stats.total : 0"
                            >

                                <template v-slot:icon>
                                    <div class="flex-shrink-0 bg-blue-800 rounded-md p-3">
                                        <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="#fff"
                                             viewBox="0 0 24 24">
                                            <path d="M11.362 2c4.156 0 2.638 6 2.638 6s6-1.65 6 2.457v11.543h-16v-20h7.362zm.827-2h-10.189v24h20v-14.386c0-2.391-6.648-9.614-9.811-9.614zm4.811 13h-3v-1h3v1zm0 2h-3v1h3v-1zm0 3h-10v1h10v-1zm-5-6h-5v4h5v-4z"/>
                                        </svg>
                                    </div>
                                </template>


                            </stat-box>
                            <stat-box title="Pending"
                                      :loading="loading.stats"
                                      :value="stats.pending ? stats.pending : 0"
                            >
                                <template v-slot:icon>
                                    <div class="flex-shrink-0 bg-blue-500 rounded-md p-3">
                                        <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="#fff"
                                             viewBox="0 0 24 24">
                                            <path d="M18.513 7.119c.958-1.143 1.487-2.577 1.487-4.036v-3.083h-16v3.083c0 1.459.528 2.892 1.487 4.035l3.086 3.68c.567.677.571 1.625.009 2.306l-3.13 3.794c-.936 1.136-1.452 2.555-1.452 3.995v3.107h16v-3.107c0-1.44-.517-2.858-1.453-3.994l-3.13-3.794c-.562-.681-.558-1.629.009-2.306l3.087-3.68zm-4.639 7.257l3.13 3.794c.652.792.996 1.726.996 2.83h-1.061c-.793-2.017-4.939-5-4.939-5s-4.147 2.983-4.94 5h-1.06c0-1.104.343-2.039.996-2.829l3.129-3.793c1.167-1.414 1.159-3.459-.019-4.864l-3.086-3.681c-.66-.785-1.02-1.736-1.02-2.834h12c0 1.101-.363 2.05-1.02 2.834l-3.087 3.68c-1.177 1.405-1.185 3.451-.019 4.863z"/>
                                        </svg>
                                    </div>
                                </template>
                            </stat-box>
                            <stat-box title="Approved"
                                      :loading="loading.stats"
                                      :value="stats.approved ? stats.approved : 0">

                                <template v-slot:icon>
                                    <div class="flex-shrink-0 bg-green-500 rounded-md p-3">
                                        <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                  d="M5 13l4 4L19 7"/>
                                        </svg>
                                    </div>
                                </template>

                            </stat-box>
                            <stat-box title="Refused"
                                      :loading="loading.stats"
                                      :value="stats.refused ? stats.refused : 0">


                                <template v-slot:icon>
                                    <div class="flex-shrink-0 bg-red-500 rounded-md p-3">
                                        <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                  d="M6 18L18 6M6 6l12 12"/>
                                        </svg>
                                    </div>
                                </template>


                            </stat-box>

                            <stat-box title="Split Decision"
                                      :loading="loading.stats"
                                      :value="stats.split ? stats.split : 0"
                            >

                                <template v-slot:icon>
                                    <div class="flex-shrink-0 bg-orange-500 rounded-md p-3">

                                        <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 24 24">
                                            <path fill="#fff" d="M19.172 5l1 1-1 1h-14.172v-2h14.172zm.828-2h-17v6h17l3-3-3-3zm-1 10v2h-14.171l-1-1 1-1h14.171zm2-2h-17l-3 3 3 3h17v-6zm-6-9v-2h-5v2h5zm-2 17v3h-1v-3h-2v5h5v-5h-2z"/>
                                        </svg>
                                    </div>
                                </template>

                            </stat-box>

                            <stat-box title="Withdrawn"
                                      :loading="loading.stats"
                                      :value="stats.withdrawn ? stats.withdrawn : 0"
                            >

                                <template v-slot:icon>
                                    <div class="flex-shrink-0 bg-gray-700 rounded-md p-3">
                                        <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path fill="#fff" d="M13.033 2v-2l10 3v18l-10 3v-2h-9v-7h1v6h8v-18h-8v7h-1v-8h9zm1 20.656l8-2.4v-16.512l-8-2.4v21.312zm-3.947-10.656l-3.293-3.293.707-.707 4.5 4.5-4.5 4.5-.707-.707 3.293-3.293h-9.053v-1h9.053z"/></svg>
                                    </div>
                                </template>

                            </stat-box>
                        </div>
                    </div>
                    <div class="column w-full lg:w-2/3">
                        <dashboard-widget class="h-full" title="Planning Applications"
                                          description="Overview of planning applications in the past 30 days">
                            <slot>
                                <div class="h-32 w-full" v-loading="true"
                                     v-if="loading.stats"
                                >
                                </div>

                                <apexchart type="bar"
                                           v-if="!loading.stats && getSalesChart !== null"
                                           :options="getSalesChart.options"
                                           :series="getSalesChart.series"></apexchart>
                            </slot>
                        </dashboard-widget>
                    </div>
                </div>
                </div>
                <div class="column w-full">

                </div>
                <div class="column w-full">
                </div>
                <div class="column w-full">
                    <div class="columns">
                        <div class="column w-full">
                            <dashboard-widget title="Recently Received"
                                              description="Recently received planning applications">
                                <slot>
                                    <application-table
                                            :filters="{exclude_future: true}"
                                            :sort="{prop: 'date_received', order: 'descending' }"
                                    />
                                </slot>
                            </dashboard-widget>
                        </div>
                        <div class="column w-full">
                            <dashboard-widget title="Recently Approved"
                                              description="Recently approved planning applications">
                                <slot>
                                    <application-table
                                            :filters="{decision: 'APPROVED', exclude_future: true}"
                                            :sort="{prop: 'date_decided', order: 'descending' }"
                                    />
                                </slot>
                            </dashboard-widget>
                        </div>
                        <div class="column w-full">
                            <dashboard-widget title="Recently Rejected"
                                              description="Recently rejected planning applications">
                                <slot>
                                    <application-table
                                            :filters="{decision: 'REFUSED', exclude_future: true}"
                                            :sort="{prop: 'date_decided', order: 'descending' }"
                                    />
                                </slot>
                            </dashboard-widget>
                        </div>

                        <div class="column w-full">
                            <dashboard-widget title="Recently Withdrawn"
                                              description="Recently withdrawn planning applications">
                                <slot>
                                    <application-table
                                            :filters="{decision: 'WITHDRAWN', exclude_future: true}"
                                            :sort="{prop: 'date_decided', order: 'descending' }"
                                    />
                                </slot>
                            </dashboard-widget>
                        </div>

                    </div>
                </div>
            </div>
            </template>


        </page>
    </div>

</template>

<script>

    import PageHeader from "../components/incs/PageHeader";
    import Page from "./Page";
    import DashboardWidget from "../components/incs/DashboardWidget";
    import StatBox from "../components/incs/StatBox";
    import ApplicationTable from "../components/ApplicationTable";
    import PlanningApplicationsNav from "./Applications/PlanningApplicationsNav";

    export default {
        name: 'app',
        components: {PlanningApplicationsNav, ApplicationTable, StatBox, DashboardWidget, Page, PageHeader},
        computed: {
            getSalesChart(){
                try {
                    return {
                        options: {
                            chart: {
                                zoom: {
                                    enabled: false
                                },
                            },
                            dataLabels: {
                                enabled: false
                            },
                            xaxis: {
                                categories: this.charts && this.charts.categories ? this.charts.categories : []
                            },
                            yaxis: {
                                labels: {
                                    formatter: (val) => {
                                        return val ?  val.toLocaleString() : 0
                                    }
                                }
                            }
                        },
                        series: this.charts && this.charts.data ? this.charts.data : []
                    }
                } catch (e) {
                    return null;
                }
            },
        },

        methods: {
            getStats() {
                let vm = this;
                vm.loading.stats = true;
                vm.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/stats/applications').then((response) => {
                    vm.stats = response.data.data;
                    vm.charts = response.data.data.chart;
                    vm.loading.stats = false;
                }).catch(() => {
                    vm.loading.stats = false;
                })
            },
        },
        created() {
            this.getStats();
        },
        data() {
            return {
                selected: this.$route.path,
                tabOptions: [
                    {label: 'Dashboard', url: '/planning-applications'},
                    {label: 'Search', url: '/planning-applications/search'},
                ],
                stats: {},
                loading: {
                    type: true,
                    status: true,
                    stats: false,
                },
                options: {
                    status: [],
                    type: [],
                },
                results: {
                    data: [],
                    points: []
                },
                filter: {
                    status: null,
                    local_authority: null,
                    search_by: 'distance',
                    type: null,
                    distance: 5,
                    unit: 'km'
                },
                map: {
                    zoom: 10,
                    center: [51.836254, -0.460615],
                    circle: {
                        color: 'red'
                    }
                }
            }
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.$store.commit('auth/setBreadcrumbs', [
                    {
                        title: 'Planning Applications'
                    }
                ]);
            })
        }
    }
</script>