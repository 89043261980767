<template>
    <div>
        <div class="p-8" v-if="!loaded">
            <property-loader />
        </div>
        <div class="columns" v-if="loaded">


            <div class="columns w-full">
                <div class="column w-48">
                    <div class="w-32 mt-4 mx-auto">
                        <filepond-avatar
                                @change="(val)=>{ profile.avatar = val; }"
                                v-model="profile.avatar"
                        />
                    </div>

                </div>
                <div class="column flex-grow">
                    <div class="column w-full">
                        <el-alert
                                v-if="!profile.email_verified"
                                type="warning" effect="dark" :closable="false">
                            Your email address has not been verified.
                        </el-alert>
                    </div>



                    <div class="column flex-grow">
                        <el-form label-position="left" label-width="200px"
                                 :rules="rules" ref="profileForm"
                                 :model="profile">
                            <el-form-item label="Name" prop="name">
                                <el-input v-model="profile.name"
                                          :disabled="updating"
                                />
                            </el-form-item>
                            <el-form-item label="Email" prop="email">
                                <el-input v-model="profile.email"
                                          :disabled="updating"
                                />
                            </el-form-item>
                            <el-form-item label="Date Format" prop="date_format">

                                <el-select
                                        v-model="profile.date_format"
                                        :disabled="updating"
                                        :sclearable="false"
                                >
                                    <el-option
                                            :key="k"
                                            v-for="(i, k) in date_format"
                                            :label="i.label"
                                            :value="i.value">
                                        {{ i.label }}
                                    </el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item label="Receive newsletter updates">
                                <el-switch
                                        :disabled="updating"
                                        v-model="profile.newsletter"
                                        active-color="#13ce66"
                                        inactive-color="#ff4949">
                                </el-switch>
                            </el-form-item>

                            <!--
                            <el-form-item label="Polygon Colour">
                                <v-swatches v-model="profile.polygon_color"
                                            fallback-input-type="color"
                                ></v-swatches>
                            </el-form-item>
                            -->



                            <!--
                            <el-form-item label="Map Style" prop="map_style" >

                                <el-select
                                        v-model="profile.map_style"
                                        :disabled="updating"
                                        :sclearable="false"
                                >
                                    <el-option
                                            :key="k"
                                            v-for="(i, k) in map_styles"
                                            :label="i.label"
                                            :value="i.value">
                                        {{ i.label }}
                                    </el-option>
                                </el-select>

                                <div class="py-4">
                                    <l-map
                                            :min-zoom="7"
                                            :max-zoom="14"
                                            ref="map"
                                            class="w-64 h-64 rounded"
                                            :center="center"
                                            :zoom="8"
                                    >
                                        <l-tile-layer
                                                :url="'https://api.os.uk/maps/raster/v1/zxy/' + profile.map_style + '/{z}/{x}/{y}.png?key=wC6Him1A448aAquBYlhEJq2IS4GAK51Z'"
                                        />
                                    </l-map>
                                </div>

                            </el-form-item>
                            -->
                        </el-form>
                    </div>

                    <div class="column w-full text-right">
                        <el-button
                                @click="preUpdate"
                                :disabled="updating"
                                :loading="updating"
                                icon="el-icon-refresh-right"
                                type="success">
                            Update
                        </el-button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>

    import PropertyLoader from "../../components/incs/PropertyLoader";
    import FilepondAvatar from "../../components/FilepondAvatar";

    export default {
        name: 'AccountProfileComponent',
        components: {FilepondAvatar, PropertyLoader},
        data() {
            return {
                center: [52.09228255792897, -0.9937155504271413],
                profile: {},
                email: null,
                loaded: false,
                updating: false,
                map_styles: [
                    {label: 'Road', value: 'Road_3857'},
                    {label: 'Outdoor', value: 'Outdoor_3857'},
                    {label: 'Light', value: 'Light_3857'},
                ],
                date_format: [
                    {value: 'MMM Do YYYY', label: this.$moment().format('MMM Do YYYY')},
                    {value: 'MMM Do YY',  label: this.$moment().format('MMM Do YY')},

                    {value: 'MM DD YYYY',  label: this.$moment().format('MM DD YYYY')},
                    {value: 'MM/DD/YYYY',  label: this.$moment().format('MM/DD/YYYY')},
                ],
                rules: {
                    name: [
                        {required: true, message: 'A name is required', trigger: 'blur'}
                    ],
                    date_format: [
                        {required: true}
                    ],
                    map_style: [
                        {required: true}
                    ],
                    newsletter: [
                        {required: true}
                    ],
                    email: [
                        {required: true, type: 'email', message: 'A valid email is required', trigger: 'blur'}
                    ]
                }
            }
        },
        created(){
          this.fetch();
        },
        methods: {
            fetch(){
                const vm = this;
                vm.$http.get('/api/v1/auth/user')
                    .then((response) => {
                        vm.profile = response.data.data;
                        vm.email = response.data.data.email;
                        vm.loaded = true;
                    })
                    .catch(() => {
                        vm.loaded = false;
                    })
            },
            preUpdate(){
                let vm = this;
                vm.$refs['profileForm'].validate((valid) => {
                    if (!valid) {
                        return false;
                    } else {
                        if(vm.profile.email !== vm.email){
                            vm.$confirm(
                                'Are you sure you want to change your email? You will need to re-verify this new email address.',
                                'Change Email Address?',
                                {
                                    confirmButtonText: 'Yes',
                                    cancelButtonText: 'Cancel'
                                })
                                .then(() => {
                                    vm.update();
                                })
                                .catch(() => {

                                })

                        }else{
                            vm.update();
                        }
                    }
                });
            },
            update(){
                let vm = this;
                vm.updating = true;
                vm.$http.put('/api/v1/auth/user', {
                    email: vm.profile.email,
                    avatar: vm.profile.avatar,
                    name: vm.profile.name,
                    date_format: vm.profile.date_format,
                    map_style: vm.profile.map_style,
                    polygon_color: vm.profile.polygon_color,
                    newsletter: vm.profile.newsletter,
                })
                    .then(() => {

                        vm.$http.get('/api/v1/auth/user')
                            .then((response) => {
                                vm.updating = false;
                                vm.$store.commit('auth/setUser', response.data.data);
                                vm.$notify({
                                    type: 'success',
                                    title: 'Profile Updated',
                                    message: 'Profile updated successfully',
                                    position: 'top-right',
                                    offset: 140,
                                });
                            }).catch(()=>{
                            vm.updating = false;
                        })



                    })
                    .catch((error) => {
                        vm.updating = false;
                        this.$notify({
                            type: 'warning',
                            title: 'Sorry, an error occurred.',
                            message: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Please validate the form before submitting',
                            position: 'top-right',
                            offset: 140,
                        });
                    })
            }
        }
    }
</script>