<template>
    <h3 class="text-xl font-bold my-4">
    <slot></slot>
    </h3>
</template>

<script>
    export default {
        name: "ReportTitle"
    }
</script>

<style scoped>

</style>