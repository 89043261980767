<template>
<div>

    <div
            v-if="loading"
            class="flex h-full mt-12 pt-12 items-center justify-center">
        <div class="p-3 text-center">
            <property-loader />
            <h3 class="font-semibold text-blue-800">Fetching Leaseholders...</h3>
        </div>
    </div>

    <div v-if="!loading" class="columns">
        <div class="column w-full">
            <dashboard-widget
                    padding-class="''"
                    title="Leaseholders">
                <slot>

                    <div class="p-2"   v-if="propertiesList && Array.isArray(propertiesList) && propertiesList.length > 0">
                        <el-alert
                                effect="dark" type="success" :closable="false">
                            {{ propertiesList.length }} leaseholders within this site
                        </el-alert>
                    </div>


                    <div class="p-3" v-if="!propertiesList || !propertiesList.length">
                        <empty-state />
                    </div>

                    <div
                            class="el-collapse--sale-prices"
                            v-if="propertiesList && Array.isArray(propertiesList) && propertiesList.length > 0">

                        <el-collapse
                                class="el-collapse--value"
                                v-model="activeProperties">
                            <el-collapse-item
                                    :key="item.uprn"
                                    v-for="(item) of propertiesList"
                                    :name="item.uprn">

                                <template slot="title">
                                    <div class="flex flex-wrap w-full flex-row">
                                        <div class="w-full py-2 pb-3">
                                            {{ item.single_line_address }}
                                        </div>
                                        <div class="text-xs w-full">
                                            Started  <date-human-view size="text-xs" v-model="item.leasehold_details.date_of_lease" />  years ago with a term of  {{ item.leasehold_details.term }}
                                        </div>
                                    </div>
                                </template>

                                <div class="p-3">


                                    <div class="columns flex-no-wrap" v-if="item.tenure">
                                        <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                                            Tenure
                                        </div>
                                        <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                            {{ item.tenure }}
                                        </div>
                                    </div>

                                    <div class="columns flex-no-wrap" v-if="item.leasehold_details.price_paid">
                                        <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                                            Price Paid
                                        </div>
                                        <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                            £{{ item.leasehold_details.price_paid.toLocaleString()  }}
                                        </div>
                                    </div>

                                    <div class="columns flex-no-wrap" v-if="item.leasehold_details.date_of_lease">
                                        <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                                            Date of Lease
                                        </div>
                                        <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                            <date-human-view size="text-xs" v-model="item.leasehold_details.date_of_lease" />
                                        </div>
                                    </div>

                                    <div class="columns flex-no-wrap" v-if="item.leasehold_details.term">
                                        <div class="column mr-auto text-xs text-gray-500 w-1/3 font-medium flex">
                                            Term
                                        </div>
                                        <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                            {{ item.leasehold_details.term }}
                                        </div>
                                    </div>



                                </div>


                            </el-collapse-item>
                        </el-collapse>



                    </div>
                </slot>
            </dashboard-widget>
        </div>

    </div>
</div>

</template>


<script>
    import EmptyState from "../../EmptyState";
    import PropertyLoader from "../../incs/PropertyLoader";
    export default {
        name: "OwnershipLeaseholders",
        components: {PropertyLoader, EmptyState},
        props: {
            value: {
                type: Array,
                default(){
                    return []
                }
            },
            activeSiteId: {
                type: Number,
                default(){
                    return null;
                }
            },
            loading: {
                type: Boolean,
                default(){
                    return true;
                }
            }
        },
        computed: {
            propertiesList(){
                if(this.activeSiteId){
                    return this.value.filter((i)=>{
                        return i.site_id === this.activeSiteId;
                    })
                }
                return this.value
            },
        },
        data(){
            return {
                activeProperties: []
            }
        }
    }
</script>

<style>
    .el-collapse--value  .el-collapse-item__header {
        line-height: 15px !important;
        height: auto !important;
        padding: 10px !important;
    }

</style>