<template>
    <div>
        <div v-if="!$store.state.auth.user.team.is_owner">
            <dashboard-widget title="You don't have a Land Monitor Subscription"
                              padding-class="p-6">
                <div
                        class="text-center">
                    <div class="mx-auto flex items-center h-full justify-center">
                        <div class="columns w-full lg:w-auto">
                            <div class="column w-full">
                                <div class="m-6 mx-auto">
                                    <lottie :options="{animationData: animationData}" :height="150"  />
                                </div>
                                <div class="leading-loose">
                                    <p class="mb-1">Looks like you don't have a subscription to Land Monitor or your previous subscription has expired.</p>
                                    <p class="mb-1">Contact your team owner in order to activate a subscription.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </dashboard-widget>
        </div>
        <div v-else>
            <dashboard-widget title="Manage your Land Monitor Subscription"
                              description="Please select a subscription/plan to continue using Land Monitor.">

                <div v-if="loading.server" class="p-3 py-12 text-center">
                    <property-loader />
                </div>

                <div
                        v-loading="loading.confirmation"
                        :class="{
                        'has-available-subs': !hasUnusedSubscriptions
                        }" v-else>
                    <el-tabs v-model="subscriptionTab">

                        <el-tab-pane label="Available Subscriptions" name="existing">

                            <div class="relative bg-white rounded-md -space-y-px w-full">
                                <label
                                        :key="item.id"
                                        v-for="(item, index) of seats"
                                        :class="{
                                        'border-gray-200' : selectedExistingSubscription !== item.id,
                                        'bg-indigo-100 border-indigo-200 z-10': selectedExistingSubscription  === item.id,
                                        'rounded-tl-md rounded-tr-md': index === 0 && seats.length > 1,
                                         'rounded-bl-md rounded-br-md': index === (seats.length - 1) && seats.length > 1,
                                        }"
                                        class="relative border p-4 flex cursor-pointer md:pl-4 md:pr-6">
                                    <div class="flex w-1/3 items-center text-sm leading-tight w-full">
                                        <input type="radio"
                                               v-model="selectedExistingSubscription" :value="item.id" class="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500" aria-labelledby="pricing-plans-0-label" aria-describedby="pricing-plans-0-description-0 pricing-plans-0-description-1">
                                        <span
                                                :class="{
                                                  'text-gray-900' : selectedExistingSubscription  !== item.id,
                                        'text-indigo-900': selectedExistingSubscription  === item.id,
                                            }"
                                                class="ml-4 font-medium">
                                                          Subscription till {{ item.subscription_to | moment("MMM Do YYYY") }}<br />
                                              <span class="text-xs"><date-human-view size="text-xs" prefix="Expires in " v-model="item.subscription_to" /></span>
                                        </span>
                                    </div>
                                </label>

                            </div>

                            <div class="text-right mt-10">
                                <el-button
                                           icon="el-icon-arrow-right"
                                           @click="assignSeat"
                                           type="success">Assign Subscription</el-button>
                            </div>

                        </el-tab-pane>
                        <el-tab-pane label="Create Subscription" name="new">
                                <div class="p-3">
                                    <div class="mb-2">
                                        <h4 class="sr-only">Status</h4>
                                        <div aria-hidden="true">
                                            <div class="bg-gray-200 rounded-full overflow-hidden">
                                                <div class="h-2 bg-blue-600 rounded-full"
                                                     :style="'width: ' + getPercentage + '%'"></div>
                                            </div>
                                            <div class="hidden sm:grid grid-cols-3 text-sm font-medium text-gray-600 mt-2">
                                                <div :class="{
                    'text-blue-600': getPercentage >= 33
                    }">Payment Details</div>
                                                <div class="text-center"  :class="{
                    'text-blue-600': getPercentage >= 66
                    }">Subscription</div>
                                                <div class="text-right"  :class="{
                    'text-blue-600': getPercentage >= 99
                    }">Confirmation</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="!loading.server">


                                        <div v-show="step === 'payment'" class="w-full"  v-loading="loading.card_verification">

                                            <div class="my-4" v-if="existing_cards.length > 0">
                                                <el-radio v-model="card.type" label="new">Add New Card</el-radio>
                                                <el-radio v-model="card.type" label="existing">Use Existing Card</el-radio>
                                            </div>


                                            <div class="relative">

                                                <el-form
                                                        v-show="card.type === 'new'"
                                                        label-position="top"
                                                        size="small"
                                                        label-width="240px"
                                                        :rules="rules" ref="cardBillingForm"
                                                        :model="card">


                                                    <el-form-item  label="Cardholder Name" prop="card_holder">
                                                        <el-input size="small" v-model="card.card_holder" />
                                                    </el-form-item>

                                                    <el-form-item label="Card Details" prop="card">
                                                        <div class="relative">
                                                            <stripe-element-card
                                                                    :hidePostalCode="true"
                                                                    ref="stripeElementRef"
                                                                    @error="onStripeError"
                                                                    @token="onTokenCreated"
                                                                    :pk="$store.state.auth.stripe_key"
                                                            />
                                                        </div>
                                                    </el-form-item>


                                                    <div class="flex w-full">
                                                        <div class="w-1/2">
                                                            <el-form-item label="Address Line One" prop="address_line_one">
                                                                <el-input size="small" v-model="card.address_line_one" />
                                                            </el-form-item>
                                                        </div>
                                                        <div class="w-1/2 ml-4">
                                                            <el-form-item label="Address Line Two" prop="address_line_two">
                                                                <el-input size="small" v-model="card.address_line_two" />
                                                            </el-form-item>
                                                        </div>
                                                    </div>
                                                    <div class="flex w-full">
                                                        <div class="w-1/2">
                                                            <el-form-item label="Town/City" prop="address_city">
                                                                <el-input size="small" v-model="card.address_city" />
                                                            </el-form-item>
                                                        </div>
                                                        <div class="w-1/2 ml-4">
                                                            <el-form-item label="Zip/Postal Code" prop="address_zip_post_code">
                                                                <el-input size="small" v-model="card.address_zip_post_code" />
                                                            </el-form-item>
                                                        </div>
                                                    </div>



                                                </el-form>


                                                <div v-if="card.type === 'existing' && existing_cards.length > 0">
                                                    <div class="relative bg-white rounded-md -space-y-px w-full">
                                                        <label
                                                                :key="item.id"
                                                                v-for="(item, index) of existing_cards"
                                                                :class="{
                                        'border-gray-200' : card.token !== item.id,
                                        'bg-indigo-100 border-indigo-200 z-10': card.token  === item.id,
                                        'rounded-tl-md rounded-tr-md': index === 0 && existing_cards.length > 1,
                                         'rounded-bl-md rounded-br-md': index === (pricing_plans.length - 1) && existing_cards.length > 1,
                                        }"
                                                                class="relative border p-4 flex cursor-pointer md:pl-4 md:pr-6">
                                                            <div class="flex w-1/3 items-center text-sm leading-tight w-full">
                                                                <input type="radio"
                                                                       v-model="card.token" :value="item.id" class="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500" aria-labelledby="pricing-plans-0-label" aria-describedby="pricing-plans-0-description-0 pricing-plans-0-description-1">
                                                                <span
                                                                        :class="{
                                                  'text-gray-900' : card.token  !== item.id,
                                        'text-indigo-900': card.token  === item.id,
                                            }"
                                                                        class="ml-3">
                                            <span class="font-medium">{{ item.name ? item.name : item.brand }}</span><br />
                                            <small>{{ item.brand }} - {{ item.exp }}</small>
                                        </span>
                                                            </div>
                                                            <p class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 leading-tight text-right w-1/2">
                                        <span
                                                :class="{
                                                  'text-gray-900' : card.token  !== item.id,
                                        'text-indigo-900': card.token  === item.id,
                                            }"
                                                class="font-medium">
                                            XXXX XXXX XXXX {{ item.last4 }}
                                        </span>
                                                            </p>
                                                        </label>

                                                    </div>
                                                </div>


                                                <div class="w-full pt-4 text-right">
                                                    <el-button
                                                            type="success"
                                                            icon="el-icon-arrow-right"
                                                            @click="verifyPaymentDetails">{{ card.type === 'new' ? 'Add ' : 'Select ' }} Payment Method</el-button>
                                                </div>


                                            </div>

                                        </div>

                                        <div v-if="step === 'subscription'">
                                            <div class="relative bg-white rounded-md -space-y-px w-full mt-12">
                                                <label
                                                        :key="item.id"
                                                        v-for="(item, index) of pricing_plans"
                                                        :class="{
                                        'border-gray-200' : selected_plan !== item.id,
                                        'bg-indigo-100 border-indigo-200 z-10': selected_plan === item.id,
                                        'rounded-tl-md rounded-tr-md': index === 0,
                                         'rounded-bl-md rounded-br-md': index === (pricing_plans.length - 1),
                                        }"
                                                        class="relative border p-4 flex cursor-pointer md:pl-4 md:pr-6">
                                                    <div class="flex w-1/3 items-center text-sm leading-tight w-full">
                                                        <input type="radio"
                                                               v-model="selected_plan" :value="item.id" class="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500" aria-labelledby="pricing-plans-0-label" aria-describedby="pricing-plans-0-description-0 pricing-plans-0-description-1">
                                                        <span
                                                                :class="{
                                                  'text-gray-900' : selected_plan !== item.id,
                                        'text-indigo-900': selected_plan === item.id,
                                            }"
                                                                class="ml-3">
                                            <span class="font-medium">{{ item.name }}</span><br />
                                            <small>{{ item.description }}</small>
                                        </span>
                                                    </div>
                                                    <p class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 leading-tight text-right w-1/2">
                                        <span
                                                :class="{
                                                  'text-gray-900' : selected_plan !== item.id,
                                        'text-indigo-900': selected_plan === item.id,
                                            }"
                                                class="font-medium">£{{ item.price }} / per user / {{ item.term }}
                                        </span>
                                                    </p>
                                                </label>

                                            </div>

                                            <p class="text-xs leading-tight break-normal mt-2">Prices displayed are excluding VAT. All subscriptions are renewed on a monthly or annually basis unless cancelled.</p>


                                            <div class="w-full pt-12 flex text-right items-center">
                                                <div class="mr-auto">
                                                    <p class="cursor-pointer text-gray-500 hover:text-blue-800 text-sm" @click="() => step = 'payment'">Previous Step</p>
                                                </div>
                                                <el-button type="success"
                                                           @click="() => step = 'confirmation'"
                                                           icon="el-icon-d-arrow-right">Confirm Subscription</el-button>
                                            </div>


                                        </div>

                                        <div v-if="step === 'confirmation'" class="mt-4 bg-white">
                                            <ul role="list" class="divide-y divide-gray-200">
                                                <li class="flex py-6 px-4 sm:px-6">
                                                    <div class="flex w-1/3 items-center text-sm leading-tight w-full">
                               <span class="ml-3">
                                            <span class="font-medium">Land Monitor {{ getPlan.name }}</span><br />
                                            <small>{{ getPlan.description }}</small>
                                        </span>
                                                    </div>
                                                    <p class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 leading-tight text-right w-1/2">
                                        <span
                                                class="font-medium">
                                            £{{ getPlan.price }} / per user / {{ getPlan.term }}
                                        </span>
                                                    </p>
                                                </li>

                                                <!-- More products... -->
                                            </ul>
                                            <dl class="border-t border-gray-200 py-6 px-4 space-y-6 sm:px-6">
                                                <div class="flex items-center justify-between">
                                                    <dt class="text-sm">Subtotal</dt>
                                                    <dd class="text-sm font-medium text-gray-900">{{ getPlanSubtotal }}</dd>
                                                </div>
                                                <div class="flex items-center justify-between">
                                                    <dt class="text-sm">VAT (20%)</dt>
                                                    <dd class="text-sm font-medium text-gray-900">{{ getCalculateVAT }}</dd>
                                                </div>
                                                <div class="flex items-center justify-between border-t border-gray-200 pt-6">
                                                    <dt class="text-base font-medium">Total</dt>
                                                    <dd class="text-base font-medium text-gray-900">{{ getPlanTotal }}</dd>
                                                </div>
                                            </dl>
                                            <p class="text-xs leading-tight break-normal mt-2">
                                                All subscriptions are renewed on a monthly or annually basis unless cancelled.</p>


                                            <div class="w-full pt-12 flex text-right items-center">
                                                <div class="mr-auto">
                                                    <p class="cursor-pointer text-gray-500 hover:text-blue-800 text-sm" @click="() => step = 'subscription'">Previous Step</p>
                                                </div>
                                                <el-button type="success"
                                                           @click="confirmSubscriptionPurchase"
                                                           icon="el-icon-check">Confirm Purchase</el-button>
                                            </div>

                                        </div>

                                    </div>
                                    <div v-else>
                                        <div class="p-3 py-12 text-center">
                                            <property-loader />
                                        </div>
                                    </div>
                                </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>

            </dashboard-widget>
        </div>
    </div>
</template>
<script>
    import * as animationData from "./../incs/Animations/construction";
    import PropertyLoader from "../incs/PropertyLoader";
    import Lottie from "vue-lottie";


    export default {
        name: 'AccountNoActiveSubscription',
        components: {
            PropertyLoader,
            Lottie
        },
        data(){
            return {
                selectedExistingSubscription: null,
                subscriptionTab: 'new',
                step: 'payment',
                loading: {
                    card_verification: false,
                    server: true,
                    confirmation: false
                },
                existing_cards: [],
                seats: [],
                rules: {
                    card_holder: [
                        {required: true, message: 'The cardholder\'s name is required.', trigger: 'blur'},
                    ],
                    address_line_one: [
                        {required: true, message: 'An address is required.', trigger: 'blur'},
                    ],
                    address_city: [
                        {required: true, message: 'A town/city is required.', trigger: 'blur'},
                    ],
                    address_zip_post_code: [
                        {required: true, message: 'A zip/postcode is required.', trigger: 'blur'},
                    ]
                },
                card: {
                    type: 'new',
                    token: null,
                    card_holder: '',
                    address_line_one: '',
                    address_line_two: '',
                    address_city: '',
                    address_zip_post_code: '',
                    validating: false
                },
                vat_rate: 0,
                animationData: animationData.default,
                creating: false,
                selected_plan: null,
                pricing_plans: [],
            }
        },
        watch: {
          'card.type'(){
              if(this.card.type === 'new'){
                  this.card.token = null;
              }
              if(this.card.type === 'existing' && this.existing_cards.length > 0){
                  this.card.token = this.existing_cards[0].id
              }
          },

        },
        mounted(){

            this.pricing_plans = [
                {id: this.$store.state.auth.plans.yearly, name: 'Yearly', price: 999, term: 'year', description: 'Our most popular package with 12 months for the price of 10.'},
                {id: this.$store.state.auth.plans.monthly, name: 'Monthly', price: 99, term: 'month', description: 'For those looking to try out Land Monitor.'},
            ];
            this.selected_plan = this.$store.state.auth.plans.yearly;

            if(this.$store.state.auth.user.team.is_owner){
                this.fetch()
            }
        },
        computed: {
            hasUnusedSubscriptions(){
              return this.seats.length > 0;
            },
            getSubmissionDetails(){
              return {
                  plan: this.getPlan ? this.getPlan.id : 0,
                  token: this.card.token ? this.card.token : null
              }
            },
            getPercentage(){
                if(this.step === 'payment'){
                    return 33.333;
                }
                if(this.step === 'subscription'){
                    return 66.666;
                }
                return 100;
            },
            getPlanTotal(){
                let price = this.getPlan ? (this.getPlan.price + this.calculateVAT) : 0;
                return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(price)
            },
            calculateVAT(){
                let price = this.getPlan ? this.getPlan.price : 0;
                return (20 / 100) * price;
            },
            getCalculateVAT(){
                return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(this.calculateVAT)
            },
            getPlanSubtotal(){
                let price = this.getPlan ? this.getPlan.price : 0;
                return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(price)
            },
            getPlan(){
                const vm = this;
              let found = this.pricing_plans.find((x) => {
                  return x.id === vm.selected_plan;
              });
              return found ? found : null;
            }
        },
        methods: {
            assignSeat(){
                const vm = this;
                vm.loading.confirmation = true;

                const team = this.$store.state.auth.user.team;

                vm.$http.post('/api/v1/team/' + team.id + '/member/' + team.member_id + '/subscription/' + vm.selectedExistingSubscription)
                    .then(()=>{

                            vm.$http.get('/api/v1/auth/user')
                                .then((response)=> {
                                    vm.$store.commit('auth/setUser', response.data.data);
                                    location.reload();
                                })
                                .catch(() => {
                                    vm.loading.confirmation = false;
                                })

                    })
                    .catch(() => {
                        vm.loading.confirmation = false;
                    })
            },
            confirmSubscriptionPurchase(){
                const vm = this;
                vm.loading.confirmation = true;
            },
            verifyPaymentDetails(){
                if(this.card.type === 'new'){
                    this.verifyBillingDetails();
                }else{
                    this.step = 'subscription';
                }
            },
            verifyBillingDetails(){
                let vm = this;
                vm.creating = true;
                vm.$refs['cardBillingForm'].validate((valid) => {
                    if (!valid) {
                        vm.creating = false;
                        return false;
                    } else {
                        vm.loading.card_verification = true;
                        vm.$refs.stripeElementRef.submit();
                    }
                });
            },
            onStripeError(){
                let vm = this;
                vm.loading.card_verification = false;
            },
            onTokenCreated(token){
                let vm = this;
                vm.card.token = token && token.card && token.card.id ? token.card.id : null;
                vm.loading.card_verification  = false;
                this.step = 'subscription';
            },
            fetch(){
                const vm = this;
                
                vm.$http.get('/api/v1/auth/user')
                    .then((response)=>{

                        vm.$store.commit('auth/setUser', response.data.data);

                        Promise.all([
                            vm.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/payment-methods'),
                            vm.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/seats?available=true')
                        ]).then(([methods, seats]) => {
                            vm.existing_cards = methods.data.data;
                            vm.seats = seats.data.data;
                            vm.selectedExistingSubscription = vm.seats && vm.seats.length > 0 ? vm.seats[0].id : null;
                            if(vm.selectedExistingSubscription){
                                vm.subscriptionTab = 'existing';
                            }
                            vm.loading.server = false;
                        }) .catch(() => {
                            vm.loading.server = false;
                        });

                    })
                    .catch(()=>{
                        vm.loading.server = false;
                    })
            }
        }
    }
</script>
<style>
    .has-available-subs .el-tabs__header {
        display: none !important;
    }
    .el-form-item--small {
        margin-bottom: 10px !important;
    }
    .el-form-item--small .el-form-item__label {
        font-size: 0.8rem;
        padding-bottom: 5px;
    }
</style>