<template>
  <div class="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full flex justify-center items-center text-center sm:max-w-md">
      <svg viewBox="0 0 100 133"
           version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="w-6 mr-2">
        <g stroke="none" stroke-width="1" fill-rule="evenodd" class="fill-current text-green-600"><g><g><path d="M99.469,49.958 C99.469,66.251 84.687,89.43 71.25,107.167 L63.289,97.727 C76.121,80.427 87.375,61.413 87.375,49.958 C87.375,29.198 70.512,12.301 49.718,12.301 C28.957,12.301 12.094,29.198 12.094,49.958 C12.094,65.411 32.552,94.57 49.718,114.792 L57.578,124.131 C52.908,129.641 49.751,132.999 49.751,132.999 C49.751,132.999 0,79.823 0,49.958 C0,22.479 22.272,0.207 49.751,0.207 C77.196,0.207 99.469,22.479 99.469,49.958" id="Fill-13"></path><path d="M60.568,101.288 L68.529,110.728 C65.674,114.423 62.919,117.782 60.467,120.739 L52.573,111.4 L49.718,108.007 C32.652,87.246 16.46,62.22 16.46,49.958 C16.46,31.583 31.376,16.668 49.718,16.668 C68.093,16.668 83.008,31.583 83.008,49.958 C83.008,59.633 72.964,77.168 60.366,94.301 L52.338,84.76 C65.069,67.091 70.914,56.214 70.914,49.958 C70.914,38.268 61.408,28.761 49.718,28.761 C38.027,28.761 28.554,38.268 28.554,49.958 C28.554,56.214 35.205,68.804 49.718,88.388 L57.679,97.862 L60.568,101.288" id="Fill-15"></path></g></g></g></svg>
      <h1 class="text-white text-2xl">Land Monitor</h1>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-sm px-6 md:px-0">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

     <div class="mb-6">
       <el-alert effect="dark" type="success" :closable="false">
         Create your account below to start a 7 day free trial of Land Monitor.
       </el-alert>
     </div>
        <form
        >
          <div>
            <label
                    for="name"
                    class="block text-sm font-medium leading-5 text-gray-700"
            >
              Full name <sup class="text-red-600">*</sup>
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <el-input id="name"
                        :disabled="running"
                        placeholder="E.g, Elon Musk"
                        @keyup.enter.native="register"
                        v-model="name" />
            </div>
          </div>
          <div class="mt-6">
            <label
              for="email"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Email address <sup class="text-red-600">*</sup>
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <el-input id="email"
                        :disabled="running"
                        placeholder="E.g, bill@microsoft.com"
                        @keyup.enter.native="register"
                        v-model="email" />
            </div>
          </div>

          <div class="mt-6">
            <label
              for="password"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Password <sup class="text-red-600">*</sup>
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <el-input
                      :disabled="running"
                      id="password"
                v-model="password"
                      @keyup.enter.native="register"
                      placeholder="E.g, ******"
                type="password"
                autocomplete="off"
              />
            </div>
          </div>


          <div class="mt-6 flex items-center justify-between">
            <div class="text-sm leading-5">
              <a
                href="/login"
                class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
              >
                Already registered?
              </a>
            </div>
          </div>

          <recaptcha-component
                  class="mt-3"
                  ref="grecaptcha"
                  @token="(value)=> recaptcha_value = value "
          />

          <div class="mt-6">
            <span class="block w-full rounded-md shadow-sm">
              <el-button
                class="w-full"
                type="info"
                :disabled="running"
                :loading="running"
                @click="register"
              >
                Register
              </el-button>
            </span>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

import { register, reset } from '../utils/auth'
import RecaptchaComponent from "../components/RecaptchaComponent";

export default {
  name: 'Register',
  components: {RecaptchaComponent},
  data () {
    return {
      name: null,
      email: null,
      password: null,
      recaptcha_value: null,
      running: false
    }
  },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
          vm.$store.commit('auth/setBreadcrumbs', [
            {title: 'Register'}
          ]);
          reset(vm)
        });
    },
  created(){
    const vm = this;
    if(this.$route.query) {
      if(this.$route.query['email']){
        vm.email = this.$route.query['email'];
      }
      if(this.$route.query['name']){
        vm.name = this.$route.query['name'];
      }
    }
  },
  methods: {
    register () {
      const vm = this

      if (!this.recaptcha_value) {
        vm.$notify({
          type: 'warning',
          title: 'Recaptcha Required',
          message: 'Please verify your not a robot before logging in.',
          position: 'top-right',
        });
        return;
      }


      vm.running = true
      register(vm.name, vm.email, vm.password, vm.recaptcha_value)
        .then(() => {

          vm.$http.get('/api/v1/auth/user')
                  .then((response)=>{
                    vm.$store.commit('auth/setUser', response.data.data);
                    vm.$router.push(vm.$route.query.redirect ? vm.$route.query.redirect : '/')
                  })
                  .catch((e)=>{
                    vm.running = false;
                    vm.$refs.grecaptcha.reset()
                    vm.$store.dispatch('auth/handleServerError', {
                      vm: vm,
                      error: e
                    });
                  })

        })
        .catch((e) => {
          vm.running = false;
          vm.$refs.grecaptcha.reset()
          vm.$store.dispatch('auth/handleServerError', {
            vm: vm,
            error: e,
            offset: 0
          });
        })
    }
  }
}
</script>
<style>
  .bg-particles {
    background-image: url("/img/particles-transparent.png");
  }
</style>