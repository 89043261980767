<template>
    <h3 class="font-bold my-2" :class="size">
    <slot></slot>
    </h3>
</template>

<script>
    export default {
        name: "ReportSubTitle",
        props: {
            size: {
                type: String,
                default(){
                    return 'text-lg'
                }
            }
        }
    }
</script>

<style scoped>

</style>