<template>
    <page :show-content="false">


        <!-- Neighbour compare -->
        <neighbour-compare-modal
                v-if="modal_data.neighbour_compare.show"
                v-model="modal_data.neighbour_compare.usrn"
                @close="()=>{ modal_data.neighbour_compare.show = false }"

        />

        <!-- Corporate Ownership --->

        <corporate-ownership-modal
                v-if="modal_data.company.show"
                :hash="modal_data.company.hash"
                @close="()=> { modal_data.company.show = false }"
                @view="(titleNumber)=>{
                modal_data.company.show = false;
                search.title_number.value  = titleNumber;
                fetchTitleNumberPolygons();
                 }"
        />

        <!--- Workflows ---->

        <el-dialog
                title="My Workflows"
                :close-on-press-escape="!modal_data.workflow.loading_workflows"
                :close-on-click-modal="!modal_data.workflow.loading_workflows"
                :visible.sync="modal_data.workflow.show"
                width="30%">

            <property-loader v-if="modal_data.workflow.loading_workflows"/>

            <div v-if="!modal_data.workflow.loading_workflows && !modal_data.workflow.workflows.length">
                <empty-state class="p-3"
                             title="No workflows found"
                             message="Sorry, but we could not find any workflows"
                             :support="false"
                />
            </div>

            <div class="mb-3"
                 v-if="!modal_data.workflow.loading_workflows && modal_data.workflow.workflows.length > 0">
                <label class="text-xs uppercase block mb-2 block">Select a Workflow</label>

                <el-select
                        size="medium"
                        class="w-full"
                        popper-class="el-block--workflow-select"
                        filterable
                        @change="onWorkflowChange"
                        placeholder="Loading Workflows..."
                        v-model="modal_data.workflow.active_workflow"
                >
                    <el-option
                            :key="k"
                            :value="i.id"
                            :label="i.name"
                            v-for="(i, k) of modal_data.workflow.workflows">
                        <strong>{{ i.name }}</strong>
                        <div class="-mx-1 flex w-full p-1 text-xs">
                            <div class="p-1 flex justify-center items-center">
                                <svg class="mr-1 fill-current text-gray-500" xmlns="http://www.w3.org/2000/svg"
                                     width="15" height="15" viewBox="0 0 24 24">
                                    <path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z"></path>
                                </svg>
                                <span>  {{ i.columns }} columns</span>
                            </div>
                            <div class="p-1 flex justify-center items-center">
                                <svg class="mr-1 fill-current text-gray-500" xmlns="http://www.w3.org/2000/svg"
                                     width="15" height="15" viewBox="0 0 24 24">
                                    <path d="M11.362 2c4.156 0 2.638 6 2.638 6s6-1.65 6 2.457v11.543h-16v-20h7.362zm.827-2h-10.189v24h20v-14.386c0-2.391-6.648-9.614-9.811-9.614zm4.811 13h-3v-1h3v1zm0 2h-3v1h3v-1zm0 3h-10v1h10v-1zm-5-6h-5v4h5v-4z"/>
                                </svg>

                                <span>  {{ i.title_numbers }} title numbers</span>
                            </div>
                        </div>
                    </el-option>
                </el-select>
            </div>

            <div v-if="modal_data.workflow.active_workflow && !modal_data.workflow.loading_workflows">
                <workflow-kanban-board
                        ref="kanban"
                        :show-delete="false"
                        :show-edit="false"
                        loader-color="#fff"
                        :confirm-view="handleWorkflowView"
                        v-model="modal_data.workflow.active_workflow"
                />
            </div>


        </el-dialog>


        <!-- Switch Teams --->
        <el-dialog
                title="Switch Teams"
                :close-on-press-escape="!$store.state.auth.showSwitchTeam"
                :close-on-click-modal="!$store.state.auth.showSwitchTeam"
                :visible.sync="$store.state.auth.showSwitchTeam"
                width="30%">
            <switch-team />
        </el-dialog>

        <!--- Account dialog ---->
        <el-dialog
                title="Your Account"
                :close-on-press-escape="!$store.state.auth.account.updating"
                :close-on-click-modal="!$store.state.auth.account.updating"
                :show-close="!$store.state.auth.account.updating"
                :visible.sync="$store.state.auth.account.visible"
                width="30%">
            <account />
        </el-dialog>

        <!--- Lookup History dialog ---->
        <el-dialog
                title="Your Lookup History"
                :close-on-press-escape="!$store.state.team.lookups.loading"
                :close-on-click-modal="!$store.state.team.lookups.loading"
                :visible.sync="$store.state.team.lookups.visible"
                width="30%">
            <lookup-history
                    @view="(titleNumber)=>{
                $store.state.team.lookups.visible = false;
                search.title_number.value  = titleNumber;
                fetchTitleNumberPolygons();
                 }"
                    @share="(titleNumber)=>{
                            modal_data.share_title_number.title = titleNumber;
                            modal_data.share_title_number.show = true;
                             }"
                    v-if="$store.state.team.lookups.visible" />
        </el-dialog>



        <!--- Custom Areas dialog ---->
        <el-dialog
                title="Your Custom Draw Areas"
                :close-on-press-escape="!$store.state.team.customAreas.loading"
                :close-on-click-modal="!$store.state.team.customAreas.loading"
                :visible.sync="$store.state.team.customAreas.visible"
                width="30%">
            <custom-areas
                    @view="(polygon) => {
                    $store.state.team.customAreas.visible = false;
                    fetchCustomDrawOverview(polygon);
                    }"
                    @share="(polygon) => {
                        modal_data.share_custom_polygon.polygon = polygon;
                        modal_data.share_custom_polygon.show = true;
                    }"
                    v-if="$store.state.team.customAreas.visible" />
        </el-dialog>




        <!--- Teams dialog ---->
        <el-dialog
                title="My Teams"
                :close-on-press-escape="!$store.state.team.updating"
                :close-on-click-modal="!$store.state.team.updating"
                :show-close="!$store.state.team.updating"
                :visible.sync="$store.state.team.visible"
                width="30%">
            <team />
        </el-dialog>

        <!-- Map Element --->
        <div v-if="!hasOutstandingAccountAction">
            <div class="el-block--search p-2 flex bg-gray-800">
                <div class="w-32 p-2">
                    <floating-label-input label="Select Map">

                        <el-select
                                v-model="$store.state.map.map_mode"
                                :disabled="isLoading"
                                filterable
                                placeholder="Select map mode">
                            <el-option
                                    v-for="(item) in $store.state.map.map_views"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                {{ item.label }}
                            </el-option>
                        </el-select>
                    </floating-label-input>
                </div>
                <div class="w-40 p-2">

                    <floating-label-input label="Search By">
                        <el-select
                                v-model="$store.state.map.search_mode"
                                filterable
                                :disabled="isLoading || isStreetView"
                                placeholder="Select search">
                            <el-option
                                    v-for="(item) in $store.state.map.search_modes"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                {{ item.label }}
                            </el-option>
                        </el-select>
                    </floating-label-input>


                </div>
                <div class="p-2 flex-grow w-auto">

                    <el-select
                            v-if="$store.state.map.search_mode === 'company'"
                            class="w-full"
                            v-model="search.company_name.value"
                            filterable
                            :disabled="isLoading || isStreetView"
                            remote
                            placeholder="Please enter a company..."
                            @change="onCompanySelected"
                            :remote-method="companySearch"
                            :loading="search.company_name.loading">
                        <el-option
                                class="el-option-select--company"
                                v-for="(item) in search.company_name.options"
                                :key="item.hash"
                                :label="item.name"
                                :value="item.hash">
                            <span class="max-w-md font-semibold block truncate">{{ item.name }}</span>
                            <div class="flex text-xs">
                                <span>{{ item.category }}</span>
                                <span class="ml-auto">{{ item.country }}</span>
                            </div>
                        </el-option>
                    </el-select>
                    <el-input
                            :disabled="isLoading || isStreetView"
                            :loading="isLoading"
                            placeholder="Search for a title number..."
                            v-model="search.title_number.value"
                            v-on:keyup.enter="fetchTitleNumberPolygons"
                            v-if="$store.state.map.search_mode === 'title_number'"
                    />


                    <vue-google-autocomplete
                            v-if="$store.state.map.search_mode === 'location'"
                            class="p-2 rounded px-4 w-full bg-white text-sm border-gray-400 border h-12"
                            id="google-autocomplete"
                            ref="search-google-autocomplete"
                            :enable-geolocation="true"
                            :disabled="isLoading || isStreetView"
                            :placeholder="!map.loading_location ?  map.location : 'Loading location...'"
                            :country="['uk']"
                            types="geocode"
                            v-on:placechanged="onLocationSearchChange"
                    />

                </div>
                <div
                        v-if="$store.state.map.search_mode === 'title_number'"
                        class="w-32 p-2">
                    <el-button
                            @click="fetchTitleNumberPolygons"
                            type="success" icon="el-icon-search" class="w-full h-12">Search</el-button>
                </div>
                <div class="p-2">
                    <el-button
                            :disabled="isStreetView || $store.state.map.custom_draw.visible"
                            @click="modal_data.workflow.show = true"
                            type="primary"
                            class="el-action--launch-workflows h-12">
                        <div class="flex">
                            <svg class="mr-1 w-3 h-3"
                                 fill="#fff"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path d="M14 16.825l.007.008c0-5.326 5.459-13.114 9.008-16.833h-4.39c-1.534 1.458-3.225 3.646-4.625 6v-6h-3.993v6c-1.4-2.354-3.067-4.542-4.602-6h-4.39c3.549 3.718 8.985 11.506 8.985 16.833l.007-.008v1.175h-3.007l5 6 5-6h-3v-1.175z"></path>
                            </svg>
                            <span>Workflows {{ $store.state.auth.account.loading }}</span>
                        </div>
                    </el-button>
                </div>
                <div class="p-2">
                    <el-button
                            v-if="!$store.state.map.custom_draw.visible"
                            class="h-12"
                            @click="$store.state.team.lookups.visible = true"
                            type="info">
                        <div class="flex">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 class="mr-1 w-4 h-4"
                                 fill="#fff"
                                 viewBox="0 0 24 24"><path d="M24 12c0 6.627-5.373 12-12 12s-12-5.373-12-12h2c0 5.514 4.486 10 10 10s10-4.486 10-10-4.486-10-10-10c-2.777 0-5.287 1.141-7.099 2.977l2.061 2.061-6.962 1.354 1.305-7.013 2.179 2.18c2.172-2.196 5.182-3.559 8.516-3.559 6.627 0 12 5.373 12 12zm-13-6v8h7v-2h-5v-6h-2z"/></svg>
                            <span>Lookup History</span>
                        </div>
                    </el-button>

                    <el-button
                            v-if="$store.state.map.custom_draw.visible"
                            class="h-12"
                            @click="$store.state.team.customAreas.visible = true"
                            type="info">
                        <div class="flex">
                            <svg  class="mr-1 w-4 h-4" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect stroke="#FFFFFF" stroke-width="3" transform="translate(20.000000, 20.000000) rotate(-315.000000) translate(-20.000000, -20.000000) " x="6.5" y="6.5" width="27" height="27" rx="5"></rect>
                                </g>
                            </svg>
                            <span>Saved Custom Draws</span>
                        </div>
                    </el-button>
                </div>
            </div>
            <div
                    id="el-block--site-source"
                    :class="{'is-open': sidebarOpen}"
                    element-loading-background="rgba(255, 255, 255, 0.9)"
                    v-loading="isLoading">



                <l-map
                        class="el-block--ownership-map"
                        ref="map"
                        :center="$store.state.auth.user.location"
                        :zoom="$store.state.auth.user.zoom"
                        :max-zoom="18"
                        :min-zoom="8"
                        @ready="onMapReady"
                        @update:center="(e) => { $store.state.auth.user.location = e;  }"
                        @update:zoom="(zoom)=>{ $store.state.auth.user.zoom = zoom;   }"
                        @update:bounds="(bounds)=>{ $store.state.map.bounds = bounds;   }"
                        :options="{editable: true, drawControl: false, zoomControl: false, attributionControl: false}"
                >
                    <l-draw-toolbar
                            v-if="$store.state.map.custom_draw.visible"
                            @draw:created="onDrawCreated"
                            @draw:edited="onDrawEdited"
                            @draw:deleted="onDrawDeleted"
                            @draw:click="onDrawClick"
                            @size-limit-reached="onDrawLimitReached"
                            position="topright" />

                    <l-control-zoom v-if="!isStreetView" position="bottomright"></l-control-zoom>


                    <tile-manager
                            ref="tileManager"
                            v-model="$store.state.map.map_mode"
                            @uprn="(uprn) => fetchPolygonFromUPRN(uprn)"
                            @fetch="(inspireId)=> { fetchOwnershipModal({
                inspireId
                }) }"
                            @planning-open="(data)=>{
                        modal_data.planning.data = data;
                         modal_data.planning.show = true;
                        }"
                    />

                </l-map>

                <!---  Map Overlays --->
                <menu-sidebar-layer
                        :show-close="!isLoading"
                        @close="()=>{ overlays.open = false }"
                        :show="overlays.open && !isStreetView">
                    <div class="h-full overflow-scroll">
                        <div class="bg-blue-900 p-6">
                            <h4 class="font-semibold overflow-hidden text-white">Map Overlays</h4>
                            <p class="text-xs text-white">Add overlays to the map in order to view more information abut the local area.</p>
                        </div>
                        <div class="p-4">
                            <el-collapse v-model="overlays.active">
                                <el-collapse-item

                                        :key="j"
                                        v-for="(type, j) of $store.getters['map/getGroupedOverlays']"
                                        :title="type.title" :name="j">

                                    <div class="flex -mr-2 flex-wrap">
                                        <div
                                                :key="i"
                                                v-for="(item, i) of type.items"
                                                class="w-1/2 p-2">
                                            <label
                                                    @click="$store.dispatch('map/toggleOverlayAvailability', item.value)"
                                                    :class="{
                                        'bg-gray-900 text-white': $store.getters['map/hasActiveOverlay'](item.value),
                                        'text-gray-900': !$store.getters['map/hasActiveOverlay'](item.value)
                                        }"
                                                    class="relative block select-none rounded-lg border border-gray-300 bg-white shadow-sm p-2 cursor-pointer hover:border-gray-400 flex">
                                                <div class="w-1/3">
                                                    <img
                                                            v-if="item.img"
                                                            :src="'/img/overlays/overlay-' + item.img + '.png'"
                                                            class="w-16 h-16 rounded"/>
                                                </div>
                                                <div class="flex w-2/3 ml-4 leading-tight items-center">
                                                    <p class="font-medium text-sm">
                                                        {{ item.title }}
                                                    </p>
                                                </div>
                                            </label>
                                        </div>

                                    </div>


                                </el-collapse-item>
                            </el-collapse>
                        </div>
                    </div>
                </menu-sidebar-layer>


                <!---  Ownership Data --->
                <menu-sidebar-layer
                        :show-close="!isLoading"
                        @close="()=>{ active_ownership_open = false }"
                        :show="active_ownership_open">
                    <ownership-loading
                            class="mx-12 px-12"
                            v-if="modal_data.resource.loading"

                    />
                    <ownership-layer-details
                            ref="ownershipLayerDetails"
                            @uprn="(uprn) => {
                        fetchPolygonFromUPRN(uprn)
                        }"
                            v-if="!modal_data.resource.loading"
                            v-model="modal_data.resource.data"
                            @close="()=>{ active_ownership_open = false }"
                            @show-workflow="()=>{ modal_data.workflow.show = true }"
                            @show-3d-buildings="(data)=>{
                            modal_data.buildings.show = true;
                                                        modal_data.buildings.data = data;

                             }"
                            @share="(titleNumber)=>{
                            modal_data.share_title_number.title = titleNumber;
                            modal_data.share_title_number.show = true;
                             }"
                            @snap="snapActiveToMap"
                            @compare="(usrn)=> {
                        modal_data.neighbour_compare.show = true;
                        modal_data.neighbour_compare.usrn = usrn;
                        }"
                            @open-corporate-ownership="launchCompanyModal"
                    />
                </menu-sidebar-layer>

                
                <!--- Custom Draw Data --->
                <menu-sidebar-layer
                        :show-close="!isLoading"
                        @close="()=>{ active_custom_draw_open = false }"
                        :show="active_custom_draw_open">
                    <ownership-loading
                            text="Fetching Custom Draw Details..."
                            class="mx-12 px-12"
                            v-if="modal_data.resource.loading"
                    />

                    <custom-draw-layer-details
                            ref="customDrawLayerDetails"
                            v-if="!modal_data.resource.loading"
                            :custom-polygon="custom_polygon"
                            v-model="custom_polygon_data"
                            @close="()=>{ active_custom_draw_open = false }"
                            @show-workflow="()=>{ modal_data.workflow.show = true }"
                            @share="(titleNumber)=>{
                            modal_data.share_title_number.title = titleNumber;
                            modal_data.share_title_number.show = true;
                             }"
                            @snap="snapActiveToMap"
                            />


                </menu-sidebar-layer>
                
                

                <!---  Data Insights --->
                <menu-sidebar-layer
                        :show-close="!isLoading"
                        @close="()=>{ $store.state.map.show_metrics = false; }"
                        :show="$store.state.map.show_metrics"
                >

                    <div class="bg-blue-900 p-6">
                        <h4 class="font-semibold overflow-hidden text-white">Data Insights</h4>
                        <p class="text-xs text-white">Use Data Insights to drill-down to find out more comprehensive data about the local area.</p>
                    </div>
                    <div class="p-4">

                        <div class="flex -mr-2 flex-wrap">
                            <div
                                    @click="()=>{ $store.state.map.active_data_metric = item.value; $store.state.map.show_metrics = false; }"
                                    :key="i"
                                    v-for="(item, i) of data_metrics.metrics"
                                    class="w-1/2 p-2">
                                <label
                                        class="relative block select-none rounded-lg border border-gray-300 bg-white shadow-sm p-2 cursor-pointer hover:border-gray-400 flex hover:bg-blue-900 hover:text-white">
                                    <div class="w-1/3">
                                        <img
                                                v-if="item.img"
                                                :src="'/img/overlays/overlay-' + item.img + '.png'" class="w-16 h-16 rounded"/>
                                    </div>
                                    <div class="flex w-2/3 ml-4 leading-tight items-center">
                                        <p class="font-medium text-sm">
                                            {{ item.title }}
                                        </p>
                                    </div>
                                </label>
                            </div>

                        </div>
                    </div>


                </menu-sidebar-layer>


                <menu-sidebar-layer
                        :show-close="!isLoading"
                        @close="()=>{ $store.state.map.active_data_metric = null; }"
                        position="right"
                        :show-overlay="false"
                        :show-collapse="true"
                        :show="$store.state.map.active_data_metric !== null "
                >

                    <corporate-ownership-data-layer
                            v-if="$store.state.map.active_data_metric === 'company-ownership'"
                            @company="launchCompanyModal"
                            @view="(titleNumber)=>{
                modal_data.company.show = false;
                search.title_number.value  = titleNumber;
                fetchTitleNumberPolygons();
                 }"
                    />

                    <commercial-availability-data-layer
                            v-if="$store.state.map.active_data_metric === 'commercial'"
                            @lat-lon="({lat, lon})=>{
                     setLocation({
                     lat: lat,
                     lng: lon
                     })
                         }"
                    />

                    <sale-prices-data-layer
                            @uprn="(uprn) => {
                        fetchPolygonFromUPRN(uprn)
                        }"
                            v-if="$store.state.map.active_data_metric === 'sale-prices'"/>


                   <leaseholder-data-layer
                            @uprn="(uprn) => {
                        fetchPolygonFromUPRN(uprn)
                        }"
                            v-if="$store.state.map.active_data_metric === 'leaseholders'"
                    />

                    <epc-data-layer
                            @uprn="(uprn) => {
                        fetchPolygonFromUPRN(uprn)
                        }"
                            v-if="$store.state.map.active_data_metric === 'epcs'"
                    />

                    <known-marketed-sales-data-layer
                            @uprn="(uprn) => {
                        fetchPolygonFromUPRN(uprn)
                        }"
                            v-if="$store.state.map.active_data_metric === 'known-marketed-sales'"
                    />

                    <hmo-data-layer
                            @uprn="(uprn) => {
                        fetchPolygonFromUPRN(uprn)
                        }"
                            v-if="$store.state.map.active_data_metric === 'hmos'"
                    />

                    <current-occupier-data-layer
                            @uprn="(uprn) => {
                        fetchPolygonFromUPRN(uprn)
                        }"
                            v-if="$store.state.map.active_data_metric === 'occupiers'"
                    />

                    <planning-data-layer
                            @uprn="(uprn) => {
                        fetchPolygonFromUPRN(uprn)
                        }"
                            @open-planning="(data)=>{
                        modal_data.planning.data = data;
                         modal_data.planning.show = true;
                        }"
                            v-if="$store.state.map.active_data_metric === 'planning'"
                    />

                </menu-sidebar-layer>


                <!--- Street View --->
                <street-view-layer
                        v-if="$store.state.map.map_mode === 'street'"
                        class="el-block--master-map el-block--master-map-street-view"
                        :location="$store.state.auth.user.location"
                        @change="(val) => $store.state.auth.user.location = val"
                />



                <div class="el-block--filter absolute flex items-start ml-10 mt-6 left-0 top-0" v-if="!isStreetView">
                    <ownership-filter
                            @refresh="()=>{ $refs.tileManager.refreshOwnershipLayers();  }"
                    />
                </div>


                <div class="el-block--filter el-block--layers absolute items-start mt-6 left-0 top-0" v-if="!isStreetView">


                    <div class="rounded bg-white align-start w-48 overflow-hidden shadow">
                        <h4 class="text-white text-sm p-2 border border-blue-900 bg-blue-800 font-semibold p-1">Map Overlays</h4>

                        <draggable v-if="!$store.state.map.custom_draw.visible" class="el-filter--overlays-list" v-model="$store.state.map.active_overlays"
                                   handle=".handle">
                            <transition-group>
                                <div
                                        class="p-1 px-2 border-b border-gray-900 flex  items-start  bg-gray-800 text-white text-xs"
                                        v-for="(o, i) in $store.state.map.active_overlays" :key="'workflow-column-' + i">

                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         class="w-3 h-3 mt-1 text-white handle cursor-pointer mr-1" fill="currentColor"
                                         viewBox="0 0 24 24">
                                        <path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z"/>
                                    </svg>


                                    <span class="w-auto flex-grow pl-2 select-none"> {{ $store.getters['map/getActiveOverlayDetails'](o).title }}</span>
                                    <span class="pl-2 cursor-pointer"
                                          @click="$store.commit('map/toggleLayerVisibility', $store.getters['map/getActiveOverlayDetails'](o).value)">
                                              <svg v-if="!$store.getters['map/isOverlayVisible'](o)"
                                                   class="w-3 h-3 text-white" fill="currentColor"
                                                   xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path
                                                      d="M19.604 2.562l-3.346 3.137c-1.27-.428-2.686-.699-4.243-.699-7.569 0-12.015 6.551-12.015 6.551s1.928 2.951 5.146 5.138l-2.911 2.909 1.414 1.414 17.37-17.035-1.415-1.415zm-6.016 5.779c-3.288-1.453-6.681 1.908-5.265 5.206l-1.726 1.707c-1.814-1.16-3.225-2.65-4.06-3.66 1.493-1.648 4.817-4.594 9.478-4.594.927 0 1.796.119 2.61.315l-1.037 1.026zm-2.883 7.431l5.09-4.993c1.017 3.111-2.003 6.067-5.09 4.993zm13.295-4.221s-4.252 7.449-11.985 7.449c-1.379 0-2.662-.291-3.851-.737l1.614-1.583c.715.193 1.458.32 2.237.32 4.791 0 8.104-3.527 9.504-5.364-.729-.822-1.956-1.99-3.587-2.952l1.489-1.46c2.982 1.9 4.579 4.327 4.579 4.327z"/></svg>
                    <svg v-if="$store.getters['map/isOverlayVisible'](o)" class="w-3 h-3 text-white" fill="currentColor"
                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path
                            d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 3c-2.209 0-4 1.792-4 4 0 2.209 1.791 4 4 4s4-1.791 4-4c0-2.208-1.791-4-4-4z"/></svg>

                                </span>
                                    <span
                                            @click="$store.dispatch('map/toggleOverlayAvailability', $store.getters['map/getActiveOverlayDetails'](o).value)"
                                            v-if="$store.getters['map/getActiveOverlayDetails'](o).closeable"
                                            class="pl-2 cursor-pointer">
                                    <i class="el-icon-close"></i>
                                </span>


                                </div>
                            </transition-group>
                        </draggable>

                        <div
                                v-if="!$store.state.map.custom_draw.visible"
                                class="p-2 pb-0 bg-gray-800">
                            <el-button
                                    @click="()=>{ overlays.open = true  }"
                                    type="success"
                                    size="small"
                                    class="w-full">

                                <div class="flex items-center justify-center">
                                    <svg class="mr-2 w-3 h-3" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 24 24" fill="#fff">
                                        <path d="M21.698 10.658l2.302 1.342-12.002 7-11.998-7 2.301-1.342 9.697 5.658 9.7-5.658zm-9.7 10.657l-9.697-5.658-2.301 1.343 11.998 7 12.002-7-2.302-1.342-9.7 5.657zm0-19l8.032 4.685-8.032 4.685-8.029-4.685 8.029-4.685zm0-2.315l-11.998 7 11.998 7 12.002-7-12.002-7z"/>
                                    </svg>
                                    <span>Map Overlays</span>
                                </div>


                            </el-button>
                        </div>

                        <div class="p-2 bg-gray-800">
                            <el-button
                                    @click="()=>{
                                     $store.state.map.custom_draw.visible = !$store.state.map.custom_draw.visible;
                                     if($store.state.map.custom_draw.visible) {
                                       $store.state.map.active_data_metric = null;
                                     }
                                       }"
                                    :type="$store.state.map.custom_draw.visible ? 'danger' : 'info'"
                                    size="small"
                                    class="w-full">

                                <div class="flex items-center justify-center">
                                    <svg class="mr-2 w-3 h-3" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 24 24" fill="#fff">
                                        <path d="M1.438 16.873l-1.438 7.127 7.127-1.437 16.874-16.872-5.69-5.69-16.873 16.872zm1.12 4.572l.722-3.584 2.86 2.861-3.582.723zm18.613-15.755l-13.617 13.617-2.86-2.861 13.617-13.617 2.86 2.861z"/></svg>
                                    <span>{{ $store.state.map.custom_draw.visible ? 'Close' : 'Open' }} Draw Mode</span>
                                </div>


                            </el-button>
                        </div>


                    </div>

                    <overlay-details
                            v-if="!$store.state.map.custom_draw.visible"
                            class="mt-4" @open="()=>{ overlays.open = true  }" />


                </div>

                <div
                        @click="active_ownership_open = true"
                        v-if="$store.state.map.active_polygon_id !== null && !isStreetView && !$store.state.map.custom_draw.visible"
                        class="el--block--show-info font-medium p-2 px-4 bg-green-600 text-white rounded-b-lg transform hover:bg-green-700 shadow">
                    <i class="el-icon-info"></i> View Details
                </div>


                <!-- END HERE --->


            </div>
        </div>

        <div class="w-full flex items-center justify-center" v-if="hasOutstandingAccountAction">

            <div class="w-full max-w-2xl my-12">
                   <account-email-verification v-if="!$store.getters['auth/emailVerified']" />
                <account-no-active-subscription v-else-if="!$store.getters['auth/hasSubscription']" />
                <account-set-initial-location v-else-if="!$store.getters['auth/hasSetInitialLocation']" />
            </div>

        </div>

        <!-- Share Title Number --->
        <el-dialog
                title="Share Title Number"
                :visible.sync="modal_data.share_title_number.show"
                width="30%">
            <div class="columns">

                <div class="column w-full">

                    <div class="w-full flex">
                        <input
                                class="p-3 border border-gray-400 w-full rounded mr-2"
                                :value="getTitleNumberShareUrl"/>
                        <el-button
                                @click="copyTitleNumberShareLink"
                                class="info" type="success">Copy
                        </el-button>
                    </div>


                </div>
                <div class="column w-1/4">
                    <ShareNetwork
                            network="facebook"
                            :url="getTitleNumberShareUrl"
                            :title="'Title Number ' + modal_data.share_title_number.title + ' on Land Monitor'"
                    >
                        <el-button class="w-full" type="info">Facebook</el-button>
                    </ShareNetwork>

                </div>
                <div class="column w-1/4">

                    <ShareNetwork
                            network="twitter"
                            :url="getTitleNumberShareUrl"
                            :title="'Title Number ' + modal_data.share_title_number.title + ' on Land Monitor'"
                    >
                        <el-button class="w-full" type="info">Twitter</el-button>
                    </ShareNetwork>

                </div>
                <div class="column w-1/4">

                    <ShareNetwork
                            network="LinkedIn"
                            :url="getTitleNumberShareUrl"
                            :title="'Title Number ' + modal_data.share_title_number.title + ' on Land Monitor'"
                    >
                        <el-button class="w-full" type="info">LinkedIn</el-button>
                    </ShareNetwork>

                </div>
                <div class="column w-1/4">

                    <ShareNetwork
                            network="email"
                            :url="getTitleNumberShareUrl"
                            :title="'Title Number ' + modal_data.share_title_number.title + ' on Land Monitor'"
                    >
                        <el-button class="w-full" type="info">Email</el-button>
                    </ShareNetwork>
                </div>
            </div>
        </el-dialog>

        <!-- Share Custom Draw --->
        <el-dialog
                title="Share Custom Draw Area"
                :visible.sync="modal_data.share_custom_polygon.show"
                width="30%">
            <div class="columns">

                <div class="column w-full">

                    <div class="w-full flex">
                        <input
                                class="p-3 border border-gray-400 w-full rounded mr-2"
                                :value="getPolygonShareUrl"/>
                        <el-button
                                @click="copyLink(getPolygonShareUrl)"
                                class="info" type="success">Copy
                        </el-button>
                    </div>


                </div>
                <div class="column w-1/4">
                    <ShareNetwork
                            network="facebook"
                            :url="getPolygonShareUrl"
                            :title="'My Custom Area on Land Monitor'"
                    >
                        <el-button class="w-full" type="info">Facebook</el-button>
                    </ShareNetwork>

                </div>
                <div class="column w-1/4">

                    <ShareNetwork
                            network="twitter"
                            :url="getPolygonShareUrl"
                            :title="'My Custom Area on Land Monitor'"
                    >
                        <el-button class="w-full" type="info">Twitter</el-button>
                    </ShareNetwork>

                </div>
                <div class="column w-1/4">

                    <ShareNetwork
                            network="LinkedIn"
                            :url="getPolygonShareUrl"
                            :title="'My Custom Area on Land Monitor'"
                    >
                        <el-button class="w-full" type="info">LinkedIn</el-button>
                    </ShareNetwork>

                </div>
                <div class="column w-1/4">

                    <ShareNetwork
                            network="email"
                            :url="getPolygonShareUrl"
                            :title="'My Custom Area on Land Monitor'"
                    >
                        <el-button class="w-full" type="info">Email</el-button>
                    </ShareNetwork>
                </div>
            </div>
        </el-dialog>



    </page>

</template>

<script>
    import L from "leaflet";
    import copy from 'copy-to-clipboard';


    window.L = L;

    import MenuSidebarLayer from "../components/LandMonitor/MenuSidebarLayer";
    import OwnershipLayerDetails from "../components/LandMonitor/OwnershipLayerDetails";
    import StreetViewLayer from "../components/LandMonitor/StreetViewLayer";
    import OwnershipLoading from "../components/LandMonitor/OwnershipLayerComponents/OwnershipLoading";

    import 'shepherd.js/dist/css/shepherd.css';
    import PropertyLoader from "../components/incs/PropertyLoader";
    import EmptyState from "../components/EmptyState";
    import WorkflowKanbanBoard from "../components/Workflow/WorkflowKanbanBoard";

    import OverlayDetails from "../components/LandMonitor/OverlayDetails";
    import CorporateOwnershipDataLayer from "../components/LandMonitor/DataOverlays/CorporateOwnershipDataLayer";
    import SalePricesDataLayer from "../components/LandMonitor/DataOverlays/SalePricesDataLayer";
    import OwnershipFilter from "../components/LandMonitor/OwnershipFilter";
    import TileManager from "../components/LandMonitor/TileManager";
    import LeaseholderDataLayer from "../components/LandMonitor/DataOverlays/LeaseholderDataLayer";
    import PlanningDataLayer from "../components/LandMonitor/DataOverlays/PlanningDataLayer";
    import EpcDataLayer from "../components/LandMonitor/DataOverlays/EpcDataLayer";
    import CommercialAvailabilityDataLayer from "../components/LandMonitor/DataOverlays/CommercialAvailabilityDataLayer";
    import CorporateOwnershipModal from "../components/LandMonitor/CorporateOwnershipModal";
    import NeighbourCompareModal from "../components/LandMonitor/NeighbourCompareModal";
    import CurrentOccupierDataLayer from "../components/LandMonitor/DataOverlays/CurrentOccupierDataLayer";
    import KnownMarketedSalesDataLayer from "../components/LandMonitor/DataOverlays/KnownMarketedSalesDataLayer";
    import Account from "../components/Account/Account";
    import AccountEmailVerification from "../components/Account/AccountEmailVerification";
    import AccountNoActiveSubscription from "../components/Account/AccountNoActiveSubscription";
    import Team from "../components/Team/Team";
    import SwitchTeam from "../components/Account/SwitchTeam";
    import AccountSetInitialLocation from "../components/Account/AccountSetInitialLocation";
    import LookupHistory from "../components/LookupHistory";
    import HmoDataLayer from "../components/LandMonitor/DataOverlays/HmoDataLayer";
    import CustomDrawLayerDetails from "../components/LandMonitor/CustomDrawLayerDetails";
    import CustomAreas from "../components/CustomAreas";

    const Tutorial = require('../utils/tutorial');

    export default {
        name: 'LandMonitor',
        components: {
            CustomAreas,
            CustomDrawLayerDetails,
            HmoDataLayer,
            LookupHistory,
            AccountSetInitialLocation,
            SwitchTeam,
            Team,
            AccountNoActiveSubscription,
            AccountEmailVerification,
            Account,
            KnownMarketedSalesDataLayer,
            CurrentOccupierDataLayer,
            NeighbourCompareModal,
            CorporateOwnershipModal,
            CommercialAvailabilityDataLayer,
            EpcDataLayer,
            PlanningDataLayer,
            LeaseholderDataLayer,
            TileManager,
            OwnershipFilter,
            SalePricesDataLayer,
            CorporateOwnershipDataLayer,
            OverlayDetails,
            WorkflowKanbanBoard,
            EmptyState,
            PropertyLoader,
            OwnershipLoading,
            StreetViewLayer,
            OwnershipLayerDetails,
            MenuSidebarLayer
        },
        data() {
            return {
                num: 10,
                layersVisible: true,
                active_ownership_open: false,
                active_custom_draw_open: false,
                custom_polygon: {},
                custom_polygon_data: {},
                map: {
                    loading_location: true,
                    location: null
                },
                search: {
                    address: {
                        value: '',
                        loading: false,
                        options: []
                    },
                    company_name: {
                        value: '',
                        loading: false,
                        options: []
                    },
                    title_number: {
                        value: '',
                        loading: false
                    }
                },
                filter: {
                    show: false
                },
                searches: {
                    loading: false,
                    selected: null,
                    searches: []
                },
                modal_data: {
                    neighbour_compare: {
                      show: false,
                      usrn: null
                    },
                    save_search: {
                        show: false,
                        running: false,
                        model: {
                            id: null,
                            name: null
                        }
                    },
                    buildings: {
                        show: false,
                        data: {}
                    },
                    custom_data: {
                        show: false,
                        loading: false,
                        data: {},
                        sales_object: [],
                        active_tab: 'info',
                        loading_property_sales: true,
                        active_property_sales_id: null
                    },
                    resource: {
                        show: false,
                        loading: false,
                        data: {},
                        sales_object: [],
                        active_tab: 'info',
                        loading_property_sales: true,
                        active_property_sales_id: null
                    },
                    share_title_number: {
                        show: false,
                        title: null
                    },
                    share_custom_polygon: {
                        show: false,
                        polygon: null
                    },
                    workflow: {
                        loading_workflows: true,
                        active_workflow: null,
                        workflows: [],
                        show: false,
                        data: {},
                    },
                    company: {
                        loading: false,
                        show: false,
                        data: {},
                        object: {}
                    },
                    planning: {
                        show: false,
                        data: {},
                    },
                    listed_buildings: {
                        show: false,
                        data: {},
                        active_tab: 'info'
                    }
                },

                loading_layer: false,

                applications: [],
                sales: [],
                prices: [],
                active_layer: null,
                data_metrics: {
                    active: null,
                    metrics: [
                        {title: 'Company Ownership', value: 'company-ownership', img: 'company-ownership'},
                        {title: 'Sale Prices', value: 'sale-prices', img: 'sale-prices'},
                        {title: 'Planning History', value: 'planning', img: 'planning'},
                        {title: 'Houses of Multiple Occupation (HMOs)', value: 'hmos', img: 'planning'},
                        {title: 'EPC Ratings', value: 'epcs', img: 'epcs'},
                        {title: 'Current Occupiers', value: 'occupiers', img: 'current-occupiers'},
                        {title: 'Known Marketed Sales', value: 'known-marketed-sales', img: 'known-marketed-sales'},
                    ]
                },
                overlays: {
                    active: [0],
                    open: false
                },
                tiles: {}
            }
        },
        beforeRouteEnter(to, from, next) {

            next((vm) => {
                vm.$store.commit('auth/setBreadcrumbs', [
                    {
                        title: 'Land Monitor'
                    }
                ]);
            })
        },
        computed: {
            hasOutstandingAccountAction(){
                return !this.$store.getters['auth/emailVerified']
                    || !this.$store.getters['auth/hasSubscription']
                    || !this.$store.getters['auth/hasSetInitialLocation'];
            },
            getTitleNumberShareUrl() {
                return 'https://app.landmonitor.com' + this.$route.path + '?title-number=' + this.modal_data.share_title_number.title;
            },
            getPolygonShareUrl() {
                return 'https://app.landmonitor.com' + this.$route.path + '?polygon=' + JSON.stringify(this.modal_data.share_custom_polygon.polygon);
            },
            isStreetView() {
                return this.$store.state.map.map_mode === 'street';
            },
            isLoading() {
                return this.$store.state.map.loading_polygons || this.modal_data.resource.loading;
            },
            sidebarOpen() {
                return this.$store.state.map.show_filters || this.active_ownership_open;
            }
        },

        watch: {
            'modal_data.workflow.show'() {
                const vm = this;
                vm.fetchWorkflows();
            },
            active_ownership_open() {
                if (!this.active_ownership_open) {
                    this.$store.commit('auth/setBreadcrumbs', [
                        {
                            title: 'Land Monitor'
                        }
                    ]);
                }
            },
            '$store.state.auth.user.location'() {
                const vm = this;
                console.log('Change')
                if (vm.$store.state.map.map_mode === 'street') {
                    return false;
                }
                const location = Object.assign(vm.$store.state.auth.user.location, {
                    zoom: vm.$store.state.auth.user.zoom
                });

                console.log(location);
                vm.$store.commit('auth/setUserLocation', location)
                vm.refreshLocation();
            }
        },
        created() {
            const vm = this;
            vm.$store.state.map.active_polygon_id = null;

            /*
            if (!vm.$store.getters['auth/hasSkippedTutorial']) {
                vm.$confirm(
                    'Would you like to start the Land Monitor tutorial? It only takes a few minutes and will show you some of the best features and functionality we have to offer.',
                    'Start Land Monitor Tutorial?',
                    {
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'Cancel',
                        closeOnClickModal: false,
                        closeOnPressEscape: false
                    })
                    .then(() => {
                        vm.skipTutorial();
                        vm.startTutorial();
                    })
                    .catch(() => {
                        vm.skipTutorial();
                        vm.init();
                    })
            } else {
                vm.init();
            }

             */
            this.init();
        },

        methods: {
            onDrawCreated(object){

                if(!object.is_valid){
                    this.onDrawLimitReached();
                    return;
                }
                const vm = this;
                vm.$confirm(
                    'Your custom draw area was created successfully. Would would you like to view data for the area now?',
                    'Custom Area Created',
                    {
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No'
                    })
                    .then(() => {
                        vm.fetchCustomDrawOverview(object.geoJSON.geometry)
                    }).catch(()=>{

                })

            },
            onDrawEdited(){
                const vm = this;
                vm.$confirm(
                    'Your custom draw area was updated successfully. Would would you like to view data for the area now?',
                    'Custom Area Updated',
                    {
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No'
                    })
                    .then(() => {

                    }).catch(()=>{

                })
            },
            onDrawDeleted(){
                this.$notify({
                    type: 'success',
                    title: 'Custom Area\'s Deleted',
                    message: 'You custom draw area\'s were deleted successfully. Click for latest details.',
                    position: 'top-right',
                    customClass: 'mr-8',
                    offset: 140,
                });
            },
            onDrawClick(object){
                const vm = this;
                if(!object.is_valid){
                    this.onDrawLimitReached();
                    return;
                }
                vm.fetchCustomDrawOverview(object.geoJSON.geometry)
            },
            onDrawLimitReached(){
                this.$notify({
                    type: 'warning',
                    title: 'Draw Area too Large',
                    message: 'Your custom draw area can not exceed a 250m radius. Please draw something smaller.',
                    position: 'top-right',
                    customClass: 'mr-8',
                    offset: 140,
                });
            },
            fetchPolygonFromUPRN(uprn){
                const vm = this;
                vm.modal_data.resource.loading = true;
              return this.$store.dispatch('map/fetchPolygonFromUPRN', uprn).then((response)=>{
                let data = response.data.data;

                if(data.bbox){
                    this.snapActiveToMap(data.bbox)
                }
                if(data.inspire_id){
                    vm.modal_data.resource.loading = false;
                    this.fetchOwnershipDetails(data.inspire_id);
                    this.$store.state.map.active_polygon_id = data.inspire_id;
                    this.active_ownership_open = true;
                }

              }).catch(()=>{
                  vm.modal_data.resource.loading = false;

              })
            },
            onMapReady() {
                this.$store.state.map.bounds = this.$refs.map.mapObject.getBounds();
            },
            handleWorkflowView(object) {
                const vm = this;
                vm.modal_data.workflow.show = false;
                vm.search.title_number.value = object.titleNumber;
                vm.fetchTitleNumberPolygons()
            },
            onWorkflowChange() {
                const vm = this;
                vm.modal_data.workflow.loading_workflows = true;
                setTimeout(() => {
                    vm.modal_data.workflow.loading_workflows = false;
                }, 1);
            },
            fetchWorkflows() {
                const vm = this;
                vm.modal_data.workflow.loading_workflows = true;

                return vm.$store.dispatch('workflow/index', {
                    params: {
                        all: true
                    }
                }).then((response) => {
                    vm.modal_data.workflow.loading_workflows = false;
                    vm.modal_data.workflow.workflows = response.data.data.results;
                }).catch(() => {
                    vm.modal_data.workflow.loading_workflows = false;
                    vm.modal_data.workflow.workflows = [];
                })

            },
            skipTutorial() {
                const vm = this;
                vm.$store.commit('auth/setUserObject', {
                    key: 'skip_tutorial',
                    value: true
                });
                vm.$store.dispatch('auth/skipTutorial');
            },
            init() {
                if (this.$route.query && this.$route.query['title-number']) {
                    this.search.title_number.value = this.$route.query['title-number'];
                    this.fetchTitleNumberPolygons();
                } else if (this.$route.query && this.$route.query['action'] && this.$route.query['action'] === 'coords' && this.$route.query['lat'] && this.$route.query['lng']) {

                  this.setLocation({
                      lat: parseFloat(this.$route.query['lat']),
                      lng: parseFloat(this.$route.query['lng'])
                  })

                } else if (this.$route.query && this.$route.query['polygon']) {
                   try {
                       const p = JSON.parse(this.$route.query['polygon']);
                       this.$store.state.team.customAreas.visible = false;
                       this.fetchCustomDrawOverview(p);
                   }catch (e) {
                       console.warn(e);
                   }
                }

                this.refreshLocation();

            },

            setLocation({lat, lng}){
                const vm = this;
                vm.$store.state.auth.user.location = {
                    lat: parseFloat(lat),
                    lng: parseFloat(lng)
                };

                vm.$store.state.auth.user.zoom = 18;

                vm.$store.commit('auth/setUserLocation', Object.assign(this.$store.state.auth.user.location, {
                    zoom: this.$store.state.auth.user.zoom
                }));

                setTimeout(() => {
                    vm.refreshLocation();
                }, 1000)
            },
            startTutorial() {
                this.$store.commit('map/resetFilter');
                const tutorial = new Tutorial(this);
                tutorial.init();
            },

            copyTitleNumberShareLink() {
                copy(this.getTitleNumberShareUrl);
            },
            copyLink(text) {
                copy(text);
            },
            openExternalURL(url) {
                window.open(url)
            },
            launchCompanyModal(hash) {
                const vm = this;
                vm.modal_data.company.loading = true;
                vm.modal_data.company.show = true;
                vm.modal_data.company.hash = hash;
            },

            snapActiveToMap(bbox) {
                if (bbox.length === 4) {
                    let polygonBounds = L.latLngBounds(
                        L.latLng(bbox[1], bbox[0]),
                        L.latLng(bbox[3], bbox[2])
                    );
                    this.$refs.map.mapObject.fitBounds(polygonBounds);
                }
            },

            onCompanySelected() {
                this.launchCompanyModal(this.search.company_name.value)
                this.search.company_name.value = null;
            },

            companySearch(query) {
                const vm = this;
                vm.search.company_name.loading = true;
                vm.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/company-ownership', {
                    params: {
                        q: query
                    }
                })
                    .then((response) => {
                        vm.search.company_name.loading = false;
                        vm.search.company_name.options = response.data.data;
                    })
                    .catch(() => {
                        vm.search.company_name.loading = false;
                        vm.search.company_name.options = [];
                    })
            },


            fetchOwnershipModal({inspireId}) {
                const vm = this;
                if (inspireId === this.$store.state.map.active_polygon_id) {
                    this.active_ownership_open = true;
                    return false;
                }

                vm.fetchOwnershipDetails(inspireId);
                vm.$store.state.map.active_polygon_id = inspireId;
                vm.active_ownership_open = true;
            },


            fetchCustomDrawOverview(polygon){
                const vm = this;
                vm.custom_polygon = polygon;
                vm.active_custom_draw_open = true;
                vm.modal_data.resource.loading = true;
                this.$http.post('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/custom-draw', {
                    polygon,
                    view: 'overview'
                })
                    .then((response)=>{
                        vm.modal_data.resource.loading = false;
                        vm.custom_polygon_data = response.data.data;
                    }).catch(()=>{
                    vm.active_custom_draw_open = false;
                    vm.modal_data.resource.loading = false;
                    vm.custom_polygon_data = {}
                })

            },

            fetchOwnershipDetails(inspireId) {
                const vm = this;
                vm.$store.state.map.active_polygon_id = inspireId;
                vm.modal_data.resource.loading = true;
                vm.modal_data.resource.data = {};
                return vm.$store.dispatch('map/fetch', {
                    inspireId: inspireId
                })
                    .then((response) => {
                        vm.modal_data.resource.data = response;
                        vm.modal_data.resource.loading = false;
                    })
                    .catch(() => {
                        vm.modal_data.resource.loading = false;
                    })
            },

            onLocationSearchChange(opts) {
                const vm = this;

                if (opts.latitude && opts.longitude) {

                    new Promise((resolve) => {
                        return resolve(vm.$refs.map.mapObject.setView(L.latLng(opts.latitude, opts.longitude)));
                    }).then(() => {
                        setTimeout(() => {
                            const el = vm.$refs['search-google-autocomplete'];
                            if (el && el.$el) {
                                el.clear();
                            }
                        }, 100)
                    })

                }
            },

            resetLayers() {
                const vm = this;
                vm.$store.state.map.polygons = [];
                vm.listed_buildings = [];
            },

            fetchTitleNumberPolygons() {
                const vm = this;

                vm.$store.state.map.active_polygon_id = null;
                vm.active_ownership_open = false;

                vm.$store.dispatch('map/fetchTitleNumberPolygons', vm.search.title_number.value)
                    .then((response) => {

                        const data = response.data.data;

                        let id = data.id ? data.id : null;
                        let bbox = data.bbox ? data.bbox : [];

                        if (bbox.length === 4 && bbox[0] !== null) {
                            vm.calculateMapBounds(bbox, true);
                        }

                        vm.fetchOwnershipDetails(id);
                        vm.active_ownership_open = true;

                        vm.$store.state.map.loading_polygons = false;

                    })
                    .catch(() => {
                        vm.$store.state.map.loading_polygons = false;
                        vm.$alert('We can\'t find any properties. Please Try refining your search criteria.', 'No results found', {
                            confirmButtonText: 'OK'
                        });
                    })
            },

            calculateMapBounds(bbox, alwaysFit) {
                const vm = this;
                alwaysFit = typeof alwaysFit === 'boolean' ? alwaysFit : false;

                let polygonBounds = L.latLngBounds(
                    L.latLng(bbox[1], bbox[0]),
                    L.latLng(bbox[3], bbox[2])
                );

                if (alwaysFit) {
                    vm.$refs.map.mapObject.fitBounds(polygonBounds);
                    return;
                }

                let currentBounds = vm.$refs.map.mapObject.getBounds();
                if (!polygonBounds.contains(currentBounds)) {
                    vm.$refs.map.mapObject.fitBounds(polygonBounds);
                }
            },


            refreshLocation() {
                const vm = this;
                vm.map.loading_location = true;

                vm.$store.dispatch('auth/refreshLocation')
                    .then((response) => {
                        vm.map.loading_location = false;
                        vm.map.location = response.data.data.location;
                    })
                    .catch(() => {
                        vm.map.loading_location = false;
                        vm.map.location = null;
                    })
            }
        }

    }
</script>
<style lang="scss">

    .el-dialog {
        max-height: calc(100vh - 150px) !important;
        overflow: hidden;
        .el-dialog__body {
            overflow: scroll;
            max-height: calc(100vh - 190px) !important;
        }
    }

    .el-notification {
        z-index: 15001 !important;
    }

    .leaflet-control-attribution {
        display: none !important;
    }

    .el-tab-pane--fixed {
        height: 470px;
        overflow-y: scroll;
        padding: 0 1rem;
    }


    .el-popover--map-details {
        text-align: left !important;
        word-break: normal !important;
    }

    .leaflet-bottom .leaflet-control {
        margin: 1rem !important;
    }

    .el-slider__stop {
        background-color: #23472c !important;
    }

    .el-loading-mask {
        z-index: 3000 !important;
    }

    .el-block--master-map-street-view {
        height: 100%;
        position: absolute !important;
        top: 0;
        left: 0;
        z-index: 2001;
        display: block;
        width: 100%;
    }

    .pac-container {
        z-index: 4000 !important;
    }

    .el-option-select--address {
        height: auto !important;
        line-height: 20px !important;
        padding: 0.5rem !important;
    }

    .el-option-select--company {
        height: auto !important;
        line-height: 20px !important;
        padding: 0.5rem !important;
    }

    .el-block--workflow-select {
        .el-select-dropdown__item {
            height: auto !important;
            line-height: 1rem;
            padding: 5px 10px;
        }
    }


    .el-block--buildings-gl {
        height: calc(100vh - 200px)
    }

    .tippy-box[data-theme~='ownership'] {
        background-color: #fff;
        color: #0c0c0c;
    }

    .tippy-box[data-theme~='ownership'] .tippy-content {
        padding: 0;
    }

    .tippy-box[data-theme~='ownership'][data-placement^='top'] > .tippy-arrow::before {
        border-top-color: #fff;
    }

    .tippy-box[data-theme~='ownership'][data-placement^='bottom'] > .tippy-arrow::before {
        border-bottom-color: #fff;
    }

    .tippy-box[data-theme~='ownership'][data-placement^='left'] > .tippy-arrow::before {
        border-left-color: #fff;
    }

    .tippy-box[data-theme~='ownership'][data-placement^='right'] > .tippy-arrow::before {
        border-right-color: #fff;
    }

    .el-filter--overlays-list {
        max-height: 260px;
        overflow: scroll;
    }

    .el-block--layers {
        margin-left: 18rem;
    }

</style>