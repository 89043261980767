<template>
    <div>

        <dashboard-widget
                padding-class=""
                title="Population & Dwellings">
            <slot>

                <el-table
                        striped
                        border
                        :data="value"
                        header-row-class-name="bg-blue-300"
                        style="width: 100%">
                    <el-table-column
                            prop="distance"
                            class-name="font-semibold"
                            label="Distance">
                        <template slot-scope="scope">
                            {{ scope.row.label }}
                        </template>
                    </el-table-column>
                    <el-table-column
                            width="100"
                            prop="population"
                            align="right"
                            label="Population">
                        <template slot-scope="scope">
                            {{ scope.row.population ? scope.row.population.toLocaleString() : 0 }}
                        </template>
                    </el-table-column>
                    <el-table-column
                            width="120"
                            prop="households"
                            align="right"
                            label="Dwellings">
                        <template slot-scope="scope">
                            {{ scope.row.dwellings ? scope.row.dwellings.toLocaleString() : 0 }}
                        </template>
                    </el-table-column>
                </el-table>
            </slot>
        </dashboard-widget>


    </div>
</template>

<script>

    export default {
        name: "OwnershipPopulationComponent",
        props: {
            value: {
                type: Array,
                default(){
                    return []
                }
            },
            loading: {
                type: Boolean,
                default(){
                    return true
                }
            }
        }
    }
</script>

<style scoped>

</style>