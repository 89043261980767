<template>
<div class="overflow-hidden"
     :class="{'rounded': isRounded}"
     v-if="maxBounds !== null">
    <l-map
            clas="el-block--preview-map"
            ref="preview-map"
            :style="{
            height: this.height + 'px'
            }"
            :options="{zoomControl: false}"
            @ready="onMapReady"
            :max-bounds="maxBounds"
            :center="getCoords"
    >




        <l-geo-json
                :key="'geojson_' + index"
                v-for="(polygon, index) of getPolygons"
                :options-style="()=>{ return {
                                                    color: polygon.properties && polygon.properties.color ? polygon.properties.color : '#ff002d',
                                                    weight: 1,
                                                    fillColor: polygon.properties && polygon.properties.color ? polygon.properties.color : '#ff002d'
                                                    } }"
                :geojson="polygon" />



        <l-marker
                :key="'marker_' + k"
                v-for="(icon, k) of getLeaseholderPoints"
                :lat-lng="icon">
            <l-icon
                    :icon-anchor="[0, 29]">
                <leaseholder-icon />
            </l-icon>
        </l-marker>


        <l-google-tile-layer
                ref="googleLayer"
                :apikey="$store.state.auth.map.google.key"
                :options="{type: 'hybrid'} "/>

        <slot></slot>

    </l-map>
</div>
</template>

<script>
    import L from 'leaflet';
    import LeaseholderIcon from "../../LeaseholderIcon";
    export default {
        name: "OwnershipOverviewMap",
        components: {
            LeaseholderIcon
        },
        props: {
            height: {
              type: Number,
              default(){
                  return 220;
              }
            },
            isRounded: {
                type: Boolean,
                default(){
                    return true
                }
            },
            coords: {
              type: Object,
                data(){
                  return {
                      lat: 0,
                      lon: 0
                  }
                }
            },
            polygons: {
              type: Array,
              default(){
                  return []
              }
            },
            bbox: {
              type: Array,
              default(){
                  return []
              }
            }
        },
        data(){
          return {
              zoom: 3,
              map: {
                  polygons: this.polygons,
                  bbox: this.bbox,
                  coords: this.coords,
              }
          }
        },
        watch: {
          polygons: {
              deep: true,
              handler(){
                  this.map.polygons = this.polygons;
              }
          },
            bbox: {
                deep: true,
                handler(){
                    this.map.bbox = this.bbox;
                    this.onMapReady();
                }
            },
            coords: {
                deep: true,
                handler(){
                    this.map.coords = this.coords;
                }
            }
        },
        computed: {
            getCoords(){
              return {
                  lat: this.map.coords && this.map.coords.lat ? this.map.coords.lat : 0,
                  lng: this.map.coords && this.map.coords.lon ? this.map.coords.lon : 0
              }
            },
            maxBounds(){
                const vm = this;
                if(this.map.bbox && this.map.bbox.length === 4){
                    return [
                        [vm.map.bbox[1], vm.map.bbox[0]],
                        [vm.map.bbox[3], vm.map.bbox[2]],
                    ]
                }
                return null;
            },
            getPolygons(){
                if(Array.isArray(this.map.polygons) && this.map.polygons.length > 0){
                    return this.map.polygons.map((item)=>{
                        item.geometry.properties = item.properties ? item.properties : {};
                        return item.geometry;
                    }).filter((item) => item.type !== 'Point');
                }
                return []
            },
            getLeaseholderPoints(){
                if(Array.isArray(this.map.polygons) && this.map.polygons.length > 0){

                    return this.map.polygons.map((item)=>{
                    return item.geometry;
                }).filter((item) => item.type === 'Point').map((item)=>{
                    return {
                        lat: item.coordinates[1],
                        lng: item.coordinates[0]
                    }
                })
                }
                return []
            },
            isSingleLeaseholder(){
                return Array.isArray(this.map.polygons) && this.map.polygons.length === 1 && this.map.polygons[0].geometry.type === 'Point';
            }
        },
        methods: {
            onMapReady(){
                if(this.$refs && this.$refs['preview-map'] && this.map.bbox && this.map.bbox.length === 4){
                    let polygonBounds = L.latLngBounds(
                        L.latLng(this.map.bbox[1], this.map.bbox[0]),
                        L.latLng(this.map.bbox[3], this.map.bbox[2])
                    );
                    this.$refs['preview-map'].mapObject.fitBounds(polygonBounds);
                    if(this.isSingleLeaseholder){
                        this.zoom = 19;
                    }
                }
            }
        },
    }
</script>

<style>
    .el-block--preview-map .leaflet-marker-icon {
        display: none !important;
    }
</style>