<template>
    <div class="el-block--overlay-details mb-2 w-full bg-gray-800 text-white rounded overflow-hidden shadow mr-3">
        <div class="p-1 bg-gray-900 rounded-top px-2">
            <div class="flex items-start w-full">
                <div
                        @click="showDetails = !showDetails"
                        v-if="keyed" class="text-white mr-2 flex items-center justify-center w-4 h-4 text-white text-xs cursor-pointer">
                    <i class="el-icon-arrow-up" v-if="showDetails"></i>
                    <i class="el-icon-arrow-down" v-if="!showDetails"></i>
                </div>
                    <div
                            @click="showDetails = !showDetails"
                            class="text-xs cursor-pointer select-none leading-snug w-full mr-2">
                        <slot name="title"></slot>
                    </div>
                <div
                        v-if="showClose"
                        @click="$emit('close')"
                        class="text-white ml-2 flex items-center justify-center w-4 h-4 text-white text-xs cursor-pointer"><i class="el-icon-close"></i></div>
            </div>
        </div>

                <div class="w-full" v-if="keyed && showDetails">
                <div class="flex overflow-hidden flex-col p-1">
                    <slot name="legend"></slot>
                </div>
                </div>
    </div>
</template>

<script>
    export default {
        name: "OverlayDetail",
        props: {
            keyed: {
                type: Boolean,
                default(){
                    return true
                }
            },
            visible: {
                type: Boolean,
                default(){
                    return true
                }
            },
            showVisibility: {
                type: Boolean,
                default(){
                    return true
                }
            },
            showClose: {
                type: Boolean,
                default(){
                    return true
                }
            }
        },
        watch: {
            visible(bool){
                this.isVisible = bool;
            }
        },
        methods: {
            collapse(){
                this.showDetails = false;
            },
            expand(){
                this.showDetails = true;
            }
        },
        data(){
            return {
                isVisible: this.visible,
                showDetails: true
            }
        }
    }
</script>

<style scoped>
</style>



