import api from "../../utils/api";

const initialState = {
    running: false
}

export default {
    namespaced: true,
    state: initialState,
    mutations: {
        reset(state) {
            console.log('reset')
            Object.keys(initialState).forEach(key => {
                state[key] = initialState[key]
            })
        },
    },
    actions: {
        add: ({rootState, state}, opts) => {
            state.running = true;

            return api.post('/api/v1/team/' + rootState.auth.user.team.id  + '/watch-list', {
                hash: opts.hash
            })
                .then(() => {
                    state.running = false;
                    return true;
                }).catch(() => {
                    state.running = false;
                    return true;
            })
        },
        remove: ({rootState, state}, opts) => {
            state.running = true;
            api.delete('/api/v1/team/' + rootState.auth.user.team.id  + '/watch-list/' + opts.hash)
                .then(() => {
                    state.running = false;
                    return true;
                }).catch(() => {
                state.running = false;
                return true;
            })
        },
    }
}
