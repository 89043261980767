<template>
    <div>

        <!--
        <affix
                :offset="{ top: 0, bottom: 0 }"
               :relative-element-selector="'#detailed-' + prefix + '-content'">
            <div class="letter-menu mb-4 flex border border-gray-200 rounded">
                <a
                        :key="letter"
                        v-for="letter of letters"
                        class="p-2 px-4 border-r border-gray-200 bg-white w-auto"
                        :href="'#view-' + prefix + '-' + letter">{{ letter }}</a>
            </div>
        </affix>
-->

        <div class="w-full p-8 my-12 flex justify-center"  v-if="loading">
            <loading-spinner />
        </div>

        <div class="columns" :id="'detailed-' + prefix + '-content'" v-if="!loading">

            <div
                    :key="k"
                    :id="'view-' + prefix + '-' + k"
                    v-for="(i, k) in  categorisedFiltered"
                    class="column w-full lg:w-1/2 xl:w-1/3">
                <dashboard-widget
                        :title="k"
                                  description="Overview of planning applications in the past 30 days">
                    <slot>

                        <div class="flex flex-wrap -mx-2">
                            <div class="p-2 text-sm"  :key="l"
                                 v-for="(j, l) of i">
                               <router-link class="text-blue-700 hover:border-blue-70 hover:border-b" :to="'/property-sales/' + prefix + '/' + j.id">{{ j.name }}</router-link>
                            </div>
                        </div>
                    </slot>
                </dashboard-widget>
            </div>

        </div>

    </div>
</template>

<script>
    export default {
        name: "LocationType",
        props: {
          url: {
              type: String,
              default: '/api/v1/types/postcode-districts'
          },
            prefix: {
              type: String,
                default: ''
            }
        },
        created(){
          this.fetch()
        },
        data(){
            return {
                data: [],
                search: '',
                loading: true
            }
        },
        computed: {
            letters() {
                let letters = []
                for(let i = "A".charCodeAt(0); i <= "Z".charCodeAt(0); i++) {letters.push(String.fromCharCode([i]))}
                return letters
            },
            categorisedFiltered(){
              let items = {};
              for(let item of this.data){
                  let first = item.name.charAt(0).toString();
                  if(!items[first]){
                      items[first] = [];
                  }
                  items[first].push(item);
              }
              return Object.keys(items).sort().reduce(function (acc, key) {
                  acc[key] = items[key];
                  return acc;
              }, {});
            }
        },
        methods: {
            fetch(){
                let vm = this;
                vm.loading = true;
                vm.$http.get(vm.url)
                    .then((response) => {
                        vm.data = response.data;
                        vm.loading = false;
                    })
                    .catch(() => {
                        vm.loading = false;
                    })
            },
        }
    }
</script>

<style lang="scss" scoped>
.affix {
    background: #1a202c;
    width: 100%;
    left: 0;
    .letter-menu {
        border-radius: 0 !important;
        margin-bottom: 0;
        border: 0 !important;
    }
}
</style>