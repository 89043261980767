import { render, staticRenderFns } from "./OrdinanceSurveyComponent.vue?vue&type=template&id=44300e59&scoped=true&"
import script from "./OrdinanceSurveyComponent.vue?vue&type=script&lang=js&"
export * from "./OrdinanceSurveyComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44300e59",
  null
  
)

export default component.exports