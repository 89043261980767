<template>
    <div class="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8" v-loading="loading">
        <div class="sm:mx-auto sm:w-full flex justify-center items-center text-center sm:max-w-md">
            <svg viewBox="0 0 100 133"
                 version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="w-6 mr-2">
                <g stroke="none" stroke-width="1" fill-rule="evenodd" class="fill-current text-green-600"><g><g><path d="M99.469,49.958 C99.469,66.251 84.687,89.43 71.25,107.167 L63.289,97.727 C76.121,80.427 87.375,61.413 87.375,49.958 C87.375,29.198 70.512,12.301 49.718,12.301 C28.957,12.301 12.094,29.198 12.094,49.958 C12.094,65.411 32.552,94.57 49.718,114.792 L57.578,124.131 C52.908,129.641 49.751,132.999 49.751,132.999 C49.751,132.999 0,79.823 0,49.958 C0,22.479 22.272,0.207 49.751,0.207 C77.196,0.207 99.469,22.479 99.469,49.958" id="Fill-13"></path><path d="M60.568,101.288 L68.529,110.728 C65.674,114.423 62.919,117.782 60.467,120.739 L52.573,111.4 L49.718,108.007 C32.652,87.246 16.46,62.22 16.46,49.958 C16.46,31.583 31.376,16.668 49.718,16.668 C68.093,16.668 83.008,31.583 83.008,49.958 C83.008,59.633 72.964,77.168 60.366,94.301 L52.338,84.76 C65.069,67.091 70.914,56.214 70.914,49.958 C70.914,38.268 61.408,28.761 49.718,28.761 C38.027,28.761 28.554,38.268 28.554,49.958 C28.554,56.214 35.205,68.804 49.718,88.388 L57.679,97.862 L60.568,101.288" id="Fill-15"></path></g></g></g></svg>
            <h1 class="text-white text-2xl">Land Monitor</h1>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md px-6 md:px-0" v-if="!loading">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

                <div v-if="valid">
                    <div class="mb-6">
                        <el-alert effect="dark" type="info" :closable="false">
                            Please enter a new password below
                        </el-alert>
                    </div>



                    <el-form label-position="top" label-width="175px"
                             :rules="rules" ref="passwordForm"
                             :model="password">
                        <el-form-item label="Email Address" prop="email">
                            <el-input id="email"
                                      :disabled="true"
                                      placeholder="E.g, bill@microsoft.com"
                                      v-model="email" />
                        </el-form-item>
                        <el-form-item label="New Password" prop="new_password">
                            <el-input v-model="password.new_password"
                                      type="password"
                                      :disabled="running"
                                      :show-password="true"/>
                        </el-form-item>
                        <el-form-item label="Repeat New Password" prop="new_password_confirm">
                            <el-input v-model="password.new_password_confirm"
                                      type="password"
                                      :disabled="running"
                                      :show-password="true"/>
                        </el-form-item>
                    </el-form>



                        <div class="mt-8 pt-4">
              <el-button
                      class="w-full"
                      type="success"
                      :disabled="running"
                      :loading="running"
                      @click="resetPassword"
              >
                Reset Password
              </el-button>
                        </div>
                </div>

                <div class="text-center" v-if="!valid">
                    <h4>Password reset invalid</h4>

                        <div class="mt-6 text-sm leading-5">
                            <a
                                    href="/password-reset"
                                    class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                            >
                               Reset Password`
                            </a>
                        </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>


    export default {
        name: 'ResetPassword',
        data () {
            return {
                valid: false,
                email: null,
                running: false,
                loading: true,
                rules: {
                    new_password: [
                        {
                            required: true, validator: (rule, value, callback) => {
                                if (value === '') {
                                    callback(new Error('Please input your new password'));
                                } else {
                                    if (this.password.new_password_confirm !== '') {
                                        this.$refs.passwordForm.validateField('new_password_confirm');
                                    }
                                    callback();
                                }
                            }, trigger: 'blur'
                        },
                        {min: 6, message: 'New password should be at least 6 characters', trigger: 'blur'}
                    ],
                    new_password_confirm: [
                        {
                            required: true, validator: (rule, value, callback) => {
                                if (value === '') {
                                    callback(new Error('Please confirm your new password'));
                                } else if (value !== this.password.new_password) {
                                    callback(new Error('The new password and confirmation password do not match'));
                                } else {
                                    callback();
                                }
                            }, trigger: 'blur'
                        }
                    ]
                },
                password: {
                    new_password: '',
                    new_password_confirm: '',
                }
            }
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {

                if(!vm.$route.query.email && !vm.$route.query.code){
                    vm.loading = false;
                    vm.valid = false;
                }else{
                    vm.$http.get('/api/v1/auth/password-reset', {
                        params: {
                            email: vm.$route.query.email,
                            code: vm.$route.query.code,
                        }
                    })
                        .then(()=>{
                            vm.loading = false;
                            vm.valid = true;
                        })
                        .catch(()=>{
                            vm.loading = false;
                            vm.valid = false;
                        })
                }

            });
        },
        created(){
            const vm = this;
            if(this.$route.query) {
                if(this.$route.query['email']){
                    vm.email = this.$route.query['email'];
                }
            }
        },
        methods: {
            resetPassword() {
                let vm = this;

                vm.$refs['passwordForm'].validate((valid) => {
                    if (!valid) {
                        return false;
                    } else {
                        vm.running = true;

                        vm.$http.post('/api/v1/auth/password-reset', {
                            code: vm.$route.query['code'],
                            email: vm.$route.query['email'],
                            password: vm.password.new_password,
                            confirm_password: vm.password.new_password_confirm
                        })
                            .then(() => {
                                vm.$router.push('/login?password-reset=true&email=' + vm.$route.query['email'])
                            })
                            .catch((e) => {
                                vm.running = false;
                                vm.$store.dispatch('auth/handleServerError', {
                                    vm: vm,
                                    error: e
                                });
                            })
                    }
                })

            }
        }
    }
</script>
<style>
    .bg-particles {
        background-image: url("/img/particles-transparent.png");
    }
</style>