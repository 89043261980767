<template>
    <div>

        <navigation/>

        <page-header title="London Boroughs">
            <template v-slot:tabs>
                <property-prices-nav />
            </template>
        </page-header>
        <page>

            <location-type
                    url="/api/v1/types/london-boroughs"
                    prefix="london-boroughs"
            />
        </page>
    </div>

</template>
<script>
    import LocationType from "../../../components/LocationType";
    import PropertyPricesNav from "./PropertyPricesNav";
    export default {
        name: "LondonBoroughs",
        components: {PropertyPricesNav, LocationType},
            beforeRouteEnter(to, from, next) {
                next((vm) => {
                    vm.$store.commit('auth/setBreadcrumbs', [
                        {
                            title: 'Property Prices',
                            path: '/property-sales'
                        },
                        {
                            title: 'London Boroughs'
                        }
                    ]);
                })
            }
    }
</script>
