<template>

      <div v-if="show"  class="w-full h-full absolute md:-mx-16 top-0 modal-zindex">


    <transition
  enter-active-class="ease-out duration-300"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="ease-in duration-200"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
>
  <div v-if="showOverlay" class="fixed inset-0 transition-opacity">
    <div class="absolute inset-0 bg-gray-700 opacity-75"></div>
  </div>
</transition>

      <transition

              enter-active-class="ease-out duration-300"
           enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
           enter-to-class="opacity-100 translate-y-0 sm:scale-100"
           leave-active-class="ease-in duration-200"
           leave-class="opacity-100 translate-y-0 sm:scale-100"
           leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">


        <div class="h-screen relative flex flex-col md:max-w-6xl md:mx-auto rounded overflow-hidden">
          <!--- Header --->
          <div
                  v-if="hasHeader"
                  class="bg-white px-4 py-5 border-b border-gray-200 md:mt-20 rounded-t">
            <div class="-ml-4 -mt-4 flex justify-between items-center">
              <div class="ml-4 mt-4">
                <slot name="header"></slot>
              </div>
              <div
                      v-if="showClose"
                      class="ml-4 mt-4 flex flex-shrink-0">
                <button
                        @click="close"
                        type="button"
                        class="text-gray-400 top-0 right-0 mt-1 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150">
                  <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <!-- Content --->
          <div class="h-full px-4 py-5 bg-white flex-0 overflow-auto" :class="{
          'md:mt-20 rounded-t': !hasHeader,
          'md:mb-12 rounded-b': !hasActions
          }">
            <slot name="content"></slot>
          </div>

          <!-- Actions -->
          <div class="bg-gray-100 border-t border-300 px-4 py-5 md:mb-12 rounded-b" v-if="hasActions">
            <slot name="actions"></slot>
          </div>

        </div>

      </transition>




  </div>
</template>
<script>
    export default{
      props: {
      width: {
        type: String,
        default(){
          return null
        }
      },
        autoHeight: {
          type: Boolean,
          default: false
        },
        show: {
          type: Boolean,
          default: true
        },
        hasActions: {
          type: Boolean,
          default: true
        },
        hasHeader: {
          type: Boolean,
          default: true
        },
        showClose: {
          type: Boolean,
          default: true
        }
      },
      watch: {
        show(val){
          if(val){
            setTimeout(()=>{
              this.showOverlay = val;
            }, 200);
            setTimeout(()=>{
              this.showContent = val;
            }, 300);
          }
        }
      },
      data(){
        return {
          showOverlay: this.show,
          showContent: this.show
        }
      },
      methods: {
        close(){
          setTimeout(()=>{
            this.showContent = false;
          }, 100);
          setTimeout(()=>{
            this.showOverlay = false;
          }, 200);
          setTimeout(()=>{
            this.$emit('close');
          }, 400);
        }
      }
    }
</script>
<style>

  .max-modal-height {
    height: calc(100vh - 20rem);
  }

  .modal-zindex {
    z-index: 9006 !important;
  }
</style>