<template>
        <span :class="{
        'text-green-400': reverse ? value : !value,
        'text-red-400': reverse ? !value : value
        }">{{ value === true ? 'Yes' : 'No' }}</span>
</template>

<script>
    export default {
        name: "YesNoComponent",
        props: {
            value: {
                type: Boolean
            },
            reverse: {
                type: Boolean,
                default(){
                    return false
                }
            }
        }
    }
</script>

<style scoped>

</style>