<template>
        <page>

            <template slot="header">
                <page-header title="Search Planning Applications">
                    <template v-slot:tabs>
                        <planning-applications-nav />
                    </template>
                </page-header>
            </template>

            <template slot="content">
                <dashboard-widget title="Your Application Watchlist"
                                  description="Recently decided planning applications">

                    <div class="h-32 w-full"
                         v-if="loading"
                         v-loading="true"></div>

                    <empty-state class="p-3"
                                 title="Your Watchlist is empty"
                                 message="It look's like your application watchlist is empty."
                                 :support="false"
                                 v-if="!loading && !results.length" />


                    <div class="columns pb-4"
                         v-if="meta.totalResult && !loading"
                    >
                        <div class="column flex items-center">
                                <span class="text-sm">
                                  {{ meta.totalResult.toLocaleString() }} application(s) found. Displaying page {{ meta.current.toLocaleString() }} of {{ meta.pageCount.toLocaleString() }}
                                </span>
                        </div>
                        <div class="column ml-auto">
                            <div class="columns">
                                <div class="column flex items-center">
                                    <span class="text-gray-600 uppercase text-xs">per page</span>
                                </div>
                                <div class="column">
                                    <el-select
                                            class="w-32"
                                            v-model="rpp" @change="fetch(1)">
                                        <el-option :value="10">10</el-option>
                                        <el-option :value="20">20</el-option>
                                        <el-option :value="30">30</el-option>
                                        <el-option :value="40">40</el-option>
                                        <el-option :value="50">50</el-option>
                                        <el-option :value="100">100</el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <el-table
                            v-if="meta.totalResult && !loading"
                            :data="results"
                            :default-sort="default_sort"
                            @sort-change="onSortChange"
                            style="width: 100%">

                        <el-table-column
                                prop="proposal.keyword"
                                sortable="custom"
                                label="Proposal">
                            <template slot-scope="scope">

                                <div class="break-normal text-xs leading-tight">
                                    <p class="font-medium text-blue-700 leading-loose truncate w-full">{{ scope.row.proposal }}</p>
                                    <p class="pt-1">
                                        {{ scope.row.local_authority }} - {{ scope.row.site_address }}</p>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                                width="180px"
                                prop="status.keyword"
                                sortable="custom"
                                label="Status">
                            <template slot-scope="scope">
                                          <span class="w-2 h-2 inline-block rounded-full"
                                                :class="{
                                        'bg-green-500': scope.row.status_simplified === 'DECIDED',
                                        'bg-blue-500': scope.row.status_simplified === 'PENDING',
                                        'bg-gray-500': scope.row.status_simplified === 'WITHDRAWN'
                                        }"
                                          ></span>
                                <span class="flex-grow ml-1">{{ scope.row.status }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                width="180px"
                                prop="decision.keyword"
                                sortable="custom"
                                label="Decision">
                            <template slot-scope="scope">

                                        <span class="w-2 h-2 inline-block rounded-full"
                                              :class="{
                                        'bg-red-500': scope.row.decision_simplified === 'REFUSED',
                                        'bg-green-500': scope.row.decision_simplified === 'APPROVED',
                                        'bg-blue-500': scope.row.decision_simplified === 'PENDING',
                                        'bg-gray-500': scope.row.decision_simplified === 'WITHDRAWN',
                                        'bg-orange-500': scope.row.decision_simplified === 'SPLIT'
                                        }"
                                        ></span>
                                <span class="flex-grow ml-1">{{ scope.row.decision }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                width="150px"
                                prop="date_received"
                                sortable="custom"
                                label="Date Received">
                            <template slot-scope="scope">
                                <div class="break-normal h-full text-xs leading-tight">
                                    {{ scope.row.date_received | moment("MMM Do YYYY") }}
                                    <br /><small>{{ scope.row.date_received | moment("from", "now") }}</small>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                                width="140px"
                                prop="date_decided"
                                sortable="custom"
                                label="Date Decided">
                            <template slot-scope="scope">
                                <div class="break-normal h-full text-xs leading-tight">
                                    {{ scope.row.date_decided | moment("MMM Do YYYY") }}
                                    <br /><small>{{ scope.row.date_decided | moment("from", "now") }}</small>

                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column
                                fixed="right"
                                width="140"
                        >
                            <template slot-scope="scope">


                                <el-dropdown @command="(cmd)=>{ handleCommand(cmd, scope.row.id)  }">
                                    <el-button size="medium" type="success">
                                        Actions <i class="el-icon-arrow-down el-icon--right"></i>
                                    </el-button>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item command="view">
                                            View
                                        </el-dropdown-item>
                                        <el-dropdown-item command="unwatch">
                                            Un-watch
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>

                            </template>
                        </el-table-column>

                    </el-table>

                    <div class="columns pt-4"
                         v-if="meta.totalResult && !loading"
                    >
                        <div class="column flex items-center">
                                <span class="text-sm">
                                  {{ meta.totalResult.toLocaleString() }} application(s) found. Displaying page {{ meta.current.toLocaleString() }} of {{ meta.pageCount.toLocaleString() }}
                                </span>
                        </div>
                        <div class="column ml-auto">
                            <el-pagination
                                    background
                                    layout="prev, pager, next"
                                    @current-change="(page)=>{ fetch(page) }"
                                    :page-size="rpp"
                                    :current-page="meta.current"
                                    :total="meta.totalResult">
                            </el-pagination>

                        </div>
                    </div>

                </dashboard-widget>
            </template>

        </page>

</template>

<script>

    import EmptyState from "../../components/EmptyState";
    import PlanningApplicationsNav from "./PlanningApplicationsNav";
    export default {
        name: 'WatchList',
        components: {PlanningApplicationsNav, EmptyState},
        data() {
            return {
                rpp: 10,
                loading: true,
                default_sort: {
                    prop: 'date_decided',
                    order: 'desc'
                },
                results: [],
                meta: {}
            }
        },
        created() {
            this.fetch(1);
        },
        methods: {
            handleCommand(cmd, id) {
                const vm = this;
                switch (cmd) {
                    case "view":
                        vm.$router.push('/planning-application/' + id)
                        break;
                    case "unwatch":
                        vm.unwatch(id);
                        break;
                }
            },
            onSortChange(object) {
                this.default_sort = object.prop && object.order ? object : null;
                this.fetch(1);
            },
            unwatch(hash) {
                let vm = this;

                vm.$confirm(
                    'Are you sure you want to unwatch this application? This cannot be undone.',
                    'Unwatch Application?',
                    {
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'Cancel'
                    })
                    .then(() => {
                        vm.loading = true;

                        this.$store.dispatch('watchlist/remove', {
                            hash: hash
                        }).then(()=>{
                            vm.$notify({
                                type: 'success',
                                title: 'Application Removed',
                                message: 'Application removed from watchlist',
                                position: 'top-right',
                                offset: 140,
                            });

                            setTimeout(()=>{
                                vm.fetch(1);
                            }, 100)
                        })

                    }).catch((e) => {
                    console.log(e);
                });

            },
            fetch(page) {
                let vm = this;
                vm.results = [];
                vm.loading = true;
                vm.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id  + '/watch-list', {
                    params: {
                        page: page || 1,
                        rpp: vm.rpp,
                        sort: vm.default_sort && vm.default_sort.prop ? vm.default_sort.prop : null,
                        order: vm.default_sort && vm.default_sort.order ? vm.default_sort.order === 'descending' ? 'desc' : 'asc' : null,
                    }
                }).then((response) => {
                    vm.results = response.data.results;
                    vm.meta = response.data.meta;
                    vm.loading = false;
                }).catch(() => {
                    vm.loading = false;
                })
            },
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.$store.commit('auth/setBreadcrumbs', [
                    {
                        title: 'Planning Applications',
                        path: '/planning-applications'
                    },
                    {
                        title: 'Watchlist'
                    }
                ]);
            })
        }
    }
</script>