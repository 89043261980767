<template>
    <div>

        <div class="h-32 w-full"
             v-if="loading"
             v-loading="true"></div>

        <empty-state class="p-3"
                     title="You have no application alerts"
                     message="It look's like you have not created any application alerts"
                     :support="false"
                     v-if="!loading && !results.length" />

        <el-table
                v-if="results.length > 0 && !loading"
                :data="getData"
                :default-sort="default_sort"
                @sort-change="onSortChange"
                style="width: 100%">

            <el-table-column
                    prop="label"
                    sortable="custom"
                    label="Postcode District">
                <template slot-scope="scope">
                    <h4 class="font-semibold">{{ scope.row.label }}</h4>
                </template>
            </el-table-column>

            <el-table-column
                    width="120px"
                    prop="rental_avg"
                    sortable="custom"
                    align="right"
                    label="Average">
                <template slot-scope="scope">
                    <market-tracker-nested-value
                            v-model="scope.row.rental_avg"
                            :avg="avg"
                    />
                </template>
            </el-table-column>


            <el-table-column
                    width="120px"
                    prop="rent_avg_detached"
                    sortable="custom"
                    align="right"
                    label="Detached">
                <template slot-scope="scope">
                    <market-tracker-nested-value
                            v-model="scope.row.rent_avg_detached"
                            :avg="avgDetached"
                    />
                </template>
            </el-table-column>

            <el-table-column
                    width="120px"
                    prop="rent_avg_semi_detached"
                    sortable="custom"
                    align="right"
                    label="Semi">
                <template slot-scope="scope">
                    <market-tracker-nested-value
                            v-model="scope.row.rent_avg_semi_detached"
                            :avg="avgSemi"
                    />
                </template>
            </el-table-column>

            <el-table-column
                    width="120px"
                    prop="rent_avg_bungalows"
                    sortable="custom"
                    align="right"
                    label="Bungalow">
                <template slot-scope="scope">
                    <market-tracker-nested-value
                            v-model="scope.row.rent_avg_bungalows"
                            :avg="avgBungalow"
                    />
                </template>
            </el-table-column>

            <el-table-column
                    width="180px"
                    prop="rent_avg_flats"
                    sortable="custom"
                    align="right"
                    label="Flats/Apartments">
                <template slot-scope="scope">
                    <market-tracker-nested-value
                            v-model="scope.row.rent_avg_flats"
                            :avg="avgFlat"
                    />
                </template>
            </el-table-column>

            <el-table-column
                    align="right"
                    width="120px"
            >
                <template slot-scope="scope">
                    <el-button
                            size="small"
                            type="info"
                            icon="el-icon-view"
                            @click="openLink(scope.row.label)"
                            class="info">View</el-button>
                </template>
            </el-table-column>


        </el-table>

    </div>
</template>

<script>
    import MarketTrackerNestedValue from "../MarketTrackerNestedValue";
    export default {
        name: "MarketTrackerNested",
        components: {MarketTrackerNestedValue},
        props: {
            value: {
                type: String,
                default(){
                    return null;
                }
            },
            avg: {
                type: Number,
                default(){
                    return 0
                }
            },
            avgSemi: {
                type: Number,
                default(){
                    return 0
                }
            },
            avgDetached: {
                type: Number,
                default(){
                    return 0
                }
            },
            avgBungalow: {
                type: Number,
                default(){
                    return 0
                }
            },
            avgFlat: {
                type: Number,
                default(){
                    return 0
                }
            },
        },
        data() {
            return {
                loading: true,
                default_sort: {
                    prop: 'rent_avg',
                    order: 'descending'
                },
                col: 'rent_avg',
                results: [],
            }
        },
        created() {
            this.fetch();
        },
        computed: {
            getData(){

                if(!this.results.length){
                    return [];
                }

                if(!this.col){
                    return this.results;
                }

                let col = this.default_sort.prop, results = this.results;
                if(this.default_sort.order === 'descending'){
                    return results.sort((a,b) => (a[col] > b[col]) ? 1 : ((b[col] > a[col]) ? -1 : 0))
                }
                if(this.default_sort.order === 'ascending'){
                    return results.sort((a,b) => (a[col] < b[col]) ? 1 : ((b[col] < a[col]) ? -1 : 0))
                }
                return results;
            }
        },
        methods: {
            openLink(code){
                window.open('https://www.rightmove.co.uk/property-to-rent/' + code + '.html')
            },
            toPrice(value){
                return value ? "£" + Math.floor(value).toLocaleString() : 'N/A';
            },
            onSortChange(col){
                this.default_sort = {
                    prop: col.column.property,
                    order: col.column.order
                }
            },
            fetch() {
                let vm = this;
                vm.results = [];
                vm.loading = true;
                vm.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/property-prices/district/' + this.value).then((response) => {
                    vm.results = response.data.data;
                    vm.loading = false;
                }).catch(() => {
                    vm.loading = false;
                })
            }
        }
    }
</script>

<style scoped>

</style>