<template>
    <div>

        <div
                v-if="loading"
                class="flex h-full mt-12 pt-12 items-center justify-center">
            <div class="p-3 text-center">
                <property-loader/>
                <h3 class="font-semibold text-blue-800">Fetching Topography Analysis...</h3>
            </div>
        </div>

        <div class="columns"
             v-if="!loading"
        >
            <div class="column w-full">
                <dashboard-widget
                        padding-class=""
                        title="Topography Snapshot">
                    <template slot="cta">
                        <div class="flex text-gray-600">
                            <div class="mr-2">
                                <label class="text-xs uppercase">Toggle Overlays</label>
                            </div>
                            <div>
                                <el-switch
                                        v-model="showPolygons"
                                        active-color="#13ce66"
                                        inactive-color="#ff4949">
                                </el-switch>
                            </div>
                        </div>
                    </template>
                    <ownership-topography-map
                            v-if="getSelectedTopographyData && getSelectedTopographyData.features && getSelectedTopographyData.center && getSelectedTopographyData.bbox"
                            v-model="getSelectedTopographyData"
                            :show-polygons="showPolygons"
                    />
                </dashboard-widget>
            </div>
            <div class="column w-full">
                <topography-analysis-component
                        type-label="Type"
                        title="Type Analysis"
                        v-model="getTypeBreakdown"
                />
            </div>
            <div class="column w-full">
                <topography-analysis-component
                        type-label="Location"
                        title="Physical Location Analysis"
                        v-model="getPhysicalLocationBreakdown"
                />
            </div>
            <div class="column w-full">
                <topography-analysis-component
                        type-label="Make"
                        title="Make Analysis"
                        v-model="getMakeBreakdown"
                />
            </div>
        </div>

    </div>

</template>ci

<script>

    import Styles from "../../../../data/OSStyleCodes";
    import PropertyLoader from "../../../incs/PropertyLoader";
    import OwnershipTopographyMap from "../incs/OwnershipTopographyMap";
    import TopographyAnalysisComponent from "../incs/TopographyAnalysisComponent";

    export default {
        name: "OwnershipTopographyOverview",
        components: {TopographyAnalysisComponent, OwnershipTopographyMap, PropertyLoader},
        props: {
            loading: {
                type: Boolean,
                default: true
            },
            value: {
                type: Object,
                default() {
                    return {}
                }
            },
            activeSiteId: {
                type: Number,
                default() {
                    return null;
                }
            }
        },
        watch: {
            value: {
                handler(val){
                    this.object = JSON.parse(JSON.stringify(val));
                },
                deep: true
            }
        },
        data() {
            return {
                object: JSON.parse(JSON.stringify(this.value)),
                active_building_index: null,
                showPolygons: true,
                activeTabs: [],
                selected: 0,
                map: {
                    zoom: 3
                },
            }
        },
        computed: {
            getPhysicalLocationBreakdown(){
              return this.fetchCoverageBreakdown('by_physical_locations');
            },
            getTypeBreakdown(){
                return this.fetchCoverageBreakdown('by_type');
            },
            getMakeBreakdown(){
                return this.fetchCoverageBreakdown('by_make');
            },
            hasData() {
                return this.object.length > 0;
            },


            getSelectedTopographyData() {
                let overview = this.object && this.object.overview ? this.object.overview : null;
                if(!overview){
                    return  null;
                }
                let sites = this.object && this.object.sites ? this.object.sites : [];

                if(this.activeSiteId && sites.length > 0){
                    return sites.find((i)=>{
                        return i.site_id === this.activeSiteId;
                    })
                }

                return overview;
            }

        },
        methods: {
            fetchCoverageBreakdown(type){
                let breakdown = this.getSelectedTopographyData;
                return breakdown && breakdown.breakdown && breakdown.breakdown[type] ? breakdown.breakdown[type] : [];
            },
            fetchStyle(code) {
                let item = Styles.find((item) => {
                    return item.code === code;
                });
                return item ? item.color : '#c22025';
            }
        }
    }
</script>

<style scoped>

</style>