<template>
    <div class="h-full bg-gray-200 overflow-scroll">
        <div class="bg-blue-900 p-6">
            <h4 class="font-semibold overflow-hidden text-white">
                Leaseholders
            </h4>
        </div>
        <div class="bg-gray-200 p-3">
            <div class="columns">
                <div class="column w-full">
                    <dashboard-widget title="Search Leaseholders">
                        <template v-slot:cta>
                        </template>


                        <div v-loading="$store.state.map.data_metrics.leaseholders.fetching">
                            <filter-component title="Search" class="mb-2">

                                <el-select
                                        class="w-full"
                                        @change="()=>{ searchLeaseholdersTable(1) }"
                                        placeholder="Select Search Area..."
                                        size="medium"
                                        :disabled="$store.state.map.data_metrics.leaseholders.fetching"
                                        v-model="search_area">
                                    <el-option
                                            v-for="item in search_areas"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                        {{ item.label }}
                                    </el-option>
                                </el-select>

                            </filter-component>
                            <filter-component>
                                <vue-query-builder
                                        v-model="query"
                                        :maxDepth="1"
                                        :rules="filters"/>
                            </filter-component>
                        </div>

                        <div class="flex w-full justify-end my-3">
                            <el-button
                                    type="success"
                                    icon="el-icon-search"
                                    :disabled="$store.state.map.data_metrics.leaseholders.fetching"
                                    :loading="$store.state.map.data_metrics.leaseholders.fetching"
                                    @click="()=>{ searchLeaseholdersTable(1); }"
                            >Search
                            </el-button>
                        </div>

                        <div class="h-1 border-b mt-3 border-gray-200"></div>

                        <pagination-wrapper
                                @change="(page)=>{ searchLeaseholdersTable (page)  }"
                                :has-results="$store.state.map.data_metrics.leaseholders.results.length > 0"
                                :meta="$store.state.map.data_metrics.leaseholders.meta"
                                :loading="$store.state.map.data_metrics.leaseholders.fetching">
                            <template v-slot:header>
                                <div class="columns my-3">
                                    <div class="column w-1/3 text-xs flex items-center uppercase">
                                        {{ $store.state.map.data_metrics.leaseholders.meta.totalResult.toLocaleString() }} results
                                    </div>
                                    <div class="column w-2/3">
                                        <div class="flex items-center">
                                            <div class="text-xs uppercase ml-auto mr-2">Sort by</div>

                                            <div class="w-48">
                                                <el-select
                                                        auto-complete="off"
                                                        @change="()=>{ searchLeaseholdersTable(1) }"
                                                        placeholder="Sort..."
                                                        size="small"
                                                        v-model="sort">
                                                    <el-option
                                                            v-for="item in sort_fields"
                                                            :key="item.value"
                                                            :label="item.label"
                                                            :value="item.value">
                                                        <div class="flex w-full items-center">
                                                            <i v-if="item.value.endsWith('desc')" class="el-icon-arrow-down"></i>
                                                            <i v-if="item.value.endsWith('asc')"  class="el-icon-arrow-up"></i>
                                                            <span class="ml-1">{{ item.label }}</span>
                                                        </div>
                                                    </el-option>
                                                </el-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:results>
                                <div
                                        :key="k"
                                        v-for="(i, k) of $store.state.map.data_metrics.leaseholders.results"
                                        class="bg-white px-4 py-2 mb-6 border shadow border border-gray-200">
                                    <div class="flex -pr-1 items-center  text-xs">
                                        <div class="pr-1 w-auto mr-auto">
                                            Started <date-human-view size="text-xs" v-model="i.date_of_lease"/>
                                            <span v-if="i.price_paid"> for £{{ i.price_paid.toLocaleString() }}</span></div>
                                        <div>
                                            <div class="ml-auto"  v-if="i.uprn">
                                                <el-button
                                                        icon="el-icon-view"
                                                        @click="$emit('uprn', i.uprn)"
                                                        type="success" size="mini">View</el-button>
                                            </div>
                                        </div>
                                    </div>
                                    <h3 class="font-bold mt-1 leading-tight my-2 mt-3 ">
                                        {{ i.register_property_description.startsWith('Unavailable') ? i.associated_property_description : i.register_property_description }}
                                        <br /><small class="text-xs font-medium"> {{ i.associated_property_description }}</small>
                                    </h3>
                                    <div class="text-xs">
                                        <span v-if="i.sqft">{{ i.sqft.toLocaleString() }} ft²</span>  <span v-if="i.property_type">{{ i.property_type }}</span>
                                        <span v-if="i.construction_age_band">
                                        <br /> Built {{ i.construction_age_band }}
                                    </span>
                                    </div>

                                    <h4 class="text-sm my-2">
                                        {{ i.term }}
                                    </h4>


                                </div>
                            </template>
                        </pagination-wrapper>


                    </dashboard-widget>
                </div>
            </div>
        </div>
    </div>
</template>

<script>



    import collect from "collect.js";
    import VueQueryBuilder from 'vue-query-builder';
    import FilterComponent from "../FilterComponent";
    import PaginationWrapper from "../../incs/PaginationWrapper";

    export default {
        name: "LeaseholderDataLayer",
        components: {PaginationWrapper, FilterComponent, VueQueryBuilder},
        data() {
            return {
                sort: 'date_of_lease|desc',
                query: {},
                search_area: 'bounds',
                search_areas: [
                    {value: 'bounds', label: 'Current Bounds'},
                    {value: null, label: 'United Kingdom'},
                ],
                filters: [
                    {
                        id: 'register_property_description',
                        label: 'Register Property Description',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']},
                    {
                        id: 'associated_property_description',
                        label: 'Associated Property Address',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']},
                    {
                        id: 'term',
                        label: 'Term',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },

                    {
                        id: 'price_paid',
                        label: 'Price Paid',
                        type: 'numeric'
                    },

                    {
                        type: "text",
                        id: "date_of_lease",
                        label: "Date of Lease",
                        operators: ["=", "<",  "<=", '>', ">="],
                    },

                ]
            }
        },
        created() {
            this.searchLeaseholdersTable(1);
        },
        watch: {
            '$store.state.map.bounds'(){
                const vm = this;
                if(!vm.$store.state.map.data_metrics.leaseholders.fetching){
                    this.searchLeaseholdersTable(1);
                }
            }
        },
        computed: {
            ascFields(){
                return this.filters.map((item)=>{
                    return {
                        value: item.id + (item.type === 'text' && item.id !== 'date_of_lease' ? '.keyword' : '') + '|asc',
                        label: item.label
                    }
                });
            },
            descFields(){
                return this.filters.map((item)=>{
                    return {
                        value: item.id + (item.type === 'text' && item.id !== 'date_of_lease' ? '.keyword' : '') + '|desc',
                        label: item.label
                    }
                });
            },
            sort_fields(){
                return collect(this.ascFields.concat(this.descFields)).sortBy('label').toArray()
            },
            getQueryObject() {
                const match = this.query && this.query.logicalOperator ? this.query.logicalOperator : null;
                if (!match) {
                    return null;
                }
                return {
                    match: match,
                    rules: this.query && this.query.children ? this.query.children.map((i) => {
                        return i.query
                    }).filter((item) => {
                        return item && ((typeof item.value === 'string' && item.value.length > 0) || item.type === 'custom-component' || (typeof item.value === 'boolean'));
                    }) : []
                }
            },
        },
        methods: {

            searchLeaseholdersTable(page) {
                const vm = this;
                let object = {
                    rpp: 10,
                    page: page || 1
                };

                let filters = {}, queryObject = this.getQueryObject;

                if(queryObject && queryObject.match && queryObject.rules){
                    filters.match = queryObject.match;
                    filters.rules = queryObject.rules;
                }

                if(this.sort && this.sort.length > 0){
                    let ex = this.sort.split('|');
                    if(ex.length === 2){
                        object.sort = ex[0];
                        object.order = ex[1];
                    }
                }


                const bounds = this.search_area === 'bounds' ? vm.$store.getters['map/getMapBoundsAsObject'] : {};

                object = Object.assign(object, filters, bounds);

                vm.$store.state.map.data_metrics.leaseholders.fetching = true;
                vm.$store.dispatch('map/searchLeaseholders', object).then((response) => {
                    vm.$store.state.map.data_metrics.leaseholders.fetching = false;
                    vm.$store.state.map.data_metrics.leaseholders.results = response.data.data.results;
                    vm.$store.state.map.data_metrics.leaseholders.meta = response.data.data.meta;
                }).catch(() => {
                    vm.$store.state.map.data_metrics.leaseholders.fetching = false;
                    vm.$store.state.map.data_metrics.leaseholders.results = [];
                    vm.$store.state.map.data_metrics.leaseholders.meta = {};
                })
            },
        }
    }
</script>
<style>
    .el-date-picker {
        z-index: 50001 !important;
    }
</style>