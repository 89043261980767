var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.showOverlay && _vm.show ? 'top-0  w-full top-0 h-full absolute' : ''},[_c('transition',{attrs:{"enter-active-class":"transition ease-in-out duration-300 transform","enter-class":"opacity-0","enter-to-class":"opacity-50","leave-active-class":"transition ease-in-out duration-300 transform","leave-class":"opacity-0","leave-to-class":"opacity-0"}},[(_vm.show && _vm.showOverlay)?_c('div',{staticClass:"absolute w-full h-full bg-black bg-opacity-50",style:({
'z-index': 5001
})}):_vm._e()]),_c('transition',{attrs:{"enter-active-class":"transition ease-in-out duration-300 transform","enter-class":_vm.position !== 'left' ? 'translate-x-full' : '-translate-x-full',"enter-to-class":"translate-x-0","leave-active-class":"transition ease-in-out duration-300 transform","leave-class":"translate-x-0","leave-to-class":_vm.position !== 'left' ? 'translate-x-full' : '-translate-x-full'}},[(_vm.show)?_c('div',{staticClass:"absolute h-full top-0 shadow-lg bg-white shadow el-block--sidebar-option w-full md:w-2/3 md:max-w-lg md:w-auto",class:{
    'left-0': _vm.position === 'left',
    'right-0': _vm.position !== 'left',
    customClass: _vm.customClass
    },style:({
'z-index': 6001,
})},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","placement":_vm.position === 'left' ? 'right' : 'left',"content":"Close"}},[(_vm.showClose)?_c('div',{staticClass:"bg-red-700 text-white cursor-pointer mt-2 w-8 text-xs h-8 flex items-center justify-center shadow-lg rounded-full absolute",class:{
                    'right-0 -mr-10': _vm.position === 'left',
                    'left-0 -ml-10': _vm.position !== 'left',
                    },on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" X ")]):_vm._e()]),_vm._t("default")],2):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }