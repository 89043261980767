<template>
    <div>



        <page>

            <template slot="header">
                <page-header title="Account Security">
                    <template v-slot:tabs>
                        <account-nav />
                    </template>
                </page-header>
            </template>

            <template slot="content">
            <div class="columns">
                <div class="column w-full">
                    <dashboard-widget title="Account Password"
                                      description="Reset your password">


                        <el-form label-position="left" label-width="175px"
                                 :rules="rules" ref="passwordForm"
                                 :model="password">
                            <el-form-item label="Current Password" prop="current">
                                <el-input v-model="password.current"
                                          type="password"
                                          :disabled="updating"
                                          :show-password="true"/>
                            </el-form-item>
                            <el-form-item label="New Password" prop="new_password">
                                <el-input v-model="password.new_password"
                                          type="password"
                                          :disabled="updating"
                                          :show-password="true"/>
                            </el-form-item>
                            <el-form-item label="Repeat New Password" prop="new_password_confirm">
                                <el-input v-model="password.new_password_confirm"
                                          type="password"
                                          :disabled="updating"
                                          :show-password="true"/>
                            </el-form-item>
                        </el-form>

                        <div class="mt-4">
                            <div class="columns">
                                <div class="column ml-auto">
                                    <el-button
                                            @click="updatePassword"
                                            :disabled="updating"
                                            :loading="updating"
                                            icon="el-icon-refresh-right"
                                            type="success">
                                        Change Password
                                    </el-button>
                                </div>
                            </div>
                        </div>


                    </dashboard-widget>
                </div>
            </div>
            </template>

        </page>
    </div>

</template>

<script>

    import AccountNav from "./AccountNav";
    export default {
        name: 'Security',
        components: {AccountNav},
        data() {
            return {
                updating: false,
                rules: {
                    current: [
                        {required: true, message: 'Your current password is required', trigger: 'blur'},
                    ],
                    new_password: [
                        {
                            required: true, validator: (rule, value, callback) => {
                                if (value === '') {
                                    callback(new Error('Please input your new password'));
                                } else {
                                    if (this.password.new_password_confirm !== '') {
                                        this.$refs.passwordForm.validateField('new_password_confirm');
                                    }
                                    callback();
                                }
                            }, trigger: 'blur'
                        },
                        {min: 6, message: 'New password should be at least 6 characters', trigger: 'blur'}
                    ],
                    new_password_confirm: [
                        {
                            required: true, validator: (rule, value, callback) => {
                                if (value === '') {
                                    callback(new Error('Please confirm your new password'));
                                } else if (value !== this.password.new_password) {
                                    callback(new Error('The new password and confirmation password do not match'));
                                } else {
                                    callback();
                                }
                            }, trigger: 'blur'
                        }
                    ]
                },
                password: {
                    current: '',
                    new_password: '',
                    new_password_confirm: '',
                },
            }
        },
        methods: {
            updatePassword() {
                let vm = this;

                vm.$refs['passwordForm'].validate((valid) => {
                    if (!valid) {
                        return false;
                    } else {
                        vm.updating = true;

                        vm.$http.post('/api/v1/auth/password', vm.password)
                            .then(() => {

                                vm.$notify({
                                    title: 'Password Updated',
                                    message: 'Your password has been updated successfully',
                                    type: 'success',
                                    position: 'top-right',
                                    offset: 140,
                                });

                                vm.updating = false;

                            })
                            .catch((e) => {
                                vm.updating = false;
                                vm.$store.dispatch('auth/handleServerError', {
                                    vm: vm,
                                    error: e
                                });
                            })
                    }
                })

            }
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.$store.commit('auth/setBreadcrumbs', [
                    {
                        title: 'Account Security'
                    }
                ]);
            })
        }
    }
</script>