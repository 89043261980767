<template>
    <el-tooltip
            :disabled="tooltipDisabled"
            class="item" effect="dark" placement="left">
        <div slot="content">
            You can export up to 500 records.
        </div>

        <el-dropdown @command="exportData">
        <el-button
                :disabled="exporting || disabled"
                :loading="exporting" :type="type" icon="el-icon-download" size="medium">
            Download
        </el-button>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="xlsx">XLSX</el-dropdown-item>
            <el-dropdown-item command="csv">CSV</el-dropdown-item>
            <el-dropdown-item command="txt">TXT</el-dropdown-item>
            <el-dropdown-item command="html">HTML</el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
    </el-tooltip>
</template>

<script>
    export default {
        name: "ExportButton",
        props: {
            type: {
              type: String,
              default(){
                  return 'info'
              }
            },
            tooltipDisabled: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            exporting: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            exportData(command){
                this.$emit('export', command)
            }
        }
    }
</script>