
const initialState = {
form: {
    channel: null,
        price: [0, 100000000],
    size: [0, 20000],
    property_types: [],
    date_first_listed: null,
    is_business: null,
    new_build: null,
    auction_only: null,
    postcode: null,
    radius: 1000
},
}

export default {
    namespaced: true,
    state: initialState,
    mutations: {
        reset(state) {
            console.log('reset')
            Object.keys(initialState).forEach(key => {
                state[key] = initialState[key]
            })
        },
    },
    getters: {
        getFormObject: (state) => {
            let object = {};

            if(state.form.channel){
                object.channel = state.form.channel;
            }

            if(state.form.price && state.form.price.length > 0){

                if(state.form.price[0] !== 0){
                    object.price_min = state.form.price[0];
                }
                if(state.form.price[1] !== 100000000) {
                    object.price_max = state.form.price[1];
                }
            }



            if(state.form.size && state.form.size.length > 0){
                if(state.form.size[0] !== 0){
                    object.size_min = state.form.size[0];
                }
                if(state.form.size[1] !== 20000) {
                    object.size_max = state.form.size[1];
                }
            }

            if(state.form.property_types && state.form.property_types.length > 0){
                object.property_types = state.form.property_types;
            }

            if(state.form.is_business !== null){
                object.is_business = state.form.is_business;
            }
            if(state.form.new_build !== null){
                object.new_build = state.form.new_build;
            }
            if(state.form.auction_only !== null){
                object.auction_only = state.form.auction_only;
            }

            if(state.form.postcode && state.form.postcode.length > 0){
                object.postcode = state.form.postcode;
                object.radius = state.form.radius;
            }


            return object;
        }
    }
}
