<template>
    <apexchart type="bar" :height="height"
               :options="getActivityChartOptions"
               :series="getActivityChartSeries"></apexchart>
</template>

<script>
    export default {
        name: "OwnershipActivityChart",
        props: {
            value: {
                type: Object
            },
            height: {
                type: Number,
                default(){
                    return 350;
                }
            }
        },
        computed: {
            getActivityChartOptions() {
                return {
                    chart: {
                        height: 140,
                        type: 'bar',
                    },
                    xaxis: {
                        categories: this.value && this.value.categories ? this.value.categories  : [],
                        title: {
                            text: 'Date'
                        },
                    },
                    yaxis: {
                        title: {
                            text: 'Count'
                        },
                        labels: {
                            formatter(y) {
                                return  y.toLocaleString();
                            }
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    tooltip: {
                        y: {
                            formatter (val) {
                                return val.toLocaleString()
                            }
                        }
                    },
                    title: {
                        align: 'center',
                        style: {
                            color: '#444'
                        }
                    }
                }
            },
            getActivityChartSeries() {
                return this.value && this.value.series ? this.value.series : [];
            },
        }
    }
</script>

<style scoped>

</style>