<template>

    <div>
        <div
                v-if="loading"
                class="flex h-full mt-12 pt-12 items-center justify-center">
            <div class="p-3 text-center">
                <property-loader />
                <h3 class="font-semibold text-blue-800">Fetching Mobile/Broadband Data...</h3>
            </div>
        </div>
        <div
                v-if="!loading" class="columns">

            <div class="column w-full">

                <dashboard-widget
                        padding-class=""
                        title="Broadband Coverage">
                    <slot>
                        <div class="p-1"
                             v-if="!getBroadbandList.length "
                        >
                            <empty-state />
                        </div>

                        <el-table
                                v-if="getBroadbandList.length > 0"
                                striped
                                border
                                :data="getBroadbandList"
                                header-row-class-name="bg-blue-300"
                                style="width: 100%">
                            <el-table-column
                                    prop="type"
                                    class-name="font-semibold"
                                    label="Type">
                                <template slot-scope="scope">
                                  {{ scope.row.type }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                    width="90"
                                    class-name="text-xs"
                                    align="right"
                                    label="Download">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.download_speed > -1">
                                      {{ scope.row.download_speed }}   <small>mbps</small>
                                    </span>
                                    <span v-else>
                                        <i class="el-icon-close text-red-600"></i>
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    width="80"
                                    class-name="text-xs"
                                    align="right"
                                    label="Upload">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.upload_speed > -1">
                                      {{ scope.row.upload_speed }}  <small>mbps</small>
                                    </span>
                                    <span v-else>
                                        <i class="el-icon-close text-red-600"></i>
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    width="85"
                                    class-name="text-xs"
                                    align="right"
                                    label="Coverage">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.availability > -1">
                                      {{ scope.row.availability }}%
                                    </span>
                                    <span v-else>
                                        <i class="el-icon-close text-red-600"></i>
                                    </span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </slot>
                </dashboard-widget>

            </div>

            <div class="column w-full">

                <dashboard-widget
                        padding-class=""
                        title="Mobile Coverage">
                    <slot>

                        <template slot="cta">
                            <el-select
                                    v-model="selected_mobile_coverage"
                                    size="mini">
                                <el-option
                                        v-for="item in mobile_coverage_types"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </template>

                        <div class="p-1"
                                v-if="!getMobileList.length"
                        >
                            <empty-state />
                        </div>
                        <el-table
                                v-if="getMobileList.length > 0"
                                striped
                                border
                                :data="getMobileList"
                                header-row-class-name="bg-blue-300"
                                style="width: 100%">
                            <el-table-column
                                    class-name="font-semibold"
                                    prop="provider"
                                    label="Provider">
                                <template slot-scope="scope">
                                    {{ scope.row.provider }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                    width="60"
                                    class-name="text-xs"
                                    align="center"
                                    label="Voice">
                                <template slot-scope="scope">
                                    <mobile-coverage-checkbox-component
                                            v-if="selected_mobile_coverage === 'indoor-4g'"
                                            v-model="scope.row.voice_indoor"
                                    />
                                    <mobile-coverage-checkbox-component
                                            v-if="selected_mobile_coverage === 'indoor-no-4g'"
                                            v-model="scope.row.voice_indoor_no4g"
                                    />

                                    <mobile-coverage-checkbox-component
                                            v-if="selected_mobile_coverage === 'outdoor-4g'"
                                            v-model="scope.row.voice_outdoor"
                                    />

                                    <mobile-coverage-checkbox-component
                                            v-if="selected_mobile_coverage === 'outdoor-no-4g'"
                                            v-model="scope.row.voice_outdoor_no4g"
                                    />

                                </template>
                            </el-table-column>

                            <el-table-column
                                    width="60"
                                    class-name="text-xs"
                                    align="center"
                                    label="Data">
                                <template slot-scope="scope">

                                    <mobile-coverage-checkbox-component
                                            v-if="selected_mobile_coverage === 'indoor-4g'"
                                            v-model="scope.row.data_indoor"
                                    />

                                    <mobile-coverage-checkbox-component
                                            v-if="selected_mobile_coverage === 'indoor-no-4g'"
                                            v-model="scope.row.data_indoor_no4g"
                                    />

                                    <mobile-coverage-checkbox-component
                                            v-if="selected_mobile_coverage === 'outdoor-4g'"
                                            v-model="scope.row.data_outdoor"
                                    />


                                    <mobile-coverage-checkbox-component
                                            v-if="selected_mobile_coverage === 'outdoor-no-4g'"
                                            v-model="scope.row.data_outdoor_no4g"
                                    />


                                </template>
                            </el-table-column>

                            <el-table-column
                                    width="115"
                                    class-name="text-xs"
                                    align="center"
                                    v-if="selected_mobile_coverage === 'outdoor-4g'"
                                    label="Enhanced Data">
                                <template slot-scope="scope">

                                    <mobile-coverage-checkbox-component
                                            v-model="scope.row.enhanced_data_outdoor"
                                    />

                                </template>
                            </el-table-column>

                        </el-table>
                    </slot>
                </dashboard-widget>

            </div>

        </div>


    </div>

</template>

<script>

    import EmptyState from "../../EmptyState";
    import PropertyLoader from "../../incs/PropertyLoader";
    import { broadbandCoverageTable, getClassForMobileCoverage } from "./incs/BroadbandCoverage"
    import MobileCoverageCheckboxComponent from "./incs/MobileCoverageCheckboxComponent";

    export default {
        name: "OwnershipMobileBroadbandCoverage",
        components: {MobileCoverageCheckboxComponent, PropertyLoader, EmptyState},
        props: {
            id: {
                type: [Number, String],
                default(){
                    return null
                }
            }
        },
        data() {
            return {
                data: {},
                loading: true,
                selected_mobile_coverage: 'indoor-4g',
                mobile_coverage_types: [
                    {value: 'indoor-4g', label: 'Indoor - 4G'},
                    {value: 'indoor-no-4g', label: 'Indoor - No 4G'},
                    {value: 'outdoor-4g', label: 'Outdoor - 4G'},
                    {value: 'outdoor-no-4g', label: 'Outdoor - No 4G'},
                ],
                showPolygons: true,
                activeTabs: [],
                map: {
                    center: [0, 0],
                    zoom: 3
                },
            }
        },
        mounted(){
          this.fetchBroadband();
        },
        computed: {
          hasData(){
              return Object.keys(this.data).length > 0;
          },
            getLocationList(){
              return this.data && this.data.locations && Array.isArray(this.data.locations) ? this.data.locations : [];
            },
            getMobileList(){
                return this.data && this.data.mobile && Array.isArray(this.data.mobile) ? this.data.mobile : [];
            },
            getBroadbandList(){
               let broadband = this.data && this.data.broadband ? this.data.broadband : {};
                return broadbandCoverageTable(broadband);
            }
        },
        methods: {
            getClassForMobileCoverage(rating){
                return getClassForMobileCoverage(rating);
            },
            fetchBroadband(){
                const vm = this;
                vm.loading = true;
                this.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/ownership/' + this.id + '/mobile-broadband')
                    .then((response)=>{
                        vm.loading = false;
                         vm.data = response.data.data;
                    }).catch(()=>{
                    vm.loading = false;
                    vm.data = {};
                    })
            }
        }
    }
</script>

<style scoped>

</style>