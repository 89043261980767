import api from "../../utils/api";

const initialState = {

};

export default {
    namespaced: true,
    state: initialState,
    mutations: {
        reset(state) {
            console.log('reset')
            Object.keys(initialState).forEach(key => {
                state[key] = initialState[key]
            })
        },
    },
    actions: {
        index: ({rootState}, params) => {
            return api.get('/api/v1/team/' + rootState.auth.user.team.id + '/land-hawk-jobs', params);
        },
        create: ({rootState}, params) => {
            return api.post('/api/v1/team/' + rootState.auth.user.team.id + '/land-hawk-jobs', params);
        },
        remove: ({rootState}, params) => {
            return api.delete('/api/v1/team/' + rootState.auth.user.team.id + '/land-hawk-job/' + params.jobId);
        },
        read: ({rootState}, params) => {
            return api.get('/api/v1/team/' + rootState.auth.user.team.id + '/land-hawk-job/' + params.jobId, {
                params: params.params
            });
        },
        download: ({rootState}, params) => {
            return api.get('/api/v1/team/' + rootState.auth.user.team.id + '/land-hawk-job/' + params.jobId, {
                params: {
                    format: params.format,
                    export: true
                }
            });
        },

    }
}