<template>
    <div>
        <div :key="k" v-for="(item, k) of getList">
            <div class="mb-1 w-full flex">
                                <span class="w-3 h-3 mr-2">
                                    <i class="el-icon-check text-green-600"></i>
                                </span>
                <span class="w-auto mt-1 leading-none text-xs break-normal text-left">
                                {{ item }}
                                </span>
            </div>
        </div>
    </div>
</template>

<script>
    const moment = require('moment');
    const humanNumber = require('human-number')

    export default {
        name: "FilterBreakdown",
        props: {
            value: {
                type: Object
            },
            excludeLocation: {
                default: false
            }
        },
        computed: {
            getPropertySizeBreakdown(){
                let min = this.value.area_min ? this.value.area_min : '0';
                let max = this.value.area_max ? this.value.area_max : '40+';
                return min + ' - ' + max + ' acres';
            },
            getBuildingFootprintBreakdown(){
                let min = this.value.min_buildings_footprint ? this.value.min_buildings_footprint.toLocaleString() : '0';
                let max = this.value.max_buildings_footprint ? this.value.max_buildings_footprint.toLocaleString() : (20000).toLocaleString() + '+';
                return min + ' - ' + max + ' sqft';
            },
            getSalePriceRange(){
                let min = this.value.sale_price_min ? this.value.sale_price_min : '0';
                let max = this.value.sale_price_max ? this.value.sale_price_max : '£40m +';
                return (typeof min === 'string' ? min : '£' + humanNumber(min)) + ' - ' + (typeof max === 'string' ? max : '£' + humanNumber(max))
            },
            getBuildingsCount(){
                let min = this.value.min_buildings_count ? this.value.min_buildings_count : '0';
                let max = this.value.max_buildings_count ? this.value.max_buildings_count : '100+';
                return min + ' - ' + max;
            },
            getBuildingsFootprintPercentage(){
                let min = this.value.min_buildings_footprint_percentage ? this.value.min_buildings_footprint_percentage : '0';
                let max = this.value.max_buildings_footprint_percentage ? this.value.max_buildings_footprint_percentage : '100';
                return min + ' - ' + max + '%';
            },
            getSalePriceDateRange(){
                let format = 'MMM YYYY';
                let min = this.value.sales_last_sold_from ? moment(this.value.sales_last_sold_from).format(format) : 'Jan 95';
                let max = this.value.sales_last_sold_to ? moment(this.value.sales_last_sold_to).format(format) : moment().subtract('1', 'month').format(format);
                return min + ' - ' + max;
            },
            getList(){
                let items = [];

                if(!this.excludeLocation){
                        items.push('Properties ' + (this.value.show_leaseholders ? '(Including Leaseholders)' : '' )  + ' within a ' + this.value.radius + ' radius')
                }

                if(this.value.ownership === 'private'){
                    items.push('Privately Owned')
                }
                if(this.value.ownership === 'corporate'){
                    items.push('Corporately Owned')
                }

                if(this.value.area_min || this.value.area_max){
                    items.push('Property size between ' + this.getPropertySizeBreakdown);
                }
                if(this.value.min_buildings_footprint || this.value.max_buildings_footprint){
                    items.push('Total Building footprint between ' + this.getBuildingFootprintBreakdown);
                }


                if(this.value.exclude_greenbelt){
                    items.push('Not within Greenbelt')
                }
                if(this.value.exclude_greenbelt){
                    items.push('Not within AONB')
                }


                if(this.value.environmental_must_include && this.value.environmental_must_include.length > 0){
                    if(this.value.environmental_must_include.length  === 1){
                        items.push('Environment must include ' + this.value.environmental_must_include[0])
                    }else{
                        items.push('Environment must include ' + this.value.environmental_must_include.slice(0, -1).join(', ')+' or '+ this.value.environmental_must_include.slice(-1))
                    }
                }
                if(this.value.environmental_must_not_include && this.value.environmental_must_not_include.length > 0){
                    if(this.value.environmental_must_not_include.length  === 1){
                        items.push('Environment must not include ' + this.value.environmental_must_not_include[0])
                    }else{
                        items.push('Environment must not include ' + this.value.environmental_must_not_include.slice(0, -1).join(', ')+' or '+ this.value.environmental_must_include.slice(-1))
                    }
                }

                if(this.value.use_types && this.value.use_types.length > 0){
                    if(this.value.use_types.length  === 1){
                        items.push('Property types must include ' + this.value.use_types[0])
                    }else{
                        items.push('Property types must include ' + this.value.use_types.slice(0, -1).join(', ')+' or '+ this.value.use_types.slice(-1))
                    }
                }

                if(this.value.epc_ratings && this.value.epc_ratings.length > 0){
                    if(this.value.use_types.length  === 1){
                        items.push('EPC ratings must include ' + this.value.epc_ratings[0])
                    }else{
                        items.push('EPC ratings must include ' + this.value.epc_ratings.slice(0, -1).join(', ')+' or '+ this.value.epc_ratings.slice(-1))
                    }
                }

                if(this.value.sales_last_sold_from || this.value.sales_last_sold_to){
                    items.push('Last sold between ' + this.getSalePriceDateRange);
                }

                if(this.value.sale_price_min || this.value.sale_price_max){
                    items.push('Sales price between ' + this.getSalePriceRange);
                }

                if(this.value.sales_types && this.value.sales_types.length > 0){
                    if(this.value.sales_types.length  === 1){
                        items.push('Property sales type must include ' + this.value.sales_types[0])
                    }else{
                        items.push('Property sales type must include ' + this.value.sales_types.slice(0, -1).join(', ')+' or '+ this.value.sales_types.slice(-1))
                    }
                }

                if(this.value.sales_tenure && this.value.sales_tenure.length > 0){
                    if(this.value.sales_tenure.length  === 1){
                        items.push('Property sales tenure must include ' + this.value.sales_tenure[0])
                    }else{
                        items.push('Property sales tenure must include ' + this.value.sales_tenure.slice(0, -1).join(', ')+' or '+ this.value.sales_tenure.slice(-1))
                    }
                }


                if(this.value.sales_build_type === 'new'){
                    items.push('Property sales must be new build sales')
                }

                if(this.value.exclude_listed_buildings){
                    items.push('Exclude listed buildings')
                }


                if (
                    (this.value.min_buildings_footprint_percentage && this.value.min_buildings_footprint_percentage > 0) ||
                    (this.value.max_buildings_footprint_percentage && this.value.max_buildings_footprint_percentage < 100)
                ) {
                    items.push('Building footprint percentage between ' + this.getBuildingsFootprintPercentage);
                }

                if(this.value.min_buildings_count || this.value.max_buildings_count){
                    items.push('Building count between ' + this.getBuildingsCount);
                }

                return items;
            }
        }
    }
</script>

<style scoped>

</style>