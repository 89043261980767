<template>
    <div>


        <div
                v-if="loading"
                class="flex h-full mt-12 pt-12 items-center justify-center">
            <div class="p-3 text-centerfetchNearestSubstations">
                <property-loader/>
                <h3 class="font-semibold text-blue-800">Fetching Buildings...</h3>
            </div>
        </div>

        <div class="columns" v-if="!loading">
            <div class="column w-full">
                <dashboard-widget
                        padding-class=""
                        title="Buildings Snapshot">
                    <template slot="cta">
                        <div class="flex text-gray-600">
                            <div class="mr-2">
                                <label class="text-xs uppercase">Toggle Markers</label>
                            </div>
                            <div>
                                <el-switch
                                        v-model="showMarkers"
                                        active-color="#13ce66"
                                        inactive-color="#ff4949">
                                </el-switch>
                            </div>
                        </div>
                    </template>

                    <slot>

                        <ownership-topography-map
                                v-if="getSelectedTopographyData && getSelectedTopographyData.features && getSelectedTopographyData.center && getSelectedTopographyData.bbox"
                                v-model="getSelectedTopographyData"
                                :markers="getMarkers"
                        />

                        <el-collapse
                                v-if="getBuildingList && getBuildingList.length > 0"
                                class="el-collapse--value"
                                v-model="activeBuildings">
                            <el-collapse-item
                                    :key="'buildings-' + item.properties.toid"
                                    v-for="(item, index) of getBuildingList"
                                    :name="index">


                                <div class="columns w-full p-2">
                                    <div class="column w-1/2">


                                        <ownership-topography-map
                                                wrapper-class="rounded overflow-hidden"
                                                v-if="activeBuildings.indexOf(index) > -1"
                                                v-model="item.map"
                                        />

                                    </div>
                                    <div class="column w-1/2">


                                        <div class="columns flex-no-wrap" v-if="item.properties.area_sqft">
                                            <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                                                Area
                                            </div>
                                            <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                                {{ item.properties.area_sqft }} ft²
                                            </div>
                                        </div>

                                        <div class="columns flex-no-wrap" v-if="item.properties.rel_h2_max">
                                            <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                                                Height to Chimney
                                            </div>
                                            <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                                {{ item.properties.rel_h2_max }}m
                                            </div>
                                        </div>

                                        <div class="columns flex-no-wrap" v-if="item.properties.rel_h2">
                                            <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                                                Height to Eaves
                                            </div>
                                            <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                                {{ item.properties.rel_h2 }}m
                                            </div>
                                        </div>

                                        <div class="columns flex-no-wrap" v-if="item.properties.estimated_floors">
                                            <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                                                Estimated Floors
                                            </div>
                                            <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                                {{ item.properties.estimated_floors }}
                                            </div>
                                        </div>

                                        <div class="columns flex-no-wrap" v-if="item.properties.physical_level">
                                            <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                                                Physical Location
                                            </div>
                                            <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                                {{ item.properties.physical_level }}
                                            </div>
                                        </div>

                                        <div class="columns flex-no-wrap" v-if="item.properties.last_change_date">
                                            <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                                                Last Changed
                                            </div>
                                            <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                                <date-human-view v-model="item.properties.last_change_date" size="text-xs" />
                                            </div>
                                        </div>

                                        <div class="columns flex-no-wrap" v-if="item.properties.reason_for_change">
                                            <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                                                Reason for Change
                                            </div>
                                            <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                                {{ item.properties.reason_for_change }}
                                            </div>
                                        </div>


                                        <div class="columns flex-no-wrap" v-if="item.properties.change_count">
                                            <div class="column mr-auto text-xs text-gray-500 w-1/2 font-medium flex">
                                                Change Count
                                            </div>
                                            <div class="column ml-auto text-xs text-right text-gray-900 flex items-center">
                                                {{ item.properties.change_count }}
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <template slot="title">
                                    <div class="flex flex-wrap w-full flex-row select-none">
                                        <div class="w-full flex items-start  py-1">
                                            <span class="w-4 h-4 mr-1 flex flex-none text-white border border-white shadow items-center justify-center shadow-lg rounded-full bg-blue-600 text-xxs">{{    index + 1 }}</span>
                                            <span class="text-xs">
                                                {{ item.properties.is_registered_building === true ? item.properties.registered_address : 'Building ' + ( index + 1) }}
                                            </span>
                                        </div>
                                        <div class="columns text-xs font-light mt-1">
                                            <div class="column">{{ item.properties.area_sqft.toLocaleString() }} <small>ft²</small></div>
                                            <div class="column">{{ item.properties.physical_level }}</div>
                                            <div class="column" v-if="item.properties.estimated_floors">{{ item.properties.estimated_floors }} floor{{ item.properties.estimated_floors > 1 ? 's': '' }}</div>
                                            <div class="column" v-if="item.properties.last_change_date">Last modified <date-human-view size="text-xs" v-model="item.properties.last_change_date" /></div>
                                        </div>
                                    </div>
                                </template>

                            </el-collapse-item>
                        </el-collapse>
                    </slot>

                </dashboard-widget>
            </div>
            <div class="column w-full">
                <dashboard-widget
                        padding-class=""
                        title="Building Area's">
                    <el-table :data="getBuildingAreas">
                        <el-table-column label="Building">
                            <template slot-scope="scope">
                                <span class="font-bold text-xs leading-tight">{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column align="right" width="120" label="Est. Floors">
                            <template slot-scope="scope">
                                {{ scope.row.estimated_floors }}
                            </template>
                        </el-table-column>
                        <el-table-column align="right" width="120" label="Area">
                            <template slot-scope="scope">
                                {{ scope.row.area }} <small>ft²</small>
                            </template>
                        </el-table-column>
                    </el-table>
                </dashboard-widget>
            </div>
            <div class="column w-full">
                <dashboard-widget
                        padding-class=""
                        title="Building Height's">
                    <el-table :data="getBuildingHeights">
                        <el-table-column label="Building">
                            <template slot-scope="scope">
                                <span class="font-bold text-xs leading-tight">{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column align="right" width="120" label="Height to Chimney">
                            <template slot-scope="scope">
                                 {{ scope.row.height_to_chimney }}<small>m</small>
                            </template>
                        </el-table-column>
                        <el-table-column align="right" width="120" label="Height to Eaves">
                            <template slot-scope="scope">
                                {{ scope.row.height_to_eaves }}<small>m</small>
                            </template>
                        </el-table-column>
                    </el-table>
                </dashboard-widget>
            </div>
        </div>


    </div>

</template>

<script>

    import PropertyLoader from "../../../incs/PropertyLoader";
    import OwnershipTopographyMap from "../incs/OwnershipTopographyMap";

    export default {
        name: "OwnershipTopographyBuildings",
        components: {OwnershipTopographyMap, PropertyLoader},
        props: {
            loading: {
                type: Boolean,
                default: true
            },
            value: {
                type: Object,
                default() {
                    return []
                }
            },
            activeSiteId: {
                type: Number,
                default() {
                    return null;
                }
            }
        },
        watch: {
          value: {
              handler(val){
                  this.object = JSON.parse(JSON.stringify(val));
              },
              deep: true
          }
        },
        data() {
            return {
                showMarkers: true,
                object: JSON.parse(JSON.stringify(this.value)),
                activeBuildings: [],
                activeTabs: [],
                selected: 0,
                map: {
                    zoom: 3
                },
            }
        },
        computed: {
            getBuildingAreas(){
                if(this.getSelectedTopographyData.features){
                    return this.getSelectedTopographyData.features.map((i, index)=>{
                        const is_registered = typeof i.properties.is_registered_building === 'boolean' && i.properties.is_registered_building === true;
                        return {
                            index: index + 1,
                            name: !is_registered ? 'Building ' + (index + 1) : i.properties.registered_address,
                            estimated_floors:  i.properties.estimated_floors,
                            area: i.properties.area_sqft.toLocaleString()
                        }
                    });
                }
                return [];
            },
            getBuildingHeights(){
                if(this.getSelectedTopographyData.features){
                    return this.getSelectedTopographyData.features.map((i, index)=>{
                        const is_registered = typeof i.properties.is_registered_building === 'boolean' && i.properties.is_registered_building === true;
                        return {
                            index: index + 1,
                            name: !is_registered ? 'Building ' + (index + 1) : i.properties.registered_address,
                            height_to_chimney: i.properties.rel_h2_max,
                            height_to_eaves: i.properties.rel_h2,
                            estimated_floors: i.properties.estimated_floors
                        }
                    });
                }
                return [];
            },
            getBuildingList(){
              if(this.getSelectedTopographyData.features){
                  return  this.getSelectedTopographyData.features.map((i)=>{
                      i.map = {
                          features: [JSON.parse(JSON.stringify(i))],
                          center: i.properties.center,
                          bbox: i.properties.bbox
                      }
                      return i;
                  });
              }
              return [];
            },
            getMarkers(){
                return !this.showMarkers ? [] : this.getSelectedTopographyData.features.map((i)=>{
                    return i.properties.center;
                })
            },
            hasData() {
                return this.object.length > 0;
            },
            getSelectedTopographyData() {
                let overview = this.object && this.object.overview ? this.object.overview : null;
                if(!overview){
                    return  null;
                }
                let sites = this.object && this.object.sites ? this.object.sites : [];

                if(this.activeSiteId && sites.length > 0){
                    overview = sites.find((i)=>{
                        return i.site_id === this.activeSiteId;
                    });
                }

                overview.features = overview.features.filter((i)=> {
                    return i.properties.type === 'Buildings'
                })

                return overview;
            }

        }
    }
</script>