import {latLngBounds} from "leaflet";
import api from "../../utils/api";

const initialState = {
  access_token: null,
  refresh_token: null,
  api: process.env.NODE_ENV === 'production' ? 'https://api.landmonitor.com' : 'https://localhost',
  showMenu: false,
  stripe_key: process.env.NODE_ENV === 'production' ? 'pk_live_51IWfIiB3ODPpbJmcgaRD8Iqndo9OqJjiIEpR97Tr65btbk5UN0YIg0rjM7QXnWAkQRRu8UhvhOr0ohNP3BxAS6BW00engwfBlx' : 'pk_test_51IWfIiB3ODPpbJmcy9INTWxFuM345wRYcAjQZkZ8YiCJQQaFtxb2tm5B3WGoYdjc9NOSa8r9KNMrHfWQN243TLVI0062cmHna3',
  showSwitchTeam: false,
  plans: {
    yearly: process.env.NODE_ENV === 'production' ? 'price_1KZze1B3ODPpbJmcBFkrzktZ' : 'price_1KVZfTB3ODPpbJmcVm62LL3z',
    monthly: process.env.NODE_ENV === 'production' ? 'price_1KZze1B3ODPpbJmc0hwxt1KN' : 'price_1KVZfTB3ODPpbJmcTVyxz4sS'
  },
  user: {
    id: null,
    firstname: null,
    lastname: null,
    email: null,
    avatar: null,
    map_style: 'Road_3857',
    permissions: [],
    has_outstanding_invites: false,
    total_invites: 0,
    total_teams: 0,
    has_set_initial_location: false,
    skip_tutorial: true,
    email_verification: {
      code: null,
      verifying: false,
    },
    team: {
      id: null,
      member_id: null,
      name: null,
      is_owner: false,
      has_subscription: false
    },
    location: {
      lat: 51.81845234645576,
      lng: -0.3588728305670497
    },
    polygon_color: '#C0382B',
    zoom: 15,
    newsletter: true
  },
  account: {
    updating: false,
    visible: false,
    activeTab: 'profile'
  },
  email_verification: {
    code: null
  },
  map: {
    max_bounds: latLngBounds([
      [61.173129, 2.329445],
      [49.365495, -12.040672],

    ]),
    max_zoom: 19,
    min_zoom: 7,

    google: {
      key: 'AIzaSyAENZy2SL14YFJdl4T9OiaPIgWgKejcCPg',
      opts: {
        type: 'roadmap'
      }
    }
  },
  breadcrumbs: []
}

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    reset (state) {
      console.log('reset')
      Object.keys(initialState).forEach(key => {
        state[key] = initialState[key]
      })
    },
    setUserLocation(state, prop){
      state.user.location.lat = prop.lat;
      state.user.location.lng = prop.lng;
      state.user.location.zoom = prop.zoom;
    },
    setBreadcrumbs(state, prop){
      state.breadcrumbs = prop;
      if(prop.length > 0){
        document.title = "Land Monitor - " + prop.map((item)=>{ return item.title }).join(' / ')
      }else{
        document.title = "Land Monitor"
      }
    },
    setAccessToken (state, access_token) {
      state.access_token = access_token;
    },
    setRefreshToken(state, refresh_token) {
      state.refresh_token = refresh_token;
    },
    setUser (state, payload) {
      state.user = payload
    },
    setUserObject(state, payload){
      state.user[payload.key] = payload.value;
    }
  },
  actions: {
    skipTutorial: () => {

      return api.put('/api/v1/auth/skip-tutorial').then(()=>{

      }).catch(()=>{

      });

    },
    openSupport: () => {
      const el = document.querySelector('#PapercupsChatWidget button');
      if(el){
        el.click();
      }
    },


    refreshLocation: ({state}) => {
      return api.get('/api/v1/location', {
        params: Object.assign(state.user.location, {
          zoom: state.user.zoom
        })
      })
    },

    handleServerError: (object, params) => {

      const error = params.error;
      console.log(params);

      let notificationObject = {
        position: 'top-right',
        title: 'Sorry, something is wrong;',
        message: 'We had an internal error occurred. Please contact support',
        type: 'error',
        offset: typeof params.offset === 'number' ? params.offset : 65
      };

      console.log(typeof params.offset)
      if (error.response) {

        if(error.response.status === 500){
          params.vm.$notify(notificationObject);
        }

        if(error.response.status === 404 && error.response.data && error.response.data.message){
          let message = error.response.data.message;
          if(message.length > 0){
            params.vm.$notify(Object.assign(notificationObject, {
              message: message,
              type: 'warning'
            }));
          }
        }

          if(error.response.status === 400 && error.response.data && error.response.data.message){
          let message = error.response.data.message.replace('body.', '');
          if(message.length > 0){
            params.vm.$notify(Object.assign(notificationObject, {
              message: message,
              type: 'warning'
            }));
          }
        }
      }
    }
  },
  getters: {
    getActiveTeamId: (state) => {
      return state.user.team.id;
    },
    getMaxBounds: () => {
      return latLngBounds([
        [58.6350001085, 1.68153079591],
        [49.959999905, -7.57216793459],
      ])
    },
    getMapStyle: (state) => {
      return  "https://api.os.uk/maps/raster/v1/zxy/" + state.user.map_style + "/{z}/{x}/{y}.png?key=wC6Him1A448aAquBYlhEJq2IS4GAK51Z"
    },
    hasPermission: (state) => (permission) => {
      return state.user.permissions.indexOf(permission) > -1
    },
    accessToken: (state) => {
      return state.access_token
    },
    refreshToken:  (state) => {
      return state.refresh_token;
    },
    isAuthenticated: (state) => {
      return state.access_token !== null;
    },
    hasOutstandingInvites: (state) => {
      return state.user.has_outstanding_invites ? state.user.has_outstanding_invites : false;
    },
    hasSkippedTutorial: (state) => {
      return state.user.skip_tutorial ? state.user.skip_tutorial : false;
    },
    hasActiveTeam: (state) => {
      return state.user.has_active_team ? state.user.has_active_team : false;
    },
    getActiveTeamAvatar: (state) => {
      return state.user.has_active_team && state.user.team.avatar ? state.user.team.avatar : null;
    },
    getActiveTeamName: (state) => {
      return state.user.has_active_team && state.user.team.name ? state.user.team.name : null;
    },
    totalInvites: (state) => {
      return state.user.total_invites ? state.user.total_invites : 0;
    },
    totalTeams: (state) => {
      return state.user.total_teams ? state.user.total_teams : 0;
    },
    emailVerified: (state) => {
      return state.user.email_verified ? state.user.email_verified : false;
    },
    hasSubscription: (state) => {
      return state.user.team.has_subscription ? state.user.team.has_subscription : false;
    },
    hasSetInitialLocation: (state) => {
      return state.user.has_set_initial_location ? state.user.has_set_initial_location : false;
    },
    getBreadcrumbs: (state) => {
      return [
        {
          title: 'Land Monitor',
          path: '/'
        }
      ].concat(state.breadcrumbs)
    }
  }
}
