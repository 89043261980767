<template>

    <page v-loading="loading">

        <template slot="header">
            <page-header :title="data.loading ? 'My Team' : data.name">
            </page-header>

        </template>

        <template slot="content">
            <div class="columns justify-center mt-12">
                <div
                        :key="k"
                        v-for="(m, k) of data.members"
                        class="column">
                    <div class="w-64 rounded shadow bg-white shadow-sm">
                        <h4 class="p-3 border-b font-semibold border-border-gray-300">{{m.name}}</h4>
                       <div class="p-4">
                           <div class="flex items-center justify-center">
                               <avatar-manager
                                       :size="60"
                                       :name="m.name"
                                       v-model="m.avatar" />
                           </div>
                           <div class="text-center">
                               <p class="mt-2 truncate text-sm w-auto">{{ m.email }}</p>
                               <p class="my-3">
                                   <span
                                           :class="{
                                           'is-success': m.is_owner,
                                           'is-info': !m.is_owner
                                           }"
                                           class="tag">
                                       {{ m.is_owner ? 'Owner' : 'Member' }}
                                   </span>
                               </p>
                           </div>
                       </div>
                    </div>
                </div>
            </div>
        </template>

    </page>

</template>
<script>
   // import PropertyLoader from "../components/incs/PropertyLoader";
  //  import EmptyState from "../components/EmptyState";
   import AvatarManager from "../components/AvatarManager";
  //  import PrettyStatusLabelComponent from "../components/PrettyStatusLabelComponent";

    export default {
        name: 'ActiveTeam',
        components: {AvatarManager},
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.$store.commit('auth/setBreadcrumbs', [
                    {
                        title: 'My Team'
                    }
                ]);

                return vm.$store.dispatch('team/read', {
                    teamId: vm.$store.state.auth.user.team.id
                }).then((response) => {
                    vm.data = response.data.data;
                    vm.loading = false;
                })
                    .catch(() => {
                        vm.$router.push('/')
                    })
            })
        },
        data() {
            return {
                loading: true,
                data: {
                    id: null,
                    name: null,
                    avatar: null,
                    members: []
                }
            }
        }
    }
</script>

