<template>

    <page>

        <template slot="header">
            <page-header title="Property Sale Prices" />
        </template>


        <template slot="content">
            <div class="columns">
                <div class="column w-full" v-if="$store.state.propertyPrices.search.show">
                    <property-search-form
                            @change="(val)=>{ $store.state.propertyPrices.search.options = val }"
                            @search="fetchPropertyPrices(1)"
                    />
                </div>
                <div class="column w-full" v-if="!$store.state.propertyPrices.search.show">

                    <dashboard-widget
                            title="Property Sales">

                        <template slot="cta">
                            <el-button
                                    @click="showSearch"
                                    :disabled="$store.state.propertyPrices.search.loading"
                                    type="success" icon="el-icon-search">Show Search</el-button>
                        </template>


                        <div class="h-32 w-full"
                             v-if="$store.state.propertyPrices.search.loading"
                             v-loading="true"></div>

                        <empty-state class="p-3"
                                     title="No properties found"
                                     message="Sorry, but we could not find any properties matching your search"
                                     :support="false"
                                     v-if="!$store.state.propertyPrices.search.loading && !$store.state.propertyPrices.search.results.length" />

                        <div class="columns pb-4"
                             v-if="$store.state.propertyPrices.search.meta.totalResult && !$store.state.propertyPrices.search.loading"
                        >
                            <div class="column flex items-center">
                                <span class="text-sm">
                                  {{ $store.state.propertyPrices.search.meta.totalResult.toLocaleString() }} applications found. Displaying page {{ $store.state.propertyPrices.search.meta.current }} of {{ $store.state.propertyPrices.search.meta.pageCount }}
                                </span>
                            </div>
                            <div class="column ml-auto">
                                <div class="columns">
                                    <div class="column flex items-center">
                                        <span class="text-gray-600 uppercase text-xs">per page</span>
                                    </div>
                                    <div class="column">
                                        <el-select
                                                class="w-32"
                                                v-model="$store.state.propertyPrices.search.rpp" @change="fetchPropertyPrices(1)">
                                            <el-option :value="10">10</el-option>
                                            <el-option :value="20">20</el-option>
                                            <el-option :value="30">30</el-option>
                                            <el-option :value="40">40</el-option>
                                            <el-option :value="50">50</el-option>
                                            <el-option :value="100">100</el-option>
                                        </el-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <el-table
                                v-if="$store.state.propertyPrices.search.meta.totalResult && !$store.state.propertyPrices.search.loading"
                                :data="$store.state.propertyPrices.search.results"
                                :default-sort="$store.state.propertyPrices.search.default_sort"
                                @sort-change="onSortPropertyChange"
                                style="width: 100%">
                            <el-table-column
                                    prop="address"
                                    sortable="custom"
                                    label="Address">
                                <template slot-scope="scope">
                                    <div class="break-normal h-full text-xs leading-tight">
                                        <p
                                                :key="k"
                                                v-for="(i, k) in scope.row.address_array">
                                            {{ i }}
                                        </p>
                                    </div>

                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="property_type.keyword"
                                    width="130px"
                                    sortable="custom"
                                    class-name="text-xs"
                                    label="Type">
                                <template slot-scope="scope">

                                    {{ scope.row.property_type_string }}

                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="new_build"
                                    width="120px"
                                    sortable="custom"
                                    align="center"
                                    class-name="text-xs"
                                    label="New Build">
                                <template slot-scope="scope">



                                    {{ scope.row.new_build ? 'Yes' : 'No' }}

                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="duration.keyword"
                                    sortable="custom"
                                    width="100px"
                                    class-name="text-xs"
                                    label="Tenure">
                                <template slot-scope="scope">

                                    {{ scope.row.duration_string }}

                                </template>
                            </el-table-column>
                            <el-table-column
                                    width="120px"
                                    prop="postcode.keyword"
                                    sortable="custom"
                                    class-name="text-xs"
                                    label="Postcode">
                                <template slot-scope="scope">

                                    {{ scope.row.postcode }}

                                </template>
                            </el-table-column>

                            <el-table-column
                                    width="120px"
                                    prop="date_of_transfer"
                                    sortable="custom"
                                    align="right"
                                    class-name="text-xs"
                                    label="Date">
                                <template slot-scope="scope">
                                    <div class="break-normal h-full text-xs leading-tight">
                                        {{ scope.row.date_of_transfer | moment("MMM Do YYYY") }}
                                        <br /><small>{{ scope.row.date_of_transfer | moment("from", "now") }}</small>
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column
                                    width="130px"
                                    prop="price"
                                    sortable="custom"
                                    align="right"
                                    class-name="text-xs"
                                    label="Price">
                                <template slot-scope="scope">

                                    £{{ scope.row.price.toLocaleString() }}

                                </template>
                            </el-table-column>


                        </el-table>

                        <div class="columns pt-4"
                             v-if="$store.state.propertyPrices.search.meta.totalResult && !$store.state.propertyPrices.search.loading"
                        >
                            <div class="column flex items-center">
                                <span class="text-sm">
                                  {{ $store.state.propertyPrices.search.meta.totalResult.toLocaleString() }} properties found. Displaying page {{ $store.state.propertyPrices.search.meta.current }} of {{ $store.state.propertyPrices.search.meta.pageCount }}
                                </span>
                            </div>
                            <div class="column ml-auto">
                                <el-pagination
                                        background
                                        layout="prev, pager, next"
                                        @current-change="(page)=>{ fetchPropertyPrices(page) }"
                                        :page-size="$store.state.propertyPrices.search.rpp"
                                        :current-page="$store.state.propertyPrices.search.meta.current"
                                        :total="$store.state.propertyPrices.search.meta.totalResult">
                                </el-pagination>

                            </div>
                        </div>


                    </dashboard-widget>

                </div>
            </div>

        </template>`

    </page>


</template>
<script>
    import PropertySearchForm from "../../../components/PropertySearchForm";
    import EmptyState from "../../../components/EmptyState";

    export default {
        name: "PropertyPricesSearch",
        components: {EmptyState, PropertySearchForm},
        methods: {
            onSortPropertyChange(object){
                if(object.prop && object.order){
                    this.$store.state.propertyPrices.search.default_sort = object;
                }else{
                    this.$store.state.propertyPrices.search.default_sort = null;
                }
                this.fetchPropertyPrices(1);
            },
            showSearch(){
                this.$store.state.propertyPrices.search.meta = {};
                this.$store.state.propertyPrices.search.results = [];
                this.$store.state.propertyPrices.search.show = true;
            },
            fetchPropertyPrices(page){
                let vm = this;
                vm.$store.state.propertyPrices.search.results = [];
                vm.$store.state.propertyPrices.search.show = false;
                vm.$store.state.propertyPrices.search.loading = true;
                vm.$http.get('/api/v1/team/' + vm.$store.state.auth.user.team.id + '/property-prices', {
                    params: Object.assign( this.$store.getters['propertyPrices/getFormObject'], {
                        page: page || 1,
                        rpp: vm.$store.state.propertyPrices.search.rpp,
                        sort: vm.$store.state.propertyPrices.search.default_sort && vm.$store.state.propertyPrices.search.default_sort.prop ? vm.$store.state.propertyPrices.search.default_sort.prop : null,
                        order: vm.$store.state.propertyPrices.search.default_sort && vm.$store.state.propertyPrices.search.default_sort.order ? vm.$store.state.propertyPrices.search.default_sort.order === 'descending' ? 'desc' : 'asc' : null,
                    })
                }).then((response) => {
                    vm.$store.state.propertyPrices.search.results = response.data.results;
                    vm.$store.state.propertyPrices.search.meta = response.data.meta;
                    vm.$store.state.propertyPrices.search.loading = false;
                }).catch(() => {
                    vm.$store.state.propertyPrices.search.loading = false;
                })
            },
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.$store.commit('propertyPrices/reset');
                vm.$store.commit('auth/setBreadcrumbs', [
                    {
                        title: 'Property Price'
                    }
                ]);
            })
        }
    }
</script>
