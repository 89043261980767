<template>
    <div>
        <div>
            <slot v-if="!loading" name="header"></slot>

            <div v-if="!loading && hasResults">
                <slot name="results"></slot>
            </div>

            <div v-if="loading"
                 class="rounded-md p-4 w-full my-12 mx-auto">
                <div class="animate-pulse flex space-x-4">
                    <div class="rounded-full bg-gray-400 h-12 w-12"></div>
                    <div class="flex-1 space-y-4 py-1">
                        <div class="h-4 bg-gray-400 rounded w-3/4"></div>
                        <div class="space-y-2">
                            <div class="h-4 bg-gray-400 rounded"></div>
                            <div class="h-4 bg-gray-400 rounded w-5/6"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!loading && !hasResults">
                <empty-state title="Sorry, no results found" />
            </div>


          <div class="columns w-full" v-if="!loading && meta  && hasResults">
                <div class="column ml-auto">
                    <el-pagination
                            background
                            layout="prev, pager, next"
                            @current-change="(page)=>{ $emit('change', page) }"
                            :page-size="meta.rpp"
                            :current-page="meta.current"
                            :total="meta.totalResult">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import EmptyState from "../EmptyState";
    export default {
        name: "PaginationWrapper",
        components: {EmptyState},
        props: {
            loading: {
                type: Boolean,
                default: true
            },
            hasResults: {
                type: Boolean,
                default: false
            },
            meta: {
                type: Object,
                default() {
                    return {}
                }
            }
        }
    }
</script>
<style>

    .max-modal-height {
        height: calc(100vh - 20rem);
    }
</style>