<template>
    <div :id="id"></div>
</template>

<script>

    const grecaptcha = window.grecaptcha,
        siteKey = '6LcSCaYeAAAAAEcFhLY9BIzgIz3mKutb5CzZYzZA';

    import moment from 'moment';

    export default {
        name: "RecaptchaComponent",
        data(){
          return {
              grecaptcha: null,
              recaptcha_value: null,
              id: 'el-item-' + moment().unix()
          }
        },
        methods: {
            onRecaptchaCallback(e){
                this.recaptcha_value = e;
                this.$emit('token', this.recaptcha_value)
            },
            reset(){
                if(this.grecaptcha){
                    this.grecaptcha.reset();
                }
            }
        },
        mounted(){
            const vm = this;

            grecaptcha.ready(() => {
                vm.grecaptcha = grecaptcha;
                vm.grecaptcha.render(vm.id, {
                    "sitekey": siteKey,
                    callback: vm.onRecaptchaCallback
                });
            });

        },
    }
</script>

 core-js driver.js html-entities mapbox-gl mapbox-gl-leaflet maplibre-gl osmbuildings polylabel vue-google-maps vue-googlemaps vue-head vue-input-code vue-papercups vue-programmatic-invisible-google-recaptcha vue-recaptcha vue-recaptcha-v3 vue-verification-code-input vue2-datepicker vue2-leaflet-draw-toolbar vue2-leaflet-googlemutant wkt
