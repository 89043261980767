import api from "./../../utils/api";

const initialState = {
    loading: false,
    bounds: null,
    type: 'topographic-area',
    geo_objects: [],
    property: null,
    types: [
        {
            label: 'Identifiers',
            options: [
                {
                    value: 'greenspace',
                    label: 'Greenspace'
                },
                {
                    value: 'functional-site',
                    label: 'Functional Sites'
                },
                {
                    value: 'topographic-area',
                    label: 'Topographic Area'
                },
                {
                    value: 'foreshore',
                    label: 'Foreshore'
                },
                {
                    value: 'local-buildings',
                    label: 'Local Buildings'
                },
                {
                    value: 'national-parks',
                    label: 'National Parks'
                },
                {
                    value: 'surfacewater',
                    label: 'Surfacewater'
                },
                {
                    value: 'woodland',
                    label: 'Woodland'
                }
            ]
        }

    ]

};

export default {
    namespaced: true,
    state: initialState,
    mutations: {
        reset(state) {
            console.log('reset')
            Object.keys(initialState).forEach(key => {
                state[key] = initialState[key]
            })
        },
    },
    getters: {
        property: (state) => {
            return state.property;
        },
        getGeoObjects: (state) => {
            return state.geo_objects;
        },
        getSelectedPropertyGeoJson: (state) => {
            return state.property && state.property.result ? state.property.result : null;
        },
        getSelectedPropertyLatLon: (state) => {
            return state.property && state.property.lon && state.property.lat ?
                {
                    lat: state.property.lat,
                    lon: state.property.lon
                }
                : null;
        },
        getSelectedPropertyInfo: (state) => {
           try {
               let properties = state.property.result.features[0].properties;
               return [
                   {key: 'Type', value: [
                           properties.DescriptiveGroup,
                           properties.DescriptiveTerm,
                       ].filter((item)=>{
                       return item && typeof item === 'string' && item.length > 0 && item !== "null"
                       }).join(', ')
                   },
                   {key: 'Calculated Area (sqm)', value: properties.CalculatedAreaValue ? properties.CalculatedAreaValue.toLocaleString() : null},
                   {key: 'Last Changed', value: properties.ChangeDate ? properties.ChangeDate : null},
                   {key: 'Reason For Change', value:  properties.ReasonForChange ? properties.ReasonForChange : null},
                   {key: 'Make', value: properties.Make ? properties.Make : null},
                   {key: 'Location', value:  properties.PhysicalLevel ? properties.PhysicalLevel : null},
               ]
           }catch (e) {
               return null;
           }
        },
    },
    actions: {
        getOdsProperty: ({state}, opts) => {
            state.loading = true;
            return api.get('/api/v1/ordinance/property', {
                params: {
                    lat: opts.lat,
                    lon: opts.lon,
                    type: state.type
                }
            }).then((response) => {
                state.loading = false;
               state.property = response.data.data;
            }).catch(() => {
                state.loading = false;
                state.property = null;
            })
        },
        getOdsNearby: ({state}, opts) => {
            state.loading = true;
            opts.type = state.type;
            return api.get('/api/v1/ordinance/nearby', {
                params: opts
            }).then((response) => {
                state.loading = false;
                console.log(response.data)
                state.geo_objects = response.data && response.data.data && response.data.data.features ? response.data.data.features : [];
            }).catch(() => {
                state.loading = false;
                state.geo_objects = [];
            })
        },
    }
}