<template>
    <el-tooltip placement="top">
        <div slot="content">
            {{ value | moment("MMM Do YYYY") }}
        </div>
        <div class="inline-block" :class="size">
            <span
                class="inline-block  cursor-pointer border-gray-500 border-b border-dotted">
         {{ prefix }}   {{ value | moment("from", "now") }} {{ suffix }}
        </span>
        </div>

    </el-tooltip>
</template>

<script>
    export default {
        name: "DateHumanView",
        props: {
            size: {
                type: String,
                default(){
                    return 'text-sm';
                }
            },
            value: {
                type: String,
                default(){
                    return null;
                }
            },
            prefix: {
                type: String,
                default(){
                    return '';
                }
            },
            suffix: {
                type: String,
                default(){
                    return '';
                }
            }
        }
    }
</script>

<style scoped>

</style>