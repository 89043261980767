<template>

    <page>

        <template slot="header">
            <page-header title="Land Monitor Reports">
            </page-header>

        </template>

        <template slot="content">
            <div class="columns">
                <div class="column w-full">

                    <dashboard-widget
                            padding-class=""
                            title="Land Monitor Reports">

                        <empty-state class="p-3"
                                     title="No Land Monitor reports found"
                                     message="You currently have no reports"
                                     :support="false"
                                     v-if="!table.loading && !table.results.length"/>


                        <div class="p-3 py-12 text-center" v-if="table.loading">
                            <property-loader />
                        </div>


                        <div v-if="table.results.length > 0 && !table.loading">
                            <div class="p-3">
                                <el-alert effect="dark" type="info" :closable="false">
                                    {{ table.meta.totalResult.toLocaleString() }} reports found. Displaying page {{ table.meta.current }} of {{ table.meta.pageCount }}
                                </el-alert>
                            </div>
                            <div class="columns p-3">
                                <div class="column ml-auto">
                                    <el-pagination
                                            background
                                            layout="prev, pager, next"
                                            @current-change="(page)=>{ fetch(page) }"
                                            :page-size="table.rpp"
                                            :current-page="table.meta.current"
                                            :total="table.meta.totalResult">
                                    </el-pagination>
                                </div>
                            </div>
                            <el-table :data="table.results">
                                <el-table-column label="Document">
                                    <template slot-scope="scope">
                                      <strong>{{ scope.row.title }}</strong>
                                    </template>
                                </el-table-column>
                                <el-table-column  width="140" label="Date">
                                    <template slot-scope="scope">
                                        <date-human-view v-model="scope.row.purchased_at" />
                                    </template>
                                </el-table-column>


                                <el-table-column width="160" align="right">


                                    <template slot-scope="scope">
                                       <el-button
                                               @click="open(scope.row.link)"
                                               type="success" icon="el-icon-document">
                                           Download
                                       </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="columns p-3">
                                <div class="column ml-auto">
                                    <el-pagination
                                            background
                                            layout="prev, pager, next"
                                            @current-change="(page)=>{ fetch(page) }"
                                            :page-size="table.rpp"
                                            :current-page="table.meta.current"
                                            :total="table.meta.totalResult">
                                    </el-pagination>
                                </div>
                            </div>
                        </div>

                    </dashboard-widget>
                </div>
            </div>
        </template>


    </page>

</template>
<script>

    import EmptyState from "../components/EmptyState";
    import PropertyLoader from "../components/incs/PropertyLoader";
    export default {
        name: 'Reports',
        components: { PropertyLoader, EmptyState},
        mounted(){
            this.fetch(1);
        },
        methods: {
            open(link){
              window.open(link);
            },
            fetch(page) {
                const vm = this;
                vm.table.loading = true;
                return vm.$store.dispatch('report/index', {
                    params: {
                        page: page || 1,
                        rpp: vm.table.rpp
                    }
                }).then((response) => {
                    vm.table.loading = false;
                    vm.table.results = response.data.data.results;
                    vm.table.meta = response.data.data.meta;
                }).catch(() => {
                    vm.table.loading = false;
                    vm.table.results = [];
                    vm.table.meta = {};
                })

            }
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.$store.commit('auth/setBreadcrumbs', [
                    {
                        title: 'Land Monitor Reports',
                    }
                ]);
            })
        },
        data(){
            return {
                loading: true,
                subscription: {
                    updating: false,
                    show: false
                },
                seats: {
                    activeMember: null,
                    loading: false,
                    results: [],
                    selected: {
                        team: null
                    }
                },
                team: {},
                table: {
                    rpp: 10,
                    loading: true,
                    results: [

                    ],
                    meta: {}
                }
            }
        }
    }
</script>
<style>
    .el-option-select--seat-data {
        height: auto !important;
    }
</style>
