<template>
    <div class="h-full bg-gray-200 overflow-scroll">
        <div class="bg-blue-900 p-6">
            <h4 class="font-semibold overflow-hidden text-white">
                Planning Applications
            </h4>
            <p class="text-xs text-white">View local and national planning applications to quickly what has been approved/rejected at scale.</p>
        </div>
        <div class="bg-gray-200 p-3">
            <div class="columns">
                <div class="column w-full">
                    <dashboard-widget title="Search Planning Applications">
                        <template v-slot:cta>
                            <export-button
                                    @export="(format) => exportData(format) "
                                    :exporting="$store.state.map.data_metrics.planning.fetching"
                                    :disabled="$store.state.map.data_metrics.planning.fetching"
                            />
                        </template>


                        <data-layer-filters
                                ref="dataLayerFilters"
                                :loading="$store.state.map.data_metrics.planning.fetching"
                                :filters="filters"
                                @change="()=> { fetchTable(1) } "
                        />

                        <div class="h-1 border-b mt-3 border-gray-200"></div>

                        <data-layer-table-wrapper
                                ref="tableWrapper"
                                :filters="filters"
                                default-sort-field="date_decided|desc"
                                @change="(page)=> {  fetchTable(page) }"
                                v-model="$store.state.map.data_metrics.planning">

                            <div
                                    :key="k"
                                    v-for="(i, k) of $store.state.map.data_metrics.planning.results"
                                    class="bg-white p-4 mb-6 border shadow border border-gray-200">


                                <div v-if="i.date_received" class="flex -pr-1 items-center  text-xs">
                                    <div class="p-1 w-1/2">
                                        Received {{i.date_received | moment("MMM Do YYYY") }}
                                    </div>
                                    <div v-if="i.date_decided" class="p-1 w-1/2 text-right">
                                        Decided {{ i.date_decided | moment("MMM Do YYYY") }}
                                    </div>
                                </div>

                                <h3 class="font-bold mt-1 leading-snug my-2 mt-3 uppercase">{{ i.site_address }}</h3>
                                <h4 class="text-sm my-1 text-xs my-2">{{ i.proposal }}</h4>


                                <p class="text-xs"><strong >{{ i.status }} </strong>  {{ i.decision  ? '-' : '' }} {{ i.decision }}</p>


                                <div class="my-3 flex w-full flex-wrap" v-if="findTags(i).length > 0">
                                    <div class="p-1"
                                         :key="k"
                                         v-for="(v, k) of findTags(i)">
                                        <el-tag type="info">{{ v }}</el-tag>
                                    </div>
                                </div>

                                <div class="columns mb-0 mt-3 -mx-1">
                                    <div class="w-1/2 p-1">
                                        <el-button class="w-full" type="success"
                                                   :disabled="!i.uprn"
                                                   icon="el-icon-info"
                                                   @click="() => $emit('uprn', i.uprn)"
                                                   size="small"> View Property
                                        </el-button>
                                    </div>
                                    <div class="w-1/2 p-1">
                                        <el-button
                                                @click="openApplication(i.url)"
                                                :disabled="!i.url"
                                                icon="el-icon-link"
                                                class="w-full" type="info" size="small">
                                            View Application
                                        </el-button>
                                    </div>
                                </div>

                            </div>

                        </data-layer-table-wrapper>


                    </dashboard-widget>
                </div>
            </div>
        </div>
    </div>
</template>

<script>



    import DataLayerFilters from "../../DataLayerFilters";
    import ExportButton from "../../ExportButton";
    import DataLayerTableWrapper from "../../DataLayerTableWrapper";
    import {download} from "../../../utils/download";
    import {getPlanningTypeTags} from "../../../utils/ownership";

    export default {
        name: "PlanningDataLayer",
        components: {DataLayerTableWrapper, ExportButton, DataLayerFilters},
        data() {
            return {
                filters: [
                    {
                        id: 'site_address',
                        label: 'Site Address',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'postcode',
                        label: 'Postcode',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'status',
                        label: 'Status',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'decision',
                        label: 'Decision',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'decision_type',
                        label: 'Decision Type',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        type: "text",
                        id: "date_s106",
                        label: "Date S106",
                        operators: ["=", "<",  "<=", '>', ">="],
                        date: true
                    },
                    {
                        type: "text",
                        id: "date_received",
                        label: "Date Received",
                        operators: ["=", "<",  "<=", '>', ">="],
                        date: true
                    },
                    {
                        type: "text",
                        id: "date_validated",
                        label: "Date Validated",
                        operators: ["=", "<",  "<=", '>', ">="],
                        date: true
                    },
                    {
                        type: "text",
                        id: "date_decided",
                        label: "Date Decided",
                        operators: ["=", "<",  "<=", '>', ">="],
                        date: true
                    },  {
                        type: "text",
                        id: "date_decision_expected",
                        label: "Date Decision Decided",
                        operators: ["=", "<",  "<=", '>', ">="],
                        date: true
                    },  {
                        type: "text",
                        id: "date_committee",
                        label: "Date of Committee",
                        operators: ["=", "<",  "<=", '>', ">="],
                        date: true
                    },
                    {
                        type: "text",
                        id: "date_consultation_start",
                        label: "Date Consultation Start",
                        operators: ["=", "<",  "<=", '>', ">="],
                        date: true
                    },
                    {
                        type: "text",
                        id: "date_consultation_end",
                        label: "Date Consultation End",
                        operators: ["=", "<",  "<=", '>', ">="],
                        date: true
                    },
                    {
                        type: "text",
                        id: "date_site_visit",
                        label: "Date Site Visit",
                        operators: ["=", "<",  "<=", '>', ">="],
                        date: true
                    },
                    {
                        type: "text",
                        id: "date_appeal",
                        label: "Date Appeal",
                        operators: ["=", "<",  "<=", '>', ">="],
                        date: true
                    },
                    {
                        type: "text",
                        id: "date_appeal_decision",
                        label: "Date Appeal Decision",
                        operators: ["=", "<",  "<=", '>', ">="],
                        date: true
                    },
                    {
                        id: 'ward',
                        label: 'Ward',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'parish',
                        label: 'Parish',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'district',
                        label: 'District',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'proposal',
                        label: 'Proposal',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'type',
                        label: 'Type',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'case_officer',
                        label: 'Case Officer',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'applicant_name',
                        label: 'Applicant Name',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'applicant_address',
                        label: 'Applicant Address',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'applicant_company',
                        label: 'Applicant Company',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'agent_name',
                        label: 'Agent Name',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'agent_company',
                        label: 'Agent Company',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'agent_telephone',
                        label: 'Agent Telephone',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'agent_email',
                        label: 'Agent Email',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'appeal_type',
                        label: 'Appeal Type',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'appeal_reference',
                        label: 'Appeal Reference',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'appeal_reference_alternative',
                        label: 'Appeal Reference Alternative',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'appeal_decision',
                        label: 'Appeal Decision',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'appeal_officer',
                        label: 'Appeal Officer',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'is_tpo',
                        value: 'is_tpo',
                        label: 'Is TPO/Trees',
                        type: 'select',
                        choices: [
                            {label: "Yes", value: true},
                            {label: "No", value: false}
                        ]
                    },
                    {
                        id: 'is_appeal',
                        value: 'is_appeal',
                        label: 'Is Appealed',
                        type: 'select',
                        choices: [
                            {label: "Yes", value: true},
                            {label: "No", value: false}
                        ]
                    },
                    {
                        id: 'is_advert',
                        value: 'is_advert',
                        label: 'Is Advertisement',
                        type: 'select',
                        choices: [
                            {label: "Yes", value: true},
                            {label: "No", value: false}
                        ]
                    },
                    {
                        id: 'is_major',
                        value: 'is_major',
                        label: 'Is Major Application',
                        type: 'select',
                        choices: [
                            {label: "Yes", value: true},
                            {label: "No", value: false}
                        ]
                    },
                    {
                        id: 'is_minor',
                        value: 'is_minor',
                        label: 'Is Minor Application',
                        type: 'select',
                        choices: [
                            {label: "Yes", value: true},
                            {label: "No", value: false}
                        ]
                    },
                    {
                        id: 'is_full',
                        value: 'is_full',
                        label: 'Is Full Application',
                        type: 'select',
                        choices: [
                            {label: "Yes", value: true},
                            {label: "No", value: false}
                        ]
                    },
                    {
                        id: 'is_household',
                        value: 'is_household',
                        label: 'Is Household Application',
                        type: 'select',
                        choices: [
                            {label: "Yes", value: true},
                            {label: "No", value: false}
                        ]
                    },
                    {
                        id: 'is_conservation',
                        value: 'is_household',
                        label: 'Is Conservation Area',
                        type: 'select',
                        choices: [
                            {label: "Yes", value: true},
                            {label: "No", value: false}
                        ]
                    },
                    {
                        id: 'is_listed_building',
                        value: 'is_listed_building',
                        label: 'Is Listed Building',
                        type: 'select',
                        choices: [
                            {label: "Yes", value: true},
                            {label: "No", value: false}
                        ]
                    },
                    {
                        id: 'is_discharge_of_conditions',
                        value: 'is_discharge_of_conditions',
                        label: 'Is Discharge of Conditions',
                        type: 'select',
                        choices: [
                            {label: "Yes", value: true},
                            {label: "No", value: false}
                        ]
                    },
                    {
                        id: 'is_change_of_use',
                        value: 'is_change_of_use',
                        label: 'Is Change of Use',
                        type: 'select',
                        choices: [
                            {label: "Yes", value: true},
                            {label: "No", value: false}
                        ]
                    },
                    {
                        id: 'is_certificate',
                        value: 'is_certificate',
                        label: 'Is Certificate',
                        type: 'select',
                        choices: [
                            {label: "Yes", value: true},
                            {label: "No", value: false}
                        ]
                    },
                    {
                        id: 'is_agricultural',
                        value: 'is_agricultural',
                        label: 'Is Agricultural',
                        type: 'select',
                        choices: [
                            {label: "Yes", value: true},
                            {label: "No", value: false}
                        ]
                    },
                    {
                        id: 'is_demolition',
                        value: 'is_demolition',
                        label: 'Is Demolition',
                        type: 'select',
                        choices: [
                            {label: "Yes", value: true},
                            {label: "No", value: false}
                        ]
                    },
                    {
                        id: 'is_extension',
                        value: 'is_extension',
                        label: 'Is Extension',
                        type: 'select',
                        choices: [
                            {label: "Yes", value: true},
                            {label: "No", value: false}
                        ]
                    }
                ]
            }
        },
        created() {
            this.fetchTable(1);
            this.$store.commit('map/addLayerAvailability', 'planning');
            this.$store.commit('map/addLayerVisibility', 'planning');
        },
        beforeDestroy(){
            this.$store.commit('map/removeLayerAvailability', 'planning')
            this.$store.commit('map/removeLayerVisibility', 'planning')
        },

        watch: {
            '$store.state.map.bounds'(){
                const vm = this;
                if(this.$store.state.map.search_area === 'bounds' ){
                    setTimeout(()=>{
                        vm.fetchTable(1);
                    }, 500);
                }
            }
        },
        computed: {
            getQueryObject() {
                const match = this.query && this.query.logicalOperator ? this.query.logicalOperator : null;
                if (!match) {
                    return null;
                }
                return {
                    match: match,
                    rules: this.query && this.query.children ? this.query.children.map((i) => {
                        return i.query
                    }).filter((item) => {
                        return item && ((typeof item.value === 'string' && item.value.length > 0) || item.type === 'custom-component' || (typeof item.value === 'boolean'));
                    }) : []
                }
            },
        },
        methods: {
            findTags(item){
                return getPlanningTypeTags(item);
            },
            openApplication(url){
                window.open(url)
            },
            buildFilters(page) {

                let vm = this,
                    filters = {},
                    object = {
                        rpp: 10,
                        page: page || 1
                    },
                    queryObject = this.$refs.dataLayerFilters ? this.$refs.dataLayerFilters.getFilters() : {};

                if (queryObject && queryObject.match && queryObject.rules) {
                    filters.match = queryObject.match;
                    filters.rules = queryObject.rules;
                }

                if (this.$refs.tableWrapper && this.$refs.tableWrapper.getSortField().length > 0) {
                    let ex = this.$refs.tableWrapper.getSortField().split('|');
                    console.log(ex);
                    if (ex.length === 2) {
                        object.sort = ex[0];
                        object.order = ex[1];
                    }
                }

                const bounds = this.$store.state.map.search_area === 'bounds' ? vm.$store.getters['map/getMapBoundsAsObject'] : {};

                object.search_area = this.$store.state.map.search_area;

                return Object.assign(object, filters, bounds);

            },
            exportData(type){
                const vm = this, object = this.buildFilters();
                vm.$store.state.map.data_metrics.planning.fetching = true;
                object.format = type;
                vm.$store.dispatch('map/searchPlanning', object)
                    .then((response) => {
                        vm.$store.state.map.data_metrics.planning.fetching = false;
                        download(response.data.data);
                    }).catch(() => {
                    vm.$store.state.map.data_metrics.planning.fetching = false;
                })
            },
            fetchTable(page) {
                const vm = this, object = this.buildFilters(page);

                vm.$store.state.map.data_metrics.planning.fetching = true;
                vm.$store.dispatch('map/searchPlanning', object).then((response) => {
                    vm.$store.state.map.data_metrics.planning.fetching = false;
                    vm.$store.state.map.data_metrics.planning.results = response.data.data.results;
                    vm.$store.state.map.data_metrics.planning.meta = response.data.data.meta;
                }).catch(() => {
                    vm.$store.state.map.data_metrics.planning.fetching = false;
                    vm.$store.state.map.data_metrics.planning.results = [];
                    vm.$store.state.map.data_metrics.planning.meta = {};
                })
            },
        }
    }
</script>