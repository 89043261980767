<template>
    <div class="h-full bg-gray-200 overflow-scroll">
        <div class="bg-blue-900 p-6">
            <h4 class="font-semibold overflow-hidden text-white">
               Commercial Availability
            </h4>
        </div>
        <div class="bg-gray-200 p-3">
            <div class="columns">
                <div class="column w-full">
                    <dashboard-widget title="Search Commercial Availability">
                        <template v-slot:cta>
                        </template>




                        <div v-loading="$store.state.map.data_metrics.commercial_availability.fetching">
                            <filter-component title="Search" class="mb-2">



                                <el-select
                                        class="w-full"
                                        @change="()=>{ searchCommercialAvailabilityTable(1); searchPolygons() }"
                                        placeholder="Select Search Area..."
                                        size="medium"
                                        :disabled="$store.state.map.data_metrics.commercial_availability.fetching"
                                        v-model="search_area">
                                    <el-option
                                            v-for="item in search_areas"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                        {{ item.label }}
                                    </el-option>
                                </el-select>




                            </filter-component>
                            <filter-component>
                                <vue-query-builder
                                        v-model="query"
                                        :maxDepth="1"
                                        :rules="filters"/>
                            </filter-component>
                        </div>

                        <div class="flex w-full justify-end my-3">
                            <el-button
                                    type="success"
                                    icon="el-icon-search"
                                    :disabled="$store.state.map.data_metrics.commercial_availability.fetching"
                                    :loading="$store.state.map.data_metrics.commercial_availability.fetching"
                                    @click="()=>{ searchCommercialAvailabilityTable(1); searchPolygons() }"
                            >Search
                            </el-button>
                        </div>

                        <div class="h-1 border-b mt-3 border-gray-200"></div>

                        <div v-if="$store.state.map.data_metrics.commercial_availability.fetching"
                             class="rounded-md p-4 w-full my-12 mx-auto">
                            <div class="animate-pulse flex space-x-4">
                                <div class="rounded-full bg-gray-400 h-12 w-12"></div>
                                <div class="flex-1 space-y-4 py-1">
                                    <div class="h-4 bg-gray-400 rounded w-3/4"></div>
                                    <div class="space-y-2">
                                        <div class="h-4 bg-gray-400 rounded"></div>
                                        <div class="h-4 bg-gray-400 rounded w-5/6"></div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div v-if="!$store.state.map.data_metrics.commercial_availability.fetching && !$store.state.map.data_metrics.commercial_availability.results.length">
                            <empty-state title="Sorry, no results found" />
                        </div>

                        <div v-if="!$store.state.map.data_metrics.commercial_availability.fetching && $store.state.map.data_metrics.commercial_availability.meta.totalResult && $store.state.map.data_metrics.commercial_availability.results.length > 0">

                            <div class="columns my-3">
                                <div class="column w-1/3 text-xs flex items-center uppercase">
                                    {{ $store.state.map.data_metrics.commercial_availability.meta.totalResult.toLocaleString() }} results
                                </div>
                                <div class="column w-2/3">
                                    <div class="flex items-center">
                                        <div class="text-xs uppercase ml-auto mr-2">Sort by</div>

                                        <div class="w-48">
                                            <el-select
                                                    autocomplete="off"
                                                    name="sort"
                                                    @change="()=>{ searchCommercialAvailabilityTable(1) }"
                                                    placeholder="Sort..."
                                                    size="small"
                                                    v-model="sort">
                                                <el-option
                                                        v-for="item in sort_fields"
                                                        :key="item.value"
                                                        :label="item.label"
                                                        :value="item.value">
                                                    <div class="flex w-full items-center">
                                                        <i v-if="item.value.endsWith('desc')" class="el-icon-arrow-down"></i>
                                                        <i v-if="item.value.endsWith('asc')"  class="el-icon-arrow-up"></i>
                                                        <span class="ml-1">{{ item.label }}</span>
                                                    </div>
                                                </el-option>
                                            </el-select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                    :key="k"
                                    v-for="(i, k) of $store.state.map.data_metrics.commercial_availability.results"
                                    class="bg-white p-4 mb-6 border shadow border border-gray-200">

                                <div class="flex -pr-1 items-center  text-xs">
                                    <div class="pr-1">

                                        <span class="el--tenure-tag uppercase p-1 select-none rounded" :class="i.channel === 'Buy' ? 'bg-green-300 text-green-600 border border-green-600' : 'bg-blue-300 text-blue-600 border border-blue-600' ">
        {{ i.channel }}
    </span>
                                    </div>
                                    <div class="pr-1 ml-auto">
                                        First listed <date-human-view size="text-xs" v-model="i.date_added" />
                                    </div>
                                </div>

                                <h3 class="font-bold text-lg leading-tight my-3">{{ i.address }}</h3>

                                <div class="columns">
                                    <div class="column w-1/3">
                                        <div class="rounded overflow-hidden">
                                            <el-image
                                                    class="shadow border-gray-200 border"
                                                    :src="i.image"
                                                    fit="contain"></el-image>
                                        </div>
                                    </div>
                                    <div class="column w-2/3">
                                        <p class="my-3">{{ i.price_qualifier && i.price_qualifier !== 'None' ? i.price_qualifier : '' }} {{ i.price ? '£' + i.price.toLocaleString() : '' }} {{ i.price ? i.channel === 'Rent' ? 'p/m' : '' : '' }}</p>
                                        <div class="text-xs">
                                            <span v-if="i.date_added">
                                                <span class="font-bold">Listed for:</span>  {{ $moment().diff($moment(i.date_added), 'days') }} days <br />
                                            </span>
                                            <span v-if="i.type">
                                                <span class="font-bold">Type:</span> {{ i.type }} <br />
                                            </span>
                                           <span  v-if="hasSqft(i)">
                                               <span class="font-bold">Sqft:</span> {{ hasSqft(i) }} (ft²)<br />
                                           </span>
                                        </div>
                                        <div class="flex justify-end" v-if="i.coords">
                                        </div>
                                    </div>
                                </div>


                                <div class="columns mt-3">
                                <div class="w-1/2 p-1">
                                    <el-button
                                            :disabled="!i.coords"
                                            @click="$emit('lat-lon', i.coords)"
                                            icon="el-icon-location"
                                            class="w-full"  type="success" size="small"> View Location
                                    </el-button>
                                </div>
                                    <div class="w-1/2 p-1">
                                        <el-button
                                                @click="openListing(i.id)"
                                                icon="el-icon-postcard"
                                                class="w-full" type="info" size="small"> View Listing
                                        </el-button>
                                    </div>
                            </div>


                            </div>

                            <div class="columns p-3" v-if="!$store.state.map.data_metrics.commercial_availability.fetching && $store.state.map.data_metrics.commercial_availability.meta  && $store.state.map.data_metrics.commercial_availability.results.length > 0">
                                <div class="column ml-auto">
                                    <el-pagination
                                            background
                                            layout="prev, pager, next"
                                            @current-change="(page)=>{ searchCommercialAvailabilityTable (page) }"
                                            :page-size="$store.state.map.data_metrics.commercial_availability.meta.rpp"
                                            :current-page="$store.state.map.data_metrics.commercial_availability.meta.current"
                                            :total="$store.state.map.data_metrics.commercial_availability.meta.totalResult">
                                    </el-pagination>
                                </div>
                            </div>

                        </div>


                    </dashboard-widget>
                </div>
            </div>
        </div>
    </div>
</template>

<script>



    import collect from "collect.js";
    import VueQueryBuilder from 'vue-query-builder';
    import FilterComponent from "../FilterComponent";
    import EmptyState from "../../EmptyState";

    export default {
        name: "CommercialAvailabilityDataLayer",
        components: {EmptyState, FilterComponent, VueQueryBuilder},
        data() {
            return {
                sort: 'address.keyword|desc',
                query: {},
                search_area: 'bounds',
                search_areas: [
                    {value: 'bounds', label: 'Current Bounds'},
                    {value: null, label: 'United Kingdom'},
                ],
                filters: [
                    {
                        id: 'address',
                        label: 'Address',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'rating',
                        label: 'Rating',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },
                    {
                        id: 'potential_rating',
                        label: 'Potential Rating',
                        type: 'text',
                        operators: ['contains', 'starts with', 'ends with', 'equal to', 'not equal to', 'not end with', 'not start with', 'not contains']
                    },

                    {
                        type: "text",
                        id: "valid_until",
                        label: "Valid Until",
                        operators: ["=", "<",  "<=", '>', ">="],
                        date: true
                    },

                    {
                        type: "numeric",
                        id: "total_floor_area_sqm",
                        label: "Total floor area (sqm)",
                        operators: ["=", "<",  "<=", '>', ">="],
                        date: true
                    },


                ]
            }
        },
        created() {
            this.searchCommercialAvailabilityTable(1);
            this.searchPolygons();
           // this.$store.commit('map/addLayerAvailability', 'epcs');
           // this.$store.commit('map/addLayerVisibility', 'epcs');
        },
        beforeDestroy(){
          //  this.$store.commit('map/removeLayerAvailability', 'epcs')
          //  this.$store.commit('map/removeLayerVisibility', 'epcs')
        },

        watch: {
            '$store.state.map.bounds'(){
                const vm = this;

                setTimeout(()=>{
                    vm.searchCommercialAvailabilityTable(1);
                    vm.searchPolygons();

                }, 500);

            }
        },
        computed: {
            ascFields(){
                return this.filters.map((item)=>{
                    return {
                        value: item.id + (item.type === 'text' && !item.date ? '.keyword' : '') + '|asc',
                        label: item.label
                    }
                });
            },
            descFields(){
                return this.filters.map((item)=>{
                    return {
                        value: item.id + (item.type === 'text' && !item.date ? '.keyword' : '') + '|desc',
                        label: item.label
                    }
                });
            },
            sort_fields(){
                return collect(this.ascFields.concat(this.descFields)).sortBy('label').toArray()
            },
            getQueryObject() {
                const match = this.query && this.query.logicalOperator ? this.query.logicalOperator : null;
                if (!match) {
                    return null;
                }
                return {
                    match: match,
                    rules: this.query && this.query.children ? this.query.children.map((i) => {
                        return i.query
                    }).filter((item) => {
                        return item && ((typeof item.value === 'string' && item.value.length > 0) || item.type === 'custom-component' || (typeof item.value === 'boolean'));
                    }) : []
                }
            },
        },
        methods: {
            hasSqft(i){
                if(i.min_size_ft || i.max_size_ft ){
                    if(i.min_size_ft === i.max_size_ft){
                        return i.max_size_ft.toLocaleString();
                    }
                    if(i.min_size_ft && !i.max_size_ft) {
                        return  i.min_size_ft.toLocaleString();
                    }
                    if(!i.min_size_ft && i.max_size_ft) {
                        return  i.max_size_ft.toLocaleString();
                    }
                    return  i.min_size_ft.toLocaleString() + ' to ' +  i.max_size_ft.toLocaleString();
                }
                return null;
            },
            openListing(id){
                window.open('https://www.rightmove.co.uk/properties/' + id + '#/');
            },
            searchPolygons() {
                const vm = this;

                let object = {
                    view: 'polygons'
                }, filters = {}, queryObject = this.getQueryObject;

                if(queryObject && queryObject.match && queryObject.rules){
                    filters.match = queryObject.match;
                    filters.rules = queryObject.rules;
                }


                const bounds = this.search_area === 'bounds' ? vm.$store.getters['map/getMapBoundsAsObject'] : {};

                object = Object.assign(object, filters, bounds);
                vm.$store.state.map.data_metrics.commercial_availability.fetching_polygons = true;
                vm.$store.state.map.data_metrics.commercial_availability.polygons = [];
                vm.$store.dispatch('map/searchCommercialAvailability', object).then((response) => {
                    vm.$store.state.map.data_metrics.commercial_availability.fetching_polygons = false;
                    vm.$store.state.map.data_metrics.commercial_availability.polygons = response.data.data;
                }).catch(() => {
                    vm.$store.state.map.data_metrics.commercial_availability.fetching_polygons = false;
                    vm.$store.state.map.data_metrics.commercial_availability.polygons = [];
                })

            },

            searchCommercialAvailabilityTable(page) {
                const vm = this;
                let object = {
                    rpp: 10,
                    page: page || 1
                };

                let filters = {}, queryObject = this.getQueryObject;

                if(queryObject && queryObject.match && queryObject.rules){
                    filters.match = queryObject.match;
                    filters.rules = queryObject.rules;
                }

                if(this.sort && this.sort.length > 0){
                    let ex = this.sort.split('|');
                    if(ex.length === 2){
                        object.sort = ex[0];
                        object.order = ex[1];
                    }
                }


                const bounds = this.search_area === 'bounds' ? vm.$store.getters['map/getMapBoundsAsObject'] : {};

                object = Object.assign(object, filters, bounds);

                vm.$store.state.map.data_metrics.commercial_availability.fetching = true;
                vm.$store.dispatch('map/searchCommercialAvailability', object).then((response) => {
                    vm.$store.state.map.data_metrics.commercial_availability.fetching = false;
                    vm.$store.state.map.data_metrics.commercial_availability.results = response.data.data.results;
                    vm.$store.state.map.data_metrics.commercial_availability.meta = response.data.data.meta;
                }).catch(() => {
                    vm.$store.state.map.data_metrics.commercial_availability.fetching = false;
                    vm.$store.state.map.data_metrics.commercial_availability.results = [];
                    vm.$store.state.map.data_metrics.commercial_availability.meta = {};
                })
            },
        }
    }
</script>
<style>
    .el-date-picker {
        z-index: 50001 !important;
    }
</style>