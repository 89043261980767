<template>
    <div class="tag"
    :class="{
    'is-success': value === 'ACTIVE',
    'is-warning': value === 'SUSPENDED',
    }"
    >
        {{ value }}
    </div>
</template>

<script>
    export default {
        name: "PrettyStatusLabelComponent",
        props: {
            value: {
                type: String,
                default(){
                    return null
                }
            }
        }
    }
</script>

<style scoped>

</style>